.dialog-join-meeting { text-align: center; }
.dialog-join-meeting-content { padding: 64px 128px; }
.dialog-join-meeting-content .title-header { padding: 0; margin-bottom: 8px; }
.dialog-join-meeting-content .title-header .title { display: inline-block; font-size: 48px; line-height: 52px; font-family: Lora; font-weight: 700; }
.dialog-join-meeting-content .title-header .close-icon { position: absolute; top: 20px; right: 20px; cursor: pointer; }

.dialog-join-meeting-content .content { padding: 0; overflow-x: hidden; }
.dialog-join-meeting-content .content .sub-title { font-size: 18px; line-height: 24px; font-weight: 400; margin-bottom: 24px; font-family: Source Sans Pro; padding: 0 40px; }

.dialog-join-meeting-content .content .actions { margin-top: 8px; display: flex; justify-content: center; }
.dialog-join-meeting-content .content .actions .button-join { margin-right: 24px; padding: 15px 32px; background: #00BCD4; border-radius: 4px; color: #fff; text-transform: uppercase; font-size: 14px; line-height: 18px; font-family: Source Sans Pro; font-weight: 700; }