.css-qd57tp-MuiPaper-root-MuiMenu-paper-MuiPopover-paper{
  top: 84px !important;
  padding: 20px 10px;
}

.notification-link {
  color: #000
}

.notification-link:hover {
  color: #000
}

.notification-link:visited {
  color: #000
}

.notification-row .left-part {
  float: left;
}

.notification-row .notification-title {
  padding: 7px;
  padding-left: 10px;
  padding-right: 10px;
  font-size: 13px;
  margin: 0;
}

.notification-row .notification-content {
  padding: 7px;
  padding-left: 10px;
  padding-right: 10px;
  font-size: 12px;
  margin: 0;
}


.notification-row .right-part {
  float: right;
  font-size: 12px;
}

.no-notifications {
  text-align: center;
  font-size: 14px;
  margin: 0;
  padding: 20px 0;
}
.loading-more-notifications{
  margin-top: 5px;
  margin-left: 10px;
  color: #0097A7;
}

@media screen and (max-width:1320px) {
  
  .header-lawyer .notification-icon {
    margin-right: 10px;
  }
}

@media screen and (max-width: 768px) {
  .header-lawyer .notification-icon {
    margin-top: 5px;
    margin-left: 10px;
  }
}
@media screen and (max-width:453px) {
  .notification-icon {
    margin-right: 10px;
  
  }
}


