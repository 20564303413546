.profile-lawyer .profile-lawyer-hire-content { margin: 30px 140px; background: #F8F8F8; border-radius: 4px; text-align: center; }
.profile-lawyer .profile-lawyer-hire-content .title { font-size: 32px; line-height: 32px; font-weight: 700; font-family: Lora; margin-bottom: 8px; }
.profile-lawyer .profile-lawyer-hire-content .text { margin: 0 20px 24px 20px; font-size: 18px; line-height: 24px; font-family: Source Sans Pro; font-weight: 400; }
.profile-lawyer .profile-lawyer-hire-content .box-content { margin-bottom: 24px; }
.profile-lawyer .profile-lawyer-hire-content .box-content .box { padding: 15px; background: #EDEDED; border-radius: 4px; text-align: center; height: 180px; }
.profile-lawyer .profile-lawyer-hire-content .box-content .box .main-icon .icon { margin-bottom: 10px; }
.profile-lawyer .profile-lawyer-hire-content .box-content .box .text-box { font-size: 16px; line-height: 18px; font-family: Source Sans Pro; font-weight: 400; }
.profile-lawyer .profile-lawyer-hire-content .button { padding: 15px 32px; background: #00BCD4; color: #fff; border-radius: 4px; text-transform: uppercase; font-family: Source Sans Pro; font-size: 14px; line-height: 18px; font-weight: 700; }

@media screen and (max-width: 1140px) {
    .profile-lawyer .profile-lawyer-hire-content .box-content {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 40px;
    }
}

@media screen and (max-width: 1000px) {
    .profile-lawyer .profile-lawyer-hire-content .hire-lawer-box-content{
        width: 300px !important;
    }
}

@media screen and (max-width: 700px) {
    .profile-lawyer .profile-lawyer-hire-content {
        margin: 0 !important;   
        width: 100%;
    }

    .profile-lawyer .profile-lawyer-hire-content .title {
        font-size: 25px;
    }

    .profile-lawyer .back-btn {
        background: #00BCD4;
        color: #fff;
    }
    
    .profile-lawyer .back-btn:hover {
        background: #00BCD4;
        color: #fff;
    }

    .profile-lawyer .profile-lawyer-hire-content .text {
        margin: 0 0 10px 0;
    }

    .profile-lawyer .profile-lawyer-hire-content .box-content .box {
        margin: 0 auto;
        width: 200px;
    }

    .profile-lawyer .profile-lawyer-hire-content .hire-lawer-box-content {
        width: 100% !important;
    }
}