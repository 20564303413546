.dialog-common-multi-profile {
  text-align: center;
}

.dialog-common-stripe-setup-modal {
  padding: 20px 35px;
}

.dialog-common-stripe-setup-modal .title-header {
  padding: 0;
  margin-bottom: 8px;
}
.dialog-common-stripe-setup-modal-content {
  padding: 20px 35px;
}

.dialog-common-stripe-setup-modal-content .title-header .title {
  display: inline-block;
  font-size: 32px;
  line-height: 32px;
  font-family: Lora;
  font-weight: 700;
  text-align: center;
  width: 96%;
}

.dialog-common-stripe-setup-modal-content .title-header .close-icon {
  display: inline-block;
  cursor: pointer;
  float: right;
}

.dialog-common-stripe-setup-modal-content .content {
  padding: 0;
  overflow-x: hidden;
}

.dialog-common-stripe-setup-modal-content .content .sub-title {
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 20px;
  font-family: "Source Sans Pro";
  padding: 0 40px;
}

.dialog-common-stripe-setup-modal-content .content .actions {
  margin-top: 8px;
}

.dialog-common-stripe-setup-modal-content .content .actions .button-continue {
  height: 45px;
  padding: 15px 32px;
  background: #00bcd4;
  border-radius: 4px;
  color: #fff;
  text-transform: uppercase;
  margin-right: 25px;
  font-size: 14px;
  line-height: 18px;
  font-family: "Source Sans Pro";
  font-weight: 700;
}

.dialog-common-stripe-setup-modal-content .content .actions .button-back {
  height: 45px;
  padding: 15px 32px;
  border: 2px solid #00bcd4;
  border-radius: 4px;
  color: #000;
  text-transform: none;
  font-size: 14px;
  line-height: 18px;
  font-family: "Source Sans Pro";
  font-weight: 700;
}

@media screen and (max-width: 1000px) {
}
@media screen and (max-width: 950px) {
  .dialog-common-stripe-setup-modal-content .content .col4of12 {
    width: 80%;
  }
}

@media screen and (max-width: 600px) {
}
@media screen and (max-width: 415px) {
  .dialog-common-stripe-setup-modal-content .content .actions {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  .dialog-common-stripe-setup-modal-content .content .actions .button-continue {
    width: 100%;
  }
  .dialog-common-stripe-setup-modal-content .content .actions .button-back {
    width: 100%;
  }
}

@media screen and (max-width: 432px) {
  .dialog-common-stripe-setup-modal-content .content .sub-title {
    padding: 0;
    text-align: left;
  }
  .dialog-common-stripe-setup-modal-content .content .col4of12 {
    width: 100%;
  }
}

@media screen and (max-width: 320px) {
  .dialog-common-stripe-setup-modal-content .title-header .title {
    font-size: 16px;
  }
}
