.search-properties .filters .headerSearch {
    padding: 16px 32px;
    background-color: #FFFFFF;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.3);
    display: flex;
    align-items: center;
    position: fixed;
    top: 80px;
    z-index: 20;
    width: 1440px;
}

.search-properties .filters .headerSearch .item {
    margin-right: 20px;
    padding: 0 10px;
}

.search-properties .filters .headerSearch .item.autocomplete-cities,
.search-properties .filters .headerSearch .item.autocomplete-neigborhood, 
.search-properties .filters .headerSearch .item.autocomplete-sort-by,
.search-properties .filters .headerSearch .item.autocomplete-price-range,
.search-properties .filters .headerSearch .item.autocomplete-area,
.search-properties .filters .headerSearch .item.autocomplete-year {
    background: rgba(244, 244, 244, 1);
    position: relative;
    border-radius: 50px;
    padding: 0 20px;
}

.search-properties .filters .headerSearch .item.autocomplete-cities .listItem,
.search-properties .filters .headerSearch .item.autocomplete-neigborhood .listItem,
.search-properties .filters .headerSearch .item.autocomplete-sort-by .listItem,
.search-properties .filters .headerSearch .item.autocomplete-price-range .listItem,
.search-properties .filters .headerSearch .item.autocomplete-area .listItem,
.search-properties .filters .headerSearch .item.autocomplete-year .listItem {
    cursor: pointer;
    padding: 0;
}

.search-properties .filters .headerSearch .item.autocomplete-cities.show-before-element::before,
.search-properties .filters .headerSearch .item.autocomplete-neigborhood.show-before-element::before,
.search-properties .filters .headerSearch .item.autocomplete-sort-by.show-before-element::before,
.search-properties .filters .headerSearch .item.autocomplete-price-range.show-before-element::before,
.search-properties .filters .headerSearch .item.autocomplete-area.show-before-element::before,
.search-properties .filters .headerSearch .item.autocomplete-year.show-before-element::before {
    position: absolute;
    top: 100%;
    left: 40px;
    content: '';
    border-bottom: 20px solid rgba(255, 255, 2555, 1);
    border-left: 20px solid transparent;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.30)) drop-shadow(0px 8px 12px rgba(0, 0, 0, 0.15));
    border-right: 20px solid transparent;
}

.search-properties .filters .headerSearch .item.autocomplete-cities.selected-option, 
.search-properties .filters .headerSearch .item.autocomplete-neigborhood.selected-option,
.search-properties .filters .headerSearch .item.autocomplete-sort-by.selected-option,
.search-properties .filters .headerSearch .item.autocomplete-price-range.selected-option,
.search-properties .filters .headerSearch .item.autocomplete-area.selected-option,
.search-properties .filters .headerSearch .item.autocomplete-year.selected-option {
    color: #ffffff;
    background: rgba(0, 188, 212, 1);
}

.search-properties .filters .headerSearch .item.autocomplete-cities.selected-option .open,
.search-properties .filters .headerSearch .item.autocomplete-cities.selected-option .close,
.search-properties .filters .headerSearch .item.autocomplete-neigborhood.selected-option .open,
.search-properties .filters .headerSearch .item.autocomplete-neigborhood.selected-option .close,
.search-properties .filters .headerSearch .item.autocomplete-sort-by.selected-option .open,
.search-properties .filters .headerSearch .item.autocomplete-sort-by.selected-option .close,
.search-properties .filters .headerSearch .item.autocomplete-price-range.selected-option .open,
.search-properties .filters .headerSearch .item.autocomplete-price-range.selected-option .close,
.search-properties .filters .headerSearch .item.autocomplete-area.selected-option .open,
.search-properties .filters .headerSearch .item.autocomplete-area.selected-option .close,
.search-properties .filters .headerSearch .item.autocomplete-year.selected-option .open,
.search-properties .filters .headerSearch .item.autocomplete-year.selected-option .close {
    filter: none;
}

.search-properties .filters .headerSearch .item.autocomplete-cities.selected-option .title-container .title,
.search-properties .filters .headerSearch .item.autocomplete-neigborhood.selected-option .title-container .title,
.search-properties .filters .headerSearch .item.autocomplete-sort-by.selected-option .title-container .title,
.search-properties .filters .headerSearch .item.autocomplete-price-range.selected-option .title-container .title,
.search-properties .filters .headerSearch .item.autocomplete-area.selected-option .title-container .title,
.search-properties .filters .headerSearch .item.autocomplete-year.selected-option .title-container .title {
    color: #ffffff;
}


.search-properties .filters .headerSearch .item.autocomplete-cities.selected-option:hover, 
.search-properties .filters .headerSearch .item.autocomplete-neigborhood.selected-option:hover,
.search-properties .filters .headerSearch .item.autocomplete-sort-by.selected-option:hover,
.search-properties .filters .headerSearch .item.autocomplete-price-range.selected-option:hover,
.search-properties .filters .headerSearch .item.autocomplete-area.selected-option:hover,
.search-properties .filters .headerSearch .item.autocomplete-year.selected-option:hover {
    background: rgba(0, 188, 212, 0.8);
}

.search-properties .filters .headerSearch .item.autocomplete-cities .title-container,
.search-properties .filters .headerSearch .item.autocomplete-neigborhood .title-container,
.search-properties .filters .headerSearch .item.autocomplete-sort-by .title-container,
.search-properties .filters .headerSearch .item.autocomplete-price-range .title-container {
    max-width: 160px;
    min-width: 160px;
}

.search-properties .filters .headerSearch .item.autocomplete-neigborhood .title-container {
    max-width: 130px;
    min-width: 130px;
}

.search-properties .filters .headerSearch .item.autocomplete-sort-by .title-container {
    max-width: 100px;
    min-width: 100px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.search-properties .filters .headerSearch .item.autocomplete-price-range .title-container {
    min-width: 135px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.search-properties .filters .headerSearch .item.text-all {
    margin-right: 0;
}

.search-properties .filters .headerSearch .item.text-all p {
    color:rgba(0, 188, 212, 1);
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    float: right;
    margin-bottom: 0;
    display: block;
}

.search-properties .filters .headerSearch .item.autocomplete-cities .title-container .title,
.search-properties .filters .headerSearch .item.autocomplete-neigborhood .title-container .title,
.search-properties .filters .headerSearch .item.autocomplete-sort-by .title-container .title,
.search-properties .filters .headerSearch .item.autocomplete-price-range .title-container .title,
.search-properties .filters .headerSearch .item.autocomplete-area .title-container .title,
.search-properties .filters .headerSearch .item.autocomplete-year .title-container .title {
    font-size: 16px;
    font-weight: 300;
    text-align: left;
    padding: 10px 0;
    margin-bottom: 0;
    margin-right: 10px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    color: #000000;
}

.search-properties .filters .headerSearch .item.autocomplete-cities .dropdown-container-location,
.search-properties .filters .headerSearch .item.autocomplete-neigborhood .dropdown-container-neighborhood,
.search-properties .filters .headerSearch .item.autocomplete-sort-by .dropdown-container-sort-by,
.search-properties .filters .headerSearch .item.autocomplete-price-range .dropdown-container-price-range,
.search-properties .filters .headerSearch .item.autocomplete-area .dropdown-container-area,
.search-properties .filters .headerSearch .item.autocomplete-year .dropdown-container-year {
    position: absolute;
    background-color: rgba(255, 255, 255, 1);
    padding: 20px;
    width: 300px;
    max-width: 300px;
    border-radius: 5px;
    z-index: 2;
    margin-top: 20px;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.30)) drop-shadow(0px 8px 12px rgba(0, 0, 0, 0.15));
    overflow-y: auto;
    fill: #ffffff;
}

.search-properties .filters .headerSearch .item.autocomplete-sort-by .dropdown-container-sort-by,
.search-properties .filters .headerSearch .item.autocomplete-price-range .dropdown-container-price-range,
.search-properties .filters .headerSearch .item.autocomplete-area .dropdown-container-area
.search-properties .filters .headerSearch .item.autocomplete-year .dropdown-container-year {
    padding: 0;
}

.search-properties .filters .headerSearch .item.autocomplete-sort-by .dropdown-container-sort-by .radio-group-sort-by {
    padding: 20px 20px 0 20px;
}

.search-properties .filters .headerSearch .item.autocomplete-price-range .dropdown-container-price-range .budget {
    padding: 30px 23px 0 23px;
    text-align: center;
    margin: 0 auto;
}

.search-properties .filters .headerSearch .item.autocomplete-price-range .dropdown-container-price-range .budget .label {
    color: #000;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 1.08px;
    text-transform: capitalize;
    margin-bottom: 20px;
}

.search-properties .filters .headerSearch .item.autocomplete-price-range .dropdown-container-price-range .budget .prices {
    color: #505050;
    font-size: 13px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    letter-spacing: 1.08px;
    text-transform: capitalize;
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
    align-items: center;
}

.search-properties .filters .headerSearch .item.autocomplete-price-range .dropdown-container-price-range .budget .change-live-price {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.search-properties .filters .headerSearch .item.autocomplete-price-range .dropdown-container-price-range .budget .change-live-price .price-view {
    width: 100px;
    font-size: 18px;
    letter-spacing: 1.0800000429153442px;
    text-align: left;
    color: rgba(0, 0, 0, 1);
    margin-bottom: 0;
}

.search-properties .filters .headerSearch .item.autocomplete-price-range .dropdown-container-price-range .budget .change-live-price .price-view input {
    width: 107px;
    font-weight: 500;
}

.search-properties .filters .headerSearch .item.autocomplete-price-range .dropdown-container-price-range .budget .change-live-price .price-view:nth-last-child(3),
.search-properties .filters .headerSearch .item.autocomplete-price-range .dropdown-container-price-range .budget .change-live-price .price-view:nth-last-child(3) input {
    width: 100;
    text-align: center;
}


.search-properties .filters .headerSearch .item.autocomplete-price-range .dropdown-container-price-range .budget .prices input,
.search-properties .filters .headerSearch .item.autocomplete-price-range .dropdown-container-price-range .budget .price-view input {
    background-color: transparent;
}

.search-properties .filters .headerSearch .item.autocomplete-price-range .dropdown-container-price-range .budget .prices .price-left,
.search-properties .filters .headerSearch .item.autocomplete-price-range .dropdown-container-price-range .budget .prices .price-center,
.search-properties .filters .headerSearch .item.autocomplete-price-range .dropdown-container-price-range .budget .prices .price-right,
.search-properties .filters .headerSearch .item.autocomplete-price-range .dropdown-container-price-range .budget .prices .price-left input,
.search-properties .filters .headerSearch .item.autocomplete-price-range .dropdown-container-price-range .budget .prices .price-center input,
.search-properties .filters .headerSearch .item.autocomplete-price-range .dropdown-container-price-range .budget .prices .price-right input {
    width: 70px;
    margin-bottom: 0;
}

.search-properties .filters .headerSearch .item.autocomplete-price-range .dropdown-container-price-range .budget .prices input {
    background-color: transparent;
}


.search-properties .filters .headerSearch .item.autocomplete-sort-by .dropdown-container-sort-by .radio-group-sort-by .sort-by-label span {
    font-size: 16px;
}

.search-properties .filters .headerSearch .item.autocomplete-cities .dropdown-container-location::-webkit-scrollbar,
.search-properties .filters .headerSearch .item.autocomplete-neigborhood .dropdown-container-neighborhood::-webkit-scrollbar,
.search-properties .filters .headerSearch .item.autocomplete-sort-by .dropdown-container-sort-by::-webkit-scrollbar {
    width: 10px !important;
    background: transparent;
}

.search-properties .filters .headerSearch .item.autocomplete-cities .dropdown-container-location::-webkit-scrollbar-track,
.search-properties .filters .headerSearch .item.autocomplete-neigborhood .dropdown-container-neighborhood::-webkit-scrollbar-track,
.search-properties .filters .headerSearch .item.autocomplete-sort-by .dropdown-container-sort-by::-webkit-scrollbar-track {
    background: #fff;
    border-radius: 12px;
}

.search-properties .filters .headerSearch .item.autocomplete-cities .dropdown-container-location::-webkit-scrollbar-thumb,
.search-properties .filters .headerSearch .item.autocomplete-neigborhood .dropdown-container-neighborhood::-webkit-scrollbar-thumb
.search-properties .filters .headerSearch .item.autocomplete-sort-by .dropdown-container-sort-by::-webkit-scrollbar-thumb {
    background-color: #0097A7;
    border-radius: 10px;
}

.search-properties .filters .clear-filter {
    color: rgba(0, 188, 212, 1);
    text-align: center;
    cursor: pointer;
    font-size: 16px;
    margin-bottom: 10px;
}

.search-properties .filters .headerSearch .item.autocomplete-cities .dropdown-container-location .search {
    float: left;
    position: relative;
    margin-top: 4px;
    background-color: rgba(244, 244, 244, 1);
    border-radius: 50px;
    width: 100%;
    height: 44px;
    cursor: pointer;
    padding: 5px 16px;
}

.search-properties .filters .headerSearch .item.autocomplete-cities .dropdown-container-location .search-icon {
    float: left;
    margin-top: 4px;
    width: 26px;
    height: 26px;
    filter: invert(49%) sepia(0%) saturate(1%) hue-rotate(100deg) brightness(97%) contrast(89%);
}

.search-properties .filters .headerSearch .item.autocomplete-cities .dropdown-container-location .search-field {
    float: left;
    padding: 16px 10px !important;
    height: 28px;
    border: none;
    background-color: transparent;
    width: 80% !important;
    margin-bottom: 10px;
}

.search-properties .filters .headerSearch .item.autocomplete-cities .dropdown-container-location .search-field::placeholder {
    font-weight: 400;
    font-size: 18px;
}

.search-properties .filters .headerSearch .item.autocomplete-cities .dropdown-container-location .search.focus-input-filed {
    outline: 2px solid #00BCD4;
}

.search-properties .filters .headerSearch .item.autocomplete-cities .dropdown-container-location .search .MuiFormControl-marginNormal {
    margin: 0
}

.search-properties .filters .headerSearch .item.autocomplete-cities .dropdown-container-location .search .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] {
    padding: 0;
    background-color: #EDEDED;
}

.search-properties .filters .headerSearch .item.autocomplete-neigborhood .dropdown-container-neighborhood .checkbox-with-label .MuiFormControlLabel-label {
    color: #000000;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.search-properties .filters .headerSearch .item.autocomplete-neigborhood .dropdown-container-neighborhood .checkbox-with-label .MuiCheckbox-root {
    color:  rgba(0, 0, 0, 0.3)
}

.search-properties .filters .headerSearch .item.autocomplete-neigborhood .dropdown-container-neighborhood .checkbox-with-label .MuiCheckbox-colorPrimary.Mui-checked {
    color: #00BCD4
}

.search-properties .filters .headerSearch .item.autocomplete-area .dropdown-container-area .area-title,
.search-properties .filters .headerSearch .item.autocomplete-year .dropdown-container-year .year-title {
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 1.08px;
    text-align: center;
    color: rgba(0, 0, 0, 1);
    padding-top: 30px;
}

.search-properties .filters .headerSearch .item.autocomplete-area .dropdown-container-area .area,
.search-properties .filters .headerSearch .item.autocomplete-year .dropdown-container-year .year {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 20px;
}

.search-properties .filters .headerSearch .item.autocomplete-area .dropdown-container-area .area input[type=number],
.search-properties .filters .headerSearch .item.autocomplete-year .dropdown-container-year .year input[type=number] {
    border: 2px solid rgba(203, 203, 203, 1);
    border-radius: 10px;
    padding: 10px;
    text-align: center;
}

.search-properties .filters .headerSearch .item.autocomplete-area .dropdown-container-area .area input[type=number]::-webkit-inner-spin-button,
.search-properties .filters .headerSearch .item.autocomplete-year .dropdown-container-year .year input[type=number]::-webkit-inner-spin-button {
    -webkit-appearance: none; 
    margin: 0; 
}

.search-properties .filters .headerSearch .item.autocomplete-area .dropdown-container-area,
.search-properties .filters .headerSearch .item.autocomplete-year .dropdown-container-year {
    padding: 0;
}

.search-properties .filters .headerSearch .item.autocomplete-area .title-container {
    min-width: 90px;
    max-width: 90px;
}

.search-properties .filters .headerSearch .item.autocomplete-year .title-container {
    min-width: 90px;
    max-width: 70px;
}

.search-properties .property-result {
    padding: 0 0 24px 0;
    background-color: #fff;
    position: relative;
    transition: margin-top 0.5s ease-in-out;
}

.search-properties .property-result.mobile {
    margin-top: 10px;
}

.search-properties .property-result.view-filters {
    margin-top: 60px;
}

.search-properties .property-result .item-right {
    float: right;
    margin-right: 30px;
    padding: 10px 19px;
    background: #E0F7FA;
    border-radius: 4px;
}

.search-properties .property-result .item-right img {
    display: inline-block;
    margin-right: 9px;
}

.search-properties .property-result .item-right img.icon {
    margin-top: 5px;
}

.search-properties .property-result .item-right .text {
    display: inline-block;
    margin-right: 9px;
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: #014245;
}

.search-properties .property-result .list-properties {
    padding: 0 12px 0 12px;
    margin-right: 5px;
    overflow: hidden;
}

.search-properties .property-result .list-properties .no-search-property {
    text-align: center;
    font-size: 20px;
    color: #014245;
    margin-top: 30px;
}

.search-properties .property-result .list-properties .col7of12 {
    padding-right: 15px;
} 

.search-properties .property-result .list-properties::-webkit-scrollbar {
    width: 6px !important;
    background: #0097A7;
}

.search-properties .property-result .list-properties::-webkit-scrollbar-track {
    background: #fff;
    border-radius: 12px;
}

.search-properties .property-result .list-properties::-webkit-scrollbar-thumb {
    background-color: #0097A7;
    border-radius: 10px;
}

.search-properties .pagination-sector {
    text-align: center;
    padding-top: 20px;
    background: #ffffff;
}

.search-properties .pagination-sector .pagination-info {
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 24px;
    color: #000000;
    opacity: 0.7;
}

.search-properties .property-result .col3of12.map {
    position: sticky;
    top: 190px;
    border-radius: 30px;
    overflow: hidden;
    transition: top 0.5s ease-in-out;
}

.search-properties .property-result .col3of12.map.visible-map {
    position: sticky;
    top: 190px;
    border-radius: 30px;
    overflow: hidden;
}

.search-properties .property-result .col3of12.map.hide-map {
    position: sticky;
    top: 110px;
    border-radius: 30px;
    overflow: hidden;
}

.search-properties .property-result .maps {
    height: 72vh;
}

.search-properties .property-result .hide-map .maps {
    height: 82vh;
}

.search-properties .property-result .maps div div {
    border-radius: 30px;
}

.gmnoprint,
.gm-style-cc {
    /* display: none !important; */
}

.search-properties .filters .headerSearch .reset-all {
    font-size: 18px;
    font-weight: 300;
    letter-spacing: 1.0800000429153442px;
    text-align: left;
    margin-right: 0;
    cursor: pointer;
} 

.search-properties .filters .headerSearch .reset-all p {
    color: rgba(0, 0, 0, 1);
    margin-bottom: 0;
    border-bottom: 2px solid rgba(0, 0, 0, 1);
} 

/* Style modal pin */
.modal-marker {
    width: 250px;
    padding: 5px;
    cursor: pointer;
}

.modal-marker img {
    width: 100%;
    height: 120px;
    object-fit: cover;
    margin-bottom: 14px;
    border-radius: 6px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
}

.modal-marker .info {
    font-size: 18px;
    color: rgba(0, 0, 0, 0.6);
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: normal;
}

.modal-marker .info .price {
    font-size: 30px;
    margin-bottom: 18px;
}

.modal-marker .info .property-type {
    margin-bottom: 12px;
}

.property-mobile-result-map-tab {
    position: fixed;
    -moz-box-pack: center;
    justify-content: center;
    z-index: 999;
    background-color: rgb(255, 255, 255);
    transform: translateX(-50%);
    left: 50%;
    bottom: 0px;
    display:flex;
    margin-bottom: calc(8px + env(safe-area-inset-bottom));
    filter: drop-shadow(0px 1px 1px rgba(0, 0, 0, 0.14)) drop-shadow(0px 2px 1px rgba(0, 0, 0, 0.12)) drop-shadow(0px 1px 3px rgba(0, 0, 0, 0.2));
    border-radius: 8px;
}

.property-mobile-result-map-tab button {
    border: 0;
    background: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: capitalize;
    color: rgb(59, 65, 68);
    padding: 8px 16px;
    font-family: Source Sans Pro;
    font-style:14px !important;
    gap:4px;
}
.property-mobile-result-map-tab button:hover{
    background-color: #f2f2f2;
    transition: all 0.3s ease-in-out;
    border-radius: 8px;
}
.property-mobile-result-map-tab .tab-map {
    border-left: 2px solid #ccc;
}

.property-mobile-result-map-tab button p {
    margin-bottom: 0 !important;
}

.search-properties .is_sold_banner_bottom_only_sold_filter {
    background: #FFF9C4; 
    width: 100%; 
    text-align: center; 
    color: #F57F17;
    font-family: 'Source Sans Pro';
    font-size: 18px;
    font-weight: 900;
    padding: 10px 0
}

.search-properties .is_sold_banner_bottom_only_sold_filter p {
    margin-bottom: 0;
}

.search-properties .property-result .part-left-filters.col2of12::-webkit-scrollbar {
    width: 10px !important;
    height: 10px !important;
    background: transparent;
}

.search-properties .property-result .part-left-filters.col2of12::-webkit-scrollbar-track {
    background: #fff;
    border-radius: 12px;
}

.search-properties .property-result .part-left-filters.col2of12::-webkit-scrollbar-thumb {
    background-color: #00BCD4;
    border-radius: 25px;
}

.search-properties .property-result .part-left-filters {
    padding: 0 1px 0 1px;
    position: sticky;
    top: 190px;
    overflow-y: scroll;
    height: 450px;
    transition: margin-top 0.5s ease-in-out;
}

.search-properties .property-result .part-left-filters.hide-filter {
    padding: 0 1px 0 1px;
    position: sticky;
    top: 190px;
    overflow-y: scroll;
    height: 450px;
    top: 110px;
}

.search-properties .property-result .part-left-filters.visible-filter {
    padding: 0 1px 0 1px;
    position: sticky;
    top: 190px;
    overflow-y: scroll;
    height: 450px;
    
}

.search-properties .property-result .part-left-filters .listItem {
    cursor: pointer;
    padding: 0;
}

.search-properties .property-result .part-left-filters .listItem .number {
    position: absolute;
    background-color: #00BCD4;
    color: #ffffff;
    top: 0;
    right: -15px;
    font-size: 11px;
    border-radius: 50px;
    width: 16px;
    height: 16px;
    text-align: center;
}

.search-properties .property-result .part-left-filters .MuiListItem-root.listItem {
    justify-content: space-between !important;
}

.search-properties .listItem .open {
    margin-top: 0;
    filter: brightness(0) saturate(100%);
}

.search-properties .listItem .close {
    transform: rotate(180deg);
    margin-top: 0;
    filter: brightness(0) saturate(100%);
}

.search-properties .property-result .part-left-filters .listItem .title-container {
    position: relative;
}

.search-properties .property-result .part-left-filters .listItem .title-container .title {
    text-align: left;
    margin-bottom: 0;
    font-size: 17px;
    font-weight: 500;
    line-height: 42px;
}

.search-properties .property-result .part-left-filters .checkbox-with-label .MuiFormControlLabel-label {
    color: #000000;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.search-properties .property-result .part-left-filters .checkbox-with-label .MuiCheckbox-root {
    color:  rgba(0, 0, 0, 0.3)
}

.search-properties .property-result .part-left-filters .checkbox-with-label .MuiCheckbox-colorPrimary.Mui-checked {
    color: #00BCD4
}

.search-properties .property-result .part-left-filters .checkbox-list {
    height: 110px;
    overflow: hidden;
    transition: height 0.5s ease-out;
    padding-left: 2px;
}

.search-properties .property-result .part-left-filters .checkbox-list.show-all-near {
    height: 91%;
}

.search-properties .property-result .part-left-filters .checkbox-list.show-all-heating {
    height: 80%;
}

.search-properties .property-result .part-left-filters .checkbox-list.show-all-property-type {
    height: 96%;
}

.search-properties .property-result .part-left-filters .checkbox-list.show-all-construction-type {
    height: 80%;
}

.search-properties .property-result .part-left-filters .checkbox-list.show-all-construction-stage {
    height: 100%;
}

.search-properties .property-result .part-left-filters .show-text {
    font-size: 15px;
    font-weight: 400;
    line-height: 33px;
    text-align: left;
    cursor: pointer;
    color: rgba(0, 188, 212, 1);
    margin-bottom: 0;
}

.gm-fullscreen-control {
    background-color: transparent !important;
    box-shadow: none !important;
}

.gm-fullscreen-control img {
    content: url("https://d3kuhob3u5bpy2.cloudfront.net/744cf534-97d7-4c88-9002-9e14a0140004.svg"); /* Задай URL на твоята икона */
    width: 55px !important;
    height: 55px !important;
}

.search-properties .property-result .part-left-filters .hr {
    border: 1.5px solid rgba(210, 210, 210, 1);
    margin: 0;
}

@media (max-width: 991px) {
    .search-properties .property-result .list-properties {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        padding: 0px;
    }

    .property-result .col4of12 {
        width: 49.333%;
    }

    .property-result .col6of12 {
        width: 100%;
    }

    .property-result .col8of12 {
        width: 50%;
    }
}

.ping {
    width: 25px;
    height: 25px;
    background-color:#012324;
    border-radius: 100%;
    background-position: top center;
    background-repeat: no-repeat;
    background-size: contain;
    position: absolute;
    -webkit-animation: pulse 2s ease-in;
    -moz-animation: pulse 2s ease-in;
    animation: pulse 2s ease-in;
    -webkit-animation-iteration-count: infinite;
    -moz-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    left:-12px;
    top:-12px;
}

@-moz-keyframes pulse {
    0% {
      -moz-transform: scale(1.1);
      opacity: 0.9;
    }
    10% {
      -moz-transform: scale(1.2);
      opacity: 0.8;
    }
    20% {
      -moz-transform: scale(1.3);
      opacity: 0.7;
    }
    30% {
      -moz-transform: scale(1.4);
      opacity: 0.6;
    }
    40% {
      -moz-transform: scale(1.5);
      opacity: 0.5;
    }
    50% {
      -moz-transform: scale(1.6);
      opacity: 0.4;
    }
    60% {
      -moz-transform: scale(1.7);
      opacity: 0.3;
    }
    70% {
      -moz-transform: scale(1.8);
      opacity: 0.2;
    }
    80% {
      -moz-transform: scale(1.9);
      opacity: 0.1;
    }
    90% {
      -moz-transform: scale(2);
      opacity: 0;
    }
    100% {
      -moz-transform: scale(2);
      opacity: 0;
    }
}

@keyframes pulse {
    0% {
      -moz-transform: scale(1.1);
      opacity: 0.9;
    }
    10% {
      transform: scale(0.2);
      -moz-transform: scale(1.2);
      opacity: 0.8;
    }
    20% {
      transform: scale(0.4);
  
      -moz-transform: scale(1.3);
      opacity: 0.7;
    }
    30% {
      transform: scale(0.6);
  
      -moz-transform: scale(1.4);
      opacity: 0.6;
    }
    40% {
      transform: scale(0.8);
  
      -moz-transform: scale(1.5);
      opacity: 0.5;
    }
    50% {
      transform: scale(1);
  
      -moz-transform: scale(1.6);
      opacity: 0.4;
    }
    60% {
      transform: scale(1.2);
  
      -moz-transform: scale(1.7);
      opacity: 0.3;
    }
    70% {
      transform: scale(1.4);
      -moz-transform: scale(1.8);
      opacity: 0.2;
    }
    80% {
      transform: scale(1.6);
  
      -moz-transform: scale(1.9);
      opacity: 0.1;
    }
    90% {
      transform: scale(1.8);
  
      -moz-transform: scale(2);
      opacity: 0;
    }
    100% {
      transform: scale(2);
      -moz-transform: scale(2);
      opacity: 0;
    }
}

@media screen and (max-width:1320px){
    .search-properties .filters {
        display: none;
    }
}

@media (max-width: 767px) {
    .property-result .col4of12 {
        width: 100%;
    }

    .property-result .col8of12 {
        width: 100%;
    }
    .search-properties .property-result .list-properties {
        padding: 0px 0px 0 0px;
      }
}

@media screen and (max-width:591px){
    .search-properties .property-result .list-properties {
        max-height: 100%;
        margin-right: 0;
    }

    .search-properties .property-result .list-properties .col6of12 {
        padding-right: 0;
    }

    .search-properties .property-result .cols{
        padding: 0;
    }
}
