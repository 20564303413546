.wrong-test { padding-top: 83px; padding-bottom: 128px; margin: 0 auto; text-align: center; }
.wrong-test img { width: 53px; height: 53px; margin-bottom: 27px; }
.wrong-test .title { margin-bottom: 8px; font-family: Lora; font-size: 48px; line-height: 52px; }
.wrong-test .title-sub { margin-bottom: 24px; font-family: Source Sans Pro; font-style: normal; font-weight: normal; font-size: 18px; line-height: 24px; }
.wrong-test .link { margin-bottom: 108px; padding: 12px 32px; cursor: pointer; padding: 15px 32px; background: #00BCD4; border-radius: 4px; font-family: Source Sans Pro; font-style: normal; font-weight: bold; font-size: 14px; line-height: 18px; text-transform: uppercase; color: #EFEFEF; }

.wrong-test .mistakes-title { margin-bottom: 24px; margin-top: 64px; font-family: 'Lora'; font-size: 24px; line-height: 26px; text-align: center; color: #000000; }

.wrong-test .test-review-pane { max-width: 1000px; margin: 0 auto; }
.wrong-test .test-review-pane .test-row { display: flex; flex-direction: row; align-items: center; justify-content: flex-start; gap: 124px; }
.wrong-test .test-review-pane .test-row .test-number { font-family: 'Source Sans Pro'; font-style: normal; font-weight: 300; font-size: 80px; line-height: 101px; text-align: center; color: #000000; }
.wrong-test .test-review-pane .test-row .test-details { flex-grow: 1; display: flex; flex-direction: column; justify-content: flex-start; text-align: left; margin-bottom: 64px; }
.wrong-test .test-review-pane .test-row .test-details h4 { font-family: 'Source Sans Pro'; font-style: normal; font-weight: 900; font-size: 18px; line-height: 22px; text-transform: uppercase; }
.wrong-test .test-review-pane .test-row .test-details .option-details.wrong-answer { width: 100%; background-color: #FFCDD2; }
.wrong-test .test-review-pane .test-row .test-details .option-details.checked { width: 100%; background-color: #C8E6C9; }



@media screen and (max-width:1032px){

    .wrong-test .test-review-pane .test-row { 
        display: flex; 
        flex-direction:column; 
        align-items: center; 
        justify-content: flex-start; 
        gap: 5px; 
        padding: 0px 10px;
    }

.wrong-test .test-review-pane .test-row .test-details { 
    width:100%;
}

}

