.seller-profile .profile-no-virtual-agent {
  /* padding: 95px 250px 270px 250px; */
  padding: 95px 95px 270px 95px;
  width: calc(100% - 64px);
  text-align: center;
}

.seller-profile .profile-no-virtual-agent .title { font-size: 32px; line-height: 32px; font-family: Lora; font-weight: 700; margin-bottom: 8px; }
.seller-profile .profile-no-virtual-agent .sub-title { margin: 0 20px; font-size: 18px; line-height: 24px; font-family: Source Sans Pro; font-weight: 400; margin-bottom: 24px; }
.seller-profile .profile-no-virtual-agent .button { height: 48px; padding: 15px 32px; background: #00BCD4; border-radius: 4px; color: #ffffff; font-size: 14px; line-height: 18px; font-family: Source Sans Pro; font-weight: 700; text-transform: uppercase; }
.seller-profile .profile-no-virtual-agent .button:hover { background: #00BCD4; }

.seller-profile .profile-no-virtual-agent .content {
  display: flex;
  margin-bottom: 24px;
  justify-content: center;
}
.seller-profile .profile-no-virtual-agent .content .box { width: 200px; padding: 24px 24px 26px 24px; margin-right: 32px; background: #EDEDED; border-radius: 4px; }
.seller-profile .profile-no-virtual-agent .content .box:last-child { margin-right: 0; }
.seller-profile .profile-no-virtual-agent .content .box .icon { margin-bottom: 8px; }
.seller-profile .profile-no-virtual-agent .content .box .text { font-size: 16px; line-height: 18px; font-family: Source Sans Pro; font-weight: 400; }

@media screen and (max-width:1224px){
  .seller-profile .profile-no-virtual-agent .content{
    display: flex !important;
    flex-direction: column !important;
    gap:20px;
    justify-content:center;
 }

  .seller-profile .profile-no-virtual-agent .content .box{
     width:100%
  }
  
}

@media screen and (max-width:1034px){
 .seller-profile .profile-no-virtual-agent { 
   margin: 64px 10px;
   padding: 32px 116px;
   background: #F8F8F8;
   border-radius: 10px;
   text-align: center;
 }
}

@media screen and (max-width:768px){
  
 .seller-profile .profile-no-virtual-agent {
   margin: 64px 10px;
   padding: 32px 0px;
 }
}

@media screen and (max-width: 600px){
 .no-photographer-container .text {
   margin-bottom: 20px;
   text-align: left;
 }
}
