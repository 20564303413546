/* Activity and gains box */
.earning-tab { padding: 24px; }
.earning-tab .activity-and-gains-box { display: flex; }
.earning-tab .activity-and-gains-box .activity-box { margin-right: 24px; padding: 16px; background: #FFFFFF; border-radius: 8px; max-width: 75%; }
.earning-tab .activity-and-gains-box .activity-box .box-header { display: flex; }
.earning-tab .activity-and-gains-box .activity-box .box-header .title { font-family: Lora; font-style: normal; font-weight: bold; font-size: 24px; line-height: 26px; }
.earning-tab .activity-and-gains-box .activity-box .box-header .panels { margin-left: auto; font-family: Source Sans Pro; font-style: normal; font-weight: bold; font-size: 14px; line-height: 18px; text-transform: uppercase; }
.earning-tab .activity-and-gains-box .activity-box .box-header .panels .tab { margin-right: 16px; cursor: pointer; }
.earning-tab .activity-and-gains-box .activity-box .box-header .panels .active-tab { background: #E0F7FA; padding: 4px 8px; cursor: default; border-radius: 4px; }
.earning-tab .activity-and-gains-box .activity-box .diagram { margin-top: 14px; filter: invert(33%) sepia(89%) saturate(2251%) hue-rotate(162deg) brightness(97%) contrast(101%); }
.earning-tab .activity-and-gains-box .activity-box .time-panels { margin-top: 16px; float: right; font-family: Source Sans Pro; font-style: normal; font-weight: normal; font-size: 16px; line-height: 18px; }
.earning-tab .activity-and-gains-box .activity-box .time-panels .tab { margin-right: 16px; cursor: pointer; }
.earning-tab .activity-and-gains-box .activity-box .time-panels .active-tab { background: #E0F7FA; padding: 4px 8px; cursor: default; border-radius: 4px; }

.earning-tab .activity-and-gains-box .gains-box { flex: 1; padding: 24px; background: #FFFFFF; border-radius: 8px; text-align: left; }
.earning-tab .activity-and-gains-box .gains-box .box-header { display: flex; }
.earning-tab .activity-and-gains-box .gains-box .box-header .icon { margin-right: 10px; filter: invert(33%) sepia(89%) saturate(2251%) hue-rotate(162deg) brightness(97%) contrast(101%); }
.earning-tab .activity-and-gains-box .gains-box .box-header .title { font-family: Lora; font-style: normal; font-weight: bold; font-size: 24px; line-height: 26px; }
.earning-tab .activity-and-gains-box .gains-box .text { background: #E0F7FA; padding: 8px; margin-top: 105px; border-radius: 4px; text-align: left; display: inline-block; }
.earning-tab .activity-and-gains-box .gains-box .text .text-amount { font-family: Source Sans Pro; font-style: normal; font-weight: bold; font-size: 36px; line-height: 45px; text-transform: uppercase; }
.earning-tab .activity-and-gains-box .gains-box .text .text-currency { font-family: Source Sans Pro; font-style: normal; font-weight: bold; font-size: 16px; line-height: 20px; text-transform: uppercase; }
/* ################### */

/* History and earn more boxes */
.earning-tab .history-and-earn-more-box { display: flex; margin-top: 24px; } 
.earning-tab .history-and-earn-more-box .history-action-box { padding: 24px; margin-right: 24px; width: 70%; overflow-y: auto; overflow-x: hidden; border-radius: 8px; background: #FFFFFF; } 
.earning-tab .history-and-earn-more-box .history-action-box .title { margin-bottom: 16px; font-family: Lora; font-style: normal; font-weight: bold; font-size: 24px; line-height: 26px; } 
.earning-tab .history-and-earn-more-box .history-action-box .history-box .history-row { display: flex; margin-bottom: 10px; } 
.earning-tab .history-and-earn-more-box .history-action-box .history-box .history-row:last-child { margin-bottom: 0px; } 
.earning-tab .history-and-earn-more-box .history-action-box .history-box .history-row .date { margin-right: 48px; font-family: Source Sans Pro; font-style: normal; font-weight: 600; font-size: 14px; line-height: 20px; } 
.earning-tab .history-and-earn-more-box .history-action-box .history-box .history-row .amount { margin-right: 24px; font-family: Source Sans Pro; font-style: normal; font-weight: 600; font-size: 14px; line-height: 20px; text-align: right; } 
.earning-tab .history-and-earn-more-box .history-action-box .history-box .history-row .text { flex: 1; font-family: Source Sans Pro; font-style: normal; font-weight: normal; font-size: 16px; line-height: 18px; } 
.earning-tab .history-and-earn-more-box .history-action-box .history-action { margin-top: -5px; text-align: right; } 
.earning-tab .history-and-earn-more-box .history-action-box .history-action .button { font-family: Source Sans Pro; font-style: normal; font-weight: bold; font-size: 14px; line-height: 18px; text-transform: uppercase; color: #0097A7; } 

.earning-tab .history-and-earn-more-box .earn-more-box { padding: 42px 92px; background: #FFFFFF; border-radius: 8px; text-align: center; } 
.earning-tab .history-and-earn-more-box .earn-more-box .title { margin-bottom: 8px; font-family: Lora; font-style: normal; font-weight: bold; font-size: 32px; line-height: 32px; } 
.earning-tab .history-and-earn-more-box .earn-more-box .text { font-family: Source Sans Pro; font-style: normal; font-weight: normal; font-size: 18px; line-height: 24px; } 
.earning-tab .history-and-earn-more-box .earn-more-box .buttons { margin-top: 24px; text-align: center; } 
.earning-tab .history-and-earn-more-box .earn-more-box .buttons .button { padding: 11px 16px; background: #00BCD4; border-radius: 4px; font-family: Source Sans Pro; font-style: normal; font-weight: bold; font-size: 14px; line-height: 18px; text-transform: uppercase; color: #FFFFFF; } 
.earning-tab .history-and-earn-more-box .earn-more-box .buttons .button.first { margin-right: 24px; } 

.earning-tab .history-cash-out-box { padding: 24px; margin-top: 24px; background: #FFFFFF; border-radius: 8px; }
.earning-tab .history-cash-out-box::-webkit-scrollbar { width: 6px !important; background: gray; }
.earning-tab .history-cash-out-box::-webkit-scrollbar-track { background : #fff; border-radius: 12px; }
.earning-tab .history-cash-out-box::-webkit-scrollbar-thumb { background-color :#000; border-radius: 10px; }
.earning-tab .history-cash-out-box .title { font-family: Lora; font-style: normal; font-weight: bold; font-size: 24px; line-height: 26px; }
.earning-tab .history-cash-out-box .info-header { margin-top: 16px; font-family: Source Sans Pro; font-style: normal; font-weight: normal; font-size: 12px; line-height: 16px; letter-spacing: 0.4px; }
.earning-tab .history-cash-out-box .info-content { margin-top: 5px; font-family: Source Sans Pro; font-style: normal; font-weight: 600; font-size: 14px; line-height: 20px; }
.earning-tab .history-cash-out-box .info-content .info-row { margin-top: 20px; }
.earning-tab .history-cash-out-box .info-content .info-row:first-child { margin-top: 0; }
.earning-tab .history-cash-out-box .info-content .download-specimen .icon { margin-top: -5px; margin-right: 20px; cursor: pointer; }