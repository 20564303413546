.profile-virtual-assistant-relationship-seller { padding: 24px 24px 16px 24px; display: flex; flex-direction: column;width: 100%; }

.profile-virtual-assistant-relationship-seller .header-content .avatar .photo { border-radius: 50%; width: 110px; height: 110px; position: relative; margin-bottom: 7px; }
.profile-virtual-assistant-relationship-seller .header-content .avatar .photo .icon { filter: invert(44%) sepia(55%) saturate(6704%) hue-rotate(163deg) brightness(99%) contrast(101%); position: absolute; bottom: 0; right: 0; }
.profile-virtual-assistant-relationship-seller .header-content .avatar .location { display: flex; align-items: center; justify-content: center; }
.profile-virtual-assistant-relationship-seller .header-content .avatar .location .icon { margin-right: 4px; }
.profile-virtual-assistant-relationship-seller .header-content .avatar .location .text { font-size: 14px; line-height: 16px; font-family: Roboto; font-weight: 400; margin-top: 3px; }

.profile-virtual-assistant-relationship-seller .header-content .user-details-section { margin-left: 16px; float: left; text-align: left;display: flex;flex-direction: column; justify-content: flex-start; align-items: flex-start;}
.profile-virtual-assistant-relationship-seller .header-content .user-details-section .title { font-size: 48px; line-height: 52px; margin-bottom: 16px; font-family: Lora; font-weight: 700; }

.profile-virtual-assistant-relationship-seller .header-content .user-details-section .rating { display: flex; }
.profile-virtual-assistant-relationship-seller .header-content .user-details-section .rating .title { font-size: 18px; line-height: 22px; font-weight: 900; font-family: Source Sans Pro; margin-right: 8px; text-transform: uppercase; margin-bottom: 0; }
.profile-virtual-assistant-relationship-seller .header-content .user-details-section .rating .star { width: 16px; height: 16px; margin-right: 3px; filter: invert(44%) sepia(55%) saturate(6704%) hue-rotate(163deg) brightness(99%) contrast(101%); }
.profile-virtual-assistant-relationship-seller .header-content .user-details-section .rating .star:last-child { margin-right: 0; }
.profile-virtual-assistant-relationship-seller .header-content .user-details-section .rating .result { font-size: 18px; line-height: 24px; font-weight: 400; font-family: Source Sans Pro; margin-left: 8px; }

.profile-virtual-assistant-relationship-seller .header-content .user-details-section .additional { margin-top: 14px; display: flex; }
.profile-virtual-assistant-relationship-seller .header-content .user-details-section .additional .first-half .title { color: #656565; font-size: 16px; line-height: 19px; font-weight: 400; font-family: Roboto; margin-bottom: 6px; }
.profile-virtual-assistant-relationship-seller .header-content .user-details-section .additional .block { background: #FFFFFF; border-radius: 4px; padding: 16px; }
.profile-virtual-assistant-relationship-seller .header-content .user-details-section .additional .block .icon { width: 20px; height: 20px; color: #000000; margin-right: 11px; }
.profile-virtual-assistant-relationship-seller .header-content .user-details-section .additional .block .text { font-size: 18px; line-height: 24px; font-family: Source Sans Pro; font-weight: 700; flex: none; }
.profile-virtual-assistant-relationship-seller .header-content .user-details-section .additional .second-half { margin-left: 24px; }
.profile-virtual-assistant-relationship-seller .header-content .user-details-section .additional .second-half .title-content { display: flex; }
.profile-virtual-assistant-relationship-seller .header-content .user-details-section .additional .second-half .title-content .title { color: #656565; font-size: 16px; line-height: 19px; font-weight: 400; font-family: Roboto; margin-bottom: 6px; }
.profile-virtual-assistant-relationship-seller .header-content .user-details-section .additional .second-half .title-content .icon { width: 20px; height: 20px; color: #000000; margin-left: 11px; margin-top: -1px; }

.profile-virtual-assistant-relationship-seller .meeting-box { padding: 20px 16px 16px 16px; background: #FFFFFF; border-radius: 4px; text-align: center; }
.profile-virtual-assistant-relationship-seller .meeting-box .title-box { display: flex; justify-content: center; align-items: center; margin-bottom: 15px; }
.profile-virtual-assistant-relationship-seller .meeting-box .title-box .icon { margin-right: 8px; }
.profile-virtual-assistant-relationship-seller .meeting-box .title-box .title { font-size: 18px; line-height: 22px; font-family: Source Sans Pro; font-weight: 900; text-transform: uppercase; }
.profile-virtual-assistant-relationship-seller .meeting-box .button { height: 55px; padding: 19px 48px; border-radius: 4px; background: #00BCD4; color: #fff; font-size: 14px; line-height: 18px; font-family: Source Sans Pro; font-weight: 700; text-transform: uppercase; }

.profile-virtual-assistant-relationship-seller .meeting-box-with-history { padding: 16px 16px 24px 16px; background: #FFFFFF; border-radius: 4px; }
.profile-virtual-assistant-relationship-seller .meeting-box-with-history .text-with-icon { display: flex; align-items: center; margin-bottom: 16px; }
.profile-virtual-assistant-relationship-seller .meeting-box-with-history .text-with-icon:first-child { margin-top: 3px; }
.profile-virtual-assistant-relationship-seller .meeting-box-with-history .text-with-icon .icon { margin-right: 10px; margin-top: -2px; }
.profile-virtual-assistant-relationship-seller .meeting-box-with-history .text-with-icon .text { font-size: 18px; line-height: 24px; font-family: Source Sans Pro; font-weight: 400; }
.profile-virtual-assistant-relationship-seller .meeting-box-with-history .button-change { height: 40px; padding: 11px 16px; border-radius: 4px; background: #00BCD4; color: #fff; margin-bottom: 11px; font-size: 14px; line-height: 18px; font-family: Roboto; font-weight: 400; text-transform: uppercase; }
.profile-virtual-assistant-relationship-seller .meeting-box-with-history .button-cancel { height: 40px; width: 100px; padding: 8px; color: #0097A7; font-size: 18px; line-height: 18px; font-family: Source Sans Pro; font-weight: 700; text-transform: uppercase; }
.profile-virtual-assistant-relationship-seller .meeting-box-with-history .button-cancel:hover { background: #fff; }

.profile-virtual-assistant-relationship-seller .chat-area { padding: 16px; background: #EDEDED; border-radius: 12px; position: relative; overflow-y: auto; overflow-x: hidden; }

.profile-virtual-assistant-relationship-seller .chat-area .chat-box { background: #fff; padding: 15px 13px; border-radius: 8px; }
.profile-virtual-assistant-relationship-seller .chat-area .chat-box .field { width: 100%; margin-top: 60px; }
.profile-virtual-assistant-relationship-seller .chat-area .chat-box .field .button { padding: 15px 32px; height: 55px; width: 140px; background-color: #00BCD4; border-radius: 0px 12px 12px 0px; color: #FFFFFF; text-transform: uppercase; font-weight: 700; font-size: 14px; line-height: 18px; font-family: Source Sans Pro; }

.profile-virtual-assistant-relationship-seller .chat-area .chat-box .chat-blob { padding: 13px 15px; text-align: left; background: #F2FEFF; border-radius: 12px 12px 12px 0px; width: 350px; margin-bottom: 16px; }
.profile-virtual-assistant-relationship-seller .chat-area .chat-box .chat-blob.waiting { background: #FAF4F1; }
.profile-virtual-assistant-relationship-seller .chat-area .chat-box .chat-blob.send { background: #9D9D9D; }
.profile-virtual-assistant-relationship-seller .chat-area .chat-box .chat-blob .blob-header { display: flex; align-items: center; margin-bottom: 8px; }
.profile-virtual-assistant-relationship-seller .chat-area .chat-box .chat-blob .blob-header .avatar { width: 24px; height: 24px; border-radius: 50%; }
.profile-virtual-assistant-relationship-seller .chat-area .chat-box .chat-blob .blob-header .title { font-size: 14px; line-height: 18px; font-weight: 700; font-family: Source Sans Pro; margin-left: 10px; text-transform: uppercase; }
.profile-virtual-assistant-relationship-seller .chat-area .chat-box .chat-blob .blob-header .hour { font-size: 12px; line-height: 12px; font-family: Source Sans Pro; font-weight: 400; margin-left: 10px; }
.profile-virtual-assistant-relationship-seller .chat-area .chat-box .chat-blob .text { font-size: 16px; line-height: 18px; font-family: Source Sans Pro; font-weight: 400; }

.profile-virtual-assistant-relationship-seller .chat-area .chat-notifications { background: #fff; padding: 15px 10px; border-radius: 8px; overflow-y: auto; height: 457px; }
.profile-virtual-assistant-relationship-seller .chat-area .chat-notifications .notification-blob { text-align: left; padding: 24px 16px 16px 16px; background: #F2FEFF; border-radius: 12px 12px 12px 0px; margin-bottom: 8px; position: relative; }
.profile-virtual-assistant-relationship-seller .chat-area .chat-notifications .notification-blob .notification-blob-header { display: flex; align-items: center; margin-bottom: 8px; }
.profile-virtual-assistant-relationship-seller .chat-area .chat-notifications .notification-blob .notification-blob-header .icon { position: absolute; top: 0; margin-top: -6px; margin-left: 6px; }
.profile-virtual-assistant-relationship-seller .chat-area .chat-notifications .notification-blob .notification-blob-header .avatar { width: 24px; height: 24px; border-radius: 50%; }
.profile-virtual-assistant-relationship-seller .chat-area .chat-notifications .notification-blob .notification-blob-header .title { font-size: 14px; line-height: 18px; margin-left: 10px; font-family: Source Sans Pro; font-weight: 700; text-transform: uppercase; }
.profile-virtual-assistant-relationship-seller .chat-area .chat-notifications .notification-blob .notification-blob-header .hour { font-size: 12px; line-height: 12px; font-family: Source Sans Pro; font-weight: 400; margin-left: 5px; }
.profile-virtual-assistant-relationship-seller .chat-area .chat-notifications .notification-blob .text { font-size: 12px; line-height: 16px; letter-spacing: 0.4px; font-family: Source Sans Pro; font-weight: 400; }

/* Scroll */
.profile-virtual-assistant-relationship-seller .chat-area .chat-box::-webkit-scrollbar, .profile-virtual-assistant-relationship-seller .chat-area .chat-notifications::-webkit-scrollbar, .MuiDialog-paper::-webkit-scrollbar { width: 6px !important; background: gray; }
.profile-virtual-assistant-relationship-seller .chat-area .chat-box::-webkit-scrollbar-track, .profile-virtual-assistant-relationship-seller .chat-area .chat-notifications::-webkit-scrollbar-track, .MuiDialog-paper::-webkit-scrollbar-track { background : #fff; border-radius: 12px; }
.profile-virtual-assistant-relationship-seller .chat-area .chat-box::-webkit-scrollbar-thumb, .profile-virtual-assistant-relationship-seller .chat-area .chat-notifications::-webkit-scrollbar-thumb, .MuiDialog-paper::-webkit-scrollbar-thumb { background-color :#000; border-radius: 10px; }

.profile-virtual-assistant-relationship-seller .history-content { margin-top: 32px; }
.profile-virtual-assistant-relationship-seller .history-content .box { display: flex; flex-direction: column; align-items: flex-start; padding: 24px; background: #FFFFFF; border-radius: 12px; }
.profile-virtual-assistant-relationship-seller .history-content .box .box-row { width: 100%; margin-bottom: 18px; display: inline-block; }
.profile-virtual-assistant-relationship-seller .history-content .box .box-row:last-child { margin-bottom: 0; }
.profile-virtual-assistant-relationship-seller .history-content .box .box-row .date { width: 15%; float: left; font-family: Source Sans Pro; font-style: normal; font-weight: normal; font-size: 12px; line-height: 12px; color: #000000; }
.profile-virtual-assistant-relationship-seller .history-content .box .box-row .small-icon { width: 20px; height: 18px; margin-right: 10px; float: left; }
.profile-virtual-assistant-relationship-seller .history-content .box .box-row .text { margin-right: 8px; float: left; font-family: Source Sans Pro; font-style: normal; font-weight: normal; font-size: 18px; line-height: 24px; color: #000000; }
.profile-virtual-assistant-relationship-seller .history-content .box .box-row .ad-text { font-size: 18px; line-height: 21px; font-weight: 400; margin: 0px 4px; }
.profile-virtual-assistant-relationship-seller .history-content .box .box-row .ad-number { font-family: Roboto; font-style: normal; font-weight: bold; font-size: 18px; line-height: 21px; text-decoration-line: underline; color: #000000; }