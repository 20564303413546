.review {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 24px;
    background: #ffffff;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px rgba(0, 0, 0, 0.12), 0px 1px 3px rgba(0, 0, 0, 0.2);
    border-radius: 12px;
    height: fit-content;
    width: 300px;
    margin: 0 20px;
    margin-bottom: 20px;
}

.review-top {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.review-top>img {
    margin-right: 8px;
}

.review-top>div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}

.review-top>div>h5 {
    margin: 0;
    padding: 0;

    font-style: normal;
    font-weight: 900;
    font-size: 18px;
    line-height: 22px;
    text-align: right;
    text-transform: uppercase;
    color: #000000;
}

.review-top>div>p {
    margin: 0;
    padding: 0;

    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    text-align: right;
    letter-spacing: 0.4px;
    color: #000000;
}

.review .rating {
    margin: 8px 0 12px 0;
}

.review .rating>img {
    margin-right: 7.5px;
}

.review .review-content {
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 24px;
    color: #000000;
}

@media screen and  (max-width: 500px){
    .review {
        margin: 0 auto 30px auto;
        width: 300px;
    }
}