.registration-successful {
    position: relative;
    text-align: center;
    margin-top: 64px;
}

.registration-successful .title {
    font-size: 48px;
    line-height: 52px;
    font-weight: 600;
    margin-bottom: 8px;
}

.registration-successful .text {
    font-size: 18px;
    line-height: 24px;
    font-weight: 400;
    margin-bottom: 24px;
}

.registration-successful .icon {
    margin-bottom: 35px;
}

.registration-successful .button {
    position: relative !important;
    padding: 15px 32px !important;
    background: #00BCD4 !important;
    border-radius: 4px !important;
    color: #fff !important;
    font-size: 14px !important;
    line-height: 18px !important;
    font-weight: 700 !important;
    text-transform: uppercase !important;
}

.registration-successful .button:hover {
    background: #00BCD4 !important;
}

.registration-successful .image {
    margin-top: -270px;
    margin-bottom: 20px;
    background: linear-gradient(180deg, rgba(204, 243, 255, 0) 0%, #CCF3FF 100%);
}

@media screen and (max-width:1312px){
    .account-verification .image {
        margin-top: -100px;
        margin-bottom: 20px;
        background: linear-gradient(180deg, rgba(204, 243, 255, 0) 0%, #CCF3FF 100%);
    }

}

@media screen and (max-width:954px){
    .account-verification {
        position: relative;
        text-align: center;
        margin: 140px 20px 128px 20px !important;
    }
}

@media screen and (max-width:600px){
    .account-verification {
        position: relative;
        text-align: center;
        margin: 50px 20px 128px 20px !important;
    }
}

@media screen and (max-width:576px){
    .account-verification .title {
        font-size: 40px;
    }
}

@media screen and (max-width:518px){
    .account-verification .image {
        margin-top: -50px;
        margin-bottom: 20px;
    }
}