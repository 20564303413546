.lawyer-feature-item {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    background: #f8f8f8;
    border-radius: 8px;
    padding: 24px;
    > img {
        margin-bottom: 16px;
        width: 76px;
        height: 77px;
    }
    > h3 {
        font-family: Source Sans Pro;
        font-style: normal;
        font-weight: bold;
        font-size: 20px;
        line-height: 24px;
        text-transform: uppercase;
        color: #000000;
        margin-bottom: 16px;
    }
    > p {
        font-family: Source Sans Pro;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 18px;
        text-transform: none;
        color: #5d5d5d;
        margin-bottom: 16px;
    }
    >.service_price {
        font-family: Source Sans Pro;
        font-style: normal;
        font-weight: bold;
        font-size: 48px;
        line-height: 44px;
        color: #000000;
        background: #FFFFFF;
        border-radius: 4px;
        padding: 8px;
        > div {
            font-family: Source Sans Pro;
            font-style: normal;
            font-weight: 900;
            font-size: 22px;
            line-height: 22px;
            text-transform: uppercase;
            color: #000000;
        }
    }
}