.blog-page .loader {
    margin: 20px auto;
    width: fit-content;
    color: #9e9e9e;
    font-size: 20px;
    text-align: center;
}

.blog-page .headerContent {
    position: relative;
    background-size: cover;
    padding: 20px 50px;
    background-position: center;
    text-align: left;
    background-repeat: no-repeat;
    background-image: url("https://d3kuhob3u5bpy2.cloudfront.net/ecbd178d-544e-4fab-ad25-d72157b9a1b3.svg");
    height: 610px;
}

.blog-page .headerContent .col {
    padding: 0;
}

.blog-page .headerContent .text-content {
    padding-top: 90px;
}

.blog-page .headerContent .title {
    font-size: 45px;
    font-weight: 700;
    text-align: left;
    color: rgba(0, 0, 0, 1);
    margin-bottom: 25px;
}

.blog-page .headerContent .title-sub {
    font-size: 30px;
    font-weight: 400;
    text-align: left;
    margin-bottom: 50px;
}

.blog-page .headerContent .col5of12 {
    text-align: center;
}

.blog-page .headerContent .col5of12 img {
    width: 68%;
}

body.manrope-class .blog-page .headerContent .category-filters .category-filter {
    font-family: 'Manrope' !important;
}

body.poppins-class .blog-page .headerContent .category-filters .category-filter {
    font-family: 'Poppins' !important;
}

.blog-page .headerContent .category-filters .category-filter {
    font-size: 18px;
    font-weight: 500;
    line-height: 37.5px;
    text-align: left;
    border-radius: 50px;
    padding: 20px;  
    color: rgba(1, 35, 36, 1);
    background: #ffffff;
    margin-right: 21px;
    text-transform: capitalize;
    margin-bottom: 10px;
    border: 1.5px solid rgba(0, 188, 212, 1);
}

.blog-page .headerContent .category-filters .category-filter.selected {
    color: #ffffff;
    background: #00BCD4;
}

.blog-page .headerContent .select-category {
    font-size: 25px;
    font-weight: 400;
    line-height: 80px;
    letter-spacing: -0.04em;
    text-align: left;
    margin-bottom: 0;
}

.category-filters-popover {
    padding: 10px;
    display: flex;
    gap: 10px;
    align-items: center;
    flex-wrap: wrap;
    max-width: 320px;
}

.category-filters-popover .category-filter {
    font-size: 14px;
    font-weight: 500;
    line-height: 35px;
    text-align: left;
    border-radius: 50px;
    padding: 0;  
    color: rgba(1, 35, 36, 1);
    background: #ffffff;
    margin-right: -5px;
    text-transform: capitalize;
    border: 1.5px solid rgba(0, 188, 212, 1);
}

.category-filters-popover .category-filter:hover {
    background-color: #ffffff;
}

.category-filters-popover .category-filter.selected {
    color: #ffffff;
    background: #00BCD4;
}

.blog-page .featured-blog {
    margin-left: 20px;
    margin-bottom: 50px;
}

.blog-page .featured-blog .title {
    font-size: 40px;
    font-weight: 600;
    line-height: 75px;
    text-align: center;
    margin-bottom: 15px;
}

.blog-page .featured-blog .premium-slider {
    border: 1px solid rgba(232, 232, 234, 1);
    background: rgba(255, 255, 255, 1);
    border-radius: 12px;
    padding: 12px;
}

.blog-page .featured-blog .premium-slider .featured-blog-image img {
    border-radius: 6px;
    margin-bottom: 15px;
    width: 100%;
    cursor: pointer;
    height: 200px;
}

.blog-page .featured-blog .premium_listings.swiper-slide {
    margin-right: 20px !important;
    width: 320px !important;
    height: 450px;
}
  
.blog-page .featured-blog .premium_listings_new.swiper-slide {
    margin-right: 16px !important;
}

.blog-page .featured-blog .categories {
    text-align: left;
    margin-bottom: 15px;
}

.blog-page .featured-blog .categories .category-filter {
    font-size: 14px;
    text-align: left;
    font-weight: 500;
    line-height: 20px;
    border-radius: 50px;
    padding: 0;  
    color: #ffffff;
    background:rgba(1, 35, 36, 1);
    text-transform: capitalize;
}

.blog-page .featured-blog .featured-blog-content .featured-blog-title {
    font-size: 24px;
    font-weight: 600;
    line-height: 28px;
    text-align: left;
    cursor: pointer;
    color: rgba(24, 26, 42, 1);
}

.blog-page .featured-blog .featured-blog-content .featured-blog-date {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: right;
    color: rgba(151, 152, 159, 1);
}

@media screen and (max-width: 1000px) {
    .blog-page .headerContent .col5of12 img {
        width: 100%;
    }
}

@media screen and (max-width: 700px) {
    .blog-page .headerContent .col7of12,
    .blog-page .headerContent .col5of12  {
        width: 100%;
        float: none;
    }

    .blog-page .headerContent {
        padding: 20px 10px;
    }

    .blog-page .headerContent .text-content {
        padding-top: 0;
    }

    .blog-page .headerContent .title {
        font-size: 34px;
    }

    .blog-page .headerContent .title-sub {
        font-size: 20px;
        margin-bottom: 20px;
    }

    .blog-page .headerContent .col5of12 img {
        width: 50%;
    }

    .blog-page .headerContent {
        height: 900px;
    }

    .blog-page .headerContent .select-category {
        font-size: 20px;
    }

    .blog-page .headerContent .category-filters {
        margin-bottom: 20px;
        text-align: left;
    }

    .blog-page .headerContent .category-filters .category-filter {
        font-size: 12px;
        padding: 15px 9px;
        margin-right: 5px;
    }

    .blog-page .swiper.swiper-initialized.swiper-horizontal {
        margin-left: 0;
    }
}

@media screen and (max-width: 500px) {
    .blog-page .headerContent .col5of12 img {
        width: 100%;
    }

    .blog-page .featured-blog .premium_listings.swiper-slide {
        width: 285px !important;
        margin-right: 15px !important;
    }

    .blog-page .featured-blog {
        margin-left: 6px;
    }

    .blog-page .featured-blog .title {
        font-size: 35px;
    }

    .blog-page .featured-blog .featured-blog-content .featured-blog-title {
        font-size: 22px;
    }

    .blog-page .featured-blog {
        margin-bottom: 30px;
    }
}