.buyer-account-box .header .photo { float: left; width: 75px; height: 75px; border-radius: 50%; margin-right: 16px; }
.buyer-account-box .header .name { font-size: 18px; line-height: 22px; font-weight: 500; margin-left: 12px; }
.buyer-account-box .header .type { font-size: 14px; line-height: 16px; font-weight: 500; margin-top: 4px; margin-left: 12px; }

.buyer-account-box .header .service-need { margin-top: 8px; font-family: Source Sans Pro; font-style: normal; font-weight: normal; font-size: 16px; line-height: 18px; color: #8C8C8C; }

.buyer-account-box .header .services { display: flex; margin-top: 4px; padding: 10px 18px; }
.buyer-account-box .header .services .icon { margin-right: 12px; width: 24px; height: 24px; }
.buyer-account-box .header .services .icon:last-child { margin-right: 0px; }
.buyer-account-box .header .services .icon.disabled { filter: invert(70%) sepia(53%) saturate(0%) hue-rotate(175deg) brightness(107%) contrast(98%); }

.buyer-account-box .property-listing { margin-top: 15px; }
.buyer-account-box .property-listing .property-row { display: flex; margin-bottom: 8px; }
.buyer-account-box .property-listing .property-row .icon { margin-right: 8px; width: 20px; height: 20px; }
.buyer-account-box .property-listing .property-row .text { font-size: 14px; line-height: 16px; margin-top: 4px;  float: left; }