.vip-lawyer-contract { margin: 43px 24px 32px 24px; }
.vip-lawyer-contract .title { margin-top: 24px; font-size: 36px; line-height: 42px; }
.vip-lawyer-contract .sub-title { margin: 8px 120px 24px 120px; font-size: 18px; line-height: 21px; font-weight: 400; }
.vip-lawyer-contract .contract-body { padding: 16px; background: #FFFFFF; border-radius: 12px; text-align: left; height: 360px; overflow: auto; }
.vip-lawyer-contract .contract-body .title { margin: 0 0 32px 0; font-size: 36px; line-height: 32px; }
.vip-lawyer-contract .contract-body .common-title { margin-bottom: 10px; font-size: 24px; line-height: 32px; }
.vip-lawyer-contract .contract-body .text { margin-bottom: 32px; font-size: 18px; line-height: 32px; }
/* .vip-lawyer-contract .buttons-group { margin-top: 16px; }
.vip-lawyer-contract .buttons-group .button-sign { padding: 15px 32px; margin-right: 45px; border-radius: 4px; text-transform: uppercase; background: #00BCD4; color: #fff; font-size: 14px; line-height: 18px; font-family: Source Sans Pro; font-weight: 700; }
.vip-lawyer-contract .buttons-group .button-upload { padding: 15px 32px; margin-right: 30px; border-radius: 4px; text-transform: uppercase; background: #00BCD4; color: #fff; font-size: 14px; line-height: 18px; font-family: Source Sans Pro; font-weight: 700; }
.vip-lawyer-contract .buttons-group .button-download { padding: 15px 32px; border-radius: 4px; text-transform: uppercase; border: 2px solid #00BCD4; color: #000; font-size: 14px; line-height: 18px; font-family: Source Sans Pro; font-weight: 700; }
.vip-lawyer-contract .buttons-group .icon { margin-top: 33px; } */


/* Scroll */
.contract .contract-body::-webkit-scrollbar { width: 6px !important; background: gray; }
.contract .contract-body::-webkit-scrollbar-track { background : #fff; border-radius: 12px; }
.contract .contract-body::-webkit-scrollbar-thumb { background-color :#000; border-radius: 10px; }