.progress-main-container {
    display: flex;
    z-index: 1400;
    flex-direction: column;
    position: fixed;
    width: 100%;
    height: 100vh;
    top: 0px;
    align-items: center;
    justify-content: center;
    color: #0097A7;
    padding-top: 250px;
}
.progress-main-container .coverer::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.8);
    z-index: 1;
    border-radius: 8px;
    z-index: 1412;

  }
.progress-main-container .progress-bar-container{
    display: flex;
    flex-direction: column;
    width: 30%;
    z-index: 1450;
}

.progress-main-container span {
    margin-top: 20px;
    text-align: center;
}

@media screen and (max-width: 600px) {
    .progress-main-container .progress-bar-container{
        width: 50%;
    }
}

@media screen and (max-width: 527px) {
}

@media screen and (max-width: 400px) {
    .progress-main-container .progress-bar-container{
        width: 70%;
    }
}

@media screen and (max-width: 350px) {
}
