.vipoffer-premium {
    padding: 24px;
    background: #ffffff;
    border-radius: 8px;
    width: 100%;
}

.vipoffer-premium .content {
    padding: 16px;
}

.vipoffer-premium .content .column {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    text-align: left;
}

.vipoffer-premium .content .title {
    font-family: 'Lora';
    font-style: normal;
    font-weight: 600;
    font-size: 48px;
    line-height: 52px;
    color: #000000;
    margin-bottom: 16px;
}

.vipoffer-premium .content .label {
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 900;
    font-size: 18px;
    line-height: 22px;
    color: #000000;
    text-transform: uppercase;
    margin-bottom: 8px;
}

.vipoffer-premium .content .label img {
    margin-left: 5px;
    filter: invert(67%) sepia(99%) saturate(4399%) hue-rotate(152deg) brightness(103%) contrast(103%);
    height: 20px;
}

.vipoffer-premium .content .impressions {
    width: 148px;
    padding: 8px;
    background: #E0F7FA;
    border-radius: 4px;
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 700;
    font-size: 48px;
    line-height: 52px;
    color: #000000;
    text-align: center;
    margin-bottom: 24px;
}

.vipoffer-premium .content .actions {
    display: flex;
    align-items: center;
}

.vipoffer-premium .content .actions .btn-reload {
    padding: 15px 32px;
    border: 2px solid #00BCD4;
    background: #00BCD4;
    border-radius: 4px;
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
    text-transform: uppercase;
    color: #ffffff;
    margin-right: 32px;
}

.vipoffer-premium .content .actions .btn-reward {
    padding: 15px 32px;
    border: 2px solid #00BCD4;
    background: #ffffff;
    border-radius: 4px;
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
    text-transform: uppercase;
    color: #000000;
}

.vipoffer-premium .content .mt-34 {
    margin-top: 34px;
}

.vipoffer-premium .content .mt-54 {
    margin-top: 54px;
}

.vipoffer-premium .content .mt-64 {
    margin-top: 64px;
}

.vipoffer-premium .content .mt-80 {
    margin-top: 80px;
}

.vipoffer-premium .content .mt-80 .text {
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    color: #000000;
}

.vipoffer-premium .content .mt-80 .separator {
    margin: 4px 0;
    border: 1px solid #DBDBDB;
    width: 100%;
}