.edit-profile-lawyer {
 margin: 0 50px;
}

.edit-profile-lawyer .header-title {
    margin-top: 64px;
}

.edit-profile-lawyer .header-title .title {
    font-family: Lora;
    margin-bottom: 12px;
    font-size: 48px;
    line-height: 52px;
    text-align: center;
}

.edit-profile-lawyer .header-common {
    text-align: center;
}

.edit-profile-lawyer .header-common .paragraph {
    margin-bottom: 24px;
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 24px;
    color: #000000;
}

.edit-profile-lawyer .button-save {
    width: 115px;
    padding: 15px 32px;
    background-color: #00BCD4;
    border-radius: 4px;
    color: #FFFFFF;
    margin-bottom: 32px;
    text-transform: uppercase;
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 18px;
}

.edit-profile-lawyer .header-common .button-view-profile {
    margin-left: 32px;
    padding: 15px 32px;
    border: 1px solid #00BCD4;
    border-radius: 4px;
    color: #000000;
    margin-bottom: 32px;
    text-transform: uppercase;
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 18px;
}

.edit-profile-lawyer .header-content {
    display: flex;
    margin-bottom: 64px;
}

.edit-profile-lawyer .header-content .avatar {
    flex-shrink: 0;
    width: 178px;
    height: 178px;
    background: #000000;
    border-radius: 50%;
    float: left;
    position: relative;
    margin-bottom: 10px;
}

.edit-profile-lawyer .header-content .avatar:hover span {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 999;
    cursor: pointer;
}

.edit-profile-lawyer .header-content .avatar:hover span::before{
    position: absolute;
    content: '';
    height: 50px;
    width: 2px;
    background-color: #fff;
    transform: rotate(45deg);
    left: 50%;
    top: 38%;
}

.edit-profile-lawyer .header-content .avatar:hover span::after{
    position: absolute;
    content: '';
    height: 50px;
    width: 2px;
    background-color: #fff;
    transform: rotate(-45deg);
    left: 50%;
    top: 38%;
}

.edit-profile-lawyer .header-content .avatar .photo {
    width: 178px;
    height: 178px;
    border-radius: 50%;
    object-fit: cover;
}

.edit-profile-lawyer .header-content .avatar:hover .photo {
    opacity: 0.3;
}

.edit-profile-lawyer .header-content .avatar:hover .upload-section {
    opacity: 1;
}

.edit-profile-lawyer .header-content .avatar .upload-section {
    position: absolute;
    top: 40%;
    left: 35%;
    display: none;
}

.edit-profile-lawyer .header-content .avatar:hover .upload-section {
    display: block;
}

.edit-profile-lawyer .header-content .user-details {
    margin-left: 24px;
    float: left;
}

.edit-profile-lawyer .header-content .user-details .user-name {
    font-family: Lora;
    font-size: 48px;
    line-height: 52px;
    margin-bottom: 8px;
}

.edit-profile-lawyer .header-content .user-details .rating {
    display: flex;
    align-items: flex-end;
}

.edit-profile-lawyer .header-content .user-details .rating .title {
    margin-right: 8px;
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: 900;
    font-size: 18px;
    line-height: 22px;
    text-transform: uppercase;
}

.edit-profile-lawyer .header-content .user-details .rating .star {
    width: 16px;
    height: 16px;
    margin-top: 1px;
    filter: invert(35%) sepia(81%) saturate(1357%) hue-rotate(156deg) brightness(94%) contrast(101%)
}

.edit-profile-lawyer .header-content .user-details .rating .result {
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 24px;
    margin-left: 8px;
}

.edit-profile-lawyer .header-content .user-details .additional {
    margin-top: 17px;
    display: flex;
}

.edit-profile-lawyer .header-content .user-details .additional .first-half .title {
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 18px;
    color: #656565;
    margin-bottom: 9px;
}

.edit-profile-lawyer .header-content .user-details .additional .block {
    display: flex;
    background: #F4F4F4;
    border-radius: 4px;
}

.edit-profile-lawyer .header-content .user-details .additional .block .icon {
    width: 18px;
    height: 20px;
    margin: 19px 11px 21px 19px;
    color: #000000;
}

.edit-profile-lawyer .header-content .user-details .additional .block .text {
    margin: 16px 16px 16px 0px;
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 24px;
    text-transform: uppercase;
    color: #000000;
    flex: none;
}

.edit-profile-lawyer .header-content .user-details .additional .second-half {
    margin-left: 32px;
}

.edit-profile-lawyer .header-content .user-details .additional .second-half .title-content {
    display: flex;
}

.edit-profile-lawyer .header-content .user-details .additional .second-half .title-content .title {
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 18px;
    color: #656565;
    margin-bottom: 9px;
}

.edit-profile-lawyer .header-content .user-details .additional .second-half .title-content .icon {
    width: 20px;
    height: 20px;
    color: #000000;
    margin-left: 11px;
    margin-top: -1px;
}

.edit-profile-lawyer .header-content .about-info {
    margin-left: 32px;
}

.edit-profile-lawyer .header-content .about-info .title {
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.4px;
    color: #000000;
    margin-bottom: 4px;
}

.edit-profile-lawyer .header-content .about-info .box {
    width: 486px;
    height: 148px;
    background: #F8F8F8;
    border-radius: 12px;
    color: #9D9D9D;
    padding: 16px;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px rgba(0, 0, 0, 0.12), 0px 1px 3px rgba(0, 0, 0, 0.2);
    overflow-y: auto;
}

.edit-profile-lawyer .header-content .price-info {
    margin-left: 32px;
}

.edit-profile-lawyer .header-content .price-info .title {
    font-size: 12px;
    line-height: 14px;
    margin-bottom: 4px;
}

.edit-profile-lawyer .header-content .price-info .box {
    width: 150px;
    height: 148px;
    background: #F8F8F8;
    border-radius: 12px;
    color: #9D9D9D;
    padding: 16px 12px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.edit-profile-lawyer .header-content .price-info .MuiInput-input {
    text-align: center;
}

.edit-profile-lawyer .services {
    margin: 64px 0 24px 0;
    text-align: center;
}

.edit-profile-lawyer .services .title {
    font-size: 48px;
    line-height: 52px;
    margin-bottom: 32px;
    font-family: Lora;
}

.edit-profile-lawyer .services .service-box {
    background: #F4F4F4;
    border-radius: 8px;
    margin-bottom: 32px;
    padding: 16px;
    text-align: left;
}

.edit-profile-lawyer .services .service-box .sub-title {
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.4px;
    margin-bottom: 4px;
}

.edit-profile-lawyer .services .service-box .icon {
    float: right;
    width: 9px;
    height: 9px;
    cursor: pointer;
    transition: 0.3s ease-in-out;
}

.edit-profile-lawyer .services .service-box .icon:hover {
    transform: scale(1.5);
}

.edit-profile-lawyer .services .button-section {
    height: 340px;
    border-style: dashed;
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 18px;
    text-transform: uppercase;
    color: #0097A7;
}

.edit-profile-lawyer .save-changes {
    text-align: center;
}

.edit-profile-lawyer .save-changes .button-save {
    width: 200px;
    height: 50px;
    padding: 15px 32px;
    background: #00BCD4;
    border-radius: 4px;
    margin-bottom: 128px;
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 18px;
    text-transform: uppercase;
    color: #FFFFFF;
}

@media screen and (max-width: 1321px) {
    .edit-profile-lawyer .header-content .about-info .box {
        width: 386px;
    }

    .edit-profile-lawyer .header-content  {
        display: flex;
        justify-content: space-between;
    }
}

@media screen and (max-width: 1227px) {

    .edit-profile-lawyer .header-content .avatar-container {
       display: flex;
       flex-direction: column;
       justify-content: center;
       align-items: center;
    }

    .header-content-avatar-conatiner {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
   
    .edit-profile-lawyer .header-content  {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 20px;
    }

    .edit-profile-lawyer .header-content .about-info .box {
        width: 564px;
    }

    .edit-profile-lawyer .header-content .user-details {
        width: 564px;
        margin-left: 0;
    }

    .edit-profile-lawyer .header-content .user-details .additional {
        width: 100%;
        display: flex;
        justify-content: space-between;
    }

    .edit-profile-lawyer .header-content .user-details .rating {
        justify-content: space-between;
    }

    .edit-profile-lawyer .header-content .user-details .user-name {
        text-align: center;
        font-size: 40px;
    }

    .edit-profile-lawyer .header-content .about-info {
        margin-left: 0;
    }

    .edit-profile-lawyer .header-content .price-info {
        margin-left: 0;
    }

}


@media screen and (max-width:1160px){

  .edit-profile-lawyer .services .cols-full {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
    }
    
    .edit-profile-lawyer .services .cols::before, .cols::after {
        content:none;
        display: grid;
    }

    .edit-profile-lawyer .services .cols-full .col4of12{
       width:100%;
    }
}


@media screen and (max-width: 883px) {
    .edit-profile-lawyer .services .cols {
        margin-right: 0 !important;
        margin-left: 0 !important;
    }
}

@media screen and (max-width:768px){
    .edit-profile-lawyer .services .cols-full {
        display:flex;
        flex-direction:column;
    }
    .edit-profile-lawyer .services .cols-full .col4of12 {
        width:100%;
    }
}

@media screen and (max-width:700px) {
    .edit-profile-lawyer .services .cols {
        flex-direction: column;
    }

    .edit-profile-lawyer .services .cols .col4of12 {
        width: 100% !important;
    }

    .edit-profile-lawyer .header-content .user-details {
        width: 100% !important;
    }

    .edit-profile-lawyer .header-content .about-info  {
        width: 100% !important;
    }

    .edit-profile-lawyer .header-content .about-info .box{
        width: 100% !important;
    }

    .edit-profile-lawyer .services .cols .col4of12{
        padding: 0 !important;
    }

    .edit-profile-lawyer .services .button-section{
        height: 100px !important;
    }
}

@media screen and (max-width:500px) {
    .edit-profile-lawyer {
        margin: 0 20px;
    }
}


@media screen and (max-width:344px){
    .lawyer-action-buttons{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-bottom: 20px;
    }

    .lawyer-action-buttons .button-save{
       width: 100% !important;
       margin: 0;
    }
    
    .lawyer-action-buttons .button-view-profile{
        width: 100%;
        margin: 0!important;
     }

     .edit-profile-lawyer .header-content .user-details .additional {
        width: 100%;
        display: flex;
        flex-direction:column;
        justify-content: center;
        align-items:center;
        gap:10px
    }
    
    .edit-profile-lawyer .header-content .user-details .additional .first-half{
        width:100%;
    }

    .edit-profile-lawyer .header-content .user-details .additional .second-half {
        width:100%;
        margin-left:0px;
    }
    
    
}