@import url("./components/sidebarMenus/sidebarMenus.css");

.virtual-tour {
  overflow: hidden;
  width: 80vw;
  height: 90vh;
  position: relative;
}

.virtual-tour.full-page {
  width: 100vw;
  height: 100vh;
}

.virtual-tour.full-width {
  width: 100vw;
}

.virtual-tour .viewer {
  width: 100%;
  height: 100%;
  position: absolute;
}

.virtual-tour #viewer {
  width: 100%;
  height: 90vh;
  cursor: grab;
  position: relative;
}

.virtual-tour.full-page #viewer {
  height: 100vh;
}
.sidebar-components-container {
  display: flex;
  flex-direction: row;
  width: 100vw;
  height: 100vh;
}

.virtual-tour .sidebar {
  position: absolute;
  z-index: 10;
  left: 0;
  top: 0;
  width: 70px;
  height: 100%;
  background-color: #0e121c;
}

.virtual-tour .sidebar .menu {
  color: #7c7e83;
  font-size: 9px;
  width: 100%;
  padding: 20px 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
  cursor: pointer;
  white-space: wrap;
  justify-content: center;
}
.virtual-tour .sidebar .menu span {
  text-align: center;
}

.virtual-tour .sidebar .menu .icon {
  height: 30px;
  width: 30px;
}

.virtual-tour .sidebar .menu.selected,
.virtual-tour .sidebar .menu:hover {
  color: white;
}

.virtual-tour .sidebar .menu.selected {
  background: #2a3038;
}

.virtual-tour .sidecontent {
  min-width: 350px;
  height: 100%;
  background-color: #2a3038;
  position: absolute;
  z-index: 9;
  left: -280px;
  top: 0px;
  transition: 0.5s ease-in-out all;
}

.virtual-tour .sidecontent.show {
  left: 70px;
}

.virtual-tour .sidecontent .children {
  overflow-y: auto;
  height: 100%;
}

.virtual-tour .sidecontent .children::-webkit-scrollbar {
  width: 10px;
}

.virtual-tour .sidecontent .children::-webkit-scrollbar-track {
  background: #0e121c;
}

.virtual-tour .sidecontent .children::-webkit-scrollbar-thumb {
  background: #3c3c3c;
  border-radius: 10px;
}

.virtual-tour .sidecontent .hider {
  background-color: inherit;
  color: white;
  position: absolute;
  right: -25px;
  top: 50%;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border: 2px solid #ffffff27;
  font-size: 1.5em;
}

.virtual-tour .sidecontent .hider .hider-icon,
.virtual-tour .right-sidecontent .hider .hider-icon {
  height: 15px;
  font-weight: bold;
  font-size: 1.1em;
}

.virtual-tour .add-spots {
  display: flex;
  gap: 10px;
  align-items: center;
  position: absolute;
  z-index: 10;
  right: 40px;
  bottom: 40px;
}

.virtual-tour .add-spots button {
  outline: none;
  border: none;
  background: rgba(0, 0, 0, 0.192);
  color: white;
  border-radius: 3px;
  padding: 4px 10px;
  font-size: 0.8em;
  cursor: pointer;
  font-weight: 600;
}

.virtual-tour .add-spots button:hover {
  background: white;
  color: black;
}

.virtual-tour .right-sidecontent {
  min-width: 300px;
  height: 100%;
  background-color: #2a3038;
  position: absolute;
  z-index: 200;
  right: -100%;
  top: 0px;
  transition: 0.7s ease;
  display: flex;
  flex-direction: column;
}

.virtual-tour .right-sidecontent.show {
  right: 0px;
}

.virtual-tour .right-sidecontent .hider {
  position: absolute;
  top: 40px;
  right: 20px;
  color: white;
  cursor: pointer;
}

.virtual-tour .right-sidecontent .content {
  height: 100%;
  overflow-y: auto;
  padding: 20px;
  flex-direction: column;
  display: flex;
  gap: 20px;
  margin-top: 40px;
  justify-content: space-between;
}

.virtual-tour .right-sidecontent .content .input-group {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  font-size: 0.9em;
}

.virtual-tour .right-sidecontent .content .input-group label {
  color: white;
  font-weight: 600;
  font-size: 0.9em;
}

.virtual-tour .right-sidecontent .content .input-group input {
  width: 100%;
  height: 45px;
  border-radius: 7px;
  border: none;
  padding: 5px 10px;
  background-color: rgb(60, 92, 121);
  color: white;
  border: 1px solid rgba(255, 255, 255, 0.129);
}

.virtual-tour .right-sidecontent .content .input-group input:focus {
  outline: 1px solid rgba(255, 255, 255, 0.225);
}

.virtual-tour .right-sidecontent .content .buttons {
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  justify-content: space-between;
}

.virtual-tour .right-sidecontent .content .buttons button {
  width: 48%;
  font-size: 0.85em;
  padding: 5px 10px;
  color: white;
  border-radius: 7px;
  border: none;
  opacity: 0.8;
}

.virtual-tour .right-sidecontent .content .buttons button:hover {
  opacity: 1;
}

.virtual-tour .right-sidecontent .content .buttons .cancel {
  background-color: #dd2d44;
  font-weight: 600;
}

.virtual-tour .right-sidecontent .content .buttons .save {
  background-color: #59c1c9;
  font-weight: 600;
}

.virtual-tour .right-sidecontent .content .input-group .pano-select-container {
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  flex-wrap: wrap;
}

.virtual-tour .right-sidecontent .content .input-group .pano-select {
  width: 45%;
  display: flex;
  flex-direction: column;
  background-color: #0d6c92;
  color: white;
  font-size: 0.8em;
  font-weight: 600;
  border-radius: 5px;
  margin: 3px;
}

.virtual-tour
  .right-sidecontent
  .content
  .input-group
  .pano-select
  .img-container {
  width: 100%;
  height: 80px;
  background-size: cover;
}

.virtual-tour
  .right-sidecontent
  .content
  .input-group
  .pano-select
  .img-container
  img {
  width: 100%;
}

.panoramas {
  height: fit-content;
  min-height: 220px;
  z-index: 1;
  bottom: 0px;
  left: 0px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
}

.panoramas.preview {
  position: relative;
}

.pano-list-container {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  transition: 0.3s ease;
}

.pano-list-container.hide {
  top: 100%;
}

.pano-list-top-bar {
  position: absolute;
  bottom: 110%;
  left: 0;
  width: 100%;
  padding: 0 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  z-index: 10000000;
}

.pano-list-controls {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.pano-list {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 15px;
  overflow: auto;
}

.pano-list.preview {
  flex-direction: row;
  width: fit-content;
  padding: 20px;
  background-color: rgba(0, 0, 0, 0.372);
  /* position: absolute; */
  /* bottom: 0px; */
  width: 100%;
  overflow: auto;
}

.pano-list.preview::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #59c1c9;
  width: 4px;
}

.pano-list.preview::-webkit-scrollbar {
  width: 5px;
  background-color: #72ced555;
}

.pano-list-item {
  height: 100%;
  min-height: 140px;
  width: auto;
  min-width: 150px;
  color: #f1f2f6;
  border-radius: 10px;
  background-size: cover;
  padding: 7px;
  position: relative;
  box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.25);
}

.pano-list-item.preview {
  width: 160px;
  /* min-width: 160px; */
  /* max-width: 200px; */
  height: fit-content;
  padding: 5px;
  border-radius: 5px;
  background-color: rgba(0, 0, 0, 0.562);
  overflow: hidden;
}

.pano-list-item.preview .in-preview {
  height: 100%;
  width: 100%;
  font-size: 0.5em;
  font-weight: 600;
  padding: 0;
}

.pano-list-item.preview .in-preview h5 {
  margin: 0;
  font-size: 1.5em;
  font-weight: 600;
  padding: 2px 0;
}

.pano-list-item.preview .in-preview .img-container {
  height: 8em;
  width: 100%;
  background-size: cover;
  border-radius: 5px;
  overflow: hidden;
}

.pano-list-item::before {
  content: "";
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  background-color: rgba(0, 0, 0, 0.041);
  border-radius: 10px;
}

.pano-list-item .pano-list-item-container {
  z-index: 1;
  width: 100%;
  height: 100%;
  align-items: center;
  display: block;
}

.pano-list-item .sortable-icon {
  color: white;
  cursor: pointer;
  height: 35px;
  width: 35px;
  position: absolute;
  top: 10px;
  left: 10px;
  font-size: 1.2em;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: #06080b92;
}

.pano-list-item .pano-settings {
  position: absolute;
  bottom: 10px;
  display: flex;
  width: 95%;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background: rgba(0, 0, 0, 0.533);
  font-size: 0.9em;
  font-weight: 700;
  flex-wrap: nowrap;
  padding: 4px 20px;
  border-radius: 20px;
  gap: 10px;
}

.pano-settings:hover {
  background: rgba(0, 0, 0, 0.752);
}

.pano-settings input {
  background: none;
  border: none;
  color: white;
  padding: 0;
  font-weight: 500;
  max-width: 170px;
  flex-grow: 1;
}

.pano-settings .active-pill {
  font-size: 0.6em;
  padding: 2px 7px;
  text-transform: uppercase;
  border-radius: 20px;
  background-color: rgb(39, 140, 255);
}

.pano-settings input:focus {
  outline: 1px solid rgb(39, 140, 255);
}

.pano-settings .pano-actions .delete-icon {
  color: rgb(255, 255, 255);
  cursor: pointer;
  background-color: red;
}

.pano-list-item .infospots {
  width: 100%;
  border-radius: 5px;
  padding: 10px;
  background-color: rgb(24, 24, 26);
  /* font-size: 0.8em; */
}

.pano-list-item .infospots .trigger {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 5px;
  font-weight: bold;
  cursor: pointer;
}

.pano-list-item .infospots .dropdown-infospot-list {
  width: 100%;
}

.pano-list-item
  .infospots
  .dropdown-infospot-list
  .dropdown-infospot-list-item {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 15px 10px;
  background-color: rgb(233, 142, 30);
  margin: 10px 0;
  color: white;
  border-radius: 5px;
  font-weight: 600;
  cursor: pointer;
}

.pano-list-item
  .infospots
  .dropdown-infospot-list
  .dropdown-infospot-list-item
  .delete-button {
  background-color: rgb(209, 17, 17);
  border: none;
  color: #f1f2f6;
  border-radius: 5px;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  /* font-size: 1em; */
  cursor: pointer;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.35);
}

.infospot-editor {
  width: 12vw;
  min-width: 150px;
  position: absolute;
  z-index: 1;
  top: 20px;
  right: 20px;
  color: #000000;
}

.infospot-editor .editor {
  flex-direction: column;
  gap: 20px;
}

.infospot-editor .editor .form-section {
  background-color: #0e121c;
  padding: 20px;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.infospot-editor .editor .form-section label {
  font-weight: bold;
  color: white;
}

.infospot-editor .editor .form-section input,
.infospot-editor .editor .form-section select {
  width: 100%;
  height: 35px;
  border-radius: 7px;
  border: #0d6c92 2px solid;
  padding: 5px 15px;
}

.infospot-editor .editor .form-section input:focus,
.infospot-editor .editor .form-section select:focus {
  outline: none;
}

.infospot-editor .editor .form-section button {
  font-weight: bold;
  background-color: dodgerblue;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  border-radius: 5px;
  border: none;
}

.pano-creator {
  /* height: 100%; */
  width: 100%;
  padding: 20px;
  margin-bottom: 10px;
  /* margin-right: 10px; */
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  background-color: #0f121c;
  border-radius: 5px;
  font-size: 0.8em;
}

.pano-creator input {
  width: 100%;
  height: 35px;
  border-radius: 5px;
  border: none;
  padding: 5px 10px;
  background-color: rgb(238, 247, 255);
  color: black;
}

.pano-creator label {
  display: flex;
  width: 100%;
  height: 35px;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-weight: bold;
  border-radius: 5px;
  background-color: dodgerblue;
}

.virtual-tour-preview {
  position: relative;
}

.virtual-tour-preview .infospot-delete-icon {
  display: none !important;
}

.usage-prompt {
  position: absolute;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  top: 35%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 0.8rem;
  max-width: 200px;
  z-index: 999999;
}

.usage-prompt img {
  height: 50px;
  animation: bouncing-sideways alternate 2s infinite;
}

@keyframes bouncing-sideways {
  0% {
    transform: translateX(-5%);
  }

  50% {
    transform: translateX(+5%);
  }

  100% {
    transform: translateX(-5%);
  }
}

.floorPlan-preview {
  min-height: 200px;
  width: 400px;
  padding: 0 10px 10px 10px;
  background: rgba(0, 0, 0, 0.873);
  backdrop-filter: blur(0.3);
}

.floorPlan-preview-icon {
  padding: 5px;
  border-radius: 5px;
  background-color: #00000087;
  color: white;
  width: fit-content;
  display: flex;
  align-items: center;
  gap: 4px;
  font-size: small;
  cursor: pointer;
}

.floorPlan-preview-icon > span {
  display: none;
}

.floorPlan-preview .top-bar {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.floorPlan-preview .top-bar .action-buttons {
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  justify-content: flex-end;
  gap: 10px;
  color: white;
}

.floorPlan-preview .top-bar .action-buttons svg {
  cursor: pointer;
}

.floorPlan-preview .select-floorPlan {
  max-width: 50%;
  color: white;
}

.floorPlan-preview .selected-floorPlan img {
  border-radius: 5px;
}

.share .copy-link {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  color: white;
  margin-top: 20px;
}

.share .copy-link .icon {
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.222);
  padding: 5px;
  height: 40px;
  width: 40px;
  display: grid;
  place-content: center;
  cursor: pointer;
}

.upload-virtual-tour {
  display: flex;
  flex-direction: row;
  gap: 5px;
  align-items: center;
  justify-content: center;
  font-size: 0.9rem;
  background: #0d6c92;
  color: white;
  border-radius: 5px;
  padding: 10px;
  margin-bottom: 10px;
  cursor: pointer;
  width: 100%;
}

.upload-virtual-tour .text-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0px;
}

.upload-virtual-tour .text-content .subtitle {
  font-size: 0.7rem;
  color: #d4c3c3;
}

.upload-virtual-tour img {
  height: 20px;
}

.delete-icon {
  position: absolute;
  top: -20px;
  right: -20px;
  display: none;
  cursor: pointer;
}

.infospot-container:hover .delete-icon {
  display: block;
}

.close-virtual-tour {
  position: absolute;
  z-index: 30;
  display: block;
  top: 10px;
  right: 10px;
  cursor: pointer;
  color: tomato;
  font-size: 1.3em;
}

.confirm-buttons {
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 20px;
  left: 0px;
  z-index: 10000;
}

.confirm-buttons .btn {
  font-size: 0.85em;
  font-weight: 800;
  box-shadow: 0px 0px 10px 10px rgba(255, 255, 255, 0.144);
  padding: 7px 15px;
}

.preMadeVirtualTourModal {
  width: 90vw;
  max-width: 600px;
  padding: 20px;
  background-color: #fff;
  color: #212b36;
  position: relative;
}

.preMadeVirtualTourModal h3 {
  color: #212b36;
  text-align: center;
}

.preMadeVirtualTourModal pre {
  background-color: #212b3617;
  padding: 10px;
  border-radius: 5px;
  word-break: break-all;
  white-space: break-spaces;
  position: relative;
  font-size: 0.8em;
}

.preMadeVirtualTourModal .icons {
  position: absolute;
  top: 10px;
  right: 10px;
  display: flex;
  gap: 10px;
}

.preMadeVirtualTourModal .icon svg {
  height: 30px;
}

.preMadeVirtualTourModal .icon {
  padding: 5px;
  border-radius: 100%;
  cursor: pointer;
}

.preMadeVirtualTourModal .icon:hover {
  background: rgba(255, 255, 255, 0.211);
}

.fullscreen-icon-container {
  position: absolute;
  top: 43.5%;
  right: 10px;
  display: flex;
  gap: 10px;
  z-index: 30;
  font-size: 1.7em;
  color: white;
  background: rgba(0, 0, 0, 0.324);
  padding: 7px;
  border-radius: 5px;
  cursor: pointer;
}

.share-icon-container {
  position: absolute;
  top: 50%;
  right: 10px;
  display: flex;
  gap: 10px;
  z-index: 30;
  font-size: 1.5em;
  color: white;
  background: rgba(0, 0, 0, 0.324);
  padding: 8px;
  border-radius: 5px;
  cursor: pointer;
}

@media screen and (max-width: 630px) {
  .virtual-tour {
    display: flex;
    flex-direction: column;
    height: 100vh;
    width: 100%;
    position: relative;
  }
  .sidebar-components-container {
    width: 100vw;
    height: 50%;
    position: relative;
  }
  .virtual-tour .sidebar {
    position: relative;
  }
  .virtual-tour .sidebar .menu {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 5px;
  }
  .virtual-tour .viewer {
    position: relative;
    width: 100vw;
    height: 50%;
  }
  .virtual-tour .sidecontent {
    position: relative;
    left: 0;
    width: 100vw;
    height: 100%;
  }
  .virtual-tour .sidecontent.show {
    position: relative;
    left: 0px;
  }
  .upload-virtual-tour {
    width: 78%;
  }
  .upload-virtual-tour .text-content .subtitle {
    font-size: 0.6em;
  }
  .upload-virtual-tour .text-content .title {
    font-size: 0.8em;
  }
  .pano-creator {
    width: 78%;
  }
  .panoramas {
    align-items: start;
    overflow: scroll;
    height: 100px;
    height: 100%;
  }
  .pano-list-item {
    width: 78%;
  }
  .pano-list-item.floor-plan {
    width: 78%;
  }
  .pano-list-item .pano-settings {
    padding: 4px 15px;
  }
  .pano-settings input {
    max-width: 100px;
  }
  .floor-plan-upload-button {
    width: 78% !important;
  }
  .share .copy-link {
    width: 78%;
  }
  .virtual-tour .add-spots {
    bottom: 30px;
    right: 55px;
    height: fit-content;
  }
  .virtual-tour .sidecontent {
    height: 100%;
    overflow: scroll;
  }
  .virtual-tour-preview {
    display: flex;
    flex-direction: column;
  }
  .virtual-tour #viewer {
    height: 100vh;
  }
  .virtual-tour-preview.virtual-tour .viewer {
    height: 65% !important;
  }
  .virtual-tour-preview .pano-list-container {
    position: relative;
    height: 35%;
    overflow: scroll;
  }
  .virtual-tour-preview .pano-list-container .pano-list-top-bar {
    position: relative;
    top: 0px;
    background-color: rgba(0, 0, 0, 0.372);
    padding-top: 10px;
    display: flex;
    flex-direction: row;
    padding-bottom: 10px;
  }

  .virtual-tour-preview .pano-list-container .pano-list-controls {
    width: 60%;
  }
  .pano-list.preview {
    display: flex;
    flex-direction: column;
    width:90vw;
    overflow-y: scroll;
    direction: rtl;
    justify-content: center;
    align-items: center;
  }

.pano-list.preview::-webkit-scrollbar {
  width: 10px;
}

.pano-list.preview::-webkit-scrollbar-track {
  background: #0e121c;
}

.pano-list.preview::-webkit-scrollbar-thumb {
  background: 0097A7;
  border-radius: 10px;
  height: 20px;
}
  .virtual-tour-preview .pano-list-item.preview {
    height: 100px;
    width: 85%;
  }
  .virtual-tour .add-spots {
    display: flex;
    flex-direction: column;
    width: fit-content;
    bottom: 20px;
  }
  .virtual-tour .add-spots.preview {
    top: 15px;
    left: 15px;
    height: fit-content;
  }
  .floorPlan-preview {
    width: 260px;
  }
  .floorPlan-preview-icon > span {
    display: inline;
  }
  .pano-list {
    height: 100%;
    overflow: scroll;
  }
  .virtual-tour .add-spots button {
    padding: 4px 5px;
    font-size: 0.5em;
    cursor: pointer;
    font-weight: 600;
    width: 100%;
  }
  .virtual-tour-preview .pano-list-container-hidden-responsive {
    height: 45px;
    position: absolute;
    bottom: 59px;
    background-color: black;
  }
  .virtual-tour-preview.virtual-tour .viewer-fullscreen-responsive {

  }
  .virtual-tour-preview .pano-list-container-hidden-responsive {

  }


}
