.generalTerms { margin-bottom: 128px; font-size: 18px; line-height: 32px; }
.generalTerms-container { margin: 0 149px }
.generalTerms .title { font-size: 25px; font-family: Roboto; font-weight: 700; margin-bottom: 10px; }
.generalTerms .title-sub { margin-left: 20px; font-size: 20px; font-family: Roboto; font-weight: 400; margin-bottom: 10px; }
.generalTerms .title-sub-sub { margin-left: 30px; font-size: 20px; font-family: Roboto; font-weight: 400; margin-bottom: 10px; }
.generalTerms .ul { margin-left: 60px; list-style-type: none; }

.generalTerms .paragraph{
  text-align: left !important;
}

@media screen and (max-width:900px){
  .generalTerms-container {
     margin: 0 80px;
     text-indent: 0 !important;
     }
  
}

@media screen and (max-width:600px){
  .generalTerms-container { margin: 0 30px }
  .generalTerms .ul { margin-left: 0px;}

}


