.seller-profile-no-virtual-agent { padding: 95px 0 270px 0; text-align: center; }

.seller-profile-no-virtual-agent .title { font-size: 32px; line-height: 32px; font-family: Lora; font-weight: 700; margin-bottom: 8px; }
.seller-profile-no-virtual-agent .sub-title { margin: 0 20px; font-size: 18px; line-height: 24px; font-family: Source Sans Pro; font-weight: 400; margin-bottom: 24px; }
.seller-profile-no-virtual-agent .button { height: 48px; padding: 15px 32px; background: #00BCD4; border-radius: 4px; color: #ffffff; font-size: 14px; line-height: 18px; font-family: Source Sans Pro; font-weight: 700; text-transform: uppercase; }
.seller-profile-no-virtual-agent .button:hover { background: #00BCD4; }

.seller-profile-no-virtual-agent .content { display: flex; margin-bottom: 24px !important; justify-content: center; }
.seller-profile-no-virtual-agent .content .box { width: 200px; padding: 24px 24px 26px 24px; margin-right: 32px; background: #EDEDED; border-radius: 4px; }
.seller-profile-no-virtual-agent .content .box:last-child { margin-right: 0; }
.seller-profile-no-virtual-agent .content .box .icon { margin-bottom: 16px; height: 75px; }
.seller-profile-no-virtual-agent .content .box .text { font-size: 16px; line-height: 18px; font-family: Source Sans Pro; font-weight: 400; }