.banner {
    position: relative;
    width: 100%;
    margin-bottom: 64px;
    padding: 20px 0;
}

.banner .title-banner {
    font-size: 72px;
    line-height: 76px;
    text-align: center;
    color: #000;
    font-family: 'Lora';
    font-weight: 500;
}

@media screen and (max-width:520px){
    .banner .title-banner {
        font-size: 40px;
        text-align: center;
        line-height: 1;
    }
}

@media screen and (max-width:320px){
    .banner {
        margin-bottom: 20px;
    }

    .banner .title-banner {
        font-size: 30px;
        line-height: 1;
        text-align: center;
    }
    .privacyPolicy .banner{
        padding:0 5px !important;
    }
}