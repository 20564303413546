.no-inspecion-yet-seller {
    margin-top: 16px;
    text-align: center;
}

.no-inspecion-yet-seller .text {
    margin-bottom: 24px;
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    text-align: justify;
    color: #000000;
    margin-left: 42px;
    margin-right: 42px;
}

.no-inspecion-yet-seller .button {
    padding: 15px 32px;
    background: #00BCD4;
    border-radius: 4px;
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 18px;
    text-transform: uppercase;
    color: #FFFFFF;
}

.no-inspecion-yet-seller .button:hover {
    background: #00BCD4;
    border-radius: 4px;
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 18px;
    text-transform: uppercase;
    color: #FFFFFF;
}

.no-inspecion-yet-seller .invite {
    margin: 0 auto 20px auto;
    width: 670px;
    padding: 24px;
    background: #F8F8F8;
    border-radius: 10px;
}

.no-inspecion-yet-seller .invite img {
    width: 48px;
    height: 48px;
    margin-bottom: 16px;
}

.no-inspecion-yet-seller .invite .title-sub {
    margin-bottom: 8px;
    font-family: Lora;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 26px;
    color: #000000;
}

.no-inspecion-yet-seller .invite .text {
    margin-bottom: 28px;
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    text-align: center;
    color: #000000;
}

.no-inspecion-yet-seller .invite .date .title-date {
    margin-bottom: 4px;
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 18px;
    text-align: center;
    color: #000000;
}

.no-inspecion-yet-seller .invite .date .title-sub-date {
    margin-bottom: 24px;
    font-family: Lora;
    font-style: normal;
    font-weight: bold;
    font-size: 32px;
    line-height: 32px;
    color: #000000;
}

.no-inspecion-yet-seller .invite .buttons .change-date-save {
    margin-right: 25px;
    padding: 15px 32px;
    background: #00BCD4;
    border-radius: 4px;
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 18px;
    text-transform: uppercase;
    color: #FFFFFF;
}

.no-inspecion-yet-seller .invite .buttons .change-date-save:disabled {
    background: rgba(239, 239, 239, 0.3);
    color: rgb(84, 84, 84);
}

.no-inspecion-yet-seller .invite .buttons .send-inquiry {
    font-family: Source Sans Pro;
    padding: 15px 32px;
    border: 1px solid #00BCD4;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 18px;
    text-transform: uppercase;
    color: #000000;
}




.no-inspecion-yet-seller .pending-approval-for-the-meeting {
    margin: 0 auto 20px auto;
    width: 670px;
    padding: 24px;
    background: #F8F8F8;
    border-radius: 10px;
}

.no-inspecion-yet-seller .pending-approval-for-the-meeting img {
    width: 48px;
    height: 48px;
    margin-bottom: 16px;
}

.no-inspecion-yet-seller .pending-approval-for-the-meeting .title-sub {
    margin-bottom: 8px;
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 18px;
    text-align: center;
}

.no-inspecion-yet-seller .pending-approval-for-the-meeting .title {
    margin-bottom: 8px;
    font-family: Lora;
    font-style: normal;
    font-weight: bold;
    font-size: 32px;
    line-height: 32px;
    color: #000000;
}

.no-inspecion-yet-seller .pending-approval-for-the-meeting .text {
    margin-bottom: 24px;
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 24px;
    text-align: center;
    color: #000000;
}

.no-inspecion-yet-seller .pending-approval-for-the-meeting .buttons .new-inquiry {
    padding: 15px 32px;
    background: #00BCD4;
    border-radius: 4px;
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 18px;
    text-transform: uppercase;
    color: #FFFFFF;
}

.no-inspecion-yet-seller .pending-approval-for-the-meeting .buttons .change-date-meeting {
    margin-right: 20px;
    padding: 15px 32px;
    background: #F8F8F8;
    border-radius: 4px;
    border: 1px solid #00BCD4;
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 18px;
    text-transform: uppercase;
    color: #000000;
}

.no-inspecion-yet-seller .pending-approval-for-the-meeting-change {
    padding: 30px 110px;
    background: #F8F8F8;
    border-radius: 10px;
}

.no-inspecion-yet-seller .pending-approval-for-the-meeting-change img {
    width: 85px;
    height: 80px;
    margin-bottom: 20px;
}

.no-inspecion-yet-seller .pending-approval-for-the-meeting-change .title-sub {
    margin-bottom: 16px;
    font-family: Lora;
    font-style: normal;
    font-weight: bold;
    font-size: 32px;
    line-height: 32px;
    text-align: center;
    color: #000000;
}

.no-inspecion-yet-seller .pending-approval-for-the-meeting-change .text-content {
    margin-bottom: 16px;
    padding: 16px;
    text-align: left;
    background-color: #EDEDED;
    border-radius: 4px;
}

.no-inspecion-yet-seller .pending-approval-for-the-meeting-change .new-title {
    margin-bottom: 8px;
    font-family: Source Sans Pro;
    font-style: normal;
    font-size: 16px;
    line-height: 18px;
    text-align: center;
    color: #000000;
    font-weight: 700;
}

.no-inspecion-yet-seller .pending-approval-for-the-meeting-change .title {
    margin-bottom: 8px;
    font-family: Lora;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 26px;
    text-align: center;
    color: #000000;
}

.no-inspecion-yet-seller .pending-approval-for-the-meeting-change .text {
    margin-bottom: 24px;
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    text-align: center;
    letter-spacing: 0.4px;
    color: #8C8C8C;
}

.no-inspecion-yet-seller .pending-approval-for-the-meeting-change .buttons .new-inquiry {
    padding: 15px 32px;
    background: #00BCD4;
    border-radius: 4px;
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 18px;
    text-transform: uppercase;
    color: #FFFFFF;
    margin-right: 15px;
}

.no-inspecion-yet-seller .pending-approval-for-the-meeting-change .buttons .cancel-and-answer {
    border: 2px solid #00BCD4;
    box-sizing: border-box;
    border-radius: 4px;
    padding: 15px 32px;
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 18px;
    text-transform: uppercase;
    color: #000000;
}

.dialog-seller-change-meeting-date-request .content .actions .button-send {
    height: 45px;
    width: 205px;
    padding: 15px 32px;
    background: #00BCD4;
    border-radius: 4px;
    color: #fff;
    text-transform: uppercase;
    font-size: 14px;
    line-height: 18px;
    font-family: Source Sans Pro;
    font-weight: 700;
}

.dialog-buyer-meeting-content .content .actions .button-close {
    height: 45px;
    width: 205px;
    padding: 15px 32px;
    border: 2px solid #00BCD4;
    border-radius: 4px;
    color: #000;
    text-transform: uppercase;
    font-size: 14px;
    line-height: 18px;
    font-family: Source Sans Pro;
    font-weight: 700;
}

/* dialog change meeting date */
.dialog-seller-change-meeting-date-request {
    text-align: center;
}

.dialog-seller-change-meeting-date-request-content {
    padding: 64px 84px;
}

.dialog-seller-change-meeting-date-request-content .title-header {
    padding: 0;
    margin-bottom: 8px;
}

.dialog-seller-change-meeting-date-request-content .title-header .title {
    display: inline-block;
    font-size: 48px;
    line-height: 52px;
    font-family: Lora;
    font-weight: 700;
}

.dialog-seller-change-meeting-date-request-content .title-header .close-icon {
    position: absolute;
    top: 20px;
    right: 20px;
    cursor: pointer;
}

.dialog-seller-change-meeting-date-request-content .content {
    padding: 0;
    overflow-x: hidden;
}

.dialog-seller-change-meeting-date-request-content .content .sub-title {
    font-size: 18px;
    line-height: 24px;
    font-weight: 400;
    margin-bottom: 24px;
    font-family: Source Sans Pro;
    padding: 0 40px;
}

.dialog-seller-change-meeting-date-request-content .content .sended-message-from-seller {
    margin-bottom: 24px;
}

.dialog-seller-change-meeting-date-request-content .content .sended-message-from-seller .title {
    margin-bottom: 7px;
    font-family: Source Sans Pro;
    font-style: normal;
    text-align: left;
    font-weight: 900;
    font-size: 18px;
    line-height: 22px;
    text-transform: uppercase;
    color: #000000;
}

.dialog-seller-change-meeting-date-request-content .content .sended-message-from-seller .field {
    width: 100%;
    padding: 16px;
    text-align: left;
    border: none !important;
    border-radius: 4px 0px 0px 4px;
    background-color: #EDEDED;
}

.dialog-seller-change-meeting-date-request-content .content .sended-message-from-seller .text {
    padding: 16px;
    text-align: left;
    background-color: #F8F8F8;
}

.dialog-seller-change-meeting-date-request-content .content .date-hour-picker-boxes {
    padding: 0 180px;
}

.dialog-seller-change-meeting-date-request-content .content .date-hour-picker-boxes .date-picker-box,
.dialog-seller-change-meeting-date-request-content .content .date-hour-picker-boxes .hour-picker-box {
    margin-bottom: 24px;
    background: #FFFFFF;
    border-radius: 10px;
    box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.14), 0px 1px 18px rgba(0, 0, 0, 0.12), 0px 3px 5px rgba(0, 0, 0, 0.2);
}

.dialog-seller-change-meeting-date-request-content .content .date-hour-picker-boxes .date-picker-box {
    padding: 16px 54px;
}

.dialog-seller-change-meeting-date-request-content .content .date-hour-picker-boxes .hour-picker-box {
    padding: 16px 44px;
}

.dialog-seller-change-meeting-date-request-content .content .date-hour-picker-boxes .date-picker-box .title,
.dialog-seller-change-meeting-date-request-content .content .date-hour-picker-boxes .hour-picker-box .title {
    font-size: 18px;
    line-height: 22px;
    font-family: Source Sans Pro;
    font-weight: 900;
    text-transform: uppercase;
    margin-bottom: 8px;
}

.dialog-seller-change-meeting-date-request-content .content .date-hour-picker-boxes .date-picker-box .MuiPickersCalendarHeader-transitionContainer p::first-letter {
    text-transform: uppercase;
}

.dialog-seller-change-meeting-date-request-content .content .date-hour-picker-boxes .hour-picker-box .hours-list .chip {
    padding: 4px 12px;
    float: left;
    background: #F8F8F8;
    border-radius: 16px;
    color: #000000;
    opacity: 0.5;
    font-size: 16px;
    line-height: 18px;
    font-weight: 400;
    font-family: Source Sans Pro;
    margin-right: 8px;
    margin-bottom: 8px;
    border: 1px solid #D8D8D8;
    box-sizing: border-box;
    cursor: pointer;
}

.dialog-seller-change-meeting-date-request-content .content .date-hour-picker-boxes .hour-picker-box .hours-list .chip.disabled {
    background: #FFFFFF;
    opacity: 0.4;
    cursor: not-allowed;
}

.dialog-seller-change-meeting-date-request-content .content .date-hour-picker-boxes .hour-picker-box .hours-list .chip.highlight {
    opacity: 1;
    background: #E0F7FA;
    color: #0097A7;
    border: 1px solid #0097A7;
}

.dialog-seller-change-meeting-date-request-content .content .date-hour-picker-boxes .hour-picker-box .hours-list .chip.active {
    opacity: 1;
    background: #80DEEA;
    border: 1px solid #80DEEA;
    cursor: default;
    color: #ffffff;
}

.dialog-seller-change-meeting-date-request-content .content .actions {
    margin-top: 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.dialog-seller-change-meeting-date-request-content .content .actions .button-send {
    height: 45px;
    width: 205px;
    padding: 15px 32px;
    background: #00BCD4;
    border-radius: 4px;
    color: #fff;
    text-transform: uppercase;
    margin-bottom: 16px;
    font-size: 14px;
    line-height: 18px;
    font-family: Source Sans Pro;
    font-weight: 700;
}

.dialog-seller-change-meeting-date-request-content .content .actions .button-close {
    height: 45px;
    width: 205px;
    padding: 15px 32px;
    border: 2px solid #00BCD4;
    border-radius: 4px;
    color: #000;
    text-transform: uppercase;
    font-size: 14px;
    line-height: 18px;
    font-family: Source Sans Pro;
    font-weight: 700;
}


.dialog-seller-change-meeting-date-request-content .content .date-hour-picker-boxes .date-picker-box .calendar {
    width: 100%;
    text-align: center;
}

.dialog-seller-change-meeting-date-request-content .content .date-hour-picker-boxes .date-picker-box .calendar .rmdp-calendar {
    width: 100%;
    text-align: center;
}

.dialog-seller-change-meeting-date-request-content .content .date-hour-picker-boxes .date-picker-box .calendar .rmdp-calendar .rmdp-day-picker {
    justify-content: center;
}

.dialog-seller-change-meeting-date-request-content .content .date-hour-picker-boxes .date-picker-box .calendar .rmdp-header-values {
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 18px;
    text-align: center;
    color: #000000;
}

.dialog-seller-change-meeting-date-request-content .content .date-hour-picker-boxes .date-picker-box .calendar .rmdp-day.rmdp-today:not(.rmdp-selected) span:not(.with-background) {
    opacity: 1;
    background: transparent;
}

.dialog-seller-change-meeting-date-request-content .content .date-hour-picker-boxes .date-picker-box .calendar .rmdp-day span {
    color: #000
}

.dialog-seller-change-meeting-date-request-content .content .date-hour-picker-boxes .date-picker-box .calendar .rmdp-day:not(.rmdp-disabled):not(.rmdp-day-hidden):not(.rmdp-selected) span:hover {
    background-color: #00BCD4;
    opacity: 0.3;
    color: #ffffff;
}

.dialog-seller-change-meeting-date-request-content .content .date-hour-picker-boxes .date-picker-box .calendar .rmdp-day.rmdp-selected span {
    background-color: #80DEEA;
    color: #ffffff;
    font-weight: 700;
}

.dialog-seller-change-meeting-date-request-content .content .date-hour-picker-boxes .date-picker-box .calendar .rmdp-day.rmdp-disabled {
    cursor: not-allowed;
    opacity: 0.2;
}

@media screen and (max-width:690px){
    .no-inspecion-yet-seller .invite {
        margin: 0 auto 20px auto;
        width: 100%;
        padding: 24px;
        background: #F8F8F8;
        border-radius: 10px;
    }
}

@media screen and (max-width:466px){
    .no-inspecion-yet-seller .invite .buttons{
        display: flex;
        flex-direction: column;
        gap:20px

    }

    .no-inspecion-yet-seller .invite .buttons .change-date-save{
        width: 100%;

    }

    .no-inspecion-yet-seller .invite .title-sub {
        font-size: 20px;   
    }

    .no-inspecion-yet-seller .invite .text {
        margin: 20px 0;
        text-align: justify;
    }
}

@media screen and (max-width:344px){
    .no-inspecion-yet-seller .invite .buttons .send-inquiry {
        padding: 15px 0px;
    }

    .no-inspecion-yet-seller .invite .date .title-sub-date {
        font-size: 25px;
    }

    .no-inspecion-yet-seller .invite .buttons .change-date-save,
    .no-inspecion-yet-seller .invite .buttons .send-inquiry {
        font-size: 11px;
    }

    .no-inspecion-yet-seller .pending-approval-for-the-meeting {
        width: 100%;
    }

    .no-inspecion-yet-seller .pending-approval-for-the-meeting .text {
        margin: 0;
        text-align: justify;
    }

    .no-inspecion-yet-seller .pending-approval-for-the-meeting-change {
        padding: 25px 0;
    }

    .no-inspecion-yet-seller .pending-approval-for-the-meeting-change .title-sub {
        font-size: 25px;
    }

    .no-inspecion-yet-seller .pending-approval-for-the-meeting-change .buttons .cancel-and-answer,
    .no-inspecion-yet-seller .pending-approval-for-the-meeting-change .buttons .new-inquiry {
        font-size: 12px;
        padding: 15px 0;
        width: 100%;
        margin: 5px 0;
    }

    .no-inspecion-yet-seller .pending-approval-for-the-meeting-change .buttons {
        display: block;
    }
}