.seller-home-contractModal .MuiBackdrop-root {
    background-color: rgba(255, 255, 255, 0.25) !important;
    backdrop-filter: blur(25px);
    border-radius: 10px;
}

.seller-home-contractModal .closeIcon {
    position: absolute;
    top: 22.67px;
    right: 22.67px;
    cursor: pointer;
}

.seller-home-contractModal .closeIcon>img {
    width: 18.67px;
    height: 18.67px;

}

.seller-home-contractModal .content {
    width: 905px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    overflow-x: hidden;
    padding: 30px 10px;
}

.seller-home-contractModal .content>h1 {
    font-family: Lora;
    font-size: 48px;
    font-style: normal;
    font-weight: 1;
    line-height: 52px;
}

.seller-home-contractModal .content>p {
    font-family: 'Source Sans Pro';
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    width: 650px;
    text-align: center;
    margin-top: 8px;
}

.seller-home-contractModal .content .innerContext {
    margin-top: 24px;
    width: 857px;
    height: 471px;
    background-color: #f4f4f4;
    overflow-y: scroll;
}

.seller-home-contractModal .content .innerContext>h3 {
    font-family: 'Source Sans Pro';
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    margin-top: 8px;
    margin-left: 8px;
}

.seller-home-contractModal .content .innerContext>p {
    font-family: 'Source Sans Pro';
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    /* text-align: center; */
    margin-top: 8px;
    margin-left: 8px;
}

.seller-home-contractModal .content .button-container>button {
    margin: 0px 16px;
}

.seller-home-contractModal .content .button-container .button {
    background-color: #00bcd4;
    height: 48px;
    font-family: 'Source Sans Pro';
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 18px;
    text-align: left;
    color: white;
    padding: 15px 32px;
    border: none;
    outline: 0;
    border-radius: 4px;
    cursor: pointer;
    text-transform: uppercase;
}

.seller-home-contractModal .contract-seller .contract-body {
    margin: 0px 100px;
    padding: 20px !important;
    background: #EFEFEF;
    border-radius: 12px;
    text-align: left;
    height: 410px;
    overflow: auto;
    overflow-y: auto;
    overflow-x: hidden !important;
}

.seller-home-contractModal .contract-seller .contract-body .title {
    margin-bottom: 8px;
    font-family: Lora;
    font-style: normal;
    font-weight: 1;
    font-size: 48px;
    line-height: 52px;
    text-align: center;
    color: #000000;
}

.seller-home-contractModal .contract-seller .contract-body .title-sub {
    margin: 0 128px 24px 128px;
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 24px;
    text-align: center;
    color: #000000;
}

.seller-home-contractModal .contract-seller .contract-body .MuiInput-underline:before {
    bottom: 7px;
}

.seller-home-contractModal .contract-seller .contract-body .MuiInput-underline:after {
    display: none;
}

.seller-home-contractModal .contract-seller .contract-body .common-title {
    margin-bottom: 10px;
    font-family: Lora;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 26px;
}

.seller-home-contractModal .contract-seller .contract-body .text {
    margin-bottom: 32px;
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 18px;
}

/* Scroll */
.seller-home-contractModal .contract-seller .contract-body::-webkit-scrollbar {
    width: 6px !important;
    background: gray;
}

.seller-home-contractModal .contract-seller .contract-body::-webkit-scrollbar-track {
    background: #fff;
    border-radius: 12px;
}

.seller-home-contractModal .contract-seller .contract-body::-webkit-scrollbar-thumb {
    background-color: #000;
    border-radius: 10px;
}

.seller-home-contractModal .content .content-steps .content-arrow {
    display: flex;
    position: fixed;
    gap: 1rem;
}

.seller-home-contractModal .content .content-steps .content-arrow :hover{
    cursor: pointer;
    color: #00bcd4;
}

.seller-home-contractModal .content .content-steps {
    display: flex;
    gap: 1rem;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
}

.seller-home-contractModal .content .content-steps .content-arrow .steps {
    display: flex;
}

.seller-home-contractModal .content .content-steps .step-number {
    display: flex;
    font-size: 17px;
    margin: 0 5px;
}

.contract-body .issuedOn,
.contract-body .issuedFrom {
    display: inline-block;
    width: 50%;
}

@media screen and (max-width:1000px) {
    .seller-home-contractModal .content  {
        width: 100%;
    }
}

@media screen and (max-width:800px) {
    .seller-home-contractModal .contract-seller .contract-body{
        margin: 0px;
    }
}

@media screen and (max-width:765px) {
    .seller-home-contractModal .contract-seller .contract-body .title-sub{
        margin: 0 50px 24px 50px;
    }
}

@media screen and (max-width:500px) {
    .seller-home-contractModal .contract-seller .contract-body .title-sub{
        margin: 0 10px 24px 10px;
    }

    .contract-body .issuedOn,
    .contract-body .issuedFrom {
        display: block;
        width: 90%;
    }
}


@media screen and (max-width:466px) {
    .seller-home-contractModal .MuiDialog-paperWidthFalse {
        width: 100% !important;
        max-width: 100% !important;
        margin: 0 auto;
    }

    .seller-home-contractModal .MuiDialog-paper {
        margin: 0 !important;
    }
}


@media screen and (max-width:410px) {
    .seller-home-contractModal .contract-seller .contract-body {
        width: 100%;
    }
}
