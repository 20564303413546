.third-parties-search {
    padding: 32px;
}

.third-parties-search .app-bar-info {
    box-shadow: none;
    background-color: #fff;
    color: #000000;
    border-radius: 12px 12px 0 0;
}

.third-parties-search .app-bar-info .tabs-info-container {
    margin-bottom: 31px;
    border-bottom: 1px solid #B2EBF2;
}

.third-parties-search .app-bar-info .tabs-info-container .tabs-title {
    opacity: 1;
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: #000000;
    text-transform: none
}

.third-parties-search .app-bar-info .tabs-info-container .tabs-title[aria-selected="true"] {
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: #00838F;
    background: #E0F7FA;
    border-radius: 4px 4px 0px 0px;
}

.third-parties-search .MuiTabs-indicator {
    display: none;
}

.third-parties-search .common-container .MuiBox-root.MuiBox-root-10 {
    padding: 0 !important;
}

.third-parties-search .common-container .primary-layer {
    margin: 0 301px 32px 301px;
    height:251px;
}

.third-parties-search .result .lattice .pagination {
    display: flex;
    justify-content: center;
    align-items: center;
}

.third-parties-search .result .list .pagination {
    display: flex;
    justify-content: center;
    align-items: center;
}
.third-parties-search .result .list, .container{
    display: flex;
    flex-direction: column;
}

@media screen and (max-width: 1330px) {
    .third-parties-search .common-container .primary-layer {
        margin: 0 0px 32px 0px;
        max-width: 622px;
        margin: 0 auto;
    }

    .third-parties-search .common-container .content {
        width: 200px;
    }
}

@media screen and (max-width: 1000px) {
    .content .circle {
        border-radius: 50%;
        width: 95px !important;
        height: 95px !important;
    }

    .content .circle img {
        width: 95px !important;
        height: 95px !important;
    }

    .lattice .cols {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
    }

    .lattice .cols col {
        width: 100%;
    }

    .lattice .cols::before,
    .cols::after {
        content: none;
    }

    .lattice .col4of12 {
        width: 100%;
    }
}

@media screen and (max-width: 767px) {
    .third-parties-search {
        padding: 20px;
    }

    .third-parties-search .app-bar-info .tabs-info-container {
        margin-bottom: 0;

    }

    .content .circle {
        border-radius: 50%;
        width: 60px !important;
        height: 60px !important;
        margin: 0 auto 0px auto !important;
    }

    .content .circle img {
        width: 60px !important;
        height: 60px !important;
    }

    .third-parties-search .content .title{
        font-size: 14px !important;
    }

    .content .result {
        font-size: 12px !important;
    }

    .third-parties-search .common-container .content {
        width: 100%;
    }

    .third-parties-search .content .title {
        margin-bottom: 0;
    }

    .third-parties-search .content .title h2 {
        margin-bottom: 0;
    }

    .third-parties-search .css-1mddgs7-MuiRating-root {
        font-size: .99rem !important;
    }


    .lattice-content .left-part .image img {
        width: 100px !important;
        height: 100px !important;
    }
}

@media screen and (max-width: 667px) {
    .lattice .cols {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
    }
}

@media screen and (max-width: 375px) {
    .lattice .col {
        padding: 0 !important;
    }
}

@media screen and (max-width:464px){
    .third-parties-search .common-container .MuiBox-root{
        padding:24px 0px !important
    }
}