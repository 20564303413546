.buttonUnFilled {
  left: 198px;
  top: 614px;
  border-radius: 4px;
  padding: 13px 32px 13px 32px;
  border: 2px solid #00bcd4;
  font-family: Source Sans Pro;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 18px;
  cursor: pointer;
  background-color: transparent;
  text-transform: uppercase;
}
.buttonUnFilled > span > img {
  margin-left: 7px;
  width: 17px;
  height: 17px;
}
