.pricePage-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.pricePage-wrapper .stepper {
  margin-top: 64px;
}

.pricePage-wrapper .choosePrice {
  margin-top: 32px;
  display: grid;
}

.pricePage-wrapper .choosePrice .label-price {
  font-family: Source Sans Pro;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.4000000059604645px;
}

.pricePage-wrapper .choosePrice .price-number {
  display: inline-block;
  padding: 4px 8px;
  background: #E0F7FA;
  border-radius: 4px;
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 32px;
  text-transform: uppercase;
  color: #000000;
  width: 170px;
  border: none;
}

.pricePage-wrapper .choosePrice .price-number.error {
  border: 1px solid #F32013;
}

.pricePage-wrapper .hire-lawyer {
  margin-top: 66px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 434px;
  width: 906px;
  background: #f8f8f8;
  border-radius: 4px;
  margin-bottom: 128px;
}

.pricePage-wrapper .hire-lawyer>h1 {
  font-family: Lora;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px;
  letter-spacing: 0em;
  text-align: left;
}

.pricePage-wrapper .hire-lawyer>p {
  font-family: Source Sans Pro;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: center;
  margin-top: 8px;
  width: 672px;
}

.pricePage-wrapper .hire-lawyer>div {
  display: flex;
  margin-bottom: 24px;
}

.pricePage-wrapper .hire-lawyer>div>div {
  width: 203px;
  height: 186px;
  background: #ededed;
  margin: 0px 16px;
  margin-top: 24px;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.pricePage-wrapper .hire-lawyer>div>div>p {
  font-family: Source Sans Pro;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: center;
}

.pricePage-wrapper .hire-lawyer>div>div>img {
  margin-bottom: 23px;
  width: 75px;
}

.pricePage-wrapper .buttons-wrapper {
  margin-top: 24px;
  text-align: center;
}

.pricePage-wrapper .buttonFilled {
  background-color: #00bcd4;
  border: 2px solid #00bcd4;
  font-family: Source Sans Pro;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 18px;
  color: white;
  padding: 15px 32px 15px 32px;
  border-radius: 4px;
  text-transform: uppercase;
}

.pricePage-wrapper .buttonFilledDisabled {
  font-family: Source Sans Pro;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 18px;
  color: rgb(84, 84, 84, 0.5);
  background-color: rgba(239, 239, 239, 0.3);
  padding: 15px 32px 15px 32px;
  border-radius: 4px;
  text-transform: uppercase;
}

.pricePage-wrapper .buttonFilled:hover {
  border: 2px solid #00bcd4;
  background-color: #00bcd4;
}

.pricePage-wrapper .buttonUnfilled {
  border-radius: 4px;
  margin-right: 25px;
  padding: 15px 21px 15px 21px;
  border: 2px solid #00bcd4;
  font-family: Source Sans Pro;
  font-size: 14px;
  font-style: normal;
  color: #000;
  font-weight: 700;
  line-height: 18px;
  background-color: transparent;
  text-transform: uppercase;
}

.pricePage-wrapper .buttonUnfilled:hover {
  border: 2px solid #00bcd4;
}

.pricePage-wrapper .hire-lawyer .sub-section-button {
  padding: 19px 48px;
  background: #00BCD4;
  border-radius: 4px;
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  text-transform: uppercase;
  color: #FFFFFF;
}


@media screen and (max-width: 630px) {

.pricePage-wrapper .stepper {
  margin-top: 32px !important;
  margin-bottom: 32px !important;
}

.pricePage-wrapper .createAnAddFlow .step-item.currentStep>div {
  width: 70px !important;
  height: 70px !important;
}

.pricePage-wrapper .createAnAddFlow .currentStep>div>p {
  font-size: 40px !important;
}

.pricePage-wrapper .createAnAddFlow .step-item>div {
  width: 55px !important;
  height: 55px !important;
}

.pricePage-wrapper .createAnAddFlow .step-item > div > p {
  font-size: 24px !important;
}

.pricePage-wrapper .createAnAddFlow .dashed-line {
  margin-bottom: 49px !important;
  }

.pricePage-wrapper .createAnAddFlow .dashed-line {
  width: 46px;
}

}

@media screen and (max-width: 570px) {
  .pricePage-wrapper .step-item>p {
      font-size: 15px !important
  }
}

@media screen and (max-width: 500px) {
 
  .pricePage-wrapper .createAnAddFlow .step-wrapper {
      margin-top: 16px !important;
  }

  .pricePage-wrapper .createAnAddFlow .step-item.currentStep>div {
      width: 60px !important;
      height: 60px !important;
  }

  .pricePage-wrapper .createAnAddFlow .step-item>div {
      width: 45px !important;
      height: 45px !important;
  }

  .pricePage-wrapper .createAnAddFlow .step-item>div>p {
      font-size: 20px !important;
  }

  .pricePage-wrapper .createAnAddFlow .dashed-line {
      width: 30px;
  }

}

@media screen and (max-width: 450px) {
  .pricePage-wrapper .createAnAddFlow .step-item>p {
      font-size: 12px !important;
  }
}

@media screen and (max-width: 400px) {
  .pricePage-wrapper .createAnAddFlow .currentStep {
      margin-right: 0px !important;
  }

  .pricePage-wrapper .createAnAddFlow>h1 {

      font-size: 30px !important;
      line-height: 32px !important;
  }

  .vissualPage-wrapper .createAnAddFlow .dashed-line {
      width: 22px;
  }

  .vissualPage-wrapper .createAnAddFlow .step-item.currentStep>div {
      width: 50px !important;
      height: 50px !important;
  }

  .vissualPage-wrapper .createAnAddFlow .step-item>div {
      width: 30px !important;
      height: 30px !important;
      align-items: baseline;
  }

  .vissualPage-wrapper .createAnAddFlow .step-item>div>p {
      font-size: 14px !important;
  }

  .vissualPage-wrapper .createAnAddFlow .step-item>p {
      font-size: 13px !important;
  }

  .vissualPage-wrapper .createAnAddFlow .dashed-line {
      width: 15px !important;
  }

  .vissualPage-wrapper .buttonUnfilled {
      margin-right: 0px !important;
  }

}

@media screen and (max-width: 700px) {
  .pricePage-wrapper .hire-lawyer{
    height: auto;
    width: auto;
    padding: 40px 20px;
  }

  .pricePage-wrapper .hire-lawyer > div{
    flex-direction: column;
  }

  .pricePage-wrapper .hire-lawyer > p{
    width: 100%;
  }
}