.card-wrapper {
    width: 100%;
    height: fit-content;
    background: #ededed;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px rgba(0, 0, 0, 0.12), 0px 1px 3px rgba(0, 0, 0, 0.2);
    border-radius: 4px;
    display: flex;
    justify-content: center;
    margin: 24px 0;
}

.card-wrapper:first-child {
    margin-top: 0;
}

.card-wrapper:last-child {
    margin-bottom: 0;
}

.dialog {
    display: flex;
    justify-content: center;
    align-items: center;
}

.dialog-container {
    display: flex;
    flex-direction: column;
    margin: 64px auto;
    align-items: center;
    justify-content: center;
    width: calc(100vw - 128px);
    max-width: 905px;
}

.dialog-container img {
    margin-bottom: 19.5px;
    width: 84px;
    height: 72px;
}

.dialog-container h3 {
    margin: 0;
    padding: 0;
    margin-bottom: 8px;
    font-family: 'Lora, sans-serif';
    font-style: normal;
    font-weight: 1;
    font-size: 48px;
    line-height: 52px;
    text-align: center;
    color: #000;
}

.dialog-container p {
    margin: 0;
    padding: 0;
    max-width: 650px;
    font-family: 'Source Sans Pro, sans-serif';
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 24px;
    text-align: center;
    color: #000;
    margin-bottom: 24px;
}

.dialog-container-buttons {
    display: flex;
}

.dialog-container-buttons button {
    margin: 0 12px;
    padding: 15px 32px;
    font-family: "Source Sans Pro", sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 18px;
    text-transform: uppercase;
    color: #ffffff;
    outline: none;
    border: none;
    background: #00bcd4;
    border-radius: 4px;
}

.dialog-container-buttons button:hover {
    margin: 0 12px;
    padding: 15px 32px;
    font-family: "Source Sans Pro", sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 18px;
    text-transform: uppercase;
    color: #ffffff;
    outline: none;
    border: none;
    background: #00bcd4;
    border-radius: 4px;
}

.card-wrapper-details {
    height: 100%;
    margin: 24px 0px;
    width: calc(100% - 48px);
    
}

.card-wrapper-details-top {
    height: 200px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    
}

.card-wrapper-details-top-left {
    width: 39%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}

.card-wrapper-details-top-left .offer-accepted {
    margin-bottom: 30px;
    background: #C8E6C9;
    padding: 14px 20px;
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 900;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    text-transform: uppercase;
    color: #1B5E20;
}

.card-wrapper-details-top-center .offer-accepted-true {
    margin-bottom: 30px;
    padding: 14px 20px;
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 900;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    text-transform: uppercase;
    color: #1B5E20;
    height: 50px;
    width: 100%;
}



.card-wrapper-details-top-left h5 {
    margin: 0;
    padding: 0;
    margin-bottom: 8px;
    font-family: Lora;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 26px;
    text-align: left;
    color: #000;
}

.card-wrapper-details-top-left-profile {
    display: flex;
    height: 100%;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
}

.card-wrapper-details-top-left-profile .buyer-profile-picture{
    width: 88px;
    height: 88px;
    border-radius: 44px;
    object-fit: cover; 
}
.card-wrapper-details-top-left-profile img {
    width: 88px;
    height: 88px;
    border-radius: 44px;
}

.card-wrapper-details-top-left-profile-details {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    margin-left: 5px;
    height: 90%;
}

.card-wrapper-details-top-left-profile-details h5 {
    margin: 0;
    padding: 0;
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: 900;
    font-size: 18px;
    line-height: 22px;
    text-transform: uppercase;
    color: #000;
}

.card-wrapper-details-top-left-profile-details div {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 24px;
    margin-top: 8px;
}

.card-wrapper-details-top-left-profile-details div div {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 24px;
    width: 24px;
    border-radius: 15px;
    background-color: white; 
    
}

.card-wrapper-details-top-left-profile-details div div img {
    height: 10.5px;
    width: 9.5px;
}

.card-wrapper-details-top-left-profile-details div p {
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: 100;
    font-size: 16px;
    line-height: 18px;
    color: #000;
    margin-left: 8px;
    margin-top: 22px;
}

.card-wrapper-details-top-center {
    width: 34%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
}

.card-wrapper-details-top-center h5 {
    margin: 0;
    margin-bottom: 8px;
    padding: 0;
    font-family: Lora;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 26px;
}

.card-wrapper-details-top-center h2 {
    margin: auto 0;
    padding: 0;
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: bold;
    font-size: 72px;
    line-height: 91px;
    /* identical to box height */
    color: #000;
}

.card-wrapper-details-top-right {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-end;
}

.card-wrapper-details-top-right p {
    margin: 0;
    padding: 0;
    margin-bottom: 8px;
    font-weight: 600;
    font-size: 15px;
}

.card-wrapper-details-top-right div {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    height: 100%;
}

.card-wrapper-details-top-right .accept-button{
    background-color: #00bcd4;
    height: 48px;
    font-family: Source Sans Pro;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 18px;
    text-align: left;
    color: #fff;
    padding: 15px 32px 15px 32px;
    border: none;
    outline: 0;
    border-radius: 4px;
    cursor: pointer;
    text-transform: uppercase
}


.card-wrapper-details-top-right .reject-button{
    border-radius: 4px;
    padding: 13px 32px 13px 32px;
    border: 2px solid #00bcd4;
    font-family: Source Sans Pro;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 18px;
    cursor: pointer;
    background-color: transparent;
    text-transform: uppercase 
}


.card-wrapper-details-bottom {
    width: 100%;
    border-radius: 4px;
}

.card-wrapper-details-bottom h5 {
    margin: 0;
    margin-bottom: 4px;
    padding: 0;
    font-family: Lora;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 26px;
}

.card-wrapper-details-bottom-msgbox {
    width: 100%;
    height: fit-content;
    background-color: white;
    padding: 16px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.card-wrapper-details-bottom-msgbox textarea {
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 24px;
}

.card-wrapper-details-bottom-msgbox p {
    font-size: 20px;
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 14px;
    margin: 0;
    margin-top: -2px;
    margin-bottom: 8px;
    padding: 0;
    color: #656565;
}

.card-wrapper-details-bottom-msgbox div {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.card-wrapper-details-bottom-msgbox div textarea {
    height: 100px;
    padding: 20px;
    border: none;
    flex: 1;
}

.card-wrapper-details-bottom-msgbox div button {
    height: 40px;
    width: 108px;
    margin-left: 16px;
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 18px;
    border: 2px solid #40ccde;
    border-radius: 4px;
    text-transform: uppercase;
    cursor: pointer;
    color: #000;
}

.card-wrapper-details-bottom-msgbox div button img {
    position: relative;
    top: 4px;
    margin-right: 7.44px;
}

.card-wrapper-details-bottom p {
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 16px;
    margin-bottom: 20px !important;
    margin: 4px 0;
    padding: 0;
    letter-spacing: 0.4px;
    color: #656565;
}

.card-wrapper-details-bottom-comment-btn {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.card-wrapper-details-bottom-comment-btn button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 182px;
    height: 40px;
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 18px;
    border: none;
    text-align: center;
    vertical-align: middle;
    line-height: 40px;
    margin-top: 24px;
    text-transform: uppercase;
    color: #0097a7;
    cursor: pointer;
}

.card-wrapper-details-bottom-comment-btn button img {
    margin: auto 12px auto 8px;
}

.card-wrapper-details .show-hide-message {
    width: 200px;
    padding: 10px;
    background: #00838F;
    border-radius: 8px;
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    color: #FFFFFF;
    float: right;
    margin-bottom: 10px;
}



@media screen and (max-width: 868px) {
    .card-wrapper-container{
        display:flex;
        flex-direction:column;
        justify-content: center;
        align-items:center !important;
    }

    .card-wrapper {
        width:100%;
        height: -moz-fit-content;
        height: fit-content;
        background: #ededed;
        box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px rgba(0, 0, 0, 0.12), 0px 1px 3px rgba(0, 0, 0, 0.2);
        border-radius: 4px;
        display: flex;
        justify-content: center;
        align-items:center !important;
        
      }

    .card-wrapper-details-top-left-profile {
        display: flex;
        height: 100%;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        width: 100%;
      }

      .card-wrapper-details-top {
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        gap:10px;
        justify-content: space-between;
        
        
    }

    .card-wrapper-details-top-right {
        display: flex;
        flex-direction: column;
        justify-content: center !important;
        align-items: center !important;
      }

      .card-wrapper-details-top-right .accept-button{
        background-color: #00bcd4;
        height: 48px;
        font-family: Source Sans Pro;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: 18px;
        text-align: left;
        color: #fff;
        padding: 15px 80px 15px 80px;
        border: none;
        outline: 0;
        border-radius: 4px;
        cursor: pointer;
        text-transform: uppercase;
        margin-bottom:20px ;
    }
    
    
    .card-wrapper-details-top-right .reject-button{
        border-radius: 4px;
        padding: 15px 80px 15px 80px;
        border: 2px solid #00bcd4;
        font-family: Source Sans Pro;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: 18px;
        cursor: pointer;
        background-color: transparent;
        text-transform: uppercase 
    }

    .card-wrapper-details-top-left {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        
      }

      .card-wrapper-details-top-center {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }

      .card-wrapper-details-top-center h2 {
        margin: auto 0;
        padding: 0;
        font-family: Source Sans Pro;
        font-style: normal;
        font-weight: bold;
        font-size: 60px;
        line-height: 91px;
        color: #000;
      }


      .card-wrapper-details-top-left-profile-details .buyer-financing-option {
        display: flex;
        justify-content: center;
        align-items: center;
        height:100%;
        width: 200px;
        margin-top: 22px;
      }
}

@media screen and (max-width:1000px){
    .MuiCollapse-wrapper{
        display: block !important;
    }
}

@media screen and (max-width:768px){
    .answer-message-box div {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        flex-direction: column;
    }
    .response-box{
        width: 100%;
    }
    .answer-message-box{
    position: relative;

    }
    .answer-message-box button {
       position: absolute;
       right: 5px;
       bottom: 8px;
    }
}



@media screen and (max-width:416px){
    .card-wrapper {
        width: 100%;
    }
}