.registration {
  margin: 0 auto;
  text-align: center;
  display: flex;
  flex-direction: row;
  width: 100%;
  padding: 0;
}

.registration .left-part {
  width: 50%;
  height: 100vh;
  display: flex;
  position: sticky;
  top: 0;
}
.registration .left-part .abidors-logo-container {
  width: 100%;
  height: 100vh;
  background-color: #012324;
  display: flex;
  justify-content: center;
  align-items: center;
}
.registration .left-part .abidors-logo-container > img {
  width: 400px;
  object-fit: cover;
  cursor: pointer;
}

.registration .right-part {
  margin: 30px;
  width: 40%;
  display: flex;
  flex-direction: column;
}

.registration .registration-title {
  font-size: 55px;
  line-height: 52px;
  font-weight: 700;
  margin-bottom: 8px;
  text-transform: capitalize;
}

.registration .sub-title {
  font-size: 33px;
  line-height: 40px;
  font-weight: 200;
  text-align: center;
  margin-top: 10px;
}

.registration .registration-form .registration-button {
  padding: 20px 32px;
  background: #00bcd4;
  color: #fff;
  border-radius: 4px;
  font-size: 20px;
  line-height: 18px;
  font-weight: 700;
  width: 100%;
  margin-top: 8px;
  text-transform: uppercase;
}

.registration .registration-form .registration-button:hover {
  background: #00bcd4;
}


.registration-form .textField {
  margin-bottom: 15px;
  margin-left: 0 !important;
  background-color: #e2e2e2;
  border-radius: 8px;
}

.registration-form .phone-number .MuiButtonBase-root svg {
  height: 100%;
}

.registration-form .textField.with-helper-text {
  margin-bottom: 4px;
}

.registration-form .with-helper-text {
  margin-bottom: 25px;
  margin-left: 21px;
  color: #000;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.4px;
  font-weight: 400;
}

.registration-form .label {
  float: left;
  font-size: 12px;
  font-weight: bold;
}

.registration-form .checkbox {
  color: #000 !important;
}

.registration-form .formControlLabel {
  width: 100%;
  font-size: 15px;
  line-height: 18px;
  font-weight: 400;
}

.registration-form .label-terms {
  color: inherit !important;
  font-weight: 700;
  text-decoration: underline !important;
}

.line-with-or {
  margin-top: 8px;
  width: 100%;
  text-align: center;
  position: relative;
  justify-content: center;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.line-with-or .left {
  width: 40%;
  height: 5px;
  background: linear-gradient(to left, #012324 0%, #04868a, #fff 100%);
  border-radius: 6px;
}

.or-text {
  display: inline-block;
  font-size: 25px;
  margin: 0px 8px;
}
.line-with-or .right {
  width: 40%;
  height: 5px;
  background: linear-gradient(to right, #012324 0%, #04868a, #fff 100%);
  border-radius: 6px;
}

.registration .right-part .you-have-already-account {
  margin-top: 15px;
  font-size: 20px;
  font-weight: 400;
  line-height: 45px;
  text-align: center;
  margin-bottom: 0;
}

.registration .right-part .you-have-already-account .login-text {
  font-size: 20px;
  font-weight: 700;
  line-height: 45px;
  text-align: center;
  color: rgba(0, 188, 212, 1);
  text-transform: uppercase;
}

.social-login-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.recaptcha-container {
  display: flex;
  margin-top: 10px;
  justify-content: center;
  align-items: center;
}

.registration .back-to-search-page {
  align-items: center;
  background: #fff;
  border-radius: 50px;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.18);
  display: flex;
  line-height: 1.4;
  width: 80px;
  position: absolute;
  top: 5px;
  z-index: 1000;
  padding: 0px 6px;
  margin: 0px 4px;
  cursor: pointer;
}

.registration .back-to-search-page .prev-btn-search {
  cursor: pointer;
  font-size: 40px;
  color: #00bcd4;
  z-index: 999 !important;
  height: 34px;
}

.registration .back-to-search-page .image-back-search {
  width: 40px;
  height: 40px;
}

@media screen and (max-width: 1300px) {
}

@media screen and (max-width: 1254px) {
}

@media screen and (max-width: 1001px) {
  .registration-container .col {
    margin-bottom: 40px;
  }
  .registration .left-part {
    width: 50%;
  }
  .registration .right-part {
    width: 50%;
  }
  .registration .left-part .abidors-logo-container > img {
    width: 300px;
  }
}

@media screen and (max-width: 800px) {
    .registration .left-part{
        display: none;
    }
    .registration .right-part{
        width: 100%;
        padding: 10px 50px;
    }
}

@media screen and (max-width: 768px) {
  .recaptcha-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 0px;
  }
}

@media screen and (max-width: 767px) {
  .registration-container .cols-full {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .registration .right-part {
    margin: 30px 15px;
  }

  .registration-container .other-registration .cols {
    margin-bottom: 0px !important;
  }

  .registration-container .col.col3of12 {
    display: none;
  }

  /* .registration-container .other-registration .title-sub {
        padding: 0 50px !important;
    } */

  .registration-container .col.col6of12 {
    width: 70%;
  }
}

@media screen and (max-width: 560px) {
  .registration-container .col.col6of12 {
    width: 100%;
  }
  .registration .right-part{
    width: 100%;
    padding: 10px 0px;
}
  .registration .registration-title {
    font-size: 40px !important;
    line-height: 44px !important;
  }

  .registration .sub-title {
    margin-bottom: 30px !important;
    font-size: 18px;
    line-height: 20px;
  }
  .registration .right-part .you-have-already-account {
    font-size: 15px;
    line-height: 15px;
    margin-top: 25px;
  }
  
  .registration .right-part .you-have-already-account .login-text {
    font-size: 15px;
    line-height: 15px;
  }

  .registration-form .formControlLabel .MuiTypography-body1 {
    margin-left: 10px !important;
    text-align: initial !important;
  }
}
