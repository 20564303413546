.preparation-before-view {
    margin-top: 35px;
    margin-bottom: 64px;
}

.preparation-before-view .title-content {
    margin-bottom: 32px;
}

.preparation-before-view .title-content .title {
    margin-bottom: 8px;
    font-family: Lora;
    font-style: normal;
    font-weight: bold;
    font-size: 32px;
    line-height: 32px;
    text-align: center;
    color: #000000;
}

.preparation-before-view .title-content .title-sub {
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 24px;
    text-align: center;
    color: #000000;
}

.preparation-before-view .questions {
    display: block;
    position: relative;
    padding: 32px;
    background: #F8F8F8;
    border-radius: 10px;
}

.preparation-before-view .questions .title {
    margin-bottom: 16px;
    font-family: Lora;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 26px;
    color: #000000;
    text-align: left;
}

.preparation-before-view ol {
    padding-left: 20px;
}

.preparation-before-view .questions .li {
    margin-bottom: 10px;
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 24px;
    color: #000000;
    text-align: justify;
}

.preparation-before-view .questions .li::marker {
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: 900;
    font-size: 18px;
    line-height: 22px;
    text-align: right;
    text-transform: uppercase;
    color: #000000;
}


.preparation-before-view .title-content .pending-approval-for-the-meeting {
    margin: 0 auto 20px auto;
    width: 670px;
    padding: 24px;
    background: #F8F8F8;
    border-radius: 10px;
}

.preparation-before-view .title-content .pending-approval-for-the-meeting img {
    width: 48px;
    display: block;
    margin: 0 auto;
    height: 48px;
    margin-bottom: 16px;
}

.preparation-before-view .title-content .pending-approval-for-the-meeting .title-sub {
    margin-bottom: 8px;
    font-family: Lora;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 26px;
    text-align: center;
}

.preparation-before-view .title-content .pending-approval-for-the-meeting .title {
    margin-bottom: 24px;
    font-family: Lora;
    font-style: normal;
    font-weight: bold;
    font-size: 32px;
    line-height: 32px;
    color: #000000;
}

.preparation-before-view .title-content .pending-approval-for-the-meeting .text {
    margin-bottom: 16px;
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 24px;
    text-align: center;
    color: #000000;
}

.preparation-before-view .title-content .pending-approval-for-the-meeting .buttons .change-date-meeting {
    padding: 15px 32px;
    background: #F8F8F8;
    border-radius: 4px;
    border: 1px solid #00BCD4;
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 18px;
    text-transform: uppercase;
    color: #000000;
    display: block;
    margin: 0 auto;
}

.preparation-before-view .title-content .buyer-text {
    margin-bottom: 10px;
    display: block;
    position: relative;
}

.preparation-before-view .title-content .buyer-text li {
    margin-bottom: 5px;
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 24px;
    color: #000000;
    text-align: justify;
}

.preparation-before-view img {
   border-radius: 10px;
}

@media screen and (max-width: 1000px) {
    .preparation-before-view .col6of12 {
        width: 100%;
    }
}


@media screen and (max-width: 765px) {
    .preparation-before-view .title-content .pending-approval-for-the-meeting {
        width: 100%;
    }
}

@media screen and (max-width: 432px){
    .preparation-before-view .title-content .pending-approval-for-the-meeting .title {
        font-size: 26px;
    }

    .preparation-before-view .title-content .title-sub {
        text-align: justify;
    }
}