.createAnAddFlow {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
.createAnAddFlow > h1 {
  font-size: 40px;
  font-weight: 700;
  line-height: 50px;
  margin-bottom: 0;
  text-align: center;
  padding: 10px;
  color: rgba(1, 35, 36, 1);
}
.createAnAddFlow > p {
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  margin-top: 10px;
  max-width: 969px;
  text-align: center;
}
.createAnAddFlow .step-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 32px;
}
.createAnAddFlow .step-item {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.createAnAddFlow .step-item > div {
  width: 64px;
  height: 64px;
  border-radius: 50%;
  background-color: #f4f4f4;
  display: flex;
  align-items: center;
  justify-content: center;
}
.createAnAddFlow .step-item > div > p {
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px;
  color: #8c8c8c;
}
.createAnAddFlow .step-item > p {
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  margin-top: 10px;
}
.createAnAddFlow .dashed-line {
  width: 64px;
  height: 0px;
  border-top: 2px dashed #000000;
  margin-bottom: 30px;
}
.createAnAddFlow .currentStep {
  margin-right: 15px;
}
.createAnAddFlow .currentStep > div {
  width: 92px;
  height: 92px;
  background-color: #006064;
}
.createAnAddFlow .currentStep > div > p {
  color: white;
  font-weight: 500;
  font-size: 72px;
}
.createAnAddFlow .currentStep > p {
  font-weight: 600;
}

@media screen and (max-width:520px){
  .createAnAddFlow .step-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 32px;
    flex-direction: column;
    gap:20px
  }
  .createAnAddFlow .dashed-line {
    width: 64px;
    height: 0px;
    border-top: 2px dashed #000000;
    margin-bottom: 30px;
    transform: rotate(90deg);
  }
}
