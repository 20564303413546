.feature-item {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    background: #f8f8f8;
    border-radius: 8px;
    padding: 20px;
    > img {
        margin-top: 40px;
        margin-bottom: 15px;
        width: 76px;
        height: 77px;
    }
    > h5 {
        margin: 0;
        padding: 0;
        font-family: 'Lora', sans-serif;
        font-style: normal;
        font-weight: bold;
        font-size: 24px;
        line-height: 26px;
        text-align: center;
        color: #000000;
        margin: 8px 0;
    }
    > p {
        max-width: 374px;
        text-align: center;
        font-family: 'Source Sans Pro';
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        line-height: 24px;
        text-align: center;
        color: #000000;
        margin-bottom: 16px;
    }
}