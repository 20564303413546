.scoreboard-wrapper {
  >.header {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding-top: 16px;
    background: #ffffff;
    border-radius: 4px;

    .box {
      display: flex;
      justify-content: space-between;
      width: 100%;
      margin-bottom: 16px;

      .text-content {
        margin-left: 16px;

        >h3 {
          font-family: Lora;
          font-size: 24px;
          font-style: normal;
          font-weight: 700;
          line-height: 26px;
          letter-spacing: 0em;
          text-align: left;
        }

        >p {
          margin-top: 8px;
          font-family: Source Sans Pro;
          font-size: 18px;
          font-style: normal;
          font-weight: 400;
          line-height: 24px;
          letter-spacing: 0em;
          text-align: left;
        }
      }

      >button {
        margin-right: 16px;
      }
    }

    .progress-wrapper {
      height: 8px;
      width: 100%;
      background: #ededed;
      border-bottom-right-radius: 4px;
      border-bottom-left-radius: 4px;

      >div {
        height: 100%;
        background-color: #00838f;
      }
    }
  }

  .body {
    position: relative;
    display: flex;
    width: 100%;
    margin-top: 24px;

    >.left {
      width: 44%;

      >.statistics {
        width: 100%;
        background-color: white;
        padding-top: 16px;
        padding-bottom: 16px;
        border-radius: 4px;

        >span {
          display: flex;
          margin: 8px 0px;
          align-items: center;

          >img {
            margin-right: 10px;
          }

          >p {
            font-family: Source Sans Pro;
            font-size: 18px;
            font-style: normal;
            font-weight: 700;
            line-height: 24px;
          }
        }
      }

      >.user-questions {
        margin-top: 24px;
        background-color: white;
        border-radius: 4px;
        padding-top: 16px;
        padding-left: 16px;
        padding-right: 16px;

        >h2 {
          font-family: Lora;
          font-size: 24px;
          font-style: normal;
          font-weight: 700;
          line-height: 26px;
        }

        >p {
          margin-top: 8px;
          font-family: Source Sans Pro;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 18px;
        }
      }

      >.responsible-section-wrapper {
        padding-top: 24px;
        background-color: white;
        display: flex;
        flex-direction: column;
        align-items: center;

        >.header {
          width: calc(100% - 36px);
          display: flex;
          justify-content: space-between;
          align-items: center;

          >h2 {
            font-family: Source Sans Pro;
            font-size: 18px;
            font-style: normal;
            font-weight: 900;
            line-height: 22px;
            letter-spacing: 0em;
            text-align: left;
          }

          >p {
            font-family: Source Sans Pro;
            font-size: 14px;
            font-style: normal;
            font-weight: 600;
            line-height: 20px;
            letter-spacing: 0em;
            text-align: right;
          }
        }
      }
    }

    >.right {
      width: 56%;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding-left: 24px;

      >.vipAnnouncements {
        // margin-top: 24px;
        background-color: white;
        border-radius: 4px;
        display: flex;
        padding: 16px;

        >div {
          display: flex;
          flex-direction: column;
          max-width: 50%;

          >h1 {
            font-family: Lora;
            font-size: 24px;
            font-style: normal;
            font-weight: 700;
            line-height: 26px;
            margin-bottom: 8px;
          }

          >p {
            font-family: Source Sans Pro;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 18px;
            margin-bottom: 24px;
          }

          >button {
            width: fit-content;
          }
        }

        >.right {
          align-items: center;
          justify-content: center;
          width: 100%;

          >img {
            width: 128px;
            height: 128px;
          }
        }
      }

      >.pricing-section {
        margin-top: 24px;
        width: calc(100% - 32px);
        background-color: white;
        padding: 16px;
        display: flex;
        flex-direction: column;

        >h1 {
          font-family: Lora;
          font-size: 24px;
          font-style: normal;
          font-weight: 700;
          line-height: 26px;
        }

        >.content {
          display: flex;
          flex-direction: column;

          >.top {
            display: flex;
            justify-content: space-between;
            margin-top: 16px;
            align-items: center;

            >h2 {
              font-family: Source Sans Pro;
              font-size: 36px;
              font-style: normal;
              font-weight: 700;
              line-height: 45px;
              padding: 3px 8px;
              background: #e0f7fa;
              width: fit-content;
            }

            >div {
              display: flex;
              flex-direction: column;

              >span {
                display: flex;
                justify-content: flex-end;

                >p {
                  font-family: Source Sans Pro;
                  font-size: 14px;
                  font-style: normal;
                  font-weight: 700;
                  line-height: 18px;
                  background-color: #e0f7fa;
                  padding: 3px 8px;
                  margin-top: 5.5px;
                  margin-bottom: 5.5px;
                  margin-right: 6px;
                }
              }
            }
          }

          >.bottom {
            margin-top: 16px;

            >div {
              width: 100%;
              height: 40px;
              background-color: #fff59d;
              display: flex;
              justify-content: center;
              align-items: center;
              border-radius: 4px;

              >h3 {
                font-family: Source Sans Pro;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 18px;
                margin-left: 5px;
              }

              >img {
                width: 29px;
              }
            }

            >p {
              margin-top: 8px;
              font-family: Source Sans Pro;
              font-size: 16px;
              font-style: normal;
              font-weight: 400;
              line-height: 18px;
              margin-bottom: 24px;
            }
          }
        }
      }
    }
  }
}