.photographer-submit-inquiry-modal .photographer-profile-modal-content {
    padding: 21px 21px;
}

.photographer-submit-inquiry-modal .photographer-profile-modal-content .title-header .close-icon {
    float: right;
    cursor: pointer;
}

.photographer-submit-inquiry-modal .photographer-profile-modal-content .photographer-profile-modal-content-inner {
    margin: 0 45px;
    padding-top: 40px;
}

.photographer-submit-inquiry-modal .photographer-profile-modal-content .photographer-profile-modal-content-inner .title {
    padding: 0px 95px;
    margin-bottom: 8px;
    font-family: Lora;
    font-style: normal;
    font-weight: 1;
    font-size: 48px;
    line-height: 52px;
    text-align: center;
    color: #000000;
}

.photographer-submit-inquiry-modal .photographer-profile-modal-content .photographer-profile-modal-content-inner .title-sub {
    padding: 0px 95px;
    margin-bottom: 24px;
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 24px;
    text-align: center;
    color: #000000;
}

.photographer-submit-inquiry-modal .photographer-profile-modal-content .photographer-profile-modal-content-inner .field {
    width: 100%;
    margin-bottom: 24px;
}

.photographer-submit-inquiry-modal .photographer-profile-modal-content .photographer-profile-modal-content-inner img {
    display: block;
    margin: 0 auto 15px auto;
}

.photographer-submit-inquiry-modal .photographer-profile-modal-content .photographer-profile-modal-content-inner .MuiFormControlLabel-root {
    flex: 1;
}

.photographer-submit-inquiry-modal .photographer-profile-modal-content .photographer-profile-modal-content-inner .checkbox-label {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 16px;
    margin-bottom: 16px;
    background: #F8F8F8;
    min-width: 100%;
}

.photographer-submit-inquiry-modal .photographer-profile-modal-content .photographer-profile-modal-content-inner .checkbox-label .img-briefcase {
    width: 40px;
    margin-bottom: 0;
}

.photographer-submit-inquiry-modal .photographer-profile-modal-content .photographer-profile-modal-content-inner .checkbox-label .text {
    margin-left: 16px;
    flex: 1;
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 24px;
    text-transform: uppercase;
    margin-right: 20px;
}

.photographer-submit-inquiry-modal .photographer-profile-modal-content .photographer-profile-modal-content-inner .checkbox-label .price {
    justify-self: flex-end;
    padding: 8px;
    background: #FFFFFF;
    border-radius: 4px;
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: bold;
    font-size: 38px;
    line-height: 44px;
}

.photographer-submit-inquiry-modal .photographer-profile-modal-content .photographer-profile-modal-content-inner .checkbox-label .price .currency {
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: 900;
    font-size: 22px;
    line-height: 22px;
    text-transform: uppercase;
}

.photographer-submit-inquiry-modal .photographer-profile-modal-content .photographer-profile-modal-content-inner .checkbox-instructions {
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    text-align: center;
    letter-spacing: 0.4px;
    color: #8C8C8C;
}

.photographer-submit-inquiry-modal .photographer-profile-modal-content .photographer-profile-modal-content-inner .title-primary {
    margin-bottom: 8px;
    font-family: Lora;
    font-style: normal;
    font-weight: 1;
    font-size: 48px;
    line-height: 52px;
    text-align: center;
    color: #000000;
}

.photographer-submit-inquiry-modal .photographer-profile-modal-content .photographer-profile-modal-content-inner .title-sub-primary {
    margin: 0 128px 24px 128px;
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 24px;
    text-align: center;
    color: #000000;
}

.photographer-submit-inquiry-modal .photographer-profile-modal-content .photographer-profile-modal-content-inner .request-service-actions {
    display: flex;
    justify-content: center;
    gap: 24px;
    margin-bottom: 43px;
}

.photographer-submit-inquiry-modal .photographer-profile-modal-content .photographer-profile-modal-content-inner .request-service-actions .button-send {
    padding: 15px 32px;
    background: #00BCD4;
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 18px;
    text-transform: uppercase;
    color: #FFFFFF;
    border-radius: 4px;
    outline: none;
    border: none;
}

.photographer-submit-inquiry-modal .photographer-profile-modal-content .photographer-profile-modal-content-inner .request-service-actions .button-close {
    padding: 15px 32px;
    border: 1px solid #00BCD4;
    border-radius: 4px;
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 18px;
    text-transform: uppercase;
    color: #000000;
}

@media screen and (max-width: 900px) {

    .photographer-submit-inquiry-modal .photographer-profile-modal-content .photographer-profile-modal-content-inner .title {
        padding: 0 !important;
    }

    .photographer-submit-inquiry-modal .photographer-profile-modal-content .photographer-profile-modal-content-inner .title-sub {
        padding: 0 !important;
    }

}

@media screen and (max-width: 625px) {
    .photographer-submit-inquiry-modal .photographer-profile-modal-content .photographer-profile-modal-content-inner {
        margin: 0 !important;
    }
}

@media screen and (max-width: 525px) {
    .photographer-submit-inquiry-modal .css-ypiqx9-MuiDialogContent-root {
        padding-left: 0 !important;
        padding-right: 0 !important
    }

    .photographer-submit-inquiry-modal .css-1nrlq1o-MuiFormControl-root {
        margin: 0 auto !important;
        width: 100% !important;
        justify-content: center !important;
        align-items: center !important;
    }
}


@media screen and (max-width: 461px) {
    .photographer-submit-inquiry-modal .photographer-profile-modal-content .photographer-profile-modal-content-inner .checkbox-label .price{
        font-size: 32px;
        line-height: 36px;
    }

    .photographer-submit-inquiry-modal .photographer-profile-modal-content .photographer-profile-modal-content-inner .title{
        font-size: 32px;
        line-height: 36px;
    }
}

@media screen and (max-width: 417px) {
    .photographer-submit-inquiry-modal .photographer-profile-modal-content .photographer-profile-modal-content-inner .checkbox-label .price{
        font-size: 25px;
        line-height: 28px;
    }

    .photographer-submit-inquiry-modal .photographer-profile-modal-content {
        padding: 10px !important;
    }

 
}