.dialog-common-multi-profile {
  text-align: center;
}

.dialog-va-education {
  padding: 20px 35px;
}

.dialog-va-education .title-header {
  padding: 0;
  margin-bottom: 8px;
}

.dialog-va-education .title-header .title {
  display: inline-block;
  font-size: 32px;
  line-height: 32px;
  font-family: Lora;
  font-weight: 700;
}

.dialog-va-education .title-header .close-icon {
  display: inline-block;
  cursor: pointer;
  float: right;
}

.dialog-va-education .content {
  padding: 0;
  overflow-x: hidden;
}

.dialog-va-education .content .sub-title {
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 20px;
  font-family: "Source Sans Pro";
  padding: 0 40px;
}

.dialog-va-education .content .actions {
  margin-top: 8px;
  display:flex;
  justify-content:center;
  align-items:center;
  gap:20px;
}

.dialog-va-education .content .actions .button-continue {
  height: 45px;
  padding: 15px 32px;
  background: #00bcd4;
  border-radius: 4px;
  color: #fff;
  text-transform: uppercase;
  margin-right: 25px;
  font-size: 14px;
  line-height: 18px;
  font-family: "Source Sans Pro";
  font-weight: 700;
}

.dialog-va-education .content .actions .button-back {
  height: 45px;
  padding: 15px 32px;
  border: 2px solid #00bcd4;
  border-radius: 4px;
  color: #000;
  text-transform: none;
  font-size: 14px;
  line-height: 18px;
  font-family: "Source Sans Pro";
  font-weight: 700;
}

.dialog-va-education .content .video {
  height: 578px;
  margin-bottom: 24px;
  background-color: #f8f8f8;
  border-radius: 4px;
  position: relative;
  justify-content: center;
  align-items: center;
  display: flex;
}

.dialog-va-education .content .video .video-play {
  width: 100%;
  height: 100%;
}

.dialog-va-education .content .button-next {
  cursor: pointer;
  padding: 15px 32px;
  background: #00bcd4;
  border-radius: 4px;
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  text-transform: uppercase;
  color: #ffffff;
}

.dialog-va-education .content .video .video-play-button {
  cursor: pointer;
}
.dialog-va-education .content .video .video-play-button-container {
  position: absolute;
  top: 40%;
  z-index: 1;
}

@media screen and (max-width: 1321px) {
  .dialog-va-education .content .video {
    height: 100%;
    margin-bottom: 24px;
    background-color: #f8f8f8;
    border-radius: 4px;
    position: relative;
    justify-content: center;
    align-items: center;
    display: flex;
  }

  .dialog-va-education .content .video-play-button-container {
    position: absolute;
    top: 45%;
    left: 45%;
    z-index: 1;
  }
}

@media screen and (max-width: 768px) {
  .dialog-va-education .content .video-play-button-container {
    position: absolute;
    top: 35%;
    left: 45%;
    z-index: 1;
  }
}

@media screen and (max-width: 605px) {
  .dialog-va-education .content {
    padding: 74px 25px 128px 25px;
    text-align: center;
  }
}

@media screen and (max-width: 500px) {
    .dialog-va-education .content .video-play-button {
    width: 50px;
    height: 50px;
  }
}

@media screen and (max-width: 500px) {
  .dialog-va-education .content .video-play-button {
    width: 40px;
    height: 40px;
  }
}
