.profile-virtual-assistant-relationship {
  padding: 24px 10px;
}

.profile-virtual-assistant-relationship .header-content .avatar {
  display: flex;
  width: 20%;
}
.profile-virtual-assistant-relationship .header-content .avatar .photo {
  border-radius: 50%;
  width: 110px;
  height: 110px;
  position: relative;
  margin-bottom: 7px;
}
.profile-virtual-assistant-relationship .header-content .avatar .photo .icon {
  filter: invert(44%) sepia(55%) saturate(6704%) hue-rotate(163deg)
    brightness(99%) contrast(101%);
  position: absolute;
  bottom: 0;
  right: 0;
}
.profile-virtual-assistant-relationship .header-content .avatar .location {
  display: flex;
  align-items: center;
  justify-content: center;
}
.profile-virtual-assistant-relationship
  .header-content
  .avatar
  .location
  .icon {
  margin-right: 4px;
}
.profile-virtual-assistant-relationship
  .header-content
  .avatar
  .location
  .text {
  font-size: 14px;
  line-height: 16px;
  font-family: Roboto;
  font-weight: 400;
  margin-top: 3px;
}

.profile-virtual-assistant-relationship .header-content .user-details {
  margin-left: 16px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 80%;
}
.profile-virtual-assistant-relationship .header-content .user-details .title {
  font-size: 48px;
  line-height: 52px;
  margin-bottom: 16px;
  font-family: Lora;
  font-weight: 700;
  word-wrap: break-word;
  max-width: 100%;
}

.profile-virtual-assistant-relationship .header-content .user-details .rating {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.profile-virtual-assistant-relationship
  .header-content
  .user-details
  .rating
  .title {
  font-size: 18px;
  line-height: 22px;
  font-weight: 900;
  font-family: Source Sans Pro;
  margin-right: 8px;
  text-transform: uppercase;
  margin-bottom: 0;
}
.profile-virtual-assistant-relationship
  .header-content
  .user-details
  .rating
  .star {
  width: 16px;
  height: 16px;
  margin-right: 3px;
  filter: invert(44%) sepia(55%) saturate(6704%) hue-rotate(163deg)
    brightness(99%) contrast(101%);
}
.profile-virtual-assistant-relationship
  .header-content
  .user-details
  .rating
  .star:last-child {
  margin-right: 0;
}
.profile-virtual-assistant-relationship
  .header-content
  .user-details
  .rating
  .result {
  font-size: 18px;
  line-height: 24px;
  font-weight: 400;
  font-family: Source Sans Pro;
  margin-left: 8px;
}

.profile-virtual-assistant-relationship
  .header-content
  .user-details
  .additional {
  margin-top: 14px;
  display: flex;
  text-align: left;
}
.profile-virtual-assistant-relationship
  .header-content
  .user-details
  .additional
  .first-half
  .title {
  color: #656565;
  font-size: 16px;
  line-height: 19px;
  font-weight: 400;
  font-family: Roboto;
  margin-bottom: 6px;
}
.profile-virtual-assistant-relationship
  .header-content
  .user-details
  .additional
  .block {
  background: #ffffff;
  border-radius: 4px;
  padding: 16px;
}
.profile-virtual-assistant-relationship
  .header-content
  .user-details
  .additional
  .block
  .icon {
  width: 20px;
  height: 20px;
  color: #000000;
  margin-right: 11px;
}
.profile-virtual-assistant-relationship
  .header-content
  .user-details
  .additional
  .block
  .text {
  font-size: 18px;
  line-height: 24px;
  font-family: Source Sans Pro;
  font-weight: 700;
  flex: none;
}
.profile-virtual-assistant-relationship
  .header-content
  .user-details
  .additional
  .second-half {
  margin-left: 24px;
}
.profile-virtual-assistant-relationship
  .header-content
  .user-details
  .additional
  .second-half
  .title-content {
  display: flex;
}
.profile-virtual-assistant-relationship
  .header-content
  .user-details
  .additional
  .second-half
  .title-content
  .title {
  color: #656565;
  font-size: 16px;
  line-height: 19px;
  font-weight: 400;
  font-family: Roboto;
  margin-bottom: 6px;
}
.profile-virtual-assistant-relationship
  .header-content
  .user-details
  .additional
  .second-half
  .title-content
  .icon {
  width: 20px;
  height: 20px;
  color: #000000;
  margin-left: 11px;
  margin-top: -1px;
}

.profile-virtual-assistant-relationship .about-info {
  margin: 32px 0;
  padding: 16px;
  background: #ffffff;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px rgba(0, 0, 0, 0.12),
    0px 1px 3px rgba(0, 0, 0, 0.2);
  border-radius: 12px;
  text-align: left;
}
.profile-virtual-assistant-relationship .about-info .title {
  margin-bottom: 6px;
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
  text-transform: uppercase;
  color: #000000;
}
.profile-virtual-assistant-relationship .about-info .paragraph {
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 24px;
  color: #000000;
  word-wrap: break-word;
  overflow-wrap: break-word;
  white-space: normal;
}

.profile-virtual-assistant-relationship .content-boxes {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 32px;
  text-align: center;
}
.profile-virtual-assistant-relationship .content-boxes .content {
  padding: 42px 32px 24px 32px;
  background: #ffffff;
  border-radius: 8px;
  text-align: center;
}
.profile-virtual-assistant-relationship .content-boxes .content img {
  margin-bottom: 21px;
}
.profile-virtual-assistant-relationship .content-boxes .content .title {
  margin-bottom: 8px;
  font-family: Lora;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 26px;
  text-align: center;
  color: #000000;
}
.profile-virtual-assistant-relationship .content-boxes .content .text {
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 24px;
  text-align: center;
  color: #000000;
}

.profile-virtual-assistant-relationship .meeting-box {
  padding: 20px 16px 16px 16px;
  background: #ffffff;
  border-radius: 4px;
  text-align: center;
}
.profile-virtual-assistant-relationship .meeting-box .title-box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 15px;
}
.profile-virtual-assistant-relationship .meeting-box .title-box .icon {
  margin-bottom: 8px;
}
.profile-virtual-assistant-relationship .meeting-box .title-box .title {
  font-size: 18px;
  line-height: 22px;
  font-family: Source Sans Pro;
  font-weight: 900;
  text-transform: uppercase;
}
.profile-virtual-assistant-relationship .meeting-box .button {
  height: 55px;
  padding: 19px 48px;
  border-radius: 4px;
  background: #00bcd4;
  color: #fff;
  font-size: 14px;
  line-height: 18px;
  font-family: Source Sans Pro;
  font-weight: 700;
  text-transform: uppercase;
}

@media screen and (max-width: 767px) {
  .profile-virtual-assistant-relationship {
    padding: 0 5px 20px 5px;
  }

  .profile-virtual-assistant-relationship .header-content .user-details {
    margin-left: 60px;
  }

  .profile-virtual-assistant-relationship .about-info {
    margin: 32px 0;
  }

  .profile-virtual-assistant-relationship .content-boxes {
    grid-template-columns: 1fr;
  }

  profile-virtual-assistant-relationship .cols {
    margin: 0;
  }
  .profile-virtual-assistant-relationship .header-content .user-details {
    width: 100%;
  }
  .profile-virtual-assistant-relationship .header-content .avatar {
    justify-content: center;
    width: 100%;
  }
}
