.dialog-property-view-modal .title-header {
  padding: 0;
  margin-bottom: 8px;
  display: flex;
  flex-direction: row;
  position: relative;
  width: 100%;
}
.dialog-property-view-modal-content {
  padding: 20px 35px;
}

.dialog-property-view-modal-content .title-header .title {
  display: inline-block;
  font-size: 32px;
  line-height: 32px;
  font-family: Lora;
  font-weight: 700;
  text-align: center;
  width: 96%;
}

.dialog-property-view-modal-content .title-header .close-icon {
  display: flex;
  position: absolute;
  cursor: pointer;
  right: 20px;
  top: 10px;
  width: 40px;
  height: 40px;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  z-index: 999;
}

.dialog-property-view-modal-content .maps {
  position: relative;
  height: 67vh;
  width: 100%;
}

.dialog-property-view-modal-content .maps .map {
  margin-bottom: 53px;
  padding: 70px 95px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url("https://d3kuhob3u5bpy2.cloudfront.net/2bd40c7c-0e69-4d88-9161-27dc0b3fba1a.svg");
  filter: blur(8px);
}

.dialog-property-view-modal-content .maps .map.filter {
  filter: blur(0);
}

.dialog-property-view-modal-content .maps .text {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 243px;
  transform: translate(-50%, -50%);
  text-align: center;
  padding: 8px 16px;
  background-color: #e0f7fa;
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  text-transform: uppercase;
  color: #000000;
  cursor: pointer;
}

.dialog-property-view-modal-content .maps .text.filter {
  display: none;
}

.dialog-property-view-modal-content .maps .circle {
  border-radius: 50%;
  position: absolute;
  top: 40%;
  left: 50%;
  width: 110px;
  height: 110px;
  transform: translate(-50%, -50%);
  background: #00bcd4;
  opacity: 0.3;
}

.MuiDialog-paperFullWidth {
  width: 100% !important;
  margin: 0 !important;
  display: flex !important;
}


.dialog-property-view-modal-content .property-video{
  position: relative;
  display: flex;
}
.dialog-property-view-modal-content .property-video .video-play-button {
  cursor: pointer;
}
.dialog-property-view-modal-content .property-video .video-play-button-container {
  position: absolute;
  top: 45%;
  left: 45%;
  z-index: 1;
}
.dialog-property-view-modal-content .property-video .property-video-loader .circular-loader{
  position: absolute;
  top: -6.5px;
  left: -6.5px;
  z-index: 300;
  cursor: pointer;
}
@media screen and (max-width: 600px) {
  .dialog-property-view-modal-content .property-video .property-video-loader {
    height: 250px !important;
  }
  .dialog-property-view-modal-content .property-video .video-play-button-container {
    top: 35%;
  }
}

@media screen and (max-width: 527px) {
  .dialog-property-view-modal-content .property-video .property-video-loader .circular-loader{
    top: -4.4px;
    left: -4.5px;
  }
}
@media screen and (max-width: 500px) {
  .dialog-property-view-modal-content .property-video .property-video-loader .circular-loader{
    top: -3.5px;
    left: -3.5px;
  }
}

@media screen and (max-width: 960px) {
 .dialog-property-view-modal-content{
  padding: 0px 5px;
  padding-top: 10px;
 } 
 .dialog-property-view-modal-content .title-header .close-icon {
  right: 20px;
  top: 20px;
}
}

@media screen and (max-width: 600px) {
  .dialog-property-view-modal-content .title-header .close-icon {
    right: 10px;
    top: 10px;
    width: 35px;
    height: 35px;
  }
  .dialog-property-view-modal-content .title-header .title {
    font-size: 28px;
    line-height: 28px;
    width: 94%;
  }
}