.header-virtual-assistant {
    width: 100%;
    position: sticky;
    top: 0;
    z-index: 999;
    background-color: #fff;
}

.header-virtual-assistant .navbar {
    padding: 14px 32px !important;
    z-index: 999;
    position: relative;
}

.header-virtual-assistant .nav-menu {
    float: left;
    display: flex;
    margin-top: 12px;
    padding-left: 0;
    list-style: none;
}

.header-virtual-assistant .nav-links {
    padding: 0 8px;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    color: #000000;
    cursor: pointer;
    text-decoration: none;
}

.header-virtual-assistant .nav-links.active {
    background-color: rgba(0, 188, 212, 1);
    color: #ffffff;
    border-radius: 30px;
    padding: 10px;

}

.header-virtual-assistant .display-translation-switcher {
    display: none;
}

.header-virtual-assistant .nav-links.dropdown {
    text-transform: lowercase;
    font-size: 12px;
    font-weight: 700;
}

.header-virtual-assistant .nav-links.dropdown span::first-letter {
    text-transform: uppercase;
}

.header-virtual-assistant .nav-links.dropdown:hover {
    background-color: transparent;
}

.header-virtual-assistant .down-caret {
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 5px 5px 0 5px;
    border-color: #000 transparent transparent transparent;
    display: inline-block;
    margin-left: 9px;
    top: -1px;
    position: relative;
    transform: rotate(0deg);
    transition: all .25s ease-in;
}

.header-virtual-assistant .open-caret {
    transform: rotate(180deg);
    transition: all .25s ease-out;
}

.header-virtual-assistant .nav-item.dropdown {
    padding: 3px 0;
}

.header-virtual-assistant #menu-list-grow {
    margin-top: -12px;
}

.header-virtual-assistant .menu-icon {
    display: none;
}

/* logo */
.header-virtual-assistant .navbar-logo {
    float: left;
    margin-right: 20px;
    margin-top: 10px;
}

/* search */
.header-virtual-assistant .search {
    float: left;
    position: relative;
    margin-top: 4px;
    margin-right: 10px;
    background-color: rgba(244, 244, 244, 1);
    border-radius: 50px;
    width: 300px;
    height: 44px;
    margin-left: 10px;
    cursor: pointer;
    padding: 5px 16px;
}

.header-virtual-assistant .search.focus-input-filed {
    outline: 2px solid #00BCD4;
}

.header-virtual-assistant .search .autocomplete-dropdown-container-home {
    margin-top: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    width: 100%;
    display: flex;
    flex-direction: column;
}

.header-virtual-assistant .search-field::placeholder {
    font-weight: 400;
    font-size: 18px;
}

.header-virtual-assistant .search .search-icon {
    float: left;
    margin-top: 4px;
    width: 26px;
    height: 26px;
    filter: invert(49%) sepia(0%) saturate(1%) hue-rotate(100deg) brightness(97%) contrast(89%);
}

.header-virtual-assistant .search-field {
    float: left;
    padding: 16px 10px !important;
    height: 28px;
    border: none;
    background-color: transparent;
    width: 80% !important;
    margin-bottom: 10px;
}

.header-virtual-assistant .drop-down {
    padding: 50px 0 !important
}

/* right part header */
.header-virtual-assistant .right-part {
    float: right;
    display: flex;
    justify-content:space-around;
    align-items: center;
    gap: 20px;
    margin-top: 10px;
}


.header-virtual-assistant .right-part .languages {
    display: none;
}

.header-virtual-assistant .right-part .account-group {
    float: left;
    /* margin-right: 27px; */
}

.header-virtual-assistant .right-part .account-group .link {
    display: flex;
}

.header-virtual-assistant .right-part .account-group .link img {
    float: left;
    width: 24px;
    margin-top: -3px;
    margin-right: 8px;
    filter: invert(33%) sepia(89%) saturate(2251%) hue-rotate(162deg) brightness(97%) contrast(101%);
}

.header-virtual-assistant .right-part .account-group .link .title {
    float: left;
    font-style: normal;
    font-weight: bold;
    margin-top: -2px;
    margin-bottom: 0px;
    font-size: 14px;
    line-height: 18px;
    text-transform: uppercase;
    color: #0097A7;
}

.header-virtual-assistant .right-part .bell {
    position: relative;
    float: left;
    margin-top: -5px;
}

.header-virtual-assistant .right-part .bell .icon {
    filter: invert(33%) sepia(89%) saturate(2251%) hue-rotate(162deg) brightness(97%) contrast(101%);
    width: 18px;
    height: 18px;
}

.header-virtual-assistant .right-part .bell .notification-list-fade-in {
    color: #000;
    position: absolute;
    top: 47px;
    right: -70px;
    padding: 20px 23px;
    width: 410px;
    height: 250px;
    border-radius: 12px;
    background-color: #CFCFCF;
    visibility: visible;
    opacity: 1;
    transition: visibility 0s linear 0s, opacity 300ms;
    z-index: 1;
    overflow-x: hidden;

}

.header-virtual-assistant .right-part .bell .notification-list-fade-in:before {
    content: "";
    position: absolute;
    top: -30px;
    right: 64px;
    z-index: 1;
    border: solid 15px transparent;
    border-bottom-color: #CFCFCF;
}

.header-virtual-assistant .right-part .bell .notification-list-fade-in .row-notification .image {
    float: left;
    width: 10px;
    height: 18px;
    margin-right: 11px;
}

.header-virtual-assistant .right-part .bell .notification-list-fade-in .row-notification .author {
    float: left;
    font-size: 14px;
    font-weight: 700;
    margin-right: 5px;
}

.header-virtual-assistant .right-part .bell .notification-list-fade-in .row-notification .text {
    float: left;
    font-size: 14px;
    font-weight: 400;
    margin-right: 5px;
}

.header-virtual-assistant .right-part .bell .notification-list-fade-in .row-notification .offer-number {
    float: left;
    font-size: 14px;
    font-weight: 700;
}

.header-virtual-assistant .right-part .bell .notification-list-fade-in .hr {
    margin: 12px 0;
    color: rgba(101, 101, 101, 1);
}

.header-virtual-assistant .right-part .bell .notification-list-fade-out {
    width: 0;
    height: 0;
    opacity: 0;
    display: none;
    transition: visibility 0s linear 300ms, opacity 300ms;
}

.header-virtual-assistant .right-part .bell img {
    vertical-align: unset;
}

.header-virtual-assistant .badge { position: absolute; top: -10px !important; right: -13px !important; padding: 2px 4px !important; border-radius: 50% !important; background: #00BCD4; color: white; font-size: 10px !important; }

.header-virtual-assistant .right-part .bell .notification-list-fade-in::-webkit-scrollbar {
    width: 10px !important;
    background: #0097A7;
}

.header-virtual-assistant .right-part .bell .notification-list-fade-in::-webkit-scrollbar-track {
    background: #fff;
    border-radius: 12px;    
}

.header-virtual-assistant .right-part .bell .notification-list-fade-in::-webkit-scrollbar-thumb {
    background-color: #0097A7;
    border-radius: 10px;
}

.header-virtual-assistant .right-part .sidebar-menu-icon{
    margin-top: 5px;
}

.header-virtual-assistant .section-fixed {
    background: rgba(1, 35, 36, 1);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 12px 0;
}
  
.header-virtual-assistant .section-fixed img {
    margin-right: 10px;
    height: 30px;
    width: 30px;
}
  
.header-virtual-assistant .section-fixed .text {
    font-size: 15px;
    font-weight: 700;
    line-height: 30px;
    letter-spacing: 1.0800000429153442px;
    text-align: center;
    color: rgba(255, 255, 255, 1);
    margin-bottom: 0;
}


.header-virtual-assistant .va-bonuses-button{
    display: flex;
    flex-direction: row;
    height: fit-content;
    gap: 7px;
    margin-left: 10px;
    cursor: pointer;
}
.header-virtual-assistant .va-bonuses-button .icon{
    color:#0097A7;
    width:26px;
    border: 1px solid #0097A7;
    border-radius: 5px;
    padding: 1px 2px 1px 2px;
    height: 25px;
}
.header-virtual-assistant .va-bonuses-button .text{
    height: fit-content;
    margin:auto;
    font-size: 16px;
    font-weight: 500;
}

.header-virtual-assistant .mobile-book-icon{
    display: none;
}

.header-virtual-assistant .logo-mobile{
    width: 50px;
    display: flex;
    position: absolute;
    top: 15px;
    z-index: 10;
}


.header-virtual-assistant .navbar .nav-menu-mobile {
    display: none;
}
@media screen and (max-width: 1320px) {
    .header-virtual-assistant .search {
        width: 170px;
    }
}

@media screen and (max-width:1127px){
    .header-virtual-assistant .nav-item {
       display: none;
    }
    
     .header-virtual-assistant .nav-menu {
        float: left;
        display: flex;
        margin-top: 0px;
        padding-left: 0;
        list-style: none;
    }

    .header-virtual-assistant .right-part .bell {
        position: relative;
        float: left;
        margin-right: 0;
    }

    .header-virtual-assistant .right-part{
      display: flex;
      justify-content:space-around;
      align-items: center;
      gap: 20px;
      margin-top: 0;
    }

    .header-virtual-assistant .right-part .account-group {
        float: left;
        margin-right:0;
        display: none;
    }

    .header-virtual-assistant .search {
        width: 40%;
        margin-top: 5px;
    }

    .header-virtual-assistant .lang-switcher {
        padding: 5px 0;
    }
    .header-virtual-assistant .navbar-logo {
        margin-top: 8px;
    }

    .header-virtual-assistant .right-part .notification-icon{
        margin-top: 5px;
    }
    .header-virtual-assistant .right-part .sidebar-menu-icon{
        margin-top: 5px;
    }
    .header-virtual-assistant .navbar .nav-menu .languages {
        margin-top: 5px;
    }
    .header-virtual-assistant .right-part .bell .icon {
        margin-top: 10px;
    }
}

@media screen and (max-width:1068px){
    .header-virtual-assistant .search {
        width: 59%;
        margin-top: 5px;
    }
}

@media screen and (max-width:1044px){
    .header-virtual-assistant .search {
        width: 58%;
        margin-top: 5px;
    }
}

@media screen and (max-width:1021px){
    .header-virtual-assistant .search {
        width: 57%;
        margin-top: 5px;
    }
}

@media screen and (max-width:1000px){
    .header-virtual-assistant .search {
        width: 50%;
        margin-top: 5px;
    }
}

@media screen and (max-width:947px){
    .header-virtual-assistant .search {
        margin-top: 5px;
    }
}

@media screen and (max-width:897px){
    .header-virtual-assistant .search {
        margin-top: 5px;
    }
}

@media screen and (max-width:867px){
    .header-virtual-assistant .search {
        margin-top: 5px;
    }
}

@media screen and (max-width:839px){
    .header-virtual-assistant .search {
        margin-top: 5px;
    }
}

@media screen and (max-width:800px){
    .header-virtual-assistant .search {
        width: 37%;
        margin-top: 5px;
    }
}

@media screen and (max-width:767px){
    .header-virtual-assistant .lang-switcher {
        margin: 0px !important;
    }
    .header-virtual-assistant .right-part .languages {
        display: block;
    }
    
    .header-virtual-assistant .right-part {
        display: flex;
        justify-content: space-around;
        align-items: center;
        gap: 10px;
    }
    
    .header-virtual-assistant .display-translation-switcher {
        display: block;
        float: left;
        margin: 10px 0 0 8px
    }

    .header-virtual-assistant .section-fixed {
        padding: 14px 10px;
    }
      
    .header-virtual-assistant .section-fixed .text {
        font-size: 16px;
    }
    .header-virtual-assistant .mobile-book-icon{
        display: inline;
    }

    .header-virtual-assistant .navbar .nav-menu-mobile {
        float: left;
        display: flex;
        margin-top: 8px;
        padding-left: 30px;
        list-style: none;
        position: relative;
    }
    
    .header-virtual-assistant .navbar  .nav-menu-mobile .navbar-logo {
        float: left;
    }
    
}

@media screen and (max-width:453px) {
    .header-virtual-assistant .section-fixed {
        padding: 3px 10px;
    }

    .header-virtual-assistant .section-fixed img {
        width: 40px;
    }

    .header-virtual-assistant .section-fixed .text {
        font-size: 11px;
        line-height: 21px;
    }
}

@media screen and (max-width:407px){
    .header-virtual-assistant .right-part {
        display: flex;
        justify-content: space-around;
        align-items: center;
        gap: 0px !important;
        margin-top: 5px;
        width: 70%;
        padding-left:10px ;
    }
    .header-virtual-assistant .navbar .nav-menu-mobile {
        padding-left: 50px;
    }
    .header-virtual-assistant .logo-mobile{
        top: 10px;
    }
    
}

@media screen and (max-width:390px){
    .header-virtual-assistant .navbar {
        padding: 9px 10px !important;
        z-index: 999;
    }

    .header-virtual-assistant .right-part .bell {
        margin-top: -9px;
        margin-right: 20px;
    }

    .header-virtual-assistant .navbar-logo {
        width: max-content;
        margin-right: 0px;
        margin-top: 3px;
    }
    .header-virtual-assistant .right-part .notification-icon{
        margin-top: 2.5px;
    }
    .header-virtual-assistant .right-part .sidebar-menu-icon{
        margin-top: 4px;
    }
    .header-virtual-assistant .right-part .languages{
        margin-top: -13px;
    }
}