.exam { padding: 208px 384px 366px 384px; text-align: center}
.exam .title { margin-bottom: 8px; font-family: Lora; font-size: 48px; line-height: 52px; }
.exam .title-sub { margin-bottom: 24px; font-family: Source Sans Pro; font-style: normal; font-weight: normal; font-size: 18px; line-height: 24px; }
.exam .actions .button { margin-right: 24px; cursor: pointer; padding: 15px 32px; background: #00BCD4; border-radius: 4px; font-family: Source Sans Pro; font-style: normal; font-weight: bold; font-size: 14px; line-height: 18px; text-transform: uppercase; color: #FFFFFF; }
.exam .actions .button.back-to-video { background-color: #FFFFFF; border: 1px solid #00BCD4; cursor: pointer; padding: 15px 32px; border-radius: 4px; font-family: Source Sans Pro; font-style: normal; font-weight: bold; font-size: 14px; line-height: 18px; text-transform: uppercase; color: #000000; }


@media screen and (max-width:1171px){
    .exam { 
        padding: 208px 200px 366px 200px; 
        text-align: center; 
       
    }
}

@media screen and (max-width:1241px){
    .exam { 
        padding: 208px 20% 366px 20%; 
        text-align: center; 
    }
}

@media screen and (max-width:804px){
    .exam .actions  { 
        display: flex;
        flex-direction: column;
        gap:20px;
    } 
}

@media screen and (max-width:500px){

    .exam { 
        padding: 100px 10px 366px 10px; 
        text-align: center; 
       
    }
}

@media screen and (max-width:450px){
.exam .actions .button {
     margin-right:0px
    }


}