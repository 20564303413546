.seller-profile {
  margin: 32px 0;
}

.seller-profile .header-content {
  display: flex;
}

.seller-profile .header-content .photo-group {
  text-align: center;
  margin-left: 32px;
}

.seller-profile .header-content .photo-group .id {
  font-size: 12px;
  line-height: 14px;
  margin-top: 7px;
  letter-spacing: 0.4px;
}

.seller-profile .header-content .photo-group .avatar {
  width: 178px;
  height: 178px;
  background-size: cover !important;
  background-repeat: no-repeat !important;
  background-position: center !important;
}

.seller-profile .header-content .photo-group .avatar .profile-image {
  width: 178px;
  height: 178px;
}

.seller-profile .header-content .photo-group .upload-image {
  display: block;
  border-radius: 50%;
  position: relative;
  text-align: center;
  width: 178px;
  height: 178px;
  margin: 0 auto 20px auto;
}

.seller-profile .header-content .photo-group .upload-image img {
  border-radius: 50%;
  width: 178px;
  height: 178px;
}

.seller-profile .header-content .photo-group .upload-image:hover div {
  width: 100%;
  height: 100%;
  background: gray;
  opacity: 0.8;
  border-radius: 50%;
  cursor: pointer;
}

.seller-profile .header-content .photo-group .upload-image:hover div span {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  z-index: 100;
}

.seller-profile
  .header-content
  .photo-group
  .upload-image:hover
  div
  span::before {
  position: absolute;
  content: "";
  height: 50px;
  width: 2px;
  background-color: #fff;
  transform: rotate(45deg);
  left: 50%;
  top: 38%;
}

.seller-profile
  .header-content
  .photo-group
  .upload-image:hover
  div
  span::after {
  position: absolute;
  content: "";
  height: 50px;
  width: 2px;
  background-color: #fff;
  transform: rotate(-45deg);
  left: 50%;
  top: 38%;
}

.seller-profile .header-content .photo-group .upload-image:hover div div {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%) scale(0.4);
  content: url("https://d3kuhob3u5bpy2.cloudfront.net/132dd736-09bf-4ae2-a557-9faf04843292.png");
  width: 178px;
  height: 178px;
}

.seller-profile .header-content .user-details {
  margin-left: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.seller-profile .header-content .user-details .title-text {
  font-size: 48px;
  line-height: 56px;
  margin-bottom: 8px;
}

.seller-profile .header-content .user-details .header-title {
  display: flex;
  margin-bottom: 12px;
}

.seller-profile .header-content .user-details .header-title .icon {
  margin-left: 10px;
}

.seller-profile .header-content .user-details .additional-info .email {
  font-size: 18px;
  line-height: 21px;
  margin-bottom: 8px;
  font-weight: 400;
}

.seller-profile .header-content .user-details .additional-info .phone {
  font-size: 18px;
  line-height: 21px;
  margin-bottom: 37px;
  font-weight: 400;
}

.seller-profile .header-content .user-details .button-edit-profile .button {
  padding: 12px 28px 12px 32px;
  border-radius: 4px;
  font-size: 14px;
  line-height: 18px;
  font-weight: 700;
  text-transform: uppercase;
}

.seller-profile
  .header-content
  .user-details
  .button-edit-profile
  .button:not(:disabled) {
  border: 2px solid #00bcd4;
}

.seller-profile
  .header-content
  .user-details
  .button-edit-profile
  .button:hover {
  background: #00bcd4;
  color: #fff;
}

.seller-profile
  .header-content
  .user-details
  .button-edit-profile
  .button
  .icon {
  margin-top: -3px;
}

.seller-profile .profile-content {
  margin-top: 32px;
}

.seller-profile .profile-content .MuiTabs-scroller {
  width: 100%;
}

.seller-profile .profile-content .MuiTabs-flexContainerVertical {
  height: 230px;
}

.seller-profile .profile-content .root {
  flex-grow: 1;
  background: #f8f8f8;
  display: flex;
  border-radius: 12px;
  margin: 0 32px;
}

.seller-profile .profile-content .root .tabs {
  width: 15%;
  background: #e8e8e8;
  border-radius: 12px 0px 0px 12px;
}

.seller-profile .profile-content .root .tabs .tab {
  border-radius: 12px 0px 0px 12px;
  text-transform: uppercase;
  font-size: 14px;
  line-height: 18px;
  font-family: Source Sans Pro;
  font-weight: 700;
}

.seller-profile .profile-content .root .tabs .tab.separator-tab::before {
  content: "";
  position: absolute;
  height: 1px;
  background-color: #aaaaaa;
  width: 90%;
  top: 0px;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 999px;
}

.seller-profile .profile-content .root .tabs .tab.disabled {
  pointer-events: none;
  opacity: 0.2;
}

.seller-profile .profile-content .root .tabs .tab.active-tab {
  background: #f8f8f8;
}

.seller-profile .profile-content .root .tabs .tab.last-tab {
  position: absolute;
  bottom: 0;
  width: 100%;
}

.seller-profile .profile-content .tab-panel {
  width: 100%;
  text-align: center;
  min-height: 20vw;
}

.seller-profile .hire-lawyer-content {
  margin: 30px 235px;
  padding: 32px 120px;
  background: #f8f8f8;
  border-radius: 10px;
  text-align: center;
}

.seller-profile .hire-lawyer-content .title {
  font-size: 32px;
  line-height: 32px;
  font-weight: 700;
  font-family: Lora;
  margin-bottom: 8px;
}

.seller-profile .hire-lawyer-content .text {
  margin: 0 20px 24px 20px;
  font-size: 18px;
  line-height: 24px;
  font-family: Source Sans Pro;
  font-weight: 400;
}

.seller-profile .hire-lawyer-content .box-content {
  margin-bottom: 24px;
}

.seller-profile .hire-lawyer-content .box-content .box {
  padding: 15px;
  background: #f4f4f4;
  border-radius: 5px;
  text-align: center;
  height: 180px;
}

.seller-profile .hire-lawyer-content .box-content .box .main-icon .icon {
  margin-bottom: 10px;
}

.seller-profile .hire-lawyer-content .box-content .box .text-box {
  font-size: 16px;
  line-height: 18px;
  font-family: Source Sans Pro;
  font-weight: 400;
}

.seller-profile .hire-lawyer-content .button {
  padding: 15px 32px;
  background: #00bcd4;
  color: #fff;
  border-radius: 4px;
  text-transform: uppercase;
  font-family: Source Sans Pro;
  font-size: 14px;
  line-height: 18px;
  font-weight: 700;
}

.seller-profile .information-content {
  margin-bottom: 128px;
  text-align: center;
}

.seller-profile .information-content .title {
  margin-bottom: 16px;
  font-size: 32px;
  line-height: 32px;
  font-family: Lora;
}

.seller-profile .information-content .text {
  margin: 0px 385px 24px 385px;
  font-size: 18px;
  line-height: 24px;
  font-family: Source Sans Pro;
}

.seller-profile .information-content .button {
  padding: 15px 32px;
  border: 2px solid #00bcd4;
  border-radius: 4px;
  text-transform: uppercase;
  font-family: Source Sans Pro;
  font-size: 14px;
  line-height: 18px;
  font-weight: 700;
}

.seller-profile .information-content .button:hover {
  background: #00bcd4;
  color: #fff;
}

.seller-profile .header-content .account-details {
  margin-left: auto;
  margin-right: 32px;
}

.seller-profile .header-content .account-details .button {
  float: right;
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  text-transform: uppercase;
  color: #0097a7;
}

.dialog-loan-status-multi-profile {
  text-align: center;
}

.dialog-loan-status-multi-profile-content {
  padding: 64px 38px;
}

.dialog-loan-status-multi-profile-content .title-header {
  padding: 0;
  margin-bottom: 8px;
}

.dialog-loan-status-multi-profile-content .title-header .title {
  display: inline-block;
  font-size: 32px;
  line-height: 32px;
  font-family: Lora;
  font-weight: 700;
}

.dialog-loan-status-multi-profile-content .title-header .close-icon {
  float: right;
  display: inline-block;
  cursor: pointer;
  margin-top: -43px;
  margin-right: -17px;
}

.dialog-loan-status-multi-profile-content .content {
  padding: 0;
  overflow-x: hidden;
}

.dialog-loan-status-multi-profile-content .content .sub-title {
  font-size: 18px;
  line-height: 24px;
  font-weight: 400;
  margin-bottom: 24px;
  font-family: Source Sans Pro;
  padding: 0 100px;
}

.dialog-loan-status-multi-profile-content .content .box {
  border: 4px solid #e2e2e2;
  padding: 24px 15px;
  background: #e2e2e2;
  border-radius: 10px;
  position: relative;
  margin-bottom: 16px;
  cursor: pointer;
}

.dialog-loan-status-multi-profile-content .content .box.seller {
  background: #f9fbe7;
  border: 4px solid #f9fbe7;
}

.dialog-loan-status-multi-profile-content .content .box.photographer {
  background: #fff8e1;
  border: 4px solid #fff8e1;
}

.dialog-loan-status-multi-profile-content .content .box.active {
  border: 4px solid #00838f;
  cursor: default;
}

.dialog-loan-status-multi-profile-content .content .box.disabled {
  background: #fbe9e7;
  border: 4px solid #fbe9e7;
  opacity: 0.5;
  cursor: not-allowed;
}

.dialog-loan-status-multi-profile-content .content .box .icon {
  height: 64px;
  margin-bottom: 24px;
}

.dialog-loan-status-multi-profile-content .content .box .parts {
  position: absolute;
  left: 45%;
  top: 14%;
  display: inline-grid;
}

.dialog-loan-status-multi-profile-content .content .box .title {
  font-size: 22px;
  line-height: 26px;
  font-weight: 700;
  font-family: Lora;
  margin-bottom: 10px;
}

.dialog-loan-status-multi-profile-content .content .box .text {
  padding: 0;
  font-size: 16px;
  line-height: 18px;
  font-family: Source Sans Pro;
  font-weight: 400;
}

.dialog-loan-status-multi-profile-content .content .actions {
  margin-top: 8px;
}

.dialog-loan-status-multi-profile-content .content .actions .button-continue {
  height: 45px;
  padding: 15px 32px;
  background: #00bcd4;
  border-radius: 4px;
  color: #fff;
  text-transform: uppercase;
  margin-right: 25px;
  font-size: 14px;
  line-height: 18px;
  font-family: Source Sans Pro;
  font-weight: 700;
}

.dialog-loan-status-multi-profile-content .content .actions .button-back {
  height: 45px;
  padding: 15px 32px;
  border: 2px solid #00bcd4;
  border-radius: 4px;
  color: #000;
  text-transform: none;
  font-size: 14px;
  line-height: 18px;
  font-family: Source Sans Pro;
  font-weight: 700;
}

/* Deactivate Account Dialog */
.dialog-deactivate-account-seller-content {
  padding: 22px;
  text-align: center;
}

.dialog-deactivate-account-seller-content .title-header {
  padding: 0;
  margin-bottom: 16px;
}

.dialog-deactivate-account-seller-content .title-header .title {
  font-family: Lora;
  font-style: normal;
  font-weight: bold;
  font-size: 32px;
  line-height: 32px;
  margin-top: 22px;
  padding: 0px 140px;
}

.dialog-deactivate-account-seller-content .title-header .close-icon {
  cursor: pointer;
  float: right;
  margin-top: -24px;
}

.dialog-deactivate-account-seller-content .content {
  padding: 0;
  overflow-x: hidden;
}

.dialog-deactivate-account-seller-content .content .actions {
  margin-top: 24px;
}

.dialog-deactivate-account-seller-content .content .actions .button-continue {
  margin-right: 24px;
  padding: 15px 32px;
  background: #f44336;
  border-radius: 4px;
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  text-transform: uppercase;
  color: #ffffff;
}

.dialog-deactivate-account-seller-content .content .actions .button-back {
  padding: 15px 32px;
  border: 2px solid #00bcd4;
  box-sizing: border-box;
  border-radius: 4px;
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  text-transform: uppercase;
  color: #000000;
}

.seller-profile .menu-burger {
  display: none;
}

.dialog-profile-offer-accepted {
  text-align: center;
}

.dialog-profile-offer-accepted-content {
  padding: 64px 127px;
}

.dialog-profile-offer-accepted-content .title-header {
  padding: 0;
  margin-bottom: 8px;
}

.dialog-profile-offer-accepted-content .title-header .title {
  display: inline-block;
  font-size: 48px;
  line-height: 52px;
  font-weight: 700;
}

.dialog-profile-offer-accepted-content .check-icon {
  margin-bottom: 15px;
  height: 76px;
  width: 76px;
}

.dialog-profile-offer-accepted-content .close-icon {
  cursor: pointer;
  position: absolute;
  top: 30px;
  right: 30px;
}

.dialog-profile-offer-accepted-content .content {
  padding: 0;
  overflow-x: hidden;
}

.dialog-profile-offer-accepted-content .content .sub-title {
  font-size: 18px;
  line-height: 24px;
  font-weight: 400;
  margin-bottom: 32px;
  font-family: Source Sans Pro;
}

.dialog-profile-offer-accepted-content .content .actions {
  margin-top: 8px;
}

.dialog-profile-offer-accepted-content .content .actions .button-close {
  height: 48px;
  padding: 15px 32px;
  background: #00bcd4;
  border-radius: 4px;
  color: #fff;
  text-transform: uppercase;
  font-size: 14px;
  line-height: 18px;
  font-family: Source Sans Pro;
  font-weight: 700;
}

/* Deactivate Account Dialog */
.dialog-deactivate-account-buyer-content {
    padding: 22px;
    text-align: center;
}

.dialog-deactivate-account-buyer-content .title-header {
    padding: 0;
    margin-bottom: 16px;
}

.dialog-deactivate-account-buyer-content .title-header .title {
    font-family: Lora;
    font-style: normal;
    font-weight: bold;
    font-size: 32px;
    line-height: 32px;
    margin-top: 22px;
    padding: 0px 140px;
}

.dialog-deactivate-account-buyer-content .title-header .close-icon {
    cursor: pointer;
    float: right;
    margin-top: -24px;
}

.dialog-deactivate-account-buyer-content .content {
    padding: 0;
    overflow-x: hidden;
}

.dialog-deactivate-account-buyer-content .content .actions {
    margin-top: 24px;
}

.dialog-deactivate-account-buyer-content .content .actions .button-continue {
    margin-right: 24px;
    padding: 15px 32px;
    background: #F44336;
    border-radius: 4px;
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 18px;
    text-transform: uppercase;
    color: #FFFFFF;
}

.dialog-deactivate-account-buyer-content .content .actions .button-back {
    padding: 15px 32px;
    border: 2px solid #00BCD4;
    box-sizing: border-box;
    border-radius: 4px;
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 18px;
    text-transform: uppercase;
    color: #000000;
}


@media screen and (min-width: 1225px) {
  .seller-profile .profile-content .root .tabs {
    display: block;
  }
  .seller-profile .profile-content .root .seller-side-drawer {
    display: none;
  }
}

@media screen and (max-width: 1224px) {
  .seller-profile .profile-content .root .desktop-tabs {
    display: none;
  }
  .seller-profile .profile-content .root {
    position: relative;
  }

  .seller-profile .menu-burger {
    display: block;
    cursor: pointer;
    margin-left: 10px;
    margin-top: 10px;
  }

  .seller-profile .profile-content .root .seller-side-drawer {
    position: absolute;
    left: 0;
    top: 0;
    width: 20%;
    height: 100%;
    background: #ededed;
    z-index: 100;
    border-radius: 12px 0px 0px 12px;
    display: block;
  }

  .seller-profile .profile-content .root .drawer-backdrop {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: #000000;
    opacity: 0.4;
    z-index: 100;
    border-radius: 12px 0px 0px 12px;
    display: block;
  }

  .seller-profile
    .profile-content
    .root
    .seller-side-drawer
    .drawer-header
    .hide-menu {
    cursor: pointer;
    float: right;
  }

  .seller-profile .profile-content .root .seller-side-drawer .drawer-tabs {
    width: 100% !important;
  }

  .seller-profile .hire-lawyer-content .box-content {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  .seller-profile .hire-lawyer-content .box-content .col4of12 {
    width: 100%;
  }

  .seller-profile .hire-lawyer-content .text {
    margin: 0px;
    text-align: left;
  }

  .seller-profile .information-content .text {
    margin: 0px 200px 24px 200px;
    font-size: 18px;
    line-height: 24px;
    font-family: Source Sans Pro;
    text-align: left;
  }

  .profile-lawyer-relationship-suggestion
    .suggestion-additional-info
    .sub-title {
    margin: 0 200px 24px 200px;
    text-align: left;
  }
}

@media screen and (max-width: 1034px) {
  .seller-profile .hire-lawyer-content {
    margin: 64px 100px;
    padding: 32px 116px;
    background: #f8f8f8;
    border-radius: 10px;
    text-align: center;
  }

  .profile-lawyer-relationship-suggestion
    .suggestion-additional-info
    .sub-title {
    margin: 0 100px 24px 100px;
    text-align: left;
  }

  .seller-profile .profile-content .root {
    margin: 0 5px !important;
  }
}

@media screen and (max-width: 942px) {
  .seller-profile .profile-content .root .seller-side-drawer {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 100;
    border-radius: 12px 0px 0px 12px;
    display: block;
  }
}

@media screen and (max-width: 768px) {
  .seller-profile .header-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .seller-profile .header-content .photo-group .upload-image {
    display: block;
    border-radius: 50%;
    position: relative;
    text-align: center;
    width: 120px;
    height: 120px;
    margin: 0 auto 20px auto;
  }

  .seller-profile .profile-content .root .seller-side-drawer {
    position: absolute;
    left: 0;
    top: 0;
    width: 30%;
    height: 100%;
    background: #ededed;
    z-index: 100;
    border-radius: 12px 0px 0px 12px;
    display: block;
  }

  .seller-profile .header-content .user-details .title-text {
    font-size: 30px;
    text-align: center;
  }
  .seller-profile .hire-lawyer-content {
    margin: 64px 100px;
    padding: 32px 50px;
  }

  .seller-profile .information-content .text {
    margin: 0px 20px 24px 20px;
  }

  .seller-profile .profile-lawyer .profile-lawyer-hire-content {
    margin: 25px 0;
  }

  .seller-profile .profile-lawyer {
    padding: 0;
  }
}

@media screen and (max-width: 840px) {
  .dialog-profile-offer-accepted-content .title-header .title {
    font-size: 35px;
  }
}
@media screen and (max-width: 762px) {
  .seller-profile .hire-lawyer-content {
    margin: 64px 32px;
    padding: 32px 116px;
    background: #f8f8f8;
    border-radius: 10px;
    text-align: center;
  }

  .seller-profile .information-content .text {
    margin: 0px 100px 24px 100px;
  }

  .seller-profile .information-content .title {
    margin-bottom: 16px;
    font-size: 25px;
    line-height: 32px;
    font-family: Lora;
  }
}
@media screen and(max-width:738px) {
  .dialog-deactivate-account-seller-content .content .sub-title {
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 24px;
    padding: 0px !important;
  }
}

@media screen and (max-width: 729px) {
  .profile-lawyer-relationship-suggestion .suggestion-additional-info .title {
    font-size: 25px;
  }
  .seller-profile .hire-lawyer-content .title {
    font-size: 25px;
  }
}
@media screen and(max-width:738px){
    .dialog-deactivate-account-buyer-content .content .sub-title {
        font-family: Source Sans Pro;
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        line-height: 24px;
        margin-bottom: 24px;
        padding: 0px !important;
    }
    
}


@media screen and (max-width: 649px) {
  .seller-profile .profile-content .root .seller-side-drawer {
    position: absolute;
    left: 0;
    top: 0;
    width: 40%;
    height: 100%;
    background: #ededed;
    z-index: 100;
    border-radius: 12px 0px 0px 12px;
    display: block;
  }

  .dialog-deactivate-account-seller-content .title-header .title {
    padding: 0 !important;
  }
}
@media screen and (max-width: 630px) {
    .dialog-profile-offer-accepted-content {
      padding: 64px;
    }
  }
  
  @media screen and (max-width: 630px) {
    .dialog-profile-offer-accepted-content {
      padding: 64px;
    }
  }

@media screen and (max-width: 600px) {
  .seller-profile .hire-lawyer-content {
    margin: 64px 32px;
    padding: 32px 50px;
    background: #f8f8f8;
    border-radius: 10px;
    text-align: center;
  }

  .seller-profile .hire-lawyer-content .text {
    margin-bottom: 20px;
    text-align: center;
  }
}

@media screen and (max-width: 597px) {
  .profile-lawyer-relationship-suggestion .suggestion-additional-info .title {
    font-size: 20px;
  }
  .seller-profile .information-content .title {
    font-size: 20px;
  }
  .seller-profile .information-content .text {
    margin: 0px 20px 24px 20px;
  }
}
@media screen and (max-width: 504px) {
  .seller-profile .profile-content .root .seller-side-drawer {
    position: absolute;
    left: 0;
    top: 0;
    width: 60%;
    height: 100%;
    background: #ededed;
    z-index: 100;
    border-radius: 12px 0px 0px 12px;
    display: block;
  }

  .seller-profile .profile-content {
    margin-top: 0;
  }
}

@media screen and (max-width: 400px) {
  .seller-profile .hire-lawyer-content {
    margin: 32px 5px;
    padding: 32px 20px;
    background: #f8f8f8;
    border-radius: 10px;
    text-align: center;
  }

  .seller-profile .profile-content .root {
    flex-grow: 1;
    background: #f8f8f8;
    display: flex;
    border-radius: 12px;
    margin: 0px;
  }

  .seller-profile .header-content .user-details .title-text {
    font-size: 24px !important;
    line-height: 28px !important;
    text-align: center;
  }

  .profile-lawyer-relationship-suggestion .suggestion-additional-info .title {
    font-size: 20px !important;
    line-height: 29px !important;
  }

  suggestion .suggestion-additional-info {
    margin-top: 10px;
    text-align: left;
  }

  .seller-profile .header-content .user-details {
    margin-left: 0 !important;
  }
}

@media screen and (max-width: 356px) {
  .seller-profile .profile-content .root .seller-side-drawer {
    position: absolute;
    left: 0;
    top: 0;
    width: 80%;
    height: 100%;
    background: #ededed;
    z-index: 100;
    border-radius: 12px 0px 0px 12px;
    display: block;
  }
}
