.virtual-assistant-features {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    margin:64px 0px 64px 0px;
    > h3 {
        font-family: 'Lora', sans-serif;
        font-style: normal;
        font-weight: 1;
        font-size: 48px;
        line-height: 52px;
        text-align: center;
        color: #000000;
        margin-bottom: 20px;
    }
    &-items {
        display: grid;
        grid-template-columns: auto auto auto;
        gap: 30px;
        width: 100%;
    }
}

@media screen and (max-width:900px){
    .virtual-assistant-features {
       
        &-items {
            grid-template-columns: repeat(2, 1fr);
        }
    }
}

@media screen and (max-width: 767px) {
    .virtual-assistant-features {
       
        &-items {
            grid-template-columns: repeat(2, 1fr);
        }
    }
}

@media screen and (max-width: 650px) {
    .virtual-assistant-features {
       
        &-items {
            grid-template-columns: repeat(1,1fr);
        }
    }
}

@media screen and (max-width: 500px) {
    .virtual-assistant-features {
       
        &-items {
            grid-template-columns: repeat(1, 1fr);
        }
    }
}
