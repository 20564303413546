.sellers-tab-va { position: relative; padding: 16px; }
.sellers-tab-va .sellers-content .link-box { margin-top: 32px; text-align: center; }
.sellers-tab-va .sellers-content .link-box .link-seller {  width: 100%; padding: 22px 14px; font-family: Source Sans Pro; font-style: normal; font-weight: bold; font-size: 14px; line-height: 18px; color: #0097A7; border: 1px dashed #8C8C8C; box-sizing: border-box; border-radius: 8px; text-transform: uppercase; }
.sellers-tab-va .sellers-content .link-box .link-seller .icon { margin-right: 5px; margin-top: 3px; filter: invert(33%) sepia(89%) saturate(2251%) hue-rotate(162deg) brightness(97%) contrast(101%); width: 20px; height: 20px; }

.sellers-tab-va .sellers-content .account-box { margin-bottom: 16px; padding: 24px; background: #FFFFFF; border-radius: 4px; cursor: pointer; box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px rgba(0, 0, 0, 0.12), 0px 1px 3px rgba(0, 0, 0, 0.2); }
.sellers-tab-va .sellers-content .account-box.active { box-sizing: border-box; border: 3px solid #4DD0E1; cursor: default; }

.sellers-tab-va .session { padding: 16px; background: #FFFFFF; border-radius: 12px; display: flex; align-items: center; }
.sellers-tab-va .session .info .title { margin-bottom: 16px; font-family: Lora; font-style: normal; font-weight: bold; font-size: 24px; line-height: 26px; }
.sellers-tab-va .session .info .info-row { margin-bottom: 8px; display: inline-block; width: 100%; }
.sellers-tab-va .session .info .info-row:last-child { margin-bottom: 0px; }
.sellers-tab-va .session .info .info-row .photo { width: 24px; height: 24px; float: left; background: #323232; border-radius: 50%; margin-right: 4px; }
.sellers-tab-va .session .info .info-row .name { float: left; margin-right: 8px; font-family: Roboto; font-style: normal; font-weight: bold; font-size: 18px; line-height: 21px; }
.sellers-tab-va .session .info .info-row .text { float: left; margin-right: 8px; font-family: Source Sans Pro; font-style: normal; font-weight: normal; font-size: 18px; line-height: 24px; }
.sellers-tab-va .session .info .info-row .link { float: left; margin-right: 8px; font-family: Source Sans Pro; font-style: normal; font-weight: bold; font-size: 18px; line-height: 24px; text-decoration-line: underline !important; }

.sellers-tab-va .sellers-property { margin-top: 24px; padding: 16px; background: #E8E8E8; border-radius: 12px; }
.sellers-tab-va .sellers-property .title { font-family: Lora; font-style: normal; font-weight: bold; font-size: 24px; line-height: 26px; }

/* Dialog */
.dialog-document { text-align: center; }
.dialog-document-content { margin: 64px 86px }
.dialog-document-content .close-icon { cursor: pointer; float: right; position: absolute; top: 0; right: 0; margin-top: 20px; margin-right: 21px; }
.dialog-document-content .title { font-size: 36px; line-height: 42px; margin-bottom: 16px; }
.dialog-document-content .content { padding: 0; overflow-x: hidden; }
.dialog-document-content .content .text { font-size: 16px; line-height: 18px; font-weight: 400; text-align: left; }
.dialog-document-content .content .actions { margin-top: 24px; } 
.dialog-document-content .content .actions .button-close { height: 40px; padding: 12px 32px; background: #000; border-radius: 12px; color: #FFFFFF; text-transform: none; font-size: 12px; line-height: 14px; font-weight: 400; }


@media screen and (max-width:768px){

    .sellers-tab-va .cols-full{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }

      .sellers-tab-va .cols-full .col3of12{
        width: 100%;
      }

      .sellers-tab-va .cols-full .col9of12{
        width: 100%;
      }

      .sellers-tab-va .cols .col3of12 .sellers-content {
        display: flex;
        gap: 20px;
        width: 100%;
        overflow-x: auto;
        flex-direction: row;
      }
}
