.seller-profile-photographer-uploads {
  display: flex;
  // height: 200px;
  width: 100%;
  flex-direction: column;
  > .top {
    display: flex;
    width: 100%;
    > .images {
      flex: 5;
      // height: 100%;
      width: 100%;
      display: grid;
      grid-template-columns: auto auto auto;
      grid-gap: 8px;
      position: relative;
      > .image-container {
        width: 100%;
        position: relative;
        > img {
          position: absolute;
          width: 100%;
          height: 100%;
          object-fit: cover;
          border-radius: 4px;
        }
      }
      > .image-container:after {
        content: '';
        display: block;
        padding-bottom: 80%;
      }
    }
    > .video-container {
      flex: 3;
      width: 100%;
      margin-left: 8px;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: black;
      border-radius: 4px;
      position: relative;
      > video {
        width: 100%;
        border-radius: 4px;
      }
      > .top {
        position: absolute;
        width: 40%;
        height: 40%;
      }
    }
    > .threeD-image {
      flex: 3;
      margin-left: 8px;
      border-radius: 4px;
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      > img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 4px;
      }
      > .top {
        position: absolute;
        width: 40%;
        height: 40%;
      }
    }
  }
  > button {
    border: none;
    background-color: transparent;
    outline: 0;
    cursor: pointer;
    font-family: Source Sans Pro;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 18px;
    text-transform: uppercase;
    color: #0097a7;
    padding: 8px 8px;
    margin-top: 16px;
    align-self: flex-end;
    margin-bottom: 32px;
  }
}
