.confirmNewPassword {
    padding: 64px 0 50px 0;
    text-align: center;
    margin: 0 auto;
    background-color: #fff;
}

.confirmNewPassword .confirmNewPasswordContent {
    margin: 0 10px 30px 30px;
    text-align: center;
    height: 100%;
}

.confirmNewPassword .confirmNewPasswordContent .confirmNewPasswordTitle {
    font-size: 30px;
    line-height: 40px;
    font-weight: 600;
    margin-bottom: 16px;
}

.confirmNewPassword .confirmNewPasswordContent .textFieldContainer {
    width: 400px;
    display: block;
    margin: 0 auto 8px auto;
    border-radius: 4px;
}

.confirmNewPassword .confirmNewPasswordContent .button {
    background: #00BCD4;
    margin-top: 24px;
    border-radius: 4px;
    color: #fff;
    padding: 15px 32px;
    text-transform: uppercase;
    font-size: 14px;
    line-height: 18px;
    font-weight: 700;
}

.confirmNewPassword .confirmNewPasswordContent .button:hover {
    background: #00BCD4;
}

.confirmNewPassword .properColor {
    position: relative;
    display: inline-block;
    padding: 8px;
    color: #0097A7;
    font-size: 14px;
    line-height: 18px;
    border-radius: 4px;
    font-weight: 700;
    text-transform: uppercase;
    z-index: 99;
}

.confirmNewPassword .image {
    margin-top: -270px;
    background: linear-gradient(180deg, rgba(204, 243, 255, 0) 0%, #CCF3FF 100%);
}

.confirmNewPassword .text-field-price {
    outline: 1px solid rgba(0, 0, 0, 0.5);
    border: none;
    border-radius: 12px;
    padding: 10px;
    margin-bottom: 10px;
    width: 450px;
}

.confirmNewPassword .text-field-price.focus {
    outline: 1px solid rgba(0, 188, 212, 1) !important;
    border: none;
}

@media screen and (max-width: 1300px) {
    .confirmNewPassword .confirmNewPasswordContent .textFieldContainer {
        display: block;
        margin: 0 auto 8px auto;
        border-radius: 4px;
    }
}

@media screen and (max-width:1082px) {
    .confirmNewPassword .image {
        margin-top: -150px;
        background: linear-gradient(180deg, rgba(204, 243, 255, 0) 0%, #CCF3FF 100%);
    }

}

@media screen and (max-width:750px) {
    .confirmNewPassword .image {
        margin-top: -100px;
    }

}

@media screen and (max-width:746px) {
    .confirmNewPassword {
        padding: 50px 100px 8px 100px !important;
    }

    .confirmNewPassword .textFieldContainer {
        width: 100%;

    }
}

@media screen and (max-width:686px) {
    .confirmNewPassword {
        padding: 50px 50px 8px 50px !important;

    }

    .confirmNewPassword .image {
        margin-top: 0px;
    }
}

@media screen and (max-width:617px) {
    .confirmNewPassword .forgotPasswordTitle {
        font-size: 30px;
    }

    .confirmNewPassword .confirmNewPasswordContent .textFieldContainer {
        width: 100%;
    }
}

@media screen and (max-width:500px) {
    .confirmNewPassword .textFieldContainer {
        width: 100%;

    }

    .confirmNewPassword {
        padding: 50px 10px 8px 10px !important;
    }
}