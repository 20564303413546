.preliminary-contract-seller { margin-bottom: 64px; }
.preliminary-contract-seller .title-primary { margin: 0 120px 32px 120px; font-family: Source Sans Pro; font-style: normal; font-weight: normal; font-size: 18px; line-height: 24px; text-align: justify; color: #000000; }

.preliminary-contract-seller .content,
.preliminary-contract-seller .content-primary { margin-bottom: 32px; padding: 40px 0; background: #F8F8F8; border-radius: 8px; text-align: center; }

.preliminary-contract-seller .content .image-primary { margin-bottom: 10px; }
.preliminary-contract-seller .content .content-image-primary { width: 53px; margin-bottom: 13px; filter: invert(0%) sepia(0%) saturate(7500%) hue-rotate(217deg) brightness(108%) contrast(108%); }
.preliminary-contract-seller .content .title { margin-bottom: 8px; font-family: Lora; font-style: normal; font-weight: bold; font-size: 32px; line-height: 32px; text-align: center; color: #000000; }
.preliminary-contract-seller .content .title-sub { margin-bottom: 24px; font-family: Source Sans Pro; font-style: normal; font-weight: normal; font-size: 18px; line-height: 24px; text-align: center; color: #000000; }
.preliminary-contract-seller .content .content-title-sub { margin: 0 200px 24px 200px; font-family: Source Sans Pro; font-style: normal; font-weight: normal; font-size: 18px; line-height: 24px; text-align: center; color: #000000; }
.preliminary-contract-seller .content .buttons { margin-bottom: 10px; }
.preliminary-contract-seller .content .buttons .button-onlain { margin-right: 30px; padding: 15px 32px; background: #00BCD4; border-radius: 4px; font-family: Source Sans Pro; font-style: normal; font-weight: bold; font-size: 14px; line-height: 18px; text-transform: uppercase; color: #FFFFFF; }
.preliminary-contract-seller .content .buttons .button-upload { margin-right: 30px; padding: 15px 32px; background: #00BCD4; border-radius: 4px; font-family: Source Sans Pro; font-style: normal; font-weight: bold; font-size: 14px; line-height: 18px; text-transform: uppercase; color: #FFFFFF; }
.preliminary-contract-seller .content .buttons .button-upload img { margin-right: 5px; }
.preliminary-contract-seller .content .buttons .button-save { padding: 15px 32px; background: #fff; border: 1px solid #00BCD4; border-radius: 4px; font-family: Source Sans Pro; font-style: normal; font-weight: bold; font-size: 14px; line-height: 18px; text-transform: uppercase; color: #000000; }
.preliminary-contract-seller .content .buttons .button-onlain-layer { padding: 15px 32px; background: #fff; border: 1px solid #00BCD4; border-radius: 4px; font-family: Source Sans Pro; font-style: normal; font-weight: bold; font-size: 14px; line-height: 18px; text-transform: uppercase; color: #000000; }
.preliminary-contract-seller .content .buttons .button-onlain-download { padding: 15px 32px; background: #00BCD4; border-radius: 4px; font-family: Source Sans Pro; font-style: normal; font-weight: bold; font-size: 14px; line-height: 18px; text-transform: uppercase; color: #FFFFFF; }

.preliminary-contract-seller .content .abidors { font-family: Source Sans Pro; font-style: normal; font-weight: normal; font-size: 12px; line-height: 16px; text-align: center; letter-spacing: 0.4px; color: #000000; }

.preliminary-contract-seller .content-primary { margin-bottom: 32px; padding: 40px 0; border-radius: 8px; text-align: center; }
.preliminary-contract-seller .content-primary .content-image-primary { width: 53px; margin-bottom: 13px; filter: invert(0%) sepia(0%) saturate(7500%) hue-rotate(217deg) brightness(108%) contrast(108%); }
.preliminary-contract-seller .content-primary .title { margin-bottom: 8px; font-family: Lora; font-style: normal; font-weight: bold; font-size: 32px; line-height: 32px; text-align: center; color: #000000; }
.preliminary-contract-seller .content-primary .content-title-sub { margin: 0 200px 24px 200px; font-family: Source Sans Pro; font-style: normal; font-weight: normal; font-size: 18px; line-height: 24px; text-align: center; color: #000000; }
.preliminary-contract-seller .content-primary .buttons .button-onlain-download { margin-bottom: 8px; padding: 15px 32px; background: #00BCD4; border-radius: 4px; font-family: Source Sans Pro; font-style: normal; font-weight: bold; font-size: 14px; line-height: 18px; text-transform: uppercase; color: #FFFFFF; }
.preliminary-contract-seller .content-primary .text { font-family: Source Sans Pro; font-style: normal; font-weight: normal; font-size: 12px; line-height: 16px; text-align: center; letter-spacing: 0.4px; color: #000000; }

.preliminary-contract-seller .button-sign {
    padding: 15px 32px;
    display: block;
    margin: 20px auto;
    background: #00BCD4;
    color: #fff;
    border-radius: 4px;
    text-transform: uppercase;
    font-family: Source Sans Pro;
    font-size: 14px;
    line-height: 18px;
    font-weight: 700;
}

.preliminary-contract-seller .button-sign:hover {
    background: #00BCD4;
    color: #fff;
}

.preliminary-contract-seller .content .button-sign {
    padding: 15px 32px;
    display: block;
    margin: 0 auto;
    background: #00BCD4;
    color: #fff;
    border-radius: 4px;
    text-transform: uppercase;
    font-family: Source Sans Pro;
    font-size: 14px;
    line-height: 18px;
    font-weight: 700;
}

.dialog-pay-success-commission-seller {
    padding: 64px 127px;
  }
  
  .dialog-pay-success-commission-seller .title-header {
    padding: 0;
    margin-bottom: 8px;
  }
  
  .dialog-pay-success-commission-seller .title-header .title {
    display: inline-block;
    font-size: 48px;
    line-height: 52px;
    font-family: Lora;
    font-weight: 700;
  }
  
  .dialog-pay-success-commission-seller .check-icon {
    margin-bottom: 15px;
    height: 76px;
    width: 76px;
  }
  
  .dialog-pay-success-commission-seller .close-icon {
    cursor: pointer;
    position: absolute;
    top: 30px;
    right: 30px;
  }
  
  .dialog-pay-success-commission-seller .content {
    padding: 0;
    overflow-x: hidden;
  }
  
  .dialog-pay-success-commission-seller .content .sub-title {
    font-size: 18px;
    line-height: 24px;
    font-weight: 400;
    margin-bottom: 32px;
    font-family: Source Sans Pro;
  }
  
  .dialog-pay-success-commission-seller .content .actions {
    margin-top: 8px;
  }
  
  .dialog-pay-success-commission-seller .content .actions .button-close {
    height: 48px;
    padding: 15px 32px;
    background: #00bcd4;
    border-radius: 4px;
    color: #fff;
    text-transform: uppercase;
    font-size: 14px;
    line-height: 18px;
    font-family: Source Sans Pro;
    font-weight: 700;
  }


  .dialog-profile-send-new-offer {
    text-align: center;
}

.dialog-profile-send-new-offer-content {
    padding: 64px 127px;
}

.dialog-profile-send-new-offer-content .title-header {
    padding: 0;
    margin-bottom: 8px;
}

.dialog-profile-send-new-offer-content .title-header .title {
    display: inline-block;
    font-size: 48px;
    line-height: 52px;
    font-family: Lora;
    font-weight: 700;
}

.dialog-profile-send-new-offer-content .title-header .close-icon {
    float: right;
    cursor: pointer;
    margin-top: -40px;
    margin-right: -100px;
}

.dialog-profile-send-new-offer-content .content {
    padding: 0;
    overflow-x: hidden;
}

.dialog-profile-send-new-offer-content .content .sub-title {
    font-size: 18px;
    line-height: 24px;
    font-weight: 400;
    margin-bottom: 20px;
    font-family: Source Sans Pro;
}

.dialog-profile-send-new-offer-content .content .announced-price {
    display: flex;
    padding: 0 40px;
    margin-bottom: 20px;
    align-items: center;
    justify-content: center;
}

.dialog-profile-send-new-offer-content .content .announced-price .label {
    font-size: 18px;
    line-height: 24px;
    font-family: Source Sans Pro;
    font-weight: 400;
    margin-right: 24px;
}

.dialog-profile-send-new-offer-content .content .announced-price .box-price {
    padding: 4px 8px;
    margin-right: 24px;
    border-radius: 8px;
    background: #E8E8E8;
    color: #000000;
    font-size: 24px;
    line-height: 30px;
    font-family: Source Sans Pro;
    font-weight: 700;
}

.dialog-profile-send-new-offer-content .content .announced-price .box-group {
    margin-right: 18px;
    display: flex;
    align-items: center;
}

.dialog-profile-send-new-offer-content .content .announced-price .box-group .box-text {
    padding: 3px 8px;
    background: #FFF59D;
    border-radius: 4px;
    font-size: 18px;
    line-height: 22px;
    font-family: Source Sans Pro;
    font-weight: 900;
    margin-right: 6px;
    
}

.dialog-profile-send-new-offer-content .content .announced-price .box-group .box-text  {
    margin-bottom:  0 !important;
}

.dialog-profile-send-new-offer-content .content .announced-price .box-group:last-child {
    margin-right: 0;
}

.dialog-profile-send-new-offer-content .content .description {
    margin-bottom: 44px;
    text-align: left;
}

.dialog-profile-send-new-offer-content .content .label {
    margin-bottom: 4px;
    font-size: 12px;
    line-height: 16px;
    font-family: Source Sans Pro;
    font-weight: 400;
    letter-spacing: 0.4px;
}

.dialog-profile-send-new-offer-content .content .description .label {
    margin-bottom: 4px;
    font-size: 12px;
    line-height: 16px;
    font-family: Source Sans Pro;
    font-weight: 400;
    letter-spacing: 0.4px;
}

.dialog-profile-send-new-offer-content .content .description .box {
    padding: 16px;
    border-radius: 8px;
    background: #F4F4F4;
}

.dialog-profile-send-new-offer-content .content .actions {
    margin-top: 8px;
}

.dialog-profile-send-new-offer-content .content .actions .button-send {
    height: 48px;
    padding: 15px 32px;
    background: #00BCD4;
    border-radius: 4px;
    color: #fff;
    text-transform: uppercase;
    margin-right: 34px;
    font-size: 14px;
    line-height: 18px;
    font-family: Source Sans Pro;
    font-weight: 700;
}

.commission .deposit-content .content .deposit .deposit-field {
  display: inline-block;
}

.commission .deposit-content .content .deposit .price-number {
  display: inline-block;
  height: 58px;
  padding: 4px 8px;
  background: #E0F7FA;
  border-radius: 4px;
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: bold;
  font-size: 17px;
  color: #000000;
  width: 170px;
  border: none;
  margin-bottom: 20px;
}

.commission .deposit-content .content .button-deposit {
  padding: 20px;
  background: #00BCD4;
  height: 58px;
  width: 170px;
  border-radius: 4px;
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  text-transform: uppercase;
  color: #FFFFFF;
}


@media screen and (max-width:860px) {

    .dialog-profile-send-new-offer-content {
      
        overflow-x: hidden;
    }
}

@media screen and (max-width:765px) {

    .dialog-profile-send-new-offer-content {
        padding: 64px 32px;
    }

    .dialog-profile-send-new-offer-content .title-header .close-icon {
        float: right;
        cursor: pointer;
        margin-top: -40px;
        margin-right: 0px;
    }

    
}

@media screen and (max-width:703px) {
    .dialog-profile-send-new-offer-content .title-header .close-icon {
        margin-top: -90px;
    }
}

@media screen and (max-width:689px) {
    .dialog-profile-send-new-offer-content .title-header .close-icon {
        margin-top: -138px;
    }

}

@media screen and (max-width:505px) {
    .dialog-profile-send-new-offer-content .title-header .title {
        font-size: 30px;
        line-height: 34px;
    }

    .dialog-profile-send-new-offer-content .title-header .close-icon {
        margin-top: -40px;
    }
}

@media screen and (max-width:492px) {
   
    .dialog-profile-send-new-offer-content .title-header .close-icon {
        margin-top: -70px;
    }
}

@media screen and (max-width: 478px) {
    .dialog-profile-send-new-offer-content {
        padding: 64px 20px;
    }
    
}

@media screen and (max-width: 452px) {
  
    .dialog-profile-send-new-offer-content .title-header .close-icon {
        margin-top: -106px;
    }

    .dialog-profile-send-new-offer-content .content .announced-price {
        padding: 0px;
    }

}

  @media screen and (max-width: 432px) {
    .preliminary-contract-seller .title-primary {
      margin: 0;
    }

    .dialog-pay-success-commission-seller {
      padding: 12px 0;
    }

    .dialog-pay-success-commission-seller .check-icon {
      width: 50px;
      height: 50px;
    }

    .dialog-pay-success-commission-seller .title-header .title {
      font-size: 17px;
      line-height: 24px;
    }
}