.dialog-profile-cancel-offer {
    text-align: center;
}

.dialog-profile-cancel-offer-content {
    padding: 64px 127px;
}

.dialog-profile-cancel-offer-content .title-header {
    padding: 0;
    margin-bottom: 8px;
}

.dialog-profile-cancel-offer-content .title-header .title {
    display: inline-block;
    font-size: 48px;
    line-height: 52px;
    font-family: Lora;
    font-weight: 700;
}

.dialog-profile-cancel-offer-content .title-header .close-icon {
    float: right;
    display: inline-block;
    cursor: pointer;
    margin-top: -40px;
    margin-right: -100px;
}

.dialog-profile-cancel-offer-content .content {
    padding: 0;
    overflow-x: hidden;
}

.dialog-profile-cancel-offer-content .content .sub-title {
    font-size: 18px;
    line-height: 24px;
    font-weight: 400;
    margin-bottom: 32px;
    font-family: Source Sans Pro;
}

.dialog-profile-cancel-offer-content .content .actions {
    margin-top: 8px;
}

.dialog-profile-cancel-offer-content .content .actions .button-reject {
    height: 48px;
    padding: 15px 32px;
    background: #00BCD4;
    border-radius: 4px;
    color: #fff;
    text-transform: uppercase;
    margin-right: 24px;
    font-size: 14px;
    line-height: 18px;
    font-family: Source Sans Pro;
    font-weight: 700;
}

.dialog-profile-cancel-offer-content .content .actions .button-close {
    height: 48px;
    padding: 15px 32px;
    border: 2px solid #00BCD4;
    border-radius: 4px;
    color: #000;
    text-transform: uppercase;
    font-size: 14px;
    line-height: 18px;
    font-family: Source Sans Pro;
    font-weight: 700;
}

@media screen and (max-width:631px){
    .dialog-profile-cancel-offer-content {
        padding: 64px 100px;
    }
    .dialog-profile-cancel-offer-content .title-header .close-icon {
        float: right;
        cursor: pointer;
        margin-top: -40px;
        margin-right: -80px;
    }
}

@media screen and (max-width:577px){
    .dialog-profile-cancel-offer-content {
        padding: 64px 80px;
    }
    .dialog-profile-cancel-offer-content .title-header .close-icon {
        float: right;
        cursor: pointer;
        margin-top: -40px;
        margin-right: -60px;
    }
    
}

@media screen and (max-width:456px){
    .dialog-profile-cancel-offer-content .content .actions {
        margin-top: 8px;
        display: flex;
        flex-direction: column;
        gap:20px;
    }

    .dialog-profile-cancel-offer-content .content .actions .button-reject {
        width: 100%;
    }

    .dialog-profile-cancel-offer-content .title-header .title {
        display: inline-block;
        font-size: 30px;
        line-height: 52px;
        font-family: Lora;
        font-weight: 700;
    }
}

@media screen and (max-width:420px){
.dialog-profile-cancel-offer-content {
    padding: 64px 60px;
}
.dialog-profile-cancel-offer-content .title-header .close-icon {
    float: right;
    cursor: pointer;
    margin-top: -40px;
    margin-right: -40px;
}
}

@media screen and (max-width:385px){
    .dialog-profile-cancel-offer-content {
        padding: 64px 40px;
    }
    .dialog-profile-cancel-offer-content .title-header .close-icon {
        float: right;
        cursor: pointer;
        margin-top: -40px;
        margin-right: -20px;
    }
}

@media screen and (max-width:345px){
    .dialog-profile-cancel-offer-content {
        padding: 64px 20px;
    }
    .dialog-profile-cancel-offer-content .title-header .close-icon {
        float: right;
        cursor: pointer;
        margin-top: -40px;
        margin-right: -15px;

}
}
@media screen and (max-width:700px) {
    .dialog-profile-cancel-offer-content {
        padding: 64px 64px;
        overflow: hidden;
    }

}

@media screen and (max-width:600px) {
    .dialog-profile-cancel-offer-content .title-header .title {
        font-size: 32px;
        line-height: 36px;
    }

    .dialog-profile-cancel-offer-content {
        padding: 20px;
    }
}

@media screen and (max-width:400px) {
    .dialog-profile-cancel-offer .MuiDialog-paperFullWidth {
        width: 100% !important;
    }

    .dialog-profile-cancel-offer-content .content .actions .button-reject {
       margin-right: 20px;
    }
}