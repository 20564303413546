.header-lawyer {
    width: 100%;
    position: sticky;
    top: 0;
    z-index: 990;
    background-color: #fff;
}

.header-lawyer .navbar {
    padding: 16px 30px !important;
    z-index: 990;
    position: relative;
}

.header-lawyer .nav-menu {
    float: left;
    display: flex;
    margin-top: 5px;
    padding-left: 0;
    list-style: none;
    margin-bottom: 0;
}

.header-lawyer .nav-links {
    padding: 0 8px;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    color: #000000;
    cursor: pointer;
    margin-bottom: 0;
    text-decoration: none;
}

.header-lawyer .nav-links.active {
    background-color: rgba(0, 188, 212, 1);
    color: #ffffff;
    border-radius: 30px;
    padding: 10px;
}

.header-lawyer .nav-links.dropdown {
    text-transform: lowercase;
    font-size: 12px;
    font-weight: 700;
}

.header-lawyer .nav-links.dropdown span::first-letter {
    text-transform: uppercase;
}

.header-lawyer .nav-links.dropdown:hover {
    background-color: transparent;
}

.header-lawyer .down-caret {
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 5px 5px 0 5px;
    border-color: #000 transparent transparent transparent;
    display: inline-block;
    margin-left: 9px;
    top: -1px;
    position: relative;
    transform: rotate(0deg);
    transition: all .25s ease-in;
}

.header-lawyer .open-caret {
    transform: rotate(180deg);
    transition: all .25s ease-out;
}

.header-lawyer .nav-item.dropdown {
    padding: 3px 0;
}

.header-lawyer #menu-list-grow {
    margin-top: -12px;
}

.header-lawyer .menu-icon {
    display: none;
}

.header-lawyer .navbar-logo {
    float: left;
    display: flex;
    margin-right: 32px;
}

.header-lawyer .search {
    float: left;
    position: relative;
    margin-top: 4px;
    margin-right: 24px;
    background-color: rgba(244, 244, 244, 1);
    border-radius: 50px;
    width: 300px;
    height: 44px;
    margin-left: 24px;
    cursor: pointer;
    padding: 5px 16px;
}

.header-lawyer .search.focus-input-filed {
    outline: 2px solid #00BCD4;
}

.header-lawyer .search .autocomplete-dropdown-container-home {
    margin-top: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    width: 100%;
    display: flex;
    flex-direction: column;
}

.header-lawyer .display-translation-switcher {
    margin-top: 0;
}

.header-lawyer .search .search-icon {
    float: left;
    margin-top: 4px;
    width: 26px;
    height: 26px;
    filter: invert(49%) sepia(0%) saturate(1%) hue-rotate(100deg) brightness(97%) contrast(89%);
}

.header-lawyer .search-field::placeholder {
    font-weight: 400;
    font-size: 18px;
}

.header-lawyer .search-field {
    float: left;
    padding: 16px 10px !important;
    height: 28px;
    border: none;
    background-color: transparent;
    width: 80% !important;
    margin-bottom: 10px;
}

.header-lawyer .drop-down {
    padding: 50px 0 !important
}

/* right part header */
.header-lawyer .right-part {
    float: right;
    display: flex;
    gap: 10px;
}

.header-lawyer .right-part .account-group {
    float: left;
    margin-top: 20px;
    margin-right: 20px;
}

.header-lawyer .right-part .account-group .link {
    display: flex;
}

.header-lawyer .right-part .account-group .link img {
    float: left;
    width: 24px;
    margin-top: -3px;
    margin-right: 8px;
    filter: invert(33%) sepia(89%) saturate(2251%) hue-rotate(162deg) brightness(97%) contrast(101%);
}

.header-lawyer .right-part .account-group .link .title {
    float: left;
    font-size: 14px;
    line-height: 14px;
    font-weight: 700;
    margin-top: -2px;
    margin-bottom: 0px;
    color: #0097A7;
    text-transform: uppercase;
}

.header-lawyer .right-part .bell {
    position: relative;
    float: left;
    margin-top: 15px;
}

.header-lawyer .right-part .bell .icon {
    filter: invert(33%) sepia(89%) saturate(2251%) hue-rotate(162deg) brightness(97%) contrast(101%);
    width: 18px;
    height: 18px;
}

.header-lawyer .right-part .bell .notification-list-fade-in {
    color: #000;
    position: absolute;
    top: 47px;
    right: -70px;
    padding: 20px 23px;
    width: 410px;
    height: 250px;
    border-radius: 12px;
    background-color: #CFCFCF;
    visibility: visible;
    opacity: 1;
    transition: visibility 0s linear 0s, opacity 300ms;
    z-index: 1;
    overflow-x: hidden;
}

.header-lawyer .right-part .bell .notification-list-fade-in:before {
    content: "";
    position: absolute;
    top: -30px;
    right: 64px;
    z-index: 1;
    border: solid 15px transparent;
    border-bottom-color: #CFCFCF;
}

.header-lawyer .right-part .bell .notification-list-fade-in .row-notification .image {
    float: left;
    width: 10px;
    height: 18px;
    margin-right: 11px;
}

.header-lawyer .right-part .bell .notification-list-fade-in .row-notification .author {
    float: left;
    font-size: 14px;
    font-weight: 700;
    margin-right: 5px;
}

.header-lawyer .right-part .bell .notification-list-fade-in .row-notification .text {
    float: left;
    font-size: 14px;
    font-weight: 400;
    margin-right: 5px;
}

.header-lawyer .right-part .bell .notification-list-fade-in .row-notification .offer-number {
    float: left;
    font-size: 14px;
    font-weight: 700;
}

.header-lawyer .right-part .bell .notification-list-fade-in .hr {
    margin: 12px 0;
    color: rgba(101, 101, 101, 1);
}

.header-lawyer .right-part .bell .notification-list-fade-out {
    width: 0;
    height: 0;
    display: none;
    opacity: 100;
    transition: visibility 0s linear 300ms, opacity 300ms;
}

.header-lawyer .badge { position: absolute; top: -10px !important; right: -13px !important; padding: 2px 4px !important; border-radius: 50% !important; background: #00BCD4; color: white; font-size: 10px !important; }

.header-lawyer .right-part .bell .notification-list-fade-in::-webkit-scrollbar {
    width: 10px !important;
    background: #0097A7;
}

.header-lawyer .right-part .bell .notification-list-fade-in::-webkit-scrollbar-track {
    background: #fff;
    border-radius: 12px;    
}

.header-lawyer .right-part .bell .notification-list-fade-in::-webkit-scrollbar-thumb {
    background-color: #0097A7;
    border-radius: 10px;
}

.lawyer-side-bar {
    display: none;
}

.header-lawyer .navbar .navbar-items {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.header-lawyer .nav-items-mobile-menu {
    display: flex;
    align-items: center;
}

.header-lawyer .lang-switcher select{
    height: 36px !important;
}

.header-lawyer .navbar-logo {
    margin-top: 0 !important;
}

.header-lawyer .lang-switcher-mobile {
    display: none;
} 

.header-lawyer .right-part .sidebar-menu-icon{
    margin-top: 5px;
}
.header-lawyer .right-part .notification-icon{
    margin-top: 6px;
}

.header-lawyer .section-fixed {
    background: rgba(1, 35, 36, 1);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 12px 0;
}
  
.header-lawyer .section-fixed img {
    margin-right: 10px;
    height: 30px;
    width: 30px;
}
  
.header-lawyer .section-fixed .text {
    font-size: 15px;
    font-weight: 700;
    line-height: 30px;
    letter-spacing: 1.0800000429153442px;
    text-align: center;
    color: rgba(255, 255, 255, 1);
    margin-bottom: 0;
}

@media only screen and (max-width: 1320px) {
    .lawyer-side-bar {
        display: block;
    }

    .header-lawyer .navbar-logo {
        margin-right: 12px;
    }

    .header-lawyer .search {
        margin-left: 10px;
        margin-right: 10px;
        width: 15%;
    }

    .header-lawyer .right-part .account-group {
        margin-right: 0;
    }

    .header-lawyer .display-translation-switcher {
        margin-top: 0;
    }

    .header-lawyer .right-part .bell-display {
       display: none;
    }
    .header-lawyer .right-part .notification-icon{
        margin-top: 5px;
    }
    .header-lawyer .right-part .sidebar-menu-icon{
        margin-top: 2.5px;
    }

    .header-lawyer .right-part .notification-icon {
        margin-right: 0 !important;
    }
}


@media only screen and (max-width: 1170px) {
    .header-lawyer .nav-menu {
        display: none;
    }
    
    .header-lawyer .search {
        display: none  !important;
     }
 
     .header-lawyer .lang-switcher-desk {
         display: none;
     }

    .header-lawyer .navbar .navbar-items {
        display: block;
    }

    .header-lawyer .lang-switcher-mobile {
        display: block;
        margin-top: 10px;
    }

    .header-lawyer .navbar-logo {
        margin-top: 7px !important;
    }
}

@media only screen and (max-width: 1000px) { 

    .header-lawyer .lang-switcher-mobile {
       margin: 7px;
    }


}
@media only screen and (max-width: 1060px) {
  
    .header-lawyer .search {
        width: 30%;
    }

    .header-lawyer .nav-items-mobile {
        display: flex ;
        justify-content: space-between;
        align-items: center;
        width: 100%;
    }

}

@media only screen and (max-width: 955px) {
    .header-lawyer .search {
       display: none  !important;
    }

    .header-lawyer .lang-switcher-desk {
        display: none;
    }

    .header-lawyer .right-part .account-group {
        margin-top: 18px;
    }
}


@media only screen and (max-width: 873px) {
    .header-lawyer .search {
        width: 40%;
    }
}


@media only screen and (max-width: 805px) {
    .header-lawyer .search {
      display: none;
    }

    .account-group-buyer {
        display: none;
    }
}

@media only screen and (max-width: 768px) {
    .header-lawyer .lang-switcher-mobile {
        margin: 8px 0;
    }
    .header-lawyer .right-part .bell {
        margin-right: 0px !important;
    }

    .header-lawyer .right-part .account-group{
        margin-right: 0px !important;
    }

    .header-lawyer .lang-switcher {
        margin-right: 0px !important;
    }

    .header-lawyer .css-2kgxp5-MuiSvgIcon-root {
        margin-left: 0px !important;
    }

    .header-lawyer .navbar {
        padding: 17px 20px !important;
    }

    .header-lawyer .navbar-logo {
        margin-right: 0 !important;
        width: auto !important;
        margin-top: 12px !important;
    }

    .header-lawyer .notification-icon {
        margin-right: 0;
        margin-left: 2px;
        margin-top: 8px;
    }

    .header-lawyer .lang-switcher {
        margin-top: 1px;
    }

    .header-lawyer .right-part .sidebar-menu-icon{
        margin-top: 3px;
    }

    .header-lawyer .section-fixed {
        padding: 14px 10px;
    }
      
    .header-lawyer .section-fixed .text {
        font-size: 16px;
    }
}

@media screen and (max-width:453px) {
    .header-lawyer .section-fixed {
        padding: 3px 10px;
    }

    .header-lawyer .section-fixed img {
        width: 40px;
    }

    .header-lawyer .section-fixed .text {
        font-size: 11px;
        line-height: 21px;
    }
}

@media screen and (max-width:339px){
.header-lawyer .navbar{
    padding: 14px 8px !important;
}

}