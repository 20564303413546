.questioneery .content-header { margin: 80px 150px 64px 150px; }
.questioneery .content-header .title { margin-top: 100px; margin-bottom: 8px; font-family: Lora; font-style: normal; font-weight: 500; font-size: 72px; line-height: 76px; color: #000000; }
.questioneery .content-header .title-sub { width: 464px; margin-bottom: 24px; font-family: Source Sans Pro; font-style: normal; font-weight: normal; font-size: 18px; line-height: 24px; color: #000000; }
.questioneery .content-header .button-virtual-assistant { padding: 15px 32px; background: #00bcd4; border-radius: 4px; font-family: Source Sans Pro; font-style: normal; font-weight: bold; font-size: 14px; line-height: 18px; text-transform: uppercase; color: #ffffff; }

.questioneery .survey-section { margin-left: 150px; }
.questioneery .survey-section .favorite .title { margin-bottom: 24px; font-family: Lora; font-style: normal; font-weight: bold; font-size: 32px; line-height: 32px; color: #000000; }

.questioneery .survey-section .favorite .content-form { margin-bottom: 32px; }
.questioneery .survey-section .favorite .question-first { margin-bottom: 16px; font-family: Source Sans Pro; font-style: normal; font-weight: bold; font-size: 20px; line-height: 24px; text-transform: uppercase; color: #000000; text-align: left; }
.questioneery .survey-section .favorite .field { width: 620px; margin-bottom: 32px; border-radius: 4px 0px 0px 4px; background-color: #ededed; }
.questioneery .survey-section .container-profile img {
  display: inline-block;
  margin-top: 10px;
  margin-right: 15px;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  object-fit: cover;
}
.questioneery .survey-section .container-profile p {
  margin-top: 10px;
    margin-bottom: 4px;
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: 900;
    font-size: 18px;
    line-height: 22px;
    text-transform: uppercase;
    color: #000000;
    display: inline-block;
}

.questioneery .button-send { margin-bottom: 32px; margin-right: 10px; padding: 12px 32px; border: 1px solid #00bcd4; border-radius: 4px; font-family: Source Sans Pro; font-style: normal; font-weight: bold; font-size: 14px; line-height: 18px; text-transform: uppercase; color: #000000; }
.questioneery .button-skip { margin-bottom: 32px; font-family: Source Sans Pro; font-style: normal; font-weight: bold; font-size: 14px; line-height: 18px; text-transform: uppercase; color: #0097a7; padding: 12px 32px; }

.questioneery .content-footer { margin-bottom: 128px; padding: 24px 0; background: #f8f8f8; text-align: center; }
.questioneery .content-footer .title { margin-bottom: 8px; font-family: Lora; font-style: normal; font-weight: bold; font-size: 32px; line-height: 32px; text-align: center; color: #000000; }
.questioneery .content-footer .content-footer-text { padding: 0 353px; margin-bottom: 24px; font-family: Source Sans Pro; font-style: normal; font-weight: normal; font-size: 18px; line-height: 24px; text-align: center; color: #000000; }
.questioneery .content-footer .button-registration { padding: 15px 32px; background: #00bcd4; border-radius: 4px; font-family: Source Sans Pro; font-style: normal; font-weight: bold; font-size: 14px; line-height: 18px; text-align: center; text-transform: uppercase; color: #ffffff; }

@media screen and  (max-width:1352px){
  .questioneery .content-header .cols-full{
    display: flex;
    flex-direction: column-reverse;
    text-align: center;
  }

  .questioneery .content-header .cols-full .col6of12{
    width: 100%;
  }
  
  .questioneery .content-header .title-sub {
  width: 100%;
  margin-bottom: 24px;
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 24px;
  color: #000000;
  text-align: left;
}
}

 @media screen and (max-width:1186px){

  .questioneery .content-page .title-sub {
    margin-top:20px ;
    padding: 0 150px;
    text-align: left;
  }
  .questioneery .content-page .title {
    padding: 0 150px;
  }

  .questioneery .content-footer .content-footer-text {
    padding: 0 200px;
    text-align: left;
  }

  .question {
    display: grid;
    justify-content: left;
    text-align: left;
  }
} 

@media screen and (max-width:860px){
  .questioneery .content-header {
    margin: 80px 80px 64px 80px;
  }

  .questioneery .content-page .title {
    padding: 0 50px;
  }

  .questioneery .content-footer .content-footer-text {
    padding: 0 100px;
    text-align: left;
  }
  .questioneery .content-page .title-sub {
    margin-top: 20px;
    padding: 0 50px;
    text-align: left;
  }
  .questioneery .survey-section {
    margin: 0px 80px ;
  }
}

@media screen and (max-width:788px){
  .questioneery .content-header {
    margin: 80px 50px 64px 50px;
  }
  .questioneery .survey-section {
    margin: 0px 50px ;
  }

  .questioneery .content-page .title-sub {
    margin-top: 20px;
    padding: 0 60px;
  }
  .questioneery .content-footer .content-footer-text {
    padding: 0 60px;
    text-align: left;
  }

  .questioneery .content-page .title {
    padding: 0 30px;
  }
}

@media screen and (max-width:726px){
  .questioneery .survey-section .favorite .field {
    width:100%;
  }

}

@media screen and (max-width:688px){
  .questioneery .content-page .title {
    padding: 0 10px;
  }
  .questioneery .content-page .title-sub {
    margin-top: 20px;
    padding: 0 15px;
  }
}

@media screen and (max-width:639px){
  .questioneery .survey-section .favorite .title {
    margin-bottom: 24px;
    font-family: Lora;
    font-style: normal;
    font-weight: bold;
    font-size:25px;
    line-height: 32px;
    color: #000000;
  }
}

@media screen and (max-width:487px){
.questioneery .survey-section .favorite .title {
    font-size: 20px;
  }
  .questioneery .content-page .title {
  padding: 0px;
  text-align: left;
  font-size: 20px;
}

.questioneery .survey-section {
  margin: 0px 20px;
}

.questioneery .content-header {
  margin: 80px 20px 64px 20px;
}
.questioneery .content-footer .content-footer-text {
  padding: 0 20px;
  text-align: left;
}

.questioneery .content-page {
  margin-bottom: 64px;
  background-color: #f8f8f8;
  padding: 5px 20px;
  text-align: left;
}

.questioneery .survey-section .favorite .question {
  font-size: 16px;
}

.questioneery .content-footer .title {
  font-size: 20px;
}

.questioneery .content-page .title-sub {
  margin-top: 20px;
  padding: 0 5px;
}

.questioneery .content-header .title {
  font-size: 50px;
}
}