.seller-information-property {
    margin-top: 16px;
    padding: 0 30px;
  }
  
  .seller-information-property .property-title {
    font-family: Lora;
    font-size: 24px;
    font-weight: 700;
    line-height: 26px;
  }
  
  .seller-information-property .property-label {
    font-family: Source Sans Pro;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 12px;
    margin-top: 6px;
    margin-bottom: 10px;
  }
  
  .seller-information-property .checkbox-with-label {
    margin-top: 15px;
  }
  
  .seller-information-property .checkbox-with-label.mt-0 {
    margin-top: 0;
  }
  
  .seller-information-property .textfield-search .notchedOutline {
    border: none;
  }
  
  .seller-information-property .textfield-search .muiFocused>input {
    border: none;
  }
  
  .seller-information-property .section-2>p,
  .seller-information-property .section-2 .sector-group>p {
    font-family: Source Sans Pro;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 12px;
    margin-bottom: 13px;
    margin-top: 24px;
  }
  
  .seller-information-property .section-2 .switch-container>label {
    font-family: Source Sans Pro;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
  }
  
  .seller-information-property .section-2 .dates-container .dash {
    margin: 0 5px;
  }

  .seller-information-property .section-2 .dates-container .date-input{
    background-color:#ededed;
    border:none;
    padding: 5px;
    border-radius: 4px;
  }
  
  .text-field {
    width: 150px;
    height: 57px;
    margin: 0;
    background-color: #ededed; 
    border-radius: 4px;
  }
  
  .text-field.w200 {
    width: 150px;
  }
  .text-field.w300 {
    width: 300px;
  }
  
  .text-field .MuiInputBase-root>input:focus {
    border: none !important;
  }
  
  .square-meter {
    font-family: Source Sans Pro;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    opacity: 0.6;
    margin-left: 5px;
  }
  
  .seller-information-property .section-2 .checkbox-with-label {
    margin-top: 15px;
  }
  
  .seller-information-property .section-3 .square-meter-labels {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 13px;
    margin-top: 6px;
  }
  
  .seller-information-property .section-3 .square-meter-labels>div>p {
    font-family: Source Sans Pro;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 12px;
  }
  
  .seller-information-property .section-3 .sqare-meter-inputs {
    align-items: center;
    display: flex;
    justify-content: space-between;
  }
  
  .seller-information-property .section-3 .sqare-meter-inputs .box {
    width: 117px;
    height: 56px;
    background-color: #ededed;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
  }
  
  .seller-information-property .section-3 .sqare-meter-inputs .box>input {
    /* width: fit-content; */
    max-width: 35px;
    border: none;
    background-color: transparent;
    margin-left: 5px;
    font-family: Source Sans Pro;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
  }
  
  .seller-information-property .section-3 .sqare-meter-inputs .box>p {
    font-family: Source Sans Pro;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    opacity: 0.6;
  }
  
  .seller-information-property .section-3 .sqare-meter-inputs .checkbox-section {
    display: flex;
    font-family: Source Sans Pro;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
  }
  
  .seller-information-property .section-3 .sqare-meter-inputs .checkbox-section>p {
    font-family: Source Sans Pro;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 12px;
    margin-left: 7px;
    margin-right: 15px;
  }
  
  .seller-information-property .section-3 .common-section-group {
    display: flex;
    justify-content: space-between;
    gap: 10px;
    /* flex-wrap: wrap; */
  }
  
  .seller-information-property .section-3 .common-section {
    margin-top: 12px;
    display: flex;
    flex-direction: column;
  }
  
  .seller-information-property .section-3 .common-section .separator {
    padding: 0 10px;
    font-family: Source Sans Pro;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 12px;
  }
  
  .seller-information-property .section-3 .common-section .floor-container {
    display: flex; align-items: center;
  }
  
  .seller-information-property .section-3 .common-section>p {
    font-family: Source Sans Pro;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 12px;
  }
  
  .seller-information-property .section-3 .common-section>p {
    font-family: Source Sans Pro;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 12px;
    margin-bottom: 13px;
  }
  
  .seller-information-property .section-3 .common-section .content {
    display: flex;
    align-items: center;
  }
  
  .seller-information-property .section-3 .common-section .content .grey-section {
    height: 56px;
    background-color: #ededed;
    display: flex;
    padding: 0px 12px;
    align-items: center;
    border-radius: 4px;
  }
  
  .seller-information-property .section-3 .common-section .content .inline-section {
    display: flex;
    margin-left: 15px;
    align-items: center;
  }

  .seller-information-property .section-3 .common-section .content .grey-section .areaUnitSelect{
    width: 180px;
    outline: 0;
  }
  
  .seller-information-property .section-3 .common-section .content .grey-section .mutliSelect {
    min-width: 330px;
    outline: 0;
  }
  
  .seller-information-property .section-3 .common-section .content .grey-section .mutliSelect .css-yk16xz-control {
    background-color: transparent;
    border: none;
    outline: 0;
  }
  
  .seller-information-property .section-3 .common-section .content .grey-section .mutliSelect .css-1pahdxg-control {
    background-color: transparent;
    border: none;
    outline: 0;
    box-shadow: 0;
  }
  
  .seller-information-property .section-3 .common-section .content .grey-section .mutliSelect .css-yk16xz-control .css-g1d714-ValueContainer .css-1rhbuit-multiValue {
    height: 26px;
    background-color: white;
    border-radius: 16px;
    padding: 0px 12px;
  }
  
  .seller-information-property .section-3 .common-section .content .grey-section .mutliSelect .css-yk16xz-control .css-g1d714-ValueContainer .css-1rhbuit-multiValue>div {
    font-family: Source Sans Pro;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    opacity: 0.5;
  }
  
  .seller-information-property .section-3 .common-section .content .grey-section .mutliSelect .css-yk16xz-control .css-g1d714-ValueContainer .css-1rhbuit-multiValue .css-12jo7m5 {
    font-family: Source Sans Pro;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    opacity: 0.5;
  }
  
  .seller-information-property .section-3 .common-section .content .grey-section .mutliSelect .css-yk16xz-control .css-g1d714-ValueContainer .css-1rhbuit-multiValue .css-xb97g8>svg {
    background-color: black;
    z-index: 100;
    border-radius: 50%;
    color: white;
  }
  
  .seller-information-property .section-3 .common-section .content .grey-section>input {
    border: none;
    background-color: transparent;
    margin-left: 15px;
    width: 40px;
  }
  
  .seller-information-property .section-3 .common-section .content>p {
    font-family: Source Sans Pro;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    margin: 0px 12px;
  }
  
  .seller-information-property .section-3 .common-section .content .inline-section>p {
    font-family: Source Sans Pro;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 12px;
    margin-left: 7px;
  }
  
  .checkbox-with-label>label {
    margin-left: 0;
    margin-right: 0;
  }
  
  .checkbox-with-label .MuiFormControlLabel-label,
  .common-section .MuiFormControlLabel-label {
    margin-left: 7px;
    font-family: Source Sans Pro;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    user-select: none;
  }
  
  .common-checkbox {
    width: 16px;
    height: 16px;
    vertical-align: middle
  }
  
  @media screen and (max-width:400px){
    .seller-information-property .section-3 .common-section .content .grey-section {
      padding: 0px;
  }
  }
  
  @media screen and (max-width:506px){
    .seller-information-property .section-3 .common-section .floor-container {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      gap: 5px;
    }
    .seller-information-property .section-3 .common-section-group {
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      gap: 10px;
    }
    .seller-information-property .section-3 .common-section .content .grey-section .mutliSelect {
     width: 100% !important;
     min-width:100% !important;
    }
    .grey-section{
      width: 100% !important;
    }
    .text-field {
      width: 100% !important;
    }
    .text-field.w200 {
      width:100% !important;
    }
    .seller-information-property .section-3{
      width: 100%;
    }
    
  }