.profile-virtual-assistant-relationship .sellers-property {
  margin-top: 24px;
  margin-bottom: 24px;
  padding: 16px;
  background: #e8e8e8;
  border-radius: 12px;
  max-height: 665px;
  overflow-y: auto;
}
.profile-virtual-assistant-relationship .sellers-property .title {
  font-family: Lora;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 26px;
}
.buyer-sent-offers {
  margin-bottom: 24px;
  background: #e8e8e8;
  padding: 16px;
  border-radius: 12px;
  max-height: 595px;
  overflow-y: auto;
}

.buyer-sent-offers .title {
    font-family: Lora;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 26px;
  }

@media screen and (max-width: 1200px) {
  .buyer-sent-offers {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 40px;
  }

  .buyer-sent-offers .cols-full {
    display: flex !important;
    flex-direction: column !important;
    gap: 40px;
  }

  .buyer-sent-offers .col3of12 {
    width: 100% !important;
  }

  .buyer-sent-offers .col4of12 {
    width: 100% !important;
  }
  .buyer-sent-offers .col2of12 {
    width: 100% !important;
  }
}

@media screen and (max-width: 920px) {
  .buyer-sent-offers {
    grid-template-columns: repeat(1, 1fr);
  }
}
