.dialog-profile-change-offer {
    text-align: center;
}

.dialog-profile-change-offer-content {
    padding: 40px 120px;
    position: relative;
}

.dialog-profile-change-offer-content .title-header {
    padding: 0;
    margin-bottom: 8px;
    position: relative;
}

.dialog-profile-change-offer-content .title-header .title {
    display: inline-block;
    font-size: 48px;
    line-height: 52px;
    font-weight: 700;
}

.dialog-profile-change-offer-content .title-header .close-icon {
    position: absolute;
    cursor: pointer;
    top: 30px;
    right: 30px;
}

.dialog-profile-change-offer-content .content {
    padding: 0;
    overflow-x: hidden;
}

.dialog-profile-change-offer-content .content .sub-title {
    font-size: 17px;
    line-height: 24px;
    font-weight: 400;
    margin-bottom: 32px;
}

.dialog-profile-change-offer-content .content .offer-information {
    padding: 0 145px;
    display: flex;
    margin-bottom: 32px;
}

.dialog-profile-change-offer-content .content .offer-information .minus {
    margin: 23px 30px 0 30px;
    font-size: 30px;
}

.dialog-profile-change-offer-content .content .offer-information .field-group {
    display: grid;
    text-align: left;
}

.dialog-profile-change-offer-content .content .offer-information .field-group .label {
    margin-bottom: 4px;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.4px;
    font-weight: 400;
    cursor: default;
}

.dialog-profile-change-offer-content .content .offer-information .field-group .box {
    padding: 4px 8px;
    background: #F4F4F4;
    border-radius: 4px;
    width: 150px;
    z-index: 10;
    border: 1px solid transparent;
}

.dialog-profile-change-offer-content .content .offer-information .field-group .box-error {
    border: 1px solid #F32013 !important;
}

.dialog-profile-change-offer-content .content .offer-information .field-group .box.new-offer {
    background: #E0F7FA;
    width: 200px !important;
}

.dialog-profile-change-offer-content .content .offer-information .field-group .box.new-offer .price-number {
    display: inline-block;
    padding: 4px 8px;
    background: #E0F7FA;
    border-radius: 4px;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 32px;
    text-transform: uppercase;
    color: #000000;
    width: 135px;
    border: none;
}

.dialog-profile-change-offer-content .content .announced-price {
    display: flex;
    padding: 0 35px;
    margin-bottom: 20px;
    align-items: center;
    justify-content: center;
}

.dialog-profile-change-offer-content .content .announced-price .label {
    font-size: 18px;
    line-height: 24px;
    font-weight: 400;
    margin-right: 10px;
}

.dialog-profile-change-offer-content .content .announced-price .box-price {
    padding: 4px 8px;
    margin-right: 16px;
    border-radius: 8px;
    background: #E8E8E8;
    color: #000000;
    font-size: 20px;
    line-height: 30px;
    font-weight: 700;
}

.dialog-profile-change-offer-content .content .announced-price .box-group {
    margin-right: 18px;
    display: flex;
    align-items: center;
}

.dialog-profile-change-offer-content .content .announced-price .box-group .box-text {
    padding: 3px 8px;
    background: #FFF59D;
    border-radius: 4px;
    font-size: 18px;
    line-height: 22px;
    font-weight: 900;
    margin-right: 6px;
}

.dialog-profile-change-offer-content .content .announced-price .box-group:last-child {
    margin-right: 0;
}

.dialog-profile-change-offer-content .content .description {
    margin-bottom: 44px;
    text-align: left;
}

.dialog-profile-change-offer-content .content .description .label {
    margin-bottom: 4px;
    font-size: 12px;
    line-height: 16px;
    font-weight: 400;
    letter-spacing: 0.4px;
}

.dialog-profile-change-offer-content .content .description .box {
    padding: 16px;
    border-radius: 8px;
    background: #F4F4F4;
}

.dialog-profile-change-offer-content .content .actions {
    margin-top: 8px;
}

.dialog-profile-change-offer-content .content .actions .button-send {
    height: 48px;
    padding: 15px 32px;
    background: #00BCD4;
    border-radius: 4px;
    color: #fff;
    text-transform: uppercase;
    margin-right: 34px;
    font-size: 14px;
    line-height: 18px;
    font-weight: 700;
}

@media screen and (max-width: 1078px) {
    .dialog-profile-change-offer-content .content .offer-information {
        padding: 0;
        justify-content: center;
    }

    .dialog-profile-change-offer-content .content .announced-price {
        flex-direction: column;
        gap: 20px;
    }
}

@media screen and (max-width: 768px) {
    .dialog-profile-change-offer-content {
        padding: 40px 20px !important;
    }

    .dialog-profile-change-offer-content .content .offer-information {
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .dialog-profile-change-offer-content .content .offer-information .minus{
        margin-top: 0;
    }
}