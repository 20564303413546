.list-content {
    margin-bottom: 32px;
    padding: 24px;
    background: #FFFFFF;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px rgba(0, 0, 0, 0.12), 0px 1px 3px rgba(0, 0, 0, 0.2);
    border-radius: 12px;
}

.list-content .left-part {
    float: left;
    margin-right: 12px;
    position: relative;
}

.list-content .left-part .image {
    float: left;
    width: 104px;
    height: 104px;
    border-radius: 70px;
}

.list-content .left-part .image img {
    width: 104px;
    height: 104px;
    border-radius: 70px;
}

.list-content .left-part .image-svg {
    position: absolute;
    top: 70%;
    left: 83%;
}

.list-content .right-part {
    float: left;
    margin-top: 10px;
    width: 200px;
}

.list-content .right-part .title {
    margin-bottom: 12px;
    font-family: Lora;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 28px;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.list-content .right-part .title>a {
    color: #000000;
}

.list-content .right-part .all-stars {
    margin-bottom: 4px;
    margin-left: -3px;
}

.list-content .right-part .result {
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 18px;
    color: #000000;
    margin-bottom: 0px;
}

.list-content .joined {
    float: left;
    margin-top: 10px;
    margin-right: 55px;
}

.list-content .joined .title {
    margin-bottom: 6px;
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    color: #656565;
}

.list-content .joined .date {
    padding: 16px 19px;
    background: #F4F4F4;
    border-radius: 4px;
}

.list-content .joined .date img {
    float: left;
    margin-top: 1px;
    margin-right: 11px;
}

.list-content .joined .date .date-text {
    float: left;
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 24px;
    color: #000000;
    text-transform: capitalize;
    margin-bottom: 0px;
}

.list-content .services-provided {
    float: left;
    margin-top: 10px;
    margin-right: 78px;
}

.list-content .services-provided .title {
    float: left;
    margin-bottom: 6px;
    margin-right: 11px;
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    color: #656565;
}

.list-content .services-provided .tooltip {
    float: left;
}

.list-content .services-provided .services {
    margin-top: 24px;
    padding: 15px 19px;
    background-color: #F4F4F4;
    border-radius: 4px;
}

.list-content .services-provided .services img {
    float: left;
    width: 20px;
    margin-top: 3px;
    margin-right: 10px;
}

.list-content .services-provided .services .services-text {
    float: left;
    font-size: 24px;
    line-height: 28px;
    margin-bottom: 0px;
}

.list-content .price-content {
    float: left;
    margin-top: 8px;
    /* margin-right: 63px; */
    
}

.list-content .price-content .title {
    float: left;
    margin-right: 11px;
    margin-bottom: 3px;
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    color: #656565;
}

.list-content .price-content .tooltip {
    float: left;
}

.list-content .price-content .price-text {
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: bold;
    font-size: 40px;
    line-height: 60px;
    text-transform: uppercase;
    color: #000000;
    margin-bottom: 0px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.list-content .price-content .price-text span {
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: 900;
    font-size: 18px;
    line-height: 22px;
    text-transform: uppercase;
    color: #000000;
}

.list-content .price-content .location {
    margin-top: 24px;
    padding: 15px 19px;
    background-color: #F4F4F4;
    border-radius: 4px;
}

.list-content .price-content .location img {
    float: left;
    width: 14px;
    margin-top: 3px;
    margin-right: 10px;
}

.list-content .price-content .location .location-text {
    float: left;
    font-size: 18px;
    line-height: 24px;
    font-weight: 700
}

.list-content .price-content .photographer-services {
    margin-top: 24px;
    padding: 18px;
    background-color: #F4F4F4;
    border-radius: 4px;
}

.list-content .price-content .photographer-services img {
    width: 20px;
    height: 20px;
    margin-right: 20px;
}

.list-content .price-content .photographer-services img:last-child {
    margin-right: 0px;
}

.list-content .buttons {
    float: right;
}

.list-content .buttons .button-send {
    display: block;
    text-align: center;
    margin-bottom: 10px;
    padding: 15px 32px;
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 18px;
    background: #00BCD4;
    border-radius: 4px;
    text-transform: uppercase;
    color: #EFEFEF;
}

.list-content .buttons .button-view-profile {
    display: block;
    text-align: center;
    padding: 15px 32px;
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 18px;
    background: #fff;
    border-radius: 4px;
    text-transform: uppercase;
    color: #000;
    border: 1px solid #00BCD4;
}

@media screen and (max-width: 1240px) {

    .list-content .services-provided {
        margin-right: 20px;
    }

    .list-content .joined {
        margin-right: 20px;
    }

    .list-content .right-part {
        margin-right: 20px;
    }
}

@media screen and (max-width: 1135px) {
    .list-content .buttons {
        margin-top: 20px;
    }

    .list-content {
        display: flex;
    }
}

@media screen and (max-width: 1070px) {
    .list-content {
        flex-direction: column;
    }

    .list-content .joined {
        margin-right: 0px;
        width: 100%;
    }

    .list-content .services-provided {
        margin-right: 0px;
        width: 100%;
    }

   
}

@media screen and (max-width: 767px) {
    .list .col {
        padding: 0;
    }
}


@media screen and (max-width:400px){
    .list-content .price-content {
      display: flex;
      flex-direction: row-reverse;
      justify-content: flex-start;
      align-items: center;
      gap:5px;
      
    }
    
    .list-content .price-content .price-text {
        font-size: 20px;
    }
}