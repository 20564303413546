.photographer-profile {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  height: 200px;
  margin-bottom: 64px;
  margin-top: 32px;

}

.photographer-profile-left {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  height: 100%;
}

.photographer-profile-left-profile {
  position: relative;
}

.photographer-profile-left-profile .photographer-avatar{
  width: 170px;
  height: 170px;
  border-radius: 50%;
}

.photographer-profile-left-profile>.photographer-avatar>.profile-pic {
  width: 170px;
  height: 170px;
  border-radius: 50%;
  object-fit: cover;
}

.photographer-profile-left-profile>.right-icon {
  position: absolute;
  top: 130px;
  right: 2px;
}

.photographer-profile-left-profile>.photographer_location {
  width: 170px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin-top: 6px;
 
}

.photographer-profile-left-profile>p>img {
  margin-right: 9px;
}

.photographer-profile-left-content {
  margin-left: 20px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  width: fit-content;
}

.photographer-profile-left-content>.top {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.photographer-profile-left-content>.top>.top-title {
  margin: 0;
  padding: 0;
  font-family: 'Lora', sans-serif;
  font-style: normal;
  font-weight: 1;
  font-size: 42px;
  line-height: 52px;
  color: #000;
}

.photographer-profile-left-content>.top>.ratings {
  display: grid;
  grid-template-columns: auto auto auto;
  column-gap: 11.66px;
  align-items: center;
  justify-content: center;
  font-family: 'Source Sans Pro', sans-serif;
  font-style: normal;
}

.photographer-profile-left-content>.top>.ratings>h5 {
  margin: 0;
  padding: 0;
  font-weight: 900;
  font-size: 18px;
  line-height: 22px;
  text-transform: uppercase;
  color: #000;
}

.photographer-profile-left-content>.top>.ratings>p {
  margin: 0;
  padding: 0;
  font-weight: normal;
  font-size: 18px;
  line-height: 24px;
  color: #000;
}

.photographer-profile-left-content>.top>.ratings>.stars {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.photographer-profile-left-content>.bottom {
  display: flex;
  align-items: flex-start;
  width: 100%;
  gap: 20px;
}

.photographer-profile-left-content>.bottom>.button {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.photographer-profile-left-content>.bottom>.button .title-content {
  display: flex;
}

.photographer-profile-left-content>.bottom>.button .title {
  margin-bottom: 9px;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  color: #656565;
}


.photographer-profile-left-content>.bottom>.button .title-content .icon {
  width: 20px;
  height: 20px;
  color: #000000;
  margin-left: 11px;
  margin-top: -1px;
}


.photographer-profile-left-content>.bottom>.button>p {
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  color: #656565;
  display: flex;
  align-items: center;
  margin-bottom: 6px;
}

.photographer-profile-left-content>.bottom>.button>p>img {
  margin-left: 9px;
}

.photographer-profile-left-content>.bottom>.button>button {
  display: flex;
  align-items: center;
  font-family: 'Source Sans Pro', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
  text-transform: uppercase;
  color: #000;
  background: #f4f4f4;
  border-radius: 4px;
  padding: 16px;
  border: none;
  cursor: pointer;
}

.photographer-profile-left-content>.bottom>.button>button>img {
  margin-right: 10px;
  width: 20px;
}

.photographer-profile-right {
  display: flex;
  height: 100%;
  margin-left: 10px;
  justify-content: center;
  align-items: center;
}

.photographer-profile-right .button {
  background-color: #00bcd4;
  height: 48px;
  font-family: Source Sans Pro;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 18px;
  text-align: left;
  color: white;
  padding: 15px 32px 15px 32px;
  border: none;
  outline: 0;
  border-radius: 4px;
  cursor: pointer;
  text-transform: uppercase;
  white-space: nowrap;
}

.photographer-profile-right .button:hover {
  background-color: #00bcd4;
  height: 48px;
  font-family: Source Sans Pro;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 18px;
  text-align: left;
  color: white;
  padding: 15px 32px 15px 32px;
  border: none;
  outline: 0;
  border-radius: 4px;
  cursor: pointer;
  text-transform: uppercase;
}

.photographer-profile-right-about {
  width: 530px;
  min-height: 190px;
  max-height: 190px;
  height: calc(100% - 32px);
  font-family: 'Source Sans Pro', sans-serif;
  font-style: normal;
  background: #f8f8f8;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px rgba(0, 0, 0, 0.12), 0px 1px 3px rgba(0, 0, 0, 0.2);
  border-radius: 12px;
  padding: 16px;
  margin-right: 30px;
  overflow-y: scroll;
}

.photographer-profile-right-about>h5 {
  margin: 0;
  padding: 0;
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
  text-transform: uppercase;
  color: #000;
}

.photographer-profile-right-about>p {
  flex: 1;
  font-weight: normal;
  font-size: 18px;
  line-height: 24px;
  color: #000;
  word-wrap: break-word; 
  overflow-wrap: break-word; 
  white-space: normal; 
}

@media screen and (max-width: 1200px) {
	.seller-photographer-profile .photographer-profile {
		flex-direction: column;
		min-height: 700px;
		justify-content: center;
		align-items: center;
	}

	.seller-photographer-profile .photographer-profile .photographer-profile-left {
		flex-direction: column;
		width: 500px;
		margin-bottom: 30px;
	}

	.seller-photographer-profile .photographer-profile .photographer-profile-right-about {
		width: 500px;
		margin-right: 0px;
	}

	.seller-photographer-profile   .photographer-profile-left-content {
		margin-left: 0px;
		width: 100%;
		gap: 20px;
	}

	.seller-photographer-profile .photographer-profile-right {
		margin-left: 0px;
		margin-right: 0px;
    flex-direction:column;
    align-items: center;
    gap: 20px;
	}

	.seller-photographer-profile .photographer-profile-left-profile {
		margin: 0 auto;
	}

  .photographer-profile-left-content>.top>.top-title {
    margin: 0;
    padding: 0;
    font-family: 'Lora', sans-serif;
    font-style: normal;
    font-weight: 1;
    font-size: 42px;
    line-height: 52px;
    text-align: center;
    color: #000;
}
}


@media screen and (max-width: 567px) {

	.seller-photographer-profile .photographer-profile .photographer-profile-left {
		
		width: 100%;
		
	}

	.seller-photographer-profile .photographer-profile .photographer-profile-right-about {
		width: 100%;
		
	}

	.seller-photographer-profile .photographer-profile-right {
		width: 100%;
	}

	.seller-photographer-profile {
		margin: 20px auto !important;
	}

}