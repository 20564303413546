.clients-no-services { position: relative; }
.clients-no-services .client-content .link-box { bottom: 0; position: absolute; margin-bottom: 8px; }
.clients-no-services .client-content .link-box .link-seller { padding: 20px 14px; background: #EFEFEF; border-radius: 12px; font-weight: 500; font-size: 14px; line-height: 16px; color: #000; }
.clients-no-services .client-content .link-box .link-seller .icon { margin-right: 10px; margin-top: -3px; }

.clients-no-services .client-content .account-box { padding: 12px; background: #EFEFEF; border-radius: 12px; cursor: pointer; }
.clients-no-services .client-content .account-box { margin-bottom: 12px; }
.clients-no-services .client-content .account-box.active { box-sizing: border-box; border: 5px solid #000; cursor: default; }

.clients-no-services .client-content .account-box .header .photo { padding: 26px; background: #CFCFCF; border-radius: 5.12852e+07px; float: left; height: 35px; margin-right: 12px; }
.clients-no-services .client-content .account-box .header .name { font-size: 24px; line-height: 28px; font-weight: 500; margin-left: 12px; }
.clients-no-services .client-content .account-box .header .type { font-size: 14px; line-height: 16px; font-weight: 500; margin-top: 4px; margin-left: 12px; }

.clients-no-services .client-content .account-box .header .rating { margin-top: 10px; }
.clients-no-services .client-content .account-box .header .rating .icon { margin-right: 6px; }
.clients-no-services .client-content .account-box .header .rating .number { font-size: 18px; line-height: 21px; font-weight: 500; vertical-align: top; margin-right: 14px; }

.clients-no-services .client-content .account-box .property-listing { margin-top: 12px; }
.clients-no-services .client-content .account-box .property-listing .property-row { display: table; margin-bottom: 8px; }
.clients-no-services .client-content .account-box .property-listing .property-row .text { font-size: 14px; line-height: 16px; font-weight: 500; margin-right: 6px; float: left; }
.clients-no-services .client-content .account-box .property-listing .property-row .icon { float: left; margin-right: 6px; margin-top: -4px; }
.clients-no-services .client-content .account-box .property-listing .property-row .link { color: #000; text-decoration: underline !important; font-size: 14px; line-height: 16px; font-weight: 700; float: left; }

.clients-no-services .session { padding: 18px 16px 16px 15px; background: #CFCFCF; border-radius: 12px; margin-top: 11px; align-items: center; }
.clients-no-services .session .info { float: left; }
.clients-no-services .session .info .title { font-size: 24px; line-height: 28px; margin-bottom: 8px; }
.clients-no-services .session .info .text { font-size: 18px; line-height: 21px; font-weight: 400; } 
.clients-no-services .session .button { float: right; padding: 16px 32px; height: 50px; background-color: #000; border-radius: 12px; color: #FFFFFF; text-transform: none; font-weight: 400; font-size: 16px; line-height: 18px; margin-left: 60px; }

.clients-no-services .session .buttons .button.cancel { margin-right: 10px; background-color: #CFCFCF; border: 1px solid #000 !important; color: #000; border-radius: 12px; }
.clients-no-services .session .buttons { float: right; }
.clients-no-services .session .buttons .button { margin: 0 }

.clients-no-services .client-property { margin-top: 24px; padding: 16px; background: #CFCFCF; border-radius: 12px; }
.clients-no-services .client-property .title { font-size: 24px; line-height: 28px; font-weight: 500; }

.clients-no-services .client-property .propery-details-box { padding: 16px; border-radius: 12px; background: #EFEFEF; margin-top: 16px; }
.clients-no-services .client-property .propery-details-box .image { margin-right: 16px; padding: 45px 40px 50px 40px; background: #CFCFCF; border-radius: 4px; float: left; }

.clients-no-services .client-property .propery-details-box .details-part-left { float: left; }
.clients-no-services .client-property .propery-details-box .details-part-left .main-price { font-size: 36px; line-height: 42px; }
.clients-no-services .client-property .propery-details-box .details-part-left .activites { margin-top: 52px; margin-left: 5px; }
.clients-no-services .client-property .propery-details-box .details-part-left .activites .icon { margin-right: 4px; height: 16px; margin-top: 2px; }
.clients-no-services .client-property .propery-details-box .details-part-left .activites .number { font-size: 18px; line-height: 21px; font-weight: 500; margin-right: 12px; }
.clients-no-services .client-property .propery-details-box .details-part-left .activites .number .sup { font-size: 10px; line-height: 11px; font-weight: 700; }

.clients-no-services .client-property .propery-details-box .details-part-left .address { margin-top: 10px; }
.clients-no-services .client-property .propery-details-box .details-part-left .address .icon { margin-right: 8px; }
.clients-no-services .client-property .propery-details-box .details-part-left .address .text { font-size: 18px; line-height: 21px; font-weight: 500; }


.clients-no-services .client-property .propery-details-box .details-part-right { float: right; margin-top: 5px; }
.clients-no-services .client-property .propery-details-box .details-part-right .price-row { margin-bottom: 7px; }
.clients-no-services .client-property .propery-details-box .details-part-right .price-row .price { float: right; padding: 5px 10px; background: #CFCFCF; border-radius: 8px; }
.clients-no-services .client-property .propery-details-box .details-part-right .price-row .tooltip { float: right; margin-left: 6px; margin-top: 6px; }

.clients-no-services .client-property .propery-details-box .details-part-right .property { float: right; margin-top: 40px; }
.clients-no-services .client-property .propery-details-box .details-part-right .property .icon { margin-right: 6px; }
.clients-no-services .client-property .propery-details-box .details-part-right .property .link { text-decoration: underline !important; color: #000; }

.clients-no-services .client-property .propery-details-box .details-last { float: left; }
.clients-no-services .client-property .propery-details-box .details-last .paragraph { font-size: 18px; line-height: 21px; font-weight: 500; float: left; margin-right: 94px; }
.clients-no-services .client-property .propery-details-box .details-last .rating { float: right; }
.clients-no-services .client-property .propery-details-box .details-last .rating .icon { margin-right: 7px; margin-left: 12px; }
.clients-no-services .client-property .propery-details-box .details-last .rating .icon-eye { vertical-align: text-top; }
.clients-no-services .client-property .propery-details-box .details-last .rating .number { font-size: 18px; line-height: 21px; font-weight: 500; vertical-align: top; }

.clients-no-services .client-property .propery-details-box .line { margin: 11px 0 14px 0; }

.clients-no-services .client-property .additional-info-box { padding: 18px; background: #EFEFEF; border-radius: 12px; margin-top: 16px; height: 222px; }
.clients-no-services .client-property .additional-info-box .info-row { margin-bottom: 12px; }
.clients-no-services .client-property .additional-info-box .info-row .icon { width: 20px; height: 18px; margin-right: 6px; }
.clients-no-services .client-property .additional-info-box .info-row .text { font-size: 14px; line-height: 16px; font-weight: 400; }

.clients-no-services .chat-area { margin-top: 24px; }
.clients-no-services .chat-area .chat-box, .clients-no-services .chat-area .chat-notifications { padding: 15px 13px; background: #EFEFEF; border-radius: 8px; height: 585px; position: relative; overflow-y: auto; overflow-x: hidden; }

.clients-no-services .chat-area .chat-box .field { position: absolute; bottom: 0; margin-bottom: 16px; width: 97%; }
.clients-no-services .chat-area .chat-box .field .button { padding: 12px 32px; height: 55px; width: 140px; background-color: #000; border-radius: 0px 12px 12px 0px; color: #FFFFFF; text-transform: none; font-weight: 400; font-size: 12px; line-height: 14px; }

.clients-no-services .chat-area .chat-box .chat-blob { padding: 13px 15px; background: #CFCFCF; border-radius: 12px 12px 12px 0px; width: 350px; margin-bottom: 8px; }
.clients-no-services .chat-area .chat-box .chat-blob .blob-header { display: flex; align-items: center; margin-bottom: 8px; }
.clients-no-services .chat-area .chat-box .chat-blob .blob-header .avatar { width: 24px; height: 24px; background: #C4C4C4; border-radius: 50%; }
.clients-no-services .chat-area .chat-box .chat-blob .blob-header .title { font-size: 14px; line-height: 16px; margin-left: 10px; }
.clients-no-services .chat-area .chat-box .chat-blob .blob-header .hour { font-size: 9px; line-height: 10px; margin-left: 10px; }
.clients-no-services .chat-area .chat-box .chat-blob .text { font-size: 12px; line-height: 14px; }

.clients-no-services .chat-area .chat-notifications .notification-blob { padding: 24px 16px 16px 16px; background: #E2E2E2; border-radius: 12px 12px 12px 0px; margin-bottom: 8px; position: relative; }
.clients-no-services .chat-area .chat-notifications .notification-blob .notification-blob-header { display: flex; align-items: center; margin-bottom: 8px; }
.clients-no-services .chat-area .chat-notifications .notification-blob .notification-blob-header .icon { position: absolute; top: 0; margin-top: -6px; margin-left: 6px; }
.clients-no-services .chat-area .chat-notifications .notification-blob .notification-blob-header .avatar { width: 24px; height: 24px; background: #C4C4C4; border-radius: 50%; }
.clients-no-services .chat-area .chat-notifications .notification-blob .notification-blob-header .title { font-size: 14px; line-height: 16px; margin-left: 10px; }
.clients-no-services .chat-area .chat-notifications .notification-blob .notification-blob-header .hour { font-size: 9px; line-height: 10px; margin-left: 10px; }
.clients-no-services .chat-area .chat-notifications .notification-blob .text { font-size: 12px; line-height: 14px; }

/* Scroll */
.clients-no-services .chat-area .chat-box::-webkit-scrollbar, .clients-no-services .chat-area .chat-notifications::-webkit-scrollbar { width: 6px !important; background: gray; }
.clients-no-services .chat-area .chat-box::-webkit-scrollbar-track, .clients-no-services .chat-area .chat-notifications::-webkit-scrollbar-track { background : #fff; border-radius: 12px; }
.clients-no-services .chat-area .chat-box::-webkit-scrollbar-thumb, .clients-no-services .chat-area .chat-notifications::-webkit-scrollbar-thumb { background-color :#000; border-radius: 10px; }

/* Dialog */
.dialog-clients { text-align: center; }
.dialog-clients-content { margin: 63px 157px 64px 118px; }
.dialog-clients-content .close-icon { cursor: pointer; float: right; position: absolute; top: 0; right: 0; margin-top: 20px; margin-right: 21px; }
.dialog-clients-content .title { font-size: 36px; line-height: 42px; margin-bottom: 7px; }

.dialog-clients-content .content { padding: 0; overflow-x: hidden; }
.dialog-clients-content .content .text { font-size: 16px; line-height: 18px; font-weight: 400; margin: 0px 40px 24px 40px; }

.dialog-clients-content .content .calendar { padding: 16px; background: #EFEFEF; border-radius: 10px; text-align: left; }
.dialog-clients-content .content .calendar .title { font-size: 16px; line-height: 18px; font-weight: 400; margin-bottom: 16px; }

.dialog-clients-content .content .hour-free, .dialog-clients-content .content .hour-not-free { padding: 16px; background: #EFEFEF; border-radius: 10px; margin-bottom: 16px; float: left; text-align: left; }
.dialog-clients-content .content .hour-free .title, .dialog-clients-content .content .hour-not-free .title { font-size: 16px; line-height: 18px; font-weight: 400; margin-bottom: 8px; }
.dialog-clients-content .content .hour-free .chip, .dialog-clients-content .content .hour-not-free .chip { padding: 4px 8px; background: #FFFFFF; border-radius: 4px; color: #9D9D9D; font-size: 12px; line-height: 14px; font-weight: 400; float: left; margin-right: 8px; margin-bottom: 8px; border: 1px solid #fff; box-sizing: border-box; cursor: pointer; }
.dialog-clients-content .content .hour-free .chip.active, .dialog-clients-content .content .hour-not-free .chip.active { border: 1px solid #000000; cursor: default; }
.dialog-clients-content .content .hour-free .chip.disabled, .dialog-clients-content .content .hour-not-free .chip.disabled { opacity: 0.3; cursor: not-allowed; }

.dialog-clients-content .content .actions { margin-top: 24px; } 
.dialog-clients-content .content .actions .button-noted { height: 40px; padding: 12px 32px; background: #000; border-radius: 12px; color: #FFFFFF; text-transform: none; margin-right: 16px; font-size: 12px; line-height: 14px; font-weight: 400; }
.dialog-clients-content .content .actions .button-free { height: 40px; padding: 12px 32px; border-radius: 12px; text-transform: none; font-size: 12px; line-height: 14px; font-weight: 400; }
.dialog-clients-content .content .end-text { font-size: 12px; line-height: 14px; font-weight: 400; text-align: center; margin-top: 8px; }