.buyer-account-box .header .photo { float: left; height: 75px; border-radius: 40px; margin-right: 16px; }
.buyer-account-box .header .name { font-size: 18px; line-height: 22px; font-weight: 500; margin-left: 12px; }
.buyer-account-box .header .type { font-size: 14px; line-height: 16px; font-weight: 500; margin-top: 4px; margin-left: 12px; }

.buyer-account-box .header .rating { margin-top: 10px; }
.buyer-account-box .header .rating .icon { margin-right: 6px; }
.buyer-account-box .header .rating .number { font-size: 14px; line-height: 18px; font-weight: 500; vertical-align: top; margin-right: 14px; }

.buyer-account-box .header .deal-info { margin-top: 14px; display: flex; }
.buyer-account-box .header .deal-info .text { font-size: 12px; line-height: 14px; font-weight: 500; margin-right: 4px; float: left; }
.buyer-account-box .header .deal-info .icon { margin-right: 6px; margin-top: -3px; float: left; }

.buyer-account-box .property-listing { margin-top: 12px; }
.buyer-account-box .property-listing .property-row { display: flex; margin-bottom: 8px; }
.buyer-account-box .property-listing .property-row .icon { margin-right: 8px; width: 20px; height: 20px; }
.buyer-account-box .property-listing .property-row .text { font-size: 14px; line-height: 16px; margin-top: 4px;  float: left; }

@media screen and (max-width:1272px){
    .buyer-account-box .header {
        display:flex;
        flex-direction:column;
        justify-content:center;
        align-items:center;
        width: 150px;
    }

    .buyer-account-box .header .name { 
        font-size: 18px; 
        line-height: 22px; 
        font-weight: 500; 
        margin-left: 0px !important;
        text-align:center;
     }
}