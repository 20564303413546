.buyer-public-profile {
    margin-top: 32px;
    padding: 0 10px;
}

.buyer-public-profile .header-content {
    position: relative;
    margin-bottom: 8px;
}

.buyer-public-profile .header-content .title {
    font-style: normal;
    font-weight: 600;
    font-size: 48px;
    line-height: 52px;
    text-align: center;
    margin-bottom: 60px;
}

.buyer-public-profile .photo-group {
    text-align: center;
}

.buyer-public-profile .photo-group .id {
    font-size: 12px;
    line-height: 14px;
    margin-top: 7px;
    letter-spacing: 0.4px;
}

.buyer-public-profile .photo-group .avatar {
    width: 100%;
    height: 100%;
    border-radius: 50%;
}

.buyer-public-profile .photo-group .avatar .profile-image {
    width: 178px;
    height: 178px;
    border-radius: 50%;
    object-fit: cover;
}

.buyer-public-profile .name {
    font-style: normal;
    font-weight: 600;
    font-size: 48px;
    line-height: 52px;
    margin-bottom: 72px;
}

.buyer-public-profile .label {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 18px;
    color: #656565;
    margin-bottom: 4px;
}

.buyer-public-profile .date {
    display: flex;
    padding: 16px;
    background: #E0F7FA;
    border-radius: 4px;
    width: fit-content;
}

.buyer-public-profile .date .date-img {
    width: 18px;
    height: 20px;
    margin-right: 11px;
}

.buyer-public-profile .date .date-text {
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    text-transform: capitalize;
    margin: -1px 0 0 0;
}

.buyer-public-profile .sub-title {
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 26px;
}

@media screen and  (max-width: 1000px){
    .buyer-public-profile .MuiGrid-container .MuiGrid-grid-xs-2,
    .buyer-public-profile .MuiGrid-container .MuiGrid-grid-xs-10 {
        width: 100%;
        flex-basis: 100% !important;
        max-width: 100%;
        text-align: center;
    }

    .buyer-public-profile .name {
        font-size: 35px;
        line-height: 40px;
        margin-bottom: 20px;
    }

    .buyer-public-profile .date {
        margin: 0 auto 30px auto;
    }
}

@media screen and  (max-width: 768px){
    .buyer-public-profile .header-content .title {
        font-size: 34px;
        line-height: 28px;
    }

    .buyer-public-profile .MuiGrid-container {
        display: flex !important;
        flex-direction: column !important;
        justify-content: center;
        align-items: center;
        margin-bottom: 0;
    }
    
    .buyer-public-profile .name {
        margin-bottom: 0;
    }

    .profile-buyer-section .back-to-search-page {
        margin: 10px 15px;
    }
}