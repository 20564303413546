.details { display: flex; }
.details .icon { margin-right: 18px; align-self: flex-start; }
.details .current-details .proposed-amount .title { margin-bottom: 8px; font-family: Lora; font-style: normal; font-weight: bold; font-size: 24px; line-height: 26px; text-align: left; }
.details .current-details .proposed-amount .text { margin-bottom: 16px; font-family: Source Sans Pro; font-style: normal; font-weight: normal; font-size: 18px; line-height: 24px; text-align: left; }
.details .current-details .proposed-amount .button-send { background: #00BCD4; border-radius: 0px 4px 4px 0px; padding: 19px 32px; font-family: Source Sans Pro; font-style: normal; font-weight: bold; font-size: 14px; line-height: 18px; text-transform: uppercase; color: #FFFFFF; }

.details .current-details .upcoming-payment { display: flex; }
.details .current-details .upcoming-payment .left-part .title { margin-bottom: 8px; font-family: Lora; font-style: normal; font-weight: bold; font-size: 24px; line-height: 26px; text-align: left; }
.details .current-details .upcoming-payment .left-part .text { margin-bottom: 16px; font-family: Source Sans Pro; font-style: normal; font-weight: normal; font-size: 18px; line-height: 24px; text-align: left; }
.details .current-details .upcoming-payment .left-part .button-send { background: #00BCD4; border-radius: 0px 4px 4px 0px; padding: 19px 32px; font-family: Source Sans Pro; font-style: normal; font-weight: bold; font-size: 14px; line-height: 18px; text-transform: uppercase; color: #FFFFFF; }
.details .current-details .upcoming-payment .right-part .price { margin-right: 5px; font-family: Source Sans Pro; font-style: normal; font-weight: bold; font-size: 56px; line-height: 70px; text-transform: uppercase; }
.details .current-details .upcoming-payment .right-part .price .currency { font-family: Source Sans Pro; font-style: normal; font-weight: bold; font-size: 16px; line-height: 20px; text-transform: uppercase; }