.seller-upload-photo-dialog .MuiBackdrop-root {
  background-color: rgba(255, 255, 255, 0.25) !important;
  backdrop-filter: blur(25px);
}

.seller-upload-photo-modal {
  width: 905px;
  min-height: 500px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 24px 38px rgba(0, 0, 0, 0.14),
    0px 9px 46px rgba(0, 0, 0, 0.12), 0px 11px 15px rgba(0, 0, 0, 0.2);
  border-radius: 12px;
}

.seller-upload-photo-modal .image-square {
  position: relative;
  padding: 6px;
  border: 1px solid #8c8c8c;
  border-radius: 4px;
}

.seller-upload-photo-dialog .seller-upload-photo-modal .image-square .counter {
  width: 20px;
  height: 20px;
  background-color: #fff;
  color: #000;
  position: absolute;
  border: 1px solid #fff;
  border-radius: 12px;
  text-align: center;
  top: 7px;
  left: 8px;
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
}

[data-rbd-draggable-context-id="0"],
[data-rbd-draggable-context-id="1"],
[data-rbd-draggable-context-id="2"] {
  display: inline-block;
  text-align: center;
  margin: 0 auto;
}

[data-rbd-droppable-context-id="0"],
[data-rbd-droppable-context-id="1"],
[data-rbd-droppable-context-id="2"] {
  text-align: center;
}

.seller-upload-photo-dialog .seller-upload-photo-modal .image-square .delete {
  position: absolute;
  top: 8px;
  right: 8px;
  width: 88px;
  height: 24px;
  border: none;
  background-color: white;
  padding: 4px 12px 4px 12px;
  cursor: pointer;
  z-index: 10000;
  border-radius: 16px;
}

.seller-upload-photo-dialog .seller-upload-photo-modal .image-square .delete span {
  display: flex;
  align-items: baseline;
}

.seller-upload-photo-dialog .seller-upload-photo-modal .image-square .delete span p {
  margin-left: 11px;
  font-family: Source Sans Pro;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.400000006px;
}

.seller-upload-photo-modal .image-container .image-square {
  width: 220px;
  height: 190px;
  /* background-color: rgb(52, 196, 176); */
  margin: 16px;
}

.seller-upload-photo-modal .image-container .image-square>img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  pointer-events: none;
}

.seller-upload-photo-modal .closeIcon {
  position: absolute;
  top: 22.67px;
  right: 22.67px;
  cursor: pointer;
}

.seller-upload-photo-modal .closeIcon>img {
  width: 18.67px;
  height: 18.67px;
}

.seller-upload-photo-modal>img {
  width: 76.67px;
  height: 69px;
}

.seller-upload-photo-modal>h1 {
  font-family: Lora;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px;
  margin-top: 23.05px;
}

.seller-upload-photo-modal>p {
  font-family: Source Sans Pro;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  margin-top: 8px;
  width: 672px;
  text-align: center;
}

.seller-upload-photo-modal .dropZone {
  border: 1px dashed #8c8c8c;
  width: 672px;
  height: 128px;
  margin-top: 9px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.seller-upload-photo-modal .dropZone .upload {
  display: flex;
}

.seller-upload-photo-modal .dropZone .upload p {
  margin-bottom: 0 !important;
}
.seller-upload-photo-modal .dropZone .upload>p {
  font-family: Source Sans Pro;
  font-size: 17px;
  font-weight: 700;
  line-height: 18px;
  color: #0097a7;
  margin-right: 5px;
}

.seller-upload-photo-modal>button {
  margin-top: 24px;
  margin-bottom: 64px;
}

.seller-upload-photo-modal .seller-button-upload-photo {
  background-color: #00bcd4;
  height: 48px;
  font-family: Source Sans Pro;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 18px;
  text-align: left;
  color: white;
  padding: 15px 32px 15px 32px;
  border: none;
  outline: 0;
  border-radius: 4px;
  cursor: pointer;
  text-transform: uppercase;
}

.seller-upload-photo-modal .seller-button-upload-photo:hover {
  background-color: #00bcd4;
}

.seller-upload-photo-modal .drag-gridItem-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 1000;
}

.seller-upload-photo-modal .drag-gridItem-container:hover {
 
  cursor: pointer;
}

@media screen and (max-width: 1000px) {
  .seller-upload-photo-modal{
    width: 100%;
    height: 100%;
    padding: 0 40px;
  }
}


@media screen and (max-width: 767px) {
  .seller-upload-photo-modal > p{
    width: 100% !important;
    text-align: center;
  }
  .seller-upload-photo-modal .dropZone{
    width: 100% !important;
  }
}

@media screen and (max-width: 500px) {

  .grid-container{
    grid-auto-rows: auto !important;
    grid-template-columns: auto !important;
  }
 }

@media screen and (max-width: 467px) {
 .MuiDialog-paper {
    margin: 20px !important
  }

  .seller-upload-photo-modal{
    padding: 0 20px;
  }
}