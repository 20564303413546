.photo-upload-pane { margin-top: 24px; padding: 16px; background: #F4F4F4;; border-radius: 12px; text-align: center; }
/* .photo-upload-pane .image-container { } */
.photo-upload-pane .image-container .title { margin-bottom: 16px; text-align: left; font-family: Lora; font-style: normal; font-weight: bold; font-size: 24px; line-height: 26px; }
.photo-upload-pane .image-container .squares { display: flex; align-items: center; justify-content: center; flex-wrap: wrap; gap: 16px; }
.photo-upload-pane .image-container .squares .image-square { width: 293px; height: 234px; }
.photo-upload-pane .image-container .squares .image-square > img { width: 100%; height: 100%; object-fit: cover; }
  
.photo-upload-pane > img { margin-top: 30px; width: 76.67px; height: 69px; filter: invert(100%) sepia(2%) saturate(2238%) hue-rotate(185deg) brightness(111%) contrast(84%); }
.photo-upload-pane > h1 { top: 82px; font-family: Source Sans Pro; font-style: normal; font-weight: bold; font-size: 20px; line-height: 24px; text-align: center; text-transform: uppercase; color: #8C8C8C; }
.photo-upload-pane .dropZone { width: 100%; padding: 26px 0px 26px 0px; margin-top: 33px; display: flex; justify-content: center; align-items: center; border: 1px dashed #8C8C8C; box-sizing: border-box; border-radius: 8px; }
.photo-upload-pane .dropZone .upload { display: flex; cursor: pointer; }
.photo-upload-pane .dropZone .upload > img { width: 16px; height: 16px; }
.photo-upload-pane .dropZone .upload > p { margin-left: 8px; font-family: Source Sans Pro; font-style: normal; font-weight: bold; font-size: 14px; line-height: 18px; text-transform: uppercase; color: #0097A7; }
