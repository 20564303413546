.check-uploaded-image-modalContainer {
  position: fixed;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.76);
  backdrop-filter: blur(25px);
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  z-index: 500;
  > .top {
    position: relative;
    align-self: center;
    width: 75%;
    height: 65%;
    display: flex;
    align-items: center;
    > .left,
    .right {
      width: 50px;
      height: 50px;
      // background-color: blue;
      cursor: pointer;
      > img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
    > .left {
      > img {
        transform: rotatez(180deg);
      }
    }
    > .middle {
      flex: 1;
      // background-color: rgba(0, 0, 0, 0.644);
      height: 100%;
      margin-left: 50px;
      margin-right: 50px;
      border-radius: 12px;
      > img {
        width: 100%;
        height: 100%;
        object-fit: contain;
        border-radius: 12px;
      }
    }
  }
  > .bottom {
    display: grid;
    // grid-template-columns: auto auto auto;
    grid-gap: 8px;

    width: fit-content;
    max-width: 75%;
    align-self: center;
    margin-top: 22px;
    > div {
      // width: 100px;
      // height: 100px;
      max-width: 90px;
      max-height: 90px;
      cursor: pointer;
      position: relative;
      > img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 4px;
      }
      &:after {
        content: '';
        display: block;
        padding-bottom: 100%;
      }
      > input {
        position: absolute;
        left: 48%;
        bottom: -20px;
      }
    }
    > .selected {
      outline: 3px solid #4dd0e1;
      box-sizing: border-box;
      border-radius: 4px;
    }
  }
  > .actions {
    max-width: 75%;
    align-self: center;
    margin-top: 28px;
    display: flex;
    z-index: 300;
    > button {
      padding: 12px 32px 12px 28px;
      font-family: 'Source Sans Pro', sans-serif;
      font-style: normal;
      font-weight: bold;
      font-size: 14px;
      line-height: 18px;
      text-transform: uppercase;
      color: #ffffff;
      margin: 0 12px;
      border-radius: 4px;
      background-color: transparent;
      display: flex;
      align-items: center;
      cursor: pointer;
      > img {
        margin-right: 6.5px;
      }
    }
    > .filled {
      background: #00bcd4;
      border: 2px solid #00bcd4;
    }
    > .unfilled {
      border: 2px solid #00bcd4;
      box-sizing: border-box;
    }
  }
  > .accepted-actions {
    max-width: 75%;
    align-self: center;
    margin-top: 38px;
    display: flex;
    flex-direction: column;
    z-index: 300;
    > button {
      padding: 12px 32px 12px 28px;
      font-family: 'Source Sans Pro', sans-serif;
      font-style: normal;
      font-weight: bold;
      font-size: 14px;
      line-height: 18px;
      text-transform: uppercase;
      color: #ffffff;
      margin: 0 12px;
      border-radius: 4px;
      background-color: transparent;
      display: flex;
      align-items: center;
      display: flex;
      justify-content: center;
      cursor: pointer;
      > img {
        margin-right: 6.5px;
      }
    }
    > .filled {
      background: #00bcd4;
      border: 2px solid #00bcd4;
    }
    > .unfilled {
      border: 2px solid #00bcd4;
      box-sizing: border-box;
      border: none;
    }
  }
}
