.stop-deposit-seller { margin-top: 47px; }
.stop-deposit-seller .stop-deposit-seller-content { margin-bottom: 32px; }
.stop-deposit-seller .stop-deposit-seller-content .text { margin: 0 120px 32px 120px; font-family: Source Sans Pro; font-style: normal; font-weight: normal; font-size: 18px; line-height: 24px; text-align: center; color: #000000; }

.stop-deposit-seller .content-primary { margin: 0 auto 64px auto; padding: 40px 37px; background: #F8F8F8; border-radius: 8px; text-align: center; }
.stop-deposit-seller .content .title { margin-bottom: 8px; font-family: Lora; font-style: normal; font-weight: bold; font-size: 32px; line-height: 32px; text-align: center; color: #000000; }
.stop-deposit-seller .content .title-sub { margin-bottom: 24px; font-family: Source Sans Pro; font-style: normal; font-weight: normal; font-size: 18px; line-height: 24px; text-align: center; color: #000000; }
.stop-deposit-seller .content .add-payment-method { padding: 15px 32px; background: #00BCD4; border-radius: 4px; font-family: Source Sans Pro; font-style: normal; font-weight: bold; font-size: 14px; line-height: 18px; text-transform: uppercase; color: #FFFFFF; }


.stop-deposit-seller .advance-to-the-preliminary-contract { margin-bottom: 30px; }
.stop-deposit-seller .advance-to-the-preliminary-contract .title { margin-bottom: 8px; font-family: Lora; font-style: normal; font-weight: bold; font-size: 32px; line-height: 32px; text-align: center; color: #000000; }
.stop-deposit-seller .advance-to-the-preliminary-contract .title-sub { margin: 0 130px 26px 130px; font-family: Source Sans Pro; font-style: normal; font-weight: normal; font-size: 18px; line-height: 24px; text-align: center; color: #000000; }

.stop-deposit-seller .deposit-content .text { margin: 0 120px 32px 120px; font-family: Source Sans Pro; font-style: normal; font-weight: normal; font-size: 18px; line-height: 24px; text-align: center; color: #000000; }
.stop-deposit-seller .deposit-content .content { margin: 0 auto 64px auto; padding: 40px; border-radius: 8px; text-align: center; }
.stop-deposit-seller .deposit-content .content .title { margin-bottom: 16px; font-family: Lora; font-style: normal; font-weight: bold; font-size: 32px; line-height: 32px; text-align: center; color: #000000; }
.stop-deposit-seller .deposit-content .content .title-primary { margin-bottom: 8px; font-family: Lora; font-style: normal; font-weight: bold; font-size: 32px; line-height: 32px; text-align: center; color: #000000; }
.stop-deposit-seller .deposit-content .content .congratulation { margin-bottom: 24px; font-family: Source Sans Pro; font-style: normal; font-weight: normal; font-size: 18px; line-height: 24px; text-align: center; color: #000000; }
.stop-deposit-seller .deposit-content .content .image-primary { margin-bottom: 13px; }
.stop-deposit-seller .deposit-content .content .price { margin: 0 auto; width: 131px; padding: 5px 8px; font-family: Source Sans Pro; font-style: normal; font-weight: bold; font-size: 36px; line-height: 45px; text-transform: uppercase; background: #E0F7FA; border-radius: 4px; color: #000000; }
.stop-deposit-seller .deposit-content .content .price span { font-family: Source Sans Pro; font-style: normal; font-weight: bold; font-size: 16px; line-height: 20px; text-transform: uppercase; color: #000000; }

.stop-deposit-seller .content-primary { margin: 0px auto 64px auto; padding: 32px 62px; background: #F8F8F8; border-radius: 8px; text-align: center; }
.stop-deposit-seller .content-primary .title { margin-bottom: 8px; font-family: Lora; font-style: normal; font-weight: bold; font-size: 32px; line-height: 32px; text-align: center; color: #000000; }
.stop-deposit-seller .content-primary .title-sub { margin-bottom: 24px; font-family: Source Sans Pro; font-style: normal; font-weight: normal; font-size: 18px; line-height: 24px; text-align: center; color: #000000; }
.stop-deposit-seller .content-primary .hire-lawyer-button { padding: 15px 32px; background: #00BCD4; border-radius: 4px; font-family: Source Sans Pro; font-style: normal; font-weight: bold; font-size: 14px; line-height: 18px; text-transform: uppercase; color: #FFFFFF; }

@media screen and (max-width: 432px) {
    .stop-deposit-seller .content-primary {
        padding: 20px;
    }

    .stop-deposit-seller .content-primary .title-sub {
        text-align: justify;
    }
}