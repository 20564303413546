.my-adds-3d-uploader {
  display: flex;
  width: 100%;
  min-height: 204px;

  >.no-uploads {
    width: 100%;

    >.dropZone {
      width: 100%;
      height: 100%;
      border: 1px dashed #8c8c8c;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      border-radius: 8px;
      background-image: url("https://d3kuhob3u5bpy2.cloudfront.net/b0cc47b4-ab0c-44fa-8055-7e6644a15dc8.svg");
      background-repeat: no-repeat;
      background-position: center;
      background-size: 50% 70%;
      position: relative;

      >.upload-area {
        display: flex;
        width: 100%;
        height: 100%;
        background-color: #f8f8f8f3;
        justify-content: center;
        align-items: center;
        border-radius: 100%;
        position: absolute;
        top: 0;
        left: 0;
        >span {
          font-family: Source Sans Pro;
          font-size: 14px;
          font-style: normal;
          font-weight: 700;
          line-height: 18px;
          letter-spacing: 0em;
          text-align: left;
          color: #0097a7;
          text-transform: uppercase;
          margin-right: 8px;
        }
      }
    }

    >.virtual-tour-preview-container {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      position: relative;

      >.virtual-tour-preview-image {
        height: 300px;
        width: 100%;
        border-radius: 12px;
        filter: blur(1px);
      }

      >.threeDIcon {
        background: white;
        padding: 50px;
        position: absolute;
        border-radius: 50%;
        transform: scale(0.4);
        cursor: pointer;
        border: 20px solid #49BDE0;
      }
    }
  }

  >.uploads-container {
    display: grid;
    grid-template-columns: auto auto auto;
    grid-gap: 8px;
    // display: flex;
    justify-content: left;
    width: 100%;
    overflow: hidden;

    >.video-container {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 4px;
      position: relative;
      height: 448px;

      >.delete {
        position: absolute;
        top: 8px;
        right: 8px;
        width: 88px;
        height: 24px;
        border: none;
        background-color: white;
        padding: 4px 12px 4px 12px;
        cursor: pointer;
        border-radius: 16px;

        >span {
          display: flex;
          justify-content:center;
          align-items:center;

          >span {
            margin-left: 11px;
            font-family: Source Sans Pro;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 16px;
            letter-spacing: 0.4000000059604645px;
          }
        }
      }

      >video {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      >.dropZone {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        border-radius: 8px;
        border: 1px dashed #8c8c8c;
        background-image: url("https://d3kuhob3u5bpy2.cloudfront.net/0d0eb11a-5090-4ce5-b6b1-8d06954ec710.svg");
        background-repeat: no-repeat;
        background-position: center;
        background-size: 50%;

        >.upload-area {
          display: flex;
          width: 100%;
          height: 100%;
          background-color: #f8f8f8dc;
          justify-content: center;
          align-items: center;
          border-radius: 100%;

          >p {
            font-family: Source Sans Pro;
            font-size: 14px;
            font-style: normal;
            font-weight: 700;
            line-height: 18px;
            letter-spacing: 0em;
            text-align: left;
            color: #0097a7;
            text-transform: uppercase;
            margin-right: 8px;
          }
        }
      }
    }

    // > .image-container:after {
    //   content: '';
    //   display: block;
    //   padding-bottom: 100%;
    // }
  }
}