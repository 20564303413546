
.swiper {
  width: 100%;
  height: 100%;
  padding: 5px;
}

.tab-slide {
  text-align: center;
  font-size: 16px;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  
}

.tab-slide img {
  display: block;
  width: 50%;
  height: 50%;
  object-fit: cover !important;
}