.dashboard .dashboard-header { text-align: center; }
.dashboard .dashboard-header .title { margin: 64px 0px 32px 0px; font-family: Lora; font-style: normal; font-weight: 500; font-size: 72px; line-height: 76px; }

.dashboard .root {  flex-grow: 1; background-color: #F8F8F8; border-radius: 12px 12px 0px 0px; margin-bottom: 128px; width: 100%; }
.dashboard .root .tabs, .dashboard .app-bar { border-radius: 12px 12px 0px 0px; background: #E8E8E8; box-shadow: none; }
.dashboard .root .tabs .tab { padding: 16px 24px; font-family: Source Sans Pro; font-style: normal; font-weight: bold; font-size: 14px; line-height: 18px; text-transform: uppercase; color: #000000; }
.dashboard .root .tabs .tab.active-tab { background: #F8F8F8; cursor: default; pointer-events: none; padding: 16px 24px; }

.dashboard .root .tabs .buyer .number, .dashboard .root .tabs .seller .number, .dashboard .root .tabs .inquiry .number { padding: 2px 5px; margin-top: -7px; float: right; background-color: #00838F; border-radius: 50%; font-family: Source Sans Pro; font-style: normal; font-weight: bold; font-size: 12px; line-height: 15px; text-align: center; color: #FFFFFF; }

.dashboard .root .tab-panel-body { background: #F8F8F8; border-radius: 0px; }

.dialog-communication-lawyer-buyer-cancel { margin: 0 10px; }
.dialog-communication-lawyer-buyer-cancel-content { padding: 22px; text-align: center; }
.dialog-communication-lawyer-buyer-cancel-content .title-header { padding: 0; margin-bottom: 16px; }
.dialog-communication-lawyer-buyer-cancel-content .title-header .title { font-family: Lora; font-style: normal; font-weight: bold; font-size: 32px; line-height: 32px; margin-top: 22px; padding: 0px 140px; }
.dialog-communication-lawyer-buyer-cancel-content .title-header .close-icon { cursor: pointer; float: right; margin-top: -24px; }
.dialog-communication-lawyer-buyer-cancel-content .content { padding: 0; overflow-x: hidden; }
.dialog-communication-lawyer-buyer-cancel-content .content .actions { margin-top: 24px; }
.dialog-communication-lawyer-buyer-cancel-content .content .actions .button-continue { margin-right: 24px; padding: 15px 32px; background: #F44336; border-radius: 4px; font-family: Source Sans Pro; font-style: normal; font-weight: bold; font-size: 14px; line-height: 18px; text-transform: uppercase; color: #FFFFFF; }
.dialog-communication-lawyer-buyer-cancel-content .content .actions .button-back { padding: 15px 32px; border: 2px solid #00BCD4; box-sizing: border-box; border-radius: 4px; font-family: Source Sans Pro; font-style: normal; font-weight: bold; font-size: 14px; line-height: 18px; text-transform: uppercase; color: #000000; }
.dialog-communication-lawyer-buyer-cancel-content .button-send { height: 45px; padding: 15px 32px; background: #00BCD4; border-radius: 4px; color: #fff; text-transform: uppercase; margin-right: 25px; font-size: 14px; line-height: 18px; font-family: Source Sans Pro; font-weight: 700; }
.dialog-communication-lawyer-buyer-cancel-content .button-send:hover { background: #00BCD4; }

@media screen and (max-width: 500px) { 
    .dashboard .dashboard-header .title {
        font-size: 48px;
        line-height: 52px;
    }

    .dialog-communication-lawyer-buyer-cancel-content .title-header .title { padding: 0; }
}