.social-login-button {
  background-color: #00bcd4;
  padding: 10px;
  border-style: none;
  color: white;
  border-radius: 4px;
}

.btn-fb {
  display: flex;
  align-items: center;
  width: 100%;
  font-size: 25px;
  font-family: Poppins;
  font-weight: 700;
  color: #00bcd4;
  background-color: white;
  border: 1.5px solid black;
  border-radius: 6px;
  margin-top: 5px;
  padding: 15px 32px;
}

.btn-fb:hover {
  box-shadow: 0 0 3px 3px rgba(59, 89, 152, 0.3);
}

.btn-fb .logo-container .facebook-icon {
  width: 25px;
  height: 25px;
  margin-right: 8px;
}

.btn-fb .logo-container {
  display: flex;
  margin-left: 38%;
  align-items: center;
  flex-direction: row;
}

.btn-google {
  display: flex;
  margin-top: 10px;
  align-items: center;
  width: 100%;
  font-size: 25px;
  font-family: Poppins;
  font-weight: 700;
  color: #00bcd4;
  background-color: white;
  border: 1.5px solid black;
  border-radius: 6px;
  padding: 15px 32px;
}

.btn-google:hover {
  box-shadow: 0 0 3px 3px rgba(59, 89, 152, 0.3);
}

.btn-google .logo-container .google-icon {
  width: 25px;
  height: 25px;
  margin-right: 8px;
}

.btn-google .logo-container {
  display: flex;
  margin-left: 38%;
  align-items: center;
  flex-direction: row;
}

/* LINKEDIN BUTTON */

.Linkedinbutton {
  border: none;
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 240px;
  height: 50px;
  background-color: #0b78b7;
  color: white;
  border-radius: 2px;
  box-shadow: 0 0 1px 0 rgba(0, 0, 0, 0.12);
}

.Linkedinbutton:hover {
  background-color: #0469a0;
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.24);
}

.Linkedinbutton:active {
  background-color: #066093;
}

@media screen and (max-width: 560px) {
  .btn-google .logo-container .google-icon {
    margin-right: 5px;
  }

  .btn-google .logo-container {
    margin: 0 auto;
  }
  .btn-fb {
    margin-top: 10px;
  }
  .btn-fb .logo-container .facebook-icon {
    margin-right: 5px;
  }

  .btn-fb .logo-container {
    margin-left: 35%;
  }
}
