.education {
    padding: 74px 150px 128px 150px;
    text-align: center;
   
}

.education .title {
    margin-bottom: 8px;
    font-family: Lora;
    font-size: 48px;
    line-height: 52px;
}

.education .title-sub {
    margin-bottom: 42px;
    padding: 0 150px;
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 24px;
}

.education .video {
    height: 578px;
    margin-bottom: 24px;
    background-color: #F8F8F8;
    border-radius: 4px;
    position: relative;
    justify-content: center;
    align-items: center;
    display: flex;
}

.education .video .video-play {
    width: 100%;
    height: 100%;
}

.education .button-next {
    cursor: pointer;
    padding: 15px 32px;
    background: #00BCD4;
    border-radius: 4px;
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 18px;
    text-transform: uppercase;
    color: #FFFFFF;
}

.video-play-button{
   cursor: pointer;
}
.video-play-button-container{
    position: absolute;
    top: 250px;
    z-index: 1;
}

.video-options{
    display: flex;
    justify-content: center;
    align-items: center;
    gap:40px

}
.video-option-button{
    cursor: pointer;
    padding: 14px 32px;
    background: #00BCD4;
    border-radius: 4px;
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    line-height: 18px;
    text-transform: uppercase;
    color: #FFFFFF;
    border-style: none;
}

.education .video .video-loader {
    background-color: black;
    z-index: 999999;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 578px;
    width: 100%;
    position: absolute;
    background-size: cover;
    background-position: center;
    background-position: 0%;
    background-image: url("https://d3kuhob3u5bpy2.cloudfront.net/700836e5-fa87-4a0b-be1e-fc3f9028c084.png");
  }
  .education .video .video-loader .circular-loader{
    position: absolute;
    top: -6.5px;
    left: -6.5px;
    z-index: 300;
    cursor: pointer;
  }

@media screen and (max-width:1321px){
    .education .video {
        height: 100%;
        margin-bottom: 24px;
        background-color: #F8F8F8;
        border-radius: 4px;
        position: relative;
        justify-content: center;
        align-items: center;
        display: flex;
    }

     .video-play-button-container{
         position: absolute;
         top: 45%;
         left: 45%;
         z-index: 1;
     }
     .education .video .video-loader {
        height: 100%;
      }
}

@media screen and (max-width:1055px){
    .education .title-sub {
        margin-bottom: 42px;
        padding: 0px;
        font-family: Source Sans Pro;
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        line-height: 24px;
    }
}

@media screen and (max-width:768px){
    .education {
        padding: 74px 50px 128px 50px;
        text-align: center;
       
    }
    .video-play-button-container{
        position: absolute;
        top: 35%;
        left: 45%;
        z-index: 1;
    }
}

@media screen and (max-width:605px){
    .education {
        padding: 74px 25px 128px 25px;
        text-align: center;
       
    }
}

@media screen and (max-width: 527px) {
    .education .video .video-loader .circular-loader{
      top: -4.4px;
      left: -4.5px;
    }
  }

@media screen and (max-width:500px){
    .video-play-button{
       width: 50px;
       height: 50px;
     }
     .education .video .video-loader .circular-loader{
        top: -3.5px;
        left: -3.5px;
      }
}

@media screen and (max-width:500px){
    .video-play-button{
       width: 40px;
       height: 40px;
     }
}