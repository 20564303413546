.lang-switcher {
    height: 100%;
}

.lang-switcher select {
    width: 100%;
    height: 100%;
    border: none;
    cursor: pointer;
    outline: none;
    padding: 0 10px;
    font-size: 12px;
    font-weight: 500;
    border-radius: 5px;
    background-color:rgb(237, 237, 237);
}

@media only screen and (max-width: 1000px) {
    .lang-switcher {
        margin: 0 20px;
    }
}

@media only screen and (max-width: 765px) {
    .lang-switcher {
        margin: 0;
        margin-right: 5px;
        width: 40px;
        margin-top: -6px;
    }
    .lang-switcher select{
        height: 39px;
    }
}