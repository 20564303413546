.request-meeting-dialog { text-align: center; }
.request-meeting-dialog .photographer-request-meeting-dialog-content { padding: 64px 84px; }
.request-meeting-dialog .photographer-request-meeting-dialog-content .title-header { padding: 0; margin-bottom: 8px; }
.request-meeting-dialog .photographer-request-meeting-dialog-content .title-header .title { display: inline-block; font-size: 48px; line-height: 52px; font-family: Lora; font-weight: 700; }
.request-meeting-dialog .photographer-request-meeting-dialog-content .title-header .close-icon { position: absolute; top: 20px; right: 20px; cursor: pointer; }

.request-meeting-dialog .photographer-request-meeting-dialog-content .request-meeting-content { padding: 0; overflow-x: hidden; }
.request-meeting-dialog .photographer-request-meeting-dialog-content .request-meeting-content .sub-title { font-size: 18px; line-height: 24px; font-weight: 400; margin-bottom: 24px; font-family: Source Sans Pro; padding: 0 40px; }

.request-meeting-dialog .photographer-request-meeting-dialog-content .request-meeting-content .date-hour-picker-boxes { padding: 0 180px; }

.request-meeting-dialog .photographer-request-meeting-dialog-content .request-meeting-content .date-hour-picker-boxes .date-picker-box,
.request-meeting-dialog .photographer-request-meeting-dialog-content .request-meeting-content .date-hour-picker-boxes .hour-picker-box { margin-bottom: 24px; background: #FFFFFF; border-radius: 10px; box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.14), 0px 1px 18px rgba(0, 0, 0, 0.12), 0px 3px 5px rgba(0, 0, 0, 0.2); }
.request-meeting-dialog .photographer-request-meeting-dialog-content .request-meeting-content .date-hour-picker-boxes .date-picker-box { padding: 16px 54px; }
.request-meeting-dialog .photographer-request-meeting-dialog-content .request-meeting-content .date-hour-picker-boxes .hour-picker-box { padding: 16px 44px; }

.request-meeting-dialog .photographer-request-meeting-dialog-content .request-meeting-content .date-hour-picker-boxes .date-picker-box .title,
.request-meeting-dialog .photographer-request-meeting-dialog-content .request-meeting-content .date-hour-picker-boxes .hour-picker-box .title { font-size: 18px; line-height: 22px; font-family: Source Sans Pro; font-weight: 900; text-transform: uppercase; margin-bottom: 8px; }

.request-meeting-dialog .photographer-request-meeting-dialog-content .request-meeting-content .date-hour-picker-boxes .date-picker-box .MuiPickersDay-day { background: #F2F5F8; color: #000; margin-bottom: 6px; }
.request-meeting-dialog .photographer-request-meeting-dialog-content .request-meeting-content .date-hour-picker-boxes .date-picker-box .MuiPickersDay-current { background: #E0F7FA; color: #0097A7; }
.request-meeting-dialog .photographer-request-meeting-dialog-content .request-meeting-content .date-hour-picker-boxes .date-picker-box .MuiPickersDay-daySelected { background: #80DEEA; color: #000; }
.request-meeting-dialog .photographer-request-meeting-dialog-content .request-meeting-content .date-hour-picker-boxes .date-picker-box .MuiPickersCalendarHeader-transitionContainer p::first-letter { text-transform: uppercase; }

.request-meeting-dialog .photographer-request-meeting-dialog-content .request-meeting-content .date-hour-picker-boxes .hour-picker-box .hours-list .chip { padding: 4px 12px; float: left; background: #F8F8F8; border-radius: 16px; color: #000000; opacity: 0.5; font-size: 16px; line-height: 18px; font-weight: 400; font-family: Source Sans Pro; margin-right: 8px; margin-bottom: 8px; border: 1px solid #F8F8F8; box-sizing: border-box; cursor: pointer; }
.request-meeting-dialog .photographer-request-meeting-dialog-content .request-meeting-content .date-hour-picker-boxes .hour-picker-box .hours-list .chip.disabled { background: #FFFFFF; opacity: 0.4; cursor: not-allowed; }
.request-meeting-dialog .photographer-request-meeting-dialog-content .request-meeting-content .date-hour-picker-boxes .hour-picker-box .hours-list .chip.highlight { opacity: 1; background: #E0F7FA; color: #0097A7; }
.request-meeting-dialog .photographer-request-meeting-dialog-content .request-meeting-content .date-hour-picker-boxes .hour-picker-box .hours-list .chip.active { opacity: 1; background: #80DEEA; border: 1px solid #80DEEA; cursor: default; color: #000; }

.request-meeting-dialog .photographer-request-meeting-dialog-content .request-meeting-content .actions { margin-top: 8px; display: flex; justify-content: center; gap: 24px; }
.request-meeting-dialog .photographer-request-meeting-dialog-content .request-meeting-content .actions .button-ask { padding: 15px 32px; background: #00BCD4; border-radius: 4px; color: #fff; text-transform: uppercase; font-size: 14px; line-height: 18px; font-family: Source Sans Pro; font-weight: 700; }
.request-meeting-dialog .photographer-request-meeting-dialog-content .request-meeting-content .actions .button-close { padding: 15px 32px; border: 2px solid #00BCD4; border-radius: 4px; color: #000; text-transform: uppercase; font-size: 14px; line-height: 18px; font-family: Source Sans Pro; font-weight: 700;  }
