.seller-virtual-assistant-profile {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 32px 0px;
  padding-left: 32px;
  width: calc(100% - 64px);
}

@media screen and (max-width:400px){

  .seller-virtual-assistant-profile {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding:  0px 32px;
    width:100%;
  }
}


