.registration-about {
    padding: 60px 0 40px 0;
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 100%;
}

.registration-about .abidors-logo {
    margin-bottom: 50px;
    margin-top: 0px;
    width: 260px;
    cursor: pointer;
}

.registration-about .title {
    font-size: 40px;
    font-weight: 700;
    line-height: 40px;
    text-align: center;
}

.registration-about .title-sub {
    font-size: 35px;
    font-weight: 400;
    line-height: 40px;
    text-align: center;
    margin-bottom: 50px;
}

.registration-about .buyer-seller-registration-section {
    margin: 0 70px 50px 70px;
    text-align: center;
}

.registration-about .buyer-seller-registration-section .col {
    margin: 0 auto;
}

.registration-about .buyer-seller-registration-section .col.col6of12 {
    width: 49%;
    box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.15);
    background: rgba(255, 255, 255, 1);
    border-radius: 30px;
    padding: 25px;
    height: 700px;
    position: relative;
    align-items: center;
    display: flex;
    flex-direction: column;
}

.registration-about .buyer-seller-registration-section .col.col6of12:nth-last-child(1) {
    float: right;
}

.registration-about .buyer-seller-registration-section img {
    margin-bottom: 30px;
    width: 65%;
}

.registration-about .buyer-seller-registration-section img.custom-width {
    margin-bottom: 30px;
    width: 66.1%;
}

.registration-about .buyer-seller-registration-section .content .title-content {
    font-size: 35px;
    font-weight: 700;
    line-height: 40px;
    text-align: center;
}

.registration-about .buyer-seller-registration-section .content .title-sub-content {
    font-size: 20px;
    font-weight: 400;
    line-height: 30px;
    text-align: center;
    margin-bottom: 20px;
}

.registration-about .buyer-seller-registration-section .content .registration-button {
    padding: 10px 40px;
    text-transform: none;
    margin-bottom: 10px;
    background: rgba(0, 188, 212, 1);
    font-size: 18px;
    font-weight: 700;
    text-align: center;
    display: inline-block;
    border-radius: 5px;
    color: #fff
}

.registration-about .buyer-seller-registration-section .content .you-have-already-account {
    font-size: 15px;
    font-weight: 400;
    line-height: 40px;
    text-align: center;
    text-transform: uppercase;
    margin-bottom: 0;
}

.registration-about .buyer-seller-registration-section .content .login-text {
    font-size: 20px;
    font-weight: 700;
    line-height: 40px;
    text-align: center;
    color: rgba(0, 188, 212, 1);
}

.registration-about .join-abidors-section-third-part-persona {
    margin-bottom: 40px;
    padding: 0 100px;
}

.registration-about .join-abidors-section-third-part-persona .title {
    font-size: 30px;
    font-weight: 700;
    line-height: 35px;
    text-align: center;
    margin-bottom: 20px;
}

.registration-about .join-abidors-section-third-part-persona .text {
    font-size: 20px;
    font-weight: 400;
    line-height: 35px;
    text-align: justify;
    margin-bottom: 20px;
}

.registration-about .join-abidors-section-third-part-persona .content {
    padding-left: 15px;
}

.registration-about .join-abidors-section-third-part-persona .content .item-content {
    margin-bottom: 20px;
}

.registration-about .join-abidors-section-third-part-persona .content .item-content img {
    float: left;
    width: 30px;
    height: 30px;
    margin-right: 10px;
}

.registration-about .join-abidors-section-third-part-persona .content .item-content p {
    float: left;
    font-size: 18px;
    font-weight: 400;
    text-align: justify;
    margin-bottom: 5px;
}

.registration-about .third-part-persona-container {
    margin: 0 100px;
}

.registration-about .third-part-persona-container .col {
    box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.15);
    padding: 20px;
    margin: 0 auto;
    border-radius: 30px; 
    width: 32%;
    position: relative;
    height: 650px;
}

.registration-about .third-part-persona-container .col .content img {
    width: 55%;
    margin-bottom: 20px;
}

.registration-about .third-part-persona-container .col .content .title {
    font-size: 25px;
    font-weight: 700;
    line-height: 40px;
    text-align: center;
    margin-bottom: 20px;
}

.registration-about .third-part-persona-container .col .content .sub-title {
    font-size: 20px;
    font-weight: 400;
    line-height: 30px;
    text-align: center;
    margin-bottom: 20px;
}

.registration-about .third-part-persona-container .col .content {
    height: 100%;
    position: relative;
}

.registration-about .third-part-persona-container .col .content .registration-button {
    padding: 10px 40px;
    text-transform: none;
    margin-bottom: 10px;
    background: rgba(0, 188, 212, 1);
    font-size: 18px;
    font-weight: 700;
    text-align: center;
    display: inline-block;
    border-radius: 5px;
    color: #fff;
}

.registration-about .third-part-persona-container .col .content .you-have-already-account {
    font-size: 15px;
    font-weight: 400;
    line-height: 40px;
    text-align: center;
    text-transform: uppercase;
    margin-bottom: 0;
}

.registration-about .third-part-persona-container .col .content .login-text {
    font-size: 20px;
    font-weight: 700;
    line-height: 40px;
    text-align: center;
    color: rgba(0, 188, 212, 1);
    margin-bottom: 0;
}

.registration-about .third-part-persona-container .content .content-bottom {
    position: absolute;
    top: 85%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
}

.registration-about .buyer-seller-registration-section .content .content-bottom {
    position: absolute;
    top: 85%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
}

.select-persona-modal { 
    text-align: center;
    margin: 0 10px;
}

.select-persona-modal .select-persona-modal-content {
    padding: 10px 0;
}

.select-persona-modal .select-persona-modal-content .title-header .primary-image {
    width: 70px;
    height: 70px;
    margin-bottom: 10px;
}

.select-persona-modal .select-persona-modal-content .title-header .title {
    font-size: 30px;
    font-weight: 600;
    line-height: 40px;
    color: rgba(0, 0, 0, 1);
    margin-bottom: 10px;
}

.select-persona-modal .select-persona-modal-content .title-header .title-sub {
    font-size: 25px;
    font-weight: 400;
    line-height: 40px;
    color: rgba(0, 0, 0, 1);
    margin-bottom: 10px;
}


.select-persona-modal .select-persona-modal-content .title-header .close-icon { 
    position: absolute; top: 20px; right: 20px; cursor: pointer; 
}

.select-persona-modal .select-persona-modal-content .title-bottom {
    font-size: 20px;
    font-weight: 400;
    line-height: 40px;
    color: rgba(0, 0, 0, 1);
}

.select-persona-modal .persona-select {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    margin-bottom: 30px;
}

.select-persona-modal .persona-select .button {
    border: 1px solid rgba(0, 188, 212, 1);
    padding: 5px 0;
    font-size: 18px;
    font-weight: 400;
    line-height: 30px;
    text-align: center;
    margin: 0 auto 10px auto;
    color: rgba(1, 35, 36, 1);
    width: 90%;
    display: block;
    flex-basis: 30%;
}

.select-persona-modal .persona-select .button:hover {
    border: 1px solid rgba(0, 188, 212, 1);
    color: #ffffff;
    background-color: rgba(0, 188, 212, 1);
}

.registration-container .back-to-search-page {
    align-items: center;
    background: #fff;
    border-radius: 50px;
    box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.18);
    display: flex;
    line-height: 1.4;
    width: 80px;
    position: absolute;
    top: 8px;
    z-index: 1000;
    padding: 0px 6px;
    margin: 0px 4px;
  }
  
  .registration-container .back-to-search-page .prev-btn-search {
    cursor: pointer;
    font-size: 40px;
    color: #00bcd4;
    z-index: 999 !important;
    height: 34px;
  }
  
  .registration-container .back-to-search-page .image-back-search {
    width: 40px;
    height: 40px;
  }

@media screen and (max-width: 1130px) {
    .registration-about .buyer-seller-registration-section .col.col6of12 {
        width: 100%;
        margin-bottom: 20px;
    }

    .registration-about .join-abidors-section-third-part-persona .content .item-content p {
        font-size: 16px;
    }

    .registration-about .buyer-seller-registration-section .content .title-content {
        font-size: 34px;
    }
}

@media screen and (max-width: 984px) {
    .registration-about .third-part-persona-container .col {
        width: 98%;
        margin-bottom: 20px;
    }

    .registration-about .third-part-persona-container .col .content img {
        width: 20%;
    }

    .registration-about .third-part-persona-container .content .content-bottom {
        position: relative;
        top: 0;
        left: 0;
        transform: none;
        width: 100%;
    }

    .registration-about .third-part-persona-container .col {
        height: 100%;
    }
}

@media screen and (max-width: 767px) {
    .registration-about .title-sub {
        font-size: 25px;
        margin-bottom: 20px;
    }

    .registration-about .buyer-seller-registration-section .col.col6of12 {
        width: 100%;
    }

    .registration-about .join-abidors-section-third-part-persona .content .item-content {
        margin-bottom: 10px;
    }

    .registration-about .join-abidors-section-third-part-persona .content .item-content img {
        width: 8%;
        margin-right: 10px;
    }

    .registration-about .join-abidors-section-third-part-persona .content .item-content p {
        width: 85%;
        text-align: left;
        font-size: 25px;
    }

    .registration-about .join-abidors-section-third-part-persona .content {
        padding-left: 10px;   
    }

    .registration-about .join-abidors-section-third-part-persona {
        padding: 0 25px;
    }

    .registration-about .buyer-seller-registration-section {
        margin: 0 25px 50px 25px;
    }
    
    .registration-about .third-part-persona-container {
        margin: 0 30px;   
    }

    .registration-about .third-part-persona-container .col .content img {
        width: 30%;
    }

    .registration-about .third-part-persona-container .col .content .sub-title {
        padding: 0 !important;
    }

    .registration-about .third-part-persona-container .col {
        padding: 20px 10px;
    }

    .registration-about .buyer-seller-registration-section .content .content-bottom {
        position: relative;
        top: 0;
        left: 0;
        transform: none;
        width: 100%;
    }

    .registration-about .buyer-seller-registration-section .col.col6of12 {
        height: 100%;
    }

    .select-persona-modal .persona-select {
        display: block;
    }
}

@media screen and (max-width: 522px) {
    .registration-about .third-part-persona-container .col .content img {
        width: 50%;
    }

    .registration-about .abidors-logo {
        width: 200px;
    }

    .registration-about .title {
        font-size: 35px;
    }

    .registration-about .title-sub {
        font-size: 23px;
    }

    .registration-about .buyer-seller-registration-section {
        margin: 0 15px 30px 15px;
    }

    .registration-about .buyer-seller-registration-section img,
    .registration-about .buyer-seller-registration-section img.custom-width {
        width: 100%;
    }

    .registration-about .buyer-seller-registration-section .content .title-content {
        font-size: 25px;
    }

    .registration-about .join-abidors-section-third-part-persona .title {
        font-size: 25px;
    }

    .registration-about .join-abidors-section-third-part-persona .content .item-content p {
        font-size: 20px;
    }
}