.small-add {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 8px 0;
  width: calc(100% - 24px);
  padding: 12px;
  font-family: 'Source Sans Pro', sans-serif;
  background: #f8f8f8;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px rgba(0, 0, 0, 0.12),
    0px 1px 3px rgba(0, 0, 0, 0.2);
  border-radius: 4px;

  &-left {
    background-color: grey;
    width: 28.88%;
    height: 100%;
    border-radius: 4px;
    position: relative;
    > img {
      height: 100%;
      width: 100%;
    }
    > .image-icons {
      height: 144px;
      width: 144px;
      padding: 8px;
      position: absolute;
      z-index: 100;
      top: 1px;
      border-radius: 4px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-start;
      > button {
        background: #f8f8f8;
        border-radius: 4px;
        border: none;
        padding: 3px 8px;
        font-style: normal;
        font-weight: bold;
        font-size: 14px;
        line-height: 18px;
        text-transform: uppercase;
        color: #000000;
      }
      > .icons {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        width: 100%;
        > img {
          filter: invert(100%);
          height: 18px;
          width: 18px;
          margin: 0 6px;
        }
      }
    }
  }
  &-right {
    // background-color: yellow;
    width: 68.23%;
    height: 176px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    > .right-top {
      margin-bottom: 8px;
      border-bottom: 1px solid;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-start;
      width: 100%;
      > .price {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        width: 100%;
        > h3 {
          font-style: normal;
          font-weight: bold;
          font-size: 36px;
          line-height: 45px;
          text-transform: uppercase;
          color: #000000;
          margin: 0;
          padding: 0;
        }
        > .area {
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: flex-end;

          > .item {
            display: flex;
            align-items: center;
            margin-top: 8px;
            > p {
              padding: 3px 8px;
              background: #ededed;
              border-radius: 4px;
              font-style: normal;
              font-weight: bold;
              font-size: 14px;
              line-height: 18px;
              > sup {
                font-size: 66%;
                line-height: 1;
              }
            }
            > img {
              margin-left: 9px;
            }
          }
        }
      }
      > .specs {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        width: 100%;
        > p {
          display: flex;
          align-items: center;
          font-style: normal;
          font-weight: normal;
          font-size: 18px;
          line-height: 24px;
          margin-right: 9px;
          > sup {
            font-size: 66%;
            line-height: 1;
          }
          > img {
            margin-right: 4px;
          }
        }
      }
      > .location {
        display: flex;
        justify-content: space-between;
        width: 100%;
        align-items: center;
        margin: 10px 0;
        > p {
          display: flex;
          align-items: center;
          font-style: normal;
          font-weight: normal;
          font-size: 18px;
          line-height: 24px;
          > img {
            margin-right: 4px;
          }
        }
      }
    }
    > p {
      font-style: normal;
      font-weight: normal;
      font-size: 18px;
      line-height: 24px;
      color: #000000;
    }
  }
}
