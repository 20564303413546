.seller-upload-video-dialog .seller-button-upload-video {
  background-color: #00bcd4;
  height: 48px;
  font-family: Source Sans Pro;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 18px;
  text-align: left;
  color: white;
  padding: 15px 32px 15px 32px;
  border: none;
  outline: 0;
  border-radius: 4px;
  cursor: pointer;
  text-transform: uppercase;
}

.seller-upload-video-dialog .seller-button-upload-video:hover {
  background-color: #00bcd4;
}

.seller-upload-video-dialog .MuiBackdrop-root {
  background-color: rgba(255, 255, 255, 0.25) !important;
  backdrop-filter: blur(25px);
}

.seller-upload-video-modal {
  width: 905px;
  min-height: 500px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 24px 38px rgba(0, 0, 0, 0.14),
    0px 9px 46px rgba(0, 0, 0, 0.12), 0px 11px 15px rgba(0, 0, 0, 0.2);
  border-radius: 12px;
}

.seller-upload-video-modal .closeIcon {
  position: absolute;
  top: 22.67px;
  right: 22.67px;
  cursor: pointer;
}

.seller-upload-video-modal .videoContainer {
  margin-top: 32px;
  width: 672px;
  height: 446px;
  margin-bottom: 24px;
}

.seller-upload-video-modal>button {
  margin-bottom: 64px;
}

.seller-upload-video-modal .closeIcon>img {
  width: 18.67px;
  height: 18.67px;
}

.seller-upload-video-modal>img {
  width: 76.67px;
  height: 69px;
}

.seller-upload-video-modal>h1 {
  font-family: Lora;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px;
  margin-top: 23.05px;
}

.seller-upload-video-modal>p {
  font-family: Source Sans Pro;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  margin-top: 8px;
  width: 672px;
  text-align: center;
}

.seller-upload-video-modal>span {
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  letter-spacing: 0.4px;
  margin-top: 8px;
}

.seller-upload-video-modal .dropZone {
  border: 1px dashed #8c8c8c;
  width: 672px;
  height: 128px;
  margin-top: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.seller-upload-video-modal .dropZone .upload {
  display: flex;
}
.seller-upload-video-modal .dropZone .upload p {
  margin-bottom: 0 !important;
}
.seller-upload-video-modal .dropZone .upload>p {
  font-family: Source Sans Pro;
  font-size: 17px;
  font-weight: 700;
  line-height: 18px;
  color: #0097a7;
  margin-right: 5px;
}

.seller-upload-video-modal .image-square .delete {
  position: absolute;
  /* top: 75px; */
  top: 30px;
  /* right: 125px; */
  right: 0;
  width: 88px;
  height: 24px;
  border: none;
  background-color: white;
  padding: 4px 12px 4px 12px;
  cursor: pointer;
  border-radius: 16px;
  z-index: 1000;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.seller-upload-video-modal .image-square .delete span {
  display: flex;
}

.seller-upload-video-modal .image-square .delete span p {
  margin-left: 11px;
  font-family: Source Sans Pro;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.400000006px;
}

.image-square-video-upload {
  height: 100% !important;
  width: 100% !important;
}
.seller-upload-video-modal .image-square .delete{
  display: flex;
  align-items: center;
}
.seller-upload-video-modal .image-square .delete span p{
  margin-bottom: 0 !important;
}

@media screen and (max-width: 1000px) {
  .seller-upload-video-modal{
    width: 100%;
    padding: 0 40px;
  }

  .seller-upload-video-modal .image-square .delete {
    right: 20px;
  }
  .seller-upload-video-modal .dropZone .upload > p {
    margin-bottom: 0 !important;
  }
}


@media screen and (max-width: 767px) {
  .seller-upload-video-modal .videoContainer {
    height: auto !important;
  }
  .seller-upload-video-modal .image-square .delete{
    right: 20px;
    top: 0;
  }
  .seller-upload-video-modal .dropZone {
    width: 100% !important;
  }
  .seller-upload-video-modal > p {
    width: 100% !important;
  }
}

@media screen and (max-width: 460px) {

  .seller-upload-video-modal{
    padding: 0 20px;
  }
}
