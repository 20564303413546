.header-without-login {
    width: 100%;
    position: sticky;
    top: 0;
    z-index: 990;
    background: #ffffff;
}

.header-without-login .navbar {
    padding: 14px 32px !important;
    position: relative;
    z-index: 990;
}

.header-without-login .navbar .nav-item {
    margin: 0 5px;
}

.header-without-login .navbar .nav-item .nav-links {
    padding: 10px 15px;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    color: #000000;
    cursor: pointer;
    border-radius: 30px;
}

.header-without-login .navbar .nav-item .nav-links.active {
    background-color: rgba(0, 188, 212, 1);
    color: #ffffff;
}

.header-without-login .navbar .nav-menu {
    float: left;
    display: flex;
    margin-top: 12px;
    padding-left: 0;
    list-style: none;
}

.header-without-login .navbar-logo {
    float: left;
    margin-top: 10px;
    margin-right: 32px;
}

.header-without-login .search {
    float: left;
    position: relative;
    margin-top: 4px;
    background-color: rgba(244, 244, 244, 1);
    border-radius: 50px;
    width: 300px;
    height: 44px;
    margin-left: 24px;
    cursor: pointer;
    padding: 5px 16px;
}

body.manrope-class .header-without-login .search {
    width: 240px;
}

.header-without-login .search .autocomplete-dropdown-container-home {
    margin-top: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    width: 100%;
    display: flex;
    flex-direction: column;
}

.header-without-login .search .search-icon {
    float: left;
    margin-top: 4px;
    width: 26px;
    height: 26px;
    filter: invert(49%) sepia(0%) saturate(1%) hue-rotate(100deg) brightness(97%) contrast(89%);
}

.header-without-login .search-field {
    float: left;
    padding: 16px 10px !important;
    height: 28px;
    border: none;
    background-color: transparent;
    width: 80% !important;
    margin-bottom: 10px;
}

.header-without-login .search-field::placeholder {
    font-weight: 400;
    font-size: 18px;
}

.header-without-login .search.focus-input-filed {
    outline: 2px solid #00BCD4;
}

.header-without-login .search .MuiFormControl-marginNormal {
    margin: 0
}

.header-without-login .search .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] {
    padding: 0;
    background-color: #EDEDED;
}

/* right part header-without-login */
.header-without-login .right-part {
    float: right;
}

.header-without-login .right-part .add-property {
    float: left;
    margin-top: 16px;
    margin-right: 20px;
}

.header-without-login .right-part .add-property img {
    float: left;
    margin-right: 8px;
    width: 26px;
    height: 26px;
    margin-top: -5px;
}

.header-without-login .right-part .add-property .add-text {
    float: right;
    line-height: 18px;
    text-transform: uppercase;
    color: #0097A7;
    margin-top: -0px;
    font-size: 16px;
    font-weight: 700;
    text-align: center;
}

.header-without-login .right-part .add-property .add-text:hover {
    color: rgba(0, 188, 212, 0.8);
}

/* login button */
.header-without-login .login-button {
    float: left;
    padding: 15px 32px;
    margin-right: 30px;
    text-transform: uppercase;
    color: #000000;
    background: #FFFFFF;
    border-radius: 50px;
    font-size: 16px;
    font-weight: 700;
    line-height: 18px;
    text-align: center;
    text-transform: none;
    outline: 2px solid #00BCD4;
}

.header-without-login .login-button:hover {
    outline: 3.5px solid #00BCD4;
}

.header-without-login .search:hover {
    background: rgba(234, 234, 234, 0.7);
}

/* registration button */
.header-without-login .registration-button {
    float: left;
    padding: 15px 32px;
    font-size: 16px;
    font-weight: 700;
    line-height: 18px;
    text-transform: uppercase;
    color: #000000;
    background: #00BCD4;
    border-radius: 50px;
    cursor: pointer;
    text-transform: none;
}

.header-without-login .registration-button:hover {
    background: rgba(0, 188, 212, 0.8);
}

/* menu burger */
.header-without-login .right-part .menu-burger {
    display: none;
}

.header-without-login .right-part .menu-burger:hover {
    cursor: pointer;
}

/* SideBar Popup modal */
.backdrop {
    position: absolute;
    width: 100%;
    height: 100vh;
    top: 0;
    left: 0;
    background: #0000008a;
}

.side-menu {
    position: absolute;
    top: 2px;
    right: 2px;
    width: 25%;
    height: 100vh;
    background: #ffffff;
    z-index: 999;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 3px 3px 0px;
}

.modal-header {
    background: #00BCD4;
    height: 40px;
    display: flex;
    display: flex;
    flex-direction: row-reverse;
}

.closebutton {
    font-size: 25px;
    cursor: pointer;
    margin-right: 10px;
    color: #fff;
}

.modal-body {
    display: flex;
    flex-direction: column;
    margin: 20px 10px 10px 30px;
    gap: 20px
}

.add-property-modal {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 5px
}

.modal-body .modal-link {
    color: #000000;
    font-size: 16px;
    line-height: 24px;
    text-align: left;

}

.modal-body .add-property-modal {
    color: #00BCD4 !important;
}

.modal-body .login-modal {
    border: 1px solid #00BCD4;
    border-radius: 5px;
    padding: 5px;
    text-align: center;
}

.modal-body .register-modal {
    border: 1px solid #00BCD4;
    border-radius: 5px;
    padding: 5px;
    text-align: center;
    background: #00BCD4;
    color: #fff
}

.header-without-login .display-translation-switcher,
.header-without-login .login-and-registration-buttons {
    display: none;
}

.MuiDrawer-paper {
    width: 25% !important;
}

.header-without-login .nav-items-mobile {
    display: none;
}
.header-without-login .nav-items-mobile-menu{
    display: flex;
    align-items: center;
}



.header-without-login .section-fixed {
    background: rgba(1, 35, 36, 1);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 12px 0;
  }
  
  .header-without-login .section-fixed img {
    margin-right: 10px;
    height: 30px;
    width: 30px;
  }
  
  .header-without-login .section-fixed .text {
    font-size: 15px;
    font-weight: 700;
    line-height: 30px;
    letter-spacing: 1.0800000429153442px;
    text-align: center;
    color: rgba(255, 255, 255, 1);
    margin-bottom: 0;
  }

@media screen and (min-width:1322px) {
    .side-menu {
        display: none;
    }
}

@media only screen and (max-width: 1330px) {
    .header-without-login .search {
        width: 40%;
    }

    .header-without-login .right-part .menu-burger {
        display: block;
        color: #00BCD4 !important;
    }

    .header-without-login .navbar .nav-menu {
        display: none;
    }

    .header-without-login .nav-items {
        display: flex;
        justify-content: space-between;
    }

    .header-without-login .right-part .registration-button {
        margin-right: 20px;
    }

    .header-without-login .display-translation-switcher {
        display: block;
        margin-top: 12px;
    }
}

@media only screen and (max-width: 1290px) {
    .header-without-login .search {
        width: 35%;
    }
}

@media only screen and (max-width: 1190px) {
    .header-without-login .search {
        width: 30%;
    }
}

@media only screen and (max-width: 1165px) {
    .header-without-login .navbar-items {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .header-without-login .search {
        margin-left: 0;
    }

    .header-without-login .login-and-registration-buttons {
        display: block;
    }

    .header-without-login .right-part .add-property {
        display: none;
    }

    .header-without-login .right-part .login-button {
        display: none;
    }

    .header-without-login .right-part .registration-button {
        display: none;
    }

    .header-without-login .right-part .menu-burger {
        margin-top: 5px;
    }
}

@media only screen and (max-width: 1000px) {
    .header-without-login .navbar-logo {
        margin-right: 0px;
    }
}

@media only screen and (max-width: 815px) {
    .header-without-login .navbar-logo {
        width: 50px;
        margin: 0 0 0 0;
        display: flex;
        margin-right: 60px;
    }

    .header-without-login .navbar-logo .display-translation-switcher {
        margin-left: 10px;
    }

    .header-without-login .display-translation-switcher .lang-switcher {
        width: 60px;
        margin-top: 12px;
    }

    .header-without-login .login-and-registration-buttons .registration-button {
        font-size: 11px;
        font-weight: 400;
        text-align: center;
        border: 1px solid rgba(0, 188, 212, 1);
        border-radius: 50px;
        padding: 9px 13px;
        color: #ffffff;
        display: inline-block;
        margin-right: 7px;
        background: rgba(0, 188, 212, 1);
    }

    .header-without-login .login-and-registration-buttons .login-button {
        font-size: 11px;
        font-weight: 400;
        text-align: center;
        border: 1px solid rgba(0, 188, 212, 1);
        border-radius: 50px;
        padding: 9px 13px;
        display: inline-block;
        margin-right: 0;
    }

    .header-without-login .nav-items {
        display: none;
    }
    
    .header-without-login .nav-items-mobile {
          display: flex ;
          justify-content: space-between;
          align-items: center;
          width: 100%;
    }

    .header-without-login .display-translation-switcher {
        margin-top: 0;
    }
}

@media screen and (max-width: 872px) {
    .MuiDrawer-paper {
        width: 30% !important;
    }

    .header-without-login .search {
        display: none;
    }
}

@media screen and (max-width: 768px) {
    .MuiDrawer-paper {
        width: 40% !important;
    }
}

@media screen and (max-width: 767px) {
    .NavbarItems {
        position: relative;
    }

    .nav-links {
        display: table;
        width: 100%;
        padding: 20px;
        text-align: center;
    }

    .nav-menu {
        display: flex;
        position: absolute;
        top: 80px;
        left: -100%;
        width: 100%;
        height: 90vh;
        flex-direction: column;
        opacity: 1;
        transition: all 0.5s ease;
    }

    .nav-menu.active {
        left: 0;
        opacity: 50;
        transition: all 0.6s ease;
        z-index: 1;
    }

    .navbar-logo {
        float: right;
    }

    .fa-times {
        color: #000;
        font-size: 2rem;
    }

    .nav-btn {
        display: flex;
        width: 100%;
        justify-content: center;
        align-items: center;
    }

    .header-without-login .display-translation-switcher {
        margin-top: 0;
    }

    .header-without-login .section-fixed {
        padding: 14px 10px;
    }
      
    .header-without-login .section-fixed .text {
        font-size: 16px;
    }
}

@media screen and (max-width: 600px) {
    .MuiDrawer-paper {
        width: 45% !important;
    }
}

@media screen and (max-width: 595px) {
    .header-without-login .navbar {
        padding: 14px 10px !important;
    }
}

@media screen and (max-width: 400px) {
    .MuiDrawer-paper {
        width: 65% !important;
    }
}

@media screen and (max-width: 500px) {
    .MuiDrawer-paper {
        width: 60% !important;
    }

    .header-without-login .makeStyles-faSearch-3 {
        display: none;
    }
}

@media screen and (max-width: 413px) {
    .header-without-login .search {
        margin-left: 0;
    }

    .header-without-login .section-fixed {
        padding: 3px 10px;
    }

    .header-without-login .section-fixed img {
        width: 40px;
    }

    .header-without-login .section-fixed .text {
        font-size:11px;
        line-height: 21px;
    }
}

@media screen and (max-width: 390px) {
    .navbar {
        padding: 10px 0;
    }

    .navbar-logo {
        width: 170px;
    }

    .navbar-container.container {
        padding: 0 0 0 5px;
    }
}

@media screen and (max-width:982px) {
    .side-menu {
        width: 40%;
    }
}

@media screen and (max-width:700px) {
    .side-menu {
        width: 50%;
    }
}

@media screen and (max-width:540px) {
    .side-menu {
        width: 55%;
    }
}

@media screen and (max-width:437px) {
    .header-without-login .search-field {
        float: left;
        padding: 10px !important;
        height: 28px;
        border: none;
        background-color: transparent;
        width: 100% !important;
    }
}

@media screen and (max-width:390px) {
    .side-menu {
        width: 70%;
    }
}

@media screen and (max-width:340px) {
    .side-menu {
        width: 80%;
    }
}

@media screen and (max-width: 320px) {
    .MuiDrawer-paper {
        width: 75% !important;
    }

    .header-without-login .search-field::placeholder {
        font-size: 14px;
    }
}