.section {
    scroll-snap-align: center;
}

.small-card {
    height: 155px;
    margin: 20px;
    background: #ffffff;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px rgba(0, 0, 0, 0.12), 0px 1px 3px rgba(0, 0, 0, 0.2);
    border-radius: 4px;
    border: 3px solid transparent;
    cursor: pointer;
}

.small-card-wrapper {
    padding: 12px;
}

.small-card.selected {
    border: 3px solid #4dd0e1;
}

.small-card-wrapper-top-image {
    float: left;
    width: 96px;
    height: 96px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.small-card-wrapper-top-details {
    float: right;
    text-align: left;
    width: 60%;
}

.small-card-wrapper-top-details h4 {
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 700;
    font-size: 36px;
    line-height: 40px;
    text-transform: uppercase;
    color: #000000;
}

.small-card-wrapper-top-details h5 {
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    margin-bottom: 8px;
}

.small-card-wrapper-top-details-specs-item {
    float: left;
}

.small-card-wrapper-top-details-specs-item img {
    display: inline-block;
    height: 15px;
}

.small-card-wrapper-top-details-specs-item h5 {
    display: inline-block;
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 0px;
}

.small-card-wrapper .propertyId {
    display: block;
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 18px;
    text-align: left;
    margin-bottom: 0px;
}

.small-card-wrapper-bottom-ratings img {
    display: inline-block;
}

.small-card-wrapper-bottom-ratings h5 {
    display: inline-block;
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
}

/* Modal style */
.deleting-a-property .deleting-a-property-content {
    padding: 21px;
}

.deleting-a-property .deleting-a-property-content .title-header .close-icon {
    float: right;
    cursor: pointer;
}

.deleting-a-property .deleting-a-property-content .deleting-a-property-modal {
    margin: 0 85px;
}

.deleting-a-property .deleting-a-property-content .deleting-a-property-modal .title {
    margin-bottom: 8px;
    font-family: Lora;
    font-style: normal;
    font-weight: 1;
    font-size: 48px;
    line-height: 52px;
    text-align: center;
    color: #000000;
}

.deleting-a-property .deleting-a-property-content .deleting-a-property-modal .title-sub {
    margin-bottom: 45px;
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 24px;
    text-align: center;
    color: #000000;
}

.deleting-a-property .deleting-a-property-content .deleting-a-property-modal .textFieldContainer {
    width: 500px;
    display: block;
    margin: 0 auto 36px auto;
    border-radius: 4px;
    background-color: #E2E2E2;
}

.deleting-a-property .deleting-a-property-content .deleting-a-property-modal .textFieldContainer:last-child {
    margin-bottom: 8px;
}

.deleting-a-property .deleting-a-property-content .deleting-a-property-modal .actions {
    text-align: center;
}

.deleting-a-property .deleting-a-property-content .deleting-a-property-modal .actions .send {
    margin-right: 20px;
    background: #00BCD4;
    border-radius: 4px;
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 18px;
    padding: 15px 32px;
    text-transform: uppercase;
    color: #FFFFFF;
}

.deleting-a-property .deleting-a-property-content .deleting-a-property-modal .actions .send:disabled {
    background-color: rgb(235, 235, 228);
    color: rgb(84, 84, 84);
}

.deleting-a-property .deleting-a-property-content .deleting-a-property-modal .actions .close {
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 18px;
    text-transform: uppercase;
    background-color: #fff;
    border-radius: 4px;
    border: 1px solid #00BCD4;
    padding: 15px 32px;
    color: #000000;
    margin-right: 20px;
}

.deleting-a-property .deleting-a-property-content .deleting-a-property-modal .actions .deleteProperty {
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 18px;
    text-transform: uppercase;
    background-color: red;
    border-radius: 4px;
    border: 1px solid red;
    padding: 15px 32px;
    color: #fff;
}


.show-square-meter{
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 50px

}


@media screen and (max-width:1239px){
    .small-card-wrapper {
        padding: 8px;
      }

    .small-card {
      height: 155px;
      margin: 20px;
      background: #ffffff;
      box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px rgba(0, 0, 0, 0.12), 0px 1px 3px rgba(0, 0, 0, 0.2);
      border-radius: 4px;
      border: 3px solid transparent;
      cursor: pointer;
      width: 88%;
    }

    .small-card-wrapper-top-details-specs-item{
        display: flex;
    }

    .small-card-wrapper-top-details {
        float: right;
        text-align: left;
        width: 60%;
        margin-bottom: 8px;
      
    }

    .small-card-wrapper-top-details-specs-item .area-icon{
      width: 35%;
      height: 15px;
    }

    .small-card-wrapper-top-details-specs-item .bed-icon{
        width: 100%;
        height: 13px;
    }
    .small-card-wrapper-top-details-specs-item .shower-icon{
        width: 100%;
        height: 15px;
    }
  }

  @media screen and (max-width:768px){
    .show-square-meter{
       overflow: visible;
        white-space: nowrap;
        text-overflow: "";
        width: 40px;
        margin-right: 5px;
    }
  }

  @media screen and (max-width:673px){
    .deleting-a-property .deleting-a-property-content .deleting-a-property-modal {
        margin: 0 20px;
    }
  }

  @media screen and (max-width:543px){
    .deleting-a-property .deleting-a-property-content .deleting-a-property-modal {
        margin:0px;
    }

    .deleting-a-property .deleting-a-property-content .deleting-a-property-modal .actions .send {
        margin-right: 20px;
        width: 100%;
        background: #00BCD4;
        border-radius: 4px;
        font-family: Source Sans Pro;
        font-style: normal;
        font-weight: bold;
        font-size: 14px;
        line-height: 18px;
        padding: 15px 32px;
        text-transform: uppercase;
        color: #FFFFFF;
    }
  }

  @media screen and (max-width:400px){
    .deleting-a-property .deleting-a-property-content .deleting-a-property-modal .actions {
       display: flex;
       flex-direction: column;
       gap: 10px;
       padding: 0 5px;
    }
    .deleting-a-property .deleting-a-property-content .deleting-a-property-modal .actions .close {
       width: 100%;
     }
     .deleting-a-property .deleting-a-property-content .deleting-a-property-modal .actions .deleteProperty {
        width: 100%;
      }
  }

  @media screen and (max-width:331px){
    .small-card-wrapper-top-details h4 {
        font-family: 'Source Sans Pro';
        font-style: normal;
        font-weight: 700;
        font-size: 30px;
        line-height: 40px;
        text-transform: uppercase;
        color: #000000;
    }
  }