.dashboard-seller{
  overflow: hidden;
}

.dashboard-seller .trans-terminate-button {
    margin-bottom: 4px;
    margin-top: 16px;
    padding: 12px 10px;
    float: right;
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 14px;
    text-transform: none;
}

.dashboard-seller .trans-terminate-button img {
    width: 20px;
    height: 20px;
}

.dashboard-seller .title {
    margin-bottom: 16px;
    font-family: Lora;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 26px;
    color: #000000;
}

.dashboard-seller .content-left {
    margin-bottom: 62px;
    display: flex;
}

.dashboard-seller .content-left .part-left {
    float: left;
    width: 36%;
    margin-right: 15px;
}

.dashboard-seller .content-left .part-left img {
    border-radius: 12px;
    height: 195px;
}

.dashboard-seller .content-left .part-right {
    float: left;
    margin-top: 40px;
}

.dashboard-seller .content-left .part-right .price-containers{
    display: flex;
    justify-content: space-between;
    gap:10px;
    
    
 }
.dashboard-seller .content-left .part-right .part-right-price {
    float: left;
}

.dashboard-seller .content-left .part-right .part-right-price .title-price {
    margin-bottom: 10px;
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: bold;
    font-size: 33px;
    line-height: 45px;
    color: #000000;
}

.dashboard-seller .content-left .part-right .part-left-price {
    float: right;
    margin-top: 10px;
    text-align: right;
}

.dashboard-seller .content-left .part-right .part-left-price p {
    margin-bottom: 8px;
}

.dashboard-seller .content-left .part-right .part-left-price .title {
    margin-right: 6px;
    border-radius: 4px;
    padding: 3px 8px;
    background-color: #F4F4F4;
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 18px;
    color: #000000;
}

.dashboard-seller .content-left .part-right .neighbor {
    margin-bottom: 10px;
}

.dashboard-seller .content-left .part-right .neighbor span {
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 24px;
    color: #000000;
}

.dashboard-seller .content-left .part-right .part-right-content .info .part-right-content-info-text {
    margin-bottom: 10px;
    margin-right: 12px;
}

.dashboard-seller .content-left .part-right .part-right-content .info .part-right-content-info-text:nth-last-child(2) img {
    margin-top: 6px;
}

.dashboard-seller .content-left .part-right .part-right-content .info .part-right-content-info-text img {
    margin-right: 5px;
}

.dashboard-seller .content-left .part-right .part-right-content .info .part-right-content-info-text span {
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 24px;
    color: #000000;
}

.dashboard-seller .content-left .part-right .part-right-content hr {
    margin: 8px 0 16px 0;
}

.dashboard-seller .content-left .part-right .part-right-content .info-details{
    display: flex;
    justify-content: space-between;
}

.dashboard-seller .content-left .part-right .part-right-content .info-details .info-details-part-left {
    float: left;
    /* margin-right: 60px; */
}

.dashboard-seller .content-left .part-right .part-right-content .info-details .info-details-part-left .info-details-title {
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    color: #000000;
}

.dashboard-seller .content-left .part-right .part-right-content .info-details .info-details-part-right .info-text {
    margin-right: 15px;
}

.dashboard-seller .content-left .part-right .part-right-content .info-details .info-details-part-right .info-text:nth-last-child(1) {
    margin-right: 0;
}

.dashboard-seller .content-left .part-right .part-right-content .info-details .info-details-part-right .info-text img {
    margin-right: 5px;
}

.dashboard-seller .content-center .maps {
    height: 190px;
    width: 100%;
    border-radius: 12px;
}

.dashboard-seller .content-center  .maps {
    position: relative;
    height: 220px;
    margin-bottom: 20px;
  }
  
  .dashboard-seller .content-center  .maps .map {
    margin-bottom: 53px;
    padding: 70px 95px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-image: url("https://d3kuhob3u5bpy2.cloudfront.net/2bd40c7c-0e69-4d88-9161-27dc0b3fba1a.svg");
    filter: blur(8px);
  }
  
  .dashboard-seller .content-center  .maps .map.filter {
    filter: blur(0);
  }
  
  .dashboard-seller .content-center  .maps .text {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 243px;
    transform: translate(-50%, -50%);
    text-align: center;
    padding: 8px 16px;
    background-color: #e0f7fa;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 18px;
    text-transform: uppercase;
    color: #000000;
    cursor: pointer;
  }

.dashboard-seller .content-right .price {
    margin-bottom: 5px;
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: bold;
    font-size: 52px;
    line-height: 64px;
    color: #000000;
}

.dashboard-seller .content-right .seller {
    margin-bottom: 8px;
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 24px;
    text-transform: uppercase;
    color: #000000;
}

.dashboard-seller .content-right .seller-person img {
    display: inline-block;
    margin-top: 10px;
    margin-right: 15px;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    object-fit: cover;
}

.dashboard-seller .content-right .seller-person .info {
    display: inline-block;
}

.dashboard-seller .content-right .seller-person .info .name {
    margin-top: 10px;
    margin-bottom: 4px;
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: 900;
    font-size: 18px;
    line-height: 22px;
    text-transform: uppercase;
    color: #000000;
}

.dashboard-seller .content-right .seller-person .info img {
    margin-top: 5px;
    margin-right: 6px;
}

.dashboard-seller .content-right .seller-person .info span {
    font-family: Source Code Pro;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
    color: #000000;
}

.dashboard-seller .title-section {
    display: flex;
    justify-content: center;
}

.dashboard-seller .title-section .title {
    font-family: Lora;
    font-style: normal;
    font-weight: bold;
    font-size: 32px;
    line-height: 32px;
    text-align: center;
    color: #000000;
}

.dashboard-seller .title-section .icon {
    margin-left: 4px;
    width: 24px;
    height: 24px;
    margin-top: 4px;
}

.dashboard-seller .stepper {
    margin: 0 40px;
}

.dashboard-seller .stepper .title {
    margin-bottom: 14px;
    font-family: Lora;
    font-style: normal;
    font-weight: bold;
    font-size: 32px;
    line-height: 32px;
    text-align: center;
    color: #000000;
}

.dashboard-seller .MuiStepIcon-text {
    font-family: Lora !important;
    font-style: normal !important;
    font-weight: bold !important;
    font-size: 14px !important;
    text-align: center !important;
    color: #8C8C8C;
}

.dashboard-seller .MuiStepIcon-active .MuiStepIcon-text {
    color: red !important;
    fill: #fff !important;
}

.dashboard-seller .chat-container {
    margin: 0 235px 64px;
}

.dashboard-seller .chat-container .chat-left {
    float: left;
    height: 100%;
    padding: 55px 35px 0 16px;
    background-color: #F8F8F8;
    border-radius: 12px 0 0px 0px;
}

.dashboard-seller .chat-container .chat-left .chat-content-users {
    margin-bottom: 24px;
    padding: 8px;
    background-color: #EDEDED;
    border-radius: 12px;
}

.dashboard-seller .chat-container .chat-left .chat-content-users .avatar {
    display: inline-block;
    width: 24px;
    height: 24px;
    margin-right: 8px;
    border-radius: 50%;
}

.dashboard-seller .chat-container .chat-left .chat-content-users .chat-info-user {
    display: inline-block;
}

.dashboard-seller .chat-container .chat-left .chat-content-users .chat-info-user .name {
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 18px;
    text-transform: uppercase;
    color: #434343;
}

.dashboard-seller .chat-container .chat-left .chat-content-users .chat-info-user .user-role {
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 12px;
    color: #8C8C8C;
}

.dashboard-seller .chat-container .info {
    float: left;
    width: 100%;

}

.dashboard-seller .chat-container .info.remove-user-chat {
    float: left;
    width: 100%;
}

/* styles tabs */
.dashboard-seller .info .app-bar-info {
    background: #F8F8F8;
    ;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px rgba(0, 0, 0, 0.12), 0px 1px 3px rgba(0, 0, 0, 0.2);
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
}

.dashboard-seller .info .app-bar-info .tabs-info-container .tabs-title {
    padding: 14px 25px;
    font-family: Source Sans Pro !important;
    font-style: normal !important;
    font-weight: normal !important;
    font-size: 18px !important;
    line-height: 24px !important;
    color: #000000 !important;
    text-transform: none !important;
}

.dashboard-seller .info .app-bar-info .tabs-info-container .tabs-title[aria-selected="true"] {
    background-color: #E8E8E8;
    border-radius: 12px 12px 0px 0px;
}

.dashboard-seller .info .container-info {
    background-color: #E8E8E8;
}

.dashboard-seller .info .MuiTabs-indicator {
    display: none;
}

.dashboard-seller .hire-lawyer-content {
    margin: 64px 235px;
    padding: 32px 116px;
    background: #F8F8F8;
    border-radius: 10px;
    text-align: center;
}

.dashboard-seller .hire-lawyer-content .title {
    font-size: 32px;
    line-height: 32px;
    font-weight: 700;
    font-family: Lora;
    margin-bottom: 8px;
}

.dashboard-seller .hire-lawyer-content .text {
    margin: 0 20px 24px 20px;
    font-size: 18px;
    line-height: 24px;
    font-family: Source Sans Pro;
    font-weight: 400;
}

.dashboard-seller .hire-lawyer-content .box-content {
    margin-bottom: 24px;
}

.dashboard-seller .hire-lawyer-content .box-content .box {
    padding: 40px 24px 26px 24px;
    background: #F4F4F4;
    border-radius: 5px;
    text-align: center;
}

.dashboard-seller .hire-lawyer-content .box-content .box .main-icon .icon {
    margin-bottom: 10px;
}

.dashboard-seller .hire-lawyer-content .box-content .box .text-box {
    font-size: 16px;
    line-height: 18px;
    font-family: Source Sans Pro;
    font-weight: 400;
}

.dashboard-seller .hire-lawyer-content .button {
    padding: 15px 32px;
    background: #00BCD4;
    color: #fff;
    border-radius: 4px;
    text-transform: uppercase;
    font-family: Source Sans Pro;
    font-size: 14px;
    line-height: 18px;
    font-weight: 700;
}

.dashboard-seller .stepper {
    margin-bottom: 64px;
}

.dashboard-seller .stepper .next {
    background: #00BCD4;
    border-radius: 4px;
    padding: 15px 32px;
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 18px;
    text-transform: uppercase;
    color: #FFFFFF;
    float: right;
}

.dashboard-seller .stepper .back {
    background: #00BCD4;
    border-radius: 4px;
    padding: 15px 32px;
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 18px;
    text-transform: uppercase;
    color: #FFFFFF;
    float: left;
}

.dashboard-seller .stepper .do-not-want-the-property {
    margin: 64px 90px;
    text-align: center;
}

.dashboard-seller .stepper .do-not-want-the-property .tile {
    margin-bottom: 8px;
    font-family: Lora;
    font-style: normal;
    font-weight: bold;
    font-size: 32px;
    line-height: 32px;
    text-align: center;
    color: #000000;
}

.dashboard-seller .stepper .do-not-want-the-property .tile-sub {
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 24px;
    text-align: center;
    color: #000000;
}

.dashboard-seller .chat-container .info .container-deal .title-content {
    margin-bottom: 40px;
    font-family: Lora;
    font-style: normal;
    font-weight: bold;
    font-size: 32px;
    line-height: 32px;
    color: #000000;
    text-align: center;
}

.dashboard-seller .chat-container .info .container-deal .content {
    padding: 32px;
    background: #FFFFFF;
    border-radius: 8px;
    text-align: left;
}

.dashboard-seller .chat-container .info .container-deal .content .title-sub {
    margin-bottom: 24px;
    display: inline-block;
    font-family: Lora;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 26px;
    color: #000000;
}

.dashboard-seller .chat-container .info .container-deal .content .sum {
    margin-bottom: 8px;
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 18px;
    color: #000000;
}

.dashboard-seller .chat-container .info .container-deal .content .price {
    margin-bottom: 16px;
    padding: 3px 8px;
    display: inline-block;
    background: #E0F7FA;
    border-radius: 4px;
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: bold;
    font-size: 36px;
    line-height: 45px;
    text-transform: uppercase;
    color: #000000;
}

.dashboard-seller .chat-container .info .container-deal .content .local-tax-costs {
    margin-bottom: 16px;
}

.dashboard-seller .chat-container .info .container-deal .content .local-tax-costs .local-tax-costs-title {
    margin-bottom: 8px;
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 18px;
    color: #000000;
}

.dashboard-seller .chat-container .info .container-deal .content .local-tax-costs .local-tax-costs-text {
    padding: 8px;
    display: inline-block;
    background: #EDEDED;
    border-radius: 4px;
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 24px;
    color: #000000;
}

.dashboard-seller .chat-container .info .container-deal .content .data {
    margin-bottom: 16px;
}

.dashboard-seller .chat-container .info .container-deal .content .data .data-title {
    margin-bottom: 8px;
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 18px;
    color: #000000;
}

.dashboard-seller .chat-container .info .container-deal .content .data .data-text {
    display: inline-block;
    padding: 8px;
    background: #EDEDED;
    border-radius: 4px;
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 24px;
    color: #000000;
}

.dashboard-seller .chat-container .info .container-deal .content .both-sides {
    margin-bottom: 10px;
}

.dashboard-seller .chat-container .info .container-deal .content .both-sides .both-sides-title {
    margin-bottom: 8px;
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 18px;
    color: #000000;
}

.dashboard-seller .chat-container .info .container-deal .content .both-sides .both-sides-text {
    display: inline-block;
    padding: 8px;
    background: #EDEDED;
    border-radius: 4px;
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 24px;
    color: #000000;
}

.dashboard-seller .chat-container .info .container-deal .content .checkbox span {
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.4px;
    color: #000000;
}

.dashboard-seller .chat-container .info .container-deal .content .date-of-inspection {
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.4px;
    color: #8C8C8C;
}

.dashboard-seller .container-document-seller .title-content {
    margin-bottom: 24px;
    font-family: Lora;
    font-style: normal;
    font-weight: bold;
    font-size: 32px;
    line-height: 32px;
    text-align: center;
    color: #000000;
}

.dashboard-seller .container-document-seller .content {
    margin: 0 235px 16px 235px;
    padding: 24px;
    background: #FFFFFF;
    border-radius: 4px;
    border-bottom: 8px solid #A5D6A7;
}

.dashboard-seller .container-document-seller .content.error {
    border-bottom: 8px solid #EF9A9A;
}

.dashboard-seller .container-document-seller .content .content-title {
    margin-bottom: 8px;
}

.dashboard-seller .container-document-seller .content .content-title img {
    display: inline-block;
    width: 17px;
    margin-top: 4px;
    margin-right: 10px
}

.dashboard-seller .container-document-seller .content .content-title .title {
    display: inline-block;
    font-family: Lora;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 26px;
    color: #000000;
}

.dashboard-seller .container-document-seller .content .text {
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 24px;
    color: #000000;
}

.dashboard-seller .container-document-seller .content .content-right-title {
    margin-top: 25px;
}

.dashboard-seller .container-document-seller .content .content-right-title img {
    display: inline-block;
    width: 53px;
    margin-right: 21px;
}

.dashboard-seller .container-document-seller .content .content-right-title .title {
    display: inline-block;
    width: 154px;
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: 900;
    font-size: 18px;
    line-height: 22px;
    text-transform: uppercase;
    color: #000000;
}

.dashboard-seller .container-document-seller .additional-document-box {
    margin: 0 235px;
    border: 1px dashed #000000;
    box-sizing: border-box;
    border-radius: 8px;
}

.dashboard-seller .container-document-seller .additional-document-box .button {
    font-weight: 500;
    text-transform: none;
}

.dashboard-seller .container-document-seller .additional-document-box .button .MuiButton-startIcon {
    margin: 0;
}

.dashboard-seller .container-document-seller .additional-document-box .button .button-info .header-content {
    display: flex;
    margin-bottom: 32px;
    margin-top: 10px;
}

.dashboard-seller .container-document-seller .additional-document-box .button .button-info .header-content .icon {
    margin-right: 9px;
}

.dashboard-seller .container-document-seller .additional-document-box .button .button-info .header-content .text {
    font-size: 20px;
    line-height: 28px;
}

.dashboard-seller .container-document-seller .additional-document-box .button .button-info .footer-content .add-icon {
    width: 25px;
    height: 25px;
    margin-right: 10px;
    vertical-align: middle;
}

.dashboard-seller .container-document-seller .additional-document-box .button .button-info .footer-content .text {
    font-size: 18px;
    line-height: 21px;
}

@media screen and (max-width:1224px){
.dashboard-seller .property-details{
    display:flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.dashboard-seller .property-details .col5of12{
    width: 100%;
    padding: 0px 0px 0px 30px;
} 
.dashboard-seller .property-details .col4of12{
    width: 100%;
}

.dashboard-seller .property-details .col4of12 .title{
    text-align: center;
}

.dashboard-seller .property-details .col3of12{
    width: 100%;
    padding: 0px 0px 0px 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap:20px;
    margin: 20px 0px;
}

.dashboard-seller .property-details .col3of12 .content-right{
  display: flex;
  gap:20px;
  justify-content: center;
  align-items: center;
 }

.dashboard-seller .content-right .seller-person {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    
}
.dashboard-seller .content-right .seller-person img {
    object-fit: cover;
}
.hire-lawyer-box-container{
  display: flex;
  flex-direction: column;
  gap:30px
}
.hire-lawyer-box-container .col4of12{
    width: 100%;
}
.dashboard-seller .stepper {
    margin: 0px 20px;
}

.dashboard-seller .chat-container {
    margin: 20px 20px 64px;
}
}

@media screen and (max-width:1004px){
    .dashboard-seller .hire-lawyer-content {
        margin: 64px 20px;
        padding: 32px 116px;
        background: #F8F8F8;
        border-radius: 10px;
        text-align: center;
    }
}

@media screen and (max-width:800px){
    .dashboard-seller .property-details .col5of12 .content-left{
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
    } 
    .dashboard-seller .content-left .part-left img {
       width: 100%;
    }
    .dashboard-seller .property-details .col5of12{
        padding: 0px  30px;
    }
    .dashboard-seller .content-left .part-left {
        float: left;
        width: 100%;
        margin-right: 0px;
    }
}

@media screen and (max-width:790px){
    .dashboard-seller .stepper .MuiStepper-horizontal {
        flex-direction: column !important;
        justify-content: center;
        align-items: center;
        gap: 20px;
    }

    .dashboard-seller .property-details .col3of12{
        width: 100%;
        padding: 0px 0px 0px 40px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        gap:5px;
        margin: 20px 0px;
    }
    .dashboard-seller .property-details .col3of12 .content-right{
        display: flex;
        flex-direction: column;
        gap:5px;
        justify-content: flex-start;
        align-items:flex-start;
       }
}

@media screen and (max-width:588px){
    .dashboard-seller .hire-lawyer-content {
        padding: 32px 20px;
    }
}


@media screen and (max-width: 432px){
    .dashboard-seller {
        margin: 20px;
    }

    .dashboard-seller .title {
        margin-bottom: 0;
    }

    .dashboard .hire-lawyer-content {
        padding: 32px 10px;
    }

    .dashboard-seller .content-right .seller-person .info .name {
        margin-top: -10;
    }

    .dashboard-seller .title-section .title {
        font-size: 24px;
    }

    .dashboard-seller .trans-terminate-button {
        padding: 0;
        right: 15px;
        position: absolute;
        float: none;
    }

    .dashboard-seller .content-left {
        margin-top: 10px;
    }

    .cols {
        margin: 0;

    }

    .col {
        padding: 0;
        width: 100%;
    }

    .dashboard-seller .property-details .col5of12 {
        padding: 0;
    }

    .dashboard-seller .content-left .part-right {
        margin-top: 10px !important;
    }

    .dashboard-seller .content-left .part-right .part-right-price .title-price {
        margin-bottom: 15px;
    }

    .dashboard-seller .content-left .part-right .part-left-price img {
        margin-top: 4px;
    }

    .dashboard-seller .content-left .part-right .part-right-content .info-details .info-details-part-left {
        margin-right: 30px;
    }

    .dashboard .content-left .part-right .part-right-content .info-details .info-details-part-left .info-details-title {
        margin-bottom: 0;
    }

    .dashboard .content-left .part-right .info-details {
        margin-bottom: 0;
    }

    .dashboard .content-left .part-right .info-details .propertyOfferCount{
        margin-bottom: 0;
    }

    .dashboard-seller .property-details .col5of12 .content-left {
        margin-bottom: 15px;
    }

    .dashboard .MuiStep-root.MuiStep-horizontal.MuiStep-alternativeLabel .MuiStepConnctor-root.MuiStepConnector-horizontal.MuiStepConnector-alternativeLabel.makeStyles-connectorLine-50.Mui-disabled {
        display: none;
    }

    .dashboard-seller .hire-lawyer-content .title {
        font-size: 25px;
    }

    .dashboard-seller .hire-lawyer-content .text {
        text-align: justify;
        margin: 0;
    }

    .dashboard-seller .stepper .title {
        font-size: 22px;
    }

    .dashboard-seller .MuiStep-root.MuiStep-horizontal.MuiStep-alternativeLabel .MuiStepConnctor-root.MuiStepConnector-horizontal.MuiStepConnector-alternativeLabel.makeStyles-connectorLine-50.Mui-disabled {
        display: none !important;
    }
    
    .dashboard-seller .stepper .MuiPaper-root.MuiStepper-root.MuiStepper-horizontal.MuiStepper-alternativeLabel .MuiStepConnector-root.MuiStepConnector-horizontal.MuiStepConnector-alternativeLabel.Mui-disabled {
        display: none !important;
    }

    .dashboard-seller .stepper .MuiPaper-root.MuiStepper-root.MuiStepper-horizontal.MuiStepper-alternativeLabel .MuiStep-root.MuiStep-horizontal.MuiStep-alternativeLabel .MuiStepConnector-root.MuiStepConnector-horizontal.MuiStepConnector-alternativeLabel.MuiStepConnector-active {
        display: none !important;
    }
    
    .dashboard .stepper .MuiPaper-root.MuiStepper-root.MuiStepper-horizontal.MuiStepper-alternativeLabel .MuiStepConnector-root.MuiStepConnector-horizontal.MuiStepConnector-alternativeLabel.Mui-disabled {
        display: none !important;
    }

    .dialog-seller-change-meeting-date-request-content {
        padding: 30px 10px;
    }

    .dashboard-seller .stepper .MuiPaper-root.MuiStepper-root.MuiStepper-horizontal.MuiStepper-alternativeLabel .MuiStep-root.MuiStep-horizontal.MuiStep-alternativeLabel.MuiStep-completed .MuiStepConnector-root.MuiStepConnector-horizontal.MuiStepConnector-alternativeLabel.MuiStepConnector-completed {
        display: none !important;
    }

    .dialog-seller-change-meeting-date-request-content .title-header .title {
        line-height: 30px;
        font-size: 25px;
    }

    .dialog-seller-change-meeting-date-request-content .content .sub-title {
        text-align: justify;
        padding: 0 20px;
    }

    .dialog-seller-change-meeting-date-request-content .content .date-hour-picker-boxes {
        padding: 0 5px;
    }

    .dialog-seller-change-meeting-date-request-content .content .date-hour-picker-boxes .hour-picker-box .hours-list .chip {
        float: none;
        display: block;
    }

    .dashboard-seller .chat-container .info .container-deal .title-content {
        font-size: 17px;
        line-height: 27px;
        margin: 0 5px;
    }

    .dashboard-seller .chat-container .info .container-deal .content .price {
        font-size: 24px;
    }

    .dashboard-seller .chat-container .info .container-deal .content .local-tax-costs .local-tax-costs-title {
        text-align: justify;
    }

    .dashboard-seller .cols-full {
        display: flex !important;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 20px;
    }
}

@media screen and (max-width:330px){
    .dashboard-seller .content-left .part-right .price-containers {
        gap: 5px;
    }
}