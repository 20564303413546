/* Edited code */
.my-profile-virtual-assistant .header-content { 
    display: flex; margin-top: 32px; 
    padding: 0 32px;
    }

.my-profile-virtual-assistant .header-content .photo-group { display: flex; flex-direction: column; justify-content: center; }
.my-profile-virtual-assistant .header-content .photo-group .avatar { 
    position: relative; 
    width: 178px; height: 178px; 
    border-radius: 50%; 
}
.my-profile-virtual-assistant .header-content .photo-group .avatar img { width: 178px; height: 178px; border-radius: 50%; object-fit:cover}

.my-profile-virtual-assistant .header-content .photo-group .avatar .icon{
    position: absolute;
    right: 0px;
    bottom: 10px;
    height: 30px;
    width: 30px;
    }
.my-profile-virtual-assistant .header-content .photo-group .user-id { margin-top: 8px; text-align: center; font-family: Source Sans Pro; font-style: normal; font-weight: normal; font-size: 12px; line-height: 16px; }

.my-profile-virtual-assistant .header-content .user-details { 
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin-left: 24px; 
 }
.my-profile-virtual-assistant .header-content .user-details .user-name { font-size: 48px; line-height: 56px; margin-bottom: 8px; }

.my-profile-virtual-assistant .header-content .user-details .rating { display: flex; align-items: flex-end; }
.my-profile-virtual-assistant .header-content .user-details .rating .title { margin-right: 12px; font-family: Source Sans Pro; font-style: normal; font-weight: 900; font-size: 18px; line-height: 22px; text-transform: uppercase; }
.my-profile-virtual-assistant .header-content .user-details .rating  .rating-results .result { font-size: 16px; font-weight: 400; line-height: 18px; margin-left: 8px; margin-top: 1px; }

.my-profile-virtual-assistant .header-content .account-details { margin-left: auto; margin-right: 32px; }
.my-profile-virtual-assistant .header-content .account-details .button { float: right; font-family: Source Sans Pro; font-style: normal; font-weight: bold; font-size: 14px; line-height: 18px; text-transform: uppercase; color: #0097A7; }

.my-profile-virtual-assistant .changes-button { display: flex; gap: 32px; margin-top: 46px; justify-content: center; }
.my-profile-virtual-assistant .changes-button .button-view-profile { padding: 15px 32px; background-color: #00BCD4; border-radius: 4px; color: #FFFFFF; text-transform: uppercase; font-family: Source Sans Pro; font-style: normal; font-weight: bold; font-size: 14px; line-height: 18px; }
.my-profile-virtual-assistant .changes-button .button { padding: 15px 32px; border: 2px solid #00BCD4; border-radius: 4px; color: #000000; text-transform: uppercase; font-family: Source Sans Pro; font-style: normal; font-weight: bold; font-size: 14px; line-height: 18px; }

/* New code */
.my-profile-virtual-assistant .my-profile-content { margin: 32px 0;width: 100%; }
.my-profile-virtual-assistant .my-profile-content .root { flex-grow: 1; background: #F8F8F8; display: flex; border-radius: 12px; margin: 0 32px; }
.my-profile-virtual-assistant .my-profile-content .root .tabs { 
    width: 15%; 
    background: #E8E8E8; 
    border-radius: 12px 0px 0px 12px; 
    }
.my-profile-virtual-assistant .my-profile-content .root .tabs .tab {
     border-radius: 12px 0px 0px 12px; 
     text-transform: uppercase; 
     font-size: 14px; 
     line-height: 18px; 
     font-family: Source Sans Pro; 
     font-weight: 700; 
     width: 100% !important;
    }
.my-profile-virtual-assistant .my-profile-content .root .tabs .tab.disabled { pointer-events: none; opacity: 0.2; } 
.my-profile-virtual-assistant .my-profile-content .root .tabs .tab.active-tab { background: #F8F8F8; }
.my-profile-virtual-assistant .my-profile-content .root .tabs .tab.last-tab { position: absolute; bottom: 0; width: 100%; }
.my-profile-virtual-assistant .my-profile-content .tab-panel { width: 85%; text-align: center; }

.my-profile-virtual-assistant .my-profile-content .root .tab-panel .tab-panel-contract { min-height: 300px; }
.my-profile-virtual-assistant .my-profile-content .root .tab-panel .tab-panel-contract .contract-list-pane { display: flex; margin: 24px; background: #FFFFFF; box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px rgba(0, 0, 0, 0.12), 0px 1px 3px rgba(0, 0, 0, 0.2); border-radius: 4px; }
.my-profile-virtual-assistant .my-profile-content .root .tab-panel .tab-panel-contract .contract-list-pane .contract-details { margin-left: 24px; margin-top: 27px; }
.my-profile-virtual-assistant .my-profile-content .root .tab-panel .tab-panel-contract .contract-list-pane .contract-details .contract-title { display: flex; }
.my-profile-virtual-assistant .my-profile-content .root .tab-panel .tab-panel-contract .contract-list-pane .contract-details .contract-title .icon { height: 21px; width: 18px; margin-right: 11px; }
.my-profile-virtual-assistant .my-profile-content .root .tab-panel .tab-panel-contract .contract-list-pane .contract-details .contract-title .title { font-family: Lora; font-style: normal; font-weight: bold; font-size: 24px; line-height: 26px; }
.my-profile-virtual-assistant .my-profile-content .root .tab-panel .tab-panel-contract .contract-list-pane .contract-details .contract-date { margin-top: 8px; text-align: left; font-family: Source Sans Pro; font-style: normal; font-weight: normal; font-size: 12px; line-height: 16px; letter-spacing: 0.4px; }
.my-profile-virtual-assistant .my-profile-content .root .tab-panel .tab-panel-contract .contract-list-pane .contract-download { margin-left: auto; margin-top: 29px; }
.my-profile-virtual-assistant .my-profile-content .root .tab-panel .tab-panel-contract .contract-list-pane .contract-download .button { padding: 15px 32px; background: #00BCD4; border-radius: 4px; font-family: Source Sans Pro; font-style: normal; font-weight: bold; font-size: 14px; line-height: 18px; text-transform: uppercase; color: #FFFFFF; }
.my-profile-virtual-assistant .my-profile-content .root .tab-panel .tab-panel-contract .contract-list-pane .contract-download .button .icon { color: #FFFFFF; margin-right: 8px; }
.my-profile-virtual-assistant .my-profile-content .root .tab-panel .tab-panel-contract .contract-list-pane .contract-review { margin: 29px 33px 29px 23px; }
.my-profile-virtual-assistant .my-profile-content .root .tab-panel .tab-panel-contract .contract-list-pane .contract-review .button { padding: 15px 32px; border: 2px solid #00BCD4; box-sizing: border-box; border-radius: 4px; font-family: Source Sans Pro; font-style: normal; font-weight: bold; font-size: 14px; line-height: 18px; text-transform: uppercase; color: #000000; }
/* ################################# */

.my-profile-virtual-assistant .information-content { margin-bottom: 128px; margin-top: 64px; text-align: center; }
.my-profile-virtual-assistant .information-content .title { margin-bottom: 16px; font-family: Lora; font-size: 32px; line-height: 32px; }
.my-profile-virtual-assistant .information-content .text { margin: 0px 48px 24px 48px; font-family: Source Sans Pro; font-style: normal; font-weight: normal; font-size: 18px; line-height: 24px; }
.my-profile-virtual-assistant .information-content .button { padding: 15px 32px; border: 2px solid #00BCD4; box-sizing: border-box; border-radius: 4px; color: #000000; font-family: Source Sans Pro; font-style: normal; font-weight: bold; font-size: 14px; line-height: 18px; text-transform: uppercase; }

/* Deactivate Account Dialog */
.dialog-deactivate-account-virtual-assistant { padding: 22px; text-align: center; }
.dialog-deactivate-account-virtual-assistant .title-header { padding: 0; margin-bottom: 16px; }
.dialog-deactivate-account-virtual-assistant .title-header .title { font-family: Lora; font-style: normal; font-weight: bold; font-size: 32px; line-height: 32px; margin-top: 22px; padding: 0px 140px; }
.dialog-deactivate-account-virtual-assistant .title-header .close-icon { cursor: pointer; float: right; margin-top: -24px; }

.dialog-deactivate-account-virtual-assistant .content { padding: 0; overflow-x: hidden; }
.dialog-deactivate-account-virtual-assistant .content .sub-title { font-family: Source Sans Pro; font-style: normal; font-weight: normal; font-size: 18px; line-height: 24px; margin-bottom: 24px; padding: 0px 100px; }
.dialog-deactivate-account-virtual-assistant .content .actions { margin-top: 24px; }
.dialog-deactivate-account-virtual-assistant .content .actions .button-continue { margin-right: 24px; padding: 15px 32px; background: #F44336; border-radius: 4px; font-family: Source Sans Pro; font-style: normal; font-weight: bold; font-size: 14px; line-height: 18px; text-transform: uppercase; color: #FFFFFF; }
.dialog-deactivate-account-virtual-assistant .content .actions .button-back { padding: 15px 32px; border: 2px solid #00BCD4; box-sizing: border-box; border-radius: 4px; font-family: Source Sans Pro; font-style: normal; font-weight: bold; font-size: 14px; line-height: 18px; text-transform: uppercase; color: #000000; }

/* Place VIP Bid Dialog */
.dialog-vip-bid-virtual-assistant-content { padding: 21px; text-align: center; }
.dialog-vip-bid-virtual-assistant-content .title-header { padding: 0; margin-bottom: 8px; }
.dialog-vip-bid-virtual-assistant-content .title-header .title { font-family: Lora; font-size: 48px; line-height: 52px; margin-top: 29px; }
.dialog-vip-bid-virtual-assistant-content .title-header .close-icon { cursor: pointer; float: right; margin-top: -32px; }

.dialog-vip-bid-virtual-assistant-content .content { padding: 0; overflow-x: hidden; }
.dialog-vip-bid-virtual-assistant-content .content .sub-title { font-family: Source Sans Pro; font-style: normal; font-weight: normal; font-size: 18px; line-height: 24px; margin-bottom: 32px; }
.dialog-vip-bid-virtual-assistant-content .content .amount { font-family: Source Sans Pro; font-style: normal; font-weight: bold; font-size: 20px; line-height: 24px; margin-bottom: 8px; text-transform: uppercase; }
.dialog-vip-bid-virtual-assistant-content .content .price-panel { background: #F8F8F8; border-radius: 4px; padding: 16px; display: inline-block; margin-bottom: 32px;  }
.dialog-vip-bid-virtual-assistant-content .content .price-panel .price { font-family: Source Sans Pro; font-style: normal; font-weight: bold; font-size: 32px; line-height: 24px; }
.dialog-vip-bid-virtual-assistant-content .content .price-panel .price .currency { font-family: Source Sans Pro; font-style: normal; font-weight: 600; font-size: 12px; line-height: 24px; text-transform: uppercase; }
.dialog-vip-bid-virtual-assistant-content .content .recent-bids .recent-bids-title { font-family: Source Sans Pro; font-style: normal; font-weight: bold; font-size: 20px; line-height: 24px; text-transform: uppercase; margin-bottom: 16px; }
.dialog-vip-bid-virtual-assistant-content .content .recent-bids .recent-bids-history { display: flex; justify-content: center; padding: 7px 0px; }
.dialog-vip-bid-virtual-assistant-content .content .recent-bids .recent-bids-history .icon { height: 16px; width: 16px; margin-right: 8px; color: #000000; }
.dialog-vip-bid-virtual-assistant-content .content .recent-bids .recent-bids-history .amount { font-family: Source Sans Pro; font-style: normal; font-weight: normal; font-size: 16px; line-height: 18px; margin-right: 20px; color: #000000; }
.dialog-vip-bid-virtual-assistant-content .content .recent-bids .recent-bids-history .user-info { font-family: Source Sans Pro; font-style: normal; font-weight: 600; font-size: 16px; line-height: 18px; color: #000000; }
.dialog-vip-bid-virtual-assistant-content .content .actions { margin-top: 32px; }
.dialog-vip-bid-virtual-assistant-content .content .actions .button-filled { margin-right: 24px; }
.dialog-vip-bid-virtual-assistant-content .content .actions .button-continue { margin-right: 24px; padding: 15px 32px; background: #F44336; border-radius: 4px; font-family: Source Sans Pro; font-style: normal; font-weight: bold; font-size: 14px; line-height: 18px; text-transform: uppercase; color: #FFFFFF; }
.dialog-vip-bid-virtual-assistant-content .content .actions .button-back { padding: 15px 32px; border: 2px solid #00BCD4; box-sizing: border-box; border-radius: 4px; font-family: Source Sans Pro; font-style: normal; font-weight: bold; font-size: 14px; line-height: 18px; text-transform: uppercase; color: #000000; }

span[class^='PrivateTabIndicator-root-'] {
    background: #F8F8F8;
}

.my-profile-virtual-assistant .my-profile-content .va-menu-burger{
    display: none;
 }

 .my-profile-virtual-assistant .my-profile-content .root .MuiTabs-scroller {
    width: 100%;
}

 @media screen and (min-width:1037px){
    .my-profile-virtual-assistant .my-profile-content .root .tabs {
       display: block;
    }
    .my-profile-virtual-assistant .my-profile-content .root .va-side-menu  {
        display: none;
    }
}

@media screen and (max-width:1036px){
    .my-profile-virtual-assistant .header-content { 
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-top: 32px; 
        }
    
.my-profile-virtual-assistant .header-content .user-details { 
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            margin-left: 24px; 
            text-align: center;
         }

         /* .my-profile-virtual-assistant .header-content .user-details .user-name {
             font-size: 24px;  
            } */

            .my-profile-virtual-assistant .my-profile-content .root  .va-destop-tabs{
                display: none;
            }

            .my-profile-virtual-assistant .my-profile-content .root  {
               position: relative;
            }
             .my-profile-virtual-assistant .my-profile-content .va-menu-burger{
                display: block;
                cursor: pointer;
             }

             .my-profile-virtual-assistant .my-profile-content .root .va-side-menu {
                position: absolute;
                left: 0;
                top: 0;
                width: 25%;
                height: 100%;
                background: #EDEDED; 
                z-index: 100;
                border-radius: 12px 0px 0px 12px;
                display:block;
             }

             .my-profile-virtual-assistant .my-profile-content .root .va-backdrop{
                position: absolute;
                left: 0;
                top: 0;
                z-index: 100;
                border-radius: 12px 0px 0px 12px;
                display:block;
            }
            .my-profile-virtual-assistant .my-profile-content .root .va-side-menu .menu-header .hide-menu {
                cursor: pointer;
                float: right;
               }
              
               .my-profile-virtual-assistant .my-profile-content .root .va-side-menu .menu-tabs {
                width: 100%;
       
            }
}

@media screen and (max-width:660px){
    .my-profile-virtual-assistant .my-profile-content .root .va-side-menu {

        width: 50%;
     }

}

@media screen and (max-width:500px){
    .my-profile-virtual-assistant .header-content { 
        display: flex; margin-top: 32px; 
        padding: 0;
        }
}

@media screen and (max-width:450px){
.my-profile-virtual-assistant .changes-button { 
    display: flex; 
    flex-direction:column;
    gap: 32px; 
    margin:24px 40px 0px 40px;
    justify-content: center;
}

}

@media screen and (max-width:400px){
    .my-profile-virtual-assistant .my-profile-content .root {
        margin: 0px 10px;
    }
    .my-profile-virtual-assistant .changes-button .button-view-profile {
        padding: 15px 10px !important;
      
    }

    .my-profile-virtual-assistant .changes-button .button {
        padding: 15px 15px;
    }
}
