.starting-service-details { display: flex; }
.starting-service-details .icon { margin-right: 18px; align-self: flex-start; }
.starting-service-details .current-details .title { margin-bottom: 8px; font-family: Lora; font-style: normal; font-weight: bold; font-size: 24px; line-height: 26px; }
.starting-service-details .current-details .text { margin-bottom: 16px; font-family: Source Sans Pro; font-style: normal; font-weight: normal; font-size: 18px; line-height: 24px; }
.starting-service-details .current-details .button-send { background: #00BCD4; border-radius: 0px 4px 4px 0px; padding: 19px 32px; font-family: Source Sans Pro; font-style: normal; font-weight: bold; font-size: 14px; line-height: 18px; text-transform: uppercase; color: #FFFFFF; }


@media screen and (max-width:500px){
    .starting-service-details {
        flex-direction: column;
        align-items: center;
    }
}