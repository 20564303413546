.seller-lawyer-profile {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  margin: 32px auto;
  width: calc(100% - 64px);
}

.back-to-search-page {
  align-items: center;
  background: #fff;
  border-radius: 50px;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.18);
  display: flex;
  line-height: 1.4;
  width: 103px;
  position: sticky;
  top: 8px;
  z-index: 1000;
  padding: 5px 10px;
  margin: 10px 30px;
}

.back-to-search-page .prev-btn-search {
  cursor: pointer;
  font-size: 40px;
  color: #00bcd4;
  z-index: 999 !important;
  height: 34px;
}

.back-to-search-page .image-back-search {
  width: 43px;
  height: 43px;
}

.seller-lawyer-profile .button-back {
  background: #00bcd4;
  line-height: 1.4;
  margin-left: -15px;
  position: sticky;
  top: 7px;
  width: 110px;
  z-index: 1000;
  color: #ffffff;
  padding: 15px;
  font-size: 14px;
  border-radius: 4px;
  display: block;
}

.lawyer-success-modal .lawyer-success-modal-content {
  padding: 21px 21px;
}

.lawyer-success-modal .lawyer-success-modal-content .title-header .close-icon {
  float: right;
  cursor: pointer;
}

.lawyer-success-modal .lawyer-success-modal-content .lawyer-success-modal-content-inner {
  margin: 0 45px;
  padding-top: 40px;
}

.lawyer-success-modal .lawyer-success-modal-content .lawyer-success-modal-content-inner img {
  display: block;
  margin: 0 auto 15px auto;
}

.lawyer-success-modal .lawyer-success-modal-content .lawyer-success-modal-content-inner .title {
  margin-bottom: 8px;
  font-family: Lora;
  font-style: normal;
  font-size: 48px;
  line-height: 52px;
  text-align: center;
  color: #000000;
}

.lawyer-success-modal .lawyer-success-modal-content .lawyer-success-modal-content-inner .title-sub {
  margin: 0 128px 24px 128px;
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 24px;
  text-align: center;
  color: #000000;
}

.lawyer-success-modal .lawyer-success-modal-content .lawyer-success-modal-content-inner .field {
  width: 100%;
  margin-bottom: 24px;
}

.lawyer-success-modal .lawyer-success-modal-content .lawyer-success-modal-content-inner .actions {
  display: flex;
  gap: 24px;
  justify-content: center;
  margin-bottom: 43px;
}

.lawyer-success-modal .lawyer-success-modal-content .lawyer-success-modal-content-inner .actions .button-send {
  padding: 15px 32px;
  background: #00BCD4;
  border-radius: 4px;
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  text-transform: uppercase;
  color: #FFFFFF;
}

.lawyer-success-modal .lawyer-success-modal-content .lawyer-success-modal-content-inner .actions .button-close {
  padding: 15px 32px;
  border: 1px solid #00BCD4;
  border-radius: 4px;
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  text-transform: uppercase;
  color: #000000;
}

.seller-lawyer-profile {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  margin: 32px auto;
  width: calc(100% - 64px);
}

@media screen and (max-width: 815px) {
  .lawyer-success-modal .lawyer-success-modal-content .lawyer-success-modal-content-inner .title-sub {
      margin: 0 !important;
      
  }

  .lawyer-success-modal .lawyer-success-modal-content .lawyer-success-modal-content-inner .actions .button-close {
      margin-top:20px;
  }
  
}

@media screen and (max-width: 768px) {
  .back-to-search-page {
    margin: 10px 18px;
  }
}

@media screen and (max-width: 600px) { 
  .lawyer-success-modal .lawyer-success-modal-content .lawyer-success-modal-content-inner{
      margin: 0px !important;
  }
}