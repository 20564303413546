.photographer-features {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  margin-bottom: 64px;
  > h3 {
    margin: 0;
    padding: 0;
    font-family: 'Lora', sans-serif;
    font-style: normal;
    font-weight: 1;
    font-size: 48px;
    line-height: 52px;
    text-align: center;
    color: #000000;
    margin-bottom: 16px;
  }
  &-items {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    column-gap: 30px;
    width: 100%;
    > .item {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      background: #f8f8f8;
      border-radius: 8px;
      padding: 20px;
      margin-bottom: 20px;
      > img {
        margin-top: 40px;
        margin-bottom: 15px;
        width: 76px;
        height: 77px;
      }
      > h5 {
        margin: 0;
        padding: 0;
        font-family: 'Lora', sans-serif;
        font-style: normal;
        font-weight: bold;
        font-size: 24px;
        line-height: 26px;
        text-align: center;
        color: #000000;
        margin: 8px 0;
      }
      > p {
        max-width: 374px;
        text-align: center;
        font-family: 'Source Sans Pro';
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        line-height: 24px;
        text-align: center;
        color: #000000;
        margin-bottom: 16px;
      }
      > .options {
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: flex-start;
        margin: 8px 0;

        > img {
          margin-right: 10px;
          width: 20px;
          height: 18px;
        }
      }
      > .feature-prices {
        display: flex;
        margin-top: 8px;
        width: 100%;
        justify-content: center;
        > div {
          background: #ffffff;
          border-radius: 4px;
          padding: 4px 20px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;

          font-family: 'Lora', sans-serif;
          font-style: normal;
          font-weight: bold;
          font-size: 24px;
          line-height: 26px;
          text-align: center;
          color: #6a6a6a;
          padding: 4px 15px;

          > span {
            margin: 0;
            padding: 0;
            font-family: 'Source Sans Pro', sans-serif;
            font-style: normal;
            font-weight: normal;
            font-size: 12px;
            line-height: 16px;
            text-align: center;
            letter-spacing: 0.4px;
            color: #6a6a6a;
          }
        }
      }
    }
  }
}
