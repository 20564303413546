.details { display: flex; }
.details .current-details .information .title { margin-bottom: 8px; font-family: Lora; font-style: normal; font-weight: bold; font-size: 24px; line-height: 26px; text-align: left; }
.details .current-details .information .text { margin-bottom: 16px; font-family: Source Sans Pro; font-style: normal; font-weight: normal; font-size: 18px; line-height: 24px; text-align: left; }
@media screen and (max-width:768px){
  .details {
     display: flex; 
     flex-direction: column;
     gap:10px
  }
}
