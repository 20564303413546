.lawyer-profile-relationship { padding: 24px 24px 16px 24px; }

.lawyer-profile-relationship .header-content .avatar .photo { border-radius: 50%; width: 178px; height: 178px; position: relative; }
.lawyer-profile-relationship .header-content .avatar .photo .icon { position: absolute; bottom: 20px; right: 0; }

.lawyer-profile-relationship .header-content .user-details { margin-left: 16px; float: left; text-align: left; }
.lawyer-profile-relationship .header-content .user-details .title { font-size: 48px; line-height: 52px; margin-bottom: 16px; font-family: Lora; font-weight: 700; }

.lawyer-profile-relationship .header-content .user-details .rating { display: flex; align-items: center; }
.lawyer-profile-relationship .header-content .user-details .rating .title { font-size: 18px; line-height: 22px; font-weight: 900; font-family: Source Sans Pro; margin-right: 8px; text-transform: uppercase; margin-bottom: 0; }
.lawyer-profile-relationship .header-content .user-details .rating .star { width: 16px; height: 16px; margin-right: 3px; filter: invert(44%) sepia(55%) saturate(6704%) hue-rotate(163deg) brightness(99%) contrast(101%); }
.lawyer-profile-relationship .header-content .user-details .rating .star:last-child { margin-right: 0; }
.lawyer-profile-relationship .header-content .user-details .rating .result { font-size: 18px; line-height: 24px; font-weight: 400; font-family: Source Sans Pro; margin-left: 8px; }

.lawyer-profile-relationship .header-content .user-details .additional { margin-top: 14px; display: flex; }
.lawyer-profile-relationship .header-content .user-details .additional .first-half .title { color: #656565; font-size: 16px; line-height: 19px; font-weight: 400; font-family: Roboto; margin-bottom: 6px; }
.lawyer-profile-relationship .header-content .user-details .additional .block { background: #FFFFFF; border-radius: 4px; padding: 16px; }
.lawyer-profile-relationship .header-content .user-details .additional .block .icon { width: 20px; height: 20px; color: #000000; margin-right: 11px; }
.lawyer-profile-relationship .header-content .user-details .additional .block .text { font-size: 18px; line-height: 24px; font-family: Source Sans Pro; font-weight: 700; flex: none; }
.lawyer-profile-relationship .header-content .user-details .additional .second-half { margin-left: 24px; }
.lawyer-profile-relationship .header-content .user-details .additional .second-half .title-content { display: flex; }
.lawyer-profile-relationship .header-content .user-details .additional .second-half .title-content .title { color: #656565; font-size: 16px; line-height: 19px; font-weight: 400; font-family: Roboto; margin-bottom: 6px; }
.lawyer-profile-relationship .header-content .user-details .additional .second-half .title-content .icon { width: 20px; height: 20px; color: #000000; margin-left: 11px; margin-top: -1px; }

.lawyer-profile-relationship .meeting-box { padding: 20px 16px 16px 16px; background: #FFFFFF; border-radius: 4px; text-align: center; }
.lawyer-profile-relationship .meeting-box .title-box { margin-bottom: 15px; text-align: center; }
.lawyer-profile-relationship .meeting-box .title-box .icon { margin-bottom: 8px; }
.lawyer-profile-relationship .meeting-box .title-box .title { font-size: 18px; line-height: 22px; font-family: Source Sans Pro; font-weight: 900; text-transform: uppercase; }
.lawyer-profile-relationship .meeting-box .button { height: 55px; padding: 19px 48px; border-radius: 4px; background: #00BCD4; color: #fff; font-size: 14px; line-height: 18px; font-family: Source Sans Pro; font-weight: 700; text-transform: uppercase; }

.lawyer-profile-relationship .meeting-box-with-history { padding: 16px 16px 24px 16px; background: #FFFFFF; border-radius: 4px; }
.lawyer-profile-relationship .meeting-box-with-history .text-with-icon { display: flex; align-items: center; margin-bottom: 16px; }
.lawyer-profile-relationship .meeting-box-with-history .text-with-icon:first-child { margin-top: 3px; }
.lawyer-profile-relationship .meeting-box-with-history .text-with-icon .icon { margin-right: 10px; margin-top: -2px; width: 18px; height: 20px; }
.lawyer-profile-relationship .meeting-box-with-history .text-with-icon .text { font-size: 18px; line-height: 24px; font-family: Source Sans Pro; font-weight: 400; }
.lawyer-profile-relationship .meeting-box-with-history .button-change { height: 40px; padding: 11px 16px; border-radius: 4px; background: #00BCD4; color: #fff; margin-bottom: 11px; font-size: 14px; line-height: 18px; font-family: Roboto; font-weight: 400; text-transform: uppercase; }
.lawyer-profile-relationship .meeting-box-with-history .button-cancel { height: 40px; width: 100px; padding: 8px; color: #0097A7; font-size: 18px; line-height: 18px; font-family: Source Sans Pro; font-weight: 700; text-transform: uppercase; }
.lawyer-profile-relationship .meeting-box-with-history .button-cancel:hover { background: #fff; }

.lawyer-profile-relationship .chat-area { padding: 16px; background: #EDEDED; border-radius: 12px; position: relative; overflow-y: auto; overflow-x: hidden; }

.lawyer-profile-relationship .chat-area .chat-box { background: #fff; padding: 15px 13px; border-radius: 8px; }
.lawyer-profile-relationship .chat-area .chat-box .field { width: 100%; margin-top: 60px; }
.lawyer-profile-relationship .chat-area .chat-box .field .button { padding: 15px 32px; height: 55px; width: 140px; background-color: #00BCD4; border-radius: 0px 12px 12px 0px; color: #FFFFFF; text-transform: uppercase; font-weight: 700; font-size: 14px; line-height: 18px; font-family: Source Sans Pro; }

.lawyer-profile-relationship .chat-area .chat-box .chat-blob { padding: 13px 15px; text-align: left; background: #F2FEFF; border-radius: 12px 12px 12px 0px; width: 350px; margin-bottom: 16px; }
.lawyer-profile-relationship .chat-area .chat-box .chat-blob.waiting { background: #FAF4F1; }
.lawyer-profile-relationship .chat-area .chat-box .chat-blob.send { background: #9D9D9D; }
.lawyer-profile-relationship .chat-area .chat-box .chat-blob .blob-header { display: flex; align-items: center; margin-bottom: 8px; }
.lawyer-profile-relationship .chat-area .chat-box .chat-blob .blob-header .avatar { width: 24px; height: 24px; border-radius: 50%; }
.lawyer-profile-relationship .chat-area .chat-box .chat-blob .blob-header .title { font-size: 14px; line-height: 18px; font-weight: 700; font-family: Source Sans Pro; margin-left: 10px; text-transform: uppercase; }
.lawyer-profile-relationship .chat-area .chat-box .chat-blob .blob-header .hour { font-size: 12px; line-height: 12px; font-family: Source Sans Pro; font-weight: 400; margin-left: 10px; }
.lawyer-profile-relationship .chat-area .chat-box .chat-blob .text { font-size: 16px; line-height: 18px; font-family: Source Sans Pro; font-weight: 400; }

.lawyer-profile-relationship .chat-area .chat-notifications { background: #fff; padding: 15px 10px; border-radius: 8px; overflow-y: auto; height: 457px; }
.lawyer-profile-relationship .chat-area .chat-notifications .notification-blob { text-align: left; padding: 24px 16px 16px 16px; background: #F2FEFF; border-radius: 12px 12px 12px 0px; margin-bottom: 8px; position: relative; }
.lawyer-profile-relationship .chat-area .chat-notifications .notification-blob .notification-blob-header { display: flex; align-items: center; margin-bottom: 8px; }
.lawyer-profile-relationship .chat-area .chat-notifications .notification-blob .notification-blob-header .icon { position: absolute; top: 0; margin-top: -6px; margin-left: 6px; }
.lawyer-profile-relationship .chat-area .chat-notifications .notification-blob .notification-blob-header .avatar { width: 24px; height: 24px; border-radius: 50%; }
.lawyer-profile-relationship .chat-area .chat-notifications .notification-blob .notification-blob-header .title { font-size: 14px; line-height: 18px; margin-left: 10px; font-family: Source Sans Pro; font-weight: 700; text-transform: uppercase; }
.lawyer-profile-relationship .chat-area .chat-notifications .notification-blob .notification-blob-header .hour { font-size: 12px; line-height: 12px; font-family: Source Sans Pro; font-weight: 400; margin-left: 5px; }
.lawyer-profile-relationship .chat-area .chat-notifications .notification-blob .text { font-size: 12px; line-height: 16px; letter-spacing: 0.4px; font-family: Source Sans Pro; font-weight: 400; }

.lawyer-profile-relationship .history { padding: 24px; background: #fff; border-radius: 12px; margin-top: 32px; text-align: left; }
.lawyer-profile-relationship .history .box-row { margin-bottom: 16px; display: flex; align-items: center; }
.lawyer-profile-relationship .history .box-row:last-child { width: 100%; margin-bottom: 0; }
.lawyer-profile-relationship .history .box-row .date { width: 12%; font-size: 12px; line-height: 12px; font-family: Source Sans Pro; font-weight: 400; margin-top: 4px; }
.lawyer-profile-relationship .history .box-row .small-icon { width: 20px; height: 18px; margin-right: 10px; margin-top: -2px; }
.lawyer-profile-relationship .history .box-row .text { font-size: 18px; line-height: 24px; font-family: Source Sans Pro; font-weight: 400; margin-right: 8px; }
.lawyer-profile-relationship .history .box-row .ad-number { font-size: 18px; line-height: 21px; font-weight: 700; font-family: Roboto; text-decoration: underline; margin-right: 8px; }
.lawyer-profile-relationship .history .box-row .price { font-size: 18px; line-height: 24px; font-family: Source Sans Pro; font-weight: 400; }
/* Scroll */
.lawyer-profile-relationship .chat-area .chat-box::-webkit-scrollbar, .lawyer-profile-relationship .chat-area .chat-notifications::-webkit-scrollbar, .MuiDialog-paper::-webkit-scrollbar { width: 6px !important; background: gray; }
.lawyer-profile-relationship .chat-area .chat-box::-webkit-scrollbar-track, .lawyer-profile-relationship .chat-area .chat-notifications::-webkit-scrollbar-track, .MuiDialog-paper::-webkit-scrollbar-track { background : #fff; border-radius: 12px; }
.lawyer-profile-relationship .chat-area .chat-box::-webkit-scrollbar-thumb, .profile-virtual-assistant-relationship .chat-area .chat-notifications::-webkit-scrollbar-thumb, .MuiDialog-paper::-webkit-scrollbar-thumb { background-color :#000; border-radius: 10px; }