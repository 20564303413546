.content {
    text-align: center;
}

.content .circle {
    margin: 0 auto 16px auto;
    width: 112px;
    height: 112px;
    border-radius: 250px;
}

.content .circle img {
    border-radius: 50%;
    width: 112px;
    height: 112px;
    object-fit: cover;
}

.content .content-title {
    margin-bottom: 12px;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    overflow: hidden;
    line-height: unset;
    /* text-overflow: ellipsis;
    white-space: nowrap; */
}

.content .title>a {
    color: #000000;
}

.content .all-stars {
    margin-bottom: 5px;
}

.content .all-stars .result {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 18px;
    color: #000000 !important;
}