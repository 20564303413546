.invitations-tab { background: #F8F8F8; display: grid; grid-template-columns: repeat(1 1fr); row-gap: 24px; padding: 16px; }

/* Dialog */
.dialog-question { text-align: center; }
.dialog-question-content { margin: 64px 117px; }
.dialog-question-content .close-icon { cursor: pointer; float: right; position: absolute; top: 0; right: 0; margin-top: 20px; margin-right: 21px; }
.dialog-question-content .content img { margin-bottom: 24px; }
.dialog-question-content .content .title { margin-bottom: 8px; font-family: Lora; font-size: 48px; line-height: 52px; }
.dialog-question-content .content .text { font-family: Source Sans Pro; font-style: normal; font-weight: normal; font-size: 18px; line-height: 24px; text-align: center; }
.dialog-question-content .content .actions { margin-top: 24px; display: flex; justify-content: center; column-gap: 24px; }

@media screen and (max-width: 700px) { 
    .dialog-question-content {
        margin: 64px 64px !important;
    }
}

@media screen and (max-width: 600px) { 
    .dialog-question-content .content .title {
        font-size: 32px !important;
        line-height: 36px !important;
    }
}

@media screen and (max-width: 500px) { 
    .dialog-question-content {
        margin: 64px 5px !important;
    }
}
