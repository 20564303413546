/* Edited code */
.vip-profile-virtual-assistant .header-content { display: flex; margin-top: 32px; }
.vip-profile-virtual-assistant .header-content .avatar { position: relative; }
.vip-profile-virtual-assistant .header-content .avatar .profile { position: relative; width: 178px; height: 178px; border-radius: 50%; }
.vip-profile-virtual-assistant .header-content .avatar .profile .photo { width: 178px; height: 178px; }
.vip-profile-virtual-assistant .header-content .avatar .profile .icon { position: absolute; right: 2px; bottom: 15px; width: 29px; }
.vip-profile-virtual-assistant .header-content .avatar .user-id { margin-top: 8px; text-align: center; }
.vip-profile-virtual-assistant .header-content .avatar .user-id .text { font-family: Source Sans Pro; font-style: normal; font-weight: normal; font-size: 12px; line-height: 16px; }

.vip-profile-virtual-assistant .header-content .user-details { margin-left: 24px; }
.vip-profile-virtual-assistant .header-content .user-details .title { font-size: 48px; line-height: 56px; margin-bottom: 8px; }

.vip-profile-virtual-assistant .header-content .user-details .header-title { display: flex; }
.vip-profile-virtual-assistant .header-content .user-details .header-title .button { height: 40px; border-radius: 12px; padding: 12px 24px; text-transform: none; margin-left: 12px; margin-top: 10px;}
.vip-profile-virtual-assistant .header-content .user-details .header-title .icon { margin-left: 10px; }

.vip-profile-virtual-assistant .header-content .user-details .rating { display: flex; align-items: flex-start; }
.vip-profile-virtual-assistant .header-content .user-details .rating .title { margin-right: 12px; font-family: Source Sans Pro; font-style: normal; font-weight: 900; font-size: 18px; line-height: 22px; text-transform: uppercase; }
.vip-profile-virtual-assistant .header-content .user-details .rating .rating-stars { display: flex; padding: 4px; }
.vip-profile-virtual-assistant .header-content .user-details .rating .rating-stars .star { width: 16px; height: 16px; margin-top: 2.5px; filter: invert(48%) sepia(93%) saturate(3629%) hue-rotate(157deg) brightness(90%) contrast(102%); }
.vip-profile-virtual-assistant .header-content .user-details .rating  .rating-results { padding-top: 4px; }
.vip-profile-virtual-assistant .header-content .user-details .rating  .rating-results .result { font-size: 16px; font-weight: 400; line-height: 18px; margin-left: 8px; margin-top: 1px; }

.vip-profile-virtual-assistant .header-content .account-details { margin-left: auto; margin-right: 32px; }
.vip-profile-virtual-assistant .header-content .account-details .button { float: right; font-family: Source Sans Pro; font-style: normal; font-weight: bold; font-size: 14px; line-height: 18px; text-transform: uppercase; color: #0097A7; }

.vip-profile-virtual-assistant .changes-button { display: flex; gap: 32px; margin-top: 46px; justify-content: center; }
.vip-profile-virtual-assistant .changes-button .button-view-profile { padding: 15px 32px; background-color: #00BCD4; border-radius: 4px; color: #FFFFFF; text-transform: uppercase; font-family: Source Sans Pro; font-style: normal; font-weight: bold; font-size: 14px; line-height: 18px; }
.vip-profile-virtual-assistant .changes-button .button-view-profile:hover { background-color: #39e8ff; }
.vip-profile-virtual-assistant .changes-button .button { padding: 15px 32px; border: 2px solid #00BCD4; border-radius: 4px; color: #000000; text-transform: uppercase; font-family: Source Sans Pro; font-style: normal; font-weight: bold; font-size: 14px; line-height: 18px; }

/* New code */
.vip-profile-virtual-assistant .vip-profile-content { margin-top: 32px; }
.vip-profile-virtual-assistant .vip-profile-content .root { flex-grow: 1; background: #F8F8F8; display: flex; border-radius: 12px; margin: 0 32px; }
.vip-profile-virtual-assistant .vip-profile-content .root .tabs { width: 15%; background: #E8E8E8; border-radius: 12px 0px 0px 12px; }
.vip-profile-virtual-assistant .vip-profile-content .root .tabs .tab { border-radius: 12px 0px 0px 12px; text-transform: uppercase; font-size: 14px; line-height: 18px; font-family: Source Sans Pro; font-weight: 700; }
.vip-profile-virtual-assistant .vip-profile-content .root .tabs .tab.disabled { pointer-events: none; opacity: 0.2; } 
.vip-profile-virtual-assistant .vip-profile-content .root .tabs .tab.active-tab { background: #F8F8F8; }
.vip-profile-virtual-assistant .vip-profile-content .root .tabs .tab.last-tab { position: absolute; bottom: 0; width: 100%; }
.vip-profile-virtual-assistant .vip-profile-content .tab-panel { width: 85%; text-align: center; }

.vip-profile-virtual-assistant .vip-profile-content .root .tab-panel .tab-panel-contract { min-height: 300px; }
.vip-profile-virtual-assistant .vip-profile-content .root .tab-panel .tab-panel-contract .contract-list-pane { display: flex; margin: 24px; background: #FFFFFF; box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px rgba(0, 0, 0, 0.12), 0px 1px 3px rgba(0, 0, 0, 0.2); border-radius: 4px; }
.vip-profile-virtual-assistant .vip-profile-content .root .tab-panel .tab-panel-contract .contract-list-pane .contract-details { margin-left: 24px; margin-top: 27px; }
.vip-profile-virtual-assistant .vip-profile-content .root .tab-panel .tab-panel-contract .contract-list-pane .contract-details .contract-title { display: flex; }
.vip-profile-virtual-assistant .vip-profile-content .root .tab-panel .tab-panel-contract .contract-list-pane .contract-details .contract-title .icon { height: 21px; width: 18px; margin-right: 11px; }
.vip-profile-virtual-assistant .vip-profile-content .root .tab-panel .tab-panel-contract .contract-list-pane .contract-details .contract-title .title { font-family: Lora; font-style: normal; font-weight: bold; font-size: 24px; line-height: 26px; }
.vip-profile-virtual-assistant .vip-profile-content .root .tab-panel .tab-panel-contract .contract-list-pane .contract-details .contract-date { margin-top: 8px; text-align: left; font-family: Source Sans Pro; font-style: normal; font-weight: normal; font-size: 12px; line-height: 16px; letter-spacing: 0.4px; }
.vip-profile-virtual-assistant .vip-profile-content .root .tab-panel .tab-panel-contract .contract-list-pane .contract-download { margin-left: auto; margin-top: 29px; }
.vip-profile-virtual-assistant .vip-profile-content .root .tab-panel .tab-panel-contract .contract-list-pane .contract-download .button { padding: 15px 32px; background: #00BCD4; border-radius: 4px; font-family: Source Sans Pro; font-style: normal; font-weight: bold; font-size: 14px; line-height: 18px; text-transform: uppercase; color: #FFFFFF; }
.vip-profile-virtual-assistant .vip-profile-content .root .tab-panel .tab-panel-contract .contract-list-pane .contract-download .button .icon { color: #FFFFFF; margin-right: 8px; }
.vip-profile-virtual-assistant .vip-profile-content .root .tab-panel .tab-panel-contract .contract-list-pane .contract-review { margin: 29px 33px 29px 23px; }
.vip-profile-virtual-assistant .vip-profile-content .root .tab-panel .tab-panel-contract .contract-list-pane .contract-review .button { padding: 15px 32px; border: 2px solid #00BCD4; box-sizing: border-box; border-radius: 4px; font-family: Source Sans Pro; font-style: normal; font-weight: bold; font-size: 14px; line-height: 18px; text-transform: uppercase; color: #000000; }
/* ################################# */

.vip-profile-virtual-assistant .information-content { margin-bottom: 128px; margin-top: 64px; text-align: center; }
.vip-profile-virtual-assistant .information-content .title { margin-bottom: 16px; font-family: Lora; font-size: 32px; line-height: 32px; }
.vip-profile-virtual-assistant .information-content .text { margin: 0px 384px 24px 384px; font-family: Source Sans Pro; font-style: normal; font-weight: normal; font-size: 18px; line-height: 24px; }
.vip-profile-virtual-assistant .information-content .button { padding: 15px 32px; border: 2px solid #00BCD4; box-sizing: border-box; border-radius: 4px; color: #000000; font-family: Source Sans Pro; font-style: normal; font-weight: bold; font-size: 14px; line-height: 18px; text-transform: uppercase; }

/* Deactivate Account Dialog */
.dialog-deactivate-account-virtual-assistant { padding: 22px; text-align: center; }
.dialog-deactivate-account-virtual-assistant .title-header { padding: 0; margin-bottom: 16px; }
.dialog-deactivate-account-virtual-assistant .title-header .title { font-family: Lora; font-style: normal; font-weight: bold; font-size: 32px; line-height: 32px; margin-top: 22px; padding: 0px 140px; }
.dialog-deactivate-account-virtual-assistant .title-header .close-icon { cursor: pointer; float: right; margin-top: -24px; }

.dialog-deactivate-account-virtual-assistant .content { padding: 0; overflow-x: hidden; }
.dialog-deactivate-account-virtual-assistant .content .sub-title { font-family: Source Sans Pro; font-style: normal; font-weight: normal; font-size: 18px; line-height: 24px; margin-bottom: 24px; padding: 0px 100px; }
.dialog-deactivate-account-virtual-assistant .content .actions { margin-top: 24px; }
.dialog-deactivate-account-virtual-assistant .content .actions .button-continue { margin-right: 24px; padding: 15px 32px; background: #F44336; border-radius: 4px; font-family: Source Sans Pro; font-style: normal; font-weight: bold; font-size: 14px; line-height: 18px; text-transform: uppercase; color: #FFFFFF; }
.dialog-deactivate-account-virtual-assistant .content .actions .button-back { padding: 15px 32px; border: 2px solid #00BCD4; box-sizing: border-box; border-radius: 4px; font-family: Source Sans Pro; font-style: normal; font-weight: bold; font-size: 14px; line-height: 18px; text-transform: uppercase; color: #000000; }

/* Place VIP Bid Dialog */
.dialog-vip-bid-virtual-assistant-content { padding: 21px; text-align: center; }
.dialog-vip-bid-virtual-assistant-content .title-header { padding: 0; margin-bottom: 8px; }
.dialog-vip-bid-virtual-assistant-content .title-header .title { font-family: Lora; font-size: 48px; line-height: 52px; margin-top: 29px; }
.dialog-vip-bid-virtual-assistant-content .title-header .close-icon { cursor: pointer; float: right; margin-top: -32px; }

.dialog-vip-bid-virtual-assistant-content .content { padding: 0; overflow-x: hidden; }
.dialog-vip-bid-virtual-assistant-content .content .sub-title { font-family: Source Sans Pro; font-style: normal; font-weight: normal; font-size: 18px; line-height: 24px; margin-bottom: 32px; }
.dialog-vip-bid-virtual-assistant-content .content .amount { font-family: Source Sans Pro; font-style: normal; font-weight: bold; font-size: 20px; line-height: 24px; margin-bottom: 8px; text-transform: uppercase; }
.dialog-vip-bid-virtual-assistant-content .content .price-panel { background: #F8F8F8; border-radius: 4px; padding: 16px; display: inline-block; margin-bottom: 32px;  }
.dialog-vip-bid-virtual-assistant-content .content .price-panel .price { font-family: Source Sans Pro; font-style: normal; font-weight: bold; font-size: 32px; line-height: 24px; }
.dialog-vip-bid-virtual-assistant-content .content .price-panel .price .currency { font-family: Source Sans Pro; font-style: normal; font-weight: 600; font-size: 12px; line-height: 24px; text-transform: uppercase; }
.dialog-vip-bid-virtual-assistant-content .content .recent-bids .recent-bids-title { font-family: Source Sans Pro; font-style: normal; font-weight: bold; font-size: 20px; line-height: 24px; text-transform: uppercase; margin-bottom: 16px; }
.dialog-vip-bid-virtual-assistant-content .content .recent-bids .recent-bids-history { display: flex; justify-content: center; padding: 7px 0px; }
.dialog-vip-bid-virtual-assistant-content .content .recent-bids .recent-bids-history .icon { height: 16px; width: 16px; margin-right: 8px; color: #000000; }
.dialog-vip-bid-virtual-assistant-content .content .recent-bids .recent-bids-history .amount { font-family: Source Sans Pro; font-style: normal; font-weight: normal; font-size: 16px; line-height: 18px; margin-right: 20px; color: #000000; }
.dialog-vip-bid-virtual-assistant-content .content .recent-bids .recent-bids-history .user-info { font-family: Source Sans Pro; font-style: normal; font-weight: 600; font-size: 16px; line-height: 18px; color: #000000; }
.dialog-vip-bid-virtual-assistant-content .content .actions { margin-top: 32px; }
.dialog-vip-bid-virtual-assistant-content .content .actions .button-filled { margin-right: 24px; }
.dialog-vip-bid-virtual-assistant-content .content .actions .button-continue { margin-right: 24px; padding: 15px 32px; background: #F44336; border-radius: 4px; font-family: Source Sans Pro; font-style: normal; font-weight: bold; font-size: 14px; line-height: 18px; text-transform: uppercase; color: #FFFFFF; }
.dialog-vip-bid-virtual-assistant-content .content .actions .button-back { padding: 15px 32px; border: 2px solid #00BCD4; box-sizing: border-box; border-radius: 4px; font-family: Source Sans Pro; font-style: normal; font-weight: bold; font-size: 14px; line-height: 18px; text-transform: uppercase; color: #000000; }

span[class^='PrivateTabIndicator-root-'] {
    background: #F8F8F8;
}
