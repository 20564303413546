.confirm-service-details-seller { display: flex; flex-direction: row; }
.confirm-service-details-seller .icon { margin-right: 18px; align-self: flex-start; }
.confirm-service-details-seller .current-details { display: flex; flex-direction: column;width:100%; }
.confirm-service-details-seller .current-details .left-part .title { margin-bottom: 8px; font-family: Lora; font-style: normal; font-weight: bold; font-size: 24px; line-height: 26px;text-align: left; }
.confirm-service-details-seller .current-details .left-part .text { margin-bottom: 16px; font-family: Source Sans Pro; font-style: normal; font-weight: normal; font-size: 18px; line-height: 24px;text-align: left; }
.confirm-service-details-seller .current-details .right-part {display: flex; flex-direction: row; gap: 20px; margin-top: 5px;width:100%;align-items: flex-start;}
.confirm-service-details-seller .current-details .right-part div {display: flex; flex-direction: column; align-items: flex-start;}

.confirm-service-details-seller .approved {
    padding: 15px 32px;
    background: #00BCD4;
    border-radius: 4px;
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 18px;
    text-transform: uppercase;
    color: #FFFFFF;
    border: 0px;
    cursor: pointer;
}

.confirm-service-details-seller .approved:hover {
    background: #00BCD4;
}

@media screen and (max-width:765px){
    .confirm-service-details-seller {
        flex-direction: column;
    }
}

@media screen and (max-width:530px){
    .confirm-service-details-seller .current-details {
        flex-direction: column;
    }
}