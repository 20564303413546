
.swiper {
  width: 100%;
  height: 100%;
  padding: 5px;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;

  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover !important;
}

.swiper {
  margin-left: auto;
  margin-right: auto;
}