/* Place VIP Bid Dialog */
.dialog-vip-bid-lawyer-content { padding: 21px; text-align: center; }
.dialog-vip-bid-lawyer-content .title-header { padding: 0; margin-bottom: 8px; }
.dialog-vip-bid-lawyer-content .title-header .title { font-family: Lora; font-size: 48px; line-height: 52px; margin-top: 29px; }
.dialog-vip-bid-lawyer-content .title-header .close-icon { cursor: pointer; float: right; margin-top: -32px; }

.dialog-vip-bid-lawyer-content .content { padding: 0; overflow-x: hidden; }
.dialog-vip-bid-lawyer-content .content .sub-title { font-family: Source Sans Pro; font-style: normal; font-weight: normal; font-size: 18px; line-height: 24px; margin-bottom: 32px; }
.dialog-vip-bid-lawyer-content .content .amount { font-family: Source Sans Pro; font-style: normal; font-weight: bold; font-size: 20px; line-height: 24px; margin-bottom: 8px; text-transform: uppercase; }
.dialog-vip-bid-lawyer-content .content .bid-price { border-radius: 4px; display: inline-block; margin-bottom: 32px; text-align: center; }
/* .dialog-vip-bid-lawyer-content .content .bid-price .textField { font-family: Source Sans Pro; font-style: normal; font-weight: bold; font-size: 32px; line-height: 24px; } */
.dialog-vip-bid-lawyer-content .content .recent-bids .recent-bids-title { font-family: Source Sans Pro; font-style: normal; font-weight: bold; font-size: 20px; line-height: 24px; text-transform: uppercase; margin-bottom: 16px; }
.dialog-vip-bid-lawyer-content .content .recent-bids .recent-bids-history { display: flex; justify-content: center; padding: 7px 0px; }
.dialog-vip-bid-lawyer-content .content .recent-bids .recent-bids-history .icon { height: 16px; width: 16px; margin-right: 8px; color: #000000; }
.dialog-vip-bid-lawyer-content .content .recent-bids .recent-bids-history .amount { font-family: Source Sans Pro; font-style: normal; font-weight: normal; font-size: 16px; line-height: 18px; margin-right: 20px; color: #000000; }
.dialog-vip-bid-lawyer-content .content .recent-bids .recent-bids-history .user-info { font-family: Source Sans Pro; font-style: normal; font-weight: 600; font-size: 16px; line-height: 18px; color: #000000; }
.dialog-vip-bid-lawyer-content .content .actions { margin-top: 32px; }
.dialog-vip-bid-lawyer-content .content .actions .button-continue { margin-right: 24px; padding: 15px 32px; background: #F44336; border-radius: 4px; font-family: Source Sans Pro; font-style: normal; font-weight: bold; font-size: 14px; line-height: 18px; text-transform: uppercase; color: #FFFFFF; }
.dialog-vip-bid-lawyer-content .content .actions .button-back { padding: 15px 32px; border: 2px solid #00BCD4; box-sizing: border-box; border-radius: 4px; font-family: Source Sans Pro; font-style: normal; font-weight: bold; font-size: 14px; line-height: 18px; text-transform: uppercase; color: #000000; }
