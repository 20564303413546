.dialog-abort-questionery {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  &-title {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    > button {
      height: 21px;
      width: 21px;
      margin: 21px;
      background-color: transparent;
      border: none;
      cursor: pointer;
      > img {
        width: 14px;
        height: 14px;
      }
    }
  }
  &-content {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding-bottom: 64px;
    width: fit-content;

    > h3 {
      font-family: 'Lora', sans-serif;
      font-style: normal;
      font-weight: 1;
      font-size: 48px;
      line-height: 52px;
      text-align: center;
      color: #000000;
      margin: 0;
      padding: 0;
      margin-bottom: 8px;
    }
    > p {
      max-width: 650px;
      margin: 0;
      padding: 0;
      font-family: 'Source Sans Pro', sans-serif;
      font-style: normal;
      font-weight: normal;
      font-size: 18px;
      line-height: 24px;
      text-align: center;
      color: #000000;
      margin-bottom: 16px;
    }

    > h5 {
      margin: 8px 0;
      padding: 0;
      font-style: normal;
      font-weight: 900;
      font-size: 18px;
      line-height: 22px;
      text-align: center;
      text-transform: uppercase;
    }
    > .rating {
      padding: 0px 13px;
      background: #f8f8f8;
      > img {
        margin: 13.45px 6.89px;
        height: 22px;
        width: 22px;
      }
    }
    > .message {
      font-family: 'Source Sans Pro', sans-serif;
      font-style: normal;
      margin-top: 16px;
      color: #000000;
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      margin-bottom: 16px;
      > h5 {
        font-weight: 900;
        font-size: 18px;
        line-height: 22px;
        margin: 0;
        padding: 0;
        text-transform: uppercase;
        margin-bottom: 8px;
      }
      > textarea {
        padding: 16px 12px;
        font-weight: normal;
        font-size: 18px;
        line-height: 24px;
        width: calc(100% - 32px);
        background: #ededed;
        border-radius: 4px;
        border: none;
        > ::placeholder {
          font-style: normal;
          font-weight: normal;
          font-size: 18px;
          line-height: 24px;
          color: rgba(0, 0, 0, 0.6);
        }
      }
    }
    > .actions {
      display: flex;
      justify-content: center;
      gap: 24px;
      margin: 8px 0px;
      > button {
        padding: 15px 32px;
        border-radius: 4px;
        font-family: Source Sans Pro;
        font-style: normal;
        font-weight: bold;
        font-size: 14px;
        line-height: 18px;
        text-transform: uppercase;
      }
    }
  }
}
