.footer {
    width: 100% !important;
    background: rgba(1, 35, 36, 1);
    position: relative;
    font-weight: 400;
    color: #fff;
    overflow-x: hidden;
    padding-bottom: 30px;
}

.footer-container {
    padding: 60px 60px 20px 60px;
}

.footer .footerImageContent {
    width: 327px;
    height: 143px;
    border-radius: 16px;
    background: #efefef;
}

.footer .imageWithTitle {
    height: "100%";
    display: block;
    color: #000;
}

.footer .image {
    height: 72px;
    width: 72px;
    margin: 35px 40px;
    float: left;
    background-color: #cfcfcf;
}

.footer .title {
    margin: 50px 0;
    float: left;
}

.footer .footerIcons {
    display: block;
    text-align: center;
    margin-bottom: 10px;
}

.footer .buttons {
    text-align: center;
}

.footer .text-follow-us {
    font-size: 30px;
    font-weight: 500;
    line-height: 40px;
    text-align: center;
    margin-bottom: 40px;
}

.footer .footerIcons a {
    background-color: rgba(255, 255, 255, 0.2);
    width: 70px;
    border-radius: 50%;
    padding: 20px 15px;
    margin-right: 20px;
    transition: all .5s;
}

.footer .footerIcons a.facebook-div {
    padding: 20px;
}

.footer .footerIcons .icon {
    width: 35px;
}

.footer .footerIcons .icon.facebook {
    width: 23px;
}

.footer .footerIcons a:hover {
    background-color: rgba(255, 255, 255, 0.3);
}

.footer .paragraph {
    width: 100%;
    font-size: 18px;
    margin: 10px 0 20px 0;
    font-size: 22px;
    font-weight: 500;
    text-align: left;
}

.footer .footerHeaderTitles {
    margin-bottom: 16px;
    font-size: 30px;
    font-weight: 500;
    line-height: 40px;
    text-align: center;
    cursor: pointer;
}

.footer .footerHeaderTitles.firstCol {
    text-align: center;
}

.footer .footerHeaderTitles.thirdCol {
    margin-top: 20px;
    font-size: 40px;
    text-align: left;
}

.footer .cities {
    float: left;
    font-size: 20px;
    font-weight: 400;
    line-height: 40px;
    text-align: center;
    cursor: pointer;
}

.footer .text {
    color: #fff;
    display: block;
    font-size: 25px;
    font-weight: 400;
    line-height: 50px;
    text-align: center;
    cursor: pointer;
}


.footer .text.links {
    color: #fff;
    display: block;
    font-size: 25px;
    font-weight: 400;
    line-height: 50px;
    text-align: center;
    cursor: pointer;
    transition: 300ms;
    text-decoration: underline !important;
    text-decoration-color: transparent !important;
    transition: .5s;
    text-underline-offset: 8px;
}

.footer .text.links:hover {
    text-decoration-color: #ffffff !important;
}

.footer .button {
    font-size: 18px;
    font-weight: 700;
    text-align: center;
    padding: 15px 40px;
    border-radius: 5px;
    color: #fff;
    background-color: #00bcd4;
    text-transform: none;
    margin-bottom: 20px;
}

.footer .button:hover {
    background-color: rgba(0, 188, 212, 0.8);
}

.footer .change_persona_button {
    width: 100%;
    padding: 19px 10px;
    background-color: #00bcd4;
    border-radius: 4px;
    color: #fff;
    margin-top: 40px;
    font-size: 14px;
    line-height: 18px;
    font-weight: 700;
}

.footer .change_persona_button:hover {
    background-color: #00bcd4;
}

.footer .button-notary {
    width: 220px;
    padding: 19px 48px;
    border: 2px solid #00bcd4;
    border-radius: 4px;
    color: #fff;
    margin-top: 32px;
    font-size: 14px;
    line-height: 18px;
    font-weight: 700;
}

@media screen and (max-width: 1258px) {
    .footer .button {
        margin-top: 100px;
    }
}

@media screen and (max-width:1217px) {
    .footer .button {
        padding: 19px 24px;
    }
}

@media screen and (max-width:1199px) {
    .footer .change_persona_button {
        width: 100%;
        padding: 19px 5px;
    }
}

@media screen and (max-width: 991px) {
    .footer .button {
        margin-top: 40px;
        padding: 19px 20px !important;
    }
}


@media screen and (max-width: 768px) {
    .footer .col {
        width: 100%;
        text-align: center;
    }

    .footer .paragraph {
        text-align: center;
    }

    .footer .footerHeaderTitles.firstCol {
        font-size: 30px;
        line-height: 45px;
        letter-spacing: -0.04em;
        text-align: center;
    }

    .footer .cities {
        font-size: 25px;
        line-height: 30px;
        text-align: center;
    }

    .footer .footerHeaderTitles.thirdCol {
        font-size: 30px;
        margin-top: 30px;
        line-height: 45px;
        letter-spacing: -0.04em;
        text-align: center;
    }

    .footer .text {
        font-size: 25px;
        line-height: 30px;
        text-align: center;
    }

    .footer .footerHeaderTitles {
        font-size: 30px;
        line-height: 45px;
        text-align: center;
    }

    .footer .text.links {
        text-align: center;
    }

    .footer .text-follow-us {
        font-size: 25px;
        font-weight: 500;
        line-height: 37.5px;
        margin: 30px 0;
    }

    .container {
        width: 80%;
    }

    .footer .button-group {
        display: flex;
        width: 100%;
    }

    .footer .button.responsive {
        display: inline-block;
        margin-right: 0;
    }

    .footer .button.responsive-virtual-assistant {
        display: inline-block;
        margin: 0 auto;
    }

    .footer-container {
        padding: 20px 0px 0px 0px;
    }
}

@media screen and (max-width: 767px) {
    .container {
        width: 50%;
    }
}

@media screen and (max-width: 600px) {
    .container {
        width: 60%;
    }
}

@media screen and (max-width: 527px) {
    .container {
        width: 70%;
    }

    .footer .footerIcons a:nth-last-child(2) {
        padding: 20px 22px;
    }
}

@media screen and (max-width: 400px) {
    .container {
        width: 80%;
    }

    .footer .text.links {
        padding-left: 0;
    }
}

@media screen and (max-width: 350px) {
    .container {
        width: 90%;
    }

    .footer .icon {
        margin-bottom: 20px;
    }
}


@media screen and (max-width: 320px) {
    .footer .button {
        padding: 19px 20px !important;
    }
}