.registration-protographer { text-align:center; padding: 64px 0 128px 0; }

.registration-protographer .title { margin-bottom: 8px; font-size: 48px; line-height: 58px; font-weight: 700; }
.registration-protographer .title-sub { margin-bottom: 36px; font-size: 16px; line-height: 18px; }

.registration-protographer .registration-form { position: relative; }
.registration-protographer .registration-form .label-text { float: left; margin-bottom: 2px; font-size: 12px; line-height: 14px; }
.registration-protographer .registration-form svg { position: absolute; top: 28px; left: 384px; z-index: 100; }
.registration-protographer .registration-form .textField div input { padding-left: 50px; }
.registration-protographer .registration-form .textField div input::placeholder { font-weight: 700; color: #9D9D9D; font-size: 16px; }

.registration-protographer .registration-form .registration-button { padding: 12px 32px; font-size: 12px; background-color: #000; color: #fff;  border-radius: 12px;}

.registration-protographer .registration-form .document-file-box { margin-bottom: 32px; border: 1px dashed #9D9D9D !important; box-sizing: border-box; border-radius: 8px; }
.registration-protographer .registration-form .document-file-box .button { padding: 24px 50px; font-weight: 500; text-transform: none; border: none !important; }
.registration-protographer .registration-form .document-file-box .button .MuiButton-startIcon { margin: 0; }
.registration-protographer .registration-form .document-file-box .button .button-info .header-content { display: flex; margin-bottom: 32px; }
.registration-protographer .registration-form .document-file-box .button .button-info .header-content .icon { margin-right: 9px; }
.registration-protographer .registration-form .document-file-box .button .button-info .header-content .text { font-size: 20px; line-height: 28px; }
.registration-protographer .registration-form .document-file-box .button .button-info .footer-content {  justify-content: center; }
.registration-protographer .registration-form .document-file-box .button .button-info .footer-content .add-icon { float: left; width: 16px; height: 11px; margin-right: 8px; }
.registration-protographer .registration-form .document-file-box .button .button-info .footer-content .text { float: left; font-size: 12px; line-height: 14px; color: #9D9D9D; }
