.privacyPolicy {
    margin-bottom: 128px;
    font-size: 18px;
    line-height: 32px;
}

.privacyPolicy .banner {
    padding: 22px 60px;
}

.privacyPolicy-container {
    margin: 0 149px
}

.privacyPolicy .title {
    font-size: 25px;
    font-family: Roboto;
    font-weight: 700;
    margin-bottom: 10px;
}

.privacyPolicy .title-sub {
    margin-left: 20px;
    font-size: 20px;
    font-family: Roboto;
    font-weight: 400;
    margin-bottom: 10px;
}

.privacyPolicy .title-sub-sub {
    margin-left: 30px;
    font-size: 20px;
    font-family: Roboto;
    font-weight: 400;
    margin-bottom: 10px;
}

.privacyPolicy .ul {
    margin-left: 60px;
    list-style-type: none;
}

@media screen and (max-width:900px){
    .privacyPolicy-container {
       margin: 0 80px;
       text-indent: 0 !important;
       }
    
  }
  
  @media screen and (max-width:600px){
    .privacyPolicy-container { margin: 0 30px }
    .privacyPolicy .ul { margin-left: 0px;}
  
  }
  
  