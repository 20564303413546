.promotion-plan-container {
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	padding-left: 10%;
	padding-right: 10%;
	margin-top: 30px;
}

.promotion-plan-container .item {
	height: 100%;
	background: #fff;
	border: 3px solid #fff;
	padding: 32px 16px !important;
	box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px rgba(0, 0, 0, 0.12), 0px 1px 3px rgba(0, 0, 0, 0.2);
	border-radius: 8px;
	text-align: center;
	max-width: 31.333333% !important;
}

.promotion-plan-container .item:hover {
	border: 3px solid #e0f7fa;
	box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.14), 0px 1px 18px rgba(0, 0, 0, 0.12), 0px 3px 5px rgba(0, 0, 0, 0.2);
}

.promotion-plan-container .item.item-chosen {
	background: #e0f7fa;
	border: 3px solid #e0f7fa;
	box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.14), 0px 1px 18px rgba(0, 0, 0, 0.12), 0px 3px 5px rgba(0, 0, 0, 0.2);
}

.promotion-plan-container .item.item-special {
	margin-top: -20px;
	padding: 40px 16px !important;
}

.promotion-plan-container .item :last-child {
	margin-right: 0;
}

.promotion-plan-container .item .plan-image {
	margin-bottom: 32px;
}

.promotion-plan-container .item .content .plan-title {
	font-family: "Poppins";
	font-style: normal;
	font-weight: 700;
	font-size: 32px;
	line-height: 32px;
	margin-bottom: 8px;
}

.promotion-plan-container .item .content ul {
	list-style-position: inside;
	font-family: "Poppins";
	font-style: normal;
	font-weight: 400;
	font-size: 18px;
	line-height: 24px;
	margin-bottom: 32px;
}

.promotion-plan-container .item .content ul li {
	margin-bottom: 4px;
}

.promotion-plan-container .item .content ul li img {
	margin-left: 5px;
	margin-top: 3px;
	filter: invert(67%) sepia(99%) saturate(4399%) hue-rotate(152deg) brightness(103%) contrast(103%);
	height: 18px;
}

.promotion-plan-container .item .content .plan-price {
	font-family: "Poppins";
	font-style: normal;
	font-weight: 700;
	font-size: 48px;
	line-height: 52px;
	margin-bottom: 12px;
}

.promotion-plan-container .item .content .plan-checkmark-icon {
	color: #00bcd4;
	font-size: 42px;
}

.promotion-plan-container .item .content .plan-choose-button {
	background: #00bcd4;
	border: 1px solid #00bcd4;
	border-radius: 4px;
	padding: 15px 32px;
	color: #fff;
	font-family: "Poppins";
	font-style: normal;
	font-weight: 700;
	font-size: 14px;
	line-height: 18px;
	text-transform: uppercase;
}

.promotion-plan-container .item .plan-choose-button {
	background: #00bcd4;
	border: 1px solid #00bcd4;
	border-radius: 4px;
	padding: 15px 32px;
	color: #fff;
	font-family: "Poppins";
	font-style: normal;
	font-weight: 700;
	font-size: 14px;
	line-height: 18px;
	text-transform: uppercase;
}

.promotion-plan-container .plan-choose-button {
	margin: 0 auto;
	padding: 12px 32px !important;
	font-family: "Poppins";
	font-style: normal;
	font-weight: bold;
	font-size: 14px;
	line-height: 18px;
	text-transform: uppercase;
	background: #00bcd4;
	color: #fff;
	border-radius: 4px;
}

.promotion-plan-container .plan-choose-button:hover {
	margin: 0 auto;
	padding: 12px 32px !important;
	font-family: "Poppins";
	font-style: normal;
	font-weight: bold;
	font-size: 14px;
	line-height: 18px;
	text-transform: uppercase;
	background: #00bcd4;
	color: #fff;
	border-radius: 4px;
}

.stripe .title-header {
	margin-bottom: 50px;
}

.stripe button {
	white-space: nowrap;
	border: 0;
	outline: 0;
	display: inline-block;
	height: 40px;
	padding: 0 14px;
	color: #fff;
	border-radius: 4px;
	font-size: 15px;
	font-weight: 600;
	background-color: #00bcd4;
	margin-top: 10px;
}

.stripe form {
	margin-bottom: 40px;
	padding-bottom: 40px;
	border-bottom: 3px solid #e6ebf1;
}

.stripe button:hover {
	color: #fff;
	cursor: pointer;
	background-color: #00bcd4;
	transform: translateY(-1px);
}

.stripe .StripeElement {
	display: block;
	margin: 0 auto 20px auto;
	max-width: 500px;
	padding: 10px 14px;
	box-shadow: rgba(50, 50, 93, 0.14902) 0px 1px 3px, rgba(0, 0, 0, 0.0196078) 0px 1px 0px;
	border-radius: 4px;
	background: white;
}

.stripe .StripeElement--focus {
	box-shadow: rgba(50, 50, 93, 0.109804) 0px 4px 6px, rgba(0, 0, 0, 0.0784314) 0px 1px 3px;
	-webkit-transition: all 150ms ease;
	transition: all 150ms ease;
}


@media screen and (max-width:880px){
	.promotion-plan-container {
		display: flex !important;
		flex-direction: column !important;
		gap:50px;
		padding: 48px 25% !important;
	}

	.promotion-plan-container .item {
		height: 100%;
		background: #fff;
		border: 3px solid #fff;
		padding: 32px 16px !important;
		box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px rgba(0, 0, 0, 0.12), 0px 1px 3px rgba(0, 0, 0, 0.2);
		border-radius: 8px;
		text-align: center;
		max-width: 100% !important;
	}	
}

@media screen and (max-width:653px){
	.promotion-plan-container {
		padding: 48px 20% !important;
	}
}
@media screen and (max-width:552px){
	.promotion-plan-container {
		padding: 48px 15% !important;
	}
}

@media screen and (max-width:480px){
	.promotion-plan-container {
		padding: 48px 10% !important;
	}
}

@media screen and (max-width:426px){
	.promotion-plan-container {
		padding: 48px 5% !important;
	}
}

