.profile-photographer {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin: 32px auto;
  width: calc(100% - 64px);
}
/* Using grid boxes */
.profile-photographer .choice-content {
  margin: 64px 0px;
  text-align: center;
}
.profile-photographer .choice-content .title {
  margin-bottom: 16px;
  font-family: Lora;
  font-size: 48px;
  line-height: 52px;
}
/* .profile-photographer .choice-content { display: flex; column-gap: 32px; } */
.profile-photographer .choice-content .grid-item {
  flex: 1;
}
.profile-photographer .choice-content .box {
  padding: 43px 32px 24px 32px;
  background: #f8f8f8;
  border-radius: 8px;
}
.profile-photographer .choice-content .box .icon {
  width: 73px;
  height: 60px;
  margin-bottom: 22px;
}
.profile-photographer .choice-content .box .title {
  margin-bottom: 8px;
  font-family: Lora;
  font-size: 24px;
  line-height: 26px;
}
.profile-photographer .choice-content .box .text {
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 24px;
}
.profile-photographer .choice-content .box .products {
  margin-top: 24px;
  text-align: left;
}
.profile-photographer .choice-content .box .products .item {
  display: flex;
  margin-bottom: 16px;
}
.profile-photographer .choice-content .box .products .item .icon {
  width: 20px;
  height: 20px;
  margin-right: 16px;
}
.profile-photographer .choice-content .box .products .item .description {
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 24px;
}
.profile-photographer .choice-content .box .options {
  display: flex;
  column-gap: 12px;
}
.profile-photographer .choice-content .box .options .item {
  padding: 4px 24px;
  background: #ffffff;
  border-radius: 4px;
}
.profile-photographer .choice-content .box .options .item .price {
  font-family: Lora;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 26px;
  color: #6a6a6a;
}
.profile-photographer .choice-content .box .options .item .details {
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.4px;
  color: #6a6a6a;
}

.profile-photographer .history-content-wrapper {
  margin-bottom: 64px;
}
.profile-photographer .history-content-wrapper .title {
  margin-bottom: 32px;
  font-family: Lora;
  font-style: normal;
  font-weight: 1;
  font-size: 48px;
  line-height: 52px;
  text-align: center;
  color: #000000;
}
.profile-photographer .history-content {
  display: flex;
}
.profile-photographer .history-content .box {
  padding: 24px;
  background: #f8f8f8;
  border-radius: 12px;
}
.profile-photographer .history-content .box .box-row {
  width: 100%;
  margin-bottom: 18px;
  display: inline-block;
}
.profile-photographer .history-content .box .box-row:last-child {
  width: 100%;
  margin-bottom: 0;
}
.profile-photographer .history-content .box .box-row .date {
  width: 15%;
  float: left;
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 12px;
  color: #000000;
}
.profile-photographer .history-content .box .box-row .small-icon {
  width: 20px;
  height: 18px;
  margin-right: 10px;
  float: left;
}
.profile-photographer .history-content .box .box-row .text {
  margin-right: 8px;
  float: left;
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 24px;
  color: #000000;
}
.profile-photographer .history-content .box .box-row .ad-text {
  font-size: 18px;
  line-height: 21px;
  font-weight: 400;
  margin: 0px 4px;
}
.profile-photographer .history-content .box .box-row .ad-number {
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 21px;
  text-decoration-line: none;
  color: #000000;
}
.profile-photographer .history-content .history-button {
  flex: 1;
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  text-transform: uppercase;
  color: #0097a7;
}

.profile-photographer .portfolio-content {
  margin-bottom: 64px;
  display: flex;
  flex-direction: column;
  width: 100%;
}
.profile-photographer .portfolio-content .title {
  margin-bottom: 32px;
  font-family: Lora;
  font-style: normal;
  font-weight: normal;
  font-size: 48px;
  line-height: 52px;
  text-align: center;
}
.profile-photographer .portfolio-content .box {
  padding: 32px 32px 14px 32px;
  background: #f8f8f8;
  border-radius: 10px;
  margin-bottom: 32px;
}
.profile-photographer .portfolio-content .box .title-with-icon {
  margin-bottom: 8px;
}
.profile-photographer .portfolio-content .box .title-with-icon .small-icon {
  width: 26px;
  height: 24px;
  margin-right: 10px;
  float: left;
}
.profile-photographer .portfolio-content .box .title-with-icon .name {
  font-family: Lora;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 26px;
}
.profile-photographer .portfolio-content .box .text {
  margin-bottom: 24px;
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 24px;
}
.profile-photographer .portfolio-content .box .gallery-images-grid {
  width: 100%;
  height: 250px;
  border-radius: 4px;
  margin-bottom: 8px;
  object-fit: cover;
}

.profile-photographer .portfolio-content .box .gallery-images-grid.primary {
  width: 100%;
  height: 500px;
  border-radius: 12px;
}

.profile-photographer .about-me-content {
  margin: 64px 0;
}
.profile-photographer .about-me-content .title {
  margin-bottom: 32px;
  font-family: Lora;
  font-style: normal;
  font-weight: 1;
  font-size: 48px;
  line-height: 52px;
  text-align: center;
  color: #000000;
}
.profile-photographer .about-me-content .box {
  padding: 27px 24px 24px 24px;
  background: #ffffff;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px rgba(0, 0, 0, 0.12),
    0px 1px 3px rgba(0, 0, 0, 0.2);
  border-radius: 12px;
}
.profile-photographer .about-me-content .box .box-header .ellipse {
  width: 24px;
  height: 24px;
  float: left;
  margin-right: 4px;
}
.profile-photographer .about-me-content .box .box-header .info {
  float: left;
  margin-bottom: 16px;
}
.profile-photographer .about-me-content .box .box-header .info .name {
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: 900;
  font-size: 18px;
  line-height: 22px;
  text-align: right;
  text-transform: uppercase;
}
.profile-photographer .about-me-content .box .box-header .info .paragraph {
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  text-align: left;
  letter-spacing: 0.4px;
  color: #000000;
}
.profile-photographer .about-me-content .box .box-header .rating {
  margin-bottom: 10px;
  filter: invert(48%) sepia(93%) saturate(3629%) hue-rotate(157deg)
    brightness(90%) contrast(102%);
}
.profile-photographer .about-me-content .box .box-header .text {
  display: block;
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 24px;
  color: #000000;
}

.profile-photographer .navigation-content {
  margin-top: 32px;
  text-align: center;
}
.profile-photographer .navigation-content .button-edit-profile {
  margin-left: 32px;
  padding: 15px 32px;
  background-color: #00bcd4;
  border-radius: 4px;
  color: #ffffff;
  margin-bottom: 64px;
  text-transform: uppercase;
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
}
.profile-photographer .navigation-content .button-edit-profile:hover {
  background-color: #00bcd4;
}

.profile-photographer .gallery-img-photographer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-evenly;
}

@media screen and (max-width: 1321px) {
  .profile-photographer .lawyer-profile-header-content .about-info {
    width: 380px;
  }
}

@media screen and (max-width: 1321px) {
  .profile-photographer .lawyer-profile-header-content .about-info {
    width: 330px;
  }
}

@media screen and (max-width: 1200px) {
  .profile-photographer .lawyer-profile-header-content {
    flex-direction: column;
    gap: 20px;
  }

  .profile-photographer .lawyer-profile-header-content .user-details {
    margin-left: 0px;
    width: 500px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .profile-photographer .lawyer-profile-header-content .about-info {
    margin-left: 0px;
    margin-right: 0px;
    width: 500px;
  }

  .profile-photographer .lawyer-profile-header-content .avatar {
    margin: 0 auto;
    margin-bottom: 20px;
  }
}

@media screen and (max-width: 1000px) {
  .profile-photographer .portfolio-content .cols {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 40px;
  }
  .profile-photographer .portfolio-content .col3of12 {
    width: 100% !important;
  }
  .profile-photographer .portfolio-content .cols::before {
    display: none !important;
  }
  .profile-photographer .gallery-img-photographer .col {
    float: none !important;
  }
}

@media screen and (max-width: 767px) {
  .profile-photographer .portfolio-content .cols {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media screen and (max-width: 600px) {
  .profile-photographer {
    width: calc(100% - 30px);
  }
}

@media screen and (max-width: 530px) {
  .profile-photographer .portfolio-content .cols {
    grid-template-columns: repeat(1, 1fr);
  }
  .profile-photographer .lawyer-profile-header-content .about-info {
    width: 100%;
  }

  .profile-photographer .lawyer-profile-header-content .user-details {
    width: 100%;
    margin-top: 60px;
  }
}
