.payment-for-service { padding: 16px; background: #F2FEFF; border-radius: 12px; text-align: left; margin-bottom: 32px; }
.payment-for-service .details { display: flex; background: #F2FEFF; border-radius: 12px; }
.payment-for-service .details.last { margin-top: 24px; }
.payment-for-service .details .icon { margin-right: 18px; align-self: flex-start; }
.payment-for-service .details .current-details .title { font-family: Lora; font-style: normal; font-weight: bold; font-size: 24px; line-height: 26px; }
.payment-for-service .details .current-details .text { margin-top: 8px; font-family: Source Sans Pro; font-style: normal; font-weight: normal; font-size: 18px; line-height: 24px; }
.payment-for-service .details .current-details .price { margin-top: 16px; padding: 4px 8px; background: #E0F7FA; border-radius: 4px; font-family: Source Sans Pro; font-style: normal; font-weight: bold; font-size: 36px; line-height: 45px; text-transform: uppercase; }
.payment-for-service .details .current-details .price .currency { font-family: Source Sans Pro; font-style: normal; font-weight: normal; font-size: 18px; line-height: 24px; }
.payment-for-service .details .current-details .button-payment { margin-top: 16px; margin-bottom: 9px; background: #00BCD4; border-radius: 4px; padding: 15px 32px; font-family: Source Sans Pro; font-style: normal; font-weight: bold; font-size: 14px; line-height: 18px; text-transform: uppercase; color: #FFFFFF; }

.details .requested-services .service-title { font-family: Lora; font-style: normal; font-weight: bold; font-size: 24px; line-height: 26px; text-align: left; }
.details .requested-services .service-boxes { display: grid; grid-template-columns: repeat(3, 1fr); margin-top: 16px; gap: 32px; text-align: center; }
.details .requested-services .service-boxes .service-box { display: flex; flex-direction: column; align-items: flex-start; padding: 24px 24px 48px 24px; background: #FFFFFF; border-radius: 12px; text-align: center; }
.details .requested-services .service-boxes .service-box .icon { margin-bottom: 8px; }
.details .requested-services .service-boxes .service-box .text { margin-bottom: 8px; font-family: Source Sans Pro; font-style: normal; font-weight: bold; font-size: 20px; line-height: 24px; text-transform: uppercase; text-align: left; }
.details .requested-services .service-boxes .service-box .price { padding: 8px; font-family: Source Sans Pro; font-style: normal; font-weight: bold; font-size: 48px; line-height: 44px; }
.details .requested-services .service-boxes .service-box .price .currency { font-family: Source Sans Pro; font-style: normal; font-weight: 900; font-size: 18px; line-height: 22px; text-transform: uppercase; }
