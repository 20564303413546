.mobile-offers-container {
  padding: 0px 100px;
  margin-top: 50px;
  display: block;
}
.mobile-offers-container .mobile-offer-price {
  font-weight: 700;
  font-size: 20px;
}
.mobile-offers .cols-full {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

@media screen and (max-width: 840px) {
  .mobile-offers-container {
    display: block;
  }

  .mobile-offers-container {
    margin-top: 30px;
  }
  
  .mobile-offers-container {
    background: rgba(176, 176, 176, 0.15);
    padding: 20px 0;
  }
  
  .mobile-offers-container .title {
    font-size: 30px;
    font-weight: 700;
    line-height: 36.31px;
    text-align: center;
    color: rgba(0, 188, 212, 1);
    padding: 15px 0;
  }
  
  .mobile-offers-container hr {
    height: 2px;
    width: 100%;
    color: rgba(0, 188, 212, 1);
    margin-bottom: 20px;
    margin-top: 0;
  }
  
  .mobile-offers-container hr.table {
    color: rgba(0, 0, 0, 0.3)
  }
  
  .mobile-offers-container .table-header {
    background: rgba(255, 255, 255, 1);
    border-radius: 20px;
    padding: 10px 0;
    margin: 0 auto 10px auto;
    width: 98%;
  }
  

  .mobile-offers-container .table-header .title-table {
    margin-bottom: 0;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  }
  
  
  .mobile-offers-container .title-table {
    font-size: 25px;
    font-weight: 900;
    line-height: 37.5px;
    text-align: center;
    margin-bottom: 15px;
  }
  
  .mobile-offers-container .title-table.hr-table {
    border-right: 0.3px solid rgba(0, 0, 0, 0.3)
  }
  
  .mobile-offers-container .price {
    font-size: 20px;
    font-weight: 700;
    line-height: 24px;
    text-align: center;
    color: rgba(0, 128, 0, 1);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .mobile-offers-container .time {
    font-size: 25px;
    font-weight: 400;
    line-height: 24px;
    text-align: center;
    color: rgba(0, 0, 0, 1);
    margin-bottom: 10px;
  }

  .seach-property-details .info .list-of-preliminary-offers-buyer .will-pay .will-pay-author p {
    font-size: 10px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
    margin-bottom: 0;
    text-transform: none;
  }

  .seach-property-details .info .list-of-preliminary-offers-buyer .feedback p {
    padding: 0;
  }

  .seach-property-details .info .list-of-preliminary-offers-buyer .will-pay .will-pay-author p {
    display: block;
  }

  .mobile-offers-container .feedback p {
    font-size: 12px !important;
    font-weight: 400 !important;
    line-height: 16px !important;
    text-align: left !important;
  }
  
  .mobile-offers-container .feedback .no-action {
    color: #f57f17 !important;
  }

  .mobile-offers-container .feedback .cancel {
    color: #b71c1c !important;
  }

  .mobile-offers-container .feedback .accepted {
    color:#1b5e20 !important;
  }

  .mobile-offers-container .feedback .in-process-of-appointment {
    color: #1b5e20 !important;
  }

  .mobile-offers-container .feedback .deposit-is-paid {
    color: #1b5e20 !important;
  }

  .mobile-offers-container .feedback .contract-is-signed {
    color: #1b5e20 !important;
  }

  .mobile-offers-container .container-offer {
    width: 98%;
    margin: 0 7px;
  }

  .seach-property-details .info .mobile-offers-container .container-offer .will-pay .will-pay-author .author-name {
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    text-align: left;
    margin-bottom: 0;
  }

  .seach-property-details .info .mobile-offers-container .container-offer .will-pay .will-pay-author .will-pay-method p {
    font-size: 12px;
    font-weight: 400;
    line-height: 10px;
    text-align: left;
    line-height: 20px;
    text-transform: none;
  }
}

@media screen and (max-width: 600px) {
  .mobile-offers-container {
    padding: 0px 40px;
  }
}

@media screen and (max-width: 600px) {
  .mobile-offers-container {
    padding: 10px 10px;
  }

  .seach-property-details .info .mobile-offers-container .container-offer .will-pay .will-pay-author img.author-img {
    width: 40px;
    height: 40px;
  }
}