.accent {
    padding: 37px 50px;
    background-color: #F4F4F4;
    text-align: center;
    margin-bottom: 128px;
}

.accent .title {
    margin-bottom: 16px;
    font-weight: bold;
    font-size: 32px;
    line-height: 32px;
    text-align: center;
    font-family: "Graphik";
    font-style: normal;
    letter-spacing: var(--letter-spacing-paragraph);
}

.accent .text {
    margin: 0;
    text-align: center;
    font-family: "Graphik Regular";
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    letter-spacing: var(--letter-spacing-paragraph);
    line-height: 24px;
}

.accent .button {
    display: inline-block;
    margin-top: 24px;
    padding: 12px 32px;
    background: #0097A7;
    color: #fff;
    font-size: 12px;
    line-height: 14px;
    border-radius: 4px;
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
    text-transform: uppercase;
}



@media screen and (max-width:768px){
 .accent .cols-full {
  display: flex;
  flex-direction: column;
  gap:20px
 }
 .accent .cols-full .col4of12 {
   width:100%;
   }

.accent .title {
    text-align: left;
}

.accent .text {
    text-align: left;
}
}

@media screen and (max-width:600px) {
    .howToBuyProperty .why-abidors .title {
        font-size: 40px !important;
    }
}

@media screen and (max-width:400px) {
    .accent {
        padding: 37px 20px;
    }
}