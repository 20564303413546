.App {
    text-align: center;
    padding: 20px;
  }
  
  .open-modal-btn {
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
    background-color: #4CAF50;
    color: white;
    border: none;
    border-radius: 5px;
  }
  
  .test {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 0;
    overflow: hidden;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: flex-end;
    z-index: 1000;
  }

  .test-content::-webkit-scrollbar {
    width: 10px !important;
    height: 10px !important;
    background: transparent;
}

.test-content::-webkit-scrollbar-track {
    background: #fff;
    border-radius: 12px;
    margin-top: 15px;
}

.test-content::-webkit-scrollbar-thumb {
    background-color: #00BCD4;
    border-radius: 25px;
}
  
  /* Показване на модала */
  .test-show {
    height: 100%; /* Показва модала */
  }
  
.test-content {
    height: 82%;
    overflow-y: scroll;
    background-color: white;
    padding: 20px;
    border-radius: 30px 30px 0 0;
    width: 100%;
    max-width: 500px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    margin-bottom: 100px;
}

.test-content.location {
    height: 55%;
    overflow-y: scroll;
    background-color: white;
    padding: 20px;
    border-radius: 30px 30px 0 0;
    width: 100%;
    max-width: 500px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    margin-bottom: 100px;
}

.test-content.neighborhood {
    height: 55%;
    overflow-y: scroll;
    background-color: white;
    padding: 20px;
    border-radius: 30px 30px 0 0;
    width: 100%;
    max-width: 500px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    margin-bottom: 100px;
}

.test-content.sort-by {
    height: 66%;
    overflow-y: scroll;
    background-color: white;
    padding: 20px;
    border-radius: 30px 30px 0 0;
    width: 100%;
    max-width: 500px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    margin-bottom: 0;
}

.test-content.priceRange {
    height: 55%;
    overflow-y: scroll;
    background-color: white;
    padding: 20px;
    border-radius: 30px 30px 0 0;
    width: 100%;
    max-width: 500px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    margin-bottom: 0;
}

.test-content.year {
    height: 28%;
    overflow-y: scroll;
    background-color: white;
    padding: 20px;
    border-radius: 30px 30px 0 0;
    width: 100%;
    max-width: 500px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    margin-bottom: 100px;
}

.test-content.area {
    height: 28%;
    overflow-y: scroll;
    background-color: white;
    padding: 20px;
    border-radius: 30px 30px 0 0;
    width: 100%;
    max-width: 500px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    margin-bottom: 100px;
}
  
  .close-btn {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 20px;
    cursor: pointer;
    background-color: transparent;
    border: none;
  }

.test .test-content .listItem {
    cursor: pointer;
    padding: 0;
}

.mobile-filters-search-page {
    background-color: #FFFFFF;
    position: fixed;
    overflow-x: scroll;
    position: fixed;
    top: 77px;
    height: 50px;
    z-index: 989;
    width: 100%;
    display: flex;
    align-items: center;
    padding-left: 18px;
    padding-bottom: 12px;
}

.mobile-filters-search-page::-webkit-scrollbar {
    display: none;
}

.test .test-content .listItem .number {
    position: absolute;
    background-color: #00BCD4;
    color: #ffffff;
    top: 0;
    right: -15px;
    font-size: 11px;
    border-radius: 50px;
    width: 16px;
    height: 16px;
    text-align: center;
}

.test .test-content .MuiListItem-root.listItem {
    justify-content: space-between !important;   
}

.test .test-content .listItem .title-container {
    position: relative;
}

.test .test-content .listItem .title-container .title {
    font-size: 20px;
    font-weight: 500;
    line-height: 40px;
    text-align: left;
    margin-bottom: 0;
}

.mobile-filters-search-page .test .test-content .checkbox-with-label .MuiFormControlLabel-label {
    color: #000000;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.mobile-filters-search-page .test .test-content .checkbox-with-label .MuiCheckbox-root {
    color:  rgba(0, 0, 0, 0.3)
}

.test .test-content .checkbox-with-label .MuiCheckbox-colorPrimary.Mui-checked {
    color: #00BCD4
}

.test .test-content .checkbox-list {
    height: 110px;
    overflow: hidden;
    transition: height 0.5s ease-out;
    padding-left: 2px;
}

.test .test-content .checkbox-list.show-all-near {
    height: 91%;
}

.test .test-content .checkbox-list.show-all-heating {
    height: 80%;
}

.test .test-content .checkbox-list.show-all-property-type {
    height: 96%;
}

.test .test-content .checkbox-list.show-all-construction-type {
    height: 80%;
}

.test .test-content .checkbox-list.show-all-construction-stage {
    height: 100%;
}

.test .test-content .show-text {
    font-size: 15px;
    font-weight: 400;
    line-height: 33px;
    text-align: left;
    cursor: pointer;
    color: rgba(0, 188, 212, 1);
    margin-bottom: 0;
}

.test .test-content .hr {
    border: 1.5px solid rgba(210, 210, 210, 1);
    margin: 10px 0 10px 10px;
}

.mobile-filters-search-page .item-filters {
    background-color: #F4F4F4;
    border-radius: 36px;
    padding: 10px 15px;
    display: flex;
    width: max-content;
    max-width: 170px;
}

.mobile-filters-search-page .item-filters.selected {
    background-color: rgba(0, 188, 212, 0.8);
}

.mobile-filters-search-page .item-filters.selected .title-filters {
    color: #ffffff;
}

.mobile-filters-search-page .item-filters.selected .arrow {
    filter: brightness(100) saturate(100%)
}

.mobile-filters-search-page .item-filters:not(:first-child) {
    padding: 10px 25px 10px 15px;
}

.all-filters {
    border-right: 1px solid rgba(234, 234, 234, 1);
    padding-right: 10px;
}

.mobile-filters-search-page .item-filters .icon {
    margin-right: 5px;
}

.mobile-filters-search-page .item-filters .title-filters {
    font-size: 12px;
    font-weight: 300;
    line-height: 14.06px;
    text-align: left;
    color: #000000;
    margin-bottom: 0;
    margin-right: 5px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    text-align: left;
}

.mobile-filters-search-page .item-filters .arrow {
    width: 12px;
    transform: rotate(180deg);
    filter: brightness(0) saturate(100%);
}

.test .actions {
    background: rgba(255, 255, 255, 1);
    width: 100%;
    height: 105px;
    padding: 14.5px 21px 14.5px 22px;
    position: absolute;
    box-shadow: 0px -1px 20px 0px rgba(0, 0, 0, 0.15);
    bottom: 0;
}

.test .actions .text {
    font-size: 15px;
    font-weight: 300;
    text-align: center;
    color: #000000;
    border-bottom: 1px solid rgba(0, 0, 0, 1);
    margin: 0 auto 10px auto;
    width: 110px;
    cursor: pointer;
}

.test .actions .text.clear {
    width: 60px;
}

.test .actions .view-result {
    background: rgba(0, 188, 212, 1);
    color: #ffffff;
    border-radius: 36px;
    padding: 8px 0;
    font-size: 16px;
    font-weight: 500;
    line-height: 23.44px;
    letter-spacing: 1.0800000429153442px;
    text-align: center;
    text-transform: none;
    width: 100%;
}

.test-content .title-modal-filter {
    font-size: 20px;
    font-weight: 500;
    line-height: 30px;
    letter-spacing: 1.0800000429153442px;
    text-align: center;
    color: #000000;
    margin-top: 20px;
}

.test-content .dropdown-container-location .search {
    float: left;
    position: relative;
    margin-top: 4px;
    background-color: rgba(244, 244, 244, 1);
    border-radius: 50px;
    width: 100%;
    height: 44px;
    cursor: pointer;
    padding: 5px 16px;
}

.test-content .dropdown-container-location .search-icon {
    float: left;
    margin-top: 4px;
    width: 26px;
    height: 26px;
    filter: invert(49%) sepia(0%) saturate(1%) hue-rotate(100deg) brightness(97%) contrast(89%);
}

.test-content .dropdown-container-location .search-field {
    float: left;
    padding: 16px 10px !important;
    height: 28px;
    border: none;
    background-color: transparent;
    width: 82% !important;
    margin-bottom: 10px;
}

.test-content .dropdown-container-location .search-field::placeholder {
    font-weight: 400;
    font-size: 18px;
}

.test-content .dropdown-container-location .search.focus-input-filed {
    outline: 2px solid #00BCD4;
}

.test-content .dropdown-container-location .search .MuiFormControl-marginNormal {
    margin: 0
}

.test-content .dropdown-container-location .search .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] {
    padding: 0;
    background-color: #EDEDED;
}

.mobile-filters-search-page {
    overflow-x: auto;
}

.test-content .prices {
    color: #505050;
    font-size: 13px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    letter-spacing: 1.08px;
    text-transform: capitalize;
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
    align-items: center;
}

.test-content .prices input,
.test-content .price-view input {
    background-color: transparent;
}

.test-content .prices .price-left,
.test-content .prices .price-center,
.test-content .prices .price-right,
.test-content .prices .price-left input,
.test-content .prices .price-center input,
.test-content .prices .price-right input {
    width: 70px;
    margin-bottom: 0;
}

.test-content .change-live-price {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.test-content .change-live-price .price-view {
    width: 100px;
    font-size: 18px;
    letter-spacing: 1.0800000429153442px;
    text-align: left;
    color: rgba(0, 0, 0, 1);
    margin-bottom: 0;
}

.test-content .change-live-price .price-view input {
    width: 107px;
    font-weight: 500;
}

.test-content .change-live-price .price-view:nth-last-child(3),
.test-content .change-live-price .price-view:nth-last-child(3) input {
    width: 100;
    text-align: center;
}



.test-content .area-title,
.test-content .year-title {
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 1.08px;
    text-align: center;
    color: rgba(0, 0, 0, 1);
    padding-top: 30px;
}

.test-content .area,
.test-content .year {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 20px;
}

.test-content .area input[type=number],
.test-content .year input[type=number] {
    border: 2px solid rgba(203, 203, 203, 1);
    border-radius: 10px;
    padding: 10px;
    text-align: center;
}

.test-content .area input[type=number]::-webkit-inner-spin-button,
.test-content .year input[type=number]::-webkit-inner-spin-button {
    -webkit-appearance: none; 
    margin: 0; 
}

.test-content {
    padding: 10px;
}

.reset-all {
    font-size: 16px;
    font-weight: 300;
    letter-spacing: 1.0800000429153442px;
    text-align: center;
    cursor: pointer;
} 

.test-content.priceRange {
    padding: 0 25px;
}

.reset-all p {
    color: rgba(0, 0, 0, 1);
    margin-bottom: 0;
    border-bottom: 2px solid rgba(0, 0, 0, 1);
    margin-right: 10px;
}


.mobile-filter-container{
    padding:30px 0;
    
}
.mobile-filter-container .filter-modal{
    padding:0 20px;
}
.mobile-filter-container .filter-modal .filter-modal-header {
    width: 100%;
    height: 50px;
    background: #00BCD4;
    display: flex;
    justify-content: right;
    align-items: center;
    padding-right: 10px;

}

.mobile-filter-container .filter-modal .mobile-close-modal{
    color:#FFFFFF;
    font-size: 30px;
    margin-top: -10px;
    cursor: pointer;
}


.mobile-filter-container .filter-modal .mobile-filters {
    background-color: #fff;
    height: 100%;
    overflow-y: scroll;
}

 .mobile-filter-container .filter-modal .mobile-filters .headerSearch {
    padding: 16px 32px;
    background-color: #FFFFFF;
    box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.14), 0px 1px 18px rgba(0, 0, 0, 0.12), 0px 3px 5px rgba(0, 0, 0, 0.2);
    display: flex;
    flex-direction:column;
    justify-content: center;
    align-items: center;
    gap:20px;
   
}

.mobile-filter-container .filter-modal .mobile-filters .headerSearch .item {
    margin-right: 10px;
    width: 400px !important;
}
.mobile-filter-container .filter-modal .mobile-filters .headerSearch .item .autocomplete {
    background: #EDEDED;
    border-radius: 4px;
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    color: #000000;
    width: 100%;
}
.mobile-filter-container .filter-modal .mobile-filters .headerSearch .item.autocomplete.neighborhood {
    width: 250px;
    height: 56px;
    color: rgba(0, 0, 0, 0.6);
}
.mobile-filter-container .filter-modal .mobile-filters .headerSearch .item.autocomplete.propertyType {
    width: 250px;
    height: 56px;
    color: rgba(0, 0, 0, 0.6);
}

.mobile-filter-container .filter-modal .mobile-filters .headerSearch .item .label {
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 12px;
    color: rgba(0, 0, 0, 0.6);
}

.mobile-filter-container .filter-modal .mobile-filters .headerSearch .item .prices .price-left {
    float: left;
}

.mobile-filter-container .filter-modal .mobile-filters .headerSearch .item .prices .price-left input {
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 12px;
    color: rgb(0, 0, 0);
}

.mobile-filter-container .filter-modal .headerSearch .item .prices .price-right {
    float: right;
}
.mobile-filter-container .filter-modal .headerSearch .item .prices .price-right input {
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 12px;
    color: rgb(0, 0, 0);
}
.mobile-filter-container .filter-modal .mobile-filters .headerSearch .item .textField {
    margin: 0 !important;
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    color: #000000;
}
.mobile-filter-container .filter-modal .mobile-filters .headerSearch .item .textField input {
    font-style: normal;
    border: none !important;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    color: #000;
}

.mobile-filter-container .filter-modal .mobile-filters .headerSearch .item .MuiSvgIcon-root {
    color: #0097A7;
}

.mobile-filter-container .filter-modal .mobile-filters .headerSearch .item.autocomplete-cities {
    width: 250px;
    height: 58px !important;
    z-index: 10000;
}

.mobile-filter-container .filter-modal .mobile-filters .headerSearch .item.autocomplete-neigbhoor {
    width: 250px;
}

.mobile-filter-container .filter-modal .mobile-filters .headerSearch .item.autocomplete-kind {
    width: 200px;
}

.mobile-filter-container .filter-modal .mobile-filters .headerSearch .item.squaring {
    width: 250px;
}

.mobile-filter-container .filter-modal .mobile-filters .headerSearch .item.squaring .textField {
    background: #EDEDED;
    border-radius: 4px;
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    color: #000000;
}


.mobile-filter-container .filter-modal .mobile-filters .headerSearch .item.squaring .center-column {
    margin-top: 5px;
    font-size: 30px;
    text-align: center;
}

.mobile-filter-container .filter-modal .mobile-filters .headerSearch .item.budget {
    width: 200px;
    margin: 0 20px;
}

.mobile-filter-container .filter-modal .mobile-filters .headerSearch .item.budget .slider {
    position: relative;
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 24px;
    text-align: center;
    color: #000000;
}

.mobile-filter-container .filter-modal .mobile-filters .headerSearch .item.budget .label {
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 12px;
    color: rgba(0, 0, 0, 0.6);
}

.mobile-filter-container .filter-modal .mobile-filters .headerSearch .item.more .button-more {
    position: relative;
    z-index: 10;
    padding: 16px 12px 16px 16px;
    background: #EDEDED;
    border-radius: 4px;
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    color: #000000;
    cursor: pointer;
}

.mobile-filter-container .filter-modal .mobile-filters .headerSearch .item.more .text-more {
    float: left;
    margin-right: 11px;
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 24px;
    color: rgba(0, 0, 0, 0.6);
}
.mobile-filter-container .filter-modal .mobile-filters .headerSearch .item.more .arrow {
    float: left;
    height: 0;
    padding-top: 10px;
    filter: invert(39%) sepia(80%) saturate(2372%) hue-rotate(158deg) brightness(91%) contrast(101%);
}

.mobile-filter-container .filter-modal .mobile-filters .headerSearch .item.more .rotate {
    transform: rotate(180deg) !important;
}

.mobile-filter-container .filter-modal .mobile-filters .headerSearch .year-of-construction {
    width: 400px;
    background-color: #EDEDED;
    padding: 14px;
    border-radius: 0px 8px 8px 8px;
    height: 950px;
}

.mobile-filter-container .filter-modal .mobile-filters .headerSearch .year-of-construction::-webkit-scrollbar {
    width: 10px !important;
    background: #0097A7;
}

.mobile-filter-container .filter-modal .mobile-filters .headerSearch .year-of-construction::-webkit-scrollbar-track {
    background: #fff;
    border-radius: 12px;
}

.mobile-filter-container .filter-modal .mobile-filters .headerSearch .year-of-construction::-webkit-scrollbar-thumb {
    background-color: #0097A7;
    border-radius: 10px;
}


.mobile-filter-container .filter-modal .mobile-filters .headerSearch .year-of-construction .label {
    margin-top: 6px;
    margin-bottom: 8px;
    font-family: Source Sans Pro;
    color: #000;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 12px;
}

.mobile-filter-container .filter-modal .mobile-filters .headerSearch .year-of-construction .textField {
    float: left;
    width: 91px !important;
    height: 40px !important;
    padding: 0;
    background-color: rgba(255, 255, 255, 1);
    margin-right: 5px;
    border-radius: 4px;
    margin-bottom: 15px;
    color: rgba(0, 0, 0, 0.6);
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 24px;
}

.mobile-filter-container .filter-modal .mobile-filters .headerSearch .year-of-construction .textField:nth-last-child(1) {
    margin-right: 0;
}

.mobile-filter-container .filter-modal .mobile-filters .headerSearch .year-of-construction .dash {
    float: left;
    margin: 0 5px;
    font-size: 16px;
    color: #656565;
    font-weight: 700;
    margin-top: 10px;
}

.mobile-filter-container .filter-modal .mobile-filters .headerSearch .year-of-construction .textField input[type="number"]::-webkit-inner-spin-button,
.mobile-filter-container .filter-modal .mobile-filters .headerSearch .year-of-construction .textField input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.mobile-filter-container .filter-modal .mobile-filters .headerSearch .year-of-construction .type-of-construction {
    display: block;
}

.mobile-filter-container .filter-modal .mobile-filters .headerSearch .year-of-construction .type-of-construction .label {
    margin-top: 10px;
    margin-bottom: 4px;
    font-family: Source Sans Pro;
    color: #000;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 12px;
    font-weight: bold;
}

.mobile-filter-container .filter-modal .mobile-filters .headerSearch .year-of-construction .type-of-construction .checkbox {
    color: #000;
}

.mobile-filter-container .filter-modal .mobile-filters .headerSearch .year-of-construction .type-of-construction .checkbox-label span {
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 24px;
    color: #012324;
}

.mobile-filter-container .filter-modal .mobile-filters .headerSearch .year-of-construction .type-of-construction .new-construction .new-construction-switch p {
    float: left;
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 24px;
    color: #012324;
}

.mobile-filter-container .filter-modal .mobile-filters .headerSearch .year-of-construction .type-of-construction .new-construction .new-construction-switch .switch-button {
    float: left;
    margin: -6px 0 0 0;
}

.mobile-filter-container .filter-modal .mobile-filters .headerSearch .year-of-construction .type-of-construction .new-construction .new-construction-switch .switch-button .MuiSwitch-colorSecondary.Mui-checked+.MuiSwitch-track {
    background-color: #0097A7;
}

.mobile-filter-container .filter-modal .mobile-filters .headerSearch .year-of-construction .type-of-construction .garage .garage-switch p {
    float: left;
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 24px;
    color: #012324;
}

.mobile-filter-container .filter-modal .mobile-filters .headerSearch .year-of-construction .type-of-construction .garage .garage-switch .garage-switch-button {
    float: left;
    margin: -6px 0 0 0;
}

.mobile-filter-container .filter-modal .mobile-filters .headerSearch .year-of-construction .type-of-construction .garage .garage-switch .garage-switch-button .MuiSwitch-colorSecondary.Mui-checked+.MuiSwitch-track {
    background-color: #0097A7;
}

.mobile-filter-container .filter-modal .mobile-filters .headerSearch .item.search {
    width: 232px;
}

.mobile-filter-container .filter-modal .mobile-filters .headerSearch .item.checkbox {
    width: 170px;
    margin: 0;
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 12px;
    color: #012324;
}

.mobile-filter-container .filter-modal .mobile-filters .headerSearch .item.checkbox label {
    margin: 0;
}



.mobile-filter-container .mobile-filter-nav{
  display: flex;
  justify-content:space-around;
  align-items: center;
}

.mobile-filter-container .mobile-filter-nav .filter-button{
  display: flex;
  justify-content:center;
  align-items: center;
  gap:10px;
  padding:4px 40px;
  font-size: 20px;
}

 .mobile-filter-container .mobile-filter-nav .filter-button:hover{
  background: #00BCD4;
}

.mobile-filter-container .mobile-filter-nav .filter-button .filter-icon{
  transform: rotate(270deg);
  font-size:30px;
}

.modal-footer-actions{
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 100%;
}

.modal-footer-actions .modal-footer-action-button{
    width: 100%;
    padding:5px;
    color: #000000;
}


.modal-footer-actions .view-homes{
    color: #ffffff;
}


.modal-footer-actions .view-homes:hover{
    background: #00BCD4;
    color: #ffffff;
}

.mobile-filter-container .autoCompletion-input-box{
    background-color: #ededed; 
    font-size: 16px;
    color: #000;
    width: 100%;
    border: none;
    border-radius: 8px;
}

.mobile-filter-container .autocomplete-dropdown-container{
     box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
     width: 230px;
     
}

.autocomplete-dropdown {
    position: absolute;
    width: 100%;
    z-index: 9999; 
  }

/* Media queries */

@media screen and (min-width:1320px){
    .mobile-filter-container{
        padding:30px 0;
        display: none;
    }
}

@media screen and (max-width: 1070px) {
.mobile-filter-container .filter-modal .mobile-filters .headerSearch {
  display: flex;
  flex-direction:column;
  justify-content: center;
  align-items: center;
  gap:20px
}
.mobile-filter-container .filter-modal .mobile-filters .headerSearch .item {
  width:400px !important
}

}

@media screen and (max-width: 768px) {
.mobile-filter-container .filter-modal .mobile-filters .headerSearch {
  display: flex;
  flex-direction:column;
  justify-content: center;
  align-items: center;
  gap:20px
}
.mobile-filter-container .filter-modal .mobile-filters .headerSearch .item {
  width:400px !important
}
}

@media screen and (max-width:500px){
      .mobile-filter-container .filter-modal .mobile-filters .headerSearch .item {
        width:100% !important
      }
      .mobile-filter-container .mobile-filter-nav .filter-item input{
       padding:12px 5px !important;
       font-size: 16px;
       text-indent: 10px;
      }

      .mobile-filter-container .mobile-filter-nav .filter-item .filter-button{
        padding:2px 5px;
        font-size: 16px;
       }
       .mobile-filter-container .mobile-filter-nav .filter-button .filter-icon{
        font-size:25px;
      }

      .mobile-filter-container .filter-modal .mobile-filters .headerSearch .year-of-construction {
        width: 100%;
    }
    
      
}
@media screen and (min-width:500px) {
    .modal-footer-actions{
        width: 400px;
        padding:5px;
    }
    
}


@media screen and (max-width:420px){
 .mobile-filter-container .filter-modal .mobile-filters .headerSearch .item {
  width:100% !important;

}
}

@media screen and (max-width:320px){
    .mobile-filter-container .filter-modal .mobile-filters .headerSearch .year-of-construction .dash {
        margin: 0px !important;
    }
    
}