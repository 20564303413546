.dialog-page-multi-profile-lawyer { padding: 20px; text-align: center; }
.dialog-multi-profile-lawyer { text-align: center; }
.dialog-multi-profile-lawyer-content { padding: 32px 37px 78px 38px; }
.dialog-multi-profile-lawyer-content .title-header { padding: 0; margin-bottom: 8px; }
.dialog-multi-profile-lawyer-content .title-header .title { display: inline-block; font-family: Lora; font-style: normal; font-weight: bold; font-size: 32px; line-height: 32px; margin-top: 29px; }
.dialog-multi-profile-lawyer-content .title-header .close-icon { display: inline-block; cursor: pointer; float: right; }

.dialog-multi-profile-lawyer-content .content { padding: 0; overflow-x: hidden; }
.dialog-multi-profile-lawyer-content .content .sub-title { font-family: Source Sans Pro; font-style: normal; font-weight: normal; font-size: 18px; line-height: 24px; margin-bottom: 24px; padding: 0 87px; }
.dialog-multi-profile-lawyer-content .content .box { padding: 24px 16px; border-radius: 10px; position: relative; }
.dialog-multi-profile-lawyer-content .content .box .icon { height: 64px; }
.dialog-multi-profile-lawyer-content .content .box .parts { position: absolute; left: 45%; top: 14%; display: inline-grid; }
.dialog-multi-profile-lawyer-content .content .box .title { margin-top: 24px; font-family: Lora; font-style: normal; font-weight: bold; font-size: 24px; line-height: 26px; }
.dialog-multi-profile-lawyer-content .content .box .text { padding: 0; margin-top: 10px; font-family: Source Sans Pro; font-style: normal; font-weight: normal; font-size: 16px; line-height: 18px; }
.dialog-multi-profile-lawyer-content .content .box.disabled { pointer-events: none; opacity: 0.5; background: #9D9D9D; }
.dialog-multi-profile-lawyer-content .content .box.buyer { background: #FBE9E7; }
.dialog-multi-profile-lawyer-content .content .box.seller { background: #F9FBE7; }
.dialog-multi-profile-lawyer-content .content .box.vassistant { background: #ECEFF1; }
.dialog-multi-profile-lawyer-content .content .box.photographer { background: #FFF8E1; }

.dialog-multi-profile-lawyer-content .content .second-row { margin-top: 16px; }
.dialog-multi-profile-lawyer-content .content .second-row.last { border: 4px solid #00838F; box-sizing: border-box; }
.dialog-multi-profile-lawyer-content .content .actions { margin-top: 24px; }
.dialog-multi-profile-lawyer-content .content .actions .button-continue { margin-right: 16px; height: 40px; width: 105px; padding: 15px 32px; background: #00BCD4; border-radius: 4px; font-family: Source Sans Pro; font-style: normal; font-weight: bold; font-size: 14px; line-height: 18px; text-transform: uppercase; color: #FFFFFF; }
.dialog-multi-profile-lawyer-content .content .actions .button-back { height: 40px; width: 105px; padding: 15px 32px; border: 2px solid #00BCD4; box-sizing: border-box; border-radius: 4px; font-family: Source Sans Pro; font-style: normal; font-weight: bold; font-size: 14px; line-height: 18px; text-transform: uppercase; color: #000000; }