
.contactPage .content { margin: 0 384px 64px; }
.contactPage .content form { text-align: center; }
.contactPage .content .title-sub { margin-bottom: 64px; font-size: 18px; line-height: 24px; text-align: center; font-family: Source Sans Pro; font-weight: 400; }
.contactPage .content .textField { margin-bottom: 36px; background-color:#EDEDED; border-radius: 4px 4px 0px 0px; }
.contactPage .content .textField:last-child { margin-bottom: 24px !important; }

.contactPage .content .button { display: inline-block; padding: 15px 32px; background-color: #00BCD4; border-radius: 4px; color: #fff; text-transform: uppercase; font-size: 14px; line-height: 18px; font-family: Source Sans Pro; font-weight: 700;}

@media screen and (max-width: 1124px) {
  .contactPage .content { 
    margin:0 25% 84px !important; 
  }
}
@media screen and (max-width: 600px) {
  .contactPage .content { 
    margin:0 15% 84px !important; 
  }
}

@media screen and (max-width: 350px) {
  .contactPage .content { 
    margin:0 10% 84px !important; 
  }
}