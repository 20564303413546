.documentsPage-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.documentsPage-wrapper .stepper {
  margin-top: 64px;
}

.documentsPage-wrapper .owner-rep-switch-wrapper {
  margin-top: 32px;
  display: flex;
  align-items: center;
}

.documentsPage-wrapper .owner-rep-switch-wrapper>span {
  margin: -15px 20px 0 20px;
}

.documentsPage-wrapper .proof-ownership-wrapper {
  margin-top: 26px;
  display: flex;
  flex-direction: column;
  width: 906px;
}

.documentsPage-wrapper .proof-ownership-wrapper>p {
  font-family: Source Sans Pro;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  margin-bottom: 16px;
}

.documentsPage-wrapper .proof-ownership-wrapper .inner-wrapper {
  display: flex;
  width: 100%;
  height: 146px;
  background-color: #f8f8f8;
  border-radius: 4px 4px 0px 0px;
  flex-direction: column;
}

.documentsPage-wrapper .proof-ownership-wrapper .inner-wrapper .content {
  margin-top: 16px;
  display: flex;
  width: 100%;
  height: 100%;
}

.documentsPage-wrapper .proof-ownership-wrapper .inner-wrapper .content>div {
  /* width: 50%; */
  display: flex;
  /* justify-content: center; */
  flex-direction: column;
  /* align-items: center; */
}

.documentsPage-wrapper .proof-ownership-wrapper .inner-wrapper .content .left {
  width: 60%;
  margin-left: 24px;
}

.documentsPage-wrapper .proof-ownership-wrapper .inner-wrapper .content .left>div {
  display: flex;
}

.documentsPage-wrapper .proof-ownership-wrapper .inner-wrapper .content .left>div>img {
  margin-right: 10px;
}

.documentsPage-wrapper .proof-ownership-wrapper .inner-wrapper .content .left>div>h1 {
  font-family: Lora;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 26px;
  letter-spacing: 0em;
  text-align: left;
}

.documentsPage-wrapper .proof-ownership-wrapper .inner-wrapper .content .left>p {
  font-family: Source Sans Pro;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  width: 500px;
  margin-top: 8px;
}

.documentsPage-wrapper .proof-ownership-wrapper .inner-wrapper .content .right {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.documentsPage-wrapper .proof-ownership-wrapper .inner-wrapper .content .right>h1 {
  font-family: Source Sans Pro;
  font-size: 18px;
  font-style: normal;
  font-weight: 900;
  line-height: 22px;
  width: 230px;
  margin-left: 21px;
  text-transform: uppercase;
}

.documentsPage-wrapper .proof-ownership-wrapper .greenLine {
  height: 8px;
  width: 100%;
  background-color: #a5d6a7;
  border-radius: 0px 0px 4px 4px;
}

.documentsPage-wrapper .hire-lawyer {
  margin-top: 66px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 434px;
  width: 906px;
  background: #f8f8f8;
  border-radius: 4px;
  margin-bottom: 128px;
}

.documentsPage-wrapper .hire-lawyer>h1 {
  font-family: Lora;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px;
  letter-spacing: 0em;
  text-align: left;
}

.documentsPage-wrapper .hire-lawyer>p {
  font-family: Source Sans Pro;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: center;
  margin-top: 8px;
  width: 672px;
}

.documentsPage-wrapper .hire-lawyer>div {
  display: flex;
  margin-bottom: 24px;
}

.documentsPage-wrapper .hire-lawyer>div>div {
  width: 203px;
  height: 186px;
  background: #ededed;
  margin: 0px 16px;
  margin-top: 24px;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.documentsPage-wrapper .hire-lawyer>div>div>p {
  font-family: Source Sans Pro;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: center;
}

.documentsPage-wrapper .hire-lawyer>div>div>img {
  margin-bottom: 23px;
  width: 75px;
}

.documentsPage-wrapper .buttons-wrapper {
  margin-top: 48px;
  text-align: center;
}

.documentsPage-wrapper .buttonFilled {
  background-color: #00bcd4;
  border: 2px solid #00bcd4;
  font-family: Source Sans Pro;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 18px;
  color: white;
  padding: 15px 32px 15px 32px;
  border-radius: 4px;
  text-transform: uppercase;
}

.documentsPage-wrapper .buttonFilled:hover {
  border: 2px solid #00bcd4;
  background-color: #00bcd4;
}

.documentsPage-wrapper .buttonUnfilled {
  border-radius: 4px;
  margin-right: 25px;
  padding: 15px 32px 15px 32px;
  border: 2px solid #00bcd4;
  font-family: Source Sans Pro;
  font-size: 14px;
  font-style: normal;
  color: #000;
  font-weight: 700;
  line-height: 18px;
  background-color: transparent;
  text-transform: uppercase;
}

.documentsPage-wrapper .buttonUnfilled:hover {
  border: 2px solid #00bcd4;
}

.documentsPage-wrapper .image-container {
  align-items: center;
  justify-content: center;
  display: flex;
  flex-wrap: wrap;
  /* width: 906px; */
  margin-top: 24px;
}

.documentsPage-wrapper .image-container .image-square,
.documentsPage-wrapper .image-container iframe {
  box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px;
  border-radius: 10px;
  width: 400px;
  height: 400px;
  overflow: hidden;
}

.documentsPage-wrapper .image-container .image-square>img {
  width: 100%;
  height: 100%;
  border-radius: 10px;
  background: transparent;
  object-fit: cover;
  transition: 0.3s ease-in-out;
}

/* .documentsPage-wrapper .image-container .image-square>img:hover {
  transform: scale(1.2, 1.2);
} */

.documentsPage-wrapper .dropZone {
  border: 1px dashed #8c8c8c;
  width: 906px;
  height: 138px;
  margin-top: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.documentsPage-wrapper .dropZone .upload {
  display: flex;
  padding: 40px;
}

.documentsPage-wrapper .dropZone .upload>p {
  font-family: Source Sans Pro;
  font-size: 14px;
  font-weight: 700;
  line-height: 18px;
  color: #0097a7;
  margin-right: 5px;
  margin-bottom: 0  !important;
}

.documentsPage-wrapper>button {
  margin-top: 24px;
  margin-bottom: 64px;
}

.documentsPage-wrapper .seller-button-upload-photo {
  background-color: #00bcd4;
  height: 48px;
  font-family: Source Sans Pro;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 18px;
  text-align: left;
  color: white;
  padding: 15px 32px 15px 32px;
  border: none;
  outline: 0;
  border-radius: 4px;
  cursor: pointer;
  text-transform: uppercase;
}

.documentsPage-wrapper .seller-button-upload-photo:hover {
  background-color: #00bcd4;
}

.documentsPage-wrapper .image-container {
  position: relative;
}

.documentsPage-wrapper .image-container .delete {
  position: absolute;
  top: 0;
  left: 103%;
  width: 88px;
  height: 24px;
  border: none;
  background-color: white;
  padding: 4px 12px 4px 12px;
  cursor: pointer;
  border-radius: 16px;
  z-index: 3;
  border: 1px solid #000;
}

.documentsPage-wrapper .image-container .delete span {
  display: flex;
}

.documentsPage-wrapper .image-container .delete span p {
  margin-left: 11px;
  font-family: Source Sans Pro;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.400000006px;
}

.documentsPage-wrapper .is-owner{
  margin: 15px 0;
  padding: 0 380px; 
  color: #0097a7; 
  font-family: Source Sans Pro;
  font-size: 20px; 
  font-weight: 700;
  line-height: 25px;
  text-align: center ;

}

.documentsPage-wrapper .image-container .delete span p {
  margin-bottom: 0 !important;
}

.documentsPage-wrapper .buttons-wrapper .text-note {
  font-family: 'Source Sans Pro';
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  margin-top: 10px;
  max-width: 969px;
  text-align: center;
}

@media screen and (max-width: 1000px) {
  .documentsPage-wrapper .dropZone {
    width: 100% !important;
    padding: 0 20px;
  }
  .documentsPage-wrapper{
    padding:0 20px;
  }

  .documentsPage-wrapper .dropZone .upload > p{
    margin-bottom: 0;
  }
}


@media screen and (max-width:1026px){
  .documentsPage-wrapper .is-owner{
    margin: 15px 0;
    padding: 0 100px; 
    color: #0097a7; 
    font-family: Source Sans Pro;
    font-size: 20px; 
    font-weight: 700;
    line-height: 25px;
    text-align: center ;
  }
}

@media screen and (max-width: 910px) {
  .documentsPage-wrapper .hire-lawyer {
   
    width: 100%;
    
  }
}

@media screen and (max-width: 765px) {
  .documentsPage-wrapper .image-container .delete {
    position: absolute;
    top: -28px;
    left: 78%;
  }
}


@media screen and (max-width: 700px) {
  .documentsPage-wrapper .hire-lawyer{
    height: auto;
    width: auto;
    padding: 40px 20px;
  }

  .documentsPage-wrapper .hire-lawyer > div{
    flex-direction: column;
  }

  .documentsPage-wrapper .hire-lawyer > p{
    width: 100%;
  }
}

@media screen and (max-width:600px) {
  .complete-post-modal .complete-post-modal-content {
    padding: 64px 25px;
  }
}

@media screen and (max-width: 630px) {

  .documentsPage-wrapper .createAnAddFlow {
    padding:0 20px;
  }

  .documentsPage-wrapper .stepper {
    margin-top: 32px !important;
    margin-bottom: 32px !important;
  }
  
  .documentsPage-wrapper .createAnAddFlow .step-item.currentStep>div {
    width: 70px !important;
    height: 70px !important;
  }
  
  .documentsPage-wrapper .createAnAddFlow .currentStep>div>p {
    font-size: 40px !important;
  }
  
  .documentsPage-wrapper .createAnAddFlow .step-item>div {
    width: 55px !important;
    height: 55px !important;
  }
  
  .documentsPage-wrapper .createAnAddFlow .step-item > div > p {
    font-size: 24px !important;
  }
  
  .documentsPage-wrapper .createAnAddFlow .dashed-line {
    margin-bottom: 49px !important;
    }
  
  .documentsPage-wrapper .createAnAddFlow .dashed-line {
    width: 46px;
  }
  
  }
  
  @media screen and (max-width: 570px) {
    .documentsPage-wrapper .step-item>p {
        font-size: 15px !important
    }
  }

  @media screen and (max-width:550px){
    .documentsPage-wrapper .is-owner{
      margin: 15px 0;
      padding: 0 50px; 
      color: #0097a7; 
      font-family: Source Sans Pro;
      font-size: 20px; 
      font-weight: 700;
      line-height: 25px;
      text-align: center ;
    }
  }
  
  @media screen and (max-width: 500px) {
   
    .documentsPage-wrapper .createAnAddFlow .step-wrapper {
        margin-top: 16px !important;
    }
  
    .documentsPage-wrapper .createAnAddFlow .step-item.currentStep>div {
        width: 60px !important;
        height: 60px !important;
    }
  
    .documentsPage-wrapper .createAnAddFlow .step-item>div {
        width: 45px !important;
        height: 45px !important;
    }
  
    .documentsPage-wrapper .createAnAddFlow .step-item>div>p {
        font-size: 20px !important;
    }
  
    .documentsPage-wrapper .createAnAddFlow .dashed-line {
        width: 30px;
    }
  
  }
  @media screen and (max-width:491px){
    .documentsPage-wrapper .buttons-wrapper {
      display: flex;
      flex-direction: column-reverse;
      justify-content: center;
      align-items: center;
      gap:20px
    }
  }
  
  @media screen and (max-width: 450px) {
    .documentsPage-wrapper .createAnAddFlow .step-item>p {
        font-size: 12px !important;
    }
  }
  
  @media screen and (max-width: 400px) {
    .documentsPage-wrapper .createAnAddFlow .currentStep {
        margin-right: 0px !important;
    }
  
    .documentsPage-wrapper .createAnAddFlow>h1 {
  
        font-size: 30px !important;
        line-height: 32px !important;
    }
  
    .vissualPage-wrapper .createAnAddFlow .dashed-line {
        width: 22px;
    }
  
    .vissualPage-wrapper .createAnAddFlow .step-item.currentStep>div {
        width: 50px !important;
        height: 50px !important;
    }
  
    .vissualPage-wrapper .createAnAddFlow .step-item>div {
        width: 30px !important;
        height: 30px !important;
        align-items: baseline;
    }
  
    .vissualPage-wrapper .createAnAddFlow .step-item>div>p {
        font-size: 14px !important;
    }
  
    .vissualPage-wrapper .createAnAddFlow .step-item>p {
        font-size: 13px !important;
    }
  
    .vissualPage-wrapper .createAnAddFlow .dashed-line {
        width: 15px !important;
    }
  
    .vissualPage-wrapper .buttonUnfilled {
        margin-right: 0px !important;
    }
    .documentsPage-wrapper .is-owner{
      margin: 15px 0;
      padding: 0 20px; 
      color: #0097a7; 
      font-family: Source Sans Pro;
      font-size: 20px; 
      font-weight: 700;
      line-height: 25px;
      text-align: center ;
    }
    .documentsPage-wrapper .buttons-wrapper{
      display: flex;
      flex-direction: column-reverse;
      gap: 20px;
    }

    .documentsPage-wrapper .buttonUnfilled{
      margin-right: 0 !important;
    }
  }
  @media screen and (max-width: 428px) {
    .documentsPage-wrapper .image-container .image-square {
      width: 200px;
      height: 200px;
    }

  }

  @media screen and (max-width:350px){
    .documentsPage-wrapper .is-owner{
      margin: 15px 0;
      padding: 0 10px; 
      color: #0097a7; 
      font-family: Source Sans Pro;
      font-size: 20px; 
      font-weight: 700;
      line-height: 25px;
      text-align: center ;
    }
  }