.property-box { display: flex; flex-wrap: wrap; gap: 18px; padding: 16px; margin-top: 16px; background: #FFFFFF; box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px rgba(0, 0, 0, 0.12), 0px 1px 3px rgba(0, 0, 0, 0.2); border-radius: 12px; }
.property-box .propery-details-box { flex: 2 1 0; }
.property-box .propery-details-box .image { margin-right: 16px; width: 189px; height: 189px; border-radius: 4px; float: left; }

.property-box .propery-details-box .details-part-left { float: left; }
.property-box .propery-details-box .details-part-left .main-price { font-family: Source Sans Pro; font-style: normal; font-weight: bold; font-size: 36px; line-height: 45px; }
.property-box .propery-details-box .details-part-left .property { margin-top: 14px;  }
.property-box .propery-details-box .details-part-left .property .link { text-decoration: none !important; color: #000; font-family: Source Sans Pro; font-style: normal; font-weight: bold; font-size: 16px; line-height: 18px; }

.property-box .propery-details-box .details-part-left .address { margin-top: 8px; }
.property-box .propery-details-box .details-part-left .address .icon { margin-right: 4px; }
.property-box .propery-details-box .details-part-left .address .text { font-family: Source Sans Pro; font-style: normal; font-weight: normal; font-size: 18px; line-height: 24px; }

.property-box .propery-details-box .details-part-left .activites { margin-top: 8px; }
.property-box .propery-details-box .details-part-left .activites .icon { margin-right: 4px; width: 16px; height: 16px; margin-top: 2px; }
.property-box .propery-details-box .details-part-left .activites .number { font-family: Source Sans Pro; font-style: normal; font-weight: normal; font-size: 18px; line-height: 24px; margin-right: 12px; }
.property-box .propery-details-box .details-part-left .activites .number .sup { font-size: 10px; line-height: 11px; font-weight: 700; }

.property-box .propery-details-box .details-part-right { float: right; margin-top: 5px; }
.property-box .propery-details-box .details-part-right .price-row { margin-bottom: 7px; }
.property-box .propery-details-box .details-part-right .price-row .price { float: right; padding: 3px 8px; background: #E0F7FA; border-radius: 4px; font-family: Source Sans Pro; font-style: normal; font-weight: bold; font-size: 14px; line-height: 18px; }
.property-box .propery-details-box .details-part-right .price-row .price .sup { font-size: 10px; line-height: 12px; }
.property-box .propery-details-box .details-part-right .price-row .tooltip { float: right; margin-left: 6px; }

.property-box .propery-details-box .details-last { float: left; }
.property-box .propery-details-box .details-last .paragraph { float: left; margin-right: 94px; font-family: Roboto; font-style: normal; font-weight: 500; font-size: 18px; line-height: 21px; }
.property-box .propery-details-box .details-last .rating { float: right; }
.property-box .propery-details-box .details-last .rating .icon { margin-right: 6px; margin-left: 14px; }
.property-box .propery-details-box .details-last .rating .icon-eye { vertical-align: text-top; }
.property-box .propery-details-box .details-last .rating .number { font-family: Source Sans Pro; font-style: normal; font-weight: normal; font-size: 18px; line-height: 24px; vertical-align: top; }

.property-box .propery-details-box .line { margin: 8px 0 16px 0; border: 0.5px solid #9D9D9D; transform: rotate(180deg); }

.property-box .additional-info-box { flex: 1 1 0; background: #F4F4F4; border-radius: 8px; }
.property-box .additional-info-box .info-row { display: grid; justify-content: center; align-items: center; text-align: center; padding: 56px 84px; }
.property-box .additional-info-box .info-row .price { margin-bottom: 8px; font-family: Source Sans Pro; font-style: normal; font-weight: bold; font-size: 36px; line-height: 45px; }
.property-box .additional-info-box .info-row .offer { font-family: Source Sans Pro; font-style: normal; font-weight: normal; font-size: 18px; line-height: 24px; }

@media screen and (max-width:959px){
    .third-party-property-box .propery-details-box { 
        flex: 2 1 0;
        display:flex;
        flex-direction:column;
     }
}