.lattice-content {
    margin-bottom: 32px;
    padding: 10px;
    background: #FFFFFF;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px rgba(0, 0, 0, 0.12), 0px 1px 3px rgba(0, 0, 0, 0.2);
    border-radius: 12px;
    display: flex;
    justify-content: space-between;
    gap:10px;
}

.lattice-content .left-part {
    float: left;
    position: relative;
    margin-right: 9px;
}

.lattice-content .left-part .image {
    float: left;
    width: 110px;
    height: 110px;
    border-radius: 100%;
}

.lattice-content .left-part .image img {
    width: 110px;
    height: 110px;
    border-radius: 100%;
    object-fit: cover;
}

.lattice-content .left-part .image-svg {
    position: absolute;
    top: 70%;
    left: 83%;
}

.lattice-content .right-part {
    float: left;
    width: 220px;
}

.lattice-content .right-part .title {
    font-family: Lora;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    color: #000000;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin: 0 !important;
    line-height: unset;
}

.lattice-content .right-part .title>a {
    color: #000000;
}



.lattice-content .right-part .all-stars {
    margin-left: -3px;
    height: 25px;
}

.lattice-content .right-part .result {
    font-size: 16px;
    line-height: 18px;
    margin-bottom: 5px;
}

.lattice-content .right-part .persona-price-container{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.lattice-content .right-part .price .title-price {
    float: left;
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: bold;
    font-size: 30px;
    text-transform: uppercase;
    color: #000000;
    margin-bottom: 0px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.lattice-content .right-part .price span {
    font-family: Source Sans Pro;
    font-style: normal;
    font-size: 16px;
    line-height: 22px;
    text-transform: uppercase;
    color: #000000;
}

.lattice-content .right-part .photographer-tasks img {
    width: 20px;
    height: 20px;
    margin: 10px;
}

.lattice-content .right-part .price .text {
    margin-bottom: 0px;
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 18px;
    color: #000000;
}

.lattice-content .right-part .price .text{
display: flex;
justify-content: center;
align-items: center;
}

.lattice-content .right-part .price .text img {
    width: 18px;
    height: 18px;
}

@media screen and (max-width:768px){
    .lattice-content .left-part .image-svg {
        position: absolute;
        top: 70%;
        left: 68%;
    }
}

@media screen and (max-width:464px){
    .lattice-content {
        margin-bottom: 32px;
        padding: 10px;
        background: #FFFFFF;
        box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px rgba(0, 0, 0, 0.12), 0px 1px 3px rgba(0, 0, 0, 0.2);
        border-radius: 12px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
 

    .lattice-content .left-part .image-svg {
    position: absolute;
    top: 84%;
    left: 35%;
    }


}