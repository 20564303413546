.lawyer-feature-item {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    background: #f8f8f8;
    border-radius: 8px;
    padding: 24px;
    > img {
        margin-bottom: 16px;
        width: 76px;
        height: 77px;
    }
    > p {
        font-family: Source Sans Pro;
        font-style: normal;
        font-weight: bold;
        font-size: 20px;
        line-height: 24px;
        text-transform: uppercase;
        color: #000000;
        margin-bottom: 16px;
    }
    > span {
        font-family: Source Sans Pro;
        font-style: normal;
        font-weight: bold;
        font-size: 48px;
        line-height: 44px;
        color: #000000;
        background: #FFFFFF;
        border-radius: 4px;
        padding: 8px;
        > span {
            font-family: Source Sans Pro;
            font-style: normal;
            font-weight: 900;
            font-size: 18px;
            line-height: 22px;
            text-transform: uppercase;
            color: #000000;
        }
    }
}