.services-tab { position: relative; padding: 16px; }

.services-tab .header-content { display: flex; margin-top: 32px; }
.services-tab .header-content .avatar { position: relative; width: 112px; height: 112px; border-radius: 50%; }
.services-tab .header-content .avatar .photo { width: 112px; height: 112px; border-radius: 50%; }
.services-tab .header-content .avatar .icon { position: absolute; right: -9px; bottom: 8px; width: 29px; }

.services-tab .header-content .user-details { margin-left: 24px;display: flex; flex-direction: column;justify-content: flex-start; align-items: flex-start; }
.services-tab .header-content .user-details .title-primary { margin-bottom: 16px; font-family: Lora; font-style: normal; font-weight: bold; font-size: 32px; line-height: 32px; color: #000000; }

.services-tab .header-content .user-details .active-rating { display: flex; margin-bottom: 20px; }
.services-tab .header-content .user-details .active-rating .title { margin-right: 10px; margin-top: 4px; font-family: Lora; font-style: normal; font-weight: 900; font-size: 18px; line-height: 22px; text-transform: uppercase; color: #000000; }
.services-tab .header-content .user-details .active-rating .star { width: 16px; height: 16px; margin-top: 2.5px; filter: invert(48%) sepia(93%) saturate(3629%) hue-rotate(157deg) brightness(90%) contrast(102%); }
.services-tab .header-content .user-details .active-rating .result { margin-left: 8px; font-family: Source Sans Pro; font-style: normal; font-weight: normal; font-size: 18px; line-height: 24px; color: #000000; }

.services-tab .header-content .user-details .block { display: flex; }
.services-tab .header-content .user-details .block .icon { width: 20px; height: 20px; margin-right: 9px; color: #000000; }
.services-tab .header-content .user-details .block .text { font-family: Source Sans Pro; font-style: normal; font-weight: bold; font-size: 18px; line-height: 24px; color: #000000; }

.services-tab .header-content .progress-update { display: flex; flex-direction: column; margin-left: auto; text-align: center; background: #FFFFFF; border-radius: 4px; padding: 30px 20px; }
.services-tab .header-content .progress-update .progress-icon { width: 33px; height: 33px; margin: 0px auto; }
.services-tab .header-content .progress-update .text { margin-top: 8px; font-family: Source Sans Pro; font-style: normal; font-weight: bold; font-size: 14px; line-height: 18px; text-align: center; text-transform: uppercase; color: #000000; }
.services-tab .header-content .progress-update .button-meeting-request { margin-top: 18px; background: #00BCD4; border-radius: 4px; padding: 19px 32px; font-family: Source Sans Pro; font-style: normal; font-weight: bold; font-size: 14px; line-height: 18px; text-transform: uppercase; color: #FFFFFF; }

.services-tab .header-content .price-info { text-align: center; }
.services-tab .header-content .price-info .price { margin-top: 27px; font-family: Source Sans Pro; font-style: normal; font-weight: bold; font-size: 48px; line-height: 60px; text-transform: uppercase; color: #000000; }
.services-tab .header-content .price-info .price span { font-family: Source Sans Pro; font-style: normal; font-weight: 900; font-size: 18px; line-height: 22px; text-transform: uppercase; color: #000000; }

.services-tab .header-content .price-info .text { margin-bottom: 32px; font-family: Source Sans Pro; font-style: normal; font-weight: normal; font-size: 18px; line-height: 24px; color: #000000; }
.services-tab .header-content .price-info .text .icon { margin-left: 6px; vertical-align: bottom; }

.services-tab .service-progress { margin: 24px 0px; }
.services-tab .service-progress .service-title { display: flex; }
.services-tab .service-progress .service-title .title { margin-bottom: 24px; }
.services-tab .service-progress .service-title .icon { width: 20px; height: 20px; color: #000000; margin-left: 4px; margin-top: 8px; }

.services-tab .step-details { margin-bottom: 24px; border-radius: 12px; padding: 16px; }

.services-tab .toggle-steps { margin-bottom: 24px; background: #F2FEFF; border-radius: 12px; padding: 16px; text-align: center; }
.services-tab .toggle-steps .button-next { margin-right: 32px; background: #00BCD4; border-radius: 4px; padding: 19px 32px; font-family: Source Sans Pro; font-style: normal; font-weight: bold; font-size: 14px; line-height: 18px; text-transform: uppercase; color: #FFFFFF; }
.services-tab .toggle-steps .button-previous { margin-right: 32px; border: 2px solid #00BCD4; border-radius: 4px; padding: 19px 32px; font-family: Source Sans Pro; font-style: normal; font-weight: bold; font-size: 14px; line-height: 18px; text-transform: uppercase; color: #000000; }

.services-tab .session { padding: 16px; background: #FFFFFF; border-radius: 12px; display: flex; align-items: center; }
.services-tab .session .info .title { margin-bottom: 16px; font-family: Lora; font-style: normal; font-weight: bold; font-size: 24px; line-height: 26px; }
.services-tab .session .info .info-row { margin-bottom: 8px; display: inline-block; width: 100%; }
.services-tab .session .info .info-row:last-child { margin-bottom: 0px; }
.services-tab .session .info .info-row .photo { width: 24px; height: 24px; float: left; background: #323232; border-radius: 50%; margin-right: 4px; }
.services-tab .session .info .info-row .name { float: left; margin-right: 8px; font-family: Roboto; font-style: normal; font-weight: bold; font-size: 18px; line-height: 21px; }
.services-tab .session .info .info-row .text { float: left; margin-right: 8px; font-family: Source Sans Pro; font-style: normal; font-weight: normal; font-size: 18px; line-height: 24px; }
.services-tab .session .info .info-row .link { float: left; margin-right: 8px; font-family: Source Sans Pro; font-style: normal; font-weight: bold; font-size: 18px; line-height: 24px; text-decoration-line: underline !important; }

.services-tab .sellers-property { margin-top: 24px; padding: 16px; background: #E8E8E8; border-radius: 12px; }
.services-tab .sellers-property .title { font-family: Lora; font-style: normal; font-weight: bold; font-size: 24px; line-height: 26px; }

/* Dialog */
.dialog-session-setup { text-align: center; }
.dialog-session-setup-content { margin: 64px 86px }
.dialog-session-setup-content .close-icon { cursor: pointer; float: right; position: absolute; top: 0; right: 0; margin-top: 20px; margin-right: 21px; }
.dialog-session-setup-content .title { font-size: 36px; line-height: 42px; margin-bottom: 16px; }
.dialog-session-setup-content .content { padding: 0; overflow-x: hidden; }
.dialog-session-setup-content .content .text { font-size: 16px; line-height: 18px; font-weight: 400; text-align: left; }
.dialog-session-setup-content .content .actions {
    margin-top: 24px;
    display: flex;
    gap: 20px;
    justify-content: center;
}
.dialog-session-setup-content .content .actions .button-close { height: 40px; padding: 12px 32px; background: #000; border-radius: 12px; color: #FFFFFF; text-transform: none; font-size: 12px; line-height: 14px; font-weight: 400; }

/* Dialog Session Setup */
.dialog-session-setup { text-align: center; }
.dialog-session-setup-content { margin: 64px 117px 64px 116px; }
.dialog-session-setup-content .close-icon { cursor: pointer; float: right; position: absolute; top: 0; right: 0; margin-top: 21px; margin-right: 21px; }
.dialog-session-setup-content .title { margin-bottom: 8px; font-family: Lora; font-style: normal; font-weight: normal; font-size: 48px; line-height: 52px; }

.dialog-session-setup-content .content { padding: 0; overflow-x: hidden; }
.dialog-session-setup-content .content .text { padding: 0px 11px 32px 11px; font-family: Roboto; font-style: normal; font-weight: normal; font-size: 16px; line-height: 19px; text-align: center; }
.dialog-session-setup-content .content .form-group-field { margin-bottom: 4px; text-align: left; }
.dialog-session-setup-content .content .form-group-field .label { font-family: Source Sans Pro; font-style: normal; font-weight: 900; font-size: 18px; line-height: 22px; text-transform: uppercase; }

.dialog-session-setup-content .content .calendar { margin-top: 24px; padding: 16px; background: #FFFFFF; box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.14), 0px 1px 18px rgba(0, 0, 0, 0.12), 0px 3px 5px rgba(0, 0, 0, 0.2); border-radius: 10px; text-align: left; }
.dialog-session-setup-content .content .calendar .title { margin-bottom: 11px; font-family: Source Sans Pro; font-style: normal; font-weight: 900; font-size: 18px; line-height: 22px; text-align: center; text-transform: uppercase; }

.dialog-session-setup-content .content .hour-free { margin-top: 24px; padding: 16px 32px; background: #FFFFFF; box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.14), 0px 1px 18px rgba(0, 0, 0, 0.12), 0px 3px 5px rgba(0, 0, 0, 0.2); border-radius: 10px; margin-bottom: 16px; float: left; text-align: left; }
.dialog-session-setup-content .content .hour-free .title { margin-bottom: 8px; font-family: Source Sans Pro; font-style: normal; font-weight: 900; font-size: 18px; line-height: 22px; text-align: center; text-transform: uppercase; }
.dialog-session-setup-content .content .hour-free .chip { padding: 4px 12px; background: #F8F8F8; border-radius: 16px; float: left; margin-right: 8px; margin-bottom: 8px; cursor: pointer; font-family: Source Sans Pro; font-style: normal; font-weight: normal; font-size: 16px; line-height: 18px; color: #9D9D9D; }
.dialog-session-setup-content .content .hour-free .chip.active { background: #80DEEA; color: #000000; cursor: default; }
.dialog-session-setup-content .content .hour-free .chip.disabled { background: #FFFFFF; opacity: 0.4; cursor: not-allowed; }

.dialog-session-setup-content .content .actions { margin-top: 24px; } 
.dialog-session-setup-content .content .actions .button-accept { width: 250px;  padding: 15px 32px; background: #00BCD4; border-radius: 4px; font-family: Source Sans Pro; font-style: normal; font-weight: bold; font-size: 14px; line-height: 18px; text-transform: uppercase; color: #FFFFFF; }
.dialog-session-setup-content .content .actions .button-cancel { width: 250px; border: 1px solid #00BCD4; padding: 15px 32px; ; border-radius: 4px; font-family: Source Sans Pro; font-style: normal; font-weight: bold; font-size: 14px; line-height: 18px; text-transform: uppercase; color: #000000; }

/* dialog rate lawyer services */
.dialog-lawyer-rating { text-align: center; }
.dialog-lawyer-rating-content { margin: 64px 86px }
.dialog-lawyer-rating-content .title-header .close-icon { cursor: pointer; float: right; position: absolute; top: 0; right: 0; margin-top: 20px; margin-right: 21px; }
.dialog-lawyer-rating-content .title-header .title { font-family: Lora; font-style: normal; font-weight: 1; font-size: 48px; line-height: 52px; text-align: center; }
.dialog-lawyer-rating-content .content { padding: 0; overflow-x: hidden; }
.dialog-lawyer-rating-content .content .lawyer-rating { padding: 13px 21px; margin: 8px auto; display: inline-block; background: #F8F8F8; }
.dialog-lawyer-rating-content .content .lawyer-rating .MuiRating-root { color: #000000; }
.dialog-lawyer-rating-content .content .sub-title { margin-top: 32px; margin-bottom: 8px; font-family: Source Sans Pro; font-style: normal; font-weight: 900; font-size: 18px; line-height: 22px; text-align: center; text-transform: uppercase; }
.dialog-lawyer-rating-content .content .actions { margin-top: 24px; } 
.dialog-lawyer-rating-content .content .actions .button-send { margin-right: 24px; padding: 15px 32px; background: #00BCD4; border-radius: 4px; font-family: Source Sans Pro; font-style: normal; font-weight: bold; font-size: 14px; line-height: 18px; text-transform: uppercase; color: #FFFFFF; }
.dialog-lawyer-rating-content .content .actions .button-close { border: 1px solid #00BCD4; padding: 15px 32px; border-radius: 4px; font-family: Source Sans Pro; font-style: normal; font-weight: bold; font-size: 14px; line-height: 18px; text-transform: uppercase; color: #000000; }



@media screen and (max-width:600px){
    .dialog-lawyer-rating-content {
        margin: 64px 30px !important;
    }

    .services-tab .header-content .user-details {
        margin-left: 0;
    }

    .services-tab .header-content .user-details .active-rating {
        display: flex;
        align-items: flex-start;
        margin-bottom: 20px;
        flex-direction: column;
        margin-left: 60px
    }

    .services-tab .header-content .user-details .title-primary {
        font-size: 25px;
        margin: 0 auto 10px auto;
    }

    .services-tab .header-content .progress-update .text {
        margin: 0 auto;
    }

    .services-tab .step-details {
        padding: 5px;
    }
}

@media screen and (max-width:500px){
    .dialog-session-setup-content .title {
        font-size: 28px !important;
        line-height: 32px !important;
    }
}

@media screen and (max-width:400px){
    .dialog-lawyer-rating-content {
        margin: 64px 16px !important;
    }
}