.informationPage-wrapper {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding: 0 20px;
}

.informationPage-wrapper .stepper {
  margin-top: 64px;
}

.informationPage-wrapper .form-container {
  margin-top: 32px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 33px;
}

.informationPage-wrapper .additional-information {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  max-width: 970px;
  margin-top: 33px;
  margin-bottom: 33px;
}

.informationPage-wrapper .additional-information>h1 {
  font-family: Lora;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px;
  margin-bottom: 8px;
}

.informationPage-wrapper .additional-information>p {
  font-family: Source Sans Pro;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  margin-bottom: 8px;
}

.informationPage-wrapper .additional-information .content {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.informationPage-wrapper .additional-information .content .single {
  width: 100%;
}

.informationPage-wrapper .additional-information .sub-section>p {
  font-family: Source Sans Pro;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 12px;
  letter-spacing: 0em;
  text-align: left;
}

.informationPage-wrapper .additional-information .sub-section .body {
  /* width: 100%; */
  height: 168px;
  background-color: #ededed;
  display: flex;
  border-radius: 4px;
  /* justify-content: center; */
  /* padding-top: 16px;
  padding-left: 16px;
  font-family: Source Sans Pro;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  color: #9d9d9d; */
}

.informationPage-wrapper .additional-information .sub-section .body>p {
  font-family: Source Sans Pro;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  color: #9d9d9d;
  margin-top: 15px;
  margin-left: 15px;
}

.informationPage-wrapper .additional-information .sub-section .first>p {
  width: 878px;
}

.informationPage-wrapper .additional-information .double .body {
  /* width: 48%; */
  width: 468px;
}

.informationPage-wrapper .additional-information .double .body>p {
  /* width: 48%; */
  width: 437px;
}

.informationPage-wrapper .additional-information .buttons-wrapper,
.informationPage-wrapper .buttons-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 24px;
}

.informationPage-wrapper .additional-information .buttons-wrapper>button {
  margin: 0px 8px;
}

.informationPage-wrapper .virtual-assisstance-section {
  background-color: #f8f8f8;
  width: 906px;
  height: 434px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: 32px;
  margin-bottom: 128px;
  border-radius: 4px;
}

.informationPage-wrapper .sub-section-button {
  padding: 19px 48px;
  background: #00BCD4;
  border-radius: 4px;
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  text-transform: uppercase;
  color: #FFFFFF;
}

.informationPage-wrapper .sub-section-button:hover {
  background: #00BCD4;
}


.informationPage-wrapper .virtual-assisstance-section>h1 {
  font-family: Lora;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px;
}

.informationPage-wrapper .virtual-assisstance-section>p {
  font-family: Source Sans Pro;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  margin-top: 8px;
  width: 672px;
  text-align: center;
}

.informationPage-wrapper .virtual-assisstance-section>div {
  margin-top: 24px;
  display: flex;
  margin-bottom: 24px;
}

.informationPage-wrapper .virtual-assisstance-section>div .sub-section {
  width: 203px;
  height: 186px;
  background: #ededed;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0px 16px;
  border-radius: 4px;
}

.informationPage-wrapper .virtual-assisstance-section>div .sub-section>p {
  width: 155px;
  font-family: Source Sans Pro;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  margin-top: 23.3px;
  text-align: center;
}

.informationPage-wrapper .virtual-assisstance-section>div .sub-section>img {
  height: 55px;
}

.informationPage-wrapper .label-description {
  font-family: Source Sans Pro;
  font-size: 1px;
  font-style: normal;
  font-weight: 400;
  line-height: 12px;
  letter-spacing: 0em;
  text-align: left;
  margin-bottom: 5px;
  color: #000;
  cursor: default;
}

.informationPage-wrapper .description {
  margin: 0;
  background-color: #ededed;
  border-radius: 4px;
  border: none;
}

.informationPage-wrapper .description .notchedOutline {
  border: none;
}

.informationPage-wrapper .buttonFilled {
  background-color: #00bcd4;
  border: 2px solid #00bcd4;
  margin-right: 25px;
  font-family: Source Sans Pro;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 18px;
  color: #ffffff;
  padding: 15px 32px 15px 32px;
  border-radius: 4px;
  text-transform: uppercase;
}

.informationPage-wrapper .buttonFilledDisabled {
  margin-right: 25px;
  font-family: Source Sans Pro;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 18px;
  color: #000000;
  padding: 15px 32px 15px 32px;
  border-radius: 4px;
  text-transform: uppercase;
}

.informationPage-wrapper .buttonFilled:hover {
  border: 2px solid #00bcd4;
  background-color: #00bcd4;
}

.informationPage-wrapper .buttonUnfilled {
  border-radius: 4px;
  padding: 15px 32px 15px 32px;
  border: 2px solid #00bcd4;
  font-family: Source Sans Pro;
  color: #000;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 18px;
  background-color: transparent;
  text-transform: uppercase;
}

.informationPage-wrapper .buttonUnfilled:hover {
  border: 2px solid #00bcd4;
}

.informationPage-wrapper .text-center {
  text-align: center;
}

.informationPage-wrapper .title {
  font-family: Lora !important;
  font-size: 32px !important;
  font-style: normal !important;
  font-weight: 700 !important;
  line-height: 32px !important;
  margin-bottom: 8px !important;
  text-align: center !important;
}

.sub-section-button {
  padding: 19px 48px;
  background: #00BCD4;
  border-radius: 4px;
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  text-transform: uppercase;
  color: #FFFFFF;
}

@media screen and (max-width: 1000px) {
	.informationPage-wrapper .css-mhc70k-MuiGrid-root > .MuiGrid-item .MuiGrid-spacing-xs-2:first-child {
		display: grid;
		grid-template-columns: repeat(2, 1fr);
	}

	.informationPage-wrapper .MuiGrid-spacing-xs-2 > .MuiGrid-item:first-child{
		grid-column: 1/-1
	}

  .seller-information-property .MuiGrid-grid-xs-3{
    width: 100% !important;
    max-width: 100% !important;
  }
}

@media screen and (max-width:930px){
  .informationPage-wrapper .virtual-assisstance-section {
    background-color: #f8f8f8;
    width: 100%;
  }
}

@media screen and (max-width: 767px) {
  .informationPage-wrapper .css-mhc70k-MuiGrid-root > .MuiGrid-item .MuiGrid-spacing-xs-2:first-child {
		grid-template-columns: repeat(1, 1fr);
	}

  .seller-information-property .MuiGrid-grid-xs-6{
    width: 100% !important;
    max-width: 100% !important;
  }
  .seller-information-property{
    padding: 0px;
  }
}
@media screen and (max-width: 700px) {
  .informationPage-wrapper .virtual-assisstance-section{
    height: 100% !important;
    padding: 50px 20px;
  }

  .informationPage-wrapper .virtual-assisstance-section > div {
    flex-direction: column;
    gap: 40px;
  }

  .informationPage-wrapper .virtual-assisstance-section > p{
    width: 250px;
  }
}
@media screen and (max-width: 630px) {
	.informationPage-wrapper .stepper {
		margin-top: 32px !important;
		margin-bottom: 32px !important;
	}

	.informationPage-wrapper .createAnAddFlow .step-item.currentStep>div {
		width: 70px !important;
		height: 70px !important;
	}

	.informationPage-wrapper .createAnAddFlow .currentStep>div>p {
		font-size: 40px !important;
	}

	.informationPage-wrapper .createAnAddFlow .step-item>div {
		width: 55px !important;
		height: 55px !important;
	}

	.informationPage-wrapper .createAnAddFlow .step-item > div > p {
		font-size: 24px !important;
	}

	.informationPage-wrapper .createAnAddFlow .dashed-line {
		margin-bottom: 49px !important;
	  }

	.informationPage-wrapper .createAnAddFlow .dashed-line {
		width: 46px;
	}

  .informationPage-wrapper .item-mb .css-mhc70k-MuiGrid-root{
    flex-direction: column;
    justify-content: center;
  }

  .informationPage-wrapper .css-1osj8n2-MuiGrid-root {
    max-width: 100% !important;
  }
}

@media screen and (max-width: 570px) {
	.createAnAddFlow .step-item > p {
		font-size: 15px !important
	}
}

@media screen and (max-width: 500px) {
	.informationPage-wrapper .createAnAddFlow > h1 {
		
		font-size: 40px !important;
		line-height: 42px !important;
	}

	.informationPage-wrapper .createAnAddFlow .step-wrapper {
		margin-top: 16px !important;
	  }

	  .informationPage-wrapper .createAnAddFlow .step-item.currentStep>div {
		width: 60px !important;
		height: 60px !important;
	}

	.informationPage-wrapper .createAnAddFlow .step-item>div {
		width: 45px !important;
		height: 45px !important;
	}

	.informationPage-wrapper .createAnAddFlow .step-item > div > p {
		font-size: 20px !important;
	}

	.informationPage-wrapper .createAnAddFlow .dashed-line {
		width: 30px;
	}
}

@media screen and (max-width: 450px) {
	.informationPage-wrapper .createAnAddFlow .step-item>p {
		font-size: 12px !important;
	}
  .seller-information-property .MuiGrid-grid-xs-6{
    max-width: 50% !important;
  }

  .informationPage-wrapper .virtual-assisstance-section > h1 {
    font-size: 24px;
  }
}

@media screen and (max-width: 400px) {
	.informationPage-wrapper .createAnAddFlow .currentStep {
		margin-right: 0px !important;
	}

	.informationPage-wrapper .createAnAddFlow>h1 {

		font-size: 30px !important;
		line-height: 32px !important;
	}

	.informationPage-wrapper .createAnAddFlow .dashed-line {
		width: 22px;
	}

	.informationPage-wrapper .createAnAddFlow .step-item.currentStep>div {
		width: 50px !important;
		height: 50px !important;
	}

	.informationPage-wrapper .createAnAddFlow .step-item>div {
		width: 30px !important;
		height: 30px !important;
		align-items: baseline;
	}

	.informationPage-wrapper .createAnAddFlow .step-item>div>p {
		font-size: 14px !important;
	}

  /* .seller-information-property .section-3 .common-section:first-child{
      width: 200px !important;
  } */

  /* .seller-information-property .section-3 .common-section .content .grey-section{
    width: 200px !important;
  } */
  
  .seller-information-property .section-3 .common-section .content .grey-section .mutliSelect{
    width: 100% !important;
  }
  .seller-information-property .section-3 .common-section .content .grey-section .mutliSelect .css-yk16xz-control{
    width:inherit !important;
 
  }

}

@media screen and (max-width:344px){
  .informationPage-wrapper .buttonUnfilled {
    padding: 15px 25px 15px 25px;
}

.informationPage-wrapper .buttonFilledDisabled {
  padding: 15px 25px 15px 25px;
}
}