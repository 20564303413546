.container-col-premium {
    position: relative;
    width: 100%;
    margin-bottom: 20px;
    background-color: #fff;
    padding: 20px;
    border-radius: 15px;
    box-shadow: 4px 10px 30px 0px rgba(0, 0, 0, 0.06);
    cursor: pointer;
}

.container-col-premium .image {
    position: relative;
    margin-bottom: 20px;
    object-fit: cover;
}

.container-col-premium .image .text-left {
    position: absolute;
    top: 24px;
    left: 12px;
    padding: 4px 8px;
    border-radius: 4px;
    background-color: #fff;
}

.container-col-premium .image .text-left p {
    font-size: 14px;
    line-height: 18px;
    font-weight: 700;
    text-transform: uppercase;
}

.container-col-premium .image .test-image {
    height: 220px;
    border-radius: 12px !important;
}

.container-col-premium .image .test-image img {
    height: 220px;
    border-radius: 20px !important;
    object-fit: cover;
}

.container-col-premium .image .text-right {
    position: absolute;
    top: 25px;
    right: 12px;
}

.container-col-premium .image .text-right span {
    display: block;
    float: right;
    margin-right: 17px;
}

.container-col-premium .image .text-right span:nth-last-child(3) {
    margin-top: -2px;
    margin-right: 0;
}

.container-col-premium .content .heart {
    position: absolute;
    top: 43%;
    left: 12px;
    padding: 10px;
    background-color: #efefef;
    border-radius: 50px;
    z-index: 2;
    box-shadow: 0px 1px 1px rgb(0 0 0 / 14%), 0px 2px 1px rgb(0 0 0 / 12%),
        0px 1px 3px rgb(0 0 0 / 20%);
}

.container-col-premium .content .heart .icon {
    filter: invert(49%) sepia(90%) saturate(1582%) hue-rotate(149deg) brightness(99%) contrast(101%);
}

.container-col-premium .content .premium {
    position: relative;
    width: 100%;
    text-align: center;
    padding: 4px 0;
    font-size: 14px;
    line-height: 16px;
    font-weight: 500;
    background: rgba(0, 0, 0, 0.75);
    color: #fff;
    border-radius: 0px 0px 12px 12px;
    user-select: none;

}

.container-col-premium .image .favorite_container {
    cursor: pointer;
    position: absolute;
    bottom: -20px;
    right: 100px;
    background-color: #fff;
    padding: 10px 12px;
    border-radius: 50%;
    box-shadow: 0px 1px 3px 0px #00000033;
    box-shadow: 0px 2px 1px 0px #0000001F;
    box-shadow: 0px 1px 1px 0px #00000024;
    z-index: 10;
}

.container-col-premium .image .favorite_container svg {
    width: 24px;
    height: 24px;
}

.container-col-premium .content .info-property .left-part {
    width: 100%
}

.property_top_price_container {
    display: flex;
    justify-content: flex-start;
    margin-top: 10px;
}

.container-col-premium .content .info-property .left-part .title {
    margin-top: 10px;
    text-align: left;
    margin-bottom: 10px;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 24px;
}

.container-col-premium .content .info-property .left-part .neighbor {
    text-align: left;
    margin-bottom: 10px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 210px;
}

.container-col-premium .content .info-property .left-part .neighbor img {
    margin-right: 8px;
}

.container-col-premium .content .info-property .left-part .neighbor span {
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 24px;
}

.container-col-premium .content .info-property .left-part .info-text img {
    margin-right: 4px;
    width: 24px;
    height: 24px;
}

.container-col-premium .content .info-property .left-part .info-text {
    float: left;
    margin-right: 9px;
    display: flex;
}

.container-col-premium .content .info-property .left-part .info-text .text {
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 24px;
}

.container-col-premium .content .info-property .left-part .info-text:nth-last-child(3) img {
    margin-top: 5px;
}

.container-col-premium .content .info-property .left-part .info-text:nth-last-child(1) img {
    margin-top: 5px;
}

.container-col-premium .content .info-property .right-part {
    float: right;
}

.container-col-premium .content .info-property .right-part .title-sub {
    font-style: normal;
    font-weight: bold;
    font-size: 23px;
    line-height: 45px;
    text-transform: uppercase;
    margin-bottom: 4px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    text-align: right;
}

.container-col-premium .content .info-property .right-part p {
    text-align: right;
    margin-bottom: 8px;
}

.container-col-premium .content .info-property .right-part p .title {
    margin-right: 9px;
    padding: 3px 8px;
    background-color: #ededed;
    border-radius: 4px;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 18px;
}

.container-col-premium .content .info-property .right-part .offer-number {
    margin-top: 35px;
    text-align: right;
    font-size: 10px;
    line-height: 11px;
}

.container-col-premium .content .info-property .right-part .offer-number span {
    font-weight: 700;
    text-decoration: underline;
}

.container-col-premium .content hr {
    /* margin: 20px 0 0px 0; */
    background-color: #014245;
    color: #014245;
}

.container-col-premium .content .info {
    padding: 10px 0px;
}

.container-col-premium .content .info .title {
    float: left;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 18px;
    text-align: right;
    margin-bottom: 0px;
}

.container-col-premium .content .info .title .send-offer {
    width: 44px;
    height: 44px;
    border-radius: 50%;
    margin-top: -20px;
    z-index: 1000;
    cursor: pointer;
}

.container-col-premium .content .info .title img:hover {
    transform: scale(1.2);
    transition: .5 all;
}

.container-col-premium .content .info .propertyOfferCount {
    float: right;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 18px;
    text-align: right;
    display: flex;
    justify-content: right;
    margin-bottom: 0px;
}

.container-col-premium .content .info .info-details {
    float: right;
}

.container-col-premium .content .info .info-details .info-text {
    margin-right: 14px;
}

.container-col-premium .content .info .info-details .info-text:nth-last-child(1) {
    margin-right: 0;
}

.container-col-premium .content .info .info-details .info-text img {
    margin-right: 7px;
}

.container-col-premium .content .info .info-details .info-text .text {
    font-size: 18px;
    font-weight: 500;
    line-height: 18px;
}

._FP5OY {
    cursor: pointer !important;
}

.property-squaremeters {
    display: flex;
}

.property-squaremeters .title {
    font-size: 16px !important;
}

.container-col-premium .image .premium_banner {
    position: absolute;
    bottom: 0px;
    width: 100%;
}

.container-col-premium .image .share {
    box-shadow: 0px 1px 1px 0px #00000024;
    cursor: pointer;
    position: absolute;
    bottom: -20px;
    right: 30px;
    background-color: #fff;
    border-radius: 50px;
    z-index: 10;
}

.container-col-premium .image .share.location img {
    width: 49px !important;
    height: 48px !important;
}

.container-col-premium .image .premium-banner {
    position: absolute;
    top: 0;
    right: 21px;
    width: 100%;
    border-radius: 0px 0px 12px 12px;
    background-color: #fff;
    width: 70px;
    height: 65px;
    padding: 3px 5px 5px;
    border-radius: 0px 0px 100px 100px;
}

.container-col-premium .image .premium-banner .premium_banner-title {
    margin: 0 auto;
    text-align: center;
    font-size: 12px;
    font-weight: bold;
    line-height: 20px;
    color: rgba(0, 188, 212, 1);
}

.container-col-premium .image .premium-banner .premium_banner-title img {
    margin: 0 auto;
    width: 26px;
    height: 28px;
}


.container-col-premium .image .premium_banner .verified-property {
    position: absolute;
    top: -30px;
    left: 0px;
    width: 40px;
    height: 40px;
    border-radius: 50px;
    background-color: transparent;
    z-index: 1;
}

.container-col-premium .image .premium_banner .verified-property-promoted {
    position: absolute;
    top: -22px;
    left: 0px;
    width: 40px;
    height: 40px;
    border-radius: 50px;
    background-color: transparent;
    z-index: 100;
}

.container-col-premium .image .premium_banner .verified-property img {
    width: 100% !important;
    height: 100% !important;
    z-index: 10000;
}

.is_sold_property_banner {
    position: absolute;
    top: 10px;
    left: 10px;
    background-color: #fff;
    padding: 3px 8px;
    border-radius: 4px;
    z-index: 1;
}

.is_sold_property_banner p {
    margin-bottom: 0;
}

.container-col-premium .descriptions img,
.container-col-premium .descriptions .pin-image {
    width: 26px !important;
    height: 28px !important;
}

.container-col-premium .descriptions .pin-image {
    float: left;
    margin-right: 8px;
    margin-bottom: 0;
}

.container-col-premium .descriptions .municipality {
    float: left;
    font-size: 23px;
    font-weight: 500;
    line-height: 37.5px;
    color: rgba(0, 188, 212, 1);
    margin-top: -4px;
    margin-bottom: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.container-col-premium .descriptions .municipality-smaller {
    float: left;
    font-size: 18px;
    font-weight: 500;
    line-height: 37.5px;
    color: rgba(0, 188, 212, 1);
    margin-top: -4px;
    margin-bottom: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.container-col-premium .descriptions .municipality-smaller-smaller {
    float: left;
    font-size: 16px;
    font-weight: 500;
    line-height: 37.5px;
    color: rgba(0, 188, 212, 1);
    margin-top: -4px;
    margin-bottom: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.property_top_price_container .info-text {
    display: inline-block;
    margin-right: 10px;
    margin-bottom: 10px;
    background-color: #000000;
    padding: 7.5px 14px;
    border-radius: 28px;
}

.property_top_price_container .info-text img {
    float: left;
    margin-top: 2px;
    width: 20px;
    height: 24px;;
    margin-right: 3px;
}

.property_top_price_container .info-text .text {
    font-size: 14px;
    font-weight: 500;
    line-height: 27px;
    color: rgba(255, 255, 255, 1);
}

.container-col-premium .info-text-type .info-text-type-property {
    background-color: #000000;
    display: inline-block;
    text-align: left;
    padding: 7.5px 14px;
    border-radius: 28px;
    margin-bottom: 10px;
}
.container-col-premium .info-text-type {
    margin-bottom: 10px;
    text-align: left;
}

.container-col-premium .info-text-type img {
    float: left;
    margin-top: 2px;
    width: 20px;
    height: 24px;;
    margin-right: 3px;
}

.container-col-premium .info-text-type .text {
    font-size: 14px;
    font-weight: 500;
    line-height: 27px;
    color: rgba(255, 255, 255, 1);
}

.container-col-premium .price-and-send-offer {
    padding: 0 15px;
}

.container-col-premium .price-and-send-offer .price {
    float: left;
    margin-bottom: 0;
}

.container-col-premium .price-and-send-offer .send-an-offer {
    float: right;
    margin-bottom: 5px;
}

.container-col-premium .price-and-send-offer .price .label {
    font-size: 18px;
    font-weight: 500;
    line-height: 22px;
    text-align: left;
    color: rgba(153, 153, 153, 1);
    margin-bottom: 0;
}

.container-col-premium .price-and-send-offer .price .format-price {
    font-size: 18px;
    font-weight: 600;
    line-height: 36px;
    color: rgba(0, 0, 0, 1);
    margin-bottom: 0;
}

.container-col-premium .price-and-send-offer .send-an-offer .send {
    font-size: 16px;
    font-weight: 700;
    line-height: 37.5px;
    color: #ffffff;
    background-color: rgba(0, 188, 212, 1);
    border-radius: 10px;
    text-transform: none;
    padding: 10px;
}

.container-col-premium .price-and-send-offer .send-an-offer .send:hover {
    background-color: rgba(0, 188, 212, 0.8);
}

@media screen and (max-width: 997px) {
    .container-col-premium .image .premium-banner .premium_banner-title img {
        width: 20px;
        height: 28px;
    }

    .container-col-premium .image .premium-banner {
        width: 60px;
        height: 50px;
        right: 30px;
    }

    .container-col-premium .image .premium-banner.search-page {
        width: 66px;
        height: 80px;
        right: 30px;
    }

    .container-col-premium .image .premium-banner .premium_banner-title span {
        font-size: 11px;
        margin-left: 0;
    }
}

@media screen and (max-width:810px) {
    .container-col-premium .content .info-property .right-part .title-sub {
        font-size: 22px;
    }
}

@media screen and (max-width:768px) {
    .container-col-premium {
        position: relative;
        width: 100%;
        margin-bottom: 32px;
        background-color: #fff;
        padding: 20px 10px 0px 10px;
        border-radius: 4px;
        filter: drop-shadow(0px 1px 1px rgba(0, 0, 0, 0.14)) drop-shadow(0px 2px 1px rgba(0, 0, 0, 0.12)) drop-shadow(0px 1px 3px rgba(0, 0, 0, 0.2));
    }
}

@media screen and (max-width: 767px) {
    .container-col-premium {
        padding: 5px !important;
        box-shadow: none;
        filter: none !important;
    }

    .property-squaremeters {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
    }

    .container-col-premium .content .info-property .right-part .title-sub {
        font-style: normal;
        font-weight: bold;
        font-size: 35px;
        line-height: 45px;
        text-transform: uppercase;
        margin-bottom: 4px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        width: max-content;
        text-align: left;
    }

    .container-col-premium {
        padding: 0px;
        filter: drop-shadow(0px 1.5px 0px rgba(0, 0, 0, 0.14));
        ;
    }

    .container-col-premium .price-and-send-offer {
        padding: 0;
    }
}

.property_price_container {
    margin-top: 10px;
}



.dialog-send-offer {
    text-align: center;
    position: relative;
}

.dialog-send-offer .dialog-send-offer-content {
    padding: 0 30px;
}
.dialog-send-offer {
    width: 740px;
    margin: 0 auto;
}
.dialog-send-offer .dialog-send-offer-content .title-header .title {
    font-size: 24px;
    font-weight: 700;
    line-height: 32.02px;
    text-align: center;
    margin-bottom: 0px;
    margin-top: 30px;
}

.dialog-send-offer .dialog-send-offer-content .text-first-paragraph {
    font-size: 16px;
    font-weight: 400;
    line-height: 21.34px;
    text-align: center;
}

.dialog-send-offer .dialog-send-offer-content .li {
    font-size: 16px;
    font-weight: 400;
    line-height: 21.34px;
    text-align: left;
    color: rgba(0, 0, 0, 0.75);
}

.dialog-send-offer .dialog-send-offer-content .title-header .close-icon {
    position: absolute;
    cursor: pointer;
    top: 30px;
    right: 30px;
}

.dialog-send-offer .dialog-send-offer-content .title-header .hr {
    border: 2px solid rgba(0, 0, 0, 0.1);
    margin-top: 0;
    margin-bottom: 10px;
}

.dialog-send-offer .dialog-send-offer-content .form-submit {
    padding: 5px;
}

.dialog-send-offer .dialog-send-offer-content .form-submit .text-field-price {
    outline: 1px solid rgba(0, 0, 0, 0.5);
    border: none;
    border-radius: 12px;
    padding: 10px;
    margin-bottom: 10px;
    width: 630px;
}

.dialog-send-offer .dialog-send-offer-content .form-submit .text-field-price.focus {
    outline: 1px solid rgba(0, 188, 212, 1) !important;
    border: none;
}

.dialog-send-offer .dialog-send-offer-content .form-submit .label-price {
    margin-bottom: 10px;
}

.dialog-send-offer .dialog-send-offer-content .form-submit .label-price-text {
    font-size: 18px;
    font-weight: 500;
    line-height: 37.5px;
    color: rgba(0, 0, 0, 0.75);
}

.dialog-send-offer .dialog-send-offer-content .form-submit .label-price-text-span {
    font-size: 18px;
    font-weight: 700;
    line-height: 37.5px;
    color: rgba(1, 35, 36, 1);
}

.dialog-send-offer .dialog-send-offer-content .form-submit .select-payment-method {
    background: rgba(6, 12, 11, 0.05);
    font-size: 18px;
    font-weight: 300;
    line-height: 36px;
    color: rgba(0, 0, 0, 1);
    margin-bottom: 15px;
    width: 630px;
    border-radius: 12px;
}

.dialog-send-offer .dialog-send-offer-content .form-submit .select-payment-method > div {
    padding: 7px 0;
}

.dialog-send-offer .dialog-send-offer-content .form-submit .select-payment-method svg {
    stroke: rgba(0, 0, 0, 1);
    fill: rgba(0, 0, 0, 1);
}

.dialog-send-offer .dialog-send-offer-content .form-submit .button-send {
    background: rgba(0, 188, 212, 1);
    color: #ffffff;
    font-size: 20px;
    font-weight: 700;
    line-height: 40px;
    width: 630px;
    text-transform: none;
    margin-bottom: 20px;
    border-radius: 12px;
}

.dialog-send-offer .dialog-send-offer-content .form-submit .info {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 630px;
    margin: 0 auto;
}

.dialog-send-offer .dialog-send-offer-content .form-submit .info .part-left {
    width: 7%;
    margin-right: 7px;
}

.dialog-send-offer .dialog-send-offer-content .form-submit .info .part-right {
    width: 100%;
    font-size: 12px;
    text-align: justify;
}

.dialog-send-offer .dialog-send-offer-content .form-submit .info .part-right p {
    margin-bottom: 0;
}


@media screen and (max-width: 767px) {
    .dialog-send-offer {
        width: 90%;
    }

    .dialog-send-offer .dialog-send-offer-content .title-header .title {
        font-size: 18px;
        padding: 0;
    }

    .dialog-send-offer .dialog-send-offer-content .form-submit .text-field-price,
    .dialog-send-offer .dialog-send-offer-content .form-submit .select-payment-method,
    .dialog-send-offer .dialog-send-offer-content .form-submit .button-send {
        width: 450px;
    }
}

.dialog-profile-change-offer {
    text-align: center;
}

.dialog-profile-change-offer-content {
    padding: 30px 120px;
    position: relative;
}

.dialog-profile-offer-accepted-content .be-a-virtual-assistant-earn-more-section .title {
    font-size: 25px;
    font-weight: 600;
    line-height: 40px;
    color: rgba(0, 0, 0, 1);
    margin-bottom: 0;
}

.dialog-profile-offer-accepted-content .be-a-virtual-assistant-earn-more-section .title-sub {
    font-size: 18px;
    font-weight: 400;
    line-height: 40px;
    color: rgba(0, 0, 0, 1);
    margin-bottom: 0;
}

.dialog-profile-offer-accepted-content .be-a-virtual-assistant-earn-more-section .see-details {
    font-size: 18px;
    font-weight: 700;
    line-height: 40px;
    width: 360px;
    padding: 15px 0;
    text-transform: none;
    margin-bottom: 20px;
    border-radius: 12px;
    border: 2px solid rgba(0, 188, 212, 1);
    background-color: #fff;
    color: rgba(0, 0, 0, 1);
}

.dialog-profile-offer-accepted-content .be-a-virtual-assistant-earn-more-section .see-details img {
    width: 25px;
    height: 25px;
    margin-right: 20px;
}

.dialog-profile-change-offer-content .title-header {
    padding: 0;
    margin-bottom: 8px;
    position: relative;
}

.dialog-profile-change-offer-content .title-header .title {
    font-size: 40px;
    font-weight: 600;
    line-height: 75px;
    text-align: center;
}

.dialog-profile-change-offer-content .title-header .close-icon {
    position: absolute;
    cursor: pointer;
    top: 30px;
    right: 30px;
}

.dialog-profile-change-offer-content .content {
    padding: 0;
    overflow-x: hidden;
}

.dialog-profile-change-offer-content .content .sub-title {
    font-size: 17px;
    line-height: 24px;
    font-weight: 400;
    margin-bottom: 32px;
}

.dialog-profile-change-offer-content .content .offer-information {
    padding: 0 145px;
    display: flex;
    margin-bottom: 32px;
}

.dialog-profile-change-offer-content .content .offer-information .minus {
    margin: 23px 30px 0 30px;
    font-size: 30px;
}

.dialog-profile-change-offer-content .content .offer-information .field-group {
    display: grid;
    text-align: left;
}

.dialog-profile-change-offer-content .content .offer-information .field-group .label {
    margin-bottom: 4px;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.4px;
    font-weight: 400;
    cursor: default;
}

.dialog-profile-change-offer-content .content .offer-information .field-group .box {
    padding: 4px 8px;
    background: #F4F4F4;
    border-radius: 4px;
    width: 150px;
    z-index: 10;
    border: 1px solid transparent;
}

.dialog-profile-change-offer-content .content .offer-information .field-group .box-error {
    border: 1px solid #F32013 !important;
}

.dialog-profile-change-offer-content .content .offer-information .field-group .box.new-offer {
    background: #E0F7FA;
    width: 200px !important;
}

.dialog-profile-change-offer-content .content .offer-information .field-group .box.new-offer .price-number {
    display: inline-block;
    padding: 4px 8px;
    background: #E0F7FA;
    border-radius: 4px;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 32px;
    text-transform: uppercase;
    color: #000000;
    width: 135px;
    border: none;
}

.dialog-profile-change-offer-content .content .announced-price {
    display: flex;
    padding: 0 35px;
    margin-bottom: 20px;
    align-items: center;
    justify-content: center;
}

.dialog-profile-change-offer-content .content .announced-price .label {
    font-size: 18px;
    line-height: 24px;
    font-weight: 400;
    margin-right: 10px;
}

.dialog-profile-change-offer-content .content .announced-price .box-price {
    padding: 4px 8px;
    margin-right: 16px;
    border-radius: 8px;
    background: #E8E8E8;
    color: #000000;
    font-size: 20px;
    line-height: 30px;
    font-weight: 700;
}

.dialog-profile-change-offer-content .content .announced-price .box-group {
    margin-right: 18px;
    display: flex;
    align-items: center;
}

.dialog-profile-change-offer-content .content .announced-price .box-group .box-text {
    padding: 3px 8px;
    background: #FFF59D;
    border-radius: 4px;
    font-size: 18px;
    line-height: 22px;
    font-weight: 900;
    margin-right: 6px;
}

.dialog-profile-change-offer-content .content .announced-price .box-group:last-child {
    margin-right: 0;
}

.dialog-profile-change-offer-content .content .description {
    margin-bottom: 44px;
    text-align: left;
}

.dialog-profile-change-offer-content .content .description .label {
    margin-bottom: 4px;
    font-size: 12px;
    line-height: 16px;
    font-weight: 400;
    letter-spacing: 0.4px;
}

.dialog-profile-change-offer-content .content .description .box {
    padding: 16px;
    border-radius: 8px;
    background: #F4F4F4;
}

.dialog-profile-change-offer-content .content .actions {
    margin-top: 8px;
}

.dialog-profile-change-offer-content .content .actions .button-send {
    height: 48px;
    padding: 15px 32px;
    background: #00BCD4;
    border-radius: 4px;
    color: #fff;
    text-transform: uppercase;
    margin-right: 34px;
    font-size: 14px;
    line-height: 18px;
    font-weight: 700;
}

.dialog-profile-change-offer .content .favorite-score {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    margin-bottom: 30px;
    width: 65%;
    margin: 0 auto 30px auto;
}

.dialog-profile-change-offer .content .favorite-score p {
    width: 61px;
    height: 61px;
    background-color: #ededed;
    margin: 0;
    border-radius: 50px;
    padding-top: 18px;
    font-size: 20px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0em;
    cursor: pointer;
    text-align: center;
}

.dialog-profile-change-offer .content .favorite-score p.active {
    background-color: #006064;
    color: #fff;
}


.dialog-profile-offer-accepted-content {
    padding: 64px 127px;
    justify-content: center;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.dialog-profile-offer-accepted-content .title-header {
    padding: 0;
    margin-bottom: 8px;
}

.dialog-profile-offer-accepted-content .title-header .title {
    display: inline-block;
    font-size: 48px;
    line-height: 52px;
    font-weight: 700;
}

.dialog-profile-offer-accepted-content .check-icon {
    margin-bottom: 15px;
    height: 76px;
    width: 76px;
}

.dialog-profile-offer-accepted-content .close-icon {
    cursor: pointer;
    position: absolute;
    top: 30px;
    right: 30px;
}

.dialog-profile-offer-accepted-content .content {
    padding: 0;
    overflow-x: hidden;
}

.dialog-profile-offer-accepted-content .content .sub-title {
    font-size: 18px;
    line-height: 24px;
    font-weight: 400;
    margin-bottom: 32px;
}

.dialog-profile-offer-accepted-content .content .actions {
    margin-top: 8px;
    justify-content: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.dialog-profile-offer-accepted-content .content .actions .button-close {
    height: 48px;
    padding: 15px 32px;
    background: #00bcd4;
    border-radius: 4px;
    color: #fff;
    text-transform: uppercase;
    font-size: 14px;
    line-height: 18px;
    font-weight: 700;
}


@media screen and (max-width: 1078px) {
    .dialog-profile-change-offer-content .content .offer-information {
        padding: 0;
        justify-content: center;
    }

    .dialog-profile-change-offer-content .content .announced-price {
        flex-direction: column;
        gap: 20px;
    }
}

@media screen and (max-width: 768px) {
    .dialog-profile-change-offer-content {
        padding: 40px 20px !important;
    }

    .dialog-profile-change-offer-content .content .offer-information {
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
}

@media screen and (max-width: 992px) {
    .dialog-send-offer {
        margin: 0 15px;
    }
}

@media screen and (max-width: 600px) {
    .dialog-send-offer .dialog-send-offer-content .title-header .title {
        font-size: 17px;
        line-height: 23px;
        padding: 0;
    }

    .dialog-send-offer .dialog-send-offer-content {
        padding: 0 10px;
    }

    .dialog-send-offer ul {
        padding-left: 13px;
    }

    .dialog-send-offer .dialog-send-offer-content .li {
        font-size: 11px;
        line-height: 15px;
    }

    .dialog-send-offer .dialog-send-offer-content .text-first-paragraph {
        font-size: 14px;
        margin-bottom: 0;
    }

    .dialog-send-offer .dialog-send-offer-content .title-header .close-icon {
        right: 10px;
        top: 15px;
    }

    .dialog-send-offer .dialog-send-offer-content .form-submit .text-field-price,
    .dialog-send-offer .dialog-send-offer-content .form-submit .select-payment-method,
    .dialog-send-offer .dialog-send-offer-content .form-submit .button-send,
    .dialog-send-offer .dialog-send-offer-content .form-submit .info {
        width: 250px;
        margin-bottom: 5px;
    }

    .dialog-send-offer .dialog-send-offer-content .form-submit .text-field-price,
    .dialog-send-offer .dialog-send-offer-content .form-submit .select-payment-method,
    .dialog-send-offer .dialog-send-offer-content .form-submit .button-send {
        height: 50px;
    }

    .dialog-send-offer .dialog-send-offer-content .form-submit .button-send,
    .dialog-send-offer .dialog-send-offer-content .form-submit .label-price-text,
    .dialog-send-offer .dialog-send-offer-content .form-submit .select-payment-method,
    .dialog-send-offer .dialog-send-offer-content .form-submit .text-field-price {
        font-size: 16px;
    }

    .dialog-send-offer .dialog-send-offer-content .form-submit .info {
        width: 100%;
    }

    .dialog-send-offer .dialog-send-offer-content .form-submit .info .part-right {
        font-size: 9px;
    }
}