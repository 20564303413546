.sellers-tab {
  position: relative;
}
.sellers-tab .cols {
  padding: 0;
  margin: 0;
}
.sellers-tab .sellers-content .link-box {
  margin-top: 32px;
  text-align: center;
}
.sellers-tab .sellers-content .link-box .link-seller {
  width: 100%;
  padding: 22px 14px;
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  color: #0097a7;
  border: 1px dashed #8c8c8c;
  box-sizing: border-box;
  border-radius: 8px;
  text-transform: uppercase;
}
.sellers-tab .sellers-content .link-box .link-seller .icon {
  margin-right: 5px;
  margin-top: 3px;
  filter: invert(33%) sepia(89%) saturate(2251%) hue-rotate(162deg)
    brightness(97%) contrast(101%);
  width: 20px;
  height: 20px;
}

.sellers-tab .sellers-content .account-box {
  margin-bottom: 16px;
  padding: 24px;
  background: #ffffff;
  border-radius: 4px;
  cursor: pointer;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px rgba(0, 0, 0, 0.12),
    0px 1px 3px rgba(0, 0, 0, 0.2);
}
.sellers-tab .sellers-content .account-box.active {
  box-sizing: border-box;
  border: 3px solid #4dd0e1;
  cursor: default;
}

.sellers-tab .service-progress {
  margin-bottom: 32px;
}
.sellers-tab .service-progress .progress-header {
  display: flex;
}
.sellers-tab .service-progress .progress-header .title {
  margin-right: 6px;
  font-family: Lora;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 26px;
}
.sellers-tab .service-progress .progress-header .icon {
  margin-top: 5px;
  width: 20px;
  height: 20px;
}
.sellers-tab .service-progress .progress-bar {
  padding: 5px;
  margin-bottom: 64px;
}

.sellers-tab .step-details {
  margin-bottom: 24px;
  background: #f2feff;
  border-radius: 12px;
  padding: 16px;
}

.sellers-tab .toggle-steps {
  margin-bottom: 24px;
  background: #f2feff;
  border-radius: 12px;
  padding: 16px;
  text-align: center;
}
.sellers-tab .toggle-steps .button-next {
  background: #00bcd4;
  border-radius: 4px;
  padding: 19px 32px;
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  text-transform: uppercase;
  color: #ffffff;
}
.sellers-tab .toggle-steps .button-previous {
  margin-right: 32px;
  border: 2px solid #00bcd4;
  border-radius: 4px;
  padding: 19px 32px;
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  text-transform: uppercase;
  color: #000000;
}
.sellers-tab .toggle-steps .button-video {
  margin-left: 32px;
  border: 2px solid #00bcd4;
  border-radius: 4px;
  padding: 19px 32px;
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  text-transform: uppercase;
  color: #000000;
}

.sellers-tab .client-requests {
  margin-bottom: 32px;
  padding-top: 7px; 
}
.sellers-tab .client-requests .client-requests-title {
  margin-top: 10px; margin-left: 7px;
  margin-bottom: 16px;
  font-family: Lora;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 26px;
}
.sellers-tab .client-requests .client-requests-panel {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px;
  margin-bottom: 8px;
  background: #ffffff;
}
.sellers-tab .client-requests .client-requests-panel:last-child {
  margin-bottom: 0px;
}
.sellers-tab .client-requests .client-requests-panel .icon {
  margin-right: 18px;
  filter: invert(33%) sepia(89%) saturate(2251%) hue-rotate(162deg)
    brightness(97%) contrast(101%);
  width: 20px;
  height: 20px;
}
.sellers-tab .client-requests .client-requests-panel .request-details {
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
  text-transform: uppercase;
  margin-top: 20px;
}

.sellers-tab .session {
  padding: 16px;
  background: #ffffff;
  border-radius: 12px;
  margin-top: 11px;
  display: flex;
  align-items: center;
}
.sellers-tab .session .info .title {
  margin-bottom: 16px;
  font-family: Lora;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 26px;
}
.sellers-tab .session .info .info-row {
  margin-bottom: 8px;
  display: inline-block;
  width: 100%;
}
.sellers-tab .session .info .info-row:last-child {
  margin-bottom: 0px;
}
.sellers-tab .session .info .info-row .photo {
  width: 24px;
  height: 24px;
  float: left;
  background: #323232;
  border-radius: 50%;
  margin-right: 4px;
}
.sellers-tab .session .info .info-row .name {
  float: left;
  margin-right: 8px;
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 21px;
}
.sellers-tab .session .info .info-row .text {
  float: left;
  margin-right: 8px;
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 24px;
}
.sellers-tab .session .info .info-row .link {
  float: left;
  margin-right: 8px;
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 24px;
  text-decoration-line: underline !important;
}

.sellers-tab .sellers-documents {
  margin-top: 24px;
  padding: 16px;
  background: #e8e8e8;
  border-radius: 12px;
}
.sellers-tab .sellers-documents .title {
  font-size: 24px;
  line-height: 28px;
  font-weight: 500;
}
.sellers-tab .sellers-documents .documents-section {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 16px;
}

.sellers-tab .sellers-documents .additional-document-box {
  border: 1px dashed #000000;
  box-sizing: border-box;
  border-radius: 8px;
}
.sellers-tab .sellers-documents .additional-document-box .button {
  padding: 18px 0px 30px 0px;
  font-weight: 500;
  text-transform: none;
}
.sellers-tab
  .sellers-documents
  .additional-document-box
  .button
  .button-info
  .header-content {
  display: flex;
  margin-bottom: 16px;
}
.sellers-tab
  .sellers-documents
  .additional-document-box
  .button
  .button-info
  .header-content
  .icon {
  margin-right: 9px;
}
.sellers-tab
  .sellers-documents
  .additional-document-box
  .button
  .button-info
  .header-content
  .text {
  font-family: Lora;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 26px;
}
.sellers-tab
  .sellers-documents
  .additional-document-box
  .button
  .button-info
  .footer-content {
  display: flex;
  justify-content: center;
  padding: 11px 8px;
}
.sellers-tab
  .sellers-documents
  .additional-document-box
  .button
  .button-info
  .footer-content
  .add-icon {
  vertical-align: middle;
  width: 16px;
  height: 16px;
  margin-right: 8px;
  filter: invert(33%) sepia(89%) saturate(2251%) hue-rotate(162deg)
    brightness(97%) contrast(101%);
}
.sellers-tab
  .sellers-documents
  .additional-document-box
  .button
  .button-info
  .footer-content
  .text {
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  text-transform: uppercase;
  color: #0097a7;
}

.sellers-tab .sellers-property {
  margin-top: 24px;
  padding: 16px;
  background: #e8e8e8;
  border-radius: 12px;
}
.sellers-tab .sellers-property .title {
  font-family: Lora;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 26px;
}

/* Dialog */
.dialog-document {
  text-align: center;
}
.dialog-document-content {
  margin: 64px 86px;
}
.dialog-document-content .close-icon {
  cursor: pointer;
  float: right;
  position: absolute;
  top: 0;
  right: 0;
  margin-top: 20px;
  margin-right: 21px;
}
.dialog-document-content .title {
  font-size: 36px;
  line-height: 42px;
  margin-bottom: 16px;
}
.dialog-document-content .content {
  padding: 0;
  overflow-x: hidden;
}
.dialog-document-content .content .text {
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 24px;
  text-align: left;
}
.dialog-document-content .content .actions {
  margin-top: 24px;
}

/* Dialog */
.dialog-session-setup {
  text-align: center;
}
.dialog-session-setup-content {
  margin: 64px 86px;
}
.dialog-session-setup-content .close-icon {
  cursor: pointer;
  float: right;
  position: absolute;
  top: 0;
  right: 0;
  margin-top: 20px;
  margin-right: 21px;
}
.dialog-session-setup-content .title {
  font-size: 36px;
  line-height: 42px;
  margin-bottom: 16px;
}
.dialog-session-setup-content .content {
  padding: 0;
  overflow-x: hidden;
}
.dialog-session-setup-content .content .text {
  font-size: 16px;
  line-height: 18px;
  font-weight: 400;
  text-align: left;
}
.dialog-session-setup-content .content .actions {
  margin-top: 24px;
}
.dialog-session-setup-content .content .actions .button-close {
  height: 40px;
  padding: 12px 32px;
  background: #000;
  border-radius: 12px;
  color: #ffffff;
  text-transform: none;
  font-size: 12px;
  line-height: 14px;
  font-weight: 400;
}

/* Dialog Session Setup */
.dialog-session-setup {
  text-align: center;
}
.dialog-session-setup-content {
  margin: 64px 117px 64px 116px;
}
.dialog-session-setup-content .close-icon {
  cursor: pointer;
  float: right;
  position: absolute;
  top: 0;
  right: 0;
  margin-top: 21px;
  margin-right: 21px;
}
.dialog-session-setup-content .title {
  margin-bottom: 8px;
  font-family: Lora;
  font-style: normal;
  font-weight: normal;
  font-size: 48px;
  line-height: 52px;
}

.dialog-session-setup-content .content {
  padding: 0;
  overflow-x: hidden;
}
.dialog-session-setup-content .content .text {
  padding: 0px 11px 32px 11px;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
}
.dialog-session-setup-content .content .form-group-field {
  margin-bottom: 4px;
  text-align: left;
}
.dialog-session-setup-content .content .form-group-field .label {
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: 900;
  font-size: 18px;
  line-height: 22px;
  text-transform: uppercase;
}

.dialog-session-setup-content .content .calendar {
  margin-top: 24px;
  padding: 16px;
  background: #ffffff;
  box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.14), 0px 1px 18px rgba(0, 0, 0, 0.12),
    0px 3px 5px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  text-align: left;
}
.dialog-session-setup-content .content .calendar .title {
  margin-bottom: 11px;
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: 900;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  text-transform: uppercase;
}

.dialog-session-setup-content .content .hour-free {
  margin-top: 24px;
  padding: 16px 32px;
  background: #ffffff;
  box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.14), 0px 1px 18px rgba(0, 0, 0, 0.12),
    0px 3px 5px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  margin-bottom: 16px;
  float: left;
  text-align: left;
}
.dialog-session-setup-content .content .hour-free .title {
  margin-bottom: 8px;
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: 900;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  text-transform: uppercase;
}
.dialog-session-setup-content .content .hour-free .chip {
  padding: 4px 12px;
  background: #f8f8f8;
  border-radius: 16px;
  float: left;
  margin-right: 8px;
  margin-bottom: 8px;
  cursor: pointer;
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 18px;
  color: #9d9d9d;
}
.dialog-session-setup-content .content .hour-free .chip.active {
  background: #80deea;
  color: #000000;
  cursor: default;
}
.dialog-session-setup-content .content .hour-free .chip.disabled {
  background: #ffffff;
  opacity: 0.4;
  cursor: not-allowed;
}

.dialog-session-setup-content .content .actions {
  margin-top: 24px;
}
.dialog-session-setup-content .content .actions .button-accept {
  width: 250px;
  padding: 15px 32px;
  background: #00bcd4;
  border-radius: 4px;
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  text-transform: uppercase;
  color: #ffffff;
}
.dialog-session-setup-content .content .actions .button-cancel {
  width: 250px;
  border: 1px solid #00bcd4;
  padding: 15px 32px;
  border-radius: 4px;
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  text-transform: uppercase;
  color: #000000;
}

/* Dialog Video Meeting */
.dialog-video-meeting {
  text-align: center;
}
.dialog-video-meeting-content {
  margin: 64px 86px;
}
.dialog-video-meeting-content .close-icon {
  cursor: pointer;
  float: right;
  position: absolute;
  top: 0;
  right: 0;
  margin-top: 20px;
  margin-right: 21px;
}
.dialog-video-meeting-content .title {
  font-size: 36px;
  line-height: 42px;
  margin-bottom: 16px;
}
.dialog-video-meeting-content .content {
  padding: 0;
  overflow-x: hidden;
}
.dialog-video-meeting-content .content .text {
  font-size: 16px;
  line-height: 18px;
  font-weight: 400;
}
.dialog-video-meeting-content .content .video-screen {
  background: #e8e8e8;
  border-radius: 8px;
  margin-top: 32px;
  height: 400px;
}
.dialog-video-meeting-content .content .video-screen .icon {
  margin-top: 90px;
  filter: invert(89%) sepia(9%) saturate(12%) hue-rotate(328deg) brightness(85%)
    contrast(84%);
}
.dialog-video-meeting-content .content .video-screen .controls {
  margin-top: 50px;
  display: flex;
  gap: 10px;
  justify-content: center;
}
.dialog-video-meeting-content .content .video-screen .controls .control-icon {
  padding: 15px;
  border-radius: 50%;
  background: #f2feff;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px rgba(0, 0, 0, 0.12),
    0px 1px 3px rgba(0, 0, 0, 0.2);
}
.dialog-video-meeting-content
  .content
  .video-screen
  .controls
  .control-icon.fullscreen {
  justify-self: flex-end;
}
.dialog-video-meeting-content .content .actions .button-accept {
  margin-right: 24px;
  padding: 15px 32px;
  background: #00bcd4;
  border-radius: 4px;
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  text-transform: uppercase;
  color: #ffffff;
}
.dialog-video-meeting-content .content .actions .button-cancel {
  border: 1px solid #00bcd4;
  padding: 15px 32px;
  border-radius: 4px;
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  text-transform: uppercase;
  color: #000000;
}

@media screen and (max-width: 1235px) {
  .sellers-tab .cols .col9of12 {
    width: 100% !important;
  }

  .sellers-tab .cols .col {
    padding: 0 !important;
  }
  
  .sellers-tab .cols  .col3of12  .sellers-content {
      display: flex;
      gap: 20px;
      width: 100%;
      overflow-x: auto;
      flex-direction: row;
  }
}

@media screen and (max-width: 400px) {
  .sellers-tab {
    padding: 0 !important;
  }
}
