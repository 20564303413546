.details { display: flex; }
.details .icon { margin-right: 18px; align-self: flex-start; }
.details .current-details .proposed-amount .title { margin-bottom: 8px; font-family: Lora; font-style: normal; font-weight: bold; font-size: 24px; line-height: 26px; }
.details .current-details .proposed-amount .text { margin-bottom: 16px; font-family: Source Sans Pro; font-style: normal; font-weight: normal; font-size: 18px; line-height: 24px; }
.details .current-details .proposed-amount .button-send { background: #00BCD4; border-radius: 0px 4px 4px 0px; padding: 19px 32px; font-family: Source Sans Pro; font-style: normal; font-weight: bold; font-size: 14px; line-height: 18px; text-transform: uppercase; color: #FFFFFF; }

.details .current-details .upcoming-payment .title { text-align: left; font-family: Lora; font-style: normal; font-weight: bold; font-size: 24px; line-height: 26px; }
.details .current-details .upcoming-payment .text { margin-bottom: 16px; text-align: left; font-family: Source Sans Pro; font-style: normal; font-weight: normal; font-size: 18px; line-height: 24px; }
.details .price { margin-top: -10px; margin-right: 5px; font-family: Source Sans Pro; font-style: normal; font-weight: bold; font-size: 40px; line-height: 70px; text-transform: uppercase; }

@media screen and (max-width: 500px) { 
    .details .current-details .upcoming-payment .title,
    .details .current-details .upcoming-payment .text {
        text-align: center;
    }
}