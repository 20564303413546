.document-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: fit-content;

  &-topmost {
    width: 100%;
    height: fit-content;
    // margin-top: 24px;
    margin-bottom: 32px;

    display: flex;
    flex-direction: column;
    align-items: center;

    background: #ffffff;
    border-radius: 8px;

    &-num {
      display: flex;
      justify-content: space-between;
      align-items: center;

      width: calc(100% - 32px);
      margin-top: 16px;
      > div {
        > h5 {
          font-family: 'Lora', sans-serif;
          font-style: normal;
          font-weight: bold;
          font-size: 24px;
          line-height: 26px;
          margin-bottom: 8px;
          /* identical to box height, or 108% */

          color: #000000;
        }
        > h6 {
          margin: 0;
          padding: 0;
          font-family: 'Source Sans Pro', sans-serif;
          font-style: normal;
          font-weight: normal;
          font-size: 18px;
          line-height: 24px;
          margin-bottom: 16px;

          color: #000000;
        }
      }
      > p {
        font-family: 'Source Sans Pro', sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        line-height: 24px;
        /* identical to box height, or 133% */

        color: #000000;
      }
    }
    &-progress {
      height: 8px;
      width: 100%;
      display: flex;
      background: #ededed;
      border-radius: 0px 0px 4px 4px;
      &-fill {
        background: #00838f;
        border-radius: 0px 0px 0px 4px;
        width: 33.33%;
        height: 100%;
      }
    }
  }

  &-upload-card {
    width: 100%;
    background: #ffffff;
    border-radius: 4px;
    margin: 0 24px 16px 24px;
    display: flex;
    flex-direction: column;
    align-items: center;

    &-body {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: calc(100% - 48px);
      height: 100%;

      &-left {
        width: fit-content;
        margin: 24px 0;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        > div {
          display: flex;
          justify-content: flex-start;
          align-items: flex-start;

          > img {
            margin-right: 10.51px;
            margin-left: 3.74px;
          }
          > h5 {
            margin: 0;
            padding: 0;
            font-family: 'Lora', sans-serif;
            font-style: normal;
            font-weight: bold;
            font-size: 24px;
            line-height: 26px;
            margin-bottom: 8px;
            /* identical to box height, or 108% */

            color: #000000;
          }
        }
        > p {
          margin: 0;
          padding: 0;
          font-family: 'Source Sans Pro', sans-serif;
          font-style: normal;
          font-weight: normal;
          font-size: 18px;
          line-height: 24px;
          /* identical to box height, or 133% */

          color: #000000;
        }
      }
      > button {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 8px 16px 8px 12px;
        outline: none;
        border: none;
        cursor: pointer;

        /* Cyan/500 */

        background: #00bcd4;
        border-radius: 4px;

        font-family: 'Source Sans Pro', sans-serif;
        font-style: normal;
        font-weight: bold;
        font-size: 14px;
        line-height: 18px;
        /* identical to box height, or 129% */

        text-transform: uppercase;

        color: #ffffff;
        > img {
          margin-right: 8px;
        }
      }
    }

    &-bottom-bar {
      background: #ef9a9a;
      border-radius: 0px 0px 4px 4px;
      width: 100%;
      height: 8px;
    }
  }
  &-additional {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 24px 50px;

    border: 1px dashed #000000;
    box-sizing: border-box;
    border-radius: 8px;

    > div {
      display: flex;
      justify-content: center;
      align-items: flex-start;

      > img {
        margin-right: 10.51px;
        margin-left: 3.74px;
      }
      > h5 {
        margin: 0;
        padding: 0;
        font-family: 'Lora', sans-serif;
        font-style: normal;
        font-weight: bold;
        font-size: 24px;
        line-height: 26px;
        margin-bottom: 8px;
        color: #000000;
      }
    }

    > button {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 8px 8px 8px 4px;

      border-radius: 4px;
      background-color: transparent;

      /* Inside Auto Layout */
      margin: 16px 0px;
      outline: none;
      border: none;
      cursor: pointer;

      border-radius: 4px;

      font-family: 'Source Sans Pro', sans-serif;
      font-style: normal;
      font-weight: bold;
      font-size: 14px;
      line-height: 18px;
      /* identical to box height, or 129% */

      text-transform: uppercase;

      color: #0097a7;
      > img {
        margin-right: 8px;
      }
    }

    &-bottom-bar {
      background: #ef9a9a;
      border-radius: 0px 0px 4px 4px;
      width: 100%;
      height: 8px;
    }
  }
}
