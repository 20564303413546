.third-party-public-lawyer-profile {
    display: flex;
    margin-top: 32px;
}

.third-party-public-lawyer-profile .avatar {
    position: relative;
    width: 178px;
    height: 178px;
    border-radius: 50%;
}

.third-party-public-lawyer-profile .avatar .photo {
    width: 178px;
    height: 178px;
    border-radius: 50%;
    object-fit: cover;
}

.third-party-public-lawyer-profile .avatar .icon {
    position: absolute;
    right: 0px;
    bottom: 10px;
    height: 30px;
    width: 30px;
}

.third-party-public-lawyer-profile .avatar >p{
    height: fit-content;
    display: flex;
}

.third-party-public-lawyer-profile .user-details {
    margin-left: 24px;
}

.third-party-public-lawyer-profile .user-details .title-primary {
    margin-bottom: 10px;
    font-family: Lora;
    font-style: normal;
    font-weight: 1;
    font-size: 48px;
    line-height: 52px;
    color: #000000;
}

.third-party-public-lawyer-profile .user-details .resize_forlong_names {
    margin-bottom: 10px;
    font-family: Lora;
    font-style: normal;
    font-weight: 1;
    font-size: 24px;
    line-height: 52px;
    color: #000000;
}

.third-party-public-lawyer-profile .user-details .rating {
    display: flex;
    margin-bottom: 17px;
}

.third-party-public-lawyer-profile .user-details .rating .title {
    margin-right: 10px;
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: 900;
    font-size: 18px;
    line-height: 22px;
    text-transform: uppercase;
    color: #000000;
}

.third-party-public-lawyer-profile .user-details .rating .star {
    width: 16px;
    height: 16px;
    margin-top: 2.5px;
    filter: invert(48%) sepia(93%) saturate(3629%) hue-rotate(157deg) brightness(90%) contrast(102%);
}

.third-party-public-lawyer-profile .user-details .rating .result {
    margin-left: 8px;
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 24px;
    color: #000000;
}

.third-party-public-lawyer-profile .user-details .additional {
    display: flex;
}

.third-party-public-lawyer-profile .user-details .additional .first-half .title {
    margin-bottom: 9px;
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    color: #656565;
}

.third-party-public-lawyer-profile .user-details .additional .block {
    width: 172px;
    height: 60px;
    display: flex;
    background: #F4F4F4;
    border-radius: 4px;
}

.third-party-public-lawyer-profile .user-details .additional .block .icon {
    width: 20px;
    height: 20px;
    margin: 19px 11px 21px 19px;
    color: #000000;
}

.third-party-public-lawyer-profile .user-details .additional .block .text {
    margin: 16px 16px 16px 0px;
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 24px;
    color: #000000;
}

.third-party-public-lawyer-profile .user-details .additional .second-half {
    margin-left: 32px;
}

.third-party-public-lawyer-profile .user-details .additional .second-half .block {
    width: 107px;
    background: #F4F4F4;
    border-radius: 4px;
}

.third-party-public-lawyer-profile .user-details .additional .second-half .title-content {
    display: flex;
}

.third-party-public-lawyer-profile .user-details .additional .second-half .title-content .title {
    margin-bottom: 9px;
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    color: #656565;
}

.third-party-public-lawyer-profile .user-details .additional .second-half .title-content .icon {
    width: 20px;
    height: 20px;
    color: #000000;
    margin-left: 11px;
    margin-top: -1px;
}

.third-party-public-lawyer-profile .about-info {
    width: 530px;
    min-height: 190px;
    max-height: 190px;
    margin-left: 34px;
    margin-right: 21px;
    padding: 16px;
    background: #F8F8F8;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px rgba(0, 0, 0, 0.12), 0px 1px 3px rgba(0, 0, 0, 0.2);
    border-radius: 12px;
    height: 148px;
    overflow-y: scroll;

}

.third-party-public-lawyer-profile .about-info .title {
    margin-bottom: 6px;
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 24px;
    text-transform: uppercase;
    color: #000000;
}

.third-party-public-lawyer-profile .about-info .paragraph {
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 24px;
    color: #000000;
    word-wrap: break-word;
    overflow-wrap: break-word;
    white-space: normal;
}

.third-party-public-lawyer-profile .price-info {
    text-align: center;
    align-items: center;
    display: flex;
    flex-direction: column;
}

.third-party-public-lawyer-profile .price-info .price {
    margin-top: 15px;
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: bold;
    font-size: 48px;
    line-height: 60px;
    text-transform: uppercase;
    color: #000000;
}

.third-party-public-lawyer-profile .price-info .price span {
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: 900;
    font-size: 18px;
    line-height: 22px;
    text-transform: uppercase;
    color: #000000;
}

.third-party-public-lawyer-profile .price-info .text {
    margin-bottom: 32px;
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 24px;
    color: #000000;
}

.third-party-public-lawyer-profile .price-info .text .icon {
    margin-left: 6px;
    vertical-align: bottom;
}

.third-party-public-lawyer-profile .price-info .button {
    width: 187px;
    height: 48px;
    padding: 15px 25px;
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 18px;
    background: #00BCD4;
    border-radius: 4px;
    text-transform: uppercase;
    color: #EFEFEF;
}

@media screen and (max-width: 1321px) {
    .third-party-public-lawyer-profile .about-info {
        width: 430px;
    }
}


@media screen and (max-width: 1239px) {
    .third-party-public-lawyer-profile .about-info {
        width: 330px;
    }
}

@media screen and (max-width: 1200px){
    .third-party-public-lawyer-profile .avatar p {
        width: 300px;
    }

    .third-party-public-lawyer-profile .user-details .title-primary{
        text-align: center;
    }
}


@media screen and (max-width: 1100px) {
    .lawyer-features-items {
        
        grid-template-columns: repeat(3, 1fr) !important;
        
    }
}

@media screen and (max-width: 1200px) {
    .third-party-public-lawyer-profile {
        display: flex;
        flex-direction: column;
        width: 80%;
        justify-content: center;
        align-items: center;
        gap: 30px;
    }

    .third-party-public-lawyer-profile .about-info {
        width: 430px;
    }

    .third-party-public-lawyer-profile .user-details {
        width: 430px;
        margin-top: 20px;
    }

    .third-party-public-lawyer-profile .user-details .additional .second-half {
        margin-left: 99px;
    }

    .third-party-public-lawyer-profile .user-details .additional .second-half .block {
        width: 100%;
    }
}


@media screen and (max-width: 830px) {
    .lawyer-features-items {
        
        grid-template-columns: repeat(2, 1fr) !important;
        
    }
}

@media screen and (max-width:768px){
    .third-party-public-lawyer-profile .about-info {
        max-height: 100%;
    }
}

@media screen and (max-width: 631px) {
    .third-party-public-lawyer-profile {
        width: 100%;
    }

    .third-party-public-lawyer-profile .user-details{
        margin-left: 0px !important;
    }

    .third-party-public-lawyer-profile .about-info {
        margin-left: 24px !important;
    }
}


@media screen and (max-width: 611px) {
    .third-party-public-lawyer-profile .about-info {
        width: 100%;
    }

    .third-party-public-lawyer-profile .user-details .additional {
        justify-content: space-between;
    }

    .third-party-public-lawyer-profile .user-details .additional .second-half {
        margin-left: 0px;
    }

    .third-party-public-lawyer-profile .user-details {
        width: 100% !important;
    }

    .third-party-public-lawyer-profile .avatar p{
        width: 100%;
    }

}


@media screen and (max-width: 550px) {
    .lawyer-features-items {
        
        grid-template-columns: repeat(1, 1fr) !important;
        
    }
}

@media screen and (max-width: 531px) {
    .third-party-public-lawyer-profile {
        width: 100%;
    }

    .third-party-public-lawyer-profile .user-details {
        width: 100%;
    }
}

@media screen and (max-width: 411px) { 
    .third-party-public-lawyer-profile .user-details .additional {
        flex-direction: column;
        gap: 20px;
    }

    .third-party-public-lawyer-profile .user-details .additional .block {
        width: 100%;
    }

    .third-party-public-lawyer-profile .user-details {
        margin-left: 0;
    }

    .third-party-public-lawyer-profile .about-info {
        margin-left: 0;
        margin-right: 0;
    }

    .virtual-assistant-profile-left-content > .bottom {
        margin-top: 20px;
    }

    .third-party-public-lawyer-profile .about-info {
        margin-left:  0 !important;
    }
}