.lawyer-profile-header-content { display: flex; margin-top: 32px; }
.lawyer-profile-header-content .avatar { position: relative; width: 178px;  border-radius: 50%; }
.lawyer-profile-header-content .avatar .photo { width: 178px; height: 178px; border-radius: 50%; }
.lawyer-profile-header-content .avatar .icon { position: absolute; right: 2px; bottom: 15px; width: 29px; }

.lawyer-profile-header-content .user-details { margin-left: 24px; }
.lawyer-profile-header-content .user-details .title-primary { margin-bottom: 10px; font-family: Lora; font-style: normal; font-weight: 1; font-size: 48px; line-height: 52px; color: #000000; }

.lawyer-profile-header-content .user-details .rating { display: flex; margin-bottom: 17px; }
.lawyer-profile-header-content .user-details .rating .title { margin-right: 10px; font-family: Source Sans Pro; font-style: normal; font-weight: 900; font-size: 18px; line-height: 22px; text-transform: uppercase; color: #000000; }
.lawyer-profile-header-content .user-details .rating .star { width: 16px; height: 16px; margin-top: 2.5px; filter: invert(48%) sepia(93%) saturate(3629%) hue-rotate(157deg) brightness(90%) contrast(102%); }
.lawyer-profile-header-content .user-details .rating .result { margin-left: 8px; font-family: Source Sans Pro; font-style: normal; font-weight: normal; font-size: 18px; line-height: 24px; color: #000000; }

.lawyer-profile-header-content .user-details .additional { display: flex; }
.lawyer-profile-header-content .user-details .additional .first-half .title { margin-bottom: 9px; font-family: Roboto; font-style: normal; font-weight: normal; font-size: 16px; line-height: 19px; color: #656565; }
.lawyer-profile-header-content .user-details .additional .block { width: 172px; height: 60px; display: flex; background: #F4F4F4; border-radius: 4px; }
.lawyer-profile-header-content .user-details .additional .block .icon { width: 20px; height: 20px; margin: 19px 11px 21px 19px; color: #000000; }
.lawyer-profile-header-content .user-details .additional .block .text { margin: 16px 16px 16px 0px; font-family: Source Sans Pro; font-style: normal; font-weight: bold; font-size: 18px; line-height: 24px; color: #000000; }

.lawyer-profile-header-content .user-details .additional .second-half { margin-left: 32px; }
.lawyer-profile-header-content .user-details .additional .second-half .block { width: 107px; background: #F4F4F4; border-radius: 4px; }
.lawyer-profile-header-content .user-details .additional .second-half .title-content { display: flex; }
.lawyer-profile-header-content .user-details .additional .second-half .title-content .title { margin-bottom: 9px; font-family: Roboto; font-style: normal; font-weight: normal; font-size: 16px; line-height: 19px; color: #656565; }
.lawyer-profile-header-content .user-details .additional .second-half .title-content .icon { width: 20px; height: 20px; color: #000000; margin-left: 11px; margin-top: -1px; }

.lawyer-profile-header-content .about-info { width: 530px; height: 160px; margin-left: 34px; margin-right: 21px; padding: 16px; background: #F8F8F8; box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px rgba(0, 0, 0, 0.12), 0px 1px 3px rgba(0, 0, 0, 0.2); border-radius: 12px; }
.lawyer-profile-header-content .about-info .title { margin-bottom: 6px; font-family: Source Sans Pro; font-style: normal; font-weight: bold; font-size: 20px; line-height: 24px; text-transform: uppercase; color: #000000; }
.lawyer-profile-header-content .about-info .paragraph { 
    font-family: Source Sans Pro; 
    font-style: normal; 
    font-weight: normal; 
    font-size: 18px; 
    line-height: 24px; 
    color: #000000; 
    word-wrap: break-word;
    overflow-wrap: break-word;
    white-space: normal;
}

.lawyer-profile-header-content .price-info { text-align: center; }
.lawyer-profile-header-content .price-info .price { margin-top: 27px; font-family: Source Sans Pro; font-style: normal; font-weight: bold; font-size: 48px; line-height: 60px; text-transform: uppercase; color: #000000; }
.lawyer-profile-header-content .price-info .price span { font-family: Source Sans Pro; font-style: normal; font-weight: 900; font-size: 18px; line-height: 22px; text-transform: uppercase; color: #000000; }

.lawyer-profile-header-content .price-info .text { margin-bottom: 32px; font-family: Source Sans Pro; font-style: normal; font-weight: normal; font-size: 18px; line-height: 24px; color: #000000; }
.lawyer-profile-header-content .price-info .text .icon { margin-left: 6px; vertical-align: bottom; }
.lawyer-profile-header-content .price-info .button { width: 187px; height: 48px; padding: 15px 25px; font-family: Source Sans Pro; font-style: normal; font-weight: bold; font-size: 14px; line-height: 18px; background: #00BCD4; border-radius: 4px; text-transform: uppercase; color: #EFEFEF; }