.header-seller {
    width: 100%;
    position: sticky;
    top: 0;
    background-color: #fff;
    z-index: 990;
}

.header-seller .navbar {
    padding: 14px 32px !important;
    position: relative;
    z-index: 990;
}

.header-seller .nav-menu {
    float: left;
    display: flex;
    margin-top: 13px;
    padding-left: 0;
    list-style: none;
}

.header-seller .nav-links {
    padding: 10px 15px;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    color: #000000;
    cursor: pointer;
    text-decoration: none;
    border-radius: 30px;
}

.header-seller .navbar .nav-item .nav-links.active {
    background-color: rgba(0, 188, 212, 1);
    color: #ffffff;
}

.header-seller .nav-links.dropdown {
    text-transform: lowercase;
    font-size: 12px;
    font-weight: 700;
}

.header-seller .nav-links.dropdown span::first-letter {
    text-transform: uppercase;
}

.header-seller .nav-links.dropdown:hover {
    background-color: transparent;
}

.header-seller .down-caret {
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 5px 5px 0 5px;
    border-color: #000 transparent transparent transparent;
    display: inline-block;
    margin-left: 9px;
    top: -1px;
    position: relative;
    transform: rotate(0deg);
    transition: all .25s ease-in;
}

.header-seller .open-caret {
    transform: rotate(180deg);
    transition: all .25s ease-out;
}

.header-seller .nav-item.dropdown {
    padding: 3px 0;
}

.header-seller #menu-list-grow {
    margin-top: -12px;
}

.header-seller .menu-icon {
    display: none;
}

/* logo */
.header-seller .navbar-logo {
    float: left;
    margin-top: 12px;
    margin-right: 32px;
}

/* search */
.header-seller .search {
    float: left;
    position: relative;
    margin-top: 4px;
    margin-right: 24px;
    background-color: rgba(244, 244, 244, 1);
    border-radius: 50px;
    width: 250px;
    height: 44px;
    margin-left: 24px;
    cursor: pointer;
    padding: 5px 16px;
}

.header-seller .search.focus-input-filed {
    outline: 2px solid #00BCD4;
}

.header-seller .search .autocomplete-dropdown-container-home {
    margin-top: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    width: 100%;
    display: flex;
    flex-direction: column;
}

.header-seller .search .search-icon {
    float: left;
    margin-top: 4px;
    width: 26px;
    height: 26px;
    filter: invert(49%) sepia(0%) saturate(1%) hue-rotate(100deg) brightness(97%) contrast(89%);
}

.header-seller .search-field {
    float: left;
    padding: 16px 10px !important;
    height: 28px;
    border: none;
    background-color: transparent;
    width: 80% !important;
    margin-bottom: 10px;
}

.header-seller .search-field::placeholder {
    font-weight: 400;
    font-size: 18px;
}

.header-seller .search .MuiFormControl-marginNormal {
    margin: 0
}

.header-seller .search .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] {
    padding: 0;
    background-color: #EDEDED;
}

.header-seller .drop-down {
    padding: 50px 0 !important
}

/* right part header */
.header-seller .right-part {
    float: right;
    display: flex;
    gap: 15px;
    justify-content: center;
    align-items: center;
}

.header-seller .right-part .add-property {
    float: left;
    margin-top: 20px;
}

.header-seller .right-part .add-property img {
    float: left;
    width: 24px;
    margin-top: -5px;
    margin-right: 8px;
}

.header-seller .right-part .add-property .add-text {
    float: left;
    font-size: 14px;
    line-height: 14px;
    font-weight: 700;
    color: #0097A7;
    text-transform: uppercase;
}

.header-seller .right-part .account-group {
    float: left;
    margin-top: 3px;
}

.header-seller .right-part .account-group .link {
    display: flex;
}

.header-seller .right-part .account-group .link img {
    float: left;
    width: 24px;
    margin-top: -5px;
    margin-right: 8px;
}

.header-seller .right-part .account-group .link .title {
    float: left;
    font-size: 14px;
    margin-bottom: 0px;
    line-height: 14px;
    font-weight: 700;
    color: #0097A7;
    text-transform: uppercase;
}

.header-seller .right-part .bell {
    position: relative;
    float: left;
}

.header-seller .right-part .seller-menu-icon{
  color: #0097A7 !important;
  margin-top: 0px !important;
  margin-top: -10px !important;
  margin-right:-10px ;
}

.header-seller .right-part .bell .icon {
    filter: invert(33%) sepia(89%) saturate(2251%) hue-rotate(162deg) brightness(97%) contrast(101%);
    width: 18px;
    height: 18px;
}

.header-seller .right-part .bell .notification-list-fade-in {
    color: #000;
    position: absolute;
    top: 50px;
    right: -70px;
    padding: 20px 23px;
    width: 410px;
    height: 250px;
    border-radius: 12px;
    background-color: #fff;
    visibility: visible;
    opacity: 1;
    transition: visibility 0s linear 0s, opacity 300ms;
    z-index: 1; 
    overflow-x: hidden;
    display: block;
}

.header-seller .right-part .bell .notification-list-fade-in:before {
    content: "";
    position: absolute;
    top: -30px;
    right: 64px;
    z-index: 1;
    border: solid 15px transparent;
    border-bottom-color: #CFCFCF;
}

.header-seller .right-part .bell .notification-list-fade-in .row-notification .image {
    float: left;
    width: 10px;
    height: 18px;
    margin-right: 11px;
}

.header-seller .right-part .bell .notification-list-fade-in .row-notification .author {
    float: left;
    font-size: 14px;
    font-weight: 700;
    margin-right: 5px;
}

.header-seller .right-part .bell .notification-list-fade-in .row-notification .text {
    float: left;
    font-size: 14px;
    font-weight: 400;
    margin-right: 5px;
}

.header-seller .right-part .bell .notification-list-fade-in .row-notification .offer-number {
    float: left;
    font-size: 14px;
    font-weight: 700;
}

.header-seller .right-part .bell .notification-list-fade-in .hr {
    margin: 12px 0;
    color: rgba(101, 101, 101, 1);
}

.header-seller .right-part .bell .notification-list-fade-out {
    width: 0;
    height: 0;
    display: none;
    opacity: 0;
    transition: visibility 0s linear 300ms, opacity 300ms;
}

.header-seller .right-part .bell .notification-list-fade-in::-webkit-scrollbar {
    width: 10px !important;
    background: #0097A7;
}

.header-seller .right-part .bell .notification-list-fade-in::-webkit-scrollbar-track {
    background: #fff;
    border-radius: 12px;
}

.header-seller .right-part .bell .notification-list-fade-in::-webkit-scrollbar-thumb {
    background-color: #0097A7;
    border-radius: 10px;
}

.header-seller .right-part .bell .notification-row {
    padding: 10px;
}

.header-seller .right-part .bell .notification-row .notification-title {
    font-size: 13px;
    margin: 0;
}

.header-seller .right-part .bell .notification-row .notification-content {
    font-size: 12px;
    margin: 0;
}

.header-seller .right-part .bell .notification-row .left-part {
    float: left;
}

.header-seller .right-part .bell .notification-row .right-part {
    float: right;
    font-size: 12px;
}

.header-seller .right-part .bell.notif .icon {
    filter: invert(33%) sepia(89%) saturate(2251%) hue-rotate(162deg) brightness(97%) contrast(101%);
}

.header-seller .right-part .bell .notification-list-fade-in .row-notification .text {
    float: left;
    font-size: 14px;
    font-weight: 400;
    margin-right: 5px;
}

.header-seller .right-part .bell .notification-list-fade-in .hr {
    margin: 12px 0;
    color: rgba(101, 101, 101, 1);
}

.header-seller .right-part .sidebar-menu-icon{
    margin-top: 5px;
}
.header-seller .right-part .notification-icon{
    margin-top: 4px;
}

.header-seller .section-fixed {
    background: rgba(1, 35, 36, 1);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 12px 0;
}
  
.header-seller .section-fixed img {
    margin-right: 10px;
    height: 30px;
    width: 30px;
}
  
.header-seller .section-fixed .text {
    font-size: 15px;
    font-weight: 700;
    line-height: 30px;
    letter-spacing: 1.0800000429153442px;
    text-align: center;
    color: rgba(255, 255, 255, 1);
    margin-bottom: 0;
}

@media only screen and (min-width: 1321px) {
    .header-seller .right-part .user-menu {
        display: none;
        
    }
    .header-seller .right-part .add-property-desktop{
        display: block;
    }
    
    .header-seller .navbar .nav-menu {
        display:flex;
        margin-right: 30px;
    }
 
    .header-seller .right-part .account-group {
        display: block;
    }

    .header-seller .search {
        width: 20%;
        margin-right: 0px; 
        margin-left:0px; 
    }
}

@media screen and (max-width: 1320px) {
    .header-seller .navbar .nav-menu .nav-item {
        display: none;
    }

    .header-seller .nav-menu {
        float: left;
        display: flex;
        margin-top: 14px;
        padding-left: 0;
        list-style: none;
      }

    .header-seller .nav-items {
        display: flex;
        justify-content: space-between;
    }
}

@media screen and (max-width:1299px){
    .header-seller .search {
        width: 30%; 
        margin-right: 0px;
    }
}

@media screen and (max-width:1144px){
    .header-seller .search {
        width: 34%; 
        margin-right: 0px;
    }
}

@media screen and (max-width:1108px){
    .header-seller .search {
        width: 32%; 
        margin-right: 0px;
    }
}

@media screen and (max-width:1076px){
    .header-seller .search {
        width: 32%; 
        margin-right: 0px;
    }
}

@media screen and (max-width:1046px){
    .header-seller .search {
        width: 30%; 
        margin-right: 0px;
    }
}

@media screen and (max-width:1020px){
    .header-seller .search {
        width: 22%; 
    }
}

@media screen and (max-width: 980px) {
    .header-seller .navbar-logo {
        margin-top: 8px;
    }

    .header-seller .nav-menu {
        margin-top: 6px;
    }

    .header-seller ul.nav-menu {
        margin-bottom: 0;
    }

    .header-seller .right-part .bell .icon {
        width: 20px;
        height: 20px;
    }

    .header-seller .right-part .sidebar-menu-icon {
        margin-top: 0;
    }

    .header-seller .search {
        width: 38%;
        margin-top: 0;
    } 

    .header-seller .right-part .account-group {
        display: none;
    } 
     .header-seller .right-part .add-property-desktop{
        display: none;
    }

    .header-seller .navbar .nav-menu .nav-item-lang-switcher{
        margin-top: 4px;
    }

    .header-seller .navbar {
        padding: 14px 32px !important;
    }
}

@media screen and (max-width:767px){
    .header-seller .search {
        display: none;
    }

    .header-seller .navbar {
        padding: 15px 32px !important;
    }

    .header-seller .right-part .sidebar-menu-icon {
        margin-top: 7px;
    }

    .css-2kgxp5-MuiSvgIcon-root{
        margin-left:0 !important
    }

    .user-menu{
        margin-top: -6px;
    }

    .nav-menu {
        display:none;
      }

      .language-option-tablet{
        display: inline-block;
        margin-top: -2px;
      }
     

      .header-seller .right-part {
        float: right;
        display: flex;
      }
      .lang-switcher select {
        height: 39px;
      }
      .header-seller .navbar-logo {
        margin-top: 4px;
      }

      .header-seller .right-part .notification-icon {
        margin-top: 2px;
      }

    .header-seller .section-fixed {
        padding: 14px 10px;
    }
      
    .header-seller .section-fixed .text {
        font-size: 16px;
    }
}



@media screen and (max-width:522px) {
    .header-seller .right-part {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 20px;
    }

    .header-seller .right-part .bell {
        margin-right: 0px;
    }

    .header-seller .navbar {
        padding: 18px 20px !important;
    }
}
@media screen and (max-width:489px){
    .home-seller .search-icon {
        display: none;
    }
}

@media screen and (max-width:453px){
      .header-seller .search {
        width: 40%;
        margin-left: 0px;
      }

      .header-seller .right-part {
        gap: 5px;
        margin-left: 10px;
      }

      .header-seller .search-field {
        width: 100% !important;
      }
      
      .home-buyer .headerTitle {
        font-size: 60px;
      }

      .header-seller .right-part .bell {
        margin-right: 5px;
        margin-top: -5px;
      }

      .header-seller .right-part .notification-icon {
        margin-top: 0;
      }

      .header-seller .right-part .bell.user-menu {
        margin-right: 0;
      }

      .header-seller .right-part .notification-icon{
        margin-right: 2px;
    }

    .header-seller .section-fixed {
        padding: 3px 10px;
    }

    .header-seller .section-fixed img {
        width: 40px;
    }

    .header-seller .section-fixed .text {
        font-size: 11px;
        line-height: 21px;
    }
}

@media screen and (max-width:412px){
    .header-seller .navbar-logo {
        margin-right: 0px;
        margin-top: 0;
    }

    .header-seller .right-part .sidebar-menu-icon {
        margin-top: 0px;
    }
}

@media screen and (max-width:390px){
    .navbar-logo-seller{
        width: auto;
        margin-right: 0px !important;
    }

    .header-seller .lang-switcher {
        margin-right: 0px;
      }
}

.header-seller .badge { position: absolute; top: -10px !important; right: -13px !important; padding: 2px 4px !important; border-radius: 50% !important; background: #00BCD4; color: white; font-size: 10px !important; }
