.no-photographer-container { margin: 64px 134px 180px 134px; background: #F8F8F8; border-radius: 10px; text-align: center; }
.no-photographer-container .title { font-size: 32px; line-height: 32px; font-weight: 700; font-family: Lora; margin-bottom: 8px; }
.no-photographer-container .text { margin: 0 20px 24px 20px; font-size: 18px; line-height: 24px; font-family: Source Sans Pro; font-weight: 400; }
.no-photographer-container .box-content { margin-bottom: 24px; }
.no-photographer-container .box-content .box { padding: 40px 24px 26px 24px; background: #EDEDED; border-radius: 4px; text-align: center; }
.no-photographer-container .box-content .box .main-icon .icon { margin-bottom: 10px; width: 92px; height: 92px; }
.no-photographer-container .box-content .box .text-box { font-size: 16px; line-height: 18px; font-family: Source Sans Pro; font-weight: 400; }
.no-photographer-container .button { padding: 15px 32px; background: #00BCD4; color: #fff; border-radius: 4px; text-transform: uppercase; font-family: Source Sans Pro; font-size: 14px; line-height: 18px; font-weight: 700; }

.no-photographer-container .box-360{
  margin-bottom: 30px;
}

@media screen and (max-width:1224px){
 
   
  .no-photographer-container .box-content{
     display: flex;
     flex-direction: column;
     gap:20px;
  }

  .no-photographer-container .box-content .col4of12{
     width:100%
  }

  .no-photographer-container .box-content .text {
     margin: 0px;
     text-align: left;
   }

  
}

@media screen and (max-width:1034px){
 .no-photographer-container { 
   margin: 64px 10px;
   padding: 32px 116px;
   background: #F8F8F8;
   border-radius: 10px;
   text-align: center;
 }
}

@media screen and (max-width:768px){
 .no-photographer-container {
   margin: 64px 10px;
   padding: 32px 0px;
 }
}

@media screen and (max-width: 600px){
 .no-photographer-container .text {
   margin-bottom: 20px;
   text-align: center;
 }
}

@media screen and (max-width: 500px){
  .no-photographer-container .box-content .col{
    padding: 0 !important;
  }
 }
 
 
