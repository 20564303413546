.home-virtual-assistant .header-virtual-assistant-content {
  justify-content: flex-start;
  flex-direction: row;
  background-size: cover;
  background-position: center;
  background-image: url("https://d3kuhob3u5bpy2.cloudfront.net/d86fe9c3-60a8-48cb-9c1b-9b48d522641b.png"),
    linear-gradient(180deg, #eff8fe 80%, #ffffff 100%);
  display: flex;
  width: 100%;
  align-items: center;
}

.home-virtual-assistant .header-virtual-assistant-content .title {
  font-family: "Poppins";
  font-size: 35px;
  font-weight: 700;
  line-height: 40px;
  text-align: left;
  color: rgba(1, 35, 36, 1);
  margin-bottom: 40px;
}

.home-virtual-assistant .header-virtual-assistant-content .title-sub {
  color: rgba(1, 35, 36, 1);
  font-family: "Poppins";
  font-size: 30px;
  font-weight: 700;
  line-height: 40px;
  text-align: left;
  margin-bottom: 40px;
}

.home-virtual-assistant .header-virtual-assistant-content .text {
  color: rgba(1, 35, 36, 1);
  font-family: "Poppins";
  font-size: 25px;
  line-height: 40px;
  text-align: left;
}

.home-virtual-assistant .header-virtual-assistant-content .part-left {
  padding: 100px 50px;
}
.home-virtual-assistant .header-virtual-assistant-content > .container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.home-virtual-assistant .header-virtual-assistant-content .part-right {
  transform: scale(0.7);
  margin-top: -40px;
}
.home-virtual-assistant .header-virtual-assistant-content .part-right .virtual-assistant-home-three-images{
  display: flex;
  flex-direction: column;
  width: 100%;
}
.home-virtual-assistant .header-virtual-assistant-content .part-right .virtual-assistant-home-three-images .first-image{
  width: 100%;
  object-fit: fill;
  border-radius: 10px;
}
.home-virtual-assistant .header-virtual-assistant-content .part-right .virtual-assistant-home-three-images .down-row{
  display: flex;
  flex-direction: row;
  margin-top: 15px;
  justify-content: space-between; 
  width: 100%; 
  object-fit: cover;
}
.home-virtual-assistant .header-virtual-assistant-content .part-right .virtual-assistant-home-three-images .down-row .second-image{
  display: flex;
  width: 57%;
  border-radius: 10px;
  object-fit: cover;
}
.home-virtual-assistant .header-virtual-assistant-content .part-right .virtual-assistant-home-three-images .down-row .third-image{
  display: flex;
  width: 40%;
  border-radius: 10px;
  object-fit: cover;
}
.home-virtual-assistant .why-abidors {
  margin: 0 70px 30px 70px;
}

.home-virtual-assistant .why-abidors .content .content-item {
  box-shadow: 4px 10px 30px 0px rgba(0, 0, 0, 0.06);
  background: rgba(255, 255, 255, 1);
  padding: 20px 40px;
  margin-bottom: 10px;
  border-radius: 30px;
}

.home-virtual-assistant .why-abidors .content .content-item img {
  float: left;
  margin-right: 25px;
  width: 20%;
}

.home-virtual-assistant .why-abidors .content .content-item .content-text {
  float: left;
  width: 71%;
}

.home-virtual-assistant
  .why-abidors
  .content
  .content-item
  .content-text
  .title {
  font-family: "Poppins";
  font-size: 20px;
  font-weight: 700;
  line-height: 40px;
  text-align: left;
}

.home-virtual-assistant
  .why-abidors
  .content
  .content-item
  .content-text
  .text {
  font-family: "Poppins";
  font-size: 18px;
  font-weight: 400;
  line-height: 40px;
  text-align: justify;
  padding: 0;
}

.home-virtual-assistant
  .why-abidors
  .content
  .content-item
  .content-text
  .button-view {
  font-family: "Poppins";
  font-size: 18px;
  font-weight: 700;
  text-align: center;
  padding: 10px 30px;
  border-radius: 5px;
  color: #fff;
  background-color: #00bcd4;
  text-transform: none;
  float: right;
}

.home-virtual-assistant
  .why-abidors
  .content
  .content-item
  .content-text
  .button-view:hover {
  background-color: rgba(0, 188, 212, 0.8);
}
.home-virtual-assistant .set-up-you-account-and-add-your-services {
  text-align: center;
}

.home-virtual-assistant .set-up-you-account-and-add-your-services .col {
  float: none !important;
  display: inline-block;
  margin: 0 auto;
  padding: 0 10px;
}

.home-virtual-assistant .set-up-you-account-and-add-your-services .title {
  font-family: "Poppins";
  font-size: 30px;
  font-weight: 700;
  margin-bottom: 20px;
  text-align: center;
  color: rgba(0, 188, 212, 1);
  padding: 0 200px;
}

.home-virtual-assistant .set-up-you-account-and-add-your-services .content {
  background: rgba(249, 249, 249, 0.5);
  margin-bottom: 10px;
  padding: 15px 10px;
  border-radius: 15px;
}

.home-virtual-assistant
  .set-up-you-account-and-add-your-services
  .content:hover {
  background: rgba(249, 249, 249, 1);
}

.home-virtual-assistant
  .set-up-you-account-and-add-your-services
  .content
  .content-left {
  float: left;
  width: 11%;
  margin-right: 15px;
}

.home-virtual-assistant
  .set-up-you-account-and-add-your-services
  .content
  .content-right {
  float: left;
  width: 80%;
}

.home-virtual-assistant
  .set-up-you-account-and-add-your-services
  .content
  .content-right
  .content-title {
  font-family: "Poppins";
  font-size: 20px;
  font-weight: 700;
  line-height: 30px;
  text-align: left;
  margin-bottom: 0;
}

.home-virtual-assistant
  .set-up-you-account-and-add-your-services
  .content
  .content-right
  .content-text {
  font-family: "Poppins";
  font-size: 15px;
  color: rgba(255, 0, 0, 1);
  font-weight: 700;
  line-height: 30px;
  text-align: left;
  margin-bottom: 0;
}

.home-virtual-assistant .my-clients {
  text-align: center;
}

.home-virtual-assistant .my-clients .col {
  float: none !important;
  display: inline-block;
  margin: 0 auto;
  padding: 0 10px;
  vertical-align: middle;
}

.home-virtual-assistant .my-clients .title {
  font-family: "Poppins";
  font-size: 35px;
  font-weight: 700;
  line-height: 40px;
  margin-bottom: 10px;
}

.home-virtual-assistant .my-clients .title-sub {
  font-family: "Poppins";
  font-size: 20px;
  font-weight: 400;
  line-height: 35px;
  padding: 0 100px;
}

.home-virtual-assistant
  .my-clients
  .active-customers-and-successfully-performed-services {
  margin: 0 200px;
}

.home-virtual-assistant
  .my-clients
  .active-customers-and-successfully-performed-services
  .content {
  border: 5px solid rgba(0, 188, 212, 1);
  border-radius: 30px;
  padding: 20px 0;
  margin-bottom: 30px;
}

.home-virtual-assistant
  .my-clients
  .active-customers-and-successfully-performed-services
  .content
  .title-content {
  font-family: "Poppins";
  font-size: 30px;
  font-weight: 700;
  line-height: 40px;
  margin-bottom: 0;
}

.home-virtual-assistant
  .my-clients
  .active-customers-and-successfully-performed-services
  .content
  .number {
  font-family: "Poppins";
  font-size: 20px;
  line-height: 30px;
  margin-bottom: 0;
  color: rgba(255, 0, 0, 1);
  font-weight: 700;
  margin-bottom: 0;
}

.home-virtual-assistant
  .my-clients
  .active-customers-and-successfully-performed-services
  .content
  .number.up {
  color: rgba(0, 128, 0, 1);
}

.home-virtual-assistant
  .my-clients
  .active-customers-and-successfully-performed-services
  .content
  .hr {
  position: relative;
  height: 40px;
}

.home-virtual-assistant
  .my-clients
  .active-customers-and-successfully-performed-services
  .content
  .hr:after {
  content: "";
  position: absolute;
  bottom: 0;
  top: 50%;
  left: 50%;
  border-left: 1px solid rgba(0, 0, 0, 1);
  transform: translate(-50%);
}

.home-virtual-assistant
  .my-clients
  .active-customers-and-successfully-performed-services
  .content
  .text {
  font-family: "Poppins";
  font-size: 20px;
  font-weight: 400;
  line-height: 30px;
  margin-right: 20px;
}

.home-virtual-assistant .my-clients .my-referrals-button {
  background-color: #00bcd4;
  color: white;
  border-radius: 7px;
  padding: 10px;
}

.home-virtual-assistant .last-added .title {
  font-family: "Poppins";
  font-size: 40px;
  font-weight: 600;
  line-height: 60px;
  text-align: center;
}

.home-virtual-assistant .virtual-section-video {
  margin: 0 160px 40px 160px;
}

.home-virtual-assistant .virtual-section-video video {
  border-radius: 30px;
}

.home-virtual-assistant .virtual-section-video .video-play-button-container {
  top: 50% !important;
  left: 50% !important;
  transform: translate(-50%, -50%);
}

.home-virtual-assistant .va-prifle-bonuses-analytics {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 30px;
}
.home-virtual-assistant .va-prifle-bonuses-analytics .box {
  display: flex;
  flex-direction: column;
  width: 50%;
  box-shadow: 4px 10px 30px 0px rgba(0, 0, 0, 0.06);
  background: rgba(255, 255, 255, 1);
  padding: 20px 40px;
  padding-right: 100px;
  margin-bottom: 10px;
  border-radius: 30px;
  align-items: baseline;
}
.home-virtual-assistant .va-prifle-bonuses-analytics .box .content {
  display: flex;
  flex-direction: column;
  width: 90%;
  gap: 10px;
}

.home-virtual-assistant .va-prifle-bonuses-analytics .box .title {
  font-family: "Poppins";
  font-size: 20px;
  font-weight: 700;
  line-height: 40px;
  text-align: left;
}

.home-virtual-assistant .va-prifle-bonuses-analytics .box .progress-bar {
  margin-top: 10px;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.home-virtual-assistant
  .va-prifle-bonuses-analytics
  .box
  .progress-bar
  .progress-amount {
  margin: auto;
}
.home-virtual-assistant .va-prifle-bonuses-analytics .box .progress-steps {
  display: flex;
  flex-direction: row;
  gap: 7px;
  border-bottom: 1px solid #00000040;
}

.home-virtual-assistant .va-prifle-bonuses-analytics .box .progress-steps-last {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  font-size: 15px;
}

.home-virtual-assistant
  .va-prifle-bonuses-analytics
  .box
  .view-profile-button-container {
  display: flex;
  flex-direction: row-reverse;
  width: 100%;
  font-size: 15px;
}
.home-virtual-assistant
  .va-prifle-bonuses-analytics
  .box
  .view-profile-button-container
  > button {
  background-color: #ededed;
  border: none;
  outline: none;
  color: #00bcd4;
  padding: 8px;
  font-size: 15px;
  border-radius: 5px;
}

.home-virtual-assistant .va-prifle-bonuses-analytics .box .earn-20-extra {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 15px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  margin-top: 10px;
  gap: 15px;
}
.home-virtual-assistant
  .va-prifle-bonuses-analytics
  .box
  .earn-20-extra
  .gift-icon {
  width: 60px;
  height: 60px;
}

.home-virtual-assistant
  .va-prifle-bonuses-analytics
  .box
  .earn-20-extra
  .information {
  display: flex;
  flex-direction: column;
}
.home-virtual-assistant
  .va-prifle-bonuses-analytics
  .box
  .earn-20-extra
  .information
  .title {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 15px;
}

.home-virtual-assistant
  .va-prifle-bonuses-analytics
  .box
  .earn-20-extra
  .information
  .description {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  color: #656575;
  text-align: left;
}
@media screen and (max-width: 1150px) {
  .home-virtual-assistant .va-prifle-bonuses-analytics .box {
    padding: 20px 20px;
  }
  .home-virtual-assistant .va-prifle-bonuses-analytics .box .content {
    width: 100%;
  }
}

@media screen and (max-width: 991px) {
  .home-virtual-assistant .header-virtual-assistant-content .title {
    font-size: 30px;
    margin-bottom: 10px;
  }

  .home-virtual-assistant .header-virtual-assistant-content .title-sub {
    font-size: 25px;
    margin-bottom: 10px;
  }

  .home-virtual-assistant .header-virtual-assistant-content .text {
    font-size: 20px;
  }

  .home-virtual-assistant .header-virtual-assistant-content .part-left {
    padding: 20px;
  }

  .home-virtual-assistant .header-virtual-assistant-content .part-right {
    transform: scale(1);
    margin-top: 30px;
  }

  .home-virtual-assistant .why-abidors {
    margin: 0 30px 30px 30px;
  }

  .home-virtual-assistant .why-abidors .content .content-item {
    padding: 15px 20px;
  }

  .home-virtual-assistant .why-abidors .content .content-item .content-text {
    width: 76%;
  }

  .home-virtual-assistant
    .why-abidors
    .content
    .content-item
    .content-text
    .text {
    line-height: 30px;
  }

  .home-virtual-assistant .my-clients .title {
    font-size: 30px;
  }

  .home-virtual-assistant .my-clients .title-sub {
    line-height: 30px;
    padding: 0 20px;
  }

  .home-virtual-assistant
    .my-clients
    .active-customers-and-successfully-performed-services {
    margin: 0 20px;
    padding: 10px 0;
  }

  .home-virtual-assistant
    .my-clients
    .active-customers-and-successfully-performed-services
    .cols {
    margin: 0;
  }

  .home-virtual-assistant
    .my-clients
    .active-customers-and-successfully-performed-services
    .content {
    padding: 10px 0;
  }

  .home-virtual-assistant
    .my-clients
    .active-customers-and-successfully-performed-services
    .content
    .title-content {
    font-size: 25px;
  }

  .home-virtual-assistant
    .my-clients
    .active-customers-and-successfully-performed-services
    .content
    .text {
    font-size: 18px;
  }
}
@media screen and (max-width: 826px) {
  .home-virtual-assistant .va-prifle-bonuses-analytics {
    flex-direction: column;
    gap: 10px;
  }
  .home-virtual-assistant .va-prifle-bonuses-analytics .box {
    width: 100%;
  }
  .home-virtual-assistant
    .va-prifle-bonuses-analytics
    .box
    .progress-steps
    > p {
    font-size: 15px;
  }
  .home-virtual-assistant
    .va-prifle-bonuses-analytics
    .box
    .progress-steps-last {
    display: flex;
    flex-direction: column;
    justify-content: left;
    align-items: start;
    width: 100%;
    font-size: 15px;
  }
}
@media screen and (max-width: 767px) {
  .home-virtual-assistant .header-virtual-assistant-content .part-right {
    transform: scale(1);
    margin: 0 auto;
  }

  .home-virtual-assistant .header-virtual-assistant-content .col.col7of12 {
    width: 100%;
  }

  .home-virtual-assistant .header-virtual-assistant-content .col.col5of12 {
    width: 100%;
  }

  .home-virtual-assistant .why-abidors {
    margin: 30px 10px;
  }

  .home-virtual-assistant .why-abidors .content .content-item {
    padding: 20px 10px;
  }

  .home-virtual-assistant .why-abidors .content .content-item img {
    float: none;
    width: 50%;
    margin-right: 0;
  }

  .home-virtual-assistant
    .why-abidors
    .content
    .content-item
    .content-text
    .text {
    text-align: center;
  }

  .home-virtual-assistant
    .why-abidors
    .content
    .content-item
    .content-text
    .title {
    text-align: center;
  }

  .home-virtual-assistant .why-abidors .content .content-item .content-text {
    width: 100%;
  }

  .home-virtual-assistant
    .why-abidors
    .content
    .content-item
    .content-text
    .button-view {
    float: none;
    display: block;
    text-align: center;
    margin: 0 auto;
  }

  .home-virtual-assistant
    .my-clients
    .active-customers-and-successfully-performed-services
    .content
    .text {
    margin-right: 0;
  }

  .home-virtual-assistant .last-added .title {
    font-size: 30px;
    font-weight: 600;
    line-height: 45px;
  }

  .home-virtual-assistant .virtual-section-video {
    margin: 0 15px 30px 15px;
  }

  .home-virtual-assistant .virtual-section-video video {
    border-radius: 0;
  }

  .home-virtual-assistant .my-clients .title {
    margin: 0 10px;
  }
}
