.successful-verification {
  padding: 64px 0 21px 0;
  text-align: center;
  margin: 0 auto;
  background-color: #fff;
  z-index: 0.1;
}

.successful-verification .loginForm {
  margin: 0 auto;
  text-align: center;
  height: 100%;
}
.successful-verification .loginForm .loginTitle {
  font-size: 48px;
  line-height: 52px;
  font-weight: 600;
  margin-bottom: 16px;
}
.successful-verification .loginForm .textFieldContainer {
  width: 674px;
  display: block;
  margin: 0 auto 8px auto;
  border-radius: 4px;
  background-color: #e2e2e2;
}
.successful-verification .loginForm .checkbox {
  margin-top: -4px;
  color: #000 !important;
}
.successful-verification .loginForm .checkboxFormControl {
  display: block;
  user-select: none;
}
.successful-verification .loginForm .forgot-password-btn {
  /* margin-bottom: 24px;  */
  padding: 0;
}
.successful-verification .loginForm .button {
  background: #00bcd4;
  border-radius: 4px;
  color: #fff;
  padding: 15px 32px;
  text-transform: uppercase;
  font-size: 14px;
  line-height: 18px;
  font-weight: 700;
}
.successful-verification .loginForm .button:hover {
  background: #00bcd4;
}

.successful-verification .line {
  width: 670px;
  background: linear-gradient(180deg, rgba(204, 243, 255, 0) 0%, #ccf3ff 100%);
  margin: 25px auto;
  z-index: 999;
}
.successful-verification .text {
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 24px;
  line-height: 26px;
  font-weight: 700;
}
.successful-verification .properColor {
  position: relative;
  display: inline-block;
  padding: 8px;
  color: #0097a7;
  font-size: 14px;
  line-height: 18px;
  border-radius: 4px;
  font-weight: 700;
  text-transform: uppercase;
  z-index: 99;
}
.successful-verification .image {
  margin-top: -270px;
  background: linear-gradient(180deg, rgba(204, 243, 255, 0) 0%, #ccf3ff 100%);
}

.successful-verification .checkbox-forgot-password-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.successful-verification .no-account {
  display: flex;
  justify-content: center;
  align-items: center;
}

.successful-verification .line-with-or {
  width: 674px;
  text-align: center;
  position: relative;
  margin: 0 auto 8px auto;
}

.successful-verification .line-with-or::before,
.successful-verification .line-with-or::after {
  content: "";
  display: inline-block;
  width: 45%;
  height: 1px;
  background-color: #55555569;
  vertical-align: middle;
}

.successful-verification .line-with-or::before {
  margin-right: 10px;
}

.successful-verification .line-with-or::after {
  margin-left: 10px;
}

.successful-verification .or-text {
  display: inline-block;
  font-weight: bold;
  font-size: 14px;
}

.successful-verification .auth-footer-image {
  position: relative;
  background: linear-gradient(180deg, rgba(204, 243, 255, 0) 0%, #ccf3ff 100%);
}

.successful-verification .auth-footer-image-caption {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.successful-verification .social-login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  z-index: 999;
}

@media screen and (max-width: 1000px) {
  .successful-verification .image {
    margin-top: -200px;
  }
}

@media screen and (max-width: 790px) {
  .successful-verification .line {
    width: 100%;
  }
}

@media screen and (max-width: 767px) {
  .successful-verification .loginForm .textFieldContainer {
    width: 100%;
  }

  .successful-verification .loginForm {
    padding: 0 20px;
  }

  .successful-verification .image {
    margin-top: -150px;
  }
}

@media screen and (max-width: 600px) {
  .successful-verification .image {
    margin-top: -100px;
  }
}

@media screen and (max-width: 500px) {
  .successful-verification .image {
    margin-top: -50px;
  }
}

@media screen and (max-width: 768px) {
  .successful-verification .line-with-or {
    width: 100%;
    text-align: center;
    position: relative;
    margin: 0 auto 8px auto;
  }

  .successful-verification .line-with-or::before,
  .successful-verification .line-with-or::after {
    width: 30%;
    margin-right: 5px;
    margin-left: 5px;
  }
  .successful-verification .social-login-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
  }

  .successful-verification .checkbox-forgot-password-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
  }

  .successful-verification .no-account {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}

.registration-successful {
  position: relative;
  text-align: center;
  margin-top: 64px;
}

.registration-successful .title {
  font-size: 48px;
  line-height: 52px;
  font-weight: 600;
  margin-bottom: 8px;
}

.registration-successful .text {
  font-size: 18px;
  line-height: 24px;
  font-weight: 400;
  margin-bottom: 24px;
}

.registration-successful .icon {
  margin-bottom: 35px;
}

.registration-successful .button {
  position: relative !important;
  padding: 15px 32px !important;
  background: #00bcd4 !important;
  border-radius: 4px !important;
  color: #fff !important;
  font-size: 14px !important;
  line-height: 18px !important;
  font-weight: 700 !important;
  text-transform: uppercase !important;
}

.registration-successful .button:hover {
  background: #00bcd4 !important;
}

.registration-successful .image {
  margin-top: -270px;
  margin-bottom: 20px;
  background: linear-gradient(180deg, rgba(204, 243, 255, 0) 0%, #ccf3ff 100%);
}

.generate-new-activation-link-title {
  font-size: 18px;
  line-height: 24px;
  font-weight: 400;
  margin-bottom: 24px;
}
@media screen and (max-width: 1312px) {
  .account-verification .image {
    margin-top: -100px;
    margin-bottom: 20px;
    background: linear-gradient(
      180deg,
      rgba(204, 243, 255, 0) 0%,
      #ccf3ff 100%
    );
  }
}

@media screen and (max-width: 954px) {
  .account-verification {
    position: relative;
    text-align: center;
    margin: 140px 20px 128px 20px !important;
  }
}

@media screen and (max-width: 600px) {
  .account-verification {
    position: relative;
    text-align: center;
    margin: 50px 20px 128px 20px !important;
  }
}

@media screen and (max-width: 576px) {
  .account-verification .title {
    font-size: 40px;
  }
}

@media screen and (max-width: 518px) {
  .account-verification .image {
    margin-top: -50px;
    margin-bottom: 20px;
  }
}
