/* ------------------------------------------------------------ *
    #generic styles
* ------------------------------------------------------------ */
html {
    min-height: 100vh;
    width: 100%;
}

body {
    overflow-y: auto;
    min-height: 100vh;
    width: 100%;
    line-height: 1.3;
    text-size-adjust: none;
    -webkit-text-size-adjust: none;
    background-color: #fff;
    font-family: Roboto, sans-serif;
    font-weight: 400;
    user-select: auto;
}

body.manrope-class {
    font-family: 'Manrope' !important;
}

body.poppins-class {
    font-family: 'Poppins' !important;
}

body.manrope-class .MuiButton-root {
    font-family: 'Manrope' !important;
}

body.poppins-class .MuiButton-root {
    font-family: 'Poppins' !important;
}

body.manrope-class .MuiFormControlLabel-label {
    font-family: 'Manrope' !important;
}

body.poppins-class .MuiFormControlLabel-label {
    font-family: 'Poppins' !important;
}

body .MuiPickersDay-day {
    background: #F2F5F8;
    color: #000;
    margin-bottom: 6px;
}

body .MuiPickersDay-current {
    background: #E0F7FA;
    color: #0097A7;
}

body .MuiPickersDay-daySelected {
    background: #80DEEA;
    color: #000;
}

body .MuiPickersDay-daySelected:hover {
    background: #80DEEA;
    color: #000;
}

body .MuiPickersCalendarHeader-transitionContainer p::first-letter {
    text-transform: uppercase;
}

body::-webkit-scrollbar {
    width: 10px !important;
    background: #0097A7;
}

body::-webkit-scrollbar-track {
    background: #fff;
    border-radius: 12px;
}

body::-webkit-scrollbar-thumb {
    background-color: #0097A7;
    border-radius: 10px;
}

#root {
    transform-origin: 50% 0;
    max-width: 1440px;
    margin: 0 auto;
}

a {
    background-color: transparent;
    text-decoration: none !important;
    cursor: pointer;
}

a:hover {
    text-decoration: underline;
}

*::before,
*::after {
    box-sizing: border-box;
}

*::selection {
    background-color: #00BCD4;
    color: #fff;
}

ul {
    margin: 0;
    padding: 0
}

.header,
.footer,
.main,
.accents {
    position: relative;
    width: 100%;
}

.header {
    z-index: 40;
}

.footer {
    z-index: 30;
}

.main {
    z-index: 20;
}

.accents {
    z-index: 10;
}

.clearfix:after {
    content: "";
    clear: both;
    display: table;
}

img,
iframe,
video,
audio,
object {
    max-width: 100%;
}

article,
aside,
details,
figcaption,
figure,
picture,
footer,
header,
hgroup,
main,
menu,
nav,
section,
summary {
    display: block;
}

img,
iframe {
    border: 0 none;
}

img {
    height: auto;
    display: inline-block;
    vertical-align: top;
    transition: all 0.3s;

}

table {
    border-collapse: collapse;
    border-spacing: 0;
}

label:hover,
input[type="radio"]:hover,
input[type="checkbox"]:hover {
    cursor: pointer;
}

textarea {
    overflow: auto;
    resize: none;
}

button,
input,
select,
textarea {
    transition: all 0.3s;
}

small {
    font-size: 80%;
}

sub,
sup {
    position: relative;
    font-size: 75%;
    line-height: 0;
}

mark {
    background-color: transparent;
    color: var(--color-secondary);
}

h1,
h2,
h3,
h4,
h5,
h6 {
    line-height: 1.2;
    color: var(--color-titles);
}

h1 {
    font-size: 42px;
}

h2 {
    font-size: 30px;
}

h3 {
    font-size: 26px;
}

h4 {
    font-size: 22px;
}

h5 {
    font-size: 20px;
}

h6 {
    font-size: 18px;
}

/* global styles */
.container {
    max-width: 100%;
    width: 1440px;
    margin: 0 auto;
}

/* ------------------------------------------------------------ *
	#grid
* ------------------------------------------------------------ */
.cols::before,
.cols::after {
    content: ' ';
    display: table;
    clear: both;
}

.cols,
.col {
    box-sizing: border-box;
}

.cols {
    margin-right: 20px;
    margin-left: 20px;
    padding-right: 20px;
    padding-left: 20px;
}

.col {
    min-height: 10px;
    float: left;
    padding: 0 20px;
}

.cols-full {
    padding-right: 0;
    padding-left: 0;
}

.cols-no-gutter {
    margin-right: 0;
    margin-left: 0;
}

.cols-no-gutter .col {
    padding: 4px;
}

.cols-full.cols-no-gutter {
    padding-right: 0;
    padding-left: 0;
}

.col1of12 {
    width: 8.33333333%;
}

.col2of12 {
    width: 16.66666667%;
}

.col3of12 {
    width: 25%;
}

.col4of12 {
    width: 33.33333333%;
}

.col5of12 {
    width: 41.66666667%;
}

.col6of12 {
    width: 50%;
}

.col7of12 {
    width: 58.33333333%;
}

.col8of12 {
    width: 66.66666667%;
}

.col9of12 {
    width: 75%;
}

.col10of12 {
    width: 83.33333333%;
}

.col11of12 {
    width: 91.66666667%;
}

.col12of12 {
    width: 100%;
}


/* style loader */
#loading-indicator:before {
    content: '';
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #b9b9b985;
    z-index: 1500;
}

#loading-indicator:after {
    content: '';
    position: fixed;
    top: 45%;
    left: 48%;
    width: 80px;
    height: 80px;
    font-size: 1.5rem;
    color: white;
    text-align: center;
    font-weight: bold;
    border: 12px solid #c5c5c5;
    border-radius: 50%;
    border-top: 12px solid #00BCD4;
    z-index: 1501;
    animation: spin 2s linear infinite;
    transform: translate(-50%, -50%);
}

#loading-indicator {
    opacity: 0;
    pointer-events: none;
    transition: opacity 300ms ease-in-out;
}

#loading-indicator.open {
    opacity: 1;
    pointer-events: all;
}

@keyframes spin {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

.App .FormGroup {
    margin: 0 15px 20px;
    padding: 0;
    border-style: none;
    background-color: #7795f8;
    will-change: opacity, transform;
    box-shadow: 0 6px 9px rgba(50, 50, 93, 0.06), 0 2px 5px rgba(0, 0, 0, 0.08), inset 0 1px 0 #829fff;
    border-radius: 4px;
}

* {
    box-sizing: border-box;
}

body,
html {
    background-color: #f6f9fc;
    font-size: 18px;
    font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
    margin: 0;
}

.DemoPickerWrapper {
    padding: 0 12px;
    font-family: "Source Code Pro", monospace;
    box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
    border-radius: 3px;
    background: white;
    margin: 24px 0 48px;
    width: 100%;
}

.DemoPicker {
    font-size: 18px;
    border-radius: 3px;
    background-color: white;
    height: 48px;
    font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
    border: 0;
    width: 100%;
    color: #6772e5;
    outline: none;
    -webkit-appearance: none;
}

.DemoWrapper {
    margin: 0 auto;
    max-width: 500px;
    padding: 0 24px;
    display: flex;
    flex-direction: column;
    height: 100vh;
}

.Demo {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-bottom: 40%;
}

.App label {
    color: #6b7c93;
    font-weight: 300;
    letter-spacing: 0.025em;
}

.App button {
    white-space: nowrap;
    border: 0;
    outline: 0;
    display: inline-block;
    height: 40px;
    line-height: 40px;
    padding: 0 14px;
    box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
    color: #fff;
    border-radius: 4px;
    font-size: 15px;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 0.025em;
    background-color: #6772e5;
    text-decoration: none;
    -webkit-transition: all 150ms ease;
    transition: all 150ms ease;
    margin-top: 10px;
}

.App button:hover {
    color: #fff;
    cursor: pointer;
    background-color: #7795f8;
    transform: translateY(-1px);
    box-shadow: 0 7px 14px rgba(50, 50, 93, 0.1), 0 3px 6px rgba(0, 0, 0, 0.08);
}

.App input,
.App .StripeElement {
    display: block;
    margin: 10px 0 20px 0;
    max-width: 500px;
    padding: 10px 14px;
    font-size: 1em;
    font-family: "Source Code Pro", monospace;
    box-shadow: rgba(50, 50, 93, 0.14902) 0px 1px 3px,
        rgba(0, 0, 0, 0.0196078) 0px 1px 0px;
    border: 0;
    outline: 0;
    border-radius: 4px;
    background: white;
}

.App input::placeholder {
    color: #aab7c4;
}

.App input:focus,
.App .StripeElement--focus {
    box-shadow: rgba(50, 50, 93, 0.109804) 0px 4px 6px,
        rgba(0, 0, 0, 0.0784314) 0px 1px 3px;
    -webkit-transition: all 150ms ease;
    transition: all 150ms ease;
}

.App .StripeElement.IdealBankElement,
.App .StripeElement.FpxBankElement,
.App .StripeElement.PaymentRequestButton {
    padding: 0;
}

.App .StripeElement.PaymentRequestButton {
    height: 40px;
}

.sub-section-button {
    padding: 19px 48px !important;
    background: #00BCD4 !important;
    border-radius: 4px !important;
    font-family: Source Sans Pro, sans-serif !important;
    font-style: normal !important;
    font-weight: 700 !important;
    font-size: 14px !important;
    line-height: 18px !important;
    text-transform: uppercase !important;
    color: #FFFFFF !important;
    border: none !important;
}

.sub-section-button:hover {
    background: #00BCD4 !important;
}

.grid-container {
    display: grid;
    grid-gap: 16px;
    grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
    grid-auto-rows: 265px;
}

iframe#webpack-dev-server-client-overlay{display:none!important}

.Toastify__toast-container:has(.is-property-active) {
    width: fit-content !important;
}

.Toastify__toast-container {
    overflow-y: auto;
    overflow-x: hidden;
    max-height: 100vh;
}

.Toastify__toast-container::-webkit-scrollbar {
    width: 10px !important;
    background: #0097A7;
}

.Toastify__toast-container::-webkit-scrollbar-track {
    background: #fff;
    border-radius: 12px;
}

.Toastify__toast-container::-webkit-scrollbar-thumb {
    background-color: #0097A7;
    border-radius: 10px;
}

.tab.separator-tab::before {
    content: '';
    position: absolute;
    height: 1px;
    background-color: #aaaaaa;
    width: 90%;
    top: 0px;
    left: 50%;
    transform: translateX(-50%);
    border-radius: 999px;
}

.virtual-assistant-container {
    position: relative;
}

.virtual-assistant-container .close-icon-modal-virtual-assistant {
    position: absolute;
    top: 10px;
    right: 10px;
    background-color: #fff;
    padding: 4px;
    border-radius: 50px;
}

.virtual-assistant-container img.close-icon-modal-virtual-assistant {
    width: 18px;
    height: 18px;
}

.virtual-assistant-modal {
    margin: 0 10px;
    cursor: pointer;
    position: fixed;
    bottom: 0;
    right: 0;
    z-index: 10;
}

.dialog-virtual-assistant-modal {
    text-align: center;
    position: relative;
    margin: 0 10px;
}

.dialog-virtual-assistant-modal .dialog-virtual-assistant-modal-content {
    padding: 20px;
}

.dialog-virtual-assistant-modal .dialog-virtual-assistant-modal-content .actions {
    margin-top: 30px;
}

.dialog-virtual-assistant-modal .dialog-virtual-assistant-modal-content .close-icon {
    float: right;
    cursor: pointer;
}

.dialog-virtual-assistant-modal .dialog-virtual-assistant-modal-content .title {
    font-size: 20px;
    font-weight: 600;
    line-height: 30px;
    color: rgba(0, 0, 0, 1);
    margin-bottom: 0;
}

.dialog-virtual-assistant-modal .dialog-virtual-assistant-modal-content .title-sub {
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    color: rgba(0, 0, 0, 1);
    margin-bottom: 10px;
}

.dialog-virtual-assistant-modal .dialog-virtual-assistant-modal-content .see-details {
    font-size: 14px;
    font-weight: 700;
    line-height: 40px;
    padding: 5px 50px;
    text-transform: none;
    margin-bottom: 20px;
    border-radius: 12px;
    background-color: #00BCD4;
    color: #ffffff;
}

.dialog-virtual-assistant-modal .dialog-virtual-assistant-modal-content .see-details.no {
    background-color:  #ffffff;
    color: #00BCD4;
    border: 1px solid #00BCD4;
    margin-left: 20px;
}

.dialog-virtual-assistant-modal .video video {
    height: 240px !important;
    width: 85% !important;
}

.dialog-virtual-assistant-modal .video {
    position: relative;
}

.dialog-virtual-assistant-modal .video .video-play-button-container {
    top: 50% !important;
    left: 50% !important;
    transform: translate(-50%, -50%);
}

.video-play-button-container{
    position: absolute;
    top: 30%;
    left: 37%;
    z-index: 1;
}

.virtual-assistant-modal {
    width: 90px;
    height: 90px;
}

@media screen and (max-width: 767px) {
    .virtual-assistant-modal {
        width: 100px;
        height: 100px;
    }
}

@media screen and (max-width: 500px) {
    .virtual-assistant-modal {
        width: 70px;
        height: 70px;
    }

    .virtual-assistant-container .close-icon-modal-virtual-assistant {
        top: 5px;
        right: 5px;
    }
    
    .virtual-assistant-container img.close-icon-modal-virtual-assistant {
        width: 16px;
        height: 16px;
    }
}