.profile-virtual-assistant {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}


@media screen and (max-width:500px){
    .profile-virtual-assistant {
        padding: 0px;
    }

    .profile-lawyer-relationship-suggestion {
        margin: 10px !important;
    }
}