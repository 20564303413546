.review-section {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.review-section h3 {
    margin: 0;
    padding: 0;
    font-style: normal;
    font-weight: 1;
    font-size: 30px;
    line-height: 52px;
    text-align: center;
    color: #000000;
}

.review-section .reviews-slider {
    margin: 30px 0 50px;
}
.review-section .reviews-slider p {
    text-align: center;
    margin-top: 32px;
}

.slick-prev:before,
.slick-next:before {
  color: #00BCD4 !important;
}

@media screen and (max-width: 500px) {
    .review-section h3 {
        line-height: 36px !important;
    }
}