.seller-upload-3d-dialog .MuiBackdrop-root {
  background-color: rgba(255, 255, 255, 0.25) !important;
  backdrop-filter: blur(25px);
}
.seller-upload-3d-dialog .MuiDialogContent-root:first-child {
  width: 905px !important;
  height: 493px !important;
  background-color: red;
}
.seller-upload-3d-modal {
  width: 905px;
  height: 500px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 24px 38px rgba(0, 0, 0, 0.14),
    0px 9px 46px rgba(0, 0, 0, 0.12), 0px 11px 15px rgba(0, 0, 0, 0.2);
  border-radius: 12px;
}
.seller-upload-3d-modal {
  width: 905px;
  height: 500px;
}
.seller-upload-3d-modal .closeIcon {
  position: absolute;
  top: 22.67px;
  right: 22.67px;
  cursor: pointer;
}
.seller-upload-3d-modal .closeIcon > img {
  width: 18.67px;
  height: 18.67px;
}

.seller-upload-3d-modal > img {
  width: 76.67px;
  height: 69px;
}
.seller-upload-3d-modal > h1 {
  font-family: Lora;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px;
  margin-top: 23.05px;
}
.seller-upload-3d-modal > p {
  font-family: Source Sans Pro;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  margin-top: 8px;
  width: 672px;
  text-align: center;
}
.seller-upload-3d-modal .dropZone {
  border: 1px dashed #8c8c8c;
  width: 672px;
  height: 128px;
  margin-top: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.seller-upload-3d-modal .dropZone .upload {
  display: flex;
}
.seller-upload-3d-modal .dropZone .upload > p {
  font-family: Source Sans Pro;
  font-size: 17px;
  font-weight: 700;
  line-height: 18px;
  color: #0097a7;
  margin-right: 5px;
}
