.dialog-seller-virtual-meeting-abort-request { text-align: center; }
.dialog-seller-virtual-meeting-abort-request-content { padding: 64px 52px; }
.dialog-seller-virtual-meeting-abort-request-content .title-header { padding: 0; margin-bottom: 8px; }
.dialog-seller-virtual-meeting-abort-request-content .title-header .title { display: inline-block; font-size: 48px; line-height: 52px; font-family: Lora sans-serif; font-weight: 700; }
.dialog-seller-virtual-meeting-abort-request-content .title-header .close-icon { position: absolute; top: 20px; right: 20px; cursor: pointer; }

.dialog-seller-virtual-meeting-abort-request-content .content { padding: 0; overflow-x: hidden; }
.dialog-seller-virtual-meeting-abort-request-content .content .sub-title { font-size: 18px; line-height: 24px; font-weight: 400; margin-bottom: 24px; font-family: Source Sans Pro sans-serif; padding: 0 40px; }

.dialog-seller-virtual-meeting-abort-request-content .content .actions { margin-top: 8px; display: flex; align-items: center; justify-content: center; }
.dialog-seller-virtual-meeting-abort-request-content .content .actions .button-abort { height: 45px; width: 150px; padding: 15px 32px; background: #00BCD4; border-radius: 4px; color: #fff; text-transform: uppercase; margin-right: 24px; font-size: 14px; line-height: 18px; font-family: Source Sans Pro sans-serif; font-weight: 700; }
.dialog-seller-virtual-meeting-abort-request-content .content .actions .button-close { height: 45px; width: 150px; padding: 15px 32px; border: 2px solid #00BCD4; border-radius: 4px; color: #000; text-transform: uppercase; font-size: 14px; line-height: 18px; font-family: Source Sans Pro sans-serif; font-weight: 700;  }