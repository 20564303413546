.buyers-tab { position: relative; }
.buyers-tab .cols{
    padding: 0;
    margin: 0;
}

.buyers-tab .buyers-content .link-box { margin-top: 32px; text-align: center; }
.buyers-tab .buyers-content .link-box .link-buyer {  width: 100%; padding: 22px 14px; font-family: Source Sans Pro; font-style: normal; font-weight: bold; font-size: 14px; line-height: 18px; color: #0097A7; border: 1px dashed #8C8C8C; box-sizing: border-box; border-radius: 8px; text-transform: uppercase; }
.buyers-tab .buyers-content .link-box .link-buyer .icon { margin-right: 5px; margin-top: -3px; filter: invert(33%) sepia(89%) saturate(2251%) hue-rotate(162deg) brightness(97%) contrast(101%); width: 20px; height: 20px; }

.buyers-tab .buyers-content .account-box { margin-bottom: 16px; padding: 24px; background: #FFFFFF; border-radius: 4px; cursor: pointer; box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px rgba(0, 0, 0, 0.12), 0px 1px 3px rgba(0, 0, 0, 0.2); }
.buyers-tab .buyers-content .account-box.active { box-sizing: border-box; border: 3px solid #4DD0E1; cursor: default; }

.buyers-tab .service-progress { margin-bottom: 32px; }
.buyers-tab .service-progress .progress-header { display: flex; }
.buyers-tab .service-progress .progress-header .title { margin-right: 6px; font-family: Lora; font-style: normal; font-weight: bold; font-size: 24px; line-height: 26px; }
.buyers-tab .service-progress .progress-header .icon { margin-top: 5px; width: 20px; height: 20px; }
.buyers-tab .service-progress .progress-bar { padding: 5px; margin-bottom: 64px; }

.buyers-tab .step-details { margin-bottom: 24px; background: #F2FEFF; border-radius: 12px; padding: 16px; }

.buyers-tab .client-requests { margin-bottom: 32px;padding-top: 7px; }
.buyers-tab .client-requests .client-requests-title { margin-top: 10px; margin-left: 7px; margin-bottom: 16px; font-family: Lora; font-style: normal; font-weight: bold; font-size: 24px; line-height: 26px; }
.buyers-tab .client-requests .client-requests-panel { display: flex; flex-direction: row; align-items: center; padding: 8px; margin-bottom: 8px; background: #FFFFFF; }
.buyers-tab .client-requests .client-requests-panel:last-child { margin-bottom: 0px; }
.buyers-tab .client-requests .client-requests-panel .icon {margin-right: 18px; filter: invert(33%) sepia(89%) saturate(2251%) hue-rotate(162deg) brightness(97%) contrast(101%); width: 20px; height: 20px; }
.buyers-tab .client-requests .client-requests-panel .request-details { font-family: Source Sans Pro; font-style: normal; font-weight: bold; font-size: 20px; line-height: 24px; text-transform: uppercase; margin-top: 20px;}

.buyers-tab .session { padding: 16px; background: #FFFFFF; border-radius: 12px; margin-top: 11px; display: flex; align-items: center; }
.buyers-tab .session .info .title { margin-bottom: 16px; font-family: Lora; font-style: normal; font-weight: bold; font-size: 24px; line-height: 26px; }
.buyers-tab .session .info .info-row { margin-bottom: 8px; display: inline-block; width: 100%; }
.buyers-tab .session .info .info-row:last-child { margin-bottom: 0px; }
.buyers-tab .session .info .info-row .photo { width: 24px; height: 24px; float: left; background: #323232; border-radius: 50%; margin-right: 4px; }
.buyers-tab .session .info .info-row .name { float: left; margin-right: 8px; font-family: Roboto; font-style: normal; font-weight: bold; font-size: 18px; line-height: 21px; }
.buyers-tab .session .info .info-row .text { float: left; margin-right: 8px; font-family: Source Sans Pro; font-style: normal; font-weight: normal; font-size: 18px; line-height: 24px; }
.buyers-tab .session .info .info-row .link { float: left; margin-right: 8px; font-family: Source Sans Pro; font-style: normal; font-weight: bold; font-size: 18px; line-height: 24px; text-decoration-line: underline !important; }

.buyers-tab .buyers-property { margin-top: 24px; padding: 16px; background: #E8E8E8; border-radius: 12px; }
.buyers-tab .buyers-property .title { font-family: Lora; font-style: normal; font-weight: bold; font-size: 24px; line-height: 26px; }

@media screen and (max-width:1235px){
    .buyers-tab .col9of12 {
        width: 100% !important;
    }
    /* .sellers-tab .cols .col {
        padding: 0 !important;
      }
       */
      .buyers-tab .cols  .col3of12  .buyers-content {
          display: flex;
          gap: 20px;
          width: 100%;
          overflow-x: auto;
          flex-direction: row;
      }

}

@media screen and (max-width:530px){
    .buyers-tab {
        padding: 16px 0px;
    }
}