.client-request-box { padding: 16px; background: #EDEDED; box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px rgba(0, 0, 0, 0.12), 0px 1px 3px rgba(0, 0, 0, 0.2); border-radius: 8px; }
.client-request-box .client-request-top { display: flex; justify-content: space-between; }
.client-request-box .client-request-top .user-info .client-type { margin-bottom: 8px; font-family: Lora; font-style: normal; font-weight: bold; font-size: 20px; line-height: 24px; text-transform: uppercase; }
.client-request-box .client-request-top .user-info .client-avatar { display: flex; margin-bottom: 14px; }
.client-request-box .client-request-top .user-info .client-avatar .left-part .photo { margin-right: 16px; width: 64px; height: 64px; border-radius: 50%; }
.client-request-box .client-request-top .user-info .client-avatar .right-part .name { margin-bottom: 14px; font-family: Lora; font-style: normal; font-weight: bold; font-size: 24px; line-height: 26px; }
.client-request-box .client-request-top .user-info .client-avatar .right-part .ratings { display: flex; }
.client-request-box .client-request-top .user-info .client-avatar .right-part .ratings .icon { width: 20px; height: 20px; margin-right: 6px; }
.client-request-box .client-request-top .user-info .client-avatar .right-part .ratings .number { font-family: Roboto; font-style: normal; font-weight: 500; font-size: 18px; line-height: 21px; }
.client-request-box .client-request-top .user-info .actions { display: flex; column-gap: 16px; }

.client-request-box .client-request-top .listing-details { margin-left: 32px; }
.client-request-box .client-request-top .listing-details .title { font-family: Source Sans Pro; font-style: normal; font-weight: normal; font-size: 16px; line-height: 18px; color: #656565; }
.client-request-box .client-request-top .listing-details .join-date { margin-top: 4px; padding: 13px 28px 12px 19px; background: #FFFFFF; display: inline-block; border-radius: 4px; }
.client-request-box .client-request-top .listing-details .join-date .icon { margin-right: 11px; }
.client-request-box .client-request-top .listing-details .join-date .month { font-family: Source Sans Pro; font-style: normal; font-weight: bold; font-size: 18px; line-height: 24px; text-transform: uppercase; }
.client-request-box .client-request-top .listing-details .join-date .year { font-family: Source Sans Pro; font-style: normal; font-weight: bold; font-size: 20px; line-height: 24px; text-transform: uppercase; }
.client-request-box .client-request-top .listing-details .funding { margin-top: 18px; }
.client-request-box .client-request-top .listing-details .funding .icon { margin-right: 8px; }
.client-request-box .client-request-top .listing-details .funding .price { font-family: Roboto; font-style: normal; font-weight: bold; font-size: 24px; line-height: 28px; }
.client-request-box .client-request-top .listing-details .funding .type { font-family: Source Sans Pro; font-style: normal; font-weight: normal; font-size: 16px; line-height: 18px; }
.client-request-box .client-request-top .listing-details .offers { margin-top: 12px; }
.client-request-box .client-request-top .listing-details .offers .icon { margin-right: 8px; }
.client-request-box .client-request-top .listing-details .offers .offer { font-family: Source Sans Pro; font-style: normal; font-weight: normal; font-size: 16px; line-height: 18px; }

.client-request-box .client-request-top .message-request { flex: 2; margin-left: 24px; min-height: 120px; }
.client-request-box .client-request-top .message-request .title { font-family: Source Sans Pro; font-style: normal; font-weight: normal; font-size: 16px; line-height: 18px; }
.client-request-box .client-request-top .message-request .message { margin-top: 13px; padding: 16px; background: #FFFFFF; border-radius: 4px; }
.client-request-box .client-request-top .message-request .message .text { font-family: Source Sans Pro; font-style: normal; font-weight: normal; font-size: 18px; line-height: 24px; }

.client-request-box .client-request-top .message-time { margin-left: 24px; }
.client-request-box .client-request-top .message-time .time-title { font-family: Source Sans Pro; font-style: normal; font-weight: normal; font-size: 16px; line-height: 18px; color: #656565; }
.client-request-box .client-request-top .message-time .time-body { padding: 16px; background: #FFFFFF; display: inline-block;border-radius: 4px; 
}
.client-request-box .client-request-top .message-time .time-body .title { margin-bottom: 0; font-family: Source Sans Pro; font-style: normal; font-weight: 600; font-size: 14px; line-height: 20px; text-align: right; }
.client-request-box .client-request-top .message-time .confirmation { display: flex; flex-direction: column; justify-content: center; align-items: center; height: 100%; }
.client-request-box .client-request-top .message-time .confirmation .time-label {font-family: Source Sans Pro; font-style: normal; font-weight: normal; font-size: 12px; line-height: 16px; text-align: center; letter-spacing: 0.4px; }
.client-request-box .client-request-top .message-time .confirmation .time { margin-top: 4px; text-align: center; font-family: Source Sans Pro; font-style: normal; font-weight: bold; font-size: 24px; line-height: 30px; }

.client-request-box .client-request-top .request-actions { margin-left: 24px; display: flex; flex-direction: column; gap: 12px; }

.client-request-box .client-request-bottom { display: flex; background: #F8F8F8;; margin-top: 16px; padding: 8px 10px; }
.client-request-box .client-request-bottom .icon {margin-right: 18px; filter: invert(33%) sepia(89%) saturate(2251%) hue-rotate(162deg) brightness(97%) contrast(101%); width: 20px; height: 20px; }
.client-request-box .client-request-bottom .request-details { font-family: Source Sans Pro; font-style: normal; font-weight: bold; font-size: 20px; line-height: 24px; text-transform: uppercase; }

.client-request-box .client-request-top .request-actions .accept-button {
    padding: 15px 32px;
    font-size: 14px;
    line-height: 18px;
    font-family: Source Sans Pro;
    background: #00BCD4;
    font-weight: 700;
    color: #fff;
    border-radius: 4px;
    cursor: pointer;
    text-transform: uppercase;
}

.client-request-box .client-request-top .request-actions .cancel-button {
    padding: 15px 32px;
    border: 2px solid #00BCD4;
    background-color: transparent;
    box-sizing: border-box;
    border-radius: 4px;
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 18px;
    text-transform: uppercase;
    color: #000000;
}
@media screen and (max-width: 785px) {
    .client-request-box .client-request-top {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 20px;
    }


    .client-request-box .client-request-top .request-actions {
        margin-left: 0 !important;
        width: 100%;
      
    }

    .client-request-box .client-request-top .listing-details {
        margin-left: 0px !important;
        width: 50%;
        text-align: center;
    }

    .client-request-box .client-request-top .listing-details .join-date {
        width: 100%;
    }

    .client-request-box .client-request-top .message-time {
        margin-left: 0px !important;
        width: 50%;
        text-align: center;
    }

    .client-request-box .client-request-top .message-time .time-body {
        width: 100%;
       
    }

    .client-request-box .client-request-top .message-time .time-body .title {
        text-align: center !important;
    }
}


@media screen and (max-width: 500px) { 
    .client-request-box .client-request-top .listing-details {
     
        width: 100%;
        
    }

    .client-request-box .client-request-top .message-time {
       
        width: 100%;
       
    }

}