.seller-info-location-wrapper .location-item-mb {
    margin-bottom: 16px;
}

.seller-info-location-wrapper .location-title {
    font-family: Lora;
    font-size: 24px;
    font-weight: 700;
    line-height: 26px;
}

.seller-info-location-wrapper .location-label {
    font-family: Source Sans Pro;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 12px;
    color: #000;
    cursor: default;
}

.seller-info-location-wrapper .location-autocomplete {
    background: #ededed;
    border-radius: 4px;
    font-family: Source Sans Pro;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    color: rgba(0, 0, 0, 0.6);
}

.seller-info-location-wrapper .location-autocomplete .notchedOutline {
    border: none;
}

.seller-info-location-wrapper .location-autocomplete .muiFocused>input {
    border: none;
}

.seller-info-location-wrapper .text {
    font-size: 14px;
    font-weight: 400;
    text-align: left;
    margin-bottom: 30px;
    margin-left: 5px;
    color: red;
}

.custom-popper {
    width: 825px !important;
}

.custom-popper-higher-width {
    width: 1145px !important;
}

.seller-information-property .textfield-search input::placeholder {
    color: red;
    opacity: 0.7;
}