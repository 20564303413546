.dialog-virtual-meeting-questionnaire-request { text-align: center; }
.dialog-virtual-meeting-questionnaire-request-content { padding: 64px 84px; }
.dialog-virtual-meeting-questionnaire-request-content .title-header { padding: 0; margin-bottom: 8px; }
.dialog-virtual-meeting-questionnaire-request-content .title-header .title { display: inline-block; font-size: 48px; line-height: 52px; font-family: Lora; font-weight: 700; }
.dialog-virtual-meeting-questionnaire-request-content .title-header .close-icon { position: absolute; top: 20px; right: 20px; cursor: pointer; }

.dialog-virtual-meeting-questionnaire-request-content .content { padding: 0; overflow-x: hidden; }
.dialog-virtual-meeting-questionnaire-request-content .content .sub-title { font-size: 18px; line-height: 24px; font-weight: 400; margin-bottom: 32px; font-family: Source Sans Pro; padding: 0 40px; }

.dialog-virtual-meeting-questionnaire-request-content .content .rate-services { margin-bottom: 24px; }
.dialog-virtual-meeting-questionnaire-request-content .content .rate-services .label,

.dialog-virtual-meeting-questionnaire-request-content .content .message-content .label { margin-bottom: 8px; font-size: 18px; line-height: 22px; font-family: Source Sans Pro; font-weight: 900; text-transform: uppercase; }
.dialog-virtual-meeting-questionnaire-request-content .content .message-content .textField { margin-bottom: 24px; border-radius: 8px; background: #EDEDED; }

.dialog-virtual-meeting-questionnaire-request-content .content .virtual-assistant-rating { padding: 13px 21px; margin: 8px auto; display: inline-block; background: #F8F8F8; }
.dialog-virtual-meeting-questionnaire-request-content .content .virtual-assistant-rating .MuiRating-root { color: #000000; }

/* .dialog-virtual-meeting-questionnaire-request-content .content .rate-services .numbers-group { display: flex; justify-content: center; align-items: center; margin-bottom: 24px; }
.dialog-virtual-meeting-questionnaire-request-content .content .rate-services .numbers-group .number-box { padding: 16px 26px 19px 25px; background: #EDEDED; color: #000; border-radius: 50%; margin-right: 24px; cursor: pointer; }
.dialog-virtual-meeting-questionnaire-request-content .content .rate-services .numbers-group .number-box:last-child { margin-right: 0; }
.dialog-virtual-meeting-questionnaire-request-content .content .rate-services .numbers-group .number-box.active { background: #0097A7; color: #fff; cursor: default; }
.dialog-virtual-meeting-questionnaire-request-content .content .rate-services .numbers-group .number { font-size: 24px; line-height: 26px; font-family: Lora; font-weight: 700; } */

.dialog-virtual-meeting-questionnaire-request-content .content .actions { margin-top: 8px; display: flex; justify-content: center; }
.dialog-virtual-meeting-questionnaire-request-content .content .actions .button-send { margin-right: 24px; padding: 15px 32px; background: #00BCD4; border-radius: 4px; color: #fff; text-transform: uppercase; font-size: 14px; line-height: 18px; font-family: Source Sans Pro; font-weight: 700; }
.dialog-virtual-meeting-questionnaire-request-content .content .actions .button-skip { padding: 15px 32px; border: 2px solid #00BCD4; border-radius: 4px; color: #000; text-transform: uppercase; font-size: 14px; line-height: 18px; font-family: Source Sans Pro; font-weight: 700;  }