.document-box { padding-top: 16px; background: #FFFFFF; margin: 16px 0; border-radius: 4px; text-align: center; }
.document-box .header { display: flex; justify-content: center; align-items: center; margin-bottom: 22px; }
.document-box .header .icon { margin-right: 15px; }
.document-box .header .subtitle { font-family: Lora; font-style: normal; font-weight: bold; font-size: 24px; line-height: 26px; }
.document-box .main-icon { width: 50px; height: 50px; margin-bottom: 13px; }
.document-box .title { font-family: Source Sans Pro; font-style: normal; font-weight: 900; font-size: 18px; line-height: 22px; text-transform: uppercase; }
.document-box .actions { display: flex; margin-top: 35px; justify-content: center; column-gap: 36px; }
.document-box .actions .read .text { font-size: 12px; line-height: 14px; margin-bottom: 10px; }
.document-box .actions .download .text { font-size: 12px; line-height: 14px; margin-bottom: 10px; }
.document-box .actions .download .icon:first-child { margin-right: 16px; }

.document-box .lower-ribbon { margin-top: 28px; background: #A5D6A7; border-radius: 0px 0px 4px 4px; width: 100%; height: 9px; }