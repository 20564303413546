.social-logout-button {
    float: left;
    padding: 15px 32px;
    margin-right: 20px;
    font-size: 14px;
    line-height: 18px;
    font-family: 'Source Sans Pro';
    font-weight: 700;
    color: #000;
    border-radius: 4px;
    border: 2px solid #00BCD4;
    cursor: pointer;
    text-transform: uppercase;
}
