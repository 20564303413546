.services-tab-photographer-seller {
  position: relative;
  padding: 16px;
}
.services-tab-photographer-seller .cols {
  padding: 0;
  margin: 0;
}

.services-tab-photographer-seller .sellers-content .link-box {
  margin-top: 32px;
  text-align: center;
}
.services-tab-photographer-seller .sellers-content .link-box .link-seller {
  width: 100%;
  padding: 22px 14px;
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  color: #0097a7;
  border: 1px dashed #8c8c8c;
  box-sizing: border-box;
  border-radius: 8px;
  text-transform: uppercase;
}
.services-tab-photographer-seller
  .sellers-content
  .link-box
  .link-seller
  .icon {
  margin-right: 5px;
  margin-top: 3px;
  filter: invert(33%) sepia(89%) saturate(2251%) hue-rotate(162deg)
    brightness(97%) contrast(101%);
  width: 20px;
  height: 20px;
}

.services-tab-photographer-seller .sellers-content .account-box {
  margin-bottom: 16px;
  padding: 24px;
  background: #ffffff;
  border-radius: 4px;
  cursor: pointer;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px rgba(0, 0, 0, 0.12),
    0px 1px 3px rgba(0, 0, 0, 0.2);
}
.services-tab-photographer-seller .sellers-content .account-box.active {
  box-sizing: border-box;
  border: 3px solid #4dd0e1;
  cursor: default;
}

.services-tab-photographer-seller .service-progress {
  margin-bottom: 24px;
}
.services-tab-photographer-seller .service-progress .progress-header {
  display: flex;
  flex-direction: row;
}
.services-tab-photographer-seller .service-progress .progress-header .title {
  margin-right: 6px;
  font-family: Lora;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 26px;
}
.services-tab-photographer-seller .service-progress .progress-header .icon {
  margin-top: 5px;
  width: 20px;
  height: 20px;
}

.services-tab-photographer-seller .step-details {
  margin-bottom: 24px;
  background: #f2feff;
  border-radius: 12px;
  padding: 16px;
}

.services-tab-photographer-seller .toggle-steps {
  margin-bottom: 24px;
  background: #f2feff;
  border-radius: 12px;
  padding: 16px;
  text-align: center;
}
.services-tab-photographer-seller .toggle-steps .button-next {
  background: #00bcd4;
  border-radius: 4px;
  padding: 19px 32px;
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  text-transform: uppercase;
  color: #ffffff;
}
.services-tab-photographer-seller .toggle-steps .button-previous {
  margin-right: 32px;
  border: 2px solid #00bcd4;
  border-radius: 4px;
  padding: 19px 32px;
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  text-transform: uppercase;
  color: #000000;
}

.services-tab-photographer-seller .session {
  padding: 16px;
  background: #ffffff;
  border-radius: 12px;
  display: flex;
  align-items: center;
}
.services-tab-photographer-seller .session .info .title {
  margin-bottom: 16px;
  font-family: Lora;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 26px;
}
.services-tab-photographer-seller .session .info .info-row {
  margin-bottom: 8px;
  display: inline-block;
  width: 100%;
}
.services-tab-photographer-seller .session .info .info-row:last-child {
  margin-bottom: 0px;
}
.services-tab-photographer-seller .session .info .info-row .photo {
  width: 24px;
  height: 24px;
  float: left;
  background: #323232;
  border-radius: 50%;
  margin-right: 4px;
}
.services-tab-photographer-seller .session .info .info-row .name {
  float: left;
  margin-right: 8px;
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 21px;
}
.services-tab-photographer-seller .session .info .info-row .text {
  float: left;
  margin-right: 8px;
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 24px;
}
.services-tab-photographer-seller .session .info .info-row .link {
  float: left;
  margin-right: 8px;
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 24px;
  text-decoration-line: underline !important;
}

.services-tab-photographer-seller .sellers-property {
  margin-top: 24px;
  margin-bottom: 16px;
  padding: 16px;
  background: #e8e8e8;
  border-radius: 12px;
  max-height: 580px;
  overflow-y: auto;
}
.services-tab-photographer-seller .sellers-property .title {
  font-family: Lora;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 26px;
}

.photos-upload-service {
  margin-top: 24px;
  padding: 16px;
  background: #f4f4f4;
  border-radius: 12px;
  text-align: center;
}
/* .photos-upload-service .image-container { } */
.photos-upload-service .image-container .title {
  margin-bottom: 16px;
  text-align: left;
  font-family: Lora;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 26px;
}
.photos-upload-service .image-container .squares {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 16px;
}
.photos-upload-service .image-container .squares .image-square {
  width: 293px;
  height: 234px;
}
.photos-upload-service .image-container .squares .image-square > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.photos-upload-service > img {
  margin-top: 30px;
  width: 76.67px;
  height: 69px;
  filter: invert(100%) sepia(2%) saturate(2238%) hue-rotate(185deg)
    brightness(111%) contrast(84%);
}
.photos-upload-service > h1 {
  top: 82px;
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
  text-transform: uppercase;
  color: #8c8c8c;
}
.photos-upload-service .dropZone {
  width: 100%;
  padding: 26px 0px 26px 0px;
  margin-top: 33px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px dashed #8c8c8c;
  box-sizing: border-box;
  border-radius: 8px;
}
.photos-upload-service .dropZone .upload {
  display: flex;
  cursor: pointer;
}
.photos-upload-service .dropZone .upload > img {
  width: 16px;
  height: 16px;
}
.photos-upload-service .dropZone .upload > p {
  margin-left: 8px;
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  text-transform: uppercase;
  color: #0097a7;
}

/* Dialog */
.dialog-session-setup {
  text-align: center;
}
.dialog-session-setup-content {
  margin: 64px 86px;
}
.dialog-session-setup-content .close-icon {
  cursor: pointer;
  float: right;
  position: absolute;
  top: 0;
  right: 0;
  margin-top: 20px;
  margin-right: 21px;
}
.dialog-session-setup-content .title {
  font-size: 36px;
  line-height: 42px;
  margin-bottom: 16px;
}
.dialog-session-setup-content .content {
  padding: 0;
  overflow-x: hidden;
}
.dialog-session-setup-content .content .text {
  font-size: 16px;
  line-height: 18px;
  font-weight: 400;
  text-align: left;
}
.dialog-session-setup-content .content .actions {
  margin-top: 24px;
  gap:10px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
}
.dialog-session-setup-content .content .actions .button-close {
  height: 40px;
  padding: 12px 32px;
  background: #000;
  border-radius: 12px;
  color: #ffffff;
  text-transform: none;
  font-size: 12px;
  line-height: 14px;
  font-weight: 400;
}

/* Dialog Session Setup */
.dialog-session-setup {
  text-align: center;
}
.dialog-session-setup-content {
  margin: 64px 117px 64px 116px;
}
.dialog-session-setup-content .close-icon {
  cursor: pointer;
  float: right;
  position: absolute;
  top: 0;
  right: 0;
  margin-top: 21px;
  margin-right: 21px;
}
.dialog-session-setup-content .title {
  margin-bottom: 8px;
  font-family: Lora;
  font-style: normal;
  font-weight: normal;
  font-size: 48px;
  line-height: 52px;
}

.dialog-session-setup-content .content {
  padding: 0;
  overflow-x: hidden;
}
.dialog-session-setup-content .content .text {
  padding: 0px 11px 32px 11px;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
}
.dialog-session-setup-content .content .form-group-field {
  margin-bottom: 4px;
  text-align: left;
}
.dialog-session-setup-content .content .form-group-field .label {
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: 900;
  font-size: 18px;
  line-height: 22px;
  text-transform: uppercase;
}

.dialog-session-setup-content .content .calendar {
  margin-top: 24px;
  padding: 16px;
  background: #ffffff;
  box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.14), 0px 1px 18px rgba(0, 0, 0, 0.12),
    0px 3px 5px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  text-align: left;
}
.dialog-session-setup-content .content .calendar .title {
  margin-bottom: 11px;
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: 900;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  text-transform: uppercase;
}

.dialog-session-setup-content .content .hour-free {
  margin-top: 24px;
  padding: 16px 32px;
  background: #ffffff;
  box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.14), 0px 1px 18px rgba(0, 0, 0, 0.12),
    0px 3px 5px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  margin-bottom: 16px;
  float: left;
  text-align: left;
}
.dialog-session-setup-content .content .hour-free .title {
  margin-bottom: 8px;
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: 900;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  text-transform: uppercase;
}
.dialog-session-setup-content .content .hour-free .chip {
  padding: 4px 12px;
  background: #f8f8f8;
  border-radius: 16px;
  float: left;
  margin-right: 8px;
  margin-bottom: 8px;
  cursor: pointer;
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 18px;
  color: #9d9d9d;
}
.dialog-session-setup-content .content .hour-free .chip.active {
  background: #80deea;
  color: #000000;
  cursor: default;
}
.dialog-session-setup-content .content .hour-free .chip.disabled {
  background: #ffffff;
  opacity: 0.4;
  cursor: not-allowed;
}

.dialog-session-setup-content .content .actions {
  margin-top: 24px;
}
.dialog-session-setup-content .content .actions .button-accept {
  width: 250px;
  padding: 15px 32px;
  background: #00bcd4;
  border-radius: 4px;
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  text-transform: uppercase;
  color: #ffffff;
}
.dialog-session-setup-content .content .actions .button-cancel {
  width: 250px;
  border: 1px solid #00bcd4;
  padding: 15px 32px;
  border-radius: 4px;
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  text-transform: uppercase;
  color: #000000;
}
.dialog-deactivate-account-virtual-assistant-seller {
  margin: 0 10px;
}

.dialog-deactivate-account-virtual-assistant-seller-content {
  padding: 22px;
  text-align: center;
}

.dialog-deactivate-account-virtual-assistant-seller-content .title-header {
  padding: 0;
  margin-bottom: 16px;
}

.dialog-deactivate-account-virtual-assistant-seller-content .title-header .title {
  font-family: Lora;
  font-style: normal;
  font-weight: bold;
  font-size: 32px;
  line-height: 32px;
  margin-top: 22px;
  padding: 0px 140px;
}

.dialog-deactivate-account-virtual-assistant-seller-content .title-header .close-icon {
  cursor: pointer;
  float: right;
  margin-top: -24px;
}

.dialog-deactivate-account-virtual-assistant-seller-content .content {
  padding: 0;
  overflow-x: hidden;
}

.dialog-deactivate-account-virtual-assistant-seller-content .content .actions {
  margin-top: 24px;
}

.dialog-deactivate-account-virtual-assistant-seller-content .content .actions .button-continue {
  margin-right: 24px;
  padding: 15px 32px;
  background: #F44336;
  border-radius: 4px;
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  text-transform: uppercase;
  color: #FFFFFF;
}

.dialog-deactivate-account-virtual-assistant-seller-content .content .actions .button-back {
  padding: 15px 32px;
  border: 2px solid #00BCD4;
  box-sizing: border-box;
  border-radius: 4px;
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  text-transform: uppercase;
  color: #000000;
}

.dialog-deactivate-account-virtual-assistant-seller-content .button-send {
  height: 45px;
  padding: 15px 32px;
  background: #00BCD4;
  border-radius: 4px;
  color: #fff;
  text-transform: uppercase;
  margin-right: 25px;
  font-size: 14px;
  line-height: 18px;
  font-family: Source Sans Pro;
  font-weight: 700;
}

.dialog-deactivate-account-virtual-assistant-seller-content .button-send:hover {
  height: 45px;
  padding: 15px 32px;
  background: #00BCD4;
  border-radius: 4px;
  color: #fff;
  text-transform: uppercase;
  margin-right: 25px;
  font-size: 14px;
  line-height: 18px;
  font-family: Source Sans Pro;
}



@media screen and (max-width: 800px) {
  .services-tab-photographer-seller {
    padding: 0px;
  }

  .services-tab-photographer-seller .cols {
    flex-direction: column;
  }

  .services-tab-photographer-seller .sellers-content .account-box {
    border: 1px solid black;
  }
  .services-tab-photographer-seller .cols .serviceTabsContainer {
    width: 100%;
    display: flex;
    margin-bottom: 20px;
  }
  .services-tab-photographer-seller
    .cols
    .serviceTabsContainer
    .sellers-content {
    display: flex;
    gap: 20px;
    flex-direction: row;
    overflow-x: auto;
  }
  .services-tab-photographer-seller .cols .servicesContainer {
    width: 100%;
    margin-bottom: 20px;
  }
}

@media screen and (max-width:400px){

  .dialog-deactivate-account-virtual-assistant-seller-content .title-header .title {
      padding: 0;
  }

}