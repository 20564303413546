.forgotPassword {
    padding: 64px 0 21px 0;
    text-align: center;
    margin: 0 auto;
    background-color: #fff;
}

.forgotPassword .forgotPasswordContent {
    margin: 0 auto;
    text-align: center;
    height: 100%;
}

.forgotPassword .forgotPasswordContent .forgotPasswordTitle {
    font-size: 48px;
    line-height: 52px;
    font-weight: 600;
    margin-bottom: 16px;
}

.forgotPassword .forgotPasswordContent .textFieldContainer {
    width: 674px;
    display: block;
    margin: 0 auto 8px auto;
    border-radius: 4px;
}

.forgotPassword .forgotPasswordContent .login-btn {
    display: block;
    margin: 12px 0 24px 0;
    padding: 0;
}

.forgotPassword .forgotPasswordContent .button {
    background: #00BCD4;
    border-radius: 4px;
    color: #fff;
    padding: 15px 32px;
    text-transform: uppercase;
    font-size: 14px;
    line-height: 18px;
    font-weight: 700;
}

.forgotPassword .forgotPasswordContent .button:hover {
    background: #00BCD4;
}

.forgotPassword .properColor {
    position: relative;
    display: inline-block;
    padding: 8px;
    color: #0097A7;
    font-size: 14px;
    line-height: 18px;
    border-radius: 4px;
    font-family: Source Sans Pro;
    font-weight: 700;
    text-transform: uppercase;
    z-index: 99;
}

.forgotPassword .image {
    margin-top: -270px;
    background: linear-gradient(180deg, rgba(204, 243, 255, 0) 0%, #CCF3FF 100%);
}

.forgotPassword .text-field-price {
    outline: 1px solid rgba(0, 0, 0, 0.5);
    border: none;
    border-radius: 12px;
    padding: 10px;
    margin-bottom: 10px;
    width: 450px;
}

.forgotPassword .text-field-price.focus {
    outline: 1px solid rgba(0, 188, 212, 1) !important;
    border: none;
}


@media screen and (max-width:1082px) {
    .forgotPassword .image {
        margin-top: -150px;
        background: linear-gradient(180deg, rgba(204, 243, 255, 0) 0%, #CCF3FF 100%);
    }

}

@media screen and (max-width:750px) {
    .forgotPassword .image {
        margin-top: -100px;
    }

}

@media screen and (max-width:746px) {
    .forgotPassword {
        padding: 50px 100px 8px 100px !important;
    }

    .forgotPassword .forgotPasswordContent .textFieldContainer {
        width: 100%;

    }
}

@media screen and (max-width:686px) {
    .forgotPassword {
        padding: 50px 50px 8px 50px !important;

    }

    .forgotPassword .image {
        margin-top: 0px;
    }
}

@media screen and (max-width:617px) {
    .forgotPassword .forgotPasswordContent .forgotPasswordTitle {
        font-size: 30px;
    }

}

@media screen and (max-width:500px) {
    .forgotPassword .forgotPasswordContent .textFieldContainer {
        width: 100%;

    }

    .forgotPassword {
        padding: 50px 10px 8px 10px !important;
    }
}