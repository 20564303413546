.confirm-service-details { display: flex; flex-direction: row; }
.confirm-service-details .icon { margin-right: 18px; align-self: flex-start; }
.confirm-service-details .current-details { display: flex; flex-direction: column;width:100%}
.confirm-service-details .current-details .left-part .title { margin-bottom: 8px; font-family: Lora; font-style: normal; font-weight: bold; font-size: 24px; line-height: 26px; }
.confirm-service-details .current-details .left-part .text { margin-bottom: 16px; font-family: Source Sans Pro; font-style: normal; font-weight: normal; font-size: 18px; line-height: 24px; }
.confirm-service-details .current-details .right-part { margin-top: 5px;width:100%}
.confirm-service-details .current-details .right-part .approved { padding: 15px 32px; background: #00BCD4; border-radius: 4px; color: #fff; text-transform: uppercase; margin-right: 25px; font-size: 14px; line-height: 18px; font-family: Source Sans Pro; font-weight: 700; }
/* Dialog */
.dialog-session-setup { text-align: center; }
.dialog-session-setup-content { margin: 64px 86px }
.dialog-session-setup-content .close-icon { cursor: pointer; float: right; position: absolute; top: 0; right: 0; margin-top: 20px; margin-right: 21px; }
.dialog-session-setup-content .title { font-size: 36px; line-height: 42px; margin-bottom: 16px; }
.dialog-session-setup-content .content { padding: 0; overflow-x: hidden; }
.dialog-session-setup-content .content .text { font-size: 16px; line-height: 18px; font-weight: 400; text-align: left; }
.dialog-session-setup-content .content .actions { margin-top: 24px; } 
.dialog-session-setup-content .content .actions .button-close { height: 40px; padding: 12px 32px; background: #000; border-radius: 12px; color: #FFFFFF; text-transform: none; font-size: 12px; line-height: 14px; font-weight: 400; }

/* Dialog Session Setup */
.dialog-session-setup { text-align: center; }
.dialog-session-setup-content { margin: 64px 117px 64px 116px; }
.dialog-session-setup-content .close-icon { cursor: pointer; float: right; position: absolute; top: 0; right: 0; margin-top: 21px; margin-right: 21px; }
.dialog-session-setup-content .title { margin-bottom: 8px; font-family: Lora; font-style: normal; font-weight: normal; font-size: 48px; line-height: 52px; }

.dialog-session-setup-content .content { padding: 0; overflow-x: hidden; }
.dialog-session-setup-content .content .text { padding: 0px 11px 32px 11px; font-family: Roboto; font-style: normal; font-weight: normal; font-size: 16px; line-height: 19px; text-align: center; }
.dialog-session-setup-content .content .form-group-field { margin-bottom: 4px; text-align: left; }
.dialog-session-setup-content .content .form-group-field .label { font-family: Source Sans Pro; font-style: normal; font-weight: 900; font-size: 18px; line-height: 22px; text-transform: uppercase; }

.dialog-session-setup-content .content .calendar { margin-top: 24px; padding: 16px; background: #FFFFFF; box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.14), 0px 1px 18px rgba(0, 0, 0, 0.12), 0px 3px 5px rgba(0, 0, 0, 0.2); border-radius: 10px; text-align: left; }
.dialog-session-setup-content .content .calendar .title { margin-bottom: 11px; font-family: Source Sans Pro; font-style: normal; font-weight: 900; font-size: 18px; line-height: 22px; text-align: center; text-transform: uppercase; }

.dialog-session-setup-content .content .hour-free { margin-top: 24px; padding: 16px 32px; background: #FFFFFF; box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.14), 0px 1px 18px rgba(0, 0, 0, 0.12), 0px 3px 5px rgba(0, 0, 0, 0.2); border-radius: 10px; margin-bottom: 16px; float: left; text-align: left; }
.dialog-session-setup-content .content .hour-free .title { margin-bottom: 8px; font-family: Source Sans Pro; font-style: normal; font-weight: 900; font-size: 18px; line-height: 22px; text-align: center; text-transform: uppercase; }
.dialog-session-setup-content .content .hour-free .chip { padding: 4px 12px; background: #F8F8F8; border-radius: 16px; float: left; margin-right: 8px; margin-bottom: 8px; cursor: pointer; font-family: Source Sans Pro; font-style: normal; font-weight: normal; font-size: 16px; line-height: 18px; color: #9D9D9D; }
.dialog-session-setup-content .content .hour-free .chip.active { background: #80DEEA; color: #000000; cursor: default; }
.dialog-session-setup-content .content .hour-free .chip.disabled { background: #FFFFFF; opacity: 0.4; cursor: not-allowed; }

.dialog-session-setup-content .content .actions { margin-top: 24px;display: flex; flex-direction: row; justify-content: center;width: 100%;gap: 10px; } 
.dialog-session-setup-content .content .actions .button-accept { width: 250px; padding: 15px 32px; background: #00BCD4; border-radius: 4px; font-family: Source Sans Pro; font-style: normal; font-weight: bold; font-size: 14px; line-height: 18px; text-transform: uppercase; color: #FFFFFF; }
.dialog-session-setup-content .content .actions .button-cancel { width: 250px;border: 1px solid #00BCD4; padding: 15px 32px; border-radius: 4px; font-family: Source Sans Pro; font-style: normal; font-weight: bold; font-size: 14px; line-height: 18px; text-transform: uppercase; color: #000000; }

@media screen and (max-width:765px){
    .confirm-service-details {
        flex-direction: column;
        align-items: center;
    }
}

@media screen and (max-width:530px){
    .confirm-service-details .current-details {
        flex-direction: column;
        align-items: center;
    }
}


@media screen and (max-width:700px){
    .dialog-session-setup-content .content .actions {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
}