.property-box { display: flex; flex-wrap: wrap; flex-direction: column; gap: 18px; padding: 16px; margin-top: 16px; background: #FFFFFF; box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px rgba(0, 0, 0, 0.12), 0px 1px 3px rgba(0, 0, 0, 0.2); border-radius: 12px; }

.property-box .propery-details-box { flex: 2 1 0; flex-wrap: wrap;}
.property-box .propery-details-box .image { margin-right: 16px; width: 189px; height: 189px; border-radius: 4px; float: left; }

.property-box .propery-details-box .details-part-left { float: left; }
.property-box .propery-details-box .details-part-left .main-price { font-family: Source Sans Pro; font-style: normal; font-weight: bold; font-size: 36px; line-height: 45px; }

.property-box .propery-details-box .details-part-left .address { margin-top: 40px; }
.property-box .propery-details-box .details-part-left .address .icon { margin-right: 4px; }
.property-box .propery-details-box .details-part-left .address .text { font-family: Source Sans Pro; font-style: normal; font-weight: normal; font-size: 18px; line-height: 24px; }

.property-box .propery-details-box .details-part-left .activites { margin-top: 8px; }
.property-box .propery-details-box .details-part-left .activites .icon { margin-right: 4px; width: 16px; height: 16px; margin-top: 2px; }
.property-box .propery-details-box .details-part-left .activites .number { font-family: Source Sans Pro; font-style: normal; font-weight: normal; font-size: 18px; line-height: 24px; margin-right: 12px; }
.property-box .propery-details-box .details-part-left .activites .number .sup { font-size: 10px; line-height: 11px; font-weight: 700; }

.property-box .propery-details-box .details-part-right { float: right; margin-top: 5px; }
.property-box .propery-details-box .details-part-right .price-row { margin-bottom: 7px; }
.property-box .propery-details-box .details-part-right .price-row .price { float: right; padding: 3px 8px; background: #E0F7FA; border-radius: 4px; font-family: Source Sans Pro; font-style: normal; font-weight: bold; font-size: 14px; line-height: 18px; }
.property-box .propery-details-box .details-part-right .price-row .price .sup { font-size: 10px; line-height: 12px; }
.property-box .propery-details-box .details-part-right .price-row .tooltip { float: right; margin-left: 6px; }
.property-box .propery-details-box .details-part-right .property { margin-top: 58px;  }
.property-box .propery-details-box .details-part-right .property .link { text-decoration: none !important; color: #000; font-family: Source Sans Pro; font-style: normal; font-weight: bold; font-size: 16px; line-height: 18px; }

.property-box .propery-details-box .details-last { float: left; }
.property-box .propery-details-box .details-last .bottom-part { display: flex; width: 100%; flex-direction: row;}
.property-box .propery-details-box .details-last .bottom-part .propertyOfferCount{margin-top: 5px;}
.property-box .propery-details-box .details-last .paragraph { float: left; margin-right: 380px; font-family: Roboto; font-style: normal; font-weight: 500; font-size: 18px; line-height: 21px; }
.property-box .propery-details-box .details-last .rating { float: right; margin-left: auto; }
.property-box .propery-details-box .details-last .rating .icon { margin-right: 6px; margin-left: 14px; }
.property-box .propery-details-box .details-last .rating .icon-eye { vertical-align: text-top; }
.property-box .propery-details-box .details-last .rating .number { font-family: Source Sans Pro; font-style: normal; font-weight: normal; font-size: 18px; line-height: 24px; vertical-align: top; }

.property-box .propery-details-box .line { margin: 8px 0 16px 0; border: 0.5px solid #9D9D9D; transform: rotate(180deg); }

.property-box .additional-info-box { flex: 1 1 0; background: #F4F4F4; border-radius: 8px; padding: 16px; }
.property-box .additional-info-box .info-row { display: flex; align-items: center; margin-bottom: 8px; }
.property-box .additional-info-box .info-row .icon { width: 20px; height: 20px; margin-right: 6px; }
.property-box .additional-info-box .info-row .item { font-family: Source Sans Pro; font-style: normal; font-weight: normal; font-size: 18px; line-height: 24px; }
.property-box .button-open-virtual-tour-editor{
    background: #00BCD4; border-radius: 4px; padding: 19px 32px; font-family: Source Sans Pro; font-style: normal; font-weight: bold; font-size: 11px; line-height: 16px; text-transform: uppercase; color: #FFFFFF; width:fit-content;
}
.property-box .button-open-virtual-tour-editor:hover{
    background-color: #000;
}
.property-box .button-open-virtual-tour-editor.request-review{
    background: rgb(132, 5, 132);
}
.property-box .button-open-virtual-tour-editor.request-review:hover{
    background: #000;
}
.property-box .button-open-virtual-tour-editor.approve-work{
    background: green;
}
.property-box .button-open-virtual-tour-editor.approve-work:hover{
    background: #000;
}
.property-box .button-open-virtual-tour-editor.request-changes{
    background: rgb(225, 156, 29);
}
.property-box .button-open-virtual-tour-editor.request-changes:hover{
    background: #000;
}
@media screen and (max-width:959px){
    .property-box .propery-details-box { 
        flex: 2 1 0;
        display:flex;
        flex-direction:column;
     }
     .property-box .propery-details-box .details-part-right { 
        margin-top:10px;
        display:flex;
        justify-content:space-between;
     }

     .property-box .propery-details-box .details-part-right .property { 
        margin-top: 0px;  
    }

    .property-box .propery-details-box .image {
        margin-right: 16px;
        width: 100%;
        height: 189px;
        border-radius: 4px;
        float: left;
    }
}