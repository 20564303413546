
.dialog-property-image-view-modal-content{
    padding: 2.5%;
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
}

.dialog-property-image-view-modal-content .close-icon {
    display: flex;
    position: absolute;
    cursor: pointer;
    right: 10px;
    top: 10px;
    height: 40px;
    width: 40px;
    justify-content: center;
    align-items: center;
}

.dialog-property-image-view-modal-content .previous-image-icon{
    display: flex;
    height: 100px;
    width: 50px;
    background-color: black;
    border-radius: 6px;
    padding: 0 !important;
    padding-left: 2px;
    position: absolute;
    left:20px;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.dialog-property-image-view-modal-content .next-image-icon{
    display: flex;
    height: 100px;
    width: 50px;
    background-color: black;
    border-radius: 6px;
    padding: 0 !important;
    padding-left: 2px;
    position: absolute;
    right:20px;
    align-items: center;
    justify-content: center;
    cursor: pointer;

}