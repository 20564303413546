.card-property {
    padding: 16px;
    margin-bottom: 32px !important;
    background: #F8F8F8;
    border-radius: 4px;
}

.card-property .card-item {
    padding: 0;
}

.card-property .tl {
    text-align: left;
    width: 100%;
}

.card-property .card-item .price {
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 700;
    font-size: 36px;
    line-height: 45px;
    text-transform: uppercase;
    margin-bottom: 18px;
    cursor: pointer;
}

.card-property .card-item .info-box,
.card-property .card-item .info-box .icon-group,
.card-property .card-item .info-box .icon-group>span,
.card-property .card-item .location-details,
.card-property .card-item .square-meters {
    display: flex;
    align-items: center;
}

.card-property .card-item .info-box {
    width: 100%;
    margin-bottom: 8px;
}

.card-property .card-item .info-box .icon-group:not(:last-child) {
    margin-right: 8px;
}

.card-property .card-item .info-box .icon-group .icon {
    width: 18px;
    height: 18px;
    margin-right: 4px;
}

.card-property .card-item .location-details .icon {
    width: 24px;
    height: 20px;
    margin-right: 4px;
    margin-left: -4px;
}

.card-property .card-item .location-details .text {
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    overflow:hidden;
    width: 100%;
    height: 25px;
    text-overflow:ellipsis; 
    white-space: nowrap;
}

.card-property .card-item .square-meters {
    margin-top: 10px;
    justify-content: flex-end;
    margin-bottom: 62px;
}

.card-property .card-item .square-meters .text {
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
    padding: 3px 8px;
    background: #EDEDED;
    border-radius: 4px;
    margin-right: 8px;
}

.card-property .card-item .offer-text {
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 18px;
    text-align: right;
}

.card-property .card-item .separator {
    margin: 8px 0;
    border: 0.5px solid #014245;
}

.card-property .card-item .property-type-text {
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    text-align: left;
    margin-bottom: 30px;
    overflow: hidden;
    width: 100%;
    height: 22px;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.card-property .main-image {
    height: 160px;
    width: 100%;
    margin-bottom: 24px;
    border-radius: 4px;
    cursor: pointer;
    object-fit: cover;
}

.card-property .btn-promote {
    padding: 15px 32px;
    background: #00BCD4;
    color: #fff;
    border-radius: 4px;
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
}

.card-property .btn-promote:hover {
    background: #00BCD4;
}

.card-property .btn-change {
    padding: 8px 8px 8px 0;
    border-radius: 4px;
    color: #00BCD4;
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
}

.card-property .btn-change:hover {
    background: transparent;
}



@media screen and (max-width:599px){
      .location-details{
        margin-left: 4px;
      }

    .promote-btn-container{
        margin-bottom: 4px;
    }
}

@media screen and (max-width:477px){
     .square-meter-container{
        max-width:38% !important;
        flex-basis: 38% !important;
    } 
    
}