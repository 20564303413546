.details { display: flex; }
.details .current-details .information .title { margin-bottom: 8px; font-family: Lora; font-style: normal; font-weight: bold; font-size: 24px; line-height: 26px; text-align: left; }
.details .current-details .information .text { margin-bottom: 16px; font-family: Source Sans Pro; font-style: normal; font-weight: normal; font-size: 18px; line-height: 24px; text-align: left; }
.details .current-details .information .button-send-lawyer { background: #00BCD4; border-radius: 6px; padding: 19px 32px; font-family: Source Sans Pro; font-style: normal; font-weight: bold; font-size: 14px; line-height: 18px; text-transform: uppercase; color: #FFFFFF; }

@media screen and (max-width:768px){
  .details {
     display: flex; 
     flex-direction: column;
     gap:10px
  }
}
