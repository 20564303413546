.personal-profile-virtual-assistant{
    padding: 0 32px;

}

.personal-profile-virtual-assistant .navigation-content { margin-top: 32px; display: flex; flex-direction: column; width: 100%; justify-content: center; align-items: center;}
.personal-profile-virtual-assistant .navigation-content .button-edit-profile { width: fit-content;margin-left: 32px; padding: 15px 32px; background-color: #00BCD4; border-radius: 4px; color: #FFFFFF; margin-bottom: 64px; text-transform: uppercase; font-family: Source Sans Pro; font-style: normal; font-weight: bold; font-size: 14px; line-height: 18px; }

/* Using grid boxes */
.personal-profile-virtual-assistant .history-content-wrapper .title { margin-bottom: 32px; font-family: Lora; font-style: normal; font-weight: 1; font-size: 48px; line-height: 52px; text-align: center; color: #000000; }
.personal-profile-virtual-assistant .history-content { display: flex; }
.personal-profile-virtual-assistant .history-content .box { padding: 24px; background: #F8F8F8; border-radius: 12px; }
.personal-profile-virtual-assistant .history-content .box .box-row { width: 100%; margin-bottom: 18px; display: inline-block; }
.personal-profile-virtual-assistant .history-content .box .box-row:last-child { width: 100%; margin-bottom: 0; }
.personal-profile-virtual-assistant .history-content .box .box-row .date { width: 15%; float: left; font-family: Source Sans Pro; font-style: normal; font-weight: normal; font-size: 12px; line-height: 12px; color: #000000; }
.personal-profile-virtual-assistant .history-content .box .box-row .small-icon { width: 20px; height: 18px; margin-right: 10px; float: left; }
.personal-profile-virtual-assistant .history-content .box .box-row .text { margin-right: 8px; float: left; font-family: Source Sans Pro; font-style: normal; font-weight: normal; font-size: 18px; line-height: 24px; color: #000000; }
.personal-profile-virtual-assistant .history-content .box .box-row .ad-text { font-size: 18px; line-height: 21px; font-weight: 400; margin: 0px 4px; }
.personal-profile-virtual-assistant .history-content .box .box-row .ad-number { font-family: Roboto; font-style: normal; font-weight: bold; font-size: 18px; line-height: 21px; text-decoration-line: underline; color: #000000; }
.personal-profile-virtual-assistant .history-content .history-button { flex: 1; font-family: Source Sans Pro; font-style: normal; font-weight: bold; font-size: 14px; line-height: 18px; text-transform: uppercase; color: #0097A7; }

.personal-profile-virtual-assistant .about-me-content { margin: 64px 0; }
.personal-profile-virtual-assistant .about-me-content .title { margin-bottom: 32px; font-family: Lora; font-style: normal; font-weight: 1; font-size: 48px; line-height: 52px; text-align: center; color: #000000; }
.personal-profile-virtual-assistant .about-me-content .box { padding: 27px 24px 24px 24px; background: #FFFFFF; box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px rgba(0, 0, 0, 0.12), 0px 1px 3px rgba(0, 0, 0, 0.2); border-radius: 12px; }
.personal-profile-virtual-assistant .about-me-content .box .box-header .ellipse { width: 24px; height: 24px; float: left; margin-right: 4px; }
.personal-profile-virtual-assistant .about-me-content .box .box-header .info { float: left; margin-bottom: 16px; }
.personal-profile-virtual-assistant .about-me-content .box .box-header .info .name { font-family: Source Sans Pro; font-style: normal; font-weight: 900; font-size: 18px; line-height: 22px; text-align: right; text-transform: uppercase; }
.personal-profile-virtual-assistant .about-me-content .box .box-header .info .paragraph { font-family: Source Sans Pro; font-style: normal; font-weight: normal; font-size: 12px; line-height: 16px; text-align: left; letter-spacing: 0.4px; color: #000000; }
.personal-profile-virtual-assistant .about-me-content .box .box-header .rating { margin-bottom: 10px; filter: invert(48%) sepia(93%) saturate(3629%) hue-rotate(157deg) brightness(90%) contrast(102%); }
.personal-profile-virtual-assistant .about-me-content .box .box-header .text { display: block; font-family: Source Sans Pro; font-style: normal; font-weight: normal; font-size: 18px; line-height: 24px; color: #000000; }


@media screen and (max-width:400px){

    .personal-profile-virtual-assistant {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding:  0px 32px;
      width:100%;
    }
  }