.profile-no-virtual-agent { padding: 95px 250px 270px 250px; text-align: center; }

.profile-no-virtual-agent .title { font-size: 32px; line-height: 32px; font-family: Lora; font-weight: 700; margin-bottom: 8px; }
.profile-no-virtual-agent .sub-title { margin: 0 20px; font-size: 18px; line-height: 24px; font-family: Source Sans Pro; font-weight: 400; margin-bottom: 24px; }
.profile-no-virtual-agent .button {  height: 48px; padding: 15px 32px; background: #00BCD4; border-radius: 4px; color: #ffffff; font-size: 14px; line-height: 18px; font-family: Source Sans Pro; font-weight: 700; text-transform: uppercase; }
.profile-no-virtual-agent .button:hover { background: #00BCD4; }

.profile-no-virtual-agent .content { display: flex; margin-bottom: 24px; }
.profile-no-virtual-agent .content .box { width: 200px; padding: 24px 24px 26px 24px; margin-right: 32px; background: #EDEDED; border-radius: 4px; }
.profile-no-virtual-agent .content .box:last-child { margin-right: 0; }
.profile-no-virtual-agent .content .box .icon { margin-bottom: 8px; }
.profile-no-virtual-agent .content .box .text { font-size: 16px; line-height: 18px; font-family: Source Sans Pro; font-weight: 400; }

@media screen and (max-width: 1300px) {
    .profile-no-virtual-agent {
        padding: 95px 0 270px 150px !important;
    }
}

@media screen and (max-width: 900px) {
    .profile-no-virtual-agent {
        padding: 95px 50px 270px 50px !important;
    }
}

@media screen and (max-width: 700px) {
    .profile-no-virtual-agent .content {
        display: grid !important;
        grid-template-columns: repeat(1, 1fr) !important;
        grid-gap: 20px;
    }

    .profile-no-virtual-agent .content .box {
        margin-right: 0 !important;
    }

    .profile-no-virtual-agent .content .box:last-child {
        margin-right: 0 !important;
    }
}

@media screen and (max-width: 600px) {
    .profile-no-virtual-agent .content {
        display: flex !important;
        flex-direction: column !important;
        align-items: center !important;
    }
    
    .profile-no-virtual-agent {
        padding: 95px 20px 270px 20px !important;
    }

}

@media  screen and (max-width: 469px) {
    .profile-no-virtual-agent {
        margin: 30px 10px;
        padding: 20px 0 !important;
    }

    .profile-no-virtual-agent .button { height: inherit; display: block; }

}