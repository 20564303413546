.no-uploads {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #F8F8F8;
  border-radius: 10px;
  margin: 32px 0;
}
.no-uploads  .dropZone {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 5px;
  color: #0097a7;
  border: 1px dashed #0097a7;
  border-radius: 5px;
  padding: 10px;
  width: fit-content;
  height: fit-content;
  cursor: pointer;
}
.no-uploads  .dropZone  .upload-area {
  display: flex;
  align-items: center;
}
.no-uploads  .dropZone  .upload-area  p {
  font-family: Source Sans Pro;
  font-size: 17px;
  font-style: normal;
  font-weight: 700;
  line-height: 18px;
  text-align: left;
  color: #0097a7;
  margin-right: 5px;
  margin-bottom: 0 !important;
}
 .videos-uploads-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 10px;
  justify-content: left;
  width: 100%;
  overflow: hidden;
}
 .videos-uploads-container  .video-container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  position: relative;
  height: 448px;
}
 .videos-uploads-container  .video-container  .delete {
  position: absolute;
  top: 8px;
  right: 8px;
  width: 88px;
  height: 24px;
  border: none;
  background-color: white;
  padding: 4px 12px 4px 12px;
  cursor: pointer;
  border-radius: 16px;
}
 .videos-uploads-container  .video-container  .delete  span {
  display: flex;
}
 .videos-uploads-container  .video-container  .delete  span  p {
  margin-left: 11px;
  font-family: Source Sans Pro;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.400000006px;
}
 .videos-uploads-container  .video-container  video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
 .videos-uploads-container  .video-container  .dropZone {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 5px;
  color: #0097a7;
  border: 1px dashed #0097a7;
  border-radius: 5px;
  padding: 10px;
  width: fit-content;
  height: fit-content;
  cursor: pointer;
}
 .videos-uploads-container  .video-container  .dropZone  .upload-area {
  display: flex;
}
 .videos-uploads-container  .video-container  .dropZone  .upload-area  p {
  font-family: Source Sans Pro;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
  color: #0097a7;
  text-transform: uppercase;
  margin-right: 8px;
}
.btn-upload-videos {
  text-align: center;
}
.btn-upload-videos  button {
  margin-top: 24px;
}
.btn-upload-videos  .portfolio-button-upload-video {
  background-color: #00bcd4;
  height: 48px;
  font-family: Source Sans Pro;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 18px;
  text-align: left;
  color: white;
  padding: 15px 32px 15px 32px;
  border: none;
  outline: 0;
  border-radius: 4px;
  cursor: pointer;
  text-transform: uppercase;
}
.btn-upload-videos .portfolio-button-upload-video:hover {
  background-color: #00bcd4;
}
.virtual-tours-grid {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 10px;
}
.virtual-tours-grid  .virtual-tour-grid-el {
  position: relative;
  border-radius: 5px;
  width: 300px;
  height: 150px;
}
.virtual-tours-grid  .virtual-tour-grid-el  .delete, .virtual-tours-grid  .virtual-tour-grid-el  .new {
  color: white;
  background: tomato;
  border-radius: 100%;
  padding: 2px;
  position: absolute;
  top: -10px;
  right: -10px;
  height: 30px;
  width: 30px;
  display: grid;
  place-content: center;
  cursor: pointer;
}
.virtual-tours-grid  .virtual-tour-grid-el  .new {
  padding: 2px 6px;
  border-radius: 999px;
  background-color: #00bcd4;
  color: white;
  left: -10px;
  right: unset;
  display: grid;
  place-content: center;
  width: fit-content;
  font-size: 0.8rem;
  font-weight: bold;
}
.virtual-tours-grid  .virtual-tour-grid-el  img {
  object-fit: cover;
  width: 100%;
  height: 100%;
  border-radius: 5px;
}
.virtual-tours-grid  .virtual-tour-grid-el  .text-content {
  display: flex;
  flex-direction: column;
  gap: 10px;
  font-family: Source Sans Pro;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 10px;
  background: linear-gradient(0deg, #373737, #00000042);
  color: white;
}
.virtual-tours-grid  .virtual-tour-grid-el  .text-content  span.tour-name {
  font-weight: bold;
}
.virtual-tours-grid  .add-virtual-tour {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 5px;
  color: #0097a7;
  border: 1px dashed #0097a7;
  border-radius: 5px;
  padding: 10px;
  width: fit-content;
  height: fit-content;
  cursor: pointer;
}
.virtual-tours-grid  .add-virtual-tour  p {
  margin: 0;
  font-weight: bold;
  font-family: Source Sans Pro;
}
.virtual-tours-grid  .header-common {
  width: 100%;
}
.pano-list-item {
  position: relative;
}
.pano-list-item  .delete {
  color: white;
  background: tomato;
  border-radius: 100%;
  padding: 2px;
  position: absolute;
  top: -10px;
  right: -10px;
  height: 30px;
  width: 30px;
  display: grid;
  place-content: center;
  cursor: pointer;
}
.image-square .delete p {
  margin-bottom: 0 !important;
}
@media screen and (max-width: 800px) {
  .videos-uploads-container  {
    grid-template-columns: repeat(1, 1fr);
  }
}
