.loading-container {
    display: grid;
    place-content: center;
    height: 100vh;
  }
  
  .loading-image {
    animation: bounce 2s infinite;
    height: 100px;
    width: 100px;
    object-fit: cover;
  }
  
  @keyframes bounce {
    0%, 20%, 50%, 80%, 100% {
      transform: translateY(0);
    }
    40% {
      transform: translateY(-10px);
    }
    60% {
      transform: translateY(-5px);
    }
  }
  