.dialog-common-multi-profile {
    text-align: center;
}

.dialog-common-multi-profile-content {
    padding: 20px 35px;
}

.dialog-common-multi-profile-content .title-header {
    padding: 0;
    margin-bottom: 8px;
}

.dialog-common-multi-profile-content .title-header .title {
    display: inline-block;
    font-size: 32px;
    line-height: 32px;
    font-family: Lora;
    font-weight: 700;
}

.dialog-common-multi-profile-content .title-header .close-icon {
    display: inline-block;
    cursor: pointer;
    float: right;
}

.dialog-common-multi-profile-content .content {
    padding: 0;
    overflow-x: hidden;
}

.dialog-common-multi-profile-content .content .sub-title {
    font-size: 16px;
    font-weight: 400;
    margin-bottom: 20px;
    font-family: 'Source Sans Pro';
    padding: 0 40px;
}

.dialog-common-multi-profile-content .content .box {
    border: 2px solid #E2E2E2;
    padding: 20px 15px;
    background: #E2E2E2;
    border-radius: 10px;
    position: relative;
    margin-bottom: 16px;
    cursor: pointer;
    user-select: none;
    min-height: 230px;
}

.dialog-common-multi-profile-content .content .box.seller {
    background: #F9FBE7;
    border: 2px solid #F9FBE7;
}

.dialog-common-multi-profile-content .content .box.lawyer {
    background: #EFEBE9;
    border: 2px solid #EFEBE9;
}

.dialog-common-multi-profile-content .content .box.virtualassistant {
    background: #ECEFF1;
    border: 2px solid #ECEFF1;
}

.dialog-common-multi-profile-content .content .box.photographer {
    background: #FFF8E1;
    border: 2px solid #FFF8E1;
    height: 300px;
}

.dialog-common-multi-profile-content .content .box.active {
    border: 2px solid #00838F;
    cursor: default;
    user-select: text;
}

.dialog-common-multi-profile-content .content .box.disabled {
    background: #FBE9E7;
    border: 2px solid #FBE9E7;
    opacity: 0.5;
    cursor: not-allowed;
}

.dialog-common-multi-profile-content .content .box .icon {
    height: 40px;
    margin-bottom: 24px;
}

.dialog-common-multi-profile-content .content .box .parts {
    position: absolute;
    left: 45%;
    top: 14%;
    display: inline-grid;
}

.dialog-common-multi-profile-content .content .box .title {
    font-size: 20px;
    line-height: 26px;
    font-weight: 700;
    font-family: Lora;
    margin-bottom: 10px;
}

.dialog-common-multi-profile-content .content .box .text {
    padding: 0;
    font-size: 15px;
    line-height: 18px;
    font-family: 'Source Sans Pro';
    font-weight: 400;
}

.dialog-common-multi-profile-content .content .actions {
    margin-top: 8px;
    position: fixed;
    bottom: 2px; 
    transform: translate(-50%, -50%); 
    left: 50%; 
    background: white;
    width: 50%;
    padding: 8px 0;
}

.dialog-common-multi-profile-content .content .actions .button-continue {
    height: 45px;
    padding: 15px 32px;
    background: #00BCD4;
    border-radius: 4px;
    color: #fff;
    text-transform: uppercase;
    margin-right: 25px;
    font-size: 14px;
    line-height: 18px;
    font-family: 'Source Sans Pro';
    font-weight: 700;
}

.dialog-common-multi-profile-content .content .actions .button-back {
    height: 45px;
    padding: 15px 32px;
    border: 2px solid #00BCD4;
    border-radius: 4px;
    color: #000;
    text-transform: none;
    font-size: 14px;
    line-height: 18px;
    font-family: 'Source Sans Pro';
    font-weight: 700;
}

.profiles-container::-webkit-scrollbar {
    width: 10px !important;
    background: #000;
}

.profiles-container::-webkit-scrollbar-track {
    background: #fff;
    border-radius: 12px;
}

.profiles-container::-webkit-scrollbar-thumb {
    background-color: #000;
}

.profiles-container-card-grid {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    margin-right: 0px !important;
    margin-left: 0px !important;
    margin-bottom: 20px;
}

.profiles-container-card-grid  .col {

    padding: 0 !important;

}


.profiles-container-card-grid .col4of12 {
    width: 100% !important;
}

.profiles-container-card-grid .top-row {
    display: flex;
    gap: 16px;
}

.profiles-container-card-grid .bottom-row {
    display: flex;
    justify-content: center;
    padding:  0 7rem;
    gap: 16px;
}

.profiles-container-card-grid .col2of12 {
    display: none !important;
}


.profiles-container-card .video {
    height: 100px;
    width: 150px;
    margin-bottom: 24px;
    border-radius: 4px;
    position: relative;
    justify-content: center;
    align-items: center;
    display: flex;
    margin: auto;
}

.profiles-container-card .video .video-play {
    width: 100%;
    height: 100%;
}

.profiles-container-card .video-play-button{
   cursor: pointer;
   width: 40px;

}
.profiles-container-card .video-play-button-container{
    position: absolute;
    top: 30%;
    left: 37%;
    z-index: 1;
}

.profile-card-reactivation-warning {
    display: flex;
    flex-direction: row;
    gap: 10px;
    margin-top: 10px;
    justify-content: center;
}

@media screen and (max-width:1000px) {
    .profiles-container-card-grid .top-row {
        flex-direction: column;
    }

    .profiles-container-card-grid .bottom-row {
        flex-direction: column;
        padding: 0;
    }

    .profiles-container-card-grid .col.col4of12 {
        width: 26rem !important;
    }

    .profiles-container {
        height: auto;
    }

    .dialog-common-multi-profile-content .content .actions {
        width: 80%;
    }
    

}
@media screen and (max-width:950px) {
    .dialog-common-multi-profile-content .content .profiles-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 20px
    }

    .dialog-common-multi-profile-content .content .col4of12 {
        width: 80%;
    }
    .dialog-common-multi-profile-content .content .box.photograph{
        height: fit-content;
    }
}

@media screen and (max-width:600px) {
    .profiles-container-card-grid .col.col4of12 {
        width: 100% !important;
    }
}

@media screen and (max-width:500px) {
    .dialog-common-multi-profile-content {
        padding: 20px 10px !important;
    }
}


@media screen and (max-width:415px) {
    .dialog-common-multi-profile-content .content .actions {
        display: flex;
        flex-direction: column;
        gap: 10px;
        padding: 5px;
    }

    .dialog-common-multi-profile-content .content .actions .button-continue {
        width: 100%;
    }

    .dialog-common-multi-profile-content .content .actions .button-back {
        width: 100%;
    }

    .dialog-common-multi-profile-content .content .cols {
        margin-right: 0 !important;
        margin-left: 0 !important;
    }
}

@media screen and (max-width:432px) {
    .dialog-common-multi-profile-content .content .sub-title {
        padding: 0 10px;
        text-align: center;
    }

    .dialog-common-multi-profile-content .content .col4of12 {
        width: 100%;
    }

    .dialog-common-multi-profile-content .content .cols {
        margin-bottom: 90px;
    }

    .dialog-common-multi-profile-content .content .actions {
        position: fixed;
        bottom: 0;
        left: 50%;
    }
}

@media screen and (max-width:320px) {
    .dialog-common-multi-profile-content .title-header .title {
        font-size: 16px;
    }
}