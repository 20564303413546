/* Edited code */
.my-profile-photographer{
    display: flex;
    flex-direction: column;
}
.my-profile-photographer .header-content { display: flex; margin-top: 32px; padding: 0 32px;}
.my-profile-photographer .header-content .photo-group { display: flex; flex-direction: column; justify-content: center; }
.my-profile-photographer .header-content .photo-group .avatar {
     position: relative;
     width: 178px;
     height: 178px;
     border-radius: 50%;
     }
.my-profile-photographer .header-content .photo-group .avatar img { 
    width: 178px;
     height: 178px; 
     border-radius: 50%;
     object-fit: cover;
 }

.my-profile-photographer .header-content .photo-group .user-id { margin-top: 8px; text-align: center; font-family: Source Sans Pro; font-style: normal; font-weight: normal; font-size: 12px; line-height: 16px; }

.my-profile-photographer .header-content .user-details { margin-left: 24px; }

.my-profile-photographer .header-content .user-details .user-name {
    font-family: Lora;
    font-size: 48px;
    line-height: 52px;
    margin-bottom: 8px;
}

.my-profile-photographer .header-content .user-details .rating { display: flex; align-items: flex-end; margin-bottom: 17px; }
.my-profile-photographer .header-content .user-details .rating .title { margin-right: 10px; font-family: Source Sans Pro; font-style: normal; font-weight: 900; font-size: 18px; line-height: 22px; text-transform: uppercase; color: #000000; }
.my-profile-photographer .header-content .user-details .rating .result { margin-left: 8px; font-family: Source Sans Pro; font-style: normal; font-weight: normal; font-size: 18px; line-height: 24px; color: #000000; }

.my-profile-photographer .header-content .account-details { margin-left: auto; margin-right: 32px; }
.my-profile-photographer .header-content .account-details .button { float: right; font-family: Source Sans Pro; font-style: normal; font-weight: bold; font-size: 14px; line-height: 18px; text-transform: uppercase; color: #0097A7; }

.my-profile-photographer .changes-button { display: flex; gap: 32px; margin-top: 46px; justify-content: center; }
.my-profile-photographer .changes-button .button-view-profile { padding: 15px 32px; background-color: #00BCD4; border-radius: 4px; color: #FFFFFF; text-transform: uppercase; font-family: Source Sans Pro; font-style: normal; font-weight: bold; font-size: 14px; line-height: 18px; }
.my-profile-photographer .changes-button .button-view-profile:hover { background-color: #00BCD4; }
.my-profile-photographer .changes-button .button { padding: 15px 32px; border: 2px solid #00BCD4; border-radius: 4px; color: #000000; text-transform: uppercase; font-family: Source Sans Pro; font-style: normal; font-weight: bold; font-size: 14px; line-height: 18px; }

/* New code */
.my-profile-photographer .my-profile-content { margin: 32px 0; width: 100%; }
.my-profile-photographer .my-profile-content .root {
    flex-grow: 1;
    background: #F8F8F8;
    display: flex;
    border-radius: 12px;
    margin: 0 32px;
    position: relative;
}
.my-profile-photographer .my-profile-content .root .tabs { width: 15%; background: #E8E8E8; border-radius: 12px 0px 0px 12px; }
.my-profile-photographer .my-profile-content .root .tabs .tab { border-radius: 12px 0px 0px 12px; text-transform: uppercase; font-size: 14px; line-height: 18px; font-family: Source Sans Pro; font-weight: 700; }
.my-profile-photographer .my-profile-content .root .tabs .tab.disabled { pointer-events: none; opacity: 0.2; } 
.my-profile-photographer .my-profile-content .root .tabs .tab.active-tab { background: #F8F8F8; }
.my-profile-photographer .my-profile-content .root .tabs .tab.last-tab { position: absolute; bottom: 0; width: 100%; }
.my-profile-photographer .my-profile-content .tab-panel { width: 85%; text-align: center; }

.my-profile-photographer .my-profile-content .root .tab-panel .tab-panel-contract { min-height: 300px; }
.my-profile-photographer .my-profile-content .root .tab-panel .tab-panel-contract .contract-list-pane { display: flex; margin: 24px; background: #FFFFFF; box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px rgba(0, 0, 0, 0.12), 0px 1px 3px rgba(0, 0, 0, 0.2); border-radius: 4px; }
.my-profile-photographer .my-profile-content .root .tab-panel .tab-panel-contract .contract-list-pane .contract-details { margin-left: 24px; margin-top: 27px; }
.my-profile-photographer .my-profile-content .root .tab-panel .tab-panel-contract .contract-list-pane .contract-details .contract-title { display: flex; }
.my-profile-photographer .my-profile-content .root .tab-panel .tab-panel-contract .contract-list-pane .contract-details .contract-title .icon { height: 21px; width: 18px; margin-right: 11px; }
.my-profile-photographer .my-profile-content .root .tab-panel .tab-panel-contract .contract-list-pane .contract-details .contract-title .title { font-family: Lora; font-style: normal; font-weight: bold; font-size: 24px; line-height: 26px; }
.my-profile-photographer .my-profile-content .root .tab-panel .tab-panel-contract .contract-list-pane .contract-details .contract-date { margin-top: 8px; text-align: left; font-family: Source Sans Pro; font-style: normal; font-weight: normal; font-size: 12px; line-height: 16px; letter-spacing: 0.4px; }
.my-profile-photographer .my-profile-content .root .tab-panel .tab-panel-contract .contract-list-pane .contract-download { margin-left: auto; margin-top: 29px; }
.my-profile-photographer .my-profile-content .root .tab-panel .tab-panel-contract .contract-list-pane .contract-download .button { padding: 15px 32px; background: #00BCD4; border-radius: 4px; font-family: Source Sans Pro; font-style: normal; font-weight: bold; font-size: 14px; line-height: 18px; text-transform: uppercase; color: #FFFFFF; }
.my-profile-photographer .my-profile-content .root .tab-panel .tab-panel-contract .contract-list-pane .contract-download .button .icon { color: #FFFFFF; margin-right: 8px; }
.my-profile-photographer .my-profile-content .root .tab-panel .tab-panel-contract .contract-list-pane .contract-review { margin: 29px 33px 29px 23px; }
.my-profile-photographer .my-profile-content .root .tab-panel .tab-panel-contract .contract-list-pane .contract-review .button { padding: 15px 32px; border: 2px solid #00BCD4; box-sizing: border-box; border-radius: 4px; font-family: Source Sans Pro; font-style: normal; font-weight: bold; font-size: 14px; line-height: 18px; text-transform: uppercase; color: #000000; }
/* ################################# */

.my-profile-photographer .information-content { margin-bottom: 128px; margin-top: 64px; text-align: center; }
.my-profile-photographer .information-content .title { margin-bottom: 16px; font-family: Lora; font-size: 32px; line-height: 32px; }
.my-profile-photographer .information-content .text { margin: 0px 48px 24px 48px; font-family: Source Sans Pro; font-style: normal; font-weight: normal; font-size: 18px; line-height: 24px; }
.my-profile-photographer .information-content .button { padding: 15px 32px; border: 2px solid #00BCD4; box-sizing: border-box; border-radius: 4px; color: #000000; font-family: Source Sans Pro; font-style: normal; font-weight: bold; font-size: 14px; line-height: 18px; text-transform: uppercase; }

/* Deactivate Account Dialog */
.dialog-deactivate-account-photographer { padding: 22px; text-align: center; }
.dialog-deactivate-account-photographer .title-header { padding: 0; margin-bottom: 16px; }
.dialog-deactivate-account-photographer .title-header .title { font-family: Lora; font-style: normal; font-weight: bold; font-size: 32px; line-height: 32px; margin-top: 22px; padding: 0px 140px; }
.dialog-deactivate-account-photographer .title-header .close-icon { cursor: pointer; float: right; margin-top: -24px; }

.dialog-deactivate-account-photographer .content { padding: 0; overflow-x: hidden; }
.dialog-deactivate-account-photographer .content .sub-title { font-family: Source Sans Pro; font-style: normal; font-weight: normal; font-size: 18px; line-height: 24px; margin-bottom: 24px; padding: 0px 100px; }
.dialog-deactivate-account-photographer .content .actions { margin-top: 24px; }
.dialog-deactivate-account-photographer .content .actions .button-continue { margin-right: 24px; padding: 15px 32px; background: #F44336; border-radius: 4px; font-family: Source Sans Pro; font-style: normal; font-weight: bold; font-size: 14px; line-height: 18px; text-transform: uppercase; color: #FFFFFF; }
.dialog-deactivate-account-photographer .content .actions .button-back { padding: 15px 32px; border: 2px solid #00BCD4; box-sizing: border-box; border-radius: 4px; font-family: Source Sans Pro; font-style: normal; font-weight: bold; font-size: 14px; line-height: 18px; text-transform: uppercase; color: #000000; }

span[class^='PrivateTabIndicator-root-'] {
    background: #F8F8F8;
}


.my-profile-photographer .menu-burger {
    display: none;
}

.my-profile-photographer .close-tab {
    display: none;
}


.my-profile-photographer .display-tab-backdrop {
    position: absolute;
    top: 0;
    left: 0;
    width: 50%;
    height: 100%;
    z-index: 100;
    border-radius: 12px;
    display: none;
}

.my-profile-photographer .display-tab-backdrop:hover {
    cursor: pointer;
}


@media screen and (max-width: 1000px) {


    .my-profile-photographer .header-content {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap:10px;
    }

    .my-profile-photographer .display-tab-backdrop {
        display: block;
    }

    .my-profile-photographer .menu-burger{
        display: block;
        position: absolute;
        left: 10px;
        top: 20px;
        z-index: 100;
        
    }

    .my-profile-photographer .close-tab{
        display: block;
        position: absolute;
        right: 10px;
        top: 0px;
        z-index: 100;
    }

    .my-profile-photographer .close-tab:hover{
        cursor: pointer;
    }

    .my-profile-photographer .menu-burger:hover{
        cursor: pointer;
    }

    .my-profile-photographer .my-profile-content .root .tabs-mobile-closed {
        width: 60px !important;
    }

    .my-profile-photographer .my-profile-content .root .tabs-mobile-open {
        width: 50% !important;
        position: absolute;
        z-index: 100;
        height: 100%;
        padding-top: 40px;
    }

    .my-profile-photographer .header-content .user-details .user-name {
        font-size: 24px !important;
        line-height: 24px !important;
        text-align: center;
    }

    .my-profile-photographer .header-content .user-details {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
}


@media screen and (max-width: 500px) {
    .my-profile-photographer .my-profile-content .tab-panel{
        padding-left: 10px !important;
        padding-right: 10px !important;
    }
    .my-profile-photographer .information-content .text{
        margin: 0px 0px 24px 0px !important;
    }

    .my-profile-photographer .my-profile-content .root{
        margin-left: 10px !important;
        margin-right: 10px !important;
    }

    .my-profile-photographer .header-content {
        padding: 0 10px;
    }
}

@media screen and (max-width: 440px) {
    .my-profile-photographer .header-content {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 20px;
    }

    .my-profile-photographer .header-content .user-details {
        margin-left: 0px !important;
    }

    .my-profile-photographer .changes-button{
        margin-top: 20px;
    }
}

@media screen and (max-width: 400px) {
    .my-profile-photographer .changes-button{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding-left: 70px;
        padding-right: 70px;
        
    }

    .my-profile-photographer .changes-button .button-view-profile {
        width: 100% !important;
    }

    .my-profile-photographer .changes-button .button {
        width: 100% !important;
        padding: 15px 10px;
    }
}
