.payment-method { text-align: center; }
.payment-method .no-payment .title { margin-top: 56px; font-size: 32px; line-height: 32px; font-family: Lora; font-weight: 700; margin-bottom: 8px; }
.payment-method .no-payment .sub-title { margin-bottom: 32px; font-size: 18px; line-height: 24px; font-family: Source Sans Pro; font-weight: 400; }
.payment-method .no-payment .box-content { display: flex; margin: 0 90px; }
.payment-method .no-payment .box-content .box { width: 256px; height: 256px; margin-right: 32px; background: #FFFFFF; border: 3px solid #FFFFFF; border-radius: 4px; display: flex; align-items: center; justify-content: center; cursor: pointer; }
.payment-method .no-payment .box-content .box.active-card { border: 3px solid #00838F; cursor: default; filter: drop-shadow(0px 6px 10px rgba(0, 0, 0, 0.14)) drop-shadow(0px 1px 18px rgba(0, 0, 0, 0.12)) drop-shadow(0px 3px 5px rgba(0, 0, 0, 0.2)); }
.payment-method .no-payment .box-content .box:last-child { margin-right: 0; }
.payment-method .no-payment .first.fields { margin-top: 80px; }
.payment-method .no-payment .fields .field-box .label { float: left; font-size: 12px; line-height: 12px; font-family: Source Sans Pro; font-weight: 400; margin-bottom: 4px; }
.payment-method .no-payment .fields .field-box .single-field { width: 100%; }
.payment-method .no-payment .fields .field-box .common-fields { display: inline-flex; }
.payment-method .no-payment .fields .last-group { margin-top: 16px; }
.payment-method .no-payment .button { padding: 15px 32px; margin: 24px 0 32px 0; background: #00BCD4; border-radius: 4px; color: #FFFFFF; text-transform: uppercase; font-size: 14px; line-height: 18px; font-family: Source Sans Pro; font-weight: 700; }
