.success-test { padding: 204px 384px 204px 384px; margin: 0 auto; text-align: center}
.success-test img { margin-bottom: 16px; width: 76px; height: 76px; }
.success-test .title { margin-bottom: 8px; font-family: Lora; font-size: 48px; line-height: 52px; }
.success-test .title-sub { margin-bottom: 24px; font-family: Source Sans Pro; font-style: normal; font-weight: normal; font-size: 18px; line-height: 24px; }
.success-test .link { padding: 12px 32px; cursor: pointer; padding: 15px 32px; background: #00BCD4; border-radius: 4px; font-family: Source Sans Pro; font-style: normal; font-weight: bold; font-size: 14px; line-height: 18px; text-transform: uppercase; color: #fff; }
.success-test .link:hover { background: #00BCD4; }

@media screen and (max-width:1040px){
.success-test { 
    padding: 100px 10px; 
}
}

@media screen and (max-width:400px){
    .success-test .title {
         margin-bottom: 8px; 
         font-family: Lora; 
         font-size: 30px; 
         line-height: 52px; 
        }

}