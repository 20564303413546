.seller-profile-relationship-suggestion .assistant-box { padding: 24px; background: #EDEDED; box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px rgba(0, 0, 0, 0.12), 0px 1px 3px rgba(0, 0, 0, 0.2); border-radius: 8px; }

.seller-profile-relationship-suggestion .assistant-box .title { font-size: 24px; line-height: 26px; font-family: Lora; font-weight: 700; margin-bottom: 16px; }
.seller-profile-relationship-suggestion .assistant-box .sub-title { font-size: 18px; line-height: 22px; font-family: Source Sans Pro; font-weight: 900; margin: 8px 0; text-transform: uppercase; }

.seller-profile-relationship-suggestion .assistant-box .service-box { padding: 8px; background: #fff; border-radius: 4px; min-height: 112px; }
.seller-profile-relationship-suggestion .assistant-box .service-box .icon { width: 20px; height: 20px; margin-bottom: 12px; filter: invert(53%) sepia(63%) saturate(6314%) hue-rotate(160deg) brightness(94%) contrast(101%); }
.seller-profile-relationship-suggestion .assistant-box .service-box .text { font-size: 18px; line-height: 24px; font-family: Source Sans Pro; font-weight: 400; }

.seller-profile-relationship-suggestion .assistant-box .message-box { padding: 8px; border-radius: 4px; background: #fff; min-height: 112px; }
.seller-profile-relationship-suggestion .assistant-box .message-box .text { font-size: 18px; line-height: 24px; font-family: Source Sans Pro; font-weight: 400; }

.seller-profile-relationship-suggestion .assistant-box .avatar-group { display: flex; align-items: center; margin-bottom: 16px; }
.seller-profile-relationship-suggestion .assistant-box .avatar-group .avatar { width: 64px; height: 64px; margin-right: 8px; border-radius: 50%; background: url("https://d3kuhob3u5bpy2.cloudfront.net/8d3fd5dc-ca6d-48ef-89de-096bf0f5ed75.jpg"); background-size: cover; }
.seller-profile-relationship-suggestion .assistant-box .avatar-group .name { font-size: 18px; line-height: 22px; font-family: Source Sans Pro; font-weight: 900; text-transform: uppercase; }

.seller-profile-relationship-suggestion .assistant-box .button-group { display: flex; }
.seller-profile-relationship-suggestion .assistant-box .button-group .button-accept { height: 48px; padding: 15px 32px; background: #00BCD4; color: #fff; border-radius: 4px; margin-right: 16px; font-size: 14px; line-height: 18px; font-family: Source Sans Pro; font-weight: 700; text-transform: uppercase; }
.seller-profile-relationship-suggestion .assistant-box .button-group .button-reject { height: 48px; padding: 15px 32px; border: 2px solid #00BCD4; color: #000; border-radius: 4px; font-size: 14px; line-height: 18px; font-family: Source Sans Pro; font-weight: 700; text-transform: uppercase; }

.seller-profile-relationship-suggestion .assistant-box .additional { margin-top: 8px; display: grid; }
.seller-profile-relationship-suggestion .assistant-box .additional .first-half { margin-bottom: 8px; }
.seller-profile-relationship-suggestion .assistant-box .additional .first-half .title { color: #656565; font-size: 16px; line-height: 19px; font-weight: 400; font-family: Roboto; margin-bottom: 6px; }
.seller-profile-relationship-suggestion .assistant-box .additional .block { background: #FFFFFF; border-radius: 4px; padding: 13px; width: fit-content; }
.seller-profile-relationship-suggestion .assistant-box .additional .first-half .block { padding: 13px 26px; }
.seller-profile-relationship-suggestion .assistant-box .additional .block .icon { width: 20px; height: 20px; color: #000000; margin-right: 11px; }
.seller-profile-relationship-suggestion .assistant-box .additional .block .text { font-size: 18px; line-height: 24px; font-family: Source Sans Pro; font-weight: 700; flex: none; }
.seller-profile-relationship-suggestion .assistant-box .additional .second-half .title-content { display: flex; }
.seller-profile-relationship-suggestion .assistant-box .additional .second-half .title-content .title { color: #656565; font-size: 16px; line-height: 19px; font-weight: 400; font-family: Roboto; margin-bottom: 6px; }
.seller-profile-relationship-suggestion .assistant-box .additional .second-half .title-content .icon { width: 20px; height: 20px; color: #000000; margin-left: 11px; margin-top: -1px; }

.seller-profile-relationship-suggestion .suggestion-additional-info { text-align: center; margin-bottom: 24px; }
.seller-profile-relationship-suggestion .suggestion-additional-info .title { font-size: 32px; line-height: 32px; font-family: Lora; font-weight: 700; margin-bottom: 16px; }
.seller-profile-relationship-suggestion .suggestion-additional-info .sub-title { margin: 0 220px; font-size: 18px; line-height: 24px; font-family: Source Sans Pro; font-weight: 400; margin-bottom: 24px; }
.seller-profile-relationship-suggestion .suggestion-additional-info .button-hire { height: 48px; padding: 15px 32px; background: #00BCD4; color: #fff; border-radius: 4px; font-size: 14px; line-height: 18px; font-family: Source Sans Pro; font-weight: 700; text-transform: uppercase; }