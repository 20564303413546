
.premium-property-slider-container{
	position: relative;
	max-width: 100%;
  transform: scale(0.9);
  margin-top: -50px;
}

.premium-slider .slick-slide > div {
  margin: 0 10px !important;
}

.slick-list {
  position: relative;
  display: block;
  margin: 0 -10px;
  max-width: 100%;
}

.prev-btn,.next-btn{
    position: absolute;
    display: block;
    top: 50%;
    transform: translate(0, -60%);
    cursor: pointer;
    font-size: 40px;
    color:#00bcd4;
    z-index: 999 !important;
}

.premium_listings.swiper-slide {
  margin-right: 20px !important;
}

.premium_listings_new.swiper-slide {
  margin-right: 16px !important;
}

.prev-btn{
    left: 20px !important;
}

.next-btn{
    right: 0 !important;
}

.no-result-container{
  display:flex;
  justify-content: center;
  align-items: center;
}

@media screen and (max-width: 640px) {
  .premium-property-slider-container{
    transform: scale(1);
    margin-top: 0;
  }
}