.dialog-selectAd {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

  &-title {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    > button {
      height: 21px;
      width: 21px;
      margin: 21px;
      background-color: transparent;
      border: none;
      cursor: pointer;
      > img {
        width: 14px;
        height: 14px;
      }
    }
  }
  &-content {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding-bottom: 64px;
    width: 100%;
    max-width: 578px;
    > h3 {
      font-family: 'Lora', sans-serif;
      font-style: normal;
      font-weight: 1;
      font-size: 48px;
      line-height: 52px;
      text-align: center;
      color: #000000;
      margin: 0;
      padding: 0;
      margin-bottom: 8px;
    }
    > p {
      max-width: 650px;
      margin: 0;
      padding: 0;
      margin-bottom: 24px;
    }

    > .actions {
      display: flex;
      flex-direction: column;
      > button {
        text-align: center;
        margin-top: 16px;
      }
    }
  }
}
