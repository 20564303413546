.seller-profile-virtual-assistant { padding: 24px 24px 16px 24px; }

.seller-profile-virtual-assistant .header-content { display: flex; }
.seller-profile-virtual-assistant .header-content .avatar .photo { border-radius: 50%; width: 110px; height: 110px; position: relative; margin-bottom: 7px; }
.seller-profile-virtual-assistant .header-content .avatar .photo .icon { filter: invert(44%) sepia(55%) saturate(6704%) hue-rotate(163deg) brightness(99%) contrast(101%); position: absolute; bottom: 0; right: 0; }

.seller-profile-virtual-assistant .header-content .user-details { margin-left: 16px; }
.seller-profile-virtual-assistant .header-content .user-details .title { font-size: 32px; line-height: 32px; margin-bottom: 16px; font-family: Lora; font-weight: 700; }

.seller-profile-virtual-assistant .header-content .user-details .rating { display: flex; align-items: center; margin-bottom: 16px; margin-left: 3px; }
.seller-profile-virtual-assistant .header-content .user-details .rating .title { font-size: 18px; line-height: 22px; font-weight: 900; font-family: Source Sans Pro; margin-right: 8px; text-transform: uppercase; margin-bottom: 0; }
.seller-profile-virtual-assistant .header-content .user-details .rating .star { width: 16px; height: 16px; margin-right: 3px; filter: invert(44%) sepia(55%) saturate(6704%) hue-rotate(163deg) brightness(99%) contrast(101%); }
.seller-profile-virtual-assistant .header-content .user-details .rating .star:last-child { margin-right: 0; }
.seller-profile-virtual-assistant .header-content .user-details .rating .result { font-size: 18px; line-height: 24px; font-weight: 400; font-family: Source Sans Pro; margin-left: 8px; }

.seller-profile-virtual-assistant .header-content .user-details .location .icon { vertical-align: bottom; }

.seller-profile-virtual-assistant .meeting-box { padding: 20px 16px 16px 16px; background: #ffffff; border-radius: 4px; text-align: center; }
.seller-profile-virtual-assistant .meeting-box .icon { margin-right: 8px; margin-bottom: 8px; }
.seller-profile-virtual-assistant .meeting-box .text { font-size: 14px; line-height: 18px; font-family: Source Sans Pro; font-weight: 700; text-transform: uppercase; }

.seller-profile-virtual-assistant .about-me-box { padding: 16px; background: #ffffff; box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px rgba(0, 0, 0, 0.12), 0px 1px 3px rgba(0, 0, 0, 0.2); border-radius: 12px; }
.seller-profile-virtual-assistant .about-me-box .title { margin-bottom: 6px; font-family: Source Sans Pro; font-weight: 700; font-size: 20px; line-height: 24px; text-transform: uppercase; }
.seller-profile-virtual-assistant .about-me-box .text { font-family: Source Sans Pro; font-weight: 400; font-size: 18px; line-height: 24px; }

.seller-profile-virtual-assistant .personal-box { padding: 24px 16px; background: #ffffff; border-radius: 8px; text-align: center; }
.seller-profile-virtual-assistant .personal-box .icon { margin-bottom: 20px; }
.seller-profile-virtual-assistant .personal-box .title { margin-bottom: 8px; font-family: Lora; font-weight: 700; font-size: 24px; line-height: 26px; }
.seller-profile-virtual-assistant .personal-box .text { margin: 0 4px; font-family: Source Sans Pro; font-weight: 400; font-size: 18px; line-height: 24px; }