.login {
  margin: 0 auto;
  text-align: center;
  display: flex;
  flex-direction: row;
  width: 100%;
  position: relative;
}

.login .left-part {
  width: 50%;
  height: 100vh;
  display: flex;
  position: sticky;
  top: 0;
}
.login .left-part .abidors-logo-container {
  width: 100%;
  height: 100vh;
  background-color: #012324;
  display: flex;
  justify-content: center;
  align-items: center;
}
.login .left-part .abidors-logo-container > img {
  width: 400px;
  object-fit: cover;
  cursor: pointer;
}

.login .right-part {
  margin: 30px;
  width: 40%;
  display: flex;
  flex-direction: column;
}
.login .loginForm {
  margin: 0 auto;
  text-align: center;
  height: 100%;
  width: 100%;
}
.login .loginForm .loginTitle {
  font-size: 55px;
  line-height: 52px;
  font-weight: 700;
  margin-bottom: 40px;
  text-transform: capitalize;
}
.login .loginForm .textFieldContainer {
  margin-bottom: 15px;
  margin-left: 0 !important;
  background-color: #e2e2e2;
  border-radius: 8px;
}
.login .loginForm .checkbox {
  margin-top: -4px;
  color: #000 !important;
}
.login .loginForm .checkboxFormControl {
  display: block;
  user-select: none;
}
.login .loginForm .forgot-password-btn {
  font-size: 20px;
  font-weight: 700;
  line-height: 45px;
  text-align: center;
  color: rgba(0, 188, 212, 1);
  text-transform: uppercase;
}
.login .loginForm .button {
  padding: 20px 32px;
  background: #00bcd4;
  color: #fff;
  border-radius: 4px;
  font-size: 20px;
  line-height: 18px;
  font-weight: 700;
  width: 100%;
  margin-top: 15px;
  text-transform: uppercase;
}
.login .loginForm .button:hover {
  background: #00bcd4;
}

.login .line {
  width: 670px;
  background: linear-gradient(180deg, rgba(204, 243, 255, 0) 0%, #ccf3ff 100%);
  margin: 25px auto;
  z-index: 999;
}
.login .text {
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 24px;
  line-height: 26px;
  font-weight: 700;
}
.login .properColor {
  position: relative;
  display: inline-block;
  padding: 8px;
  color: #0097a7;
  font-size: 14px;
  line-height: 18px;
  border-radius: 4px;
  font-weight: 700;
  text-transform: uppercase;
  z-index: 99;
}
.login .image {
  margin-top: -270px;
  background: linear-gradient(180deg, rgba(204, 243, 255, 0) 0%, #ccf3ff 100%);
}

.login .back-to-search-page {
  align-items: center;
  background: #fff;
  border-radius: 50px;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.18);
  display: flex;
  line-height: 1.4;
  width: 80px;
  position: absolute;
  top: 8px;
  z-index: 1000;
  padding: 0px 6px;
  margin: 0px 4px;
  cursor: pointer;
}

.login .back-to-search-page .prev-btn-search {
  cursor: pointer;
  font-size: 40px;
  color: #00bcd4;
  z-index: 999 !important;
  height: 34px;
}

.login .back-to-search-page .image-back-search {
  width: 40px;
  height: 40px;
}

.checkbox-forgot-password-container {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  margin-top: 10px;
}

.no-account {
  display: flex;
  justify-content: center;
  align-items: center;
}

.auth-footer-image {
  position: relative;
  background: linear-gradient(180deg, rgba(204, 243, 255, 0) 0%, #ccf3ff 100%);
}

.auth-footer-image-caption {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.social-login-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
}
.line-with-or {
  margin-top: 8px;
  margin-bottom: 8px;
  width: 100%;
  text-align: center;
  position: relative;
  justify-content: center;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.line-with-or .left {
  width: 40%;
  height: 5px;
  background: linear-gradient(to left, #012324 0%, #04868a, #fff 100%);
  border-radius: 6px;
}

.or-text {
  display: inline-block;
  font-size: 25px;
  margin: 0px 8px;
}
.line-with-or .right {
  width: 40%;
  height: 5px;
  background: linear-gradient(to right, #012324 0%, #04868a, #fff 100%);
  border-radius: 6px;
}

.login .right-part .you-have-already-account {
  margin-top: 15px;
  font-size: 20px;
  font-weight: 400;
  line-height: 45px;
  text-align: center;
  margin-bottom: 0;
}

.login .right-part .you-have-already-account .login-text {
  font-size: 20px;
  font-weight: 700;
  line-height: 45px;
  text-align: center;
  color: rgba(0, 188, 212, 1);
  text-transform: uppercase;
  margin-top: 10px;
}

.checkbox-forgot-password-container .remember-me {
  margin-top: 15px;
  font-size: 20px;
  font-weight: 400;
  line-height: 45px;
  text-align: center;
  margin-bottom: 0;
}

@media screen and (max-width: 1000px) {
  .login .left-part {
    width: 50%;
  }
  .login .right-part {
    width: 50%;
  }
  .login .left-part .abidors-logo-container > img {
    width: 300px;
  }
}

@media screen and (max-width: 800px) {
  .login .left-part {
    display: none;
  }
  .login .right-part {
    width: 100%;
    padding: 10px 50px;
  }
}

@media screen and (max-width: 790px) {
  .login .line {
    width: 100%;
  }
}

@media screen and (max-width: 767px) {
  .login .loginForm .textFieldContainer {
    width: 100%;
  }
}

@media screen and (max-width: 600px) {
}

@media screen and (max-width: 500px) {
}

@media screen and (max-width: 768px) {
  .social-login-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
  }

  .checkbox-forgot-password-container {
    margin-bottom: 10px;
  }

  .no-account {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}

@media screen and (max-width: 560px) {
  .login-container .col.col6of12 {
    width: 100%;
  }
  .login .right-part {
    width: 100%;
    padding: 10px 0px;
  }
  .login .login-title {
    font-size: 40px !important;
    line-height: 44px !important;
  }

  .login .sub-title {
    margin-bottom: 30px !important;
    font-size: 18px;
    line-height: 20px;
  }
  .login .right-part .you-have-already-account {
    font-size: 15px;
    line-height: 15px;
    margin-top: 25px;
  }

  .login .right-part .you-have-already-account .login-text {
    font-size: 15px;
    line-height: 15px;
  }

  .checkbox-forgot-password-container .remember-me {
    font-size: 15px;
    line-height: 15px;
  }

  .login .loginForm .forgot-password-btn {
    font-size: 15px;
    line-height: 15px;
  }

  .login-form .formControlLabel .MuiTypography-body1 {
    margin-left: 10px !important;
    text-align: initial !important;
  }

  .login .right-part .you-have-already-account .login-text {
    display: block;
  }
}
