.photographer-success-modal .photographer-success-modal-content {
    padding: 21px 21px;
}

.photographer-success-modal .photographer-success-modal-content .title-header .close-icon {
    float: right;
    cursor: pointer;
}

.photographer-success-modal .photographer-success-modal-content .photographer-success-modal-content-inner {
    margin: 0 45px;
    padding-top: 40px;
}

.photographer-success-modal .photographer-success-modal-content .photographer-success-modal-content-inner img {
    display: block;
    margin: 0 auto 15px auto;
}

.photographer-success-modal .photographer-success-modal-content .photographer-success-modal-content-inner .title {
    margin-bottom: 8px;
    font-family: Lora;
    font-style: normal;
    font-size: 48px;
    line-height: 52px;
    text-align: center;
    color: #000000;
}

.photographer-success-modal .photographer-success-modal-content .photographer-success-modal-content-inner .title-sub {
    margin: 0 128px 24px 128px;
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 24px;
    text-align: center;
    color: #000000;
}

.photographer-success-modal .photographer-success-modal-content .photographer-success-modal-content-inner .field {
    width: 100%;
    margin-bottom: 24px;
}

.photographer-success-modal .photographer-success-modal-content .photographer-success-modal-content-inner .actions {
    display: flex;
    gap: 24px;
    justify-content: center;
    margin-bottom: 43px;
}

.photographer-success-modal .photographer-success-modal-content .photographer-success-modal-content-inner .actions .button-send {
    padding: 15px 32px;
    background: #00BCD4;
    border-radius: 4px;
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 18px;
    text-transform: uppercase;
    color: #FFFFFF;
}

.photographer-success-modal .photographer-success-modal-content .photographer-success-modal-content-inner .actions .button-close {
    padding: 15px 32px;
    border: 1px solid #00BCD4;
    border-radius: 4px;
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 18px;
    text-transform: uppercase;
    color: #000000;
}


.seller-photographer-profile {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    margin: 32px auto;
    width: calc(100% - 64px);
}

@media screen and (max-width: 815px) {
    .photographer-success-modal .photographer-success-modal-content .photographer-success-modal-content-inner .title-sub {
        margin: 0 !important;
        
    }

    .photographer-success-modal .photographer-success-modal-content .photographer-success-modal-content-inner .actions .button-close {
        margin-top:20px;
    }
    
}

@media screen and (max-width: 600px) { 
    .photographer-success-modal .photographer-success-modal-content .photographer-success-modal-content-inner{
        margin: 0px !important;
    }
}