.lawyer-services {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    margin: 64px 0px;
    > h3 {
        margin: 0;
        padding: 0;
        font-family: 'Lora', sans-serif;
        font-style: normal;
        font-weight: 1;
        font-size: 48px;
        line-height: 52px;
        text-align: center;
        color: #000000;
        margin-bottom: 16px;
    }
    &-items {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        column-gap: 32px;
        width: 100%;
    }
}