.dialog-video-conversation { text-align: center; }
.dialog-video-conversation-content { padding: 64px 64px; }
.dialog-video-conversation-content .title-header { padding: 0; margin-bottom: 8px; }
.dialog-video-conversation-content .title-header .title { display: inline-block; font-size: 48px; line-height: 52px; font-family: Lora; font-weight: 700; }
.dialog-video-conversation-content .title-header .close-icon { position: absolute; top: 20px; right: 20px; cursor: pointer; }

.dialog-video-conversation-content .content { padding: 0; overflow-x: hidden; margin-top: 32px; }
.dialog-video-conversation-content .content .video-canvas { display: flex; flex-direction: column; background: #E8E8E8; border-radius: 8px; height: 576px; }
.dialog-video-conversation-content .content .video-canvas .video-background { flex: 2; align-self: center; width: 290px; height: 290px; }
.dialog-video-conversation-content .content .video-canvas .controls { align-self: flex-end; width: 100%; margin-bottom: 16px; }
.dialog-video-conversation-content .content .video-canvas .controls .controls-items { display: flex; justify-content: center; gap: 10px; }
.dialog-video-conversation-content .content .video-canvas .controls .controls-items .controls-icon { padding: 20px; border-radius: 50%; background: #F2FEFF; box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px rgba(0, 0, 0, 0.12), 0px 1px 3px rgba(0, 0, 0, 0.2); }
.dialog-video-conversation-content .content .video-canvas .controls .controls-items .controls-icon:last-child { justify-content: flex-end; }

.dialog-video-conversation-content .content .actions { margin-top: 8px; display: flex; justify-content: center; }
.dialog-video-conversation-content .content .actions .button-cancel { margin-right: 24px; padding: 15px 32px; background: #00BCD4; border-radius: 4px; color: #fff; text-transform: uppercase; font-size: 14px; line-height: 18px; font-family: Source Sans Pro; font-weight: 700; }
.dialog-video-conversation-content .content .actions .button-close { padding: 15px 32px; border: 2px solid #00BCD4; border-radius: 4px; color: #000; text-transform: uppercase; font-size: 14px; line-height: 18px; font-family: Source Sans Pro; font-weight: 700;  }