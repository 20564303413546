.content-wrapper { background: #E8E8E8; border-radius: 12px; padding: 16px; }
.content-wrapper .title { margin-bottom: 16px; font-family: Lora; font-style: normal; font-weight: bold; font-size: 24px; line-height: 26px; text-align: left; }
.content-wrapper .document-row { display: grid; grid-template-columns: repeat(2, 1fr); grid-gap: 19px; }
.content-wrapper .document-row .document-box { background: #FFFFFF; border-radius: 4px; border-bottom: 9px solid #A5D6A7; padding: 16px 0px 28px 0px; }
.content-wrapper .document-row .document-box .box-header { display: flex; justify-content: center; margin-bottom: 16px; }
.content-wrapper .document-row .document-box .box-header .box-header-icon { margin-right: 12px; }
.content-wrapper .document-row .document-box .box-header .box-header-title { font-family: Lora; font-style: normal; font-weight: bold; font-size: 24px; line-height: 26px; text-align: center; }
.content-wrapper .document-row .document-box .box-icon { margin-bottom: 8px; }
.content-wrapper .document-row .document-box .text-desc { margin: 0px 153px 15px 153px; font-family: Source Sans Pro; font-style: normal; font-weight: 900; font-size: 18px; line-height: 22px; text-align: center; text-transform: uppercase; color: #000000;  }
.content-wrapper .document-row .document-box .actions { margin-bottom: 8px; }
.content-wrapper .document-row .document-box .actions .action-header { display: flex; justify-content: center; gap: 42px; margin-bottom: 8px; }
.content-wrapper .document-row .document-box .actions .action-header .header-item { font-family: Roboto; font-style: normal; font-weight: normal; font-size: 12px; line-height: 14px; color: #000000; }
.content-wrapper .document-row .document-box .actions .action-items { display: flex; justify-content: center; }
.content-wrapper .document-row .document-box .actions .action-items .action-find { justify-self: left; margin-right: 40px; }
.content-wrapper .document-row .document-box .actions .action-items .action-pdf { justify-self: right; }
.content-wrapper .document-row .document-box .actions .action-items .action-word { justify-self: right; }

.content-wrapper .document-row .document-not-uploaded-box { border: 1px dashed #000000; box-sizing: border-box; border-radius: 4px; padding: 94px 0px; }
.content-wrapper .document-row .document-not-uploaded-box .box-header { display: flex; justify-content: center; margin-bottom: 16px; }
.content-wrapper .document-row .document-not-uploaded-box .box-header .box-header-icon { margin-right: 12px; }
.content-wrapper .document-row .document-not-uploaded-box .box-header .box-header-title { font-family: Lora; font-style: normal; font-weight: bold; font-size: 24px; line-height: 26px; text-align: center; }
.content-wrapper .document-row .document-not-uploaded-box .button-add-doc { border-radius: 4px; padding: 11px 32px; font-family: Source Sans Pro; font-style: normal; font-weight: bold; font-size: 14px; line-height: 18px; text-transform: uppercase; color: #0097A7; }

.content-wrapper .document-not-uploaded-box-row { margin-top: 16px; border: 1px dashed #000000; box-sizing: border-box; border-radius: 4px; padding: 18.5px 0px; }
.content-wrapper .document-not-uploaded-box-row .box-header { display: flex; justify-content: center; margin-bottom: 16px; }
.content-wrapper .document-not-uploaded-box-row .box-header .box-header-icon { margin-right: 12px; }
.content-wrapper .document-not-uploaded-box-row .box-header .box-header-title { font-family: Lora; font-style: normal; font-weight: bold; font-size: 24px; line-height: 26px; text-align: center; }
.content-wrapper .document-not-uploaded-box-row .button-add-doc { border-radius: 4px; padding: 11px 32px; font-family: Source Sans Pro; font-style: normal; font-weight: bold; font-size: 14px; line-height: 18px; text-transform: uppercase; color: #0097A7; }