.pricingPage {
  text-align: center;
  margin: 0 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
}

.pricingPage .banner {
  z-index: 1;
  position: relative;
  width: 100%;
  height: 100vh;
  background-image: url("https://d3kuhob3u5bpy2.cloudfront.net/0489d488-2192-4054-8926-1f898fc1418b.jpg"); /* Replace with your image URL */
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  padding: 0;
}

.pricingPage .banner .overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgb(0, 151, 167, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
}

.pricingPage .banner .title {
  font-family: "Poppins";
  font-weight: 900;
  font-size: 110px;
  width: 67%;
  line-height: 100px;
}
.pricingPage .banner .labels-group1 {
  position: absolute;
  top: 0;
  width: 100%;
  padding-left: 130px;
  padding-top: 100px;
  padding-right: 130px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-family: "Poppins";
}

.pricingPage .banner .labels-group2 {
  position: absolute;
  bottom: 0;
  width: 100%;
  padding-left: 130px;
  padding-bottom: 120px;
  padding-right: 130px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-family: "Poppins";
}

.pricingPage .description {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 30px;
  padding-top: 10px;
  padding-left: 130px;
  padding-right: 130px;
  margin-top: 70px;
}

.pricingPage .description .title {
  width: fit-content;
  font-family: "Poppins";
  font-size: 40px;
  font-weight: bold;
}
.pricingPage .description .paragraph {
  width: 60%;
  font-family: "Poppins";
  font-size: 18px;
  margin-top: 20px;
  text-align: left;
}

.pricingPage .buyer-seller-pricing {
  padding-left: 130px;
  padding-right: 130px;
  padding-top: 20px;
  padding-bottom: 20px;
  margin-top: 40px;
}

.pricingPage .buyer-seller-pricing .title {
  width: fit-content;
  font-family: "Poppins";
  font-size: 40px;
  font-weight: bold;
}

.pricingPage .buyer-seller-pricing .descriptions {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-top: 50px;
  padding-bottom: 50px;
}
.pricingPage .buyer-seller-pricing .descriptions .description-item {
  width: 47%;
  font-family: "Poppins";
  text-align: left;
}

.pricingPage .buyer-seller-prices {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin-top: 30px;
  background: #00bcd4;
  padding-left: 100px;
  padding-right: 100px;
  padding-top: 70px;
  padding-bottom: 70px;
}

.pricingPage .buyer-seller-prices .pricing-item {
  width: 600px;
  min-height: 620px;
  border-radius: 10px;
  background: white;
  padding: 30px;
  position: relative;
}

.pricingPage .buyer-seller-prices .pricing-item .label {
  text-align: left;
  white-space: pre;
  font-weight: 400;
  font-style: normal;
  font-family: "Poppins";
  color: #000000;
  font-size: 14px;
  letter-spacing: 0em;
  line-height: 1.5;
  text-align: left;
}

.pricingPage .buyer-seller-prices .pricing-item .title {
  white-space: wrap;
  font-weight: 400;
  font-style: normal;
  font-family: "Poppins";
  color: #2c6e49;
  font-size: 40px;
  letter-spacing: 0em;
  line-height: 1;
  text-align: left;
  margin-top: 20px;
}

.pricingPage .buyer-seller-prices .pricing-item .learn-more {
  padding: 10px;
  border-radius: 6px;
  background: black;
  border: none;
  width: 100%;
  color: white;
  font-size: 18px;
  position: absolute;
  left: 0;
  bottom: 0;
  font-family: "Poppins";
}

.pricingPage .service-commission {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  /* padding: 30px; 
  padding-top: 10px;
  padding-left: 130px;
  padding-right: 130px; */
  margin-top: 70px;
  padding: 10px 130px 30px 130px;
}

.pricingPage .service-commission .title {
  width: fit-content;
  font-family: "Poppins";
  font-size: 40px;
  font-weight: bold;
}

.pricingPage .service-commission .description-paragraph {
  width: 60%;
  font-family: "Poppins";
  font-size: 18px;
  margin-top: 20px;
  text-align: left;
}

.pricingPage .service-commission .commission-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  margin-top: 50px;
  display: flex;
  align-items: center;
}

.pricingPage .service-commission .commission-container .commission-image {
  width: 480px;
  height: 100%;
  border-radius: 6px;
  object-fit: cover;
}
.pricingPage .service-commission .commission-container .pricings {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.pricingPage .service-commission .commission-container .pricings .pricing-item {
  display: flex;
  flex-direction: column;
  gap: 5px;
  width: 60%;
}

.pricingPage
  .service-commission
  .commission-container
  .pricings
  .pricing-item
  .title {
  white-space: pre-wrap;
  word-wrap: break-word;
  word-break: break-word;
  font-weight: 700;
  font-style: normal;
  font-family: "Poppins";
  color: #000000;
  font-size: 20px;
  letter-spacing: -0.025em;
  line-height: 1.5;
  text-align: left;
}

.pricingPage
  .service-commission
  .commission-container
  .pricings
  .pricing-item
  .description {
  white-space: pre-wrap;
  word-wrap: break-word;
  word-break: break-word;
  overflow: visible;
  font-weight: 500;
  font-style: normal;
  font-family: "Poppins";
  color: #1b4965;
  font-size: 16px;
  letter-spacing: 0em;
  line-height: 1.5;
  text-align: left;
  padding: 0;
  margin: 0;
  width: 90%;
  margin-bottom: 10px;
}

.pricingPage .faq {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  /* padding: 30px;
  padding-top: 10;
  padding-left: 130px;
  padding-right: 130px; */
  padding: 10px 130px 30px 130px;
  margin-top: 70px;
}

.pricingPage .faq .title {
  width: fit-content;
  font-family: "Poppins";
  font-size: 40px;
  font-weight: bold;
}

.pricingPage .faq .questions-container {
  display: grid;
  grid-template-columns: 1fr 1fr; /* Two equal-width columns */
  gap: 50px;
  margin-top: 50px;
}

.pricingPage .faq .questions-container .question-item .question {
  white-space: pre-wrap;
  word-wrap: break-word;
  word-break: break-word;
  overflow: visible;
  font-weight: 600;
  font-style: normal;
  font-family: "Poppins";
  color: #00171f;
  font-size: 20px;
  letter-spacing: -0.025em;
  line-height: 1.5;
  text-align: left;
  width: fit-content;
}
.pricingPage .faq .questions-container .question-item .answer {
  white-space: pre-wrap;
  word-wrap: break-word;
  word-break: break-word;
  width: 400px;
  overflow: visible;
  font-weight: 400;
  font-style: normal;
  font-family: "Poppins";
  color: #003459;
  font-size: 16px;
  letter-spacing: 0em;
  line-height: 1.5;
  text-align: left;
}

.pricingPage .join-abidors {
  margin-top: 50px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 15px;
  justify-content: center;
  align-items: center;
}
.pricingPage .join-abidors .title-banner {
  overflow: visible;
  font-weight: 400;
  font-style: normal;
  font-family: "Poppins";
  color: #1b4965;
  font-size: 40px;
  text-align: center;
  height: fit-content;
  width: fit-content;
}

.pricingPage .join-abidors .description-banner {
  width: 35%;
  max-width: 100%;
  overflow: visible;
  font-weight: 500;
  font-style: normal;
  font-family: "Poppins";
  color: #1b4965;
  font-size: 16px;
  line-height: 25px;
  text-align: center;
  height: fit-content;
}

.pricingPage .actions {
  display: flex;
  flex-direction: row;
  gap: 20px;
}

.pricingPage .actions .sign-up,
.pricingPage .actions .login {
  font-family: "Poppins";
  font-size: 14px;
  font-weight: 700;
  text-align: center;
  padding: 10px 20px;
  border-radius: 5px;
  color: #fff;
  background-color: #00bcd4;
  text-transform: none;
  border: none;
}

.pricingPage .actions .sign-up,
.pricingPage .actions .login:hover {
  background-color: rgba(0, 188, 212, 0.8);
}

.pricingPage
  .service-commission
  .commission-container
  .pricings
  .pricing-item
  .video {
  height: 350px;
  margin-bottom: 24px;
  border-radius: 4px;
  position: relative;
  justify-content: center;
  align-items: center;
  display: flex;
  width: 90%;
}

.pricingPage
  .service-commission
  .commission-container
  .pricings
  .pricing-item
  .video
  .video-play {
  width: 100%;
  height: 100%;
}

.pricingPage
  .service-commission
  .commission-container
  .pricings
  .pricing-item
  .video
  .video-play-button {
  cursor: pointer;
}
.pricingPage
  .service-commission
  .commission-container
  .pricings
  .pricing-item
  .video
  .video-play-button-container {
  position: absolute;
  top: 45%;
  left: 45%;
  z-index: 1;
}

.pricingPage .video .video-loader {
  background-color: black;
  z-index: 999999;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 350px;
  width: 100%;
  position: absolute;
  background-size: cover;
  background-position: center;
  background-position: 0%;
  background-image: url("https://d3kuhob3u5bpy2.cloudfront.net/700836e5-fa87-4a0b-be1e-fc3f9028c084.png");
}
.pricingPage .video .video-loader .circular-loader {
  position: absolute;
  top: -6.5px;
  left: -6.5px;
  z-index: 300;
  cursor: pointer;
}

.commission-container .pricing-item .virtual-assistant {
  text-align: left;
  margin-bottom: 30px;
}

.commission-container .pricing-item .virtual-assistant .title {
  white-space: pre-wrap;
  word-wrap: break-word;
  word-break: break-word;
  font-weight: 700;
  font-style: normal;
  font-family: "Poppins";
  color: #000000;
  font-size: 20px;
  letter-spacing: -0.025em;
  line-height: 1.5;
  text-align: left;
}

.commission-container .pricing-item .virtual-assistant .content ul {
  margin-bottom: 10px;
}

.commission-container .pricing-item .virtual-assistant .content ul li {
  white-space: pre-wrap;
  word-wrap: break-word;
  word-break: break-word;
  overflow: visible;
  font-weight: 500;
  font-style: normal;
  font-family: "Poppins";
  color: #1b4965;
  font-size: 16px;
  letter-spacing: 0em;
  line-height: 1.5;
  text-align: left;
  padding: 0;
  margin: 0;
  width: 90%;
  margin-bottom: 10px;
}

.pro {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 5px;
  margin-bottom: 13px;
  height: 50px;
}

.pro .pro-icon {
  margin-top: 1px;
}

.pro .pro-text {
  text-align: left;
  white-space: pre-wrap;
  word-wrap: break-word;
  word-break: break-word;
  font-weight: 400;
  font-style: normal;
  font-family: "Poppins";
  color: #000000;
  font-size: 13px;
  letter-spacing: 0em;
  line-height: 1.5;
  text-align: left;
  height: fit-content;
  width: fit-content;
}
.pricingPage
  .service-commission
  .commission-container
  .pricings
  .va-key-points {
  display: flex;
  flex-direction: column;
}

.pricingPage
  .service-commission
  .commission-container
  .pricings
  .va-key-points
  .title {
  text-align: left;
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 60px;
  line-height: 73px;

  background: linear-gradient(90deg, #ff5722 0%, #012324 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}

.pricingPage
  .service-commission
  .commission-container
  .pricings
  .va-key-points
  .description-va {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 25px;
  line-height: 30px;
  text-align: left;
  width: 100%;
  margin-top: 10px;
}

.pricingPage
  .service-commission
  .commission-container
  .pricings
  .va-key-points
  .pricing-page-va-image {
  width: 100%;
  margin-top: 10px;
  border-radius: 10px;
}

.pricingPage .service-commission .pricings .va-key-points .benefits-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  margin-top: 20px;
}

.pricingPage
  .service-commission
  .pricings
  .va-key-points
  .benefits-container
  .benefit-box {
  box-sizing: border-box;

  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 50px 30px;
  gap: 20px;
  margin: 0 auto;
  width: 32%;

  background: linear-gradient(
    180deg,
    rgba(255, 87, 34, 0.1) 0%,
    rgba(255, 255, 255, 0.1) 31%
  );
  border-top: 10px solid #ff5722;
  box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
}

.pricingPage
  .service-commission
  .pricings
  .va-key-points
  .benefits-container
  .benefit-box
  .title-box {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  line-height: 36px;
  color: #ff5722;
}
.pricingPage
  .service-commission
  .pricings
  .va-key-points
  .benefits-container
  .benefit-box
  .description-box {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
}
.pricingPage .service-commission .pricings .va-key-points .advices {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 40px;
}
.pricingPage
  .service-commission
  .pricings
  .va-key-points
  .advices
  .advice-container {
  display: flex;
  flex-direction: row;
  gap: 20px;
  justify-content: space-between;
}
.pricingPage
  .service-commission
  .pricings
  .va-key-points
  .advices
  .advice-container
  .advice-number {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.pricingPage .service-commission .pricings .va-key-points .tips {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  margin-top: 20px;
}
.pricingPage .service-commission .pricings .va-key-points .tips .tip-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 30px 20px;
  gap: 10px;
  width: 24.5%;
  background: rgba(255, 87, 34, 0.05);
  border-radius: 20px;
}
.pricingPage
  .service-commission
  .pricings
  .va-key-points
  .tips
  .tip-container
  .tip-image {
  width: 50px;
  height: 50px;
  background-color: #ff5722;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.pricingPage
  .service-commission
  .pricings
  .va-key-points
  .tips
  .tip-container
  .tip-image
  .image {
  width: 30px;
}
.pricingPage
  .service-commission
  .pricings
  .va-key-points
  .tips
  .tip-container
  .tip-title {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 22px;
  color: #012324;
}

.pricingPage
  .service-commission
  .pricings
  .va-key-points
  .tips
  .tip-container
  .tip-description {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
  text-align: center;
  color: #012324;
}

.pricingPage
  .service-commission
  .pricings
  .va-key-points
  .advices
  .advice-container
  .advice-number
  .number {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 85px;
  height: 85px;
  border-radius: 50%;
  background-color: #ff5722;
  color: white;
  font-size: 45px;
  font-weight: 600;
}
.pricingPage
  .service-commission
  .pricings
  .va-key-points
  .advices
  .advice-container
  .advice-number
  .step-word {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  color: #5f5f5f;
}

.pricingPage
  .service-commission
  .pricings
  .va-key-points
  .advices
  .advice-container
  .advice-content {
  text-align: left;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 20px 40px;
  gap: 5px;
  background: rgba(255, 87, 34, 0.1);
  width: 100%;
  border-radius: 22px;
}
.pricingPage
  .service-commission
  .pricings
  .va-key-points
  .advices
  .advice-container
  .advice-content
  .advice-title {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
}
.pricingPage
  .service-commission
  .pricings
  .va-key-points
  .advices
  .advice-container
  .advice-content
  .advice-description {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 30px;
  color: #5f5f5f;
}
.pricingPage
  .service-commission
  .pricings
  .va-key-points
  .advices
  .advice-container
  .advice-content
  .advice-description.more {
  color: #ff5722;
  font-weight: 500;
}

.pricingPage
  .service-commission
  .pricings
  .va-key-points
  .your-journey-as--a-va {
  background: rgba(0, 128, 0, 0.25);
  padding: 70px 90px;
  border-radius: 20px;
  position: relative;
  margin-top: 20px;
  width: 100%;
  gap: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.pricingPage
  .service-commission
  .pricings
  .va-key-points
  .your-journey-as--a-va
  .your-journey-title {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 25px;
  line-height: 30px;
  text-align: center;
}

.pricingPage
  .service-commission
  .pricings
  .va-key-points
  .your-journey-as--a-va
  .your-journey-subtitle {
  font-family: "Inter";
  font-style: normal;
  font-size: 20px;
  line-height: 30px;
  text-align: center;
  color: #012324;
  font-weight: 600;
}

.pricingPage
  .service-commission
  .pricings
  .va-key-points
  .your-journey-as--a-va
  .your-journey-description {
  font-family: "Inter";
  font-style: normal;
  font-size: 20px;
  line-height: 30px;
  text-align: center;
  color: #012324;
}

.pricingPage
  .service-commission
  .pricings
  .va-key-points
  .your-journey-as--a-va
  .ellipse {
  position: absolute;
  top: 0;
  left: 0;
}
.pricingPage
  .service-commission
  .pricings
  .va-key-points
  .your-journey-as--a-va
  .ellipse-two {
  position: absolute;
  bottom: 0;
  right: 0;
}

.pricingPage .service-commission .pricings .va-key-points .quick-tips {
  display: flex;
  flex-direction: column;
  margin-top: 30px;
  gap: 10px;
}
.pricingPage
  .service-commission
  .pricings
  .va-key-points
  .quick-tips
  .quick-tips-title {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 40px;
  line-height: 48px;
  text-align: left;
}

.pricingPage
  .service-commission
  .pricings
  .va-key-points
  .quick-tips
  .quick-tips-content {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.pricingPage
  .service-commission
  .pricings
  .va-key-points
  .quick-tips
  .quick-tips-content
  .quick-tip-container {
  background-color: rgba(255, 87, 34, 0.1);
  border-radius: 20px;
  padding: 20px;
  margin-bottom: 10px;
}
.pricingPage
  .service-commission
  .pricings
  .va-key-points
  .quick-tips
  .quick-tips-content
  .quick-tip-container
  .left-image {
  float: left;
  width: 5%;
  margin-right: 10px;
}
.pricingPage
  .service-commission
  .pricings
  .va-key-points
  .quick-tips
  .quick-tips-content
  .quick-tip-container
  .quick-tip-description {
  float: left;
  font-family: "Inter";
  font-size: 25px;
  font-weight: 500;
  line-height: 30.26px;
  text-align: left;
  width: 85%;
  margin-bottom: 0;
}

.pricingPage
  .service-commission
  .pricings
  .va-key-points
  .start-referring-today {
  width: 100%;
  padding: 20px;
  background: #000000;
  border-radius: 20px;
  color: white;
  text-align: center;
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  line-height: 36px;
  text-align: center;
  margin-top: 20px;
}

.pricingPage
.service-commission
.pricings
.va-key-points
.virtual-assistant-button {
font-family: "Poppins";
font-size: 20px;
font-weight: 700;
text-align: center;
padding: 10px 20px;
border-radius: 5px;
color: #fff;
background-color: #00bcd4;
text-transform: none;
margin-bottom: 20px;
}

.pricingPage
.service-commission
.pricings
.va-key-points
.virtual-assistant-button:hover {
background-color: rgba(0, 188, 212, 0.8);
}

@media screen and (max-width: 1258px) {
  .pricingPage .banner .title {
    font-size: 100px;
  }

  .pricingPage
    .service-commission
    .commission-container
    .pricings
    .pricing-item
    .video {
    height: 100%;
    margin-bottom: 24px;
    background-color: #f8f8f8;
    border-radius: 4px;
    position: relative;
    justify-content: center;
    align-items: center;
    display: flex;
  }

  .pricingPage
    .service-commission
    .commission-container
    .pricings
    .pricing-item
    .video
    .video-play-button-container {
    position: absolute;
    top: 45%;
    left: 45%;
    z-index: 1;
  }
  .pricingPage .video .video-loader {
    height: 100%;
  }
}

@media screen and (max-width: 991px) {
  .pricingPage .banner .title {
    font-size: 80px;
  }
  .pricingPage .description {
    padding-left: 40px;
    padding-right: 40px;
  }
  .pricingPage .description .paragraph {
    width: 100%;
  }

  .pricingPage .description .title {
    width: 40px;
    width: 100%;
  }

  .pricingPage .buyer-seller-pricing {
    padding-left: 40px;
    padding-right: 40px;
    padding-top: 40px;
    padding-bottom: 40px;
    margin-top: 40px;
  }

  .pricingPage .buyer-seller-pricing .descriptions {
    flex-direction: column;
    padding-top: 50px;
    padding-bottom: 50px;
  }
  .pricingPage .buyer-seller-pricing .descriptions .description-item {
    width: 100%;
  }

  .pricingPage .buyer-seller-prices {
    flex-direction: column;
    justify-content: center;
    padding-left: 30px;
    padding-right: 30px;
    gap: 30px;
  }

  .pricingPage .buyer-seller-prices .pricing-item {
    width: 100%;
  }

  .pricingPage .buyer-seller-prices .pricing-item .label {
    font-size: 12px;
  }

  .pricingPage .buyer-seller-prices .pricing-item .title {
    font-size: 30px;
  }

  .pricingPage .buyer-seller-prices .pricing-item .learn-more {
    font-size: 13px;
  }

  .pricingPage .service-commission {
    flex-direction: column;
    justify-content: center;
    padding: 40px;
    padding-top: 40px;
    padding-left: 40px;
    padding-right: 40px;
    margin-top: 50px;
  }

  .pricingPage .service-commission .title {
    width: fit-content;
    font-family: "Poppins";
    font-size: 40px;
    font-weight: bold;
  }

  .pricingPage .service-commission .description-paragraph {
    width: 93%;
    margin-top: 20px;
  }

  .pricingPage .service-commission .commission-container {
    flex-direction: column-reverse;
    width: 100%;
    margin-top: 50px;
    gap: 20px;
  }

  .pricingPage .service-commission .commission-container .commission-image {
    width: 100%;
    height: auto;
  }
  .pricingPage .service-commission .commission-container .pricings {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 10px;
  }

  .pricingPage
    .service-commission
    .commission-container
    .pricings
    .pricing-item
    .title {
    font-size: 17px;
  }

  .pricingPage
    .service-commission
    .commission-container
    .pricings
    .pricing-item
    .description {
    font-size: 15px;
  }

  .pricingPage .faq {
    padding: 40px;
    padding-left: 40px;
    padding-right: 40px;
    margin-top: 50px;
  }

  .pricingPage .faq .questions-container {
    display: flex;
    flex-direction: column;
    gap: 30px;
    margin-top: 30px;
    width: 100%;
  }

  .pricingPage .faq .questions-container .question-item .question {
    width: 100%;
  }
  .pricingPage .faq .questions-container .question-item .answer {
    width: 100%;
  }
  .pricingPage
    .service-commission
    .commission-container
    .pricings
    .pricing-item
    .video {
    width: 100%;
  }
  .pro .pro-text {
    font-size: 11px;
  }
  .pricingPage
    .service-commission
    .commission-container
    .pricings
    .pricing-item {
    width: 100%;
  }

  .pricingPage
    .service-commission
    .commission-container
    .pricings
    .va-key-points {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .pricingPage
    .service-commission
    .commission-container
    .pricings
    .va-key-points
    .title {
    font-size: 25px;
    line-height: 35px;
  }

  .pricingPage
    .service-commission
    .commission-container
    .pricings
    .va-key-points
    .description-va {
    font-size: 15px;
    line-height: 20px;
  }

  .pricingPage
    .service-commission
    .commission-container
    .pricings
    .va-key-points
    .pricing-page-va-image {
    width: 100%;
    margin-top: 10px;
    border-radius: 10px;
  }

  .pricingPage
    .service-commission
    .pricings
    .va-key-points
    .benefits-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    gap: 20px;
    margin-top: 20px;
  }

  .pricingPage
    .service-commission
    .pricings
    .va-key-points
    .benefits-container
    .benefit-box {
    padding: 20px 20px;
    gap: 20px;
    margin: 0 auto;
    width: 100%;

    background: linear-gradient(
      180deg,
      rgba(255, 87, 34, 0.1) 0%,
      rgba(255, 255, 255, 0.1) 31%
    );
    border-top: 10px solid #ff5722;
    box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
  }

  .pricingPage
    .service-commission
    .pricings
    .va-key-points
    .benefits-container
    .benefit-box
    .title-box {
    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 25px;
    color: #ff5722;
  }
  .pricingPage
    .service-commission
    .pricings
    .va-key-points
    .benefits-container
    .benefit-box
    .description-box {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
  }
  .pricingPage .service-commission .pricings .va-key-points .advices {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-top: 40px;
  }
  .pricingPage
    .service-commission
    .pricings
    .va-key-points
    .advices
    .advice-container {
    display: flex;
    flex-direction: row;
    gap: 10px;
    justify-content: space-between;
  }
  .pricingPage
    .service-commission
    .pricings
    .va-key-points
    .advices
    .advice-container
    .advice-number {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  .pricingPage .service-commission .pricings .va-key-points .tips {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 20px;
    gap: 10px;
  }
  .pricingPage
    .service-commission
    .pricings
    .va-key-points
    .tips
    .tip-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 30px 20px;
    gap: 10px;
    width: 100%;
    background: rgba(255, 87, 34, 0.05);
    border-radius: 20px;
  }
  .pricingPage
    .service-commission
    .pricings
    .va-key-points
    .tips
    .tip-container
    .tip-image {
    width: 50px;
    height: 50px;
    background-color: #ff5722;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .pricingPage
    .service-commission
    .pricings
    .va-key-points
    .tips
    .tip-container
    .tip-image
    .image {
    width: 30px;
  }
  .pricingPage
    .service-commission
    .pricings
    .va-key-points
    .tips
    .tip-container
    .tip-title {
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 22px;
    color: #012324;
  }

  .pricingPage
    .service-commission
    .pricings
    .va-key-points
    .tips
    .tip-container
    .tip-description {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 20px;
    text-align: center;
    color: #012324;
  }

  .pricingPage
    .service-commission
    .pricings
    .va-key-points
    .advices
    .advice-container
    .advice-number
    .number {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: #ff5722;
    color: white;
    font-size: 25px;
    font-weight: 600;
  }
  .pricingPage
    .service-commission
    .pricings
    .va-key-points
    .advices
    .advice-container
    .advice-number
    .step-word {
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    color: #5f5f5f;
  }

  .pricingPage
    .service-commission
    .pricings
    .va-key-points
    .advices
    .advice-container
    .advice-content {
    text-align: left;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 20px 20px;
    gap: 5px;
    background: rgba(255, 87, 34, 0.1);
    width: 100%;
    border-radius: 22px;
  }
  .pricingPage
    .service-commission
    .pricings
    .va-key-points
    .advices
    .advice-container
    .advice-content
    .advice-title {
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
  }
  .pricingPage
    .service-commission
    .pricings
    .va-key-points
    .advices
    .advice-container
    .advice-content
    .advice-description {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 30px;
    color: #5f5f5f;
  }
  .pricingPage
    .service-commission
    .pricings
    .va-key-points
    .advices
    .advice-container
    .advice-content
    .advice-description.more {
    color: #ff5722;
    font-weight: 500;
  }

  .pricingPage
    .service-commission
    .pricings
    .va-key-points
    .your-journey-as--a-va {
    background: rgba(0, 128, 0, 0.25);
    padding: 15px 15px;
    border-radius: 20px;
    position: relative;
    margin-top: 20px;
    width: 100%;
    gap: 5px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .pricingPage
    .service-commission
    .pricings
    .va-key-points
    .your-journey-as--a-va
    .your-journey-title {
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 35px;
    text-align: center;
  }

  .pricingPage
    .service-commission
    .pricings
    .va-key-points
    .your-journey-as--a-va
    .your-journey-subtitle {
    font-family: "Inter";
    font-style: normal;
    font-size: 15px;
    line-height: 25px;
    text-align: center;
    color: #012324;
    font-weight: 600;
  }

  .pricingPage
    .service-commission
    .pricings
    .va-key-points
    .your-journey-as--a-va
    .your-journey-description {
    font-family: "Inter";
    font-style: normal;
    font-size: 15px;
    line-height: 25px;
    text-align: center;
    color: #012324;
  }

  .pricingPage
    .service-commission
    .pricings
    .va-key-points
    .your-journey-as--a-va
    .ellipse {
    position: absolute;
    top: 0;
    left: 0;
  }
  .pricingPage
    .service-commission
    .pricings
    .va-key-points
    .your-journey-as--a-va
    .ellipse-two {
    position: absolute;
    bottom: 0;
    right: 0;
  }

  .pricingPage .service-commission .pricings .va-key-points .quick-tips {
    display: flex;
    flex-direction: column;
    margin-top: 30px;
    gap: 10px;
  }
  .pricingPage
    .service-commission
    .pricings
    .va-key-points
    .quick-tips
    .quick-tips-title {
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 25px;
    line-height: 30px;
    text-align: left;
  }

  .pricingPage
    .service-commission
    .pricings
    .va-key-points
    .quick-tips
    .quick-tips-content {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  .pricingPage
    .service-commission
    .pricings
    .va-key-points
    .quick-tips
    .quick-tips-content
    .quick-tip-container {
    background-color: rgba(255, 87, 34, 0.1);
    border-radius: 20px;
    padding: 20px;
    margin-bottom: 10px;
  }
  .pricingPage
    .service-commission
    .pricings
    .va-key-points
    .quick-tips
    .quick-tips-content
    .quick-tip-container
    .left-image {
    float: left;
    width: 10%;
    margin-right: 10px;
  }
  .pricingPage
    .service-commission
    .pricings
    .va-key-points
    .quick-tips
    .quick-tips-content
    .quick-tip-container
    .quick-tip-description {
    float: left;
    font-family: "Inter";
    font-size: 15px;
    font-weight: 500;
    line-height: 25px;
    text-align: left;
    width: 85%;
    margin-bottom: 0;
  }

  .pricingPage
    .service-commission
    .pricings
    .va-key-points
    .start-referring-today {
    width: 100%;
    padding: 15px;
    background: #000000;
    border-radius: 20px;
    color: white;
    text-align: center;
    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    font-size: 17px;
    line-height: 25px;
    text-align: center;
    margin-top: 20px;
  }
  .pricingPage
    .service-commission
    .pricings
    .va-key-points
    .virtual-assistant-button {
    font-family: "Poppins";
    font-size: 14px;
    font-weight: 700;
    text-align: center;
    padding: 10px 20px;
    border-radius: 5px;
    color: #fff;
    background-color: #00bcd4;
    text-transform: none;
  }

  .pricingPage
    .service-commission
    .pricings
    .va-key-points
    .virtual-assistant-button:hover {
    background-color: rgba(0, 188, 212, 0.8);
  }
}

@media screen and (max-width: 600px) {
  .pricingPage .banner .title {
    font-size: 70px;
  }
  .pricingPage .buyer-seller-pricing .title {
    font-size: 30px;
  }

  .pricingPage .description .title {
    font-size: 40px;
  }

  .pricingPage .buyer-seller-prices .pricing-item {
    height: 740px;
  }

  .pricingPage .buyer-seller-prices .pricing-item .title {
    font-size: 20px;
  }
  .pricingPage .service-commission .title {
    font-size: 30px;
  }

  .pricingPage .join-abidors {
    margin-top: 50px;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 15px;
    justify-content: center;
    align-items: center;
  }
  .pricingPage .join-abidors .title-banner {
    font-size: 35px;
  }

  .pricingPage .join-abidors .description-banner {
    width: 80%;
  }
  .pricingPage
    .service-commission
    .commission-container
    .pricings
    .pricing-item
    .video
    .video-play-button-container {
    position: absolute;
    top: 35%;
    left: 45%;
    z-index: 1;
  }

  .pro {
    height: fit-content;
  }
}

@media screen and (max-width: 527px) {
  .pricingPage .banner .title {
    font-size: 60px;
  }
  .pricingPage .banner .labels-group1 {
    padding-top: 50px;
    font-size: 10px;
  }
  .pricingPage .banner .labels-group2 {
    font-size: 10px;
    padding-bottom: 50px;
  }

  .pricingPage .description {
    padding: 30px;
    padding-top: 10px;
    padding-left: 40px;
    padding-right: 20px;
    margin-top: 10px;
  }

  .pricingPage .description .title {
    font-size: 30px;
  }
  .pricingPage .description .paragraph {
    width: 93%;
    margin-top: 20px;
  }
  .pricingPage .faq .title {
    font-size: 30px;
  }
  .video-play-button {
    width: 50px;
    height: 50px;
  }
  .pricingPage .video .video-loader .circular-loader {
    top: -4.4px;
    left: -4.5px;
  }
}

@media screen and (max-width: 500px) {
  .pricingPage .video .video-loader .circular-loader {
    top: -3.5px;
    left: -3.5px;
  }
}

@media screen and (max-width: 400px) {
  .pricingPage .banner .title {
    font-size: 40px;
  }
}

@media screen and (max-width: 350px) {
  .pricingPage .banner .title {
    font-size: 40px;
  }
}
