.my-adds-visuals-wrapper {
  >.notification {
    background-color: white;
    border-radius: 4px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;

    >h1 {
      font-family: Lora;
      font-size: 32px;
      font-style: normal;
      font-weight: 700;
      line-height: 32px;
      letter-spacing: 0em;
      text-align: center;
      margin-top: 24px;
    }

    >p {
      font-family: Source Sans Pro;
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      max-width: 790px;
      margin-top: 8px;
      margin-bottom: 24px;
    }

    >button {
      margin-bottom: 24px;
    }
  }

  >.uploadArea {
    margin-top: 32px;

    >p {
      font-family: Lora;
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
      line-height: 26px;
      letter-spacing: 0em;
      text-align: left;
      margin-bottom: 6px;
    }
  }

  .button-save {
    color: #000;
    border: 2px solid #00bcd4;
    border-radius: 4px;
    padding: 15px 32px;
    font-family: Source Sans Pro;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 18px;
    text-transform: uppercase;
    width: 244px;
  }

  .button-save:hover {
    border: 2px solid #00bcd4;
    background: transparent;
  }
}