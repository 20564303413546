.no-offers {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: fit-content;

  &-top {
    width: calc(100% - 48px);
    border-radius: 8px;
    background-color: #ffffff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 24px 0;
    margin-top: 24px;

    >h4 {
      margin: 0;
      padding: 0;

      font-family: "Lora", sans-serif;
      font-style: normal;
      font-weight: bold;
      font-size: 32px;
      line-height: 32px;
      text-align: center;
      color: #000000;
      margin-bottom: 8px;
    }

    >p {
      margin: 0;
      padding: 0;

      font-family: "Roboto", sans-serif;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 19px;
      text-align: center;
      color: #000000;
    }

    >button {
      margin-top: 24px;
      padding: 15px 32px;

      font-family: "Source Sans Pro", sans-serif;
      font-style: normal;
      font-weight: bold;
      font-size: 14px;
      line-height: 18px;
      text-transform: uppercase;
      color: #ffffff;
      outline: none;
      border: none;
      background: #00bcd4;
      border-radius: 4px;
      cursor: pointer;
    }
  }

  &-bottom {
    width: calc(100% - 48px);
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-top: 24px;

    &-left {
      background-color: #ffffff;
      width: 20.3893%;
      padding: 32px 16px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      border-radius: 8px;
      margin-right: 24px;

      >img {
        margin: 32px 51.5px;
      }

      >h5 {
        margin: 8px 0;
        padding: 0;
        font-family: "Lora", sans-serif;
        font-style: normal;
        font-weight: bold;
        font-size: 24px;
        line-height: 26px;
        text-align: center;
        color: #000000;
      }

      >p {
        width: 85%;
        margin: 0;
        padding: 0;
        text-align: center;

        font-family: "Source Sans Pro", sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 18px;
        text-align: center;
        color: #000000;
      }

      >button {
        margin-top: 24px;
        padding: 15px 20px;

        font-family: "Source Sans Pro", sans-serif;
        font-style: normal;
        font-weight: bold;
        font-size: 14px;
        line-height: 18px;
        text-transform: uppercase;
        color: #ffffff;
        outline: none;
        border: none;
        background: #00bcd4;
        border-radius: 4px;
        cursor: pointer;
      }
    }

    &-right {
      flex: 1;
      background-color: #ffffff;
      border-radius: 8px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 24px 0;

      >h4 {
        margin: 0;
        padding: 0;

        font-family: "Lora", sans-serif;
        font-style: normal;
        font-weight: bold;
        font-size: 32px;
        line-height: 32px;
        text-align: center;
        color: #000000;
        margin-bottom: 8px;
      }

      >p {
        width: 85%;
        margin: 0;
        padding: 0;
        text-align: center;

        font-family: "Source Sans Pro", sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 18px;
        text-align: center;
        color: #000000;
        margin-bottom: 24px;
      }

      &-features {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: calc(100% - 48px);
        margin-bottom: 20px;

        &-item {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          height: 186px;
          width: 30%;
          background: #ededed;
          border-radius: 5px;

          >p {
            width: 75%;
            margin: 0;
            padding: 0;
            text-align: center;
            margin-top: 23.33px;

            font-family: "Source Sans Pro", sans-serif;
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            line-height: 18px;
            text-align: center;
            color: #000000;
          }
        }
      }

      >button {
        margin-top: 24px;
        padding: 15px 32px;

        font-family: "Source Sans Pro", sans-serif;
        font-style: normal;
        font-weight: bold;
        font-size: 14px;
        line-height: 18px;
        text-transform: uppercase;
        color: #ffffff;
        outline: none;
        border: none;
        background: #00bcd4;
        border-radius: 4px;
        cursor: pointer;
      }
    }
  }
}


@media screen and (max-width:1011px){
  .no-offers .no-offers-bottom{
    display: flex;
    flex-direction: column;
    gap:30px
  }

  .no-offers .no-offers-bottom .no-offers-bottom-left{
    width:100%
  }
  .no-offers .no-offers-bottom .no-offers-bottom-right{
    width:100%
  }
}

@media  screen and (max-width:651px){
  .no-offers .no-offers-bottom .no-offers-bottom-right-features{
    display: flex;
    flex-direction: column;
    gap: 30px;
  }
  .no-offers .no-offers-bottom .no-offers-bottom-right-features-item{
    width: 100%;
  }


}

@media screen and (max-width:400px){
  .no-offers-bottom-right > h4 {
    margin: 0;
    padding: 0;
    font-family: "Lora", sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 32px;
    text-align: center;
    color: #000000;
    margin-bottom: 8px;
  }

  .no-offers-bottom-left > h5 {
    margin: 8px 0;
    padding: 0;
    font-family: "Lora", sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 26px;
    text-align: center;
    color: #000000;
  }

  .no-offers-top > h4 {
    margin: 0;
    padding: 0;
    font-family: "Lora", sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 32px;
    text-align: center;
    color: #000000;
    margin-bottom: 8px;
  }

  .no-offers-bottom-left > img {
    margin: 0px 51.5px;
  }

 

  .no-offers-bottom-right .sub-section-button {
    padding: 19px 20px !important;
    background: #00BCD4 !important;
    border-radius: 4px !important;
    font-family: 'Source Sans Pro' !important;
    font-style: normal !important;
    font-weight: 700 !important;
    font-size: 14px !important;
    line-height: 18px !important;
    text-transform: uppercase !important;
    color: #FFFFFF !important;
    border: none !important;
  }
}