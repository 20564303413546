.header-buyer {
    width: 100%;
    position: sticky;
    top: 0;
    z-index: 990;
    background-color: #fff;
}

.header-buyer .navbar {
    padding: 14px 32px !important;
    position: relative;
    z-index: 990;
}

.header-buyer .nav-menu {
    float: left;
    display: flex;
    margin-top: 10px;
    padding-left: 0;
    list-style: none;
}

.header-buyer .nav-links {
    padding: 10px 15px;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    color: #000000;
    cursor: pointer;
    border-radius: 30px;
}

.header-buyer .nav-links.active {
    background-color: rgba(0, 188, 212, 1);
    color: #ffffff;
}

.header-buyer .nav-links.dropdown {
    text-transform: lowercase;
    font-size: 12px;
    font-weight: 700;
}

.header-buyer .nav-links.dropdown span::first-letter {
    text-transform: uppercase;
}

.header-buyer .nav-links.dropdown:hover {
    background-color: transparent;
}

.header-buyer .search .search-icon {
    float: left;
    margin-top: 4px;
    width: 26px;
    height: 26px;
    filter: invert(49%) sepia(0%) saturate(1%) hue-rotate(100deg) brightness(97%) contrast(89%);
}

.header-buyer .search.focus-input-filed {
    outline: 2px solid #00BCD4;
}

.header-buyer .search-field {
    float: left;
    padding: 16px 10px !important;
    height: 28px;
    border: none;
    background-color: transparent;
    width: 80% !important;
    margin-bottom: 10px;
}

.header-buyer .search-field::placeholder {
    font-weight: 400;
    font-size: 18px;
}

.header-buyer .down-caret {
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 5px 5px 0 5px;
    border-color: #000 transparent transparent transparent;
    display: inline-block;
    margin-left: 9px;
    top: -1px;
    position: relative;
    transform: rotate(0deg);
    transition: all .25s ease-in;
}

.header-buyer .open-caret {
    transform: rotate(180deg);
    transition: all .25s ease-out;
}

.header-buyer .nav-item.dropdown {
    padding: 3px 0;
}

.header-buyer #menu-list-grow {
    margin-top: -12px;
}

.header-buyer .menu-icon {
    display: none;
}

.header-buyer .navbar-logo {
    float: left;
    left: 32px;
    top: 12px;
    margin-top: 14px;
    margin-right: 32px;
}

.header-buyer .search {
    float: left;
    position: relative;
    margin-top: 4px;
    margin-right: 24px;
    background-color: rgba(244, 244, 244, 1);
    border-radius: 50px;
    width: 300px;
    height: 44px;
    margin-left: 24px;
    cursor: pointer;
    padding: 5px 16px;
}

.header-buyer .search .autocomplete-dropdown-container-home {
    margin-top: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    width: 100%;
    display: flex;
    flex-direction: column;
}

.header-buyer .sidebar-menu-icon {
    display: none;
}

.header-buyer .right-part {
    float: right;
    display: flex;
    gap: 15px;
}

.header-buyer .right-part .account-group {
    float: left;
    margin-top: 20px;
    margin-right: 27px;
}

.header-buyer .right-part .account-group .link {
    display: flex;
}

.header-buyer .right-part .account-group .link img {
    float: left;
    width: 24px;
    margin-top: -5px;
    margin-right: 8px;
}

.header-buyer .right-part .account-group .link .title {
    float: left;
    font-size: 14px;
    margin-top: -2px;
    margin-bottom: 0px;
    line-height: 14px;
    font-weight: 700;
    color: #0097A7;
    text-transform: uppercase;
}

.header-buyer .right-part .bell {
    position: relative;
    float: left;
    margin-top: 3px;
}

.header-buyer .right-part .menu-burger {
   cursor: pointer;
}

.header-buyer .right-part .bell .icon {
    filter: invert(33%) sepia(89%) saturate(2251%) hue-rotate(162deg) brightness(97%) contrast(101%);
    width: 18px;
    height: 18px;
}

.header-buyer .right-part .bell .notification-list-fade-in {
    color: #000;
    position: absolute;
    top: 50px;
    right: -70px;
    width: 410px;
    height: 250px;
    border-radius: 12px;
    background-color: #fff;
    display: block;
    opacity: 1;
    z-index: 1;
    overflow-x: hidden;
}

.header-buyer .right-part .bell .notification-list-fade-in::-webkit-scrollbar {
    width: 10px !important;
    background: #0097A7;
}

.header-buyer .right-part .bell .notification-list-fade-in::-webkit-scrollbar-track {
    background: #fff;
    border-radius: 12px;
}

.header-buyer .right-part .bell .notification-list-fade-in::-webkit-scrollbar-thumb {
    background-color: #0097A7;
    border-radius: 10px;
}

.header-buyer .right-part .bell .notification-row {
    padding: 10px;
}

.header-buyer .right-part .bell .notification-row .notification-title {
    font-size: 13px;
    margin: 0;
}

.header-buyer .right-part .bell .notification-row .notification-content {
    font-size: 12px;
    margin: 0;
}

.header-buyer .right-part .bell .notification-row .left-part {
    float: left;
}

.header-buyer .right-part .bell .notification-row .right-part {
    float: right;
    font-size: 12px;
}

.header-buyer .right-part .bell.notif .icon {
    filter: invert(33%) sepia(89%) saturate(2251%) hue-rotate(162deg) brightness(97%) contrast(101%);
}

.header-buyer .right-part .bell .notification-list-fade-in .row-notification .image {
    float: left;
    width: 10px;
    height: 18px;
    margin-right: 11px;
}

.header-buyer .buyer-lang-switcher {
    display: none;
 }

.header-buyer .right-part .bell .notification-list-fade-in .row-notification .author {
    float: left;
    font-size: 14px;
    font-weight: 700;
    margin-right: 5px;
}

.header-buyer .right-part .bell .notification-list-fade-in .row-notification .text {
    float: left;
    font-size: 14px;
    font-weight: 400;
    margin-right: 5px;
}

.header-buyer .right-part .bell .notification-list-fade-in .row-notification .offer-number {
    float: left;
    font-size: 14px;
    font-weight: 700;
}

.header-buyer .right-part .bell .notification-list-fade-in .hr {
    margin: 12px 0;
    color: rgba(101, 101, 101, 1);
}

.header-buyer .right-part .bell .notification-list-fade-out {
    width: 0;
    height: 0;
    display: none;
    opacity: 0;
}

/* logout button */
.header-buyer .logout-button {
    float: left;
    padding: 15px 32px;
    margin-right: 20px;
    font-size: 14px;
    line-height: 18px;
    font-weight: 700;
    color: #000;
    border-radius: 4px;
    border: 2px solid #00BCD4;
    cursor: pointer;
    text-transform: uppercase;
}

.header-buyer .badge { position: absolute; top: -10px !important; right: -13px !important; padding: 2px 4px !important; border-radius: 50% !important; background: #00BCD4; color: white; font-size: 10px !important; }


.header-buyer .nav-items-mobile {
    display: none;
}
.header-buyer .nav-items-mobile-menu{
    display: flex;
    align-items: center;
}

.header-buyer .right-part-mobile {
    display: flex;
    align-items: center;

}

.header-buyer .navbar .nav-menu .nav-item {
    margin-top: 5px;
}

.header-buyer .right-part .notification-icon{
    margin-left: 0px;
    margin-right: 10px;
    margin-top: 5px;
}

.header-buyer .navbar .nav-menu .nav-item-lang-switcher{
    margin-top: 5px;
}

.header-buyer .section-fixed {
    background: rgba(1, 35, 36, 1);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 12px 0;
}
  
.header-buyer .section-fixed img {
    margin-right: 10px;
    height: 30px;
    width: 30px;
}
  
.header-buyer .section-fixed .text {
    font-size: 15px;
    font-weight: 700;
    line-height: 30px;
    letter-spacing: 1.0800000429153442px;
    text-align: center;
    color: rgba(255, 255, 255, 1);
    margin-bottom: 0;
}

@media only screen and (min-width: 1321px) {
    .header-buyer .right-part .user-menu {
        display: none;
        
    }

    .header-buyer .navbar .nav-menu {
        display:flex;
    }
    
    .logout-button{
        display: block;
    }
 
    .header-buyer .right-part .account-group {
        display: block;
    }
}

@media only screen and (max-width: 1320px) {
    
    .header-buyer .right-part .bell {
        margin-right: 20px;
    }

    .header-buyer .search {
        width: 50%;
    }

    .header-buyer .right-part .user-menu {
        display: block;
        cursor: pointer;
        
    }

    .header-buyer .sidebar-menu-icon {
        display: block;
    }

    .header-buyer .navbar .nav-menu {
        display: none;
    }

    .header-buyer .nav-items {
        display: flex;
        justify-content: space-between;
    }

    .logout-button{
        display: none !important;
    }
 
    .header-buyer .right-part .account-group {
        display: none;
    }

    .header-buyer .buyer-lang-switcher {
        display: block;
        margin-right: 10px;
    }

    .header-buyer .buyer-lang-switcher .lang-switcher select {
        padding: 10px !important;
    }
}


@media only screen and (max-width: 1120px) {
    .header-buyer .search {
        width: 45%;
    }
}

@media only screen and (max-width: 980px) {

}

@media only screen and (max-width: 980px) {
    .header-buyer .search {
        width: 30%;
    }

    .header-buyer .navbar .nav-menu .nav-item-lang-switcher{
        margin-top: 2px;
    }
}

@media only screen and (max-width: 834px) {
     
    .header-buyer .search {
        width: 30%;
    }
}

@media screen and (max-width:767px) {
    .header-buyer .nav-items-mobile {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
    }

    .header-buyer .nav-items {
        display: none;
    }

    .header-buyer .search {
        display: none;
    }


    .header-buyer .right-part .bell {
        margin-right: 10px !important;
        margin-top: 0px !important;
    }

    .header-buyer .navbar-logo {
        margin-top: 5px;
    }

    .header-buyer .right-part .notification-icon {
        margin-top: 3px;
    }

    .header-buyer .section-fixed {
        padding: 14px 10px;
    }
      
    .header-buyer .section-fixed .text {
        font-size: 16px;
    }
}

@media screen and (max-width:718px){
    .header-buyer .search {
        width: 55%;
    }
}

@media screen and (max-width:675px){
    .header-buyer .search {
        width: 50%;
    }

    .header-buyer .right-part .notification-icon {
        margin-right: 5px;
    }
}

@media screen and (max-width:613px){
    .header-buyer .search {
        width: 45%;
      }
}

@media screen and (max-width:500px){
    .header-buyer .right-part .bell {
        margin-right: 20px;
    }

    .header-buyer .buyer-lang-switcher {
        margin-right: 0px;
    }

    
    .header-buyer .lang-switcher {
        width: 50px;
    }

}
    
@media screen and (max-width:489px){
    .makeStyles-faSearch-1{
        display: none;
    }

    .mortage-content .mortage-content-items .cols .col {
        padding: 0 !important;
    }
}

@media screen and (max-width:453px) {

    .header-buyer .right-part {
        gap: 15px;
    }

    .header-buyer .search-field {
        float: left;
        padding: 10px !important;
        height: 28px;
        border: none;
        background-color: transparent;
        width: 100% !important;
    }

    .home-buyer .headerTitle {
        font-size: 60px;
    }
    .header-buyer .navbar {
        padding: 19px 20px !important;
    }

    .header-buyer .right-part .bell .notification-list-fade-in { 
        width: 387px;
        right: -93px;
    }

    .header-buyer .right-part .bell {
        margin-left:0 !important;
    }

    .header-buyer .right-part-mobile .css-2kgxp5-MuiSvgIcon-root {
        margin-left: 0px !important;
    }

    .header-buyer .right-part .notification-icon {
        margin-top: 3px;
    }

    .header-buyer .section-fixed {
        padding: 3px 10px;
    }

    .header-buyer .section-fixed img {
        width: 40px;
    }

    .header-buyer .section-fixed .text {
        font-size: 11px;
        line-height: 21px;
    }
}

@media screen and (max-width:408px) {
    .header-buyer .right-part .bell .notification-list-fade-in { 
        width: 340px;
    }
}


@media screen and (max-width:408px) {
    .header-buyer .buyer-lang-switcher {
        margin-right: 0px;
    }

    
}


@media screen and (max-width: 390px) {
    .header-buyer .navbar-logo {
        width: 100px;
    }
}

@media screen and (max-width: 343px) {
    .header-buyer .navbar{
        padding: 14px 10px !important;
    }
}

@media screen and (max-width: 390px) {
    .header-buyer .navbar-logo {
        width: auto;
        margin-right: 0px !important;
    }
}