.home-on-login {
    text-align: center;
    margin: 0 auto;
    background-color: #fff;
}

.home-on-login .mortage-content {
    margin: 50px 110px 64px 110px;
    padding: 64px;
    border-radius: 30px;
    background-color: rgba(244, 244, 244, 1);
}

.home-on-login .mortage-content .content {
    text-align: left;
    margin-top: 40px;
}

.home-on-login .mortage-content img {
    width: 78%;
}

.home-on-login .mortage-content .content .title-mortage {
    font-size: 40px;
    font-weight: 700;
    line-height: 50px;
    text-align: left;
}

.home-on-login .mortage-content .content .title-mortage .percent {
    color: #00bcd4;
}

.home-on-login .mortage-content .content .text-mortage {
    font-size: 20px;
    font-weight: 400;
    line-height: 40px;
    text-align: left;
}

.home-on-login .mortage-content .content .button-mortage {
    font-size: 18px;
    font-weight: 700;
    text-align: center;
    padding: 15px 40px;
    border-radius: 5px;
    color: #fff;
    background-color: #00bcd4;
    text-transform: none;
}

.home-on-login .mortage-content .content .button-mortage:hover {
    background-color: rgba(0, 188, 212, 0.8);
}

.home-on-login .why-abidors {
    background-color: rgba(244, 244, 244, 1);
    padding: 55px;
    margin-bottom: 60px;
    text-align: center;
}

.home-on-login .why-abidors .title {
    font-size: 40px;
    font-weight: 700;
    line-height: 50px;
}

.home-on-login .why-abidors .text {
    font-size: 20px;
    font-weight: 400;
    line-height: 40px;
    padding: 0 30px;
}

.home-on-login .why-abidors .content .content-item {
    box-shadow: 4px 10px 30px 0px rgba(0, 0, 0, 0.06);
    background: rgba(255, 255, 255, 1);
    padding: 30px 40px;
    margin-bottom: 25px;
    border-radius: 30px;
}

.home-on-login .why-abidors .content .content-item img {
    float: left;
    margin-right: 45px;
    width: 17%;
}

.home-on-login .why-abidors .content .content-item .content-text {
    float: left;
    width: 71%;
    padding-top: 25px;
}

.home-on-login .why-abidors .content .content-item .content-text .title {
    font-size: 25px;
    font-weight: 700;
    line-height: 40px;
    text-align: left;
}

.home-on-login .why-abidors .content .content-item .content-text .text {
    font-size: 23px;
    font-weight: 400;
    line-height: 45px;
    text-align: left;
    padding: 0;
}

.home-on-login .buyer-seller-section {
    margin: 0 auto;
    padding: 0;
}

.home-on-login .buyer-seller-section .buyer-seller-section-content {
    background: rgba(244, 244, 244, 1);
    padding: 20px;
    border-radius: 30px;
    width: 40%;
    margin-right: 30px;
    margin-left: 130px;
    margin-bottom: 100px;
    float: left;
    height: 640px;
    position: relative;
}

.home-on-login .buyer-seller-section .buyer-seller-section-content:nth-last-child(1) {
    margin-right: 0;
    margin-left: 0;
}

.home-on-login .buyer-seller-section .buyer-seller-section-content img {
    margin-bottom: 20px;
    width: 70%;
}

.home-on-login .buyer-seller-section .buyer-seller-section-content .title {
    font-size: 25px;
    font-weight: 700;
    line-height: 30px;
    text-align: center;
}

.home-on-login .buyer-seller-section .buyer-seller-section-content .text {
    font-size: 20px;
    font-weight: 400;
    line-height: 35px;
    text-align: center;
    margin-bottom: 30px;
}

.home-on-login .buyer-seller-section .buyer-seller-section-content .learn-more {
    font-size: 18px;
    font-weight: 700;
    text-align: center;
    padding: 15px 40px;
    border-radius: 5px;
    color: #fff;
    background-color: #00bcd4;
    text-transform: none;
    position: absolute;
    bottom: 0;
    transform: translate(-50%, -50%);
}

.home-on-login .buyer-seller-section .buyer-seller-section-content .learn-more:hover {
    background-color: rgba(0, 188, 212, 0.8);
}

.home-on-login .virtual-assistant-video {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.home-on-login .virtual-assistant-video .video .video-play-button-container {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.home-on-login .virtual-assistant-video .video video {
    height: 450px !important;
    width: 100% !important;
}

.home-on-login .lawyer-and-assistant {
    margin: 100px 149px 64px;
}

.home-on-login .lawyer-and-assistant .content {
    background-color: #f4f4f4;
    padding: 32px;
    border-radius: 12px;
    height: 490px;
}

.home-on-login .lawyer-and-assistant .content .image {
    width: 130px;
    margin: 0 auto 20px auto;
}

.home-on-login .lawyer-and-assistant .content .title {
    margin-bottom: 15px;
    font-size: 25px;
    font-weight: 700;
    line-height: 30px;
    text-align: center;
}

.home-on-login .lawyer-and-assistant .content .sub-title {
    margin-bottom: 20px;
    font-size: 20px;
    font-weight: 400;
    line-height: 30px;
    text-align: center;

}

.home-on-login .lawyer-and-assistant .content .button-more {
    font-size: 18px;
    font-weight: 700;
    text-align: center;
    padding: 15px 40px;
    border-radius: 5px;
    color: #fff;
    background-color: #00bcd4;
    text-transform: none;
}

.home-on-login .lawyer-and-assistant .content .button-more:hover {
    background-color: rgba(0, 188, 212, 0.8);
}

.home-on-login .lawyer-and-assistant .content .button-more.virtual {
    margin-top: 28px;
}

.home-on-login .slider {
    position: relative;
    color: #000;
    margin-top: 25px;
}

.home-on-login .mark-label {
    color: #000;
    font-weight: bold;
    margin-left: 15px;
}

.home-on-login .neighborhood {
    margin: 64px 0 0 0;
}

.home-on-login .neighborhood .main-title {
    font-size: 40px;
    font-weight: 700;
    line-height: 90px;
    margin-bottom: 0;
}

.home-on-login .neighborhood .swiper-slide {
    margin: auto;
    overflow: hidden;
    position: relative;
    height: 400px;
}

.home-on-login .neighborhood .swiper-slide .vertical {
    background-color: #efefef;
    text-align: left;
    border-radius: 12px;
    position: relative;
    background-position: center !important;
    background-size: cover !important;
}

.home-on-login .neighborhood .swiper-slide .vertical .title {
    margin-bottom: 8px;
    padding: 24px 0 0 24px;
    font-size: 24px;
    line-height: 26px;
    font-weight: 700;
    font-style: normal;
}

.home-on-login .neighborhood .swiper-slide .vertical .sub-title {
    margin-bottom: 64px;
    font-size: 18px;
    line-height: 22px;
    font-weight: 900;
    padding: 0 24px;
}

.home-on-login .neighborhood .swiper-slide .vertical .sub-title-italic {
    margin-bottom: 8px;
    font-size: 24px;
    line-height: 32px;
    text-align: left;
    font-weight: 400;
    font-style: italic;
}

.home-on-login .neighborhood .swiper-slide .vertical .image {
    width: 108px;
    height: 108px;
    margin: 0 73px 34px;
    background-color: #efefef;
    border: 1px solid gray;
    border-radius: 8px;
}

.home-on-login .neighborhood .swiper-slide .vertical .link-group {
    margin: 12px;
    position: absolute;
    bottom: 0;
    right: 0;
}

.home-on-login .neighborhood .swiper-slide .vertical .link-group .button-link {
    border-radius: 10px;
    background: #00bcd4;
    font-size: 15px;
    color: #fff;
    line-height: 18px;
    font-weight: normal;
    font-style: normal;
    text-transform: none;
}

.home-on-login .neighborhood .swiper-slide .vertical .link-group .button-link:hover {
    background-color: rgba(0, 188, 212, 0.8);
}

.home-on-login .last-added {
    margin-bottom: 32px;
}

.home-on-login .last-added .last-added-title {
    font-size: 40px;
    font-weight: 600;
    line-height: 95px;
    text-align: center;    
}

.property-slider {
    padding: 0px 400px;
}

.css-8dfyd7-MuiSlider-root {
    top: 8px;
}

.home-on-login .virtual-assistant {
    margin-bottom: 30px;
    display: flex;
    width: 100%;
    flex-direction: column;
}
.home-on-login .virtual-assistant .main-container{
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: center;
}
.home-on-login .virtual-assistant .main-container .title-mobile{
    display: none;
}
.home-on-login .virtual-assistant .main-container .image-container{
    display: flex;
    width: 48%;
    justify-content: center;
    align-items: center;
}

.home-on-login .virtual-assistant .main-container .image-container .abidorsRocketImage{
    width: 100%;
    object-fit: cover;
}

.home-on-login .virtual-assistant .stepsContainer {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: center;
    width: 48%;
}

.home-on-login .virtual-assistant .stepsContainer .title-desktop {
    font-size: 30px;
    font-weight: 700;
    line-height: 35px;
    margin-bottom: 0;
    width: 97%;
}

.home-on-login .virtual-assistant .stepsContainer .stepContent {
    flex-basis: 40%; 
    padding: 20px 10px;
    background-color: #fff;
    border-radius: 15px;
    box-shadow: 0px 0px 20px 0px #00000026;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
}
.home-on-login .virtual-assistant .stepsContainer .stepContent.last {
    flex-basis: 82.5%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
.home-on-login .virtual-assistant .stepsContainer .stepContent.last .part-right {
    width: 70%;
}


.home-on-login .virtual-assistant .stepsContainer .stepContent .title-svg {
    width: 30px;
    height: 30px;
    margin-right: 20px;
    float: left;
}

.home-on-login .virtual-assistant .stepsContainer .stepContent .part-right {
    float: left;
    width: 70%;
}
.home-on-login .virtual-assistant .stepsContainer .stepContent.last .part-right {
    width: fit-content;
}

.home-on-login .virtual-assistant .stepsContainer .stepContent .part-right .title {
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
    text-align: left;
}

.home-on-login .virtual-assistant .virtual-assistant-button {
    display: block;
    margin: 20px auto;
    font-size: 18px;
    font-weight: 700;
    text-align: center;
    padding: 15px 40px;
    border-radius: 5px;
    color: #fff;
    background-color: #00bcd4;
    text-transform: none;
}

.home-on-login .virtual-assistant .virtual-assistant-button:hover {
    background-color: rgba(0, 188, 212, 0.8);
}

@media screen and (max-width: 1340px) {
    .home-on-login .mortage-content .content .title-mortage {
        font-size: 40px;
        line-height: 60px;
    }

    .home-on-login .why-abidors .content .content-item {
        padding: 20px;
    }

    .home-on-login .why-abidors .content .content-item img {
        width: 24%;
    }

    .home-on-login .why-abidors .content .content-item .content-text {
        padding-top: 30px;
    }
}

@media screen and (max-width: 1130px) {
    .home-on-login .mortage-content .col{
        width: 100%;
    }

    .home-on-login .buyer-seller-section .buyer-seller-section-content {
        margin-right: 20px;
    }

    .home-on-login .lawyer-and-assistant .col {
        margin-bottom: 10px;
    }
}

@media screen and (max-width: 1050px) {
    .home-on-login .why-abidors .content .content-item img {
        width: 20%;   
    }

    .home-on-login .why-abidors .content .content-item .content-text {
        padding-top: 0;
    }

    .home-on-login .why-abidors .content .content-item .content-text .title {
        font-size: 30px;
    }

    .home-on-login .why-abidors .content .content-item .content-text .text {
        font-size: 25px;
    }

    .home-on-login .buyer-seller-section .buyer-seller-section-content {
        width: 90%;
        margin: 5px 30px;
        padding: 20px;
    }

    .home-on-login .buyer-seller-section .buyer-seller-section-content img {
        width: 50%;
    }

    .home-on-login .buyer-seller-section .buyer-seller-section-content {
        text-align: center;
        margin: 0 auto;
        display: inline-block;
        float: none;
    }

    .home-on-login .virtual-assistant .main-container{
        margin-top: 30px;
        display: flex;
        width: 100%;
        flex-direction: column;
        justify-content: center;
    }

    .home-on-login .virtual-assistant .main-container .image-container{
        display: flex;
        width: 100%;
    }

    .home-on-login .virtual-assistant .stepsContainer {
        display: flex;
        flex-wrap: wrap;
        gap: 20px;
        justify-content: center;
        width: 100%;
    }
    .home-on-login .virtual-assistant .main-container .image-container .abidorsRocketImage{
        width: 82.5%;
    }
    .home-on-login .virtual-assistant .main-container .title-mobile{
        display: block;
        font-size: 30px;
        font-weight: 700;
        line-height: 35px;
        margin-bottom: 30px;
        width: 99%;
    }
    .home-on-login .virtual-assistant .stepsContainer .title-desktop {
        display: none;
    }
}

@media screen and (max-width: 984px) {
    .cols::before,
    .cols::after {
        display: none;
    }

    .home-on-login .why-abidors .content .content-item img {
        width: 20%;   
    }

    .home-on-login .buyer-seller-section .buyer-seller-section-content:nth-last-child(1) {
        margin: 5px auto 20px auto;
    }

    .home-on-login .last-added .last-added-title {
        font-size: 30px;
        font-weight: 600;
        line-height: 45px;
    }
}

@media screen and (max-width: 767px) {
    .home-on-login .mortage-content {
        margin: 10px 19px;
        padding: 20px;
    }

    .home-on-login .mortage-content .image {
        margin-bottom: 35px;
    }

    .home-on-login .mortage-content .content {
        text-align: center;
    }

    .home-on-login .mortage-content .content .title-mortage {
        font-size: 39.8px;
        font-weight: 600;
        line-height: 47px;
        text-align: left;
    }

    .home-on-login .mortage-content .content .button-mortage {
        padding: 15px 20px;
        font-size: 18px;
        font-weight: 700;
        line-height: 20px;
        text-align: center;
    }

    .home-on-login .mortage-content img {
        width: 100%;
    }

    .home-on-login .why-abidors {
        padding: 20px;
        margin-bottom: 30px;
    }

    .home-on-login .why-abidors .title {
        font-size: 39.8px;
        font-weight: 600;
        line-height: 47px;
        text-align: center;
    }

    .home-on-login .why-abidors .text {
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        text-align: center;
        padding: 0;
    }

    .home-on-login .why-abidors .content .content-item {
        margin: 0 auto 15px auto;

    }

    .home-on-login .why-abidors .content .content-item img {
        float: none;
        width: 70%;
        margin-bottom: 50px;
        margin-right: 0;
    }

    .home-on-login .why-abidors .content .content-item .content-text {
        float: none;
        width: 100%;
        text-align: center;
    }

    .home-on-login .why-abidors .content .content-item .content-text .title {
        text-align: center;
        font-size: 25px;
        font-weight: 700;
        line-height: 45px;
    }

    .home-on-login .why-abidors .content .content-item .content-text .text {
        text-align: center;
        font-size: 12px;
        font-weight: 400;
        line-height: 20px;
    }

    .home-on-login .neighborhood {
        margin: 20px 0 0 0;
    }

    .home-on-login .buyer-seller-section .buyer-seller-section-content img {
        margin-bottom: 30px;
        width: 60%;
    }

    .home-on-login .buyer-seller-section .buyer-seller-section-content {
        height: 560px;
    }

    .home-on-login .buyer-seller-section .buyer-seller-section-content .title {
        font-size: 25px;
        font-weight: 700;
        line-height: 40px;
        text-align: center;
        margin-bottom: 0;
    }

    .home-on-login .buyer-seller-section .buyer-seller-section-content .text {
        font-size: 12px;
        font-weight: 400;
        line-height: 20px;
        text-align: center;
        margin-bottom: 25px;
    }

    .home-on-login .buyer-seller-section .buyer-seller-section-content .learn-more {
        padding: 12px;
        font-size: 18px;
        font-weight: 700;
        line-height: 25px;
        width: 65%;
    }

    .home-on-login .virtual-assistant-video {
        margin: 0 10px;
        /* border-radius: 20px; */
    }

    .home-on-login .lawyer-and-assistant {
        margin: 20px 5px;
    }

    .home-on-login .lawyer-and-assistant .container {
        max-width: 100%;
    }

    .home-on-login .lawyer-and-assistant .content {
        height: 100%;
    }

    .home-on-login .lawyer-and-assistant .content .title {
        font-size: 25px;
        font-weight: 700;
        line-height: 30px;
    }

    .home-on-login .lawyer-and-assistant .content .sub-title {
        font-size: 12px;
        font-weight: 400;
        line-height: 20px;
    }

    .home-on-login .lawyer-and-assistant .content .button-more {
        padding: 12px 20px;
        font-size: 18px;
        font-weight: 700;
        line-height: 30px;
    }

    .home-on-login .virtual-assistant .stepsContainer .stepContent {
        margin: 0 10px;
        flex-basis: 100%;
    }

    .home-on-login .virtual-assistant .stepsContainer .title {
        font-size: 30px;
        font-weight: 700;
        line-height: 50px;
        margin-bottom: 0;
        padding: 0 7px;
    }

    .home-on-login .virtual-assistant .virtual-assistant-button {
        padding: 12px 20px;
        font-size: 18px;
        font-weight: 700;
        line-height: 20px;
        text-align: center;
    }

    .home-on-login .virtual-assistant-video .video video {
        height: 240px !important;
        width: 95% !important;
    }
    .home-on-login .virtual-assistant .stepsContainer .stepContent.last {
        flex-basis: 100%;
        display: flex;
        flex-direction: row;
        justify-content: center;
    }
    .home-on-login .virtual-assistant .stepsContainer .stepContent.last .part-right {
        width: 70%;
    }
}

@media screen and (max-width: 522px) {
    .home-on-login .neighborhood .main-title {
        font-size: 40px;
    }

    .home-on-login .buyer-seller-section .buyer-seller-section-content img {
        width: 100%;
    }
}