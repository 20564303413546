.payment-method .with-payment { padding: 24px; }
.payment-method .with-payment .history { background: #E2E2E2; border-radius: 12px; padding: 16px; text-align: left; height: 770px; overflow: hidden; overflow-y: auto !important; }
.payment-method .with-payment .history .title { font-size: 24px; line-height: 26px; margin-bottom: 24px; font-family: Lora; font-weight: 700; }
.payment-method .with-payment .history .history-row { margin-bottom: 8px; }
.payment-method .with-payment .history .history-row .text { font-size: 14px; line-height: 20px; font-weight: 600; font-family: Source Sans Pro; float: left; margin-right: 24px; }
.payment-method .with-payment .header-box { display: flex; }
.payment-method .with-payment .header-box .box { width: 320px; height: 190px; background: #CFCFCF; border-radius: 12px; display: flex; justify-content: center; align-items: center; }
.payment-method .with-payment .header-box .price-action { margin-left: 24px; }
.payment-method .with-payment .header-box .price-action .price-text { font-size: 72px; line-height: 64px; font-family: Source Sans Pro; font-weight: 700; }
.payment-method .with-payment .header-box .price-action .button { padding: 15px 32px; margin-top: 16px; background: #00BCD4; border-radius: 4px; color: #FFFFFF; text-transform: uppercase; float: left; font-size: 14px; line-height: 18px; font-family: Source Sans Pro; font-weight: 700; }

.payment-method .with-payment .card-info { margin-top: 8px; text-align: left; }
.payment-method .with-payment .card-info .title { font-size: 18px; line-height: 22px; font-family: Source Sans Pro; font-weight: 900; text-transform: uppercase; }
.payment-method .with-payment .card-info .text { font-size: 12px; line-height: 12px; font-weight: 400; font-family: Source Sans Pro; margin: 3px 0 8px 0; }
.payment-method .with-payment .card-info .button { padding: 11px 16px; border-radius: 4px; text-transform: uppercase; border: 2px solid #00BCD4; font-size: 14px; line-height: 18px; font-family: Source Sans Pro; font-weight: 700; }
.payment-method .with-payment .card-info .button:hover { background: #00BCD4; color: #fff; }

.payment-method .with-payment .another-card-content { margin-top: 47px; text-align: left; }
.payment-method .with-payment .another-card-content .title { font-size: 24px; line-height: 26px; font-family: Lora; font-weight: 700; }
.payment-method .with-payment .another-card-content .box-content { display: flex; margin-top: 16px; }
.payment-method .with-payment .another-card-content .box-content .box { width: 136px; height: 136px; margin-right: 16px; background: #FFFFFF; border-radius: 4px; border: 3px solid #FFFFFF; display: flex; align-items: center; justify-content: center; cursor: pointer; }
.payment-method .with-payment .another-card-content .box-content .box.active-card { border: 3px solid #00838F; cursor: default; filter: drop-shadow(0px 6px 10px rgba(0, 0, 0, 0.14)) drop-shadow(0px 1px 18px rgba(0, 0, 0, 0.12)) drop-shadow(0px 3px 5px rgba(0, 0, 0, 0.2)); }
.payment-method .with-payment .another-card-content .first.fields { margin-top: 24px; }
.payment-method .with-payment .another-card-content .fields .field-box .label { float: left; font-size: 12px; line-height: 12px; font-family: Source Sans Pro; font-weight: 400; margin-bottom: 4px; }
.payment-method .with-payment .another-card-content .fields .field-box .single-field { width: 100%; }
.payment-method .with-payment .another-card-content .fields .field-box .common-fields { display: inline-flex; }
.payment-method .with-payment .another-card-content .fields .last-group { margin-top: 16px; }
.payment-method .with-payment .another-card-content .button { padding: 15px 32px; margin: 24px 0 18px 0; background: #00BCD4; border-radius: 4px; color: #FFFFFF; text-transform: uppercase; font-size: 14px; line-height: 18px; font-family: Source Sans Pro; font-weight: 700; }
