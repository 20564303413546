.common-chat-area { margin-top: 24px; }
.common-chat-area .chat-area { padding: 16px; background: #E8E8E8; border-radius: 12px; position: relative; overflow-y: auto; overflow-x: hidden; }

.common-chat-area .chat-area .chat-box { background: #FFFFFF; padding: 16px; border-radius: 8px; }
.common-chat-area .chat-area .chat-box .field { width: 100%; }
.common-chat-area .chat-area .chat-box .field .button { padding: 15px 32px; height: 55px; width: 140px; background-color: #00BCD4; border-radius: 0px 4px 4px 0px; color: #FFFFFF; text-transform: uppercase; font-weight: 700; font-size: 14px; line-height: 18px; font-family: Source Sans Pro; }

.common-chat-area .chat-area .chat-box .chat-blob { padding: 13px 15px; text-align: left; background: #F2FEFF; border-radius: 12px 12px 12px 0px; width: 350px; margin-bottom: 16px; }
.common-chat-area .chat-area .chat-box .chat-blob.waiting { background: #FAF4F1; }
.common-chat-area .chat-area .chat-box .chat-blob.send { background: #9D9D9D; }
.common-chat-area .chat-area .chat-box .chat-blob .blob-header { display: flex; align-items: center; margin-bottom: 8px; }
.common-chat-area .chat-area .chat-box .chat-blob .blob-header .avatar { width: 24px; height: 24px; border-radius: 50%; }
.common-chat-area .chat-area .chat-box .chat-blob .blob-header .title { font-size: 14px; line-height: 18px; font-weight: 700; font-family: Source Sans Pro; margin-left: 10px; text-transform: uppercase; }
.common-chat-area .chat-area .chat-box .chat-blob .blob-header .hour { font-size: 12px; line-height: 12px; font-family: Source Sans Pro; font-weight: 400; margin-left: 10px; }
.common-chat-area .chat-area .chat-box .chat-blob .text { font-size: 16px; line-height: 18px; font-family: Source Sans Pro; font-weight: 400; }

.common-chat-area .chat-area .chat-notifications { background: #fff; padding: 15px 10px; border-radius: 8px; overflow-y: auto; height: 457px; }
.common-chat-area .chat-area .chat-notifications .notification-blob { text-align: left; padding: 24px 16px 16px 16px; background: #F2FEFF; border-radius: 12px 12px 12px 0px; margin-bottom: 8px; position: relative; }
.common-chat-area .chat-area .chat-notifications .notification-blob .notification-blob-header { display: flex; align-items: center; margin-bottom: 8px; }
.common-chat-area .chat-area .chat-notifications .notification-blob .notification-blob-header .icon { position: absolute; top: 0; margin-top: -6px; margin-left: 6px; }
.common-chat-area .chat-area .chat-notifications .notification-blob .notification-blob-header .avatar { width: 24px; height: 24px; border-radius: 50%; }
.common-chat-area .chat-area .chat-notifications .notification-blob .notification-blob-header .title { font-size: 14px; line-height: 18px; margin-left: 10px; font-family: Source Sans Pro; font-weight: 700; text-transform: uppercase; }
.common-chat-area .chat-area .chat-notifications .notification-blob .notification-blob-header .hour { font-size: 12px; line-height: 12px; font-family: Source Sans Pro; font-weight: 400; margin-left: 5px; }
.common-chat-area .chat-area .chat-notifications .notification-blob .text { font-size: 12px; line-height: 16px; letter-spacing: 0.4px; font-family: Source Sans Pro; font-weight: 400; }
/* Scroll */
.common-chat-area .chat-area .chat-box::-webkit-scrollbar, .common-chat-area .chat-area .chat-notifications::-webkit-scrollbar { width: 6px !important; background: gray; }
.common-chat-area .chat-area .chat-box::-webkit-scrollbar-track, .common-chat-area .chat-area .chat-notifications::-webkit-scrollbar-track { background : #fff; border-radius: 12px; }
.common-chat-area .chat-area .chat-box::-webkit-scrollbar-thumb, .common-chat-area .chat-area .chat-notifications::-webkit-scrollbar-thumb { background-color :#000; border-radius: 10px; }
