.contract { margin: 43px 24px 32px 24px; }
.contract .title { margin-top: 24px; font-size: 36px; line-height: 42px; }
.contract .sub-title { margin: 8px 120px 24px 120px; font-size: 18px; line-height: 21px; font-weight: 400; }
.contract .contract-body { padding: 16px; background: #FFFFFF; border-radius: 12px; text-align: left; height: 410px; overflow: auto; }
.contract .contract-body .title { margin: 0 0 32px 0; font-size: 36px; line-height: 32px; }
.contract .contract-body .common-title { margin-bottom: 10px; font-size: 24px; line-height: 32px; }
.contract .contract-body .text { margin-bottom: 32px; font-size: 18px; line-height: 32px; }
.contract .buttons-group { margin-top: 16px; }
.contract .buttons-group .button-sign { padding: 15px 32px; margin-right: 45px; border-radius: 4px; text-transform: uppercase; background: #00BCD4; color: #fff; font-size: 14px; line-height: 18px; font-family: Source Sans Pro; font-weight: 700; }
.contract .buttons-group .button-upload { padding: 15px 32px; margin-right: 30px; border-radius: 4px; text-transform: uppercase; background: #00BCD4; color: #fff; font-size: 14px; line-height: 18px; font-family: Source Sans Pro; font-weight: 700; }
.contract .buttons-group .button-download { padding: 15px 32px; border-radius: 4px; text-transform: uppercase; border: 2px solid #00BCD4; color: #000; font-size: 14px; line-height: 18px; font-family: Source Sans Pro; font-weight: 700; }
.contract .buttons-group .icon { margin-top: 33px; }

/* Scroll */
.contract .contract-body::-webkit-scrollbar { width: 6px !important; background: gray; }
.contract .contract-body::-webkit-scrollbar-track { background : #fff; border-radius: 12px; }
.contract .contract-body::-webkit-scrollbar-thumb { background-color :#000; border-radius: 10px; }

/* Accordion */
.contract .accordion-container { margin-bottom: 140px; }
.contract .accordion-container .accordion-body { margin-bottom: 16px; display: flex; }
.contract .accordion-container .accordion-body .title { color: #000000; font-size: 18px; line-height: 22px; font-style: normal; font-weight: 900; font-family: Source Sans Pro; text-transform: uppercase; }
.contract .accordion-container .accordion-body .icon { margin-left: 21px; margin-top: 16px; cursor: pointer; align-self: flex-start; }

.contract .accordion-container .accordion-body .accordion { width: 100%; }
.contract .accordion-container .accordion-body .accordion .accordion-summary .summ-span { text-align: left; }
.contract .accordion-container .accordion-body .accordion .accordion-summary .summ-span .title-span { display: flex; }
.contract .accordion-container .accordion-body .accordion .accordion-summary .summ-span .title-span .summ-document { margin-right: 8px; }
.contract .accordion-container .accordion-body .accordion .accordion-summary .summ-span .title-span .summ-title { font-family: Lora; font-style: normal; font-weight: bold; font-size: 24px; line-height: 26px; }
.contract .accordion-container .accordion-body .accordion .accordion-summary .summ-span .summ-date { margin-top: 8px; font-family: Source Sans Pro; font-style: normal; font-weight: normal; font-size: 12px; line-height: 16px; letter-spacing: 0.4px; }

.contract .accordion-container .accordion-body .accordion .accordion-summary .button-sign { margin-left: auto; background: #B2EBF2; border-radius: 4px; padding: 8px 16px 8px 12px; font-family: Source Sans Pro; font-style: normal; font-weight: bold; font-size: 14px; line-height: 18px; text-transform: uppercase; color: #B1B1B1; }
.contract .accordion-container .accordion-body .accordion .accordion-summary .button-download { float: right; font-family: Source Sans Pro; font-style: normal; font-weight: bold; font-size: 14px; line-height: 18px; text-transform: uppercase; color: #0097A7; }