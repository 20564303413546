.history {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  margin-bottom: 64px;
  > h3 {
    font-family: 'Lora', sans-serif;
    font-style: normal;
    font-weight: 1;
    font-size: 48px;
    line-height: 52px;
    text-align: center;
    color: #000000;
    margin: 0;
    padding: 0;
    margin-bottom: 32px;
  }
  &-content {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    padding: 24px;
    width: calc(100% - 48px);
    background: #f8f8f8;
    border-radius: 12px;
    > button {
      padding: 8px;
      border-radius: 4px;
      background-color: transparent;
      border: none;
      cursor: pointer;
      font-family: 'Source Sans Pro', sans-serif;
      font-style: normal;
      font-weight: bold;
      font-size: 14px;
      line-height: 18px;
      text-transform: uppercase;
      color: #0097a7;
    }
    &-items {
      display: grid;
      grid-template-columns: auto auto;
      column-gap: 100px;
      row-gap: 16px;
      font-family: 'Source Sans Pro', sans-serif;
      font-style: normal;
      font-weight: normal;
      color: #000000;
      > p {
        display: flex;
        align-items: center;
      }
      &-date {
        font-size: 12px;
        line-height: 16px;
        letter-spacing: 0.4px;
      }
      &-item {
        font-size: 18px;
        line-height: 24px;
        > img {
          margin-right: 10px;
          width: 20px;
          height: 18px;
        }
        > span {
          font-weight: bold;
          margin-left: 8px;
        }
      }
    }
  }
}
