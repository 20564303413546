.seller-public-profile {
    margin-top: 32px;
    padding: 0 40px;
}

.seller-public-profile .header-content {
    position: relative;
    margin-bottom: 8px;
}

.seller-public-profile .header-content .title {
    font-family: 'Lora';
    font-style: normal;
    font-weight: 600;
    font-size: 48px;
    line-height: 52px;
    text-align: center;
    margin-bottom: 60px;
}

.seller-public-profile .header-content .btn-change {
    position: absolute;
    right: 52px;
    top: 0;
    padding: 12px 32px;
    background: #ffffff;
    border: 2px solid #00BCD4;
    border-radius: 4px;
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
    text-transform: uppercase;
    box-shadow: none;
}

.seller-public-profile .header-content .btn-change:hover {
    background: #ffffff;
    box-shadow: none;
}

.seller-public-profile .paragraph {
    margin-bottom: 32px;
    text-align: center;
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
}

.seller-public-profile .photo-group {
    text-align: center;
}

.seller-public-profile .photo-group .id {
    font-size: 12px;
    line-height: 14px;
    margin-top: 7px;
    letter-spacing: 0.4px;
}

.seller-public-profile .photo-group .avatar {
    width: 100%;
    height: 100%;
    border-radius: 50%;
}

.seller-public-profile .photo-group .avatar .profile-image {
    width: 178px;
    height: 178px;
    border-radius: 50%;
    object-fit: cover;
}

.seller-public-profile .name {
    font-family: 'Lora';
    font-style: normal;
    font-weight: 600;
    font-size: 48px;
    line-height: 52px;
    margin-bottom: 72px;
}

.seller-public-profile .label {
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 18px;
    color: #656565;
    margin-bottom: 4px;
}

.seller-public-profile .date {
    display: flex;
    padding: 16px;
    background: #E0F7FA;
    border-radius: 4px;
    width: fit-content;
}

.seller-public-profile .date .date-img {
    width: 18px;
    height: 20px;
    margin-right: 11px;
}

.seller-public-profile .date .date-text {
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    text-transform: capitalize;
    margin-top: -3px;
    margin-bottom: 0;
}

.seller-public-profile .sub-title {
    font-family: 'Lora';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 26px;
}

.seller-public-profile .card {
    padding: 16px;
    margin: 0;
    background: #F8F8F8;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px rgba(0, 0, 0, 0.12), 0px 1px 3px rgba(0, 0, 0, 0.2);
    border-radius: 8px;
    margin-bottom: 16px;
}

.seller-public-profile .card:last-child {
    margin-bottom: 64px;
}

.seller-public-profile .card .tl {
    text-align: left;
}

.seller-public-profile .card .card-item .price {
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 45px;
    text-transform: uppercase;
    margin-bottom: 18px;
}

.seller-public-profile .card .card-item .info-box,
.seller-public-profile .card .card-item .info-box .icon-group,
.seller-public-profile .card .card-item .info-box .icon-group>span,
.seller-public-profile .card .card-item .location-details,
.seller-public-profile .card .card-item .square-meters {
    display: flex;
    align-items: center;
}

.seller-public-profile .card .card-item .info-box {
    width: 100%;
    margin-bottom: 8px;
}

.seller-public-profile .card .card-item .info-box .icon-group:not(:last-child) {
    margin-right: 8px;
}

.seller-public-profile .card .card-item .info-box .icon-group .icon {
    width: 18px;
    height: 18px;
    margin-right: 4px;
}

.seller-public-profile .card .card-item .location-details .icon {
    width: 24px;
    height: 20px;
    margin-right: 4px;
    margin-left: -4px;
}

.seller-public-profile .card .card-item .location-details .text {
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    overflow: hidden;
    width: 100%;
    height: 25px;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.seller-public-profile .card .card-item .square-meters {
    margin-top: 10px;
    justify-content: flex-end;
    margin-bottom: 62px;
}

.seller-public-profile .card .card-item .square-meters .text {
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
    padding: 3px 8px;
    background: #EDEDED;
    border-radius: 4px;
    margin-right: 8px;
}

.seller-public-profile .card .card-item .offer-text {
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 18px;
    text-align: right;
}

.seller-public-profile .card .card-item .separator {
    margin: 8px 0;
    border: 0.5px solid #014245;
}

.seller-public-profile .card .card-item .property-type-text {
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    text-align: left;
    overflow: hidden;
    width: 100%;
    height: 22px;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.seller-public-profile .card .main-image {
    height: 160px;
    width: 100%;
    border-radius: 4px;
    object-fit: cover;
}

/* Style modal pin */
.modal-marker {
    width: 250px;
    padding: 5px;
    cursor: pointer;
}

.modal-marker img {
    width: 100%;
    height: 120px;
    margin-bottom: 18px;
}

.modal-marker .info {
    font-size: 18px;
    color: rgba(0, 0, 0, 0.6);
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: normal;
}

.modal-marker .info .price {
    font-size: 30px;
    margin-bottom: 18px;
}

.modal-marker .info .property-type {
    margin-bottom: 12px;
}

.card-item .sold {
    margin-bottom: 5px;
    background: #C8E6C9;
    padding: 14px 20px;
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 900;
    font-size: 16px;
    line-height: 22px;
    text-align: center;
    text-transform: uppercase;
    color: #1B5E20;
}

.gmnoprint.gm-style-mtc-bbw,
.gm-style-cc {
    display: none !important;
}


@media screen and (max-width:1000px) {
    .seller-public-profile .MuiGrid-container .MuiGrid-grid-xs-12 {
        display: grid !important;
        grid-template-columns: repeat(2, 1fr) !important;
        gap: 50px;
    }

    .seller-public-profile .MuiGrid-container .MuiGrid-grid-xs-12 .card .MuiGrid-spacing-xs-3 {
        display: flex !important;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .seller-public-profile .MuiGrid-container .MuiGrid-grid-xs-12 .card .MuiGrid-grid-xs-5 {
        max-width: 100% !important;
    }

    .seller-public-profile .MuiGrid-container .MuiGrid-grid-xs-12 .card .MuiGrid-container {
        display: flex !important;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        max-width: 100% !important;
        margin: 0 auto !important;
    }

    .seller-public-profile .MuiGrid-container .MuiGrid-grid-xs-12 .card .MuiGrid-container .MuiGrid-grid-xs-3 {
        max-width: 100% !important;
        flex-basis: 0 !important;
        width: 100% !important;
    }

    .seller-public-profile .MuiGrid-container .MuiGrid-grid-xs-12 .card .MuiGrid-container .MuiGrid-grid-xs-4 {
        flex-basis: 0 !important;
        width: 100% !important;
        max-width: 100% !important;
    }

    .seller-public-profile .MuiGrid-container .MuiGrid-grid-xs-12 .card .MuiGrid-container .MuiGrid-grid-xs-3 p {
        text-align: center !important;
        font-size: 30px !important;
    }

    .seller-public-profile .MuiGrid-container .MuiGrid-grid-xs-12 .card .MuiGrid-container .MuiGrid-grid-xs-10 {
        max-width: 100% !important;
        flex-basis: 0;
    }

    .seller-public-profile .MuiGrid-container .MuiGrid-grid-xs-7  {
        width: 100% !important;
        max-width: 100% !important;
    }

    .seller-public-profile .sub-title-container {
        display: none;
    }

    .seller-public-profile .card .card-item .info-box {
        width: 100%;
        margin-bottom: 8px;
        justify-content: center;
    }

    .seller-public-profile .card .card-item .price  {
        text-align: center;
        margin-top: 20px;
    }

}

@media screen and  (max-width:765px){
    .seller-public-profile .header-content .title {
        font-size: 34px;
        line-height: 28px;
    }

    .seller-public-profile .MuiGrid-container {
        display: flex !important;
        flex-direction: column !important;
        justify-content: center;
        align-items: center;
        margin-bottom: 0;
    }
    
    .seller-public-profile .name {
        margin-bottom: 0;
    }

    .seller-public-profile .MuiGrid-grid-xs-2 {
        max-width: 100% !important;
    }

    .seller-public-profile .sub-title-container {
        display: none !important;
    }
}

@media screen and  (max-width: 660px){
    .seller-public-profile .MuiGrid-container .MuiGrid-grid-xs-12 {
        grid-template-columns: repeat(1, 1fr) !important;
        gap: 25px;
    }
}

@media screen and  (max-width: 410px){
    .seller-public-profile {
        padding: 0 20px;
    }

    .seller-public-profile .sub-title-container {
        display: none !important;
    }
}

.seller-public-profile .card .maps {
    position: relative;
    height: 220px;
    margin-bottom: 20px;
  }
  
  .seller-public-profile .card .maps .map {
    margin-bottom: 53px;
    padding: 70px 95px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-image: url("https://d3kuhob3u5bpy2.cloudfront.net/2bd40c7c-0e69-4d88-9161-27dc0b3fba1a.svg");
    filter: blur(8px);
  }
  
  .seller-public-profile .card .maps .map.filter {
    filter: blur(0);
  }
  
  .seller-public-profile .card .maps .text {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 243px;
    transform: translate(-50%, -50%);
    text-align: center;
    padding: 8px 16px;
    background-color: #e0f7fa;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 18px;
    text-transform: uppercase;
    color: #000000;
    cursor: pointer;
  }