.pricing {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  height: fit-content;
  margin-top: 64px;

  .label-price {
    font-family: Source Sans Pro;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0.4000000059604645px;
  }

  &-price {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    font-family: 'Source Sans Pro', sans-serif;
    margin-bottom: 16px;

    .price-number {
      display: inline-block;
      padding: 4px 8px;
      background: #E0F7FA;
      border-radius: 4px;
      font-family: Source Sans Pro;
      font-style: normal;
      font-weight: bold;
      font-size: 20px;
      line-height: 32px;
      text-transform: uppercase;
      color: #000000;
      width: 170px;
      border: none;
    }

    .price-number.error {
      border: 1px solid #F32013;
    }

    .price-number.disabled {
      pointer-events: none;
      opacity: 0.7;
    }
  }

  &-description {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 24px;

    >p {
      margin: 0;
      padding: 0;
      font-family: 'Source Sans Pro', sans-serif;
      font-style: normal;
      font-weight: normal;
      font-size: 18px;
      line-height: 24px;
      text-align: center;
      color: #000000;
    }

    &-info {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 8px;

      &-item {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        margin: 0 9px;

        >div {
          display: flex;
          align-items: center;
          justify-content: center;
          background: #fff59d;
          border-radius: 4px;

          >h5 {
            margin: 3px 8px;
            font-family: 'Source Sans Pro', sans-serif;
            font-style: normal;
            font-weight: 900;
            font-size: 18px;
            line-height: 22px;
            color: #000000;

            >sup {
              font-size: 66%;
              line-height: 1;
            }
          }
        }

        >img {
          margin: 4px 2px 4px 6px;
        }
      }
    }
  }

  .pricing-button {
    .button-edit {
      background-color: #00bcd4;
      border: 2px solid #00bcd4;
      margin-right: 15px;
      font-family: Source Sans Pro;
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: 18px;
      color: white;
      padding: 15px 32px;
      border-radius: 4px;
      text-transform: uppercase;
    }

    .button-edit:hover {
      background-color: #00bcd4;
    }

    .button-save {
      color: #000;
      border: 2px solid #00bcd4;
      border-radius: 4px;
      padding: 15px 32px;
      font-family: Source Sans Pro;
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: 18px;
      text-transform: uppercase;
    }

    .button-save.Mui-disabled {
      border: 1px solid rgba(0, 0, 0, 0.12);
      color: rgba(0, 0, 0, 0.26);
    }

    .button-save:hover {
      border: 2px solid #00bcd4;
      background: transparent;
    }

  }
}


@media screen and (max-width:435px){
  .pricing .pricing-button{
    display: flex;
    flex-direction: column;
    gap:20px;
    width: 100%;
  }
  .pricing .pricing-button .button-edit{
    width: 100%;
  }
  .pricing .pricing-button .button-save{
    width: 100%;
  }
}