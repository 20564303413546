.dialog-common-multi-profile {
    text-align: center;
}

.dialog-common-email-subscriptions {
    padding: 20px 35px;
}

.dialog-common-email-subscriptions .title-header {
    padding: 0;
    margin-bottom: 8px;
}
.dialog-common-email-subscriptions-content{
    padding: 20px 35px;
}

.dialog-common-email-subscriptions-content .title-header .title {
    display: inline-block;
    font-size: 32px;
    line-height: 32px;
    font-family: Lora;
    font-weight: 700;
    text-align: center;
    width: 96%;
}

.dialog-common-email-subscriptions-content .title-header .close-icon {
    display: inline-block;
    cursor: pointer;
    float: right;
}

.dialog-common-email-subscriptions-content .content {
    padding: 0;
    overflow-x: hidden;
}

.dialog-common-email-subscriptions-content .content .sub-title {
    font-size: 16px;
    font-weight: 400;
    margin-bottom: 20px;
    font-family: 'Source Sans Pro';
    padding: 0 40px;
}




.dialog-common-email-subscriptions-content .content .actions {
    margin-top: 8px;
}

.dialog-common-email-subscriptions-content .content .actions .button-continue {
    height: 45px;
    padding: 15px 32px;
    background: #00BCD4;
    border-radius: 4px;
    color: #fff;
    text-transform: uppercase;
    margin-right: 25px;
    font-size: 14px;
    line-height: 18px;
    font-family: 'Source Sans Pro';
    font-weight: 700;
}

.dialog-common-email-subscriptions-content .content .actions .button-back {
    height: 45px;
    padding: 15px 32px;
    border: 2px solid #00BCD4;
    border-radius: 4px;
    color: #000;
    text-transform: none;
    font-size: 14px;
    line-height: 18px;
    font-family: 'Source Sans Pro';
    font-weight: 700;
}



.subscriptions-container-card-grid {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    width: 100% !important;
}

.subscriptions-container-card{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items:center;
}

.subscriptions-container-card-grid .top-row {
    display: flex;
    flex-direction: column;
    padding: 0;
    width: 85%;
}
.subscriptions-container-card-grid .top-row .category{
    font-size: 20px;
    line-height: 26px;
    font-weight: 700;
    margin-bottom: 10px;
    text-align: left;
}

.subscriptions-container-card-grid .bottom-row {
    display: flex;
    justify-content: center;
    padding:  0 7rem;
}

.subscriptions-container-card-grid .col2of12 {
    display: none !important;
}
.subscriptions-container-card .email-type{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    text-align:left;
    padding: 0;
    font-size: 15px;
    line-height: 18px;
    font-family: 'Source Sans Pro';
    font-weight: 400;
    width: 85%;
}

.subscriptions-container-card .email-type .title {
    font-size: 16px;
    line-height: 22px;
    font-weight: 700;
    font-family: Lora, sans-serif;
    margin-bottom: 10px;
    width: 90%;
}

.subscriptions-container-card .email-type .text {
    padding: 0;
    font-size: 15px;
    line-height: 18px;
    font-family: 'Source Sans Pro';
    font-weight: 400;
}

.subscriptions-container-card .action-button {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    justify-content: center;
    align-items: center;
    display: flex;
    cursor: pointer;    
}

@media screen and (max-width:1000px) {
    .subscriptions-container-card-grid .top-row {
        flex-direction: column;
    }

    .subscriptions-container-card-grid .bottom-row {
        flex-direction: column;
        padding: 0;
    }

    .subscriptions-container-card-grid .col.col4of12 {
        width: 26rem !important;
    }
    

}
@media screen and (max-width:950px) {
    .dialog-common-email-subscriptions-content .content .subscriptions-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 20px
    }

    .dialog-common-email-subscriptions-content .content .col4of12 {
        width: 80%;
    }
}

@media screen and (max-width:600px) {
    .subscriptions-container-card-grid .col.col4of12 {
        width: 100% !important;
    }
}
@media screen and (max-width:415px){
    .dialog-common-email-subscriptions-content .content .actions{
        display:flex;
        flex-direction:column;
        gap:10px;
    }
     .dialog-common-email-subscriptions-content .content .actions .button-continue{
        width:100%;
    }
     .dialog-common-email-subscriptions-content .content .actions .button-back{
        width:100%;
    }
}

@media screen and (max-width:432px){
    .dialog-common-email-subscriptions-content .content .sub-title{
        padding:0;
        text-align:left;
    }
.dialog-common-email-subscriptions-content .content .col4of12{
 width:100%;
}
}

@media screen and (max-width:320px){
    .dialog-common-email-subscriptions-content .title-header .title{
        font-size:16px;
    }
}
