.confirm-service-details { display: flex; flex-direction: row; }
.confirm-service-details .icon { margin-right: 18px; align-self: flex-start; }
.confirm-service-details .current-details { display: flex; flex-direction: column;width:100%}
.confirm-service-details .current-details .left-part .title { margin-bottom: 8px; font-family: Lora; font-style: normal; font-weight: bold; font-size: 24px; line-height: 26px; }
.confirm-service-details .current-details .left-part .text { margin-bottom: 16px; font-family: Source Sans Pro; font-style: normal; font-weight: normal; font-size: 18px; line-height: 24px; }
.confirm-service-details .current-details .right-part { margin-top: 5px;width:100%}

@media screen and (max-width:765px){
    .confirm-service-details {
        flex-direction: row;
        align-items: center;
    }
}

@media screen and (max-width:530px){
    .confirm-service-details .current-details {
        flex-direction: column;
        align-items: center;
    }
}