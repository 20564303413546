.seller-std-button {
  background-color: #00bcd4;
  height: 48px;
  font-family: Source Sans Pro;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 18px;
  text-align: left;
  color: white;
  padding: 15px 32px 15px 32px;
  border: none;
  outline: 0;
  border-radius: 4px;
  cursor: pointer;
  text-transform: uppercase;
}
