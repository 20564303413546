/* Edited code */
.profile-lawyer{
    display: flex;
    flex-direction: column;
    margin: 0;
}
.profile-lawyer .header-content {
    display: flex;
    margin-top: 32px;
}

.profile-lawyer .header-content .photo-group .avatar {
    position: relative;
    width: 178px;
    height: 178px;
    border-radius: 50%;
}

.profile-lawyer .header-content .photo-group .avatar .photo {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    object-fit: cover;
}

.profile-lawyer .header-content .photo-group .user-id {
    margin-top: 8px;
    text-align: center;
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
}

.profile-lawyer .header-content .user-details {
    margin-left: 24px;
}

.profile-lawyer .header-content .user-details .user-name {
    font-size: 48px;
    line-height: 56px;
    margin-bottom: 8px;
}

.profile-lawyer .header-content .user-details .rating {
    display: flex;
    align-items: flex-end;
    margin-top: 12px;
}

.profile-lawyer .header-content .user-details .rating .title {
    margin-right: 12px;
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: 900;
    font-size: 18px;
    line-height: 22px;
    text-transform: uppercase;
}

.profile-lawyer .header-content .user-details .rating .rating-results .result {
    font-size: 16px;
    font-weight: 400;
    line-height: 18px;
    margin-left: 8px;
    margin-top: 1px;
}

.profile-lawyer .header-content .account-details {
    margin-left: auto;
    margin-right: 32px;
}

.profile-lawyer .header-content .account-details .button {
    float: right;
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 18px;
    text-transform: uppercase;
    color: #0097A7;
}

.profile-lawyer .changes-button {
    display: flex;
    gap: 32px;
    margin-top: 46px;
    justify-content: center;
    width: 100%;
}

.profile-lawyer .changes-button .button-view-profile {
    padding: 15px 32px;
    background-color: #00BCD4;
    border-radius: 4px;
    color: #FFFFFF;
    text-transform: uppercase;
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 18px;
}

.profile-lawyer .changes-button .button-view-profile:hover {
    background-color: #00BCD4;
}

.profile-lawyer .changes-button .button {
    padding: 15px 32px;
    border: 2px solid #00BCD4;
    border-radius: 4px;
    color: #000000;
    text-transform: uppercase;
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 18px;
}

/* New code */
.profile-lawyer .my-profile-content {
    margin: 32px 0;
    width: 100% !important;
}

.profile-lawyer .my-profile-content .root {
    flex-grow: 1;
    background: #F8F8F8;
    display: flex;
    border-radius: 12px;
    position: relative;
}

.profile-lawyer .my-profile-content .root .tabs {
    width: 15%;
    background: #E8E8E8;
    border-radius: 12px 0px 0px 12px;
}

.profile-lawyer .my-profile-content .root .tabs .tab {
    border-radius: 12px 0px 0px 12px;
    text-transform: uppercase;
    font-size: 14px;
    line-height: 18px;
    font-family: Source Sans Pro;
    font-weight: 700;
}

.profile-lawyer .my-profile-content .root .tabs .tab.disabled {
    pointer-events: none;
    opacity: 0.2;
}

.profile-lawyer .my-profile-content .root .tabs .tab.active-tab {
    background: #F8F8F8;
}

.profile-lawyer .my-profile-content .root .tabs .tab.last-tab {
    position: absolute;
    bottom: 0;
    width: 100%;
}

.profile-lawyer .my-profile-content .tab-panel {
    width: 85%;
    text-align: center;
}

.profile-lawyer .my-profile-content .root .tab-panel .tab-panel-contract {
    min-height: 300px;
}

.profile-lawyer .my-profile-content .root .tab-panel .tab-panel-contract .contract-list-pane {
    display: flex;
    margin: 24px;
    background: #FFFFFF;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px rgba(0, 0, 0, 0.12), 0px 1px 3px rgba(0, 0, 0, 0.2);
    border-radius: 4px;
}

.profile-lawyer .my-profile-content .root .tab-panel .tab-panel-contract .contract-list-pane .contract-details {
    margin-left: 24px;
    margin-top: 27px;
}

.profile-lawyer .my-profile-content .root .tab-panel .tab-panel-contract .contract-list-pane .contract-details .contract-title {
    display: flex;
}

.profile-lawyer .my-profile-content .root .tab-panel .tab-panel-contract .contract-list-pane .contract-details .contract-title .icon {
    height: 21px;
    width: 18px;
    margin-right: 11px;
}

.profile-lawyer .my-profile-content .root .tab-panel .tab-panel-contract .contract-list-pane .contract-details .contract-title .title {
    font-family: Lora;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 26px;
}

.profile-lawyer .my-profile-content .root .tab-panel .tab-panel-contract .contract-list-pane .contract-details .contract-date {
    margin-top: 8px;
    text-align: left;
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.4px;
}

.profile-lawyer .my-profile-content .root .tab-panel .tab-panel-contract .contract-list-pane .contract-download {
    margin-left: auto;
    margin-top: 29px;
}

.profile-lawyer .my-profile-content .root .tab-panel .tab-panel-contract .contract-list-pane .contract-download .button {
    padding: 15px 32px;
    background: #00BCD4;
    border-radius: 4px;
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 18px;
    text-transform: uppercase;
    color: #FFFFFF;
}

.profile-lawyer .my-profile-content .root .tab-panel .tab-panel-contract .contract-list-pane .contract-download .button .icon {
    color: #FFFFFF;
    margin-right: 8px;
}

.profile-lawyer .my-profile-content .root .tab-panel .tab-panel-contract .contract-list-pane .contract-review {
    margin: 29px 33px 29px 23px;
}

.profile-lawyer .my-profile-content .root .tab-panel .tab-panel-contract .contract-list-pane .contract-review .button {
    padding: 15px 32px;
    border: 2px solid #00BCD4;
    box-sizing: border-box;
    border-radius: 4px;
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 18px;
    text-transform: uppercase;
    color: #000000;
}

/* ################################# */

.profile-lawyer .information-content {
    margin-bottom: 128px;
    margin-top: 64px;
    text-align: center;
}

.profile-lawyer .information-content .title {
    margin-bottom: 16px;
    font-family: Lora;
    font-size: 32px;
    line-height: 32px;
}

.profile-lawyer .information-content .text {
    margin: 0px 48px 24px 48px;
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 24px;
}

.profile-lawyer .information-content .button {
    padding: 15px 32px;
    border: 2px solid #00BCD4;
    box-sizing: border-box;
    border-radius: 4px;
    color: #000000;
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 18px;
    text-transform: uppercase;
}

/* Deactivate Account Dialog */
.dialog-deactivate-account-lawyer {
    padding: 22px;
    text-align: center;
}

.dialog-deactivate-account-lawyer .title-header {
    padding: 0;
    margin-bottom: 16px;
}

.dialog-deactivate-account-lawyer .title-header .title {
    font-family: Lora;
    font-style: normal;
    font-weight: bold;
    font-size: 32px;
    line-height: 32px;
    margin-top: 22px;
    padding: 0px 140px;
}

.dialog-deactivate-account-lawyer .title-header .close-icon {
    cursor: pointer;
    float: right;
    margin-top: -24px;
}

.dialog-deactivate-account-lawyer .content {
    padding: 0;
    overflow-x: hidden;
}

.dialog-deactivate-account-lawyer .content .sub-title {
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 24px;
    padding: 0px 100px;
}

.dialog-deactivate-account-lawyer .content .actions {
    margin-top: 24px;
}

.dialog-deactivate-account-lawyer .content .actions .button-continue {
    margin-right: 24px;
    padding: 15px 32px;
    background: #F44336;
    border-radius: 4px;
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 18px;
    text-transform: uppercase;
    color: #FFFFFF;
}

.dialog-deactivate-account-lawyer .content .actions .button-back {
    padding: 15px 32px;
    border: 2px solid #00BCD4;
    box-sizing: border-box;
    border-radius: 4px;
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 18px;
    text-transform: uppercase;
    color: #000000;
}

span[class^='PrivateTabIndicator-root-'] {
    background: #F8F8F8;
}

.profile-lawyer .display-tab-backdrop {
    position: absolute;
    top: 0;
    left: 0;
    width: 50%;
    z-index: 99;
    border-radius: 12px;
    display: none;
}

.profile-lawyer .display-tab-backdrop:hover {
    cursor: pointer;
}

.profile-lawyer .menu-burger {
    display: none;
}

.profile-lawyer .close-tab {
    display: none;
}

.tabs-mobile-closed .MuiTabs-scrollable {
    display: none !important;
}


/* Deactivate Account Dialog */
.dialog-deactivate-account-vip-virtual-assistant {
    padding: 22px;
    text-align: center;
}

.dialog-deactivate-account-vip-virtual-assistant .title-header {
    padding: 0;
    margin-bottom: 16px;
    padding-right: 24px;
}

.dialog-deactivate-account-vip-virtual-assistant .title-header .title {
    font-family: Lora;
    font-style: normal;
    font-weight: bold;
    font-size: 32px;
    line-height: 32px;
    margin-top: 22px;
    padding: 0px 140px;
}

.dialog-deactivate-account-vip-virtual-assistant .title-header .close-icon {
    cursor: pointer;
    float: right;
}

.dialog-deactivate-account-vip-virtual-assistant .content {
    padding: 0;
    overflow-x: hidden;
}

.dialog-deactivate-account-vip-virtual-assistant .content .actions {
    margin-top: 24px;
    margin-bottom: 24px;
}

.dialog-deactivate-account-vip-virtual-assistant .content .actions .button-continue {
    margin-right: 24px;
    padding: 15px 32px;
    background: #F44336;
    border-radius: 4px;
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 18px;
    text-transform: uppercase;
    color: #FFFFFF;
}

.dialog-deactivate-account-vip-virtual-assistant .content .actions .button-back {
    padding: 15px 32px;
    border: 2px solid #00BCD4;
    box-sizing: border-box;
    border-radius: 4px;
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 18px;
    text-transform: uppercase;
    color: #000000;
}



.dialog-deactivate-account-virtual-assistant-seller {
    margin: 0 10px;
}

.dialog-deactivate-account-virtual-assistant-seller-content {
    padding: 22px;
    text-align: center;
}

.dialog-deactivate-account-virtual-assistant-seller-content .title-header {
    padding: 0;
    margin-bottom: 16px;
}

.dialog-deactivate-account-virtual-assistant-seller-content .title-header .title {
    font-family: Lora;
    font-style: normal;
    font-weight: bold;
    font-size: 32px;
    line-height: 32px;
    margin-top: 22px;
    padding: 0px 140px;
}

.dialog-deactivate-account-virtual-assistant-seller-content .title-header .close-icon {
    cursor: pointer;
    float: right;
    margin-top: -24px;
}

.dialog-deactivate-account-virtual-assistant-seller-content .content {
    padding: 0;
    overflow-x: hidden;
}

.dialog-deactivate-account-virtual-assistant-seller-content .content .actions {
    margin-top: 24px;
}

.dialog-deactivate-account-virtual-assistant-seller-content .content .actions .button-continue {
    margin-right: 24px;
    padding: 15px 32px;
    background: #F44336;
    border-radius: 4px;
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 18px;
    text-transform: uppercase;
    color: #FFFFFF;
}

.dialog-deactivate-account-virtual-assistant-seller-content .content .actions .button-back {
    padding: 15px 32px;
    border: 2px solid #00BCD4;
    box-sizing: border-box;
    border-radius: 4px;
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 18px;
    text-transform: uppercase;
    color: #000000;
}

.dialog-deactivate-account-virtual-assistant-seller-content .button-send {
    height: 45px;
    padding: 15px 32px;
    background: #00BCD4;
    border-radius: 4px;
    color: #fff;
    text-transform: uppercase;
    margin-right: 25px;
    font-size: 14px;
    line-height: 18px;
    font-family: Source Sans Pro;
    font-weight: 700;
}

.dialog-deactivate-account-virtual-assistant-seller-content .button-send:hover {
    height: 45px;
    padding: 15px 32px;
    background: #00BCD4;
    border-radius: 4px;
    color: #fff;
    text-transform: uppercase;
    margin-right: 25px;
    font-size: 14px;
    line-height: 18px;
    font-family: Source Sans Pro;
}



@media screen and (max-width:400px){
    .dialog-deactivate-account-virtual-assistant-seller-content .title-header .title {
        padding: 0;
    }
}
.dialog-deactivate-account-virtual-assistant-seller {
    margin: 0 10px;
}

.dialog-deactivate-account-virtual-assistant-seller-content {
    padding: 22px;
    text-align: center;
}

.dialog-deactivate-account-virtual-assistant-seller-content .title-header {
    padding: 0;
    margin-bottom: 16px;
}

.dialog-deactivate-account-virtual-assistant-seller-content .title-header .title {
    font-family: Lora;
    font-style: normal;
    font-weight: bold;
    font-size: 32px;
    line-height: 32px;
    margin-top: 22px;
    padding: 0px 140px;
}

.dialog-deactivate-account-virtual-assistant-seller-content .title-header .close-icon {
    cursor: pointer;
    float: right;
    margin-top: -24px;
}

.dialog-deactivate-account-virtual-assistant-seller-content .content {
    padding: 0;
    overflow-x: hidden;
}

.dialog-deactivate-account-virtual-assistant-seller-content .content .actions {
    margin-top: 24px;
}

.dialog-deactivate-account-virtual-assistant-seller-content .content .actions .button-continue {
    margin-right: 24px;
    padding: 15px 32px;
    background: #F44336;
    border-radius: 4px;
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 18px;
    text-transform: uppercase;
    color: #FFFFFF;
}

.dialog-deactivate-account-virtual-assistant-seller-content .content .actions .button-back {
    padding: 15px 32px;
    border: 2px solid #00BCD4;
    box-sizing: border-box;
    border-radius: 4px;
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 18px;
    text-transform: uppercase;
    color: #000000;
}

.dialog-deactivate-account-virtual-assistant-seller-content .button-send {
    height: 45px;
    padding: 15px 32px;
    background: #00BCD4;
    border-radius: 4px;
    color: #fff;
    text-transform: uppercase;
    margin-right: 25px;
    font-size: 14px;
    line-height: 18px;
    font-family: Source Sans Pro;
    font-weight: 700;
}

.dialog-deactivate-account-virtual-assistant-seller-content .button-send:hover {
    height: 45px;
    padding: 15px 32px;
    background: #00BCD4;
    border-radius: 4px;
    color: #fff;
    text-transform: uppercase;
    margin-right: 25px;
    font-size: 14px;
    line-height: 18px;
    font-family: Source Sans Pro;
}

@media screen and (max-width:400px){

    .dialog-deactivate-account-virtual-assistant-seller-content .title-header .title {
        padding: 0;
    }

}
@media screen and (max-width: 1000px) {


    .profile-lawyer .profile-content .root{
        margin: 0 16px;
    }

    .profile-lawyer .menu-burger{
        display: block !important;
        position: absolute;
        left: 10px;
        top: 20px;
        z-index: 100;
        
    }

    .profile-lawyer .close-tab{
        display: block !important;
        position: absolute;
        right: 10px;
        top: 0px;
        z-index: 100;
    }

    .profile-lawyer .close-tab:hover{
        cursor: pointer;
    }

    .profile-lawyer .menu-burger:hover{
        cursor: pointer;
    }

    .profile-lawyer  .tabs-mobile-closed {
        width: 60px !important;
    }

    .profile-lawyer .tabs-mobile-open {
        width: 50% !important;
        position: absolute;
        height: 100%;
        z-index: 99;
        padding-top: 40px !important;
    }

    .profile-lawyer .display-tab-backdrop {
        display: block;
    }
    
}

@media screen and (max-width:768px){
  .profile-lawyer .header-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.profile-lawyer .header-content .user-details {
    margin-top: 20px;
}

.profile-lawyer .header-content .user-details .user-name {
    font-size: 48px;
    line-height: 56px;
    margin-bottom: 8px;
    text-align: center;
}

.profile-lawyer .header-content .user-details .rating {
    display: flex;
    justify-content: center;
    align-items:center;
    margin-top: 12px;
}

}

@media screen and (max-width: 767px) {

    .profile-lawyer .my-profile-content .root {
        margin: 0;
    }

}
@media screen and(max-width:738px){
    .dialog-deactivate-account-vip-virtual-assistant .content .sub-title {
        font-family: Source Sans Pro;
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        line-height: 24px;
        margin-bottom: 24px;
        padding: 0px !important;
    }
    
}

@media screen and (max-width: 600px) {

    .profile-lawyer .information-content{
        margin: 64px 20px;
    }

    .profile-lawyer .information-content .text {
        margin: 0px 0px 24px 0px;
    }

} 

@media screen and (max-width: 537px) {

    .profile-lawyer .header-content .user-details .user-name {
        font-size: 24px;
        line-height: 24px;
    }

    .profile-lawyer .changes-button {
        display: flex;
        gap: 32px;
        margin-top: 46px;
        justify-content: center;
        margin-left: 0px;
        width: 100%;
    }
}

@media screen and (max-width:500px){
    .profile-lawyer{
        padding:0px 20px
    }
}

@media screen and (max-width:432px){
    .profile-lawyer .changes-button {
        display: flex;
        flex-direction: column;
        gap: 32px;
        margin-top: 46px;
        justify-content: center;
        margin-left: 0px;
        width: 100%;
    }
}

@media screen and (max-width:354px){
    .profile-lawyer .header-content .user-details {
        margin-left: 0px;
    }
}