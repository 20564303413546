.dialog-profile-delete-offer {
    text-align: center;
}

.dialog-profile-delete-offer-content {
    padding: 64px 127px;
}

.dialog-profile-delete-offer-content .title-header {
    padding: 0;
    margin-bottom: 8px;
}

.dialog-profile-delete-offer-content .title-header .title {
    display: inline-block;
    font-size: 48px;
    line-height: 52px;
    font-family: Lora;
    font-weight: 700;
}

.dialog-profile-delete-offer-content .title-header .close-icon {
    float: right;
    display: inline-block;
    cursor: pointer;
    margin-top: -40px;
    margin-right: -100px;
}

.dialog-profile-delete-offer-content .content {
    padding: 0;
    overflow-x: hidden;
}

.dialog-profile-delete-offer-content .content .sub-title {
    font-size: 18px;
    line-height: 24px;
    font-weight: 400;
    margin-bottom: 32px;
    font-family: Source Sans Pro;
}

.dialog-profile-delete-offer-content .content .actions {
    margin-top: 8px;
}

.dialog-profile-delete-offer-content .content .actions .button-delete {
    height: 48px;
    padding: 15px 32px;
    background: #00BCD4;
    border-radius: 4px;
    color: #fff;
    text-transform: uppercase;
    margin-right: 24px;
    font-size: 14px;
    line-height: 18px;
    font-family: Source Sans Pro;
    font-weight: 700;
}

.dialog-profile-delete-offer-content .content .actions .button-close {
    height: 48px;
    padding: 15px 32px;
    border: 2px solid #00BCD4;
    border-radius: 4px;
    color: #000;
    text-transform: uppercase;
    font-size: 14px;
    line-height: 18px;
    font-family: Source Sans Pro;
    font-weight: 700;
}


@media screen and (max-width:618px) {

    .dialog-profile-delete-offer-content {
        padding: 64px 32px;
        overflow: hidden;
    }

    .dialog-profile-delete-offer-content .title-header .close-icon {
        margin-top: -39px;
        margin-right: -14px;
    }
}

@media screen and (max-width:387px) {

    .dialog-profile-delete-offer-content .title-header .title {
        font-size: 30px;
        line-height: 34px;
    }

    .dialog-profile-delete-offer-content .content .actions {
        display: flex;
        flex-direction: column;
        gap: 15px;
    }

    .dialog-profile-delete-offer-content .content .actions .button-delete {
        margin-right: 0;
    }
}