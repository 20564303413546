.preliminary-contract { margin-bottom: 64px; }
.preliminary-contract .title-primary { margin: 0 120px 32px 120px; font-family: Source Sans Pro; font-style: normal; font-weight: normal; font-size: 18px; line-height: 24px; text-align: center; color: #000000; }

.preliminary-contract .content { margin-bottom: 32px; padding: 40px 0; background: #F8F8F8; border-radius: 8px; text-align: center; }

.preliminary-contract .content .image-primary { margin-bottom: 10px; }
.preliminary-contract .content .content-image-primary { width: 53px; margin-bottom: 13px; filter: invert(0%) sepia(0%) saturate(7500%) hue-rotate(217deg) brightness(108%) contrast(108%); }
.preliminary-contract .content .title { margin-bottom: 8px; font-family: Lora; font-style: normal; font-weight: bold; font-size: 32px; line-height: 32px; text-align: center; color: #000000; }
.preliminary-contract .content .title-sub { margin-bottom: 24px; padding: 0px 117px; font-family: Source Sans Pro; font-style: normal; font-weight: normal; font-size: 18px; line-height: 24px; text-align: center; color: #000000; }
.preliminary-contract .content .content-title-sub { margin: 0 200px 24px 200px; font-family: Source Sans Pro; font-style: normal; font-weight: normal; font-size: 18px; line-height: 24px; text-align: center; color: #000000; }
.preliminary-contract .content .buttons { margin-bottom: 10px; }
.preliminary-contract .content .buttons .button-online { margin-right: 30px; padding: 15px 32px; background: #00BCD4; border-radius: 4px; font-family: Source Sans Pro; font-style: normal; font-weight: bold; font-size: 14px; line-height: 18px; text-transform: uppercase; color: #FFFFFF; }
.preliminary-contract .content .buttons .button-upload { margin-right: 30px; padding: 15px 32px; border: 2px solid #00BCD4; box-sizing: border-box; border-radius: 4px; font-family: Source Sans Pro; font-style: normal; font-weight: bold; font-size: 14px; line-height: 18px; text-transform: uppercase; color: #000000; }
.preliminary-contract .content .buttons .button-upload img { margin-right: 5px; }
.preliminary-contract .content .buttons .button-save { padding: 15px 32px; font-family: Source Sans Pro; font-style: normal; font-weight: bold; font-size: 14px; line-height: 18px; text-transform: uppercase; color: #0097A7; }
.preliminary-contract .content .buttons .button-save img { margin-right: 5px; }

.preliminary-contract .content .abidors { font-family: Source Sans Pro; font-style: normal; font-weight: normal; font-size: 12px; line-height: 16px; text-align: center; letter-spacing: 0.4px; color: #000000; }

.preliminary-contract .owner-rep-switch-wrapper{
    margin-top: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.preliminary-contract .owner-rep-switch-wrapper > span{
    margin: -18px 20px 0 20px;
    color: #00BCD4 !important;
}

.commission .deposit-content .content .deposit .deposit-field {
    display: inline-block;
}

.commission .deposit-content .content .deposit .price-number {
    display: inline-block;
    height: 58px;
    padding: 4px 8px;
    background: #E0F7FA;
    border-radius: 4px;
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: bold;
    font-size: 17px;
    color: #000000;
    width: 170px;
    border: none;
    margin-bottom: 20px;
}

.commission .deposit-content .content .button-deposit {
    padding: 20px;
    background: #00BCD4;
    height: 58px;
    width: 170px;
    border-radius: 4px;
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 18px;
    text-transform: uppercase;
    color: #FFFFFF;
}

.commission .vipoffer-container.stripe { margin: 0 auto !important; }
.commission .title { font-size: 40px !important; }


.preliminary-contract .content .dropZone {
    border: 1px dashed #8c8c8c;
    width: 90%;
    height: 96px;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    cursor: pointer;
}

.preliminary-contract .content .dropzoneDisabled {
  opacity: 0.5;
  cursor: 'not-allowed !important';
}

.preliminary-contract .image-container {
    position: relative;
    display: grid;
    height: 700px;
}

.preliminary-contract .image-container .delete {
    position: relative;
    top: 0;
    left: 62%;
    width: 30px;
    height: 30px;
    background-color: white;
    cursor: pointer;
    border-radius: 16px;
    z-index: 10000;
    border: 1px solid #000;
  }
  
.preliminary-contract .image-container .delete span {
    display: flex;
}

.preliminary-contract .button-upload {
    margin: 20px 20px 0 auto;
    width: 120px;
    padding: 15px 0;
    background: #00BCD4;
    color: #fff;
    border-radius: 4px;
    text-transform: uppercase;
    font-family: Source Sans Pro;
    font-size: 14px;
    line-height: 18px;
    font-weight: 700;
}


.dialog-pay-success-commission {
  padding: 64px 127px;
}

.dialog-pay-success-commission .title-header {
  padding: 0;
  margin-bottom: 8px;
}

.dialog-pay-success-commission .title-header .title {
  display: inline-block;
  font-size: 48px;
  line-height: 52px;
  font-family: Lora;
  font-weight: 700;
}

.dialog-pay-success-commission .check-icon {
  margin-bottom: 15px;
  height: 76px;
  width: 76px;
}

.dialog-pay-success-commission .close-icon {
  cursor: pointer;
  position: absolute;
  top: 30px;
  right: 30px;
}

.dialog-pay-success-commission .content {
  padding: 0;
  overflow-x: hidden;
}

.dialog-pay-success-commission .content .sub-title {
  font-size: 18px;
  line-height: 24px;
  font-weight: 400;
  margin-bottom: 32px;
  font-family: Source Sans Pro;
}

.dialog-pay-success-commission .content .actions {
  margin-top: 8px;
}

.dialog-pay-success-commission .content .actions .button-close {
  height: 48px;
  padding: 15px 32px;
  background: #00bcd4;
  border-radius: 4px;
  color: #fff;
  text-transform: uppercase;
  font-size: 14px;
  line-height: 18px;
  font-family: Source Sans Pro;
  font-weight: 700;
}

.text-after-upload-contract {
  padding: 0 20px;
}

@media screen and (max-width: 432px) {
    .preliminary-contract .content .title-sub {
        padding: 0;
    }

    .commission .title {
        font-size: 27px !important;
    }

    .dialog-profile-send-new-offer-content .deposit-content p {
        text-align: justify;
    }

    .dialog-pay-success-commission {
      padding: 5px 0;
    }

    .dialog-pay-success-commission .check-icon {
      width: 50px;
      height: 50px;
    }

    .dialog-pay-success-commission .close-icon {
      top: 10px;
      right: 10px;
    }

    .dialog-pay-success-commission .title-header .title {
      font-size: 17px;
      line-height: 24px;
    }

    .preliminary-contract .button-upload {
      margin: 20px 5px 0 0;
    }
}