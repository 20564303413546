.dialog-profile-send-new-offer {
    text-align: center;
}

.dialog-profile-send-new-offer-content {
    padding: 64px 127px;
}

.dialog-profile-send-new-offer-content .title-header {
    padding: 0;
    margin-bottom: 8px;
}

.dialog-profile-send-new-offer-content .title-header .title {
    display: inline-block;
    font-size: 48px;
    line-height: 52px;
    font-family: Lora;
    font-weight: 700;
}

.dialog-profile-send-new-offer-content .title-header .close-icon {
    float: right;
    cursor: pointer;
    margin-top: -40px;
    margin-right: -100px;
}

.dialog-profile-send-new-offer-content .content {
    padding: 0;
    overflow-x: hidden;
}

.dialog-profile-send-new-offer-content .content .sub-title {
    font-size: 18px;
    line-height: 24px;
    font-weight: 400;
    margin-bottom: 20px;
    font-family: Source Sans Pro;
}

.dialog-profile-send-new-offer-content .content .announced-price {
    display: flex;
    padding: 0 40px;
    margin-bottom: 20px;
    align-items: center;
    justify-content: center;
}

.dialog-profile-send-new-offer-content .content .announced-price .label {
    font-size: 18px;
    line-height: 24px;
    font-family: Source Sans Pro;
    font-weight: 400;
    margin-right: 24px;
}

.dialog-profile-send-new-offer-content .content .announced-price .box-price {
    padding: 4px 8px;
    margin-right: 24px;
    border-radius: 8px;
    background: #E8E8E8;
    color: #000000;
    font-size: 24px;
    line-height: 30px;
    font-family: Source Sans Pro;
    font-weight: 700;
}

.dialog-profile-send-new-offer-content .content .announced-price .box-group {
    margin-right: 18px;
    display: flex;
    align-items: center;
}

.dialog-profile-send-new-offer-content .content .announced-price .box-group .box-text {
    padding: 3px 8px;
    background: #FFF59D;
    border-radius: 4px;
    font-size: 18px;
    line-height: 22px;
    font-family: Source Sans Pro;
    font-weight: 900;
    margin-right: 6px;
    
}

.dialog-profile-send-new-offer-content .content .announced-price .box-group .box-text  {
    margin-bottom:  0 !important;
}

.dialog-profile-send-new-offer-content .content .announced-price .box-group:last-child {
    margin-right: 0;
}

.dialog-profile-send-new-offer-content .content .description {
    margin-bottom: 44px;
    text-align: left;
}

.dialog-profile-send-new-offer-content .content .label {
    margin-bottom: 4px;
    font-size: 12px;
    line-height: 16px;
    font-family: Source Sans Pro;
    font-weight: 400;
    letter-spacing: 0.4px;
}

.dialog-profile-send-new-offer-content .content .description .label {
    margin-bottom: 4px;
    font-size: 12px;
    line-height: 16px;
    font-family: Source Sans Pro;
    font-weight: 400;
    letter-spacing: 0.4px;
}

.dialog-profile-send-new-offer-content .content .description .box {
    padding: 16px;
    border-radius: 8px;
    background: #F4F4F4;
}

.dialog-profile-send-new-offer-content .content .actions {
    margin-top: 8px;
}

.dialog-profile-send-new-offer-content .content .actions .button-send {
    height: 48px;
    padding: 15px 32px;
    background: #00BCD4;
    border-radius: 4px;
    color: #fff;
    text-transform: uppercase;
    margin-right: 34px;
    font-size: 14px;
    line-height: 18px;
    font-family: Source Sans Pro;
    font-weight: 700;
}

@media screen and (max-width:860px) {

    .dialog-profile-send-new-offer-content {
      
        overflow-x: hidden;
    }
}

@media screen and (max-width:765px) {

    .dialog-profile-send-new-offer-content {
        padding: 64px 32px;
    }

    .dialog-profile-send-new-offer-content .title-header .close-icon {
        float: right;
        cursor: pointer;
        margin-top: -40px;
        margin-right: 0px;
    }

    
}

@media screen and (max-width:703px) {
    .dialog-profile-send-new-offer-content .title-header .close-icon {
        margin-top: -90px;
    }
}

@media screen and (max-width:689px) {
    .dialog-profile-send-new-offer-content .title-header .close-icon {
        margin-top: -138px;
    }

}

@media screen and (max-width:505px) {
    .dialog-profile-send-new-offer-content .title-header .title {
        font-size: 30px;
        line-height: 34px;
    }

    .dialog-profile-send-new-offer-content .title-header .close-icon {
        margin-top: -40px;
    }
}

@media screen and (max-width:492px) {
   
    .dialog-profile-send-new-offer-content .title-header .close-icon {
        margin-top: -70px;
    }
}

@media screen and (max-width: 478px) {
    .dialog-profile-send-new-offer-content {
        padding: 64px 20px;
    }
    
}

@media screen and (max-width: 452px) {
  
    .dialog-profile-send-new-offer-content .title-header .close-icon {
        margin-top: -106px;
    }

    .dialog-profile-send-new-offer-content .content .announced-price {
        padding: 0px;
    }

}