.dialog-profile-accept-offer { text-align: center; }
.dialog-profile-accept-offer-content { padding: 64px 127px; }
.dialog-profile-accept-offer-content .title-header { padding: 0; margin-bottom: 8px; }
.dialog-profile-accept-offer-content .title-header .title { display: inline-block; font-size: 48px; line-height: 52px; font-weight: 700; }
.dialog-profile-accept-offer-content .title-header .close-icon { float: right; display: inline-block; cursor: pointer; margin-top: -40px; margin-right: -100px; }

.dialog-profile-accept-offer-content .content { padding: 0; overflow-x: hidden; }
.dialog-profile-accept-offer-content .content .sub-title { font-size: 18px; line-height: 24px; font-weight: 400; margin-bottom: 32px; }

.dialog-profile-accept-offer-content .content .actions { margin-top: 8px; }
.dialog-profile-accept-offer-content .content .actions .button-change { height: 48px; padding: 15px 32px;  background: #00BCD4; border-radius: 4px; color: #fff; text-transform: uppercase; margin-right: 34px; font-size: 14px; line-height: 18px; font-weight: 700; }
.dialog-profile-accept-offer-content .content .actions .button-close { height: 48px; padding: 15px 32px; border: 2px solid #00BCD4; border-radius: 4px; color: #000; text-transform: uppercase; font-size: 14px; line-height: 18px; font-weight: 700;  }

@media screen and (max-width: 706px) {

    .dialog-profile-accept-offer-content .content .actions {
        display: flex;
        flex-direction: column;

    }

    .dialog-profile-accept-offer-content .content .actions .button-change {
        margin-right: 0;
        margin-bottom: 16px;
    }
}

@media screen and (max-width: 624px) {

    .dialog-profile-accept-offer-content {
        padding: 64px 71px;
        overflow-x: hidden;
    }
}

@media screen and (max-width: 500px) {

    .dialog-profile-accept-offer-content {
        padding: 64px 32px;
    }
}