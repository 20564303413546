.home-buyer {
	text-align: center;
	margin: 0 auto;
	background-color: #fff;
}

.home-buyer .mortage-content {
    margin: 50px 110px 64px 110px;
    padding: 64px;
    border-radius: 30px;
    background-color: rgba(244, 244, 244, 1);
}

.home-buyer .mortage-content .content {
    text-align: left;
    margin-top: 40px;
}

.home-buyer .mortage-content img {
    width: 78%;
}

.home-buyer .mortage-content .content .title-mortage {
    font-size: 40px;
    font-weight: 700;
    line-height: 50px;
    text-align: left;
}

.home-buyer .mortage-content .content .title-mortage .percent {
    color: #00bcd4;
}

.home-buyer .mortage-content .content .text-mortage {
    font-size: 20px;
    font-weight: 400;
    line-height: 40px;
    text-align: left;
}

.home-buyer .mortage-content .content .button-mortage {
    font-size: 18px;
    font-weight: 700;
    text-align: center;
    padding: 15px 40px;
    border-radius: 5px;
    color: #fff;
    background-color: #00bcd4;
    text-transform: none;
}

.home-buyer .mortage-content .content .button-mortage:hover {
    background-color: rgba(0, 188, 212, 0.8);
}

.home-buyer .why-abidors {
    background-color: rgba(244, 244, 244, 1);
    padding: 55px;
    margin-bottom: 60px;
    text-align: center;
}

.home-buyer .why-abidors .title {
    font-size: 40px;
    font-weight: 700;
    line-height: 50px;
    margin-bottom: 0;
}

.home-buyer .why-abidors .text {
    font-size: 20px;
    font-weight: 400;
    line-height: 40px;
    padding: 0 30px;
}

.home-buyer .why-abidors .content .content-item {
    box-shadow: 4px 10px 30px 0px rgba(0, 0, 0, 0.06);
    background: rgba(255, 255, 255, 1);
    padding: 30px 40px;
    margin-bottom: 25px;
    border-radius: 30px;
}

.home-buyer .why-abidors .content .content-item img {
    float: left;
    margin-right: 45px;
    width: 17%;
}

.home-buyer .why-abidors .content .content-item .content-text {
    float: left;
    width: 71%;
    padding-top: 25px;
}

.home-buyer .why-abidors .content .content-item .content-text .title {
    font-size: 25px;
    font-weight: 700;
    line-height: 40px;
    text-align: left;
}

.home-buyer .why-abidors .content .content-item .content-text .text {
    font-size: 23px;
    font-weight: 400;
    line-height: 45px;
    text-align: left;
    padding: 0;
}

.home-buyer .neighborhood {
    margin: 64px 0 0 0;
}

.home-buyer .neighborhood .main-title {
    margin-bottom: 0;
    font-style: normal;
    font-weight: bold;
    font-size: 40px;
    line-height: 90px;
}

.home-buyer .neighborhood .swiper-slide {
    margin: auto;
    overflow: hidden;
    position: relative;
    height: 400px;
}

.home-buyer .neighborhood .swiper-slide .vertical {
    background-color: #efefef;
    text-align: left;
    border-radius: 12px;
    position: relative;
    background-position: center !important;
    background-size: cover !important;
}

.home-buyer .neighborhood .swiper-slide .vertical .title {
    margin-bottom: 8px;
    padding: 24px 0 0 24px;
    font-size: 24px;
    line-height: 26px;
    font-weight: 700;
    font-style: normal;
}

.home-buyer .neighborhood .swiper-slide .vertical .sub-title {
    margin-bottom: 64px;
    font-size: 18px;
    line-height: 22px;
    font-weight: 900;
    padding: 0 24px;
}

.home-buyer .neighborhood .swiper-slide .vertical .sub-title-italic {
    margin-bottom: 8px;
    font-size: 24px;
    line-height: 32px;
    text-align: left;
    font-weight: 400;
    font-style: italic;
}

.home-buyer .neighborhood .swiper-slide .vertical .image {
    width: 108px;
    height: 108px;
    margin: 0 73px 34px;
    background-color: #efefef;
    border: 1px solid gray;
    border-radius: 8px;
}

.home-buyer .neighborhood .swiper-slide .vertical .link-group {
    margin: 12px;
    position: absolute;
    bottom: 0;
    right: 0;
}

.home-buyer .neighborhood .swiper-slide .vertical .link-group .button-link {
    border-radius: 10px;
    background: #00bcd4;
    font-size: 15px;
    color: #fff;
    line-height: 18px;
    font-weight: normal;
    font-style: normal;
    text-transform: none;
}

.home-buyer .neighborhood .swiper-slide .vertical .link-group .button-link:hover {
    background-color: rgba(0, 188, 212, 0.8);
}

.home-buyer .last-added {
	margin-bottom: 32px;
}

.home-buyer .last-added .last-added-title {
    font-size: 40px;
    font-weight: 600;
    line-height: 95px;
    text-align: center;
}

.home-buyer .lawyer-and-assistant {
    margin: 100px 149px 64px;
}

.home-buyer .lawyer-and-assistant .content {
    background-color: #f4f4f4;
    padding: 32px;
    border-radius: 12px;
    height: 490px;
}

.home-buyer .lawyer-and-assistant .content .image {
    width: 130px;
    margin: 0 auto 20px auto;
}

.home-buyer .lawyer-and-assistant .content .title {
    margin-bottom: 15px;
    font-size: 25px;
    font-weight: 700;
    line-height: 30px;
    text-align: center;
}

.home-buyer .lawyer-and-assistant .content .sub-title {
    margin-bottom: 20px;
    font-size: 20px;
    font-weight: 400;
    line-height: 30px;
    text-align: center;
}

.home-buyer .lawyer-and-assistant .content .button-more {
    font-size: 18px;
    font-weight: 700;
    text-align: center;
    padding: 15px 40px;
    border-radius: 5px;
    color: #fff;
    background-color: #00bcd4;
    text-transform: none;
}

.home-buyer .lawyer-and-assistant .content .button-more:hover {
    background-color: rgba(0, 188, 212, 0.8);
}

.home-buyer .lawyer-and-assistant .content .button-more.virtual {
    margin-top: 28px;
}

.home-buyer .buyer-seller-section {
    margin: 0 149px;
    padding: 0;
}

.home-buyer .buyer-seller-section .buyer-seller-section-content {
    background: rgba(244, 244, 244, 1);
    padding: 20px;
    border-radius: 30px;
    display: inline-block;
    width: 100%;
    margin-bottom: 100px;
}

.home-buyer .buyer-seller-section .buyer-seller-section-content img {
    margin-bottom: 20px;
    width: 35%;
}

.home-buyer .buyer-seller-section .buyer-seller-section-content .title {
    font-size: 25px;
    font-weight: 700;
    line-height: 30px;
    text-align: center;
    margin-bottom: 30px;
}

.home-buyer .buyer-seller-section .buyer-seller-section-content .text {
    font-size: 20px;
    font-weight: 400;
    line-height: 35px;
    text-align: center;
    margin-bottom: 30px;
}

.home-buyer .buyer-seller-section .buyer-seller-section-content .learn-more {
    font-size: 18px;
    font-weight: 700;
    text-align: center;
    background: rgba(0, 188, 212, 1);
    color: #fff;
    text-transform: none;
    padding: 15px 40px;
    border-radius: 5px;
}

.home-buyer .buyer-seller-section .buyer-seller-section-content .learn-more:hover {
    background-color: rgba(0, 188, 212, 0.8);
}

@media screen and (max-width: 1340px) {
    .home-buyer .mortage-content .content .title-mortage {
        font-size: 40px;
        line-height: 60px;
    }

	.home-buyer .why-abidors .content .content-item {
        padding: 20px;
    }

    .home-buyer .why-abidors .content .content-item img {
        width: 24%;
    }

    .home-buyer .why-abidors .content .content-item .content-text {
        padding-top: 30px;
    }
}

@media screen and (max-width: 1130px) {
    .home-buyer .mortage-content .col{
        width: 100%;
    }

    .home-buyer .lawyer-and-assistant .col {
        margin-bottom: 10px;
    }
}

@media screen and (max-width: 1000px) {
	.home-buyer .why-abidors .content .content-item img {
        width: 20%;   
    }

    .home-buyer .why-abidors .content .content-item .content-text {
        padding-top: 0;
    }

    .home-buyer .why-abidors .content .content-item .content-text .title {
        font-size: 30px;
    }

    .home-buyer .why-abidors .content .content-item .content-text .text {
        font-size: 25px;
    }
}

@media screen and (max-width: 984px) {
	.cols::before,
	.cols::after {
		display: none;
	}

	.home-buyer .why-abidors .content .content-item img {
        width: 20%;   
    }

	.home-buyer .last-added .last-added-title {
        font-size: 30px;
        font-weight: 600;
        line-height: 45px;
	}
}

@media screen and (max-width:767px){
	.home-buyer .mortage-content {
        margin: 10px 19px;
        padding: 20px;
    }

    .home-buyer .mortage-content .image {
        margin-bottom: 35px;
    }

    .home-buyer .mortage-content .content {
        text-align: center;
    }

    .home-buyer .mortage-content .content .title-mortage {
        font-size: 39.8px;
        font-weight: 600;
        line-height: 47px;
        text-align: left;
    }

    .home-buyer .mortage-content .content .button-mortage {
        padding: 15px 20px;
        font-size: 18px;
        font-weight: 700;
        line-height: 15px;
        text-align: center;
    }

    .home-buyer .mortage-content img {
        width: 100%;
    }

	.home-buyer .why-abidors {
        padding: 20px;
        margin-bottom: 30px;
    }

    .home-buyer .why-abidors .title {
        font-size: 39.8px;
        font-weight: 600;
        line-height: 47px;
        text-align: center;
    }

    .home-buyer .why-abidors .text {
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        text-align: center;
        padding: 0;
    }

    .home-buyer .why-abidors .content .content-item {
        margin: 0 auto 15px auto;

    }

    .home-buyer .why-abidors .content .content-item img {
        float: none;
        width: 70%;
        margin-bottom: 50px;
        margin-right: 0;
    }

    .home-buyer .why-abidors .content .content-item .content-text {
        float: none;
        width: 100%;
        text-align: center;
    }

    .home-buyer .why-abidors .content .content-item .content-text .title {
        text-align: center;
        font-size: 25px;
        font-weight: 700;
        line-height: 45px;
    }

    .home-buyer .why-abidors .content .content-item .content-text .text {
        text-align: center;
        font-size: 12px;
        font-weight: 400;
        line-height: 20px;
    }
	
    .home-buyer .lawyer-and-assistant {
        margin: 20px 5px;
    }

    .home-buyer .lawyer-and-assistant .container {
        max-width: 100%;
    }

    .home-buyer .lawyer-and-assistant .content {
        height: 100%;
    }

    .home-buyer .lawyer-and-assistant .content .title {
        font-size: 25px;
        font-weight: 700;
        line-height: 30px;
    }

    .home-buyer .lawyer-and-assistant .content .sub-title {
        font-size: 12px;
        font-weight: 400;
        line-height: 20px;
    }

    .home-buyer .lawyer-and-assistant .content .button-more {
        padding: 12px 20px;
        font-size: 18px;
        font-weight: 700;
        line-height: 30px;
    }

	.home-buyer .buyer-seller-section {
		padding: 20px 0 0 0;
		margin: 0 auto;
        width: 85%;
	}

	.home-buyer .buyer-seller-section .buyer-seller-section-content {
		margin-bottom: 20px;
	}

	.home-buyer .buyer-seller-section .buyer-seller-section-content img {
        margin-bottom: 30px;
        width: 100%;
    }

    .home-buyer .buyer-seller-section .buyer-seller-section-content .title {
        font-size: 25px;
        font-weight: 700;
        line-height: 45px;
        text-align: center;
        margin-bottom: 0;
    }

    .home-buyer .buyer-seller-section .buyer-seller-section-content .text {
        font-size: 12px;
        font-weight: 400;
        line-height: 20px;
        text-align: center;
        margin-bottom: 25px;
    }

    .home-buyer .buyer-seller-section .buyer-seller-section-content .learn-more {
        padding: 12px;
        font-size: 18px;
        font-weight: 700;
        line-height: 25px;
    }
}

@media screen and (max-width: 500px) {
	.home-buyer .neighborhood .main-title {
		font-size: 43px;
	}
}