.photographer-tab{
  display: flex;
  flex-direction: column;
}
.photographer-tab .back-btn{
  background: #00BCD4;
  color:#fff;
  float: left;
  width: fit-content;
}

.photographer-tab .back-btn:hover{
  background: #00BCD4;
  color:#fff
}