
.place_autoCompletion_container{
    width:230px;
}

.place_autoCompletion_container .autoCompletion-input-box{
    background-color: #ededed; 
    padding: 20px;
    font-size: 16px;
    color: #000;
    width: 100%;
    border: none;
    border-radius: 8px;
}

.place_autoCompletion_container .autocomplete-dropdown-container{
     box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
     width: 230px;
     
}

.autocomplete-dropdown {
    position: absolute;
    width: 100%;
    z-index: 9999; 
  }