.VAHomeSection {
  margin: 30px 110px 64px 110px;
}

.VAHomeSection .description-container {
  position: relative;
  height: 520px;
  width: 100%;
  margin: 0 auto 24px auto;
  border-radius: 8px;
  background-size: cover;
  background-position: center;
  display: flex;
  flex-direction: column;
  gap: 20px;
  background-position: 0%;
  background-image: url("https://d3kuhob3u5bpy2.cloudfront.net/a33add14-6e7f-4db0-a9d1-03c27a73676f.png");
  padding: 30px;
  padding-top: 150px;
}

.VAHomeSection .description-container::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3); /* Adjust the opacity as needed */
  z-index: 1;
  border-radius: 8px;
}

.VAHomeSection .description-container > * {
  position: relative;
  z-index: 2;
}

.VAHomeSection .description-container > h3 {
  text-align: left;
  font-weight: 700;
  font-size: 10px;
  line-height: 50px;
  font-family: "Graphik";
  letter-spacing: var(--letter-spacing-paragraph);
  font-style: normal;

  color: #ffffff;
}
.VAHomeSection .description-container > p {
  text-align: left;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;
  font-family: "Graphik Regular";
  letter-spacing: var(--letter-spacing-paragraph);
  font-style: normal;
}
.VAHomeSection .description-container > button {
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: #00bcd4;
  padding: var(--button-padding);
  border: none;
  border-radius: 10px;
  width: fit-content;
  font-family: "Graphik";
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: var(--letter-spacing-button);
  color: white;
}

.VAHomeSection .video {
  position: relative;
  display: flex;
}
.VAHomeSection .video .video-play-button {
  cursor: pointer;
}
.VAHomeSection .video .video-play-button-container {
  position: absolute;
  top: 45%;
  left: 45%;
  z-index: 1;
}
.VAHomeSection .video .video-loader {
  background-color: black;
  z-index: 999999;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 700px;
  width: 100%;
  position: absolute;
  background-size: cover;
  background-position: center;
  background-position: 0%;
  background-image: url("https://d3kuhob3u5bpy2.cloudfront.net/700836e5-fa87-4a0b-be1e-fc3f9028c084.png");
}
.VAHomeSection .video .video-loader .circular-loader{
  position: absolute;
  top: -6.5px;
  left: -6.5px;
  z-index: 300;
  cursor: pointer;
}
@media screen and (max-width: 1187px) {
  .VAHomeSection {
    margin: 20px 30px 30px 30px;
  }
  .VAHomeSection .description-container > h3 {
    font-size: 30px;
    line-height: 35px;
  }
  .VAHomeSection .description-container > p {
    font-size: 14px;
    line-height: 20px;
  }
  .VAHomeSection .description-container > button {
    font-size: 14px;
    line-height: 22px;
  }
  .VAHomeSection .description-container {
    padding-top: 120px;
  }
}
@media screen and (max-width: 600px) {
  .VAHomeSection .video .video-loader {
    height: 250px;
  }
  .VAHomeSection .description-container > h3 {
    font-size: 20px;
    line-height: 25px;
  }
  .VAHomeSection .description-container > p {
    font-size: 13px;
    line-height: 16px;
  }
  .VAHomeSection .description-container > button {
    font-size: 11px;
    line-height: 14px;
  }
  .VAHomeSection .description-container {
    padding-top: 30px;
    height: 600px;
  }
  .VAHomeSection .video .video-play-button-container {
    top: 35%;
  }
}

@media screen and (max-width: 527px) {
  .VAHomeSection .video .video-loader .circular-loader{
    top: -4.4px;
    left: -4.5px;
  }
}
@media screen and (max-width: 500px) {
  .VAHomeSection .video .video-loader .circular-loader{
    top: -3.5px;
    left: -3.5px;
  }
}