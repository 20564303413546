.edit-profile-virtual-assistant .header-title {
    margin-top: 64px;
}

.edit-profile-virtual-assistant .header-title .title {
    font-family: Lora;
    margin-bottom: 12px;
    font-size: 48px;
    line-height: 52px;
    text-align: center;
}

.edit-profile-virtual-assistant .header-common {
    text-align: center;
}

.edit-profile-virtual-assistant .header-common .paragraph {
    margin-bottom: 24px;
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 24px;
    color: #000000;
}

.edit-profile-virtual-assistant .button-save {
    width: 115px;
    padding: 15px 32px;
    background-color: #00BCD4;
    border-radius: 4px;
    color: #FFFFFF;
    margin-bottom: 64px;
    text-transform: uppercase;
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 18px;
}

.edit-profile-virtual-assistant .header-common .button-view-profile {
    margin-left: 32px;
    padding: 15px 32px;
    background-color: #00BCD4;
    border-radius: 4px;
    color: #FFFFFF;
    margin-bottom: 64px;
    text-transform: uppercase;
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 18px;
}

.edit-profile-virtual-assistant .header-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 64px;
    padding: 0 32px;
}

.edit-profile-virtual-assistant .header-content .avatar {
    width: 178px;
    height: 178px;
    background: #000000;
    border-radius: 50%;
    margin-left: 18px;
    float: left;
    position: relative;
    margin-bottom: 8px;
}

.edit-profile-virtual-assistant .header-content .avatar:hover span {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 999;
    cursor: pointer;
}

.edit-profile-virtual-assistant .header-content .avatar:hover span::before {
    position: absolute;
    content: '';
    height: 50px;
    width: 2px;
    background-color: #fff;
    transform: rotate(45deg);
    left: 50%;
    top: 38%;
}

.edit-profile-virtual-assistant .header-content .avatar:hover span::after {
    position: absolute;
    content: '';
    height: 50px;
    width: 2px;
    background-color: #fff;
    transform: rotate(-45deg);
    left: 50%;
    top: 38%;
}

.edit-profile-virtual-assistant .header-content .avatar .icon {
    position: absolute;
    right: 0px;
    bottom: 10px;
    height: 30px;
    width: 30px;
}

.edit-profile-virtual-assistant .header-content .avatar .photo {
    width: 178px;
    height: 178px;
    border-radius: 50%;
    object-fit:cover
}

.edit-profile-virtual-assistant .header-content .avatar:hover .photo {
    opacity: 0.3;
}

.edit-profile-virtual-assistant .header-content .avatar:hover .upload-section {
    opacity: 1;
}

.edit-profile-virtual-assistant .header-content .avatar .upload-section {
    position: absolute;
    top: 40%;
    left: 35%;
    display: none;
}

.edit-profile-virtual-assistant .header-content .avatar:hover .upload-section {
    display: block;
}

.edit-profile-virtual-assistant .header-content .user-details {
    margin-left: 24px;
    float: left;
}

.edit-profile-virtual-assistant .header-content .user-details .user-name {
    font-family: Lora;
    font-size: 48px;
    line-height: 52px;
    margin-bottom: 8px;
}

.edit-profile-virtual-assistant .header-content .user-details .rating {
    display: flex;
    align-items: flex-end;
}

.edit-profile-virtual-assistant .header-content .user-details .rating .title {
    margin-right: 8px;
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: 900;
    font-size: 18px;
    line-height: 22px;
    text-transform: uppercase;
}

.edit-profile-virtual-assistant .header-content .user-details .rating .star {
    width: 16px;
    height: 16px;
    margin-top: 1px;
    filter: invert(35%) sepia(81%) saturate(1357%) hue-rotate(156deg) brightness(94%) contrast(101%)
}

.edit-profile-virtual-assistant .header-content .user-details .rating .result {
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 24px;
    margin-left: 8px;
}

.edit-profile-virtual-assistant .header-content .user-details .additional {
    margin-top: 17px;
    display: flex;
}

.edit-profile-virtual-assistant .header-content .user-details .additional .first-half .title {
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 18px;
    color: #656565;
    margin-bottom: 9px;
}

.edit-profile-virtual-assistant .header-content .user-details .additional .block {
    display: flex;
    background: #F4F4F4;
    border-radius: 4px;
}

.edit-profile-virtual-assistant .header-content .user-details .additional .block .icon {
    width: 18px;
    height: 20px;
    margin: 19px 11px 21px 19px;
    color: #000000;
}

.edit-profile-virtual-assistant .header-content .user-details .additional .block .text {
    margin: 16px 16px 16px 0px;
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 24px;
    text-transform: uppercase;
    color: #000000;
    flex: none;
}

.edit-profile-virtual-assistant .header-content .user-details .additional .second-half {
    margin-left: 32px;
}

.edit-profile-virtual-assistant .header-content .user-details .additional .second-half .title-content {
    display: flex;
}

.edit-profile-virtual-assistant .header-content .user-details .additional .second-half .title-content .title {
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 18px;
    color: #656565;
    margin-bottom: 9px;
}

.edit-profile-virtual-assistant .header-content .user-details .additional .second-half .title-content .icon {
    width: 20px;
    height: 20px;
    color: #000000;
    margin-left: 11px;
    margin-top: -1px;
}

.edit-profile-virtual-assistant .header-content .about-info {
    margin-left: 32px;
}

.edit-profile-virtual-assistant .header-content .about-info .title {
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.4px;
    color: #000000;
    margin-bottom: 4px;
}

.edit-profile-virtual-assistant .header-content .about-info .box {
    width: 486px;
    /* height: fit-content; */
    height: 148px;
    background: #F8F8F8;
    border-radius: 12px;
    color: #9D9D9D;
    padding: 16px;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px rgba(0, 0, 0, 0.12), 0px 1px 3px rgba(0, 0, 0, 0.2);
    overflow-y: auto
}

.edit-profile-virtual-assistant .header-content .price-info {
    margin-left: 32px;
}

.edit-profile-virtual-assistant .header-content .price-info .title {
    font-size: 12px;
    line-height: 14px;
    margin-bottom: 4px;
}

.edit-profile-virtual-assistant .header-content .price-info .box {
    width: 168px;
    height: 148px;
    background: #F8F8F8;
    border-radius: 12px;
    color: #9D9D9D;
    padding: 16px 12px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.edit-profile-virtual-assistant .header-content .price-info .button {
    margin-top: 80px;
    padding: 15px 32px;
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 18px;
    background: #00BCD4;
    border-radius: 4px;
    text-transform: uppercase;
    color: #EFEFEF;
}

.edit-profile-virtual-assistant .why-to-choose-me {
    margin: 64px 0 24px 0;
    text-align: center;
}

.edit-profile-virtual-assistant .why-to-choose-me .title {
    font-size: 48px;
    line-height: 52px;
    margin-bottom: 32px;
    font-family: Lora;
}

.edit-profile-virtual-assistant .why-to-choose-me .box {
    background: #F4F4F4;
    border-radius: 8px;
    padding: 16px;
    text-align: left;
    margin-bottom: 20px;
}

.edit-profile-virtual-assistant .why-to-choose-me .box .sub-title {
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.4px;
}

.edit-profile-virtual-assistant .why-to-choose-me .box .icon {
    float: right;
    width: 9px;
    height: 9px;
    cursor: pointer;
    transition: 0.3s ease-in-out;
}

.edit-profile-virtual-assistant .why-to-choose-me .box .icon:hover {
    transform: scale(1.5);
}

.edit-profile-virtual-assistant .why-to-choose-me .button-section {
    height: 270px;
    border-style: dashed;
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 18px;
    text-transform: uppercase;
    color: #0097A7;
}

.edit-profile-virtual-assistant .save-changes {
    text-align: center;
    margin-bottom: 32px;
}

.edit-profile-virtual-assistant .save-changes .button-save {
    width: 200px;
    height: 50px;
    padding: 15px 32px;
    background: #00BCD4;
    border-radius: 4px;
    margin-bottom: 128px;
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 18px;
    text-transform: uppercase;
    color: #FFFFFF;
}

.edit-profile-virtual-assistant .save-changes .button-save:hover {
    background: #00BCD4;
}

.edit-profile-virtual-assistant .part-of-success-assistants {
    text-align: center;
    margin-bottom: 128px;
}

.edit-profile-virtual-assistant .part-of-success-assistants .title {
    font-family: Lora;
    font-size: 48px;
    line-height: 52px;
    margin-bottom: 12px;
}

.edit-profile-virtual-assistant .part-of-success-assistants .text {
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 24px;
}

.edit-profile-virtual-assistant .part-of-success-assistants .users-content {
    display: flex;
    justify-content: center;
    align-items: center;
}

.edit-profile-virtual-assistant .part-of-success-assistants .users-content .user {
    display: grid;
    align-items: center;
    justify-content: center;
    margin-right: 80px;
}

.edit-profile-virtual-assistant .part-of-success-assistants .users-content .user:last-child {
    margin-right: 0;
}

.edit-profile-virtual-assistant .part-of-success-assistants .users-content .user .avatar {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 112px;
    height: 112px;
    background: #CFCFCF;
    border-radius: 250px;
    margin-bottom: 16px;
    margin-left: 20px;
}

.edit-profile-virtual-assistant .part-of-success-assistants .users-content .user .name {
    font-family: Lora;
    font-size: 24px;
    line-height: 26px;
    margin-bottom: 12px;
}

.edit-profile-virtual-assistant .part-of-success-assistants .users-content .user .rating .stars {
    margin-bottom: 4px;
}

.edit-profile-virtual-assistant .part-of-success-assistants .users-content .user .rating .stars .star {
    width: 16px;
    height: 16px;
    filter: invert(35%) sepia(81%) saturate(1357%) hue-rotate(156deg) brightness(94%) contrast(101%)
}

.edit-profile-virtual-assistant .part-of-success-assistants .users-content .user .rating .calculated-text {
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 18px;
}

@media screen and (max-width:1281px){
    .edit-profile-virtual-assistant .header-content {
        display: flex;
        flex-direction: column;
        margin-bottom: 64px;
        padding: 0 10px;
    }
    
    .edit-profile-virtual-assistant .header-content .about-info .box {
        width: 812.19px;
        height: 148px;
        background: #F8F8F8;
        border-radius: 12px;
        color: #9D9D9D;
        padding: 16px;
        box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px rgba(0, 0, 0, 0.12), 0px 1px 3px rgba(0, 0, 0, 0.2);
    }
    .edit-profile-virtual-assistant .header-content .user-details .user-name {
        font-family: Lora;
        font-size: 24px;
        line-height: 52px;
        margin-bottom: 8px;
    }
}

@media screen and (max-width:1161px){
    .edit-profile-virtual-assistant .why-to-choose-me .cols-full {
       display:grid;
       grid-template-columns: repeat(2,1fr);
    }

    .edit-profile-virtual-assistant .why-to-choose-me .cols::before, .cols::after {
        content:none;
        display: grid;
    }
    
    .edit-profile-virtual-assistant .why-to-choose-me .cols-full .col4of12 {
     width:100%
    }
    
}

@media screen and (max-width:856px){

    .edit-profile-virtual-assistant .header-content .about-info {
        margin-left: 0px;
        width: 100%;
        margin-top: 20px;
    }

    .edit-profile-virtual-assistant .header-content .about-info .box {
        width: 100%;
        height: 148px;
        background: #F8F8F8;
        border-radius: 12px;
        color: #9D9D9D;
        padding: 16px;
        box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px rgba(0, 0, 0, 0.12), 0px 1px 3px rgba(0, 0, 0, 0.2);
    }
}

@media screen and (max-width:768px){

    .edit-profile-virtual-assistant .why-to-choose-me .cols-full {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .edit-profile-virtual-assistant .why-to-choose-me .cols-full .col4of12 {
       width: 100%;
    }

    .edit-profile-virtual-assistant .header-content .about-info {
        margin-left: 0px;
        height:auto;
    }
}

@media screen and (max-width:432px){
    .edit-profile-virtual-assistant .why-to-choose-me {
        margin: 64px 20px 24px 20px;
        text-align: center;
    }
}