.service-item { background: #FFFFFF; border-radius: 8px; display: flex; align-items: center; padding: 24px; margin-top: 8px; }
.service-item .service-desc { margin-left: 24px; text-align: left; }
.service-item .service-desc .title { font-family: Lora; font-style: normal; font-weight: bold; font-size: 24px; line-height: 26px; color: #000000; }
.service-item .service-desc .text { font-family: Source Sans Pro; font-style: normal; font-weight: normal; font-size: 18px; line-height: 24px; color: #000000; }
.service-item .portfolio-content { display: flex; flex-direction: column; text-align: left; }
.service-item .portfolio-content .portfolio-item { display: flex; flex-direction: row; gap: 8px; margin-bottom: 16px; }
.service-item .portfolio-content .portfolio-item .icon { width: 24px; height: 24px; }
.service-item .portfolio-content .portfolio-item .desc { font-family: Source Sans Pro; font-style: normal; font-weight: normal; font-size: 18px; line-height: 24px; text-align: center; color: #000000; }

.service-item .portfolio-content .portfolio-item:last-child { margin-top: 8px; }
.service-item .portfolio-content .portfolio-item .package-price { padding: 4px 24px; background: #F8F8F8; border-radius: 4px; }
.service-item .portfolio-content .portfolio-item .package-price .price { font-family: Lora; font-style: normal; font-weight: bold; font-size: 24px; line-height: 26px; text-align: center; color: #6A6A6A; }
.service-item .portfolio-content .portfolio-item .package-price .unit { font-family: Source Sans Pro; font-style: normal; font-weight: normal; font-size: 12px; line-height: 16px; text-align: center; letter-spacing: 0.4px; color: #6A6A6A; }
