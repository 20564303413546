.filter-fields { margin-bottom: 32px; }
.filter-fields .MuiSvgIcon-root { color: #0097A7; }
.filter-fields .title { margin-bottom: 4px; font-family: Source Sans Pro; font-style: normal; font-weight: normal; font-size: 18px; line-height: 24px; color: #000000; }
.filter-fields .autocomplete { background: #EDEDED; border-radius: 4px; font-family: Source Sans Pro; font-style: normal; font-weight: normal; font-size: 16px; line-height: 24px; color: #000000; }
.filter-fields .textField { margin: 0 !important; font-family: Source Sans Pro; font-style: normal; font-weight: normal; font-size: 16px; line-height: 24px; color: #000000; }
.filter-fields .textField input { font-style: normal; font-weight: normal; font-size: 16px; line-height: 24px; color: #000; }

.filter-fields .search { width: 100%; position: relative; float: left; padding: 15px 0 15px 13px; background-color: #EDEDED; border-radius: 4px; }
.filter-fields .search .search-field::placeholder { font-style: normal; font-weight: normal; font-size: 16px; line-height: 24px; color: rgba(0, 0, 0, 0.6); }
.filter-fields .search .search-field { float: left; width: 80%; padding: 0; background-color: #EDEDED; font-family: Source Sans Pro; font-style: normal; font-weight: normal; font-size: 16px; line-height: 24px; border: none; color: rgba(0, 0, 0, 0.6); outline: none;}
.filter-fields .search .search-image { float: left; margin: 5px 8px 0 2px; filter: invert(32%) sepia(74%) saturate(1688%) hue-rotate(159deg) brightness(101%) contrast(101%); }

.place_autoCompletion_thirdParty .autoCompletion-input-box{
  background-color: #ededed; 
  padding: 20px;
  font-size: 16px;
  color: #000;
  width: 100%;
  border: none;
  border-radius: 8px;
}

.place_autoCompletion_thirdParty .autocomplete-dropdown-container{
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  width: 261px;
  
}

.autocomplete-dropdown {
 position: absolute;
 width: 100%;
 z-index: 9999; 
}

@media screen and (max-width:1320px){
  .filter-fields{
      display: none;
  }
}

