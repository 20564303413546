.myAdds-wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}
.myAdds-wrapper > h1 {
    font-family: Lora;
    font-size: 72px;
    font-style: normal;
    font-weight: 500;
    line-height: 76px;
    margin-top: 64px;
    margin-bottom: 32px;
}
.myAdds-wrapper .tabContents .left .pagination-sector {
    text-align: center;
    padding-top: 20px;
}
.myAdds-wrapper .tabContents .left .pagination-sector  .pagination-info {
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 24px;
    color: #000000;
    opacity: 0.7;
}
.myAdds-wrapper .tabContents {
    width: 100%;
    background-color: #e8e8e8;
    border-radius: 4px;
    display: flex;
    margin-bottom: 128px;
}
.myAdds-wrapper .tabContents > .left {
    width: 435px;
    overflow: auto;
    overflow-x: hidden;
    margin-right: 1px;
}
.myAdds-wrapper .tabContents > .left > .paginaton-sector {
    text-align: center;
    padding-top: 20px;
    background: 0;
}
.myAdds-wrapper .tabContents > .left > .paginaton-sector > .pagination-info {
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 24px;
    color: red;
    opacity: 0.7;
}
.myAdds-wrapper .tabContents > .left::-webkit-scrollbar {
    width: 0.3em !important;
    height: 0.3em !important;
}
.myAdds-wrapper .tabContents > .left::-webkit-scrollbar-track {
    margin-top: 1px !important;
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3) !important;
}
.myAdds-wrapper .tabContents > .left::-webkit-scrollbar-thumb {
    background-color: #4dd0e1;
    outline: 1px solid #4dd0e1 !important;
}
.myAdds-wrapper .tabContents > .left .button-wrap {
    margin: 24px;
}
.myAdds-wrapper .tabContents > .left .button-wrap > button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 15px 0;
    cursor: pointer;
    border: 1px dashed #8c8c8c;
    box-sizing: border-box;
    border-radius: 8px;
    font-family: 'Source Sans Pro', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 18px;
    text-transform: uppercase;
    color: #0097a7;
}
.myAdds-wrapper .tabContents > .left .button-wrap > button > img {
    margin-left: 8px;
}
.myAdds-wrapper .tabContents > .right {
    display: flex;
    flex-direction: column;
    width: 100%;
}
.myAdds-wrapper .tabContents > .right .tab-button-wrapper {
    display: flex;
}
.myAdds-wrapper .tabContents > .right .tab-button-wrapper > div {
    padding: 16px 24px;
    cursor: pointer;
}
.myAdds-wrapper .tabContents > .right .tab-button-wrapper .selected {
    background-color: #f8f8f8;
    border-radius: 12px 12px 0px 0px;
}
.myAdds-wrapper .tabContents > .right .tab-content {
    min-height: 530px;
    background-color: #f8f8f8;
    padding: 24px;
}
.tab-button-wrapper {
    position: relative;
}
.tab-button-wrapper .mobileselected {
    background: #0097a7 !important;
    border-radius: 0 !important;
    color: #fff !important;
    height: 100% !important;
    width: 100% !important;
    display: flex;
    justify-content: center;
    align-items: center;
}

.button-active-and-sold-properties {
    position: relative;
    display: flex;
    margin: 20px;
}

.button-active-and-sold-properties .active-properties,
.button-active-and-sold-properties .sold-properties {
    padding: 19px 30px;
    background: #FFFFFF;
    border: 1px solid #00BCD4;
    border-radius: 4px;
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
    text-transform: uppercase;
    color: #00BCD4;
    display: inline-block;
    width: 140px
}

.button-active-and-sold-properties .active-properties:hover,
.button-active-and-sold-properties .sold-properties:hover {
    background: #FFFFFF;
    border: 1px solid #00BCD4;
    color: #00BCD4;
}

.button-active-and-sold-properties .active-properties.active,
.button-active-and-sold-properties .sold-properties.active {
    background: #00BCD4;
    color: #FFFFFF;
}

.button-active-and-sold-properties .active-properties {
    margin-right: 10px;
}

.myAdds-wrapper .tabContents .right.sold-properties {
    padding: 10px;
}

@media screen and (max-width: 1239px) {
    .button-wrap .addBtn {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        padding: 15px 5px;
        cursor: pointer;
        border: 1px dashed #8c8c8c;
        box-sizing: border-box;
        border-radius: 8px;
        font-family: 'Source Sans Pro', sans-serif;
        font-style: normal;
        font-weight: bold;
        font-size: 14px;
        line-height: 18px;
        text-transform: uppercase;
        color: #0097a7;
        margin: 20px 0px;
   }
    .button-wrap .addBtn img {
        margin-left: 8px;
   }

   .myAdds-wrapper .MuiGrid-container .MuiGrid-grid-xs-12 {
        display: grid !important;
        grid-template-columns: repeat(2, 1fr) !important;
        gap: 50px;
    }

    .myAdds-wrapper .MuiGrid-container .MuiGrid-grid-xs-12 .card .MuiGrid-spacing-xs-3 {
        display: flex !important;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .myAdds-wrapper .MuiGrid-container .MuiGrid-grid-xs-12 .card .MuiGrid-grid-xs-5 {
        max-width: 100% !important;
    }

    .myAdds-wrapper .MuiGrid-container .MuiGrid-grid-xs-12 .card .MuiGrid-container {
        display: flex !important;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        max-width: 100% !important;
        margin: 0 auto !important;
    }

    .myAdds-wrapper .MuiGrid-container .MuiGrid-grid-xs-12 .card .MuiGrid-container .MuiGrid-grid-xs-3 {
        max-width: 100% !important;
        flex-basis: 0 !important;
        width: 100% !important;
    }

    .myAdds-wrapper .MuiGrid-container .MuiGrid-grid-xs-12 .card .MuiGrid-container .MuiGrid-grid-xs-4 {
        flex-basis: 0 !important;
        width: 100% !important;
        max-width: 100% !important;
    }

    .myAdds-wrapper .MuiGrid-container .MuiGrid-grid-xs-12 .card .MuiGrid-container .MuiGrid-grid-xs-3 p {
        text-align: center !important;
        font-size: 30px !important;
    }

    .myAdds-wrapper .MuiGrid-container .MuiGrid-grid-xs-12 .card .MuiGrid-container .MuiGrid-grid-xs-10 {
        max-width: 100% !important;
        flex-basis: 0;
    }

    .myAdds-wrapper .MuiGrid-container .MuiGrid-grid-xs-7  {
        width: 100% !important;
        max-width: 100% !important;
    }

    .myAdds-wrapper .sub-title-container {
        display: none;
    }

    .myAdds-wrapper .card .card-item .info-box {
        width: 100%;
        margin-bottom: 8px;
        justify-content: center;
    }

    .myAdds-wrapper .card .card-item .price  {
        text-align: center;
        margin-top: 20px;
    }
}

@media screen and (max-width: 793px) {
    .right .tab-content {
        position: relative;
   }
    .right .tab-content .menu-container .menu-header {
        width: 100%;
        height: 30px;
        background: #0097a7;
        display: flex;
        justify-content: flex-end;
        align-items: flex-end;
   }
    .right .tab-content .menu-container .menu-header .closebtn {
        font-size: 25px;
        cursor: pointer;
        color: #fff;
   }
    .right .tab-content .menu-container {
        position: absolute;
        top: 0;
        left: 0;
        width: 25%;
        height: 100%;
        background: #a1a1a1;
        z-index: 10000;
   }
    .right .tab-content .back-drop {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: #000 81;
        z-index: 8000;
   }
    .right .tab-content .menu-container .menu-body .menu-body-tab {
        cursor: pointer;
        padding: 5px 10px;
   }
    .right .tab-content .menu-container .menu-body .selected {
        background-color: #f8f8f8;
        cursor: pointer;
   }
    .right .tab-content .menu-btn {
        font-size: 20px;
        font-weight: 700;
        cursor: pointer;
   }

   .myAdds-wrapper .MuiGrid-container .MuiGrid-grid-xs-12 {
        grid-template-columns: repeat(1, 1fr) !important;
        gap: 25px;
    }
}
@media screen and (max-width: 768px) {
    .myAdds-wrapper > h1 {
        font-family: Lora;
        font-size: 50px;
        font-style: normal;
        font-weight: 500;
        line-height: 76px;
        margin-top: 64px;
        margin-bottom: 32px;
   }
}
@media screen and (max-width: 380px) {
    .myAdds-wrapper .tabContents > .right .tab-content {
        min-height: 530px;
        background-color: #f8f8f8;
        padding: 0px 10px;
    }

    .myAdds-wrapper .MuiGrid-container .MuiGrid-grid-xs-12 .card .MuiGrid-container {
        display: block !important;
        text-align: center;
    }

    .myAdds-wrapper .card {
        padding: 0 !important;
    }

    .myAdds-wrapper .MuiGrid-container .MuiGrid-grid-xs-12 {
        gap: 1px
    }

    .myAdds-wrapper .MuiGrid-container .MuiGrid-grid-xs-12 .MuiGrid-item {
        max-width: 100% !important;
        padding: 10px !important;
    }

    .myAdds-wrapper .card .card-item .price {
        margin-top: -10px;
    }

    .myAdds-wrapper .card .card-item .square-meters {
        margin-top: 0px !important;
        justify-content: center !important;
        margin-bottom: 0px !important;
    }

}

@media screen and (max-width: 320px) {
    .myAdds-wrapper > h1 {
        font-family: Lora;
        font-size: 45px;
        font-style: normal;
        font-weight: 500;
        line-height: 76px;
        margin-top: 45px;
        margin-bottom: 32px;
   }
}

.myAdds-wrapper .card {
    padding: 16px;
    margin: 0;
    background: #F8F8F8;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px rgba(0, 0, 0, 0.12), 0px 1px 3px rgba(0, 0, 0, 0.2);
    border-radius: 8px;
    margin-bottom: 16px;
}

.myAdds-wrapper .card:last-child {
    margin-bottom: 64px;
}

.myAdds-wrapper .card .tl {
    text-align: left;
}

.myAdds-wrapper .card .card-item .price {
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 45px;
    text-transform: uppercase;
    margin-bottom: 18px;
}

.myAdds-wrapper .card .card-item .info-box,
.myAdds-wrapper .card .card-item .info-box .icon-group,
.myAdds-wrapper .card .card-item .info-box .icon-group>span,
.myAdds-wrapper .card .card-item .location-details,
.myAdds-wrapper .card .card-item .square-meters {
    display: flex;
    align-items: center;
}

.myAdds-wrapper .card .card-item .info-box {
    width: 100%;
    margin-bottom: 8px;
}

.myAdds-wrapper .card .card-item .info-box .icon-group:not(:last-child) {
    margin-right: 8px;
}

.myAdds-wrapper .card .card-item .info-box .icon-group .icon {
    width: 18px;
    height: 18px;
    margin-right: 4px;
}

.myAdds-wrapper .card .card-item .location-details .icon {
    width: 24px;
    height: 20px;
    margin-right: 4px;
    margin-left: -4px;
}

.myAdds-wrapper .card .card-item .location-details .text {
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    overflow: hidden;
    width: 100%;
    height: 25px;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.myAdds-wrapper .card .card-item .square-meters {
    margin-top: 10px;
    justify-content: flex-end;
    margin-bottom: 62px;
}

.myAdds-wrapper .card .card-item .square-meters .text {
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
    padding: 3px 8px;
    background: #EDEDED;
    border-radius: 4px;
    margin-right: 8px;
}

.myAdds-wrapper .card .card-item .offer-text {
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 18px;
    text-align: right;
}

.myAdds-wrapper .card .card-item .separator {
    margin: 8px 0;
    border: 0.5px solid #014245;
}

.myAdds-wrapper .card .card-item .property-type-text {
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    text-align: left;
    overflow: hidden;
    width: 100%;
    height: 22px;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.myAdds-wrapper .card .main-image {
    height: 160px;
    width: 100%;
    border-radius: 4px;
}