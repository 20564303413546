.home-photographer .header-photographer-content {
    justify-content: flex-start;
    flex-direction: row;
    background-size: cover;
    background-position: center;
    background-image: url("https://d3kuhob3u5bpy2.cloudfront.net/d86fe9c3-60a8-48cb-9c1b-9b48d522641b.png"), linear-gradient(180deg, #EFF8FE 80%, #FFFFFF 100%);
    display: flex;
    width: 100%;
}

.home-photographer .header-photographer-content .title {
    font-family: 'Poppins';
    font-size: 35px;
    font-weight: 700;
    line-height: 40px;
    text-align: left;
    color: rgba(1, 35, 36, 1);
    margin-bottom: 40px;
}

.home-photographer .header-photographer-content .title-sub {
    color: rgba(1, 35, 36, 1);
    font-family: 'Poppins';
    font-size: 30px;
    font-weight: 700;
    line-height: 40px;
    text-align: left;
    margin-bottom: 40px;
}

.home-photographer .header-photographer-content .text {
    color: rgba(1, 35, 36, 1);
    font-family: 'Poppins';
    font-size: 25px;
    line-height: 40px;
    text-align: left;
}

.home-photographer .header-photographer-content .part-left {
    padding: 100px 50px;
}

.home-photographer .header-photographer-content .part-right {
    transform: scale(0.7);
    margin-top: -40px;
}

.home-photographer .why-abidors {
    margin: 0 70px 30px 70px;
}

.home-photographer .why-abidors .content .content-item {
    box-shadow: 4px 10px 30px 0px rgba(0, 0, 0, 0.06);
    background: rgba(255, 255, 255, 1);
    padding: 20px 40px;
    margin-bottom: 10px;
    border-radius: 30px;
}

.home-photographer .why-abidors .content .content-item img {
    float: left;
    margin-right: 25px;
    width: 20%;
}

.home-photographer .why-abidors .content .content-item .content-text {
    float: left;
    width: 71%;
}

.home-photographer .why-abidors .content .content-item .content-text .title {
    font-family: 'Poppins';
    font-size: 20px;
    font-weight: 700;
    line-height: 40px;
    text-align: left;
}

.home-photographer .why-abidors .content .content-item .content-text .text {
    font-family: 'Poppins';
    font-size: 18px;
    font-weight: 400;
    line-height: 40px;
    text-align: justify;
    padding: 0;
}

.home-photographer .why-abidors .content .content-item .content-text .button-view {
    font-family: 'Poppins';
    font-size: 18px;
    font-weight: 700;
    text-align: center;
    padding: 10px 30px;
    border-radius: 5px;
    color: #fff;
    background-color: #00bcd4;
    text-transform: none;
    float: right;
}

.home-photographer .why-abidors .content .content-item .content-text .button-view:hover {
    background-color: rgba(0, 188, 212, 0.8);
}

.home-photographer .set-up-you-account-and-add-your-services {
    text-align: center;
}

.home-photographer .set-up-you-account-and-add-your-services .col {
    float: none !important;
    display: inline-block;
    margin: 0 auto;
    padding: 0 10px;
}

.home-photographer .set-up-you-account-and-add-your-services .title {
    font-family: 'Poppins';
    font-size: 30px;
    font-weight: 700;
    margin-bottom: 20px;
    text-align: center;
    color: rgba(0, 188, 212, 1);
    padding: 0 200px;
}

.home-photographer .set-up-you-account-and-add-your-services .content {
    background: rgba(249, 249, 249, 0.5);
    margin-bottom: 10px;
    padding: 15px 10px;
    border-radius: 15px;
}

.home-photographer .set-up-you-account-and-add-your-services .content:hover {
    background: rgba(249, 249, 249, 1);
}

.home-photographer .set-up-you-account-and-add-your-services .content .content-left {
    float: left;
    width: 11%;
    margin-right: 15px;
}

.home-photographer .set-up-you-account-and-add-your-services .content .content-right {
    float: left;
    width: 80%;
}

.home-photographer .set-up-you-account-and-add-your-services .content .content-right .content-title {
    font-family: 'Poppins';
    font-size: 20px;
    font-weight: 700;
    line-height: 30px;
    text-align: left;
    margin-bottom: 0;
}

.home-photographer .set-up-you-account-and-add-your-services .content .content-right .content-text {
    font-family: 'Poppins';
    font-size: 15px;
    color: rgba(255, 0, 0, 1);
    font-weight: 700;
    line-height: 30px;
    text-align: left;
    margin-bottom: 0;
}

.home-photographer .my-clients {
    text-align: center;
}

.home-photographer .my-clients .col {
    float: none !important;
    display: inline-block;
    margin: 0 auto;
    padding: 0 10px;
    vertical-align: middle;
}

.home-photographer .my-clients .title {
    font-family: 'Poppins';
    font-size: 35px;
    font-weight: 700;
    line-height: 40px;
    margin-bottom: 10px;
}

.home-photographer .my-clients .title-sub {
    font-family: 'Poppins';
    font-size: 20px;
    font-weight: 400;
    line-height: 35px;
    padding: 0 100px;
}

.home-photographer .my-clients .active-customers-and-successfully-performed-services {
    margin: 0 200px;
}

.home-photographer .my-clients .active-customers-and-successfully-performed-services .content {
    border: 5px solid rgba(0, 188, 212, 1);
    border-radius: 30px;
    padding: 20px 0;
    margin-bottom: 30px;
}

.home-photographer .my-clients .active-customers-and-successfully-performed-services .content .title-content {
    font-family: 'Poppins';
    font-size: 30px;
    font-weight: 700;
    line-height: 40px;
    margin-bottom: 0;
}

.home-photographer .my-clients .active-customers-and-successfully-performed-services .content .number {
    font-family: 'Poppins';
    font-size: 20px;
    line-height: 30px;
    margin-bottom: 0;
    color: rgba(255, 0, 0, 1);
    font-weight: 700;
    margin-bottom: 0;
}

.home-photographer .my-clients .active-customers-and-successfully-performed-services .content .number.up {
    color: rgba(0, 128, 0, 1);
}

.home-photographer .my-clients .active-customers-and-successfully-performed-services .content .hr {
    position: relative;
    height: 40px;
}

.home-photographer .my-clients .active-customers-and-successfully-performed-services .content .hr:after {
    content: "";
    position: absolute;
    bottom: 0;
    top: 50%;
    left: 50%;
    border-left: 1px solid rgba(0, 0, 0, 1);
    transform: translate(-50%);
}

.home-photographer .my-clients .active-customers-and-successfully-performed-services .content .text {
    font-family: 'Poppins';
    font-size: 20px;
    font-weight: 400;
    line-height: 30px;
    margin-right: 20px;
}

.home-photographer .last-added .title {
    font-family: 'Poppins';
    font-size: 40px;
    font-weight: 600;
    line-height: 60px;
    text-align: center;
}

@media screen and (max-width: 1321px) {}

@media screen and (max-width: 1160px) {}

@media screen and (max-width: 1000px) {}

@media screen and (max-width: 991px) {

    .home-photographer .header-photographer-content .title {
        font-size: 30px;
        margin-bottom: 10px;
    }

    .home-photographer .header-photographer-content .title-sub {
        font-size: 25px;
        margin-bottom: 10px;
    }

    .home-photographer .header-photographer-content .text {
        font-size: 20px;
    }

    .home-photographer .header-photographer-content .part-left {
        padding: 20px;
    }

    .home-photographer .header-photographer-content .part-right {
        transform: scale(1);
        margin-top: 30px;
    }

    .home-photographer .why-abidors {
        margin: 0 30px 30px 30px;
    }

    .home-photographer .why-abidors .content .content-item {
        padding: 15px 20px;
    }

    .home-photographer .why-abidors .content .content-item .content-text {
        width: 76%;
    }

    .home-photographer .why-abidors .content .content-item .content-text .text {
        line-height: 30px;
    }

    .home-photographer .my-clients .title {
        font-size: 30px;
    }

    .home-photographer .my-clients .title-sub {
        line-height: 30px;
        padding: 0 20px;
    }

    .home-photographer .my-clients .active-customers-and-successfully-performed-services {
        margin: 0 20px;
        padding: 10px 0;
    }


    .home-photographer .my-clients .active-customers-and-successfully-performed-services .cols {
        margin: 0;
    }

    .home-photographer .my-clients .active-customers-and-successfully-performed-services .content {
        padding: 10px 0;
    }

    .home-photographer .my-clients .active-customers-and-successfully-performed-services .content .title-content {
        font-size: 25px;
    }

    .home-photographer .my-clients .active-customers-and-successfully-performed-services .content .text {
        font-size: 18px;
    }
}

@media screen and (max-width: 767px) {
    .home-photographer .header-photographer-content .part-right {
        transform: scale(1);
        margin: 0 auto;
    }

    .home-photographer .header-photographer-content .col.col7of12 {
        width: 100%;
    }

    .home-photographer .header-photographer-content .col.col5of12 {
        width: 100%;
    }

    .home-photographer .why-abidors {
        margin: 30px 10px;
    }

    .home-photographer .why-abidors .content .content-item {
        padding: 20px 10px;
    }

    .home-photographer .why-abidors .content .content-item img {
        float: none;
        width: 80%;
        margin-right: 0;
    }

    .home-photographer .why-abidors .content .content-item .content-text .text {
        text-align: center;
    }

    .home-photographer .why-abidors .content .content-item .content-text .title {
        text-align: center;
    }

    .home-photographer .why-abidors .content .content-item .content-text {
        width: 100%;
    }

    .home-photographer .why-abidors .content .content-item .content-text .button-view {
        float: none;
        display: block;
        text-align: center;
        margin: 0 auto;
    }

    .home-photographer .my-clients .active-customers-and-successfully-performed-services .content .text {
        margin-right: 0;
    }

    .home-photographer .last-added .title {
        font-size: 30px;
        font-weight: 600;
        line-height: 45px;
    }
}