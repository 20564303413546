.dialog-paymentSuccess {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  height: fit-content;
  &-title {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    > button {
      background-color: transparent;
      border: none;
      margin: 21px;
      height: 21px;
      width: 21px;
      cursor: pointer;
    }
  }
  &-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    font-family: "Roboto", sans-serif;
    margin-bottom: 56px;
    > img {
      margin-bottom: 19.5px;
      height: 115px;
      width: 115px;
    }
    > h3 {
      margin: 0;
      padding: 0;
      font-style: normal;
      font-weight: bold;
      font-size: 36px;
      line-height: 42px;
      text-align: center;
      color: #000000;
    }
    > p {
      margin: 0;
      padding: 0;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 19px;
      text-align: center;
      color: #000000;
      margin-bottom: 16px;
    }
    > button {
      padding: 12px 32px;
      border: 1px solid #000000;
      box-sizing: border-box;
      border-radius: 12px;
      background-color: transparent;
      margin: 8px 0;
    }
  }
}
