.dialog-seller-virtual-meeting-cancel-request { text-align: center; }
.dialog-seller-virtual-meeting-cancel-request-content { padding: 64px 84px; }
.dialog-seller-virtual-meeting-cancel-request-content .title-header { padding: 0; margin-bottom: 8px; }
.dialog-seller-virtual-meeting-cancel-request-content .title-header .title { display: inline-block; font-size: 48px; line-height: 52px; font-family: Lora; font-weight: 700; }
.dialog-seller-virtual-meeting-cancel-request-content .title-header .close-icon { position: absolute; top: 20px; right: 20px; cursor: pointer; }

.dialog-seller-virtual-meeting-cancel-request-content .content { padding: 0; overflow-x: hidden; }
.dialog-seller-virtual-meeting-cancel-request-content .content .sub-title { font-size: 18px; line-height: 24px; font-weight: 400; margin-bottom: 24px; font-family: Source Sans Pro; padding: 0 40px; }

.dialog-seller-virtual-meeting-cancel-request-content .content .actions { margin-top: 8px; display: flex; align-items: center; justify-content: center; }
.dialog-seller-virtual-meeting-cancel-request-content .content .actions .button-cancel { height: 45px; width: 125px; padding: 15px 32px; background: #00BCD4; border-radius: 4px; color: #fff; text-transform: uppercase; margin-right: 24px; font-size: 14px; line-height: 18px; font-family: Source Sans Pro; font-weight: 700; }
.dialog-seller-virtual-meeting-cancel-request-content .content .actions .button-close { height: 45px; width: 125px; padding: 15px 32px; border: 2px solid #00BCD4; border-radius: 4px; color: #000; text-transform: uppercase; font-size: 14px; line-height: 18px; font-family: Source Sans Pro; font-weight: 700;  }