.vissualPage-wrapper {
    display: flex;
    flex-direction: column;
    margin-bottom: 40px;
    overflow: hidden;
}

.vissualPage-wrapper .stepper-container {
    margin-top: 64px;
    display: flex;
    justify-content: center;
}

.vissualPage-wrapper .photo-video-upload {
    margin-top: 32px;
    display: flex;
    flex-direction: column;
    font-style: normal;
}

.vissualPage-wrapper .photo-video-upload .sub-section-wrapper {
    padding: 0px 32px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 32px;
    /* justify-content: space-evenly; */
}

.vissualPage-wrapper .photo-video-upload .sub-section {
    padding: 24px;
    background: #F8F8F8;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 4px;
    justify-content: center;
    text-align: center;
}

.vissualPage-wrapper .photo-video-upload .sub-section>img {
    width: 76.67px;
    height: 69px;
}

.vissualPage-wrapper .photo-video-upload .sub-section>h3 {
    font-family: Lora;
    font-size: 24px;
    font-weight: 700;
    line-height: 26px;
    margin-top: 16px;
}

.vissualPage-wrapper .photo-video-upload .sub-section>p {
    font-family: Source Sans Pro;
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;
    width: 389px;
    margin-top: 16px;
}

.vissualPage-wrapper .photo-video-upload .sub-section>button {
    margin-top: 24px;
}

.vissualPage-wrapper .photo-video-upload .button-wrapper>button {
    margin: 0px 10px;
}

.vissualPage-wrapper .with-phone-not-working {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 64px;
    font-style: normal;
}

.vissualPage-wrapper .with-phone-not-working>h1 {
    font-family: Lora;
    font-size: 32px;
    font-weight: 700;
    line-height: 32px;
}

.vissualPage-wrapper .with-phone-not-working>p {
    font-family: Source Sans Pro;
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;
    width: 906px;
    text-align: center;
    margin-top: 16px;
    margin-bottom: 24px;
}

.vissualPage-wrapper .with-phone-not-working>button {
    margin-bottom: 128px;
}

.vissualPage-wrapper .buttons-wrapper {
    margin-top: 24px;
    text-align: center;
}

.vissualPage-wrapper .buttonFilled {
    background-color: #00bcd4;
    border: 2px solid #00bcd4;
    font-family: Source Sans Pro;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 18px;
    color: white;
    padding: 15px 32px 15px 32px;
    border-radius: 4px;
    text-transform: uppercase;
}

.vissualPage-wrapper .buttonFilledDisabled {
    font-family: Source Sans Pro;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 18px;
    color: white;
    padding: 15px 32px 15px 32px;
    border-radius: 4px;
    text-transform: uppercase;
}

.vissualPage-wrapper .buttonFilled:hover {
    border: 2px solid #00bcd4;
    background-color: #00bcd4;
}

.vissualPage-wrapper .buttonUnfilled {
    border-radius: 4px;
    margin-right: 25px;
    padding: 15px 32px 15px 32px;
    border: 2px solid #00bcd4;
    font-family: Source Sans Pro;
    font-size: 14px;
    font-style: normal;
    color: #000;
    font-weight: 700;
    line-height: 18px;
    background-color: transparent;
    text-transform: uppercase;
}

.vissualPage-wrapper .buttonUnfilled:hover {
    border: 2px solid #00bcd4;
}

.vissualPage-wrapper .with-phone-not-working .sub-section-button {
    padding: 19px 48px;
    background: #00BCD4;
    border-radius: 4px;
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
    text-transform: uppercase;
    color: #FFFFFF;
}

@media screen and (max-width: 1000px) { 
    .vissualPage-wrapper .photo-video-upload .sub-section-wrapper{
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .vissualPage-wrapper .with-phone-not-working > p{
        width: 100% !important;
    }

    .vissualPage-wrapper .with-phone-not-working > h1{
        text-align: center; 
    }
}

@media screen and (max-width: 630px) {

    .vissualPage-wrapper{
        padding: 0 20px !important;
    }
	.vissualPage-wrapper .stepper {
		margin-top: 32px !important;
		margin-bottom: 32px !important;
	}

	.vissualPage-wrapper .createAnAddFlow .step-item.currentStep>div {
		width: 70px !important;
		height: 70px !important;
	}

	.vissualPage-wrapper .createAnAddFlow .currentStep>div>p {
		font-size: 40px !important;
	}

	.vissualPage-wrapper .createAnAddFlow .step-item>div {
		width: 55px !important;
		height: 55px !important;
	}

	.vissualPage-wrapper .createAnAddFlow .step-item > div > p {
		font-size: 24px !important;
	}

	.vissualPage-wrapper .createAnAddFlow .dashed-line {
		margin-bottom: 49px !important;
	  }

	.vissualPage-wrapper .createAnAddFlow .dashed-line {
		width: 46px;
	}

}

@media screen and (max-width: 570px) {
    .vissualPage-wrapper .step-item>p {
        font-size: 15px !important
    }
}

@media screen and (max-width: 500px) {
    .vissualPage-wrapper .createAnAddFlow>h1 {
        font-size: 31px !important;
        line-height: 33px !important;
    }

    .vissualPage-wrapper .createAnAddFlow .step-wrapper {
        margin-top: 16px !important;
    }

    .vissualPage-wrapper .createAnAddFlow .step-item.currentStep>div {
        width: 60px !important;
        height: 60px !important;
    }

    .vissualPage-wrapper .createAnAddFlow .step-item>div {
        width: 45px !important;
        height: 45px !important;
    }

    .vissualPage-wrapper .createAnAddFlow .step-item>div>p {
        font-size: 20px !important;
    }

    .vissualPage-wrapper .createAnAddFlow .dashed-line {
        width: 30px;
    }

    .vissualPage-wrapper .photo-video-upload .sub-section > p{
        width: auto !important;
    }

    .vissualPage-wrapper .photo-video-upload .sub-section-wrapper{
        padding: 0 !important;
    }
}

@media screen and (max-width: 450px) {
    .vissualPage-wrapper .createAnAddFlow .step-item>p {
        font-size: 12px !important;
    }
}

@media screen and (max-width: 400px) {
    .vissualPage-wrapper .createAnAddFlow .currentStep {
        margin-right: 0px !important;
    }

    .vissualPage-wrapper .createAnAddFlow>h1 {

        font-size: 30px !important;
        line-height: 32px !important;
    }

    .vissualPage-wrapper .createAnAddFlow .dashed-line {
        width: 22px;
    }

    .vissualPage-wrapper .createAnAddFlow .step-item.currentStep>div {
        width: 50px !important;
        height: 50px !important;
    }

    .vissualPage-wrapper .createAnAddFlow .step-item>div {
        width: 30px !important;
        height: 30px !important;
        align-items: baseline;
    }

    .vissualPage-wrapper .createAnAddFlow .step-item>div>p {
        font-size: 14px !important;
    }

    .vissualPage-wrapper .createAnAddFlow .step-item>p {
        font-size: 13px !important;
    }

    .vissualPage-wrapper .createAnAddFlow .dashed-line {
        width: 15px !important;
    }

    .vissualPage-wrapper .buttonUnfilled {
        margin-right: 0px !important;
    }

}

@media screen and (max-width: 400px) {
    .vissualPage-wrapper .buttons-wrapper {
        display: flex;
        flex-direction: column;
        gap: 20px;
    }
}