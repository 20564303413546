.answer {
    margin-top: 32px;
}

.answer .title-sub {
    margin: 0 250px 32px 250px;
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 24px;
    text-align: center;
    color: #000000;
}

.answer .content .title {
    margin-bottom: 8px;
    font-family: Lora;
    font-style: normal;
    font-weight: bold;
    font-size: 32px;
    line-height: 32px;
    text-align: center;
    color: #000000;
}

.answer .content .question .text {
    margin-bottom: 24px;
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 24px;
    text-align: center;
    color: #000000;
}

.answer .content .question .actions {
    margin-bottom: 32px;
    text-align: center;
}

.answer .content .question .actions .made-a-final-offer {
    margin-right: 25px;
    padding: 10px;
    background: #00BCD4;
    border-radius: 4px;
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 18px;
    text-transform: uppercase;
    color: #FFFFFF;
}

.answer .content .question .actions .did-not-make-an-offer {
    padding: 9px;
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 18px;
    text-transform: uppercase;
    color: #000000;
    background-color: #fff;
    border: 1px solid #00BCD4;
    border-radius: 4px;
}

.answer .content .question .actions img {
    margin-right: 6px;
}

.answer .content .price {
    margin-bottom: 32px;
}

.answer .content .price .price-question {
    margin-bottom: 8px;
    text-align: left !important;
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 24px;
}

.answer .content .price .price-number {
    display: inline-block;
    padding: 4px 8px;
    background: #E0F7FA;
    border-radius: 4px;
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 32px;
    text-transform: uppercase;
    color: #000000;
    width: 170px;
    border: none;
}

.answer .content .price .price-number.error {
    border: 1px solid #F32013;
}

.answer .content .radio-buttons {
    margin-bottom: 32px;
    text-align: left;
}

.answer .content .radio-buttons .radio-buttons-question {
    margin-bottom: 10px;
    text-align: left !important;
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 24px;
    color: #000000;
}

.answer .content .radio-buttons .radio-buttons-group {
    color: green;
}

.answer .content .radio-buttons .radio-button-label {
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 12px;
    color: #000000;
}

.answer .content .buttons-action {
    margin-bottom: 20px;
    display: flex;
    justify-content: flex-start;
}

.answer .content .buttons-action .continue-btn {
    background: #00BCD4;
    padding: 15px 32px;
    border-radius: 4px;
    margin-right: 24px;
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
    text-transform: uppercase;
    color: #FFFFFF;
}

.answer .content .buttons-action .continue-btn:disabled {
    background-color: rgb(235, 235, 228);
    color: rgb(84, 84, 84);
}

.answer .content .buttons-action .check-answers-btn {
    border: 2px solid #00BCD4;
    padding: 15px 32px;
    border-radius: 4px;
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
    text-transform: uppercase;
    color: #000000;
}

.answer .content .checkbox {
    margin-bottom: 28px;
}

.answer .content .checkbox label {
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.4px;
    color: #000000;
}

.answer .content .what-happened-during {
    margin-bottom: 64px;
}

.answer .content .what-happened-during .what-happened-during-title {
    margin-bottom: 24px;
    font-family: Lora;
    font-style: normal;
    font-weight: bold;
    font-size: 32px;
    line-height: 32px;
    text-align: center;
    color: #000000;
}

.answer .content .what-happened-during .did-not-make-an-offer {
    display: block;
    margin: 0 auto 32px auto;
    padding: 15px 25px;
    border: 1px solid #00BCD4;
    border-radius: 4px;
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 18px;
    text-transform: uppercase;
    color: #000000;
}

.answer .content .what-happened-during .did-not-make-an-offer img {
    margin-right: 10px;
}

.answer .content .what-happened-during .what-happened-during-text {
    margin: 0 120px 24px 120px !important;
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 18px;
    text-align: center;
    color: #000000;
}

.answer .content .what-happened-during .comment {
    margin: 0 120px 24px 120px;
}

.answer .content .what-happened-during .comment-title {
    margin-bottom: 8px;
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: 900;
    font-size: 18px;
    line-height: 22px;
    text-transform: uppercase;
    color: #000000;
}

.answer .content .what-happened-during .field {
    width: 100%
}

.answer .content .what-happened-during .share {
    display: block;
    margin: 0 auto;
    padding: 15px 32px;
    background: #00BCD4;
    border-radius: 4px;
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 18px;
    text-transform: uppercase;
    color: #FFFFFF;
}

.comment-dialog {
    text-align: center;
}

.comment-dialog-content {
    padding: 64px 84px;
}

.comment-dialog-content .title-header {
    padding: 0;
    margin-bottom: 8px;
}

.comment-dialog-content .title-header .title {
    display: inline-block;
    font-size: 48px;
    line-height: 52px;
    font-family: Lora;
    font-weight: 700;
}

.comment-dialog-content .title-header .close-icon {
    position: absolute;
    top: 20px;
    right: 20px;
    cursor: pointer;
}

.comment-dialog-content .content {
    padding: 0 24px;
}

.comment-dialog-content .content .sub-title {
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 24px;
}

.comment-dialog-content .content .button-search {
    display: block;
    margin: 0 auto;
    padding: 15px 32px;
    background: #00BCD4;
    border-radius: 4px;
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 18px;
    text-transform: uppercase;
    color: #FFFFFF;
}

.oposite-side-answer-dialog {
    text-align: center;
    cursor: default;
}

.oposite-side-answer-dialog-content {
    padding: 64px;
}

.oposite-side-answer-dialog-content .title-header {
    padding: 0 100px;
    margin-bottom: 24px;
}

.oposite-side-answer-dialog-content .title-header-no-answer {
    padding: 0 40px;
}

.oposite-side-answer-dialog-content .title-header .title,
.oposite-side-answer-dialog-content .title-header-no-answer .title {
    font-family: 'Lora';
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 32px;
}

.oposite-side-answer-dialog-content .title-header .close-icon,
.oposite-side-answer-dialog-content .title-header-no-answer .close-icon {
    position: absolute;
    top: 20px;
    right: 20px;
    cursor: pointer;
}

.oposite-side-answer-dialog-content .content {
    padding: 0 80px;
}

.oposite-side-answer-dialog-content .content .group {
    margin-bottom: 32px;
}

.oposite-side-answer-dialog-content .content .group .label {
    font-family: 'Lora';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 26px;
    color: #000000;
    margin-bottom: 8px;
}

.oposite-side-answer-dialog-content .content .group .text {
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    color: #000000;
}

.oposite-side-answer-dialog-content .content .group .price {
    padding: 4px 8px;
    background: #E0F7FA;
    border-radius: 4px;
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 700;
    font-size: 36px;
    line-height: 45px;
    text-transform: uppercase;
    color: #000000;
}

.oposite-side-answer-dialog-content .content .group .empty-value {
    width: 12px;
    height: 3px;
    background: #000000;
    margin: 0 auto;
}

.oposite-side-answer-dialog-content .content .button-close {
    padding: 15px 32px;
    background: #00BCD4;
    border-radius: 4px;
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
    text-transform: uppercase;
    color: #FFFFFF;
}

@media screen and (max-width: 1230px) {
    .answer .content{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
}

@media screen and (max-width: 665px) {

    .answer .content .question .actions {
        display: flex;
        flex-direction: column;
        gap: 20px;
    }

    .answer .content .question .actions .made-a-final-offer {
        margin-right: 0px;
    }
}

@media screen and (max-width: 500px) {
    .answer .content .buttons-action{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 20px;
    }

    .answer .content .buttons-action .continue-btn {
        margin-right: 0px;
        width: 100%;
    }

    .oposite-side-answer-dialog-content {
        padding: 25px !important;
    }
}

@media screen and (max-width: 400px) {

    .oposite-side-answer-dialog-content {
        padding: 18px !important;
    }    

    .answer .content .question .text {
        text-align: justify !important;
    }

    .answer .content .buttons-action .continue-btn {
        font-size: 12px;
    }

    .answer .content .buttons-action .check-answers-btn {
        font-size: 12px;
    }

    .oposite-side-answer-dialog-content .title-header {
        padding: 0 15px;
    }

    .oposite-side-answer-dialog-content .title-header .title, .oposite-side-answer-dialog-content .title-header-no-answer .title {
        line-height: 20px;
    }
}