.addToHomeScreenBanner {
  position: fixed;
  bottom: 0px;
  background-color: #0097A7;
  color: #fff;
  border: none;
  padding: 15px;
  cursor: pointer;
  width: 100%;
  z-index: 11;
}
.addToHomeScreenBanner .main-banner-container{
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
}

.addToHomeScreenBanner .main-banner-container .banner-content {
    margin-right: 10px;
    margin-bottom: 0;
    font-size: 16px;
}

.addToHomeScreenBanner .main-banner-container .tips-content{
    margin-top: 15px;
    display: flex;
    width: 100%;
    height: 100%;
    background: #000;
}

.addToHomeScreenBanner .tips-content .close-button{
    position: absolute;
    display: flex;
    right: 10px;
}

.addToHomeScreenBanner .main-banner-container .showTips{
    padding: 5px;
    background-color: white;
    border-radius: 10px;
    color:#0097A7;
    padding-left: 8px;
    padding-right: 8px;
    margin-right: 10px;
}

.cancel-button{
    display: flex;
    width: 100%;
    flex-direction: row-reverse;
    margin-bottom: 10px;
}
.sub-title{
    font-family: Lora;
    font-size: 17px;
    font-weight: 700;
}