.complete-post-modal { 
    text-align: center;
    margin: 0 10px;
}

.complete-post-modal .complete-post-modal-content {
    padding: 10px 0;
}

.complete-post-modal .complete-post-modal-content .title-header .primary-image {
    width: 70px;
    height: 70px;
    margin-bottom: 10px;
}

.complete-post-modal .complete-post-modal-content .title-header .title {
    font-size: 35px;
    font-weight: 600;
    line-height: 40px;
    color: rgba(0, 0, 0, 1);
    margin-bottom: 10px;
}

.complete-post-modal .complete-post-modal-content .title-header .title-sub {
    font-size: 20px;
    font-weight: 400;
    line-height: 33px;
    margin-bottom: 0;
}

.complete-post-modal .complete-post-modal-content .title-header .close-icon { 
    position: absolute; top: 20px; right: 20px; cursor: pointer; 
}

.complete-post-modal .complete-post-modal-content .complete-post-modal-dialog-content { 
    padding: 0; overflow-x: hidden; 
}

.complete-post-modal .complete-post-modal-content .complete-post-modal-dialog-content .your-ad-is-published {
    margin-bottom: 10px;
}

.complete-post-modal .complete-post-modal-content .complete-post-modal-dialog-content .your-ad-is-published .title,
.complete-post-modal .complete-post-modal-content .complete-post-modal-dialog-content .be-a-virtual-assistant-earn-more-section .title {
    font-size: 25px;
    font-weight: 600;
    line-height: 40px;
    color: rgba(0, 0, 0, 1);
    margin-bottom: 0;
}

.complete-post-modal .complete-post-modal-content .complete-post-modal-dialog-content .your-ad-is-published .title-sub,
.complete-post-modal .complete-post-modal-content .complete-post-modal-dialog-content .be-a-virtual-assistant-earn-more-section .title-sub {
    font-size: 18px;
    font-weight: 400;
    line-height: 40px;
    color: rgba(0, 0, 0, 1);
    margin-bottom: 0;
}

.complete-post-modal .complete-post-modal-content .complete-post-modal-dialog-content .your-ad-is-published .promote-now-and-get-seen,
.complete-post-modal .complete-post-modal-content .complete-post-modal-dialog-content .be-a-virtual-assistant-earn-more-section .see-details {
    background: rgba(0, 188, 212, 1);
    color: #ffffff;
    font-size: 18px;
    font-weight: 700;
    line-height: 40px;
    width: 360px;
    padding: 5px 0;
    text-transform: none;
    margin-bottom: 20px;
    border-radius: 12px;
}

.complete-post-modal .complete-post-modal-content .complete-post-modal-dialog-content .your-ad-is-published .promote-now-and-get-seen img,
.complete-post-modal .complete-post-modal-content .complete-post-modal-dialog-content .be-a-virtual-assistant-earn-more-section .see-details img {
    width: 25px;
    height: 25px;
    margin-right: 20px;
}

.complete-post-modal .complete-post-modal-content .complete-post-modal-dialog-content .be-a-virtual-assistant-earn-more-section .see-details {
    border: 2px solid rgba(0, 188, 212, 1);
    background-color: #fff;
    color: rgba(0, 0, 0, 1);
}

@media screen and (max-width: 700px) {
    .complete-post-modal .complete-post-modal-content {
        padding: 10px;
    }

    .complete-post-modal .complete-post-modal-content .title-header .title {
        font-size: 25px;
    }

    .complete-post-modal .complete-post-modal-content .title-header .title-sub {
        font-size: 18px;
    }

    .complete-post-modal .complete-post-modal-content .complete-post-modal-dialog-content .your-ad-is-published .title, 
    .complete-post-modal .complete-post-modal-content .complete-post-modal-dialog-content .be-a-virtual-assistant-earn-more-section .title {
        font-size: 20px;
    }

    .complete-post-modal .complete-post-modal-content .complete-post-modal-dialog-content .your-ad-is-published .title-sub, 
    .complete-post-modal .complete-post-modal-content .complete-post-modal-dialog-content .be-a-virtual-assistant-earn-more-section .title-sub {
        font-size: 16px;
        line-height: 25px;
        margin-bottom: 15px;
    }

    .complete-post-modal .complete-post-modal-content .complete-post-modal-dialog-content .your-ad-is-published .promote-now-and-get-seen, 
    .complete-post-modal .complete-post-modal-content .complete-post-modal-dialog-content .be-a-virtual-assistant-earn-more-section .see-details {
        width: 100%;
        padding: 10px 5px;
        line-height: 25px;
    }

    .complete-post-modal .complete-post-modal-content .complete-post-modal-dialog-content .your-ad-is-published .promote-now-and-get-seen img, 
    .complete-post-modal .complete-post-modal-content .complete-post-modal-dialog-content .be-a-virtual-assistant-earn-more-section .see-details img {
        margin-right: 5px;
        width: 30px;
        height: 30px;
    }
}