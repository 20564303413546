.result-header-common {
    margin-bottom: 8px;
}

.result-header-common .result-title {
    float: left;
    margin-bottom: 8px;
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 24px;
    color: #000000;
}

.result-header-common .button-group {
    float: right;
}

.result-header-common .button-group .button {
    padding: 7px 12px;
    margin-left: 8px;
    font-size: 16px;
    line-height: 18px;
    color: #000;
    cursor: pointer;
    border-radius: 4px;
    border: none;
}

.result-header-common .button-group .button span {
    margin-right: 7px;
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 18px;
    color: #000000;
}

@media screen and (max-width: 999px) {
    .result-header-common .result-title {
        display: none;
    }
}