.details { display: flex; }
.details.last { margin-top: 24px; }
.details .icon { margin-right: 18px; align-self: flex-start; }
.details .current-details .title { margin-bottom: 8px; font-family: Lora; font-style: normal; font-weight: bold; font-size: 24px; line-height: 26px; }
.details .current-details .text { margin-bottom: 16px; font-family: Source Sans Pro; font-style: normal; font-weight: normal; font-size: 18px; line-height: 24px; }
.details .current-details .button-send { background: #00BCD4; border-radius: 0px 4px 4px 0px; padding: 19px 32px; font-family: Source Sans Pro; font-style: normal; font-weight: bold; font-size: 14px; line-height: 18px; text-transform: uppercase; color: #FFFFFF; }

.inspection-details { margin-top: 32px; margin-bottom: 24px; }
.inspection-details .inspection-end { font-family: Source Sans Pro; font-style: normal; font-weight: normal; font-size: 16px; line-height: 18px; }
.inspection-details .end-date { margin-top: 4px; font-family: Lora; font-style: normal; font-weight: bold; font-size: 32px; line-height: 32px; }

.session-actions .button-accept { margin-right: 24px; background: #00BCD4; border-radius: 4px; padding: 15px 32px; font-family: Source Sans Pro; font-style: normal; font-weight: bold; font-size: 14px; line-height: 18px; text-transform: uppercase; color: #FFFFFF; }
.session-actions .button-alternative { border: 2px solid#00BCD4; border-radius: 4px; padding: 15px 32px; font-family: Source Sans Pro; font-style: normal; font-weight: bold; font-size: 14px; line-height: 18px; text-transform: uppercase; color: #000000; }