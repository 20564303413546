.customer-analysis {
  margin-top: 50px;
  margin-bottom: 50px;
}

.customer-analysis .our-success {
  margin-top: 50px;
  color: #6c757d;
  font-size: 18px;
  font-weight: 600;
  font-family: 'Poppins';
}

.customer-analysis .proven-track {
  margin-top: 25px;
  padding-left: 20%;
  padding-right: 20%;
  font-family: "Poppins";
  font-style: normal;
  font-weight: bold;
  font-size: 40px;
  line-height: 60px;
  text-align: center;
}

.customer-analysis .small-divider {
  margin-top: 35px;
  width: 30%;
  margin: auto;
  color: rgb(156 163 175);
}

.customer-analysis .statistics {
  margin-top: 30px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 30px;
}

.customer-analysis .statistics .happy-customers-image {
  width: 400px;
  border-radius: 6px;
  object-fit: cover;
}
.customer-analysis .statistics .statistics-container {
   display: flex;
   flex-direction: row;
   gap: 20px;
}

.customer-analysis .statistics .statistics-container .column{
    display:  flex;
    flex-direction: column;
    gap: 20px;
}
.customer-analysis .statistics .statistics-container .column:nth-of-type(2){
    margin-top: 40px;
}

.customer-analysis .statistics .statistics-container .statistic-item {
  width: 200px;
  height: 180px;
  box-sizing: border-box;
  border-bottom: 1.5px solid #0097a7;
  background-color: whitesmoke;
  gap: 20px;
  display: flex;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}



.customer-analysis .statistics .statistics-container .statistic-item .count{
    font-size: 40px;
    line-height: 50px;
    font-weight: 700;
    font-family: "Poppins";

}
.customer-analysis .statistics .statistics-container .statistic-item .title{
    color: #6c757d;
    font-size: 20px;
    font-family: "Poppins";

}

@media (max-width: 1060px) {
  .customer-analysis {
    margin-top: 50px;
  }
  .customer-analysis {
    padding: 0;
  }
  .customer-analysis .statistics .happy-customers-image {
    width: 90%;
    height: auto;
  }
  .customer-analysis .proven-track {
    padding-left: 20px;
    padding-right: 20px;
  }
  .customer-analysis .statistics {
    padding-left: 20px;
    padding-right: 20px;
  }
  .customer-analysis .proven-track {
    font-size: 35px;
  }
  .customer-analysis .small-divider {
    width: 45%;
  }
  .customer-analysis .statistics  {
    display: flex;
    flex-direction: column;
    gap: 20px;
 }
}


@media (max-width: 590px) {
    .customer-analysis .statistics .statistics-container .column:nth-of-type(2){
        margin-top: 0px;
    }
    .customer-analysis .statistics .statistics-container{
        display: flex;
        flex-direction: column;
        width: 100%;
    }
.customer-analysis .statistics .statistics-container .statistic-item {
    width: 100%;
}
.customer-analysis .statistics {
    padding-left: 30px;
    padding-right: 30px;
}
.customer-analysis .statistics .happy-customers-image {
    width: 100%;
    height: auto;
  }
}