.profile-my-offers {
    padding: 24px;
}

.profile-my-offers .box-offer {
    padding: 16px;
    margin-bottom: 24px;
    background: #FFFFFF;
    border-radius: 12px;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px rgba(0, 0, 0, 0.12), 0px 1px 3px rgba(0, 0, 0, 0.2);
}

.profile-my-offers .box-offer.highlighted {
    border: 2px solid #00BCD4;
}

.profile-my-offers .box-offer:last-child {
    margin-bottom: 0;
}

.profile-my-offers .box-offer .offer-price-box {
    padding: 52px 20px;
    border-radius: 8px;
    background: #F4F4F4;
    height: 190px;
}

.profile-my-offers .box-offer .offer-price-box.accepted {
    background: #00838F;
    color: #fff;
}

.profile-my-offers .box-offer .offer-price-box.expired {
    background: #880808;
    color: #fff;
}

.profile-my-offers .box-offer .offer-price-box .title {
    font-size: 22px;
    line-height: 26px;
    font-weight: 700;
    margin-bottom: 0;
}

.profile-my-offers .box-offer .offer-price-box .price {
    font-size: 30px;
    line-height: 45px;
    font-weight: 700;
}

.profile-my-offers .box-offer .image {
    height: 190px;
    border-radius: 12px;
}

.profile-my-offers .box-offer .part {
    text-align: left;
}

.profile-my-offers .box-offer .part .combine {
    height: 65px;
}

.profile-my-offers .box-offer .part .combine .price {
    font-size: 36px;
    line-height: 45px;
    font-weight: 700;
    margin-bottom: 0;
    float: left;
}

.profile-my-offers .box-offer .part .combine .group-items {
    display: flex;
    margin-top: 8px;
    float: left;
}

.profile-my-offers .box-offer .part .combine .group-items.first {
    margin-top: 15px;
}

.profile-my-offers .box-offer .part .combine .group-items .text {
    margin-right: 6px;
    background: #E0F7FA;
    border-radius: 4px;
    padding: 3px 8px;
    font-size: 14px;
    line-height: 18px;
    font-weight: 700;
}

.profile-my-offers .box-offer .part .location {
    margin-bottom: 8px;
}

.profile-my-offers .box-offer .part .location .icon {
    margin-right: 4px;
}

.profile-my-offers .box-offer .part .location .text {
    font-size: 18px;
    line-height: 24px;
    font-weight: 400;
}

.profile-my-offers .box-offer .part .category-group {
    display: flex;
}

.profile-my-offers .box-offer .part .category-group .category {
    margin-right: 11px;
}

.profile-my-offers .box-offer .part .category-group .category:last-child {
    margin-right: 0;
}

.profile-my-offers .box-offer .part .category-group .category .icon {
    margin-right: 4px;
    vertical-align: middle;
}

.profile-my-offers .box-offer .part .category-group .category .icon.bottom {
    vertical-align: text-bottom;
}

.profile-my-offers .box-offer .part .category-group .category .text {
    font-size: 18px;
    line-height: 24px;
    font-weight: 400;
}

.profile-my-offers .box-offer .divider {
    margin: 8px 0;
    opacity: 0.5;
}

.profile-my-offers .box-offer .additional-details {
    display: flex;
    align-items: center;
}

.profile-my-offers .box-offer .additional-details .text {
    font-size: 18px;
    line-height: 21px;
    font-weight: 500;
    margin-right: 30px;
}

.profile-my-offers .box-offer .additional-details .group-items {
    display: flex;
    margin-right: 12px;
}

.profile-my-offers .box-offer .additional-details .group-items:last-child {
    margin-right: 0;
}

.profile-my-offers .box-offer .additional-details .group-items .icon {
    margin-right: 6px;
}

.profile-my-offers .box-offer .additional-details .group-items .sub-text {
    font-size: 18px;
    line-height: 24px;
    font-weight: 400;
}

.profile-my-offers .box-offer .actions {
    text-align: center;
}

.profile-my-offers .box-offer .actions .text {
    font-size: 14px;
    line-height: 20px;
    font-weight: 600;
    margin: 8px 0 0 0;
}

.profile-my-offers .box-offer .actions .text.accepted {
    margin: 8px 0 1px 0;
}

.profile-my-offers .box-offer .actions .hour {
    margin-bottom: 2px;
    font-size: 24px;
    line-height: 30px;
    font-weight: 700;
}

.profile-my-offers .box-offer .actions .buttons-group .button-accept {
    height: 48px;
    padding: 15px 32px;
    margin-bottom: 16px;
    background: #00BCD4;
    color: #fff;
    border-radius: 4px;
    font-size: 14px;
    line-height: 18px;
    font-weight: 700;
    text-transform: uppercase;
}

.profile-my-offers .button-accept {
    height: 48px;
    padding: 15px 32px;
    margin-bottom: 16px;
    background: #00BCD4;
    color: #fff;
    border-radius: 4px;
    font-size: 14px;
    line-height: 18px;
    font-weight: 700;
    text-transform: uppercase;
}

.profile-my-offers .title-no-offer {
    text-align: center;
    margin-bottom: 15px;
    font-size: 14px;
    font-weight: 400;
}

.profile-my-offers .button-accept:hover {
    background: #00BCD4;
    color: #fff;
}

.profile-my-offers .box-offer .actions .buttons-group .button-reject {
    height: 48px;
    padding: 15px 32px;
    border: 2px solid #00BCD4;
    color: #000;
    border-radius: 4px;
    font-size: 14px;
    line-height: 18px;
    font-weight: 700;
    text-transform: uppercase;
}

.profile-my-offers .box-offer .messages {
    text-align: left;
    padding: 20px;
    margin-top: 20px;
    display: block;
    width: 100%;
    background: #F8F8F8;
    border-radius: 4px;
}

.profile-my-offers .box-offer .messages .name {
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 14px;
    color: #656565;
}

.profile-my-offers .box-offer .show-hide-message {
    width: 200px;
    padding: 10px;
    background: #00838F;
    border-radius: 8px;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    color: #FFFFFF;
    float: right;
}


@media screen and (max-width:1200px) {
    .profile-my-offers {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap:40px;
    }

    .profile-my-offers .cols-full {
        display: flex !important;
        flex-direction: column !important;
        gap: 40px;
    }

    .profile-my-offers .col3of12 {
        width: 100% !important;
    }

    .profile-my-offers .col4of12 {
        width: 100% !important;
    }
    .profile-my-offers .col2of12 {
        width: 100% !important;
    }
}

@media screen and (max-width:700px) {

    .profile-my-offers {
        grid-template-columns: repeat(1, 1fr);
    }

    .profile-my-offers {
        padding: 16px;
    }

    .profile-my-offers .box-offer .offer-price-box {
        padding: 20px 10px;
        height: auto;
    }
}