.profile-buyer {
    margin: 32px 0;
}

.profile-buyer .header-content {
    display: flex;
}

.profile-buyer .header-content .photo-group {
    text-align: center;
    margin-left: 32px;
}

.profile-buyer .header-content .photo-group .id {
    font-size: 12px;
    line-height: 14px;
    margin-top: 7px;
    letter-spacing: 0.4px;
}

.profile-buyer .header-content .photo-group .avatar {
    width: 178px;
    height: 178px;
    background-size: cover !important;
    background-repeat: no-repeat !important;
    background-position: center !important;
}

.profile-buyer .header-content .photo-group .upload-image {
    display: block;
    border-radius: 50%;
    position: relative;
    text-align: center;
    width: 178px;
    height: 178px;
    margin: 0 auto 20px auto;
}

.profile-buyer .header-content .photo-group .upload-image img {
    border-radius: 50%;
    width: 178px;
    height: 178px;
}

.profile-buyer .header-content .photo-group .upload-image:hover div {
    width: 100%;
    height: 100%;
    background: gray;
    opacity: 0.8;
    border-radius: 50%;
    cursor: pointer;
}

.profile-buyer .header-content .photo-group .upload-image:hover div span {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
    z-index: 999;
}

.profile-buyer .header-content .photo-group .upload-image:hover div span::before {
    position: absolute;
    content: '';
    height: 50px;
    width: 2px;
    background-color: #fff;
    transform: rotate(45deg);
    left: 50%;
    top: 38%;
}

.profile-buyer .header-content .photo-group .upload-image:hover div span::after {
    position: absolute;
    content: '';
    height: 50px;
    width: 2px;
    background-color: #fff;
    transform: rotate(-45deg);
    left: 50%;
    top: 38%;
}

.profile-buyer .header-content .photo-group .upload-image:hover div div {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%) scale(0.4);
    content: url("https://d3kuhob3u5bpy2.cloudfront.net/a01f316d-3b21-4947-9ba3-32b514c7bde3.png");
    width: 178px;
    height: 178px;
}

.profile-buyer .header-content .user-details {
    margin-left: 24px;
}

.profile-buyer .header-content .user-details .title {
    font-size: 48px;
    line-height: 56px;
    margin-bottom: 8px;
}

.profile-buyer .header-content .user-details .header-title {
    display: flex;
    margin-bottom: 12px;
}

.profile-buyer .header-content .user-details .header-title .icon {
    margin-left: 10px;
}

.profile-buyer .header-content .user-details .additional-info .email {
    font-size: 18px;
    line-height: 21px;
    margin-bottom: 8px;
    font-weight: 400;
}

.profile-buyer .header-content .user-details .additional-info .phone {
    font-size: 18px;
    line-height: 21px;
    margin-bottom: 37px;
    font-weight: 400;
}

.profile-buyer .header-content .user-details .button-edit-profile .button {
    padding: 12px 28px 12px 32px;
    border: 2px solid #00BCD4;
    border-radius: 4px;
    font-size: 14px;
    line-height: 18px;
    font-weight: 700;
    text-transform: uppercase;
}

.profile-buyer .header-content .user-details .button-edit-profile .button:hover {
    background: #00BCD4;
    color: #fff;
}

.profile-buyer .header-content .user-details .button-edit-profile .button .icon {
    margin-top: -3px;
}

.profile-buyer .header-content .account-details {
    margin-left: auto;
    margin-right: 32px;
}

.profile-buyer .header-content .account-details .button {
    float: right;
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 18px;
    text-transform: uppercase;
    color: #0097A7;
}

.profile-buyer .profile-content {
    margin-top: 32px;
}

.profile-buyer .profile-content .root {
    flex-grow: 1;
    background: #F8F8F8;
    display: flex;
    border-radius: 12px;
    margin: 0 32px;
    position: relative;
}

.profile-buyer .profile-content .root .tabs {
    width: 15%;
    background: #E8E8E8;
    border-radius: 12px 0px 0px 12px;
}

.profile-buyer .profile-content .root .tabs .tab {
    border-radius: 12px 0px 0px 12px;
    text-transform: uppercase;
    font-size: 14px;
    line-height: 18px;
    font-family: Source Sans Pro;
    font-weight: 700;
}


.profile-buyer .profile-content .root .tabs .tab.separator-tab::before {
    content: '';
    position: absolute;
    height: 1px;
    background-color: #aaaaaa;
    width: 90%;
    top: 0px;
    left: 50%;
    transform: translateX(-50%);
    border-radius: 999px;
}

.profile-buyer .profile-content .root .tabs .tab.disabled {
    pointer-events: none;
    opacity: 0.2;
}

.profile-buyer .profile-content .root .tabs .tab.active-tab {
    background: #F8F8F8;
}

.profile-buyer .profile-content .root .tabs .tab.last-tab {
    position: absolute;
    bottom: 0;
    width: 100%;
}

.profile-buyer .profile-content .tab-panel {
    width: 100%;
    text-align: center;
    padding: 0 10px;
    min-height: 330px;
    z-index: 1;
}

.profile-buyer .profile-content .tab-panel::-webkit-scrollbar {
    width: 10px !important;
    background: #0097A7;
}

.profile-buyer .profile-content .tab-panel::-webkit-scrollbar-track {
    background: #fff;
    border-radius: 12px;
}

.profile-buyer .profile-content .tab-panel::-webkit-scrollbar-thumb {
    background-color: #0097A7;
    border-radius: 10px;
}

.profile-buyer .property-box {
    margin-top: 64px;
    padding: 0px 32px;
}

.profile-buyer .hire-lawyer-content {
    margin: 64px 235px;
    padding: 30px 100px;
    background: #F8F8F8;
    border-radius: 10px;
    text-align: center;
}

.profile-buyer .hire-lawyer-content .title {
    font-size: 32px;
    line-height: 32px;
    font-weight: 700;
    font-family: Lora;
    margin-bottom: 8px;
}

.profile-buyer .hire-lawyer-content .text {
    margin: 0 20px 24px 20px;
    font-size: 18px;
    line-height: 24px;
    font-family: Source Sans Pro;
    font-weight: 400;
}

.profile-buyer .hire-lawyer-content .box-content {
    margin-bottom: 24px;
}

.profile-buyer .hire-lawyer-content .box-content .box {
    padding: 15px;
    background: #F4F4F4;
    border-radius: 5px;
    text-align: center;
    height: 190px;
}

.profile-buyer .hire-lawyer-content .box-content .box .main-icon .icon {
    margin-bottom: 10px;
}

.profile-buyer .hire-lawyer-content .box-content .box .text-box {
    font-size: 16px;
    line-height: 18px;
    font-family: Source Sans Pro;
    font-weight: 400;
}

.profile-buyer .hire-lawyer-content .button {
    padding: 15px 32px;
    background: #00BCD4;
    color: #fff;
    border-radius: 4px;
    text-transform: uppercase;
    font-family: Source Sans Pro;
    font-size: 14px;
    line-height: 18px;
    font-weight: 700;
}

.profile-buyer .information-content {
    margin-bottom: 128px;
    text-align: center;
}

.profile-buyer .information-content .title {
    margin-bottom: 16px;
    font-size: 32px;
    line-height: 32px;
    font-family: Lora;
}

.profile-buyer .information-content .text {
    margin: 0px 385px 24px 385px;
    font-size: 18px;
    line-height: 24px;
    font-family: Source Sans Pro;
}

.profile-buyer .information-content .button {
    padding: 15px 32px;
    border: 2px solid #00BCD4;
    border-radius: 4px;
    text-transform: uppercase;
    font-family: Source Sans Pro;
    font-size: 14px;
    line-height: 18px;
    font-weight: 700;
}

.profile-buyer .information-content .button:hover {
    background: #00BCD4;
    color: #fff;
}

.profile-buyer .last-added {
    margin: 32px 0;
}


.profile-buyer .last-added .last-added-title {
    margin-bottom: 32px;
    font-size: 72px;
    line-height: 76px;
    font-weight: 500;
    font-family: Lora;
    text-align: center;
}

/* Deactivate Account Dialog */
.dialog-deactivate-account-buyer-content {
    padding: 22px;
    text-align: center;
    
}

.dialog-deactivate-account-buyer-content .title-header {
    padding: 0;
    margin-bottom: 16px;
}

.dialog-deactivate-account-buyer-content .title-header .title {
    font-family: Lora;
    font-style: normal;
    font-weight: bold;
    font-size: 32px;
    line-height: 32px;
    margin-top: 22px;
    padding: 0px 140px;
}

.dialog-deactivate-account-buyer-content .title-header .close-icon {
    float: right;
    display: inline-block;
    cursor: pointer;
    margin-top: -43px;
    margin-right: -17px;
}

.dialog-deactivate-account-buyer-content .content {
    padding: 0;
    overflow-x: hidden;
}

.dialog-deactivate-account-buyer-content .content .actions {
    margin-top: 24px;
}

.dialog-deactivate-account-buyer-content .content .actions .button-continue {
    margin-right: 24px;
    padding: 15px 32px;
    background: #F44336;
    border-radius: 4px;
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 18px;
    text-transform: uppercase;
    color: #FFFFFF;
}

.dialog-deactivate-account-buyer-content .content .actions .button-back {
    padding: 15px 32px;
    border: 2px solid #00BCD4;
    box-sizing: border-box;
    border-radius: 4px;
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 18px;
    text-transform: uppercase;
    color: #000000;
}

.profile-buyer .menu-burger {
    display: none;
}

.profile-buyer .close-tab {
    display: none;
}

.tabs-mobile-closed .MuiTabs-scrollable {
    display: none !important;
}

.display-tab-backdrop {
    opacity: 0.5;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 99;
    border-radius: 12px;
    display: none;
}

.display-tab-backdrop:hover {
    cursor: pointer;
}

.profile-buyer .profile-content .root .seller-side-drawer {
    display: none;
}


.dialog-deactivate-account-virtual-assistant-seller {
    margin: 0 10px;
}

.dialog-deactivate-account-virtual-assistant-seller-content {
    padding: 22px;
    text-align: center;
}

.dialog-deactivate-account-virtual-assistant-seller-content .title-header {
    padding: 0;
    margin-bottom: 16px;
}

.dialog-deactivate-account-virtual-assistant-seller-content .title-header .title {
    font-family: Lora;
    font-style: normal;
    font-weight: bold;
    font-size: 32px;
    line-height: 32px;
    margin-top: 22px;
    padding: 0px 140px;
}

.dialog-deactivate-account-virtual-assistant-seller-content .title-header .close-icon {
    cursor: pointer;
    float: right;
    margin-top: -24px;
}

.dialog-deactivate-account-virtual-assistant-seller-content .content {
    padding: 0;
    overflow-x: hidden;
}

.dialog-deactivate-account-virtual-assistant-seller-content .content .actions {
    margin-top: 24px;
}

.dialog-deactivate-account-virtual-assistant-seller-content .content .actions .button-continue {
    margin-right: 24px;
    padding: 15px 32px;
    background: #F44336;
    border-radius: 4px;
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 18px;
    text-transform: uppercase;
    color: #FFFFFF;
}

.dialog-deactivate-account-virtual-assistant-seller-content .content .actions .button-back {
    padding: 15px 32px;
    border: 2px solid #00BCD4;
    box-sizing: border-box;
    border-radius: 4px;
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 18px;
    text-transform: uppercase;
    color: #000000;
}

.dialog-deactivate-account-virtual-assistant-seller-content .button-send {
    height: 45px;
    padding: 15px 32px;
    background: #00BCD4;
    border-radius: 4px;
    color: #fff;
    text-transform: uppercase;
    margin-right: 25px;
    font-size: 14px;
    line-height: 18px;
    font-family: Source Sans Pro;
    font-weight: 700;
}

.dialog-deactivate-account-virtual-assistant-seller-content .button-send:hover {
    height: 45px;
    padding: 15px 32px;
    background: #00BCD4;
    border-radius: 4px;
    color: #fff;
    text-transform: uppercase;
    margin-right: 25px;
    font-size: 14px;
    line-height: 18px;
    font-family: Source Sans Pro;
}


@media screen and (max-width:768px){
.dashboard-virtual-assistant .assistant-header .main-title {
     margin: 64px 0px 32px 0px; 
     font-family: Lora; 
     font-style: normal; 
     font-weight: 500; 
     font-size: 40px; 
     line-height: 76px; 
    }

}

@media screen and (max-width:400px){
    .dashboard-virtual-assistant .root .tabs .tab { 
        padding: 16px 10px; 
        font-family: Source Sans Pro; 
        font-style: normal; 
        font-weight: bold; 
        font-size: 12px; 
        line-height: 18px; 
        text-transform: uppercase; 
        color: #000000; 
        opacity: 1; 
    }

    .dialog-deactivate-account-virtual-assistant-seller-content .title-header .title {
        padding: 0;
    }

}
.dialog-deactivate-account-virtual-assistant-seller {
    margin: 0 10px;
}

.dialog-deactivate-account-virtual-assistant-seller-content {
    padding: 22px;
    text-align: center;
}

.dialog-deactivate-account-virtual-assistant-seller-content .title-header {
    padding: 0;
    margin-bottom: 16px;
}

.dialog-deactivate-account-virtual-assistant-seller-content .title-header .title {
    font-family: Lora;
    font-style: normal;
    font-weight: bold;
    font-size: 32px;
    line-height: 32px;
    margin-top: 22px;
    padding: 0px 140px;
}

.dialog-deactivate-account-virtual-assistant-seller-content .title-header .close-icon {
    cursor: pointer;
    float: right;
    margin-top: -24px;
}

.dialog-deactivate-account-virtual-assistant-seller-content .content {
    padding: 0;
    overflow-x: hidden;
}

.dialog-deactivate-account-virtual-assistant-seller-content .content .actions {
    margin-top: 24px;
}

.dialog-deactivate-account-virtual-assistant-seller-content .content .actions .button-continue {
    margin-right: 24px;
    padding: 15px 32px;
    background: #F44336;
    border-radius: 4px;
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 18px;
    text-transform: uppercase;
    color: #FFFFFF;
}

.dialog-deactivate-account-virtual-assistant-seller-content .content .actions .button-back {
    padding: 15px 32px;
    border: 2px solid #00BCD4;
    box-sizing: border-box;
    border-radius: 4px;
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 18px;
    text-transform: uppercase;
    color: #000000;
}

.dialog-deactivate-account-virtual-assistant-seller-content .button-send {
    height: 45px;
    padding: 15px 32px;
    background: #00BCD4;
    border-radius: 4px;
    color: #fff;
    text-transform: uppercase;
    margin-right: 25px;
    font-size: 14px;
    line-height: 18px;
    font-family: Source Sans Pro;
    font-weight: 700;
}

.dialog-deactivate-account-virtual-assistant-seller-content .button-send:hover {
    height: 45px;
    padding: 15px 32px;
    background: #00BCD4;
    border-radius: 4px;
    color: #fff;
    text-transform: uppercase;
    margin-right: 25px;
    font-size: 14px;
    line-height: 18px;
    font-family: Source Sans Pro;
}

@media screen and (max-width:400px){

    .dialog-deactivate-account-virtual-assistant-seller-content .title-header .title {
        padding: 0;
    }

}


@media screen and (max-width: 1200px) {
    .profile-buyer .hire-lawyer-content {
        margin: 64px 130px;
    }
    .profile-buyer .profile-content .root .seller-side-drawer {
        width: fit-content;
        padding-left: 10px;
        padding-right: 10px;
    }

    
    .profile-buyer .profile-content .root .seller-side-drawer {
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        background: #EDEDED; 
        z-index: 2;
        border-radius: 12px 0px 0px 12px;
        display:block;
        width: 50%;
    }
    .seller-side-drawer .drawer-tabs {
        width: 100% !important;
      }
}

@media screen and (max-width: 1000px) {
    .profile-buyer .profile-content .root{
        margin: 0 5px;
    }

    .profile-buyer .profile-lawyer {
        padding: 0;
    }

    .profile-buyer .profile-content .root .desktop-tabs{
        display: none;
    }

    .profile-buyer .profile-content .root .tabs {
        width: 100%;
        background-color: transparent;
    }

    .profile-buyer .profile-content .root .seller-side-drawer .drawer-header .hide-menu {
        cursor: pointer;
        float: right;
    }

    .profile-buyer .menu-burger{
        display: block;
        cursor: pointer;
        margin-left: 10px;
        margin-top: 10px;
    }

    .profile-buyer .close-tab{
        display: block;
        position: absolute;
        left: 161px;
        top: 0px;
        z-index: 102;
    }

    .profile-buyer .close-tab:hover{
        cursor: pointer;
    }

    .profile-buyer .menu-burger:hover{
        cursor: pointer;
    }

    .profile-buyer .profile-content .root .tabs-mobile-closed {
        width: 60px !important;
    }

    .profile-buyer .profile-content .root .tabs-mobile-open {
        width: 200px !important;
        /* position: absolute; */
        height: 100%;
        z-index: 101;
        padding-top: 40px;
    }

    .profile-buyer .hire-lawyer-content {
        /* margin: 611px 50px 0px 50px; */
        margin: 50px 50px 0px 50px;
    }

    .profile-buyer .information-content .text {
        padding: 0 32px;
    }

    .profile-buyer .information-content .text {
        margin: 0px 200px 24px 200px;
    }

    .profile-buyer .information-content {
        margin: 50px 20px;
    }

    .profile-buyer .header-content .photo-group .upload-image {
        width: 120px !important;
        height: 120px !important;
    }

    .profile-buyer .header-content .user-details .title{
        font-size: 32px;
        line-height: 32px;
    }

    .display-tab-backdrop {
        display: block;
    }
 }

 
@media screen and(max-width:738px){
    .dialog-deactivate-account-buyer-content .content .sub-title {
        font-family: Source Sans Pro;
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        line-height: 24px;
        margin-bottom: 24px;
        padding: 0px !important;
    }
    
}

@media screen and (max-width:768px){
    .profile-buyer .header-content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
}

 @media screen and (max-width: 767px) {

    .profile-buyer .hire-lawyer-content {
        margin: 20px 50px 0px 50px;
    }

    .profile-buyer .information-content .text {
        margin: 0px 100px 24px 100px;
    }

    .profile-buyer .header-content .user-details .title{
        font-size: 25px !important;
        line-height: 28px !important;
        text-align: center;
    }

    .profile-buyer .header-content .user-details {
        margin-left: 10px;
    }

    .profile-buyer .header-content .photo-group {
        margin-left: 20px;
    }

    .profile-buyer .last-added .last-added-title {
        font-size: 36px;
    }
 }

@media screen and (max-width: 650px) {
    .profile-buyer .hire-lawyer-content .box-content {
        display: grid !important;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 20px;
    }

    .profile-buyer .hire-lawyer-content .box-content .col4of12 {
        width: 100% !important;

    }

    .profile-buyer .hire-lawyer-content {
        /* margin: 611px 20px 0px 20px; */
        margin: 20px 50px 0px 50px;
    }

    .profile-buyer .hire-lawyer-content {
        padding: 32px 50px;
    }

    .profile-buyer .profile-content {
        margin-top: 0px;
    }
}

@media screen and (max-width: 467px) {

    .profile-buyer .hire-lawyer-content .box-content {
        grid-template-columns: repeat(1, 1fr);
    }

    .profile-buyer .information-content .text {
        margin: 0px 20px 24px 20px;
    }

    .profile-buyer .hire-lawyer-content {
        margin: 32px 5px;
    }

    .profile-buyer .hire-lawyer-content .title {
        font-size: 25px;
    }

    .profile-my-offers .cols {
        margin-left: 0;
        margin-right: 0;
    }

    .profile-my-offers .cols .col {
        padding-left: 0;
        padding-right: 0;
    }

    .profile-my-offers {
        padding: 16px;
    } 

    .profile-my-offers .box-offer .offer-price-box {
        padding: 20px 10px;
    }

    .profile-my-offers .box-offer .offer-price-box .price {
        font-size: 28px;
        margin-bottom: 0;
    }

    .profile-my-offers .box-offer .offer-price-box {
        height: auto;
    }

    .profile-my-offers .box-offer .additional-details .text {
        margin-right: 0 !important;
    }

    .profile-my-offers .box-offer .actions .buttons-group .button-accept,
    .profile-my-offers .box-offer .actions .buttons-group .button-reject {
        padding: 15px 0 !important;
    }
}