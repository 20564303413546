.referral-tab {
  padding: 24px 20px;
  text-align: center;
}

.referral-tab .container-item {
  background-color: rgba(255, 255, 255, 1);
  border-radius: 10px;
  padding: 30px 20px;
}

.referral-tab .container-item .build-strong-network {
  background: rgba(248, 248, 248, 1);
  border-radius: 20px;
  margin-bottom: 30px;
  padding: 50px 25px;
}

.referral-tab .container-item .build-strong-network .title,
.referral-tab .container-item .build-strong-network .text {
  font-family: "Inter";
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
  text-align: center;
  color: rgba(0, 0, 0, 1);
}

.referral-tab .container-item .build-strong-network .title-sub {
  font-family: "Inter";
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-align: center;
  color: rgba(0, 0, 0, 0.75);
}

.referral-tab .container-item .build-strong-network .button-invite-va,
.referral-tab .container-item .no-virtual-assistant-invite .button-invite-va,
.referral-tab .container-item .build-strong-network .button {
  padding: 15px 50px 15px 50px;
  gap: 10px;
  border-radius: 10px;
  background: rgba(0, 188, 212, 1);
  font-family: 'Inter';
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
  color: #ffffff;
  text-transform: none;
  margin-right: 10px;
}

.referral-tab .container-item .build-strong-network .button-invite-va-second-button,
.referral-tab .container-item .no-virtual-assistant-invite .button-invite-va-second-button {
  padding: 12px 20px;
  gap: 10px;
  border-radius: 10px;
  background: rgba(0, 188, 212, 1);
}

.referral-tab .container-item .referral-boost-bonus {
  margin-bottom: 10px;
  text-align: center;
}

.referral-tab .container-item .referral-boost-bonus .title {
  font-family: "Inter";
  font-size: 30px;
  font-weight: 700;
  line-height: 24px;
  margin-bottom: 10px;
  color: rgba(0, 0, 0, 1);
}

.referral-tab .container-item .referral-boost-bonus .title-sub {
  font-family: "Inter";
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  margin-bottom: 0;
  color: rgba(0, 0, 0, 0.75);
}

.referral-tab .container-item .earn-extra-credit-for-active-referrals,
.referral-tab .container-item .maximize-your-earnings,
.referral-tab .container-item .invite-and-grow-your-network,
.referral-tab .container-item .earn-an-extra-for-growing-your-network {
  box-shadow: 0px 10px 25px 0px rgba(69, 69, 80, 0.1);
  background: rgba(255, 255, 255, 1);
  border-radius: 20px;
  padding: 18px 10px; 
  margin-bottom: 30px;
}

.referral-tab .container-item .earn-extra-credit-for-active-referrals .title,
.referral-tab .container-item .maximize-your-earnings .title,
.referral-tab .container-item .invite-and-grow-your-network .title,
.referral-tab .container-item .earn-an-extra-for-growing-your-network .title {
  font-family: "Inter";
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
  text-align: left;
  margin-bottom: 0;
  color: rgba(0, 0, 0, 1);
}

.referral-tab .container-item .earn-extra-credit-for-active-referrals .title-sub,
.referral-tab .container-item .maximize-your-earnings .title-sub,
.referral-tab .container-item .invite-and-grow-your-network .title-sub,
.referral-tab .container-item .earn-an-extra-for-growing-your-network .title-sub {
  font-family: "Inter";
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  color: rgba(101, 101, 117, 1);
  margin-bottom: 0;
}

.referral-tab .container-item .earn-extra-credit-for-active-referrals .book-image,
.referral-tab .container-item .invite-and-grow-your-network .book-image,
.referral-tab .container-item .earn-an-extra-for-growing-your-network .book-image {
  border: 1px solid rgba(0, 188, 212, 1);
  border-radius: 5px;
  padding: 5px;
  cursor: pointer;
}

.referral-tab .container-item .maximize-your-earnings .title {
  margin-bottom: 10px;
}

.referral-tab .container-item .maximize-your-earnings .title-sub {
  margin-bottom: 5px;
}

.referral-tab .container-item .maximize-your-earnings .read-the-book {
  font-family: "Inter" !important;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  text-align: left;
  color: rgba(0, 188, 212, 1);
  border: 2px solid rgba(0, 188, 212, 1);
  padding: 5px 20px;
  text-transform: none;
  display: block;
  text-align: left;
}

.referral-tab .container-item .maximize-your-earnings .read-the-book:hover {
  background-color: #ffffff;
}

.referral-tab .container-item .top-va-referral {
  margin-bottom: 20px;
}

.referral-tab .container-item .top-va-referral .grid-left,
.referral-tab .container-item .top-va-referral .grid-right {
  padding: 10px 30px;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px 2px 15px 0px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  width: 49%;
}

.referral-tab .container-item .top-va-referral .grid-left {
  float: left;
}
.referral-tab .container-item .top-va-referral .grid-right {
  float: right;
}


.referral-tab .container-item .top-va-referral .part-left {
  float: left;
  width: 59%;
}

.referral-tab .container-item .top-va-referral .part-right {
  float: right;
  width: 40%;
}

.referral-tab .container-item .top-va-referral .part-left .name-va {
  font-family: "Inter";
  font-size: 20px;
  font-weight: 700;
  line-height: 24.2px;
  text-align: left;
  margin-bottom: 0;
}

.referral-tab .container-item .top-va-referral .part-left .text-ref {
  font-family: "Inter";
  font-size: 14px;
  font-weight: 400;
  line-height: 16.94px;
  text-align: left;
  margin-bottom: 24px;
}

.referral-tab .container-item .top-va-referral .part-left .text {
  font-family: "Inter";
  font-size: 8px;
  font-weight: 400;
  line-height: 9.68px;
  text-align: left;
  color: rgba(101, 101, 117, 1);
  margin-bottom: 0;
}

.referral-tab .container-item .top-va-referral .part-left .price {
  font-family: "Inter";
  font-size: 25px;
  font-weight: 700;
  line-height: 30.26px;
  text-align: left;
  color: rgba(32, 32, 32, 1);
  margin-bottom: 0;
}

.referral-tab .container-item .top-va-referral .part-right .name-va {
  font-family: "Inter";
  font-size: 8px;
  font-weight: 400;
  line-height: 9.68px;
  text-align: right;
  color: rgba(101, 101, 117, 1);
  
}

.referral-tab .container-item .top-va-referral .part-right .price {
  font-family: "Inter";
  font-size: 16px;
  font-weight: 400;
  line-height: 21.78px;
  text-align: right;
  color: rgba(32, 32, 32, 1);
  margin-bottom: 0;
}


.referral-tab .container-item .top-va-referral .part-right .progressbar-container {
  position: relative;
  width: 67px;
  height: 67px;
  float: right;
}

.referral-tab .container-item .top-va-referral .part-right .progressbar-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

.referral-tab .container-item .top-va-referral .part-right .progressbar-text .price {
  font-family: "Inter";
  font-size: 14px;
  font-weight: 600;
  line-height: 16.94px;
  text-align: right;
  margin-bottom: 0;
  color: rgba(49, 49, 49, 1);
}

.referral-tab .container-item .top-va-referral .part-right .progressbar-text .text {
  font-family: "Inter";
  font-size: 5px;
  font-weight: 400;
  line-height: 6.05px;
  text-align: center;
  margin-bottom: 0;
  color: rgba(49, 49, 49, 1);
}

.referral-tab .no-virtual-assistant-invite .primary-image {
  margin-top: 70px;
  margin-bottom: 25px;
}

.referral-tab .no-virtual-assistant-invite .title {
  font-family: "Inter";
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
  text-align: center;
  margin-bottom: 25px;
}

.access-copy-referral-link .access-copy-referral-link-content .primary-image {
  margin-bottom: 40px;
}

.access-copy-referral-link .access-copy-referral-link-content .title {
  font-size: 25px;
  font-weight: 600;
  line-height: 37.5px;
  text-align: center;
  margin-bottom: 10px;
  color: rgba(0, 0, 0, 1) !important;
}

.access-copy-referral-link .access-copy-referral-link-content .sub-title {
  font-family: "Inter";
  font-size: 22px;
  font-weight: 400;
  line-height: 26.63px;
  text-align: center;
  color: rgba(0, 0, 0, 0.5);
  margin-bottom: 40px;
}

.access-copy-referral-link .access-copy-referral-link-content .sign-contract-button{
  border: 1px solid rgba(0, 188, 212, 1);
  background-color: rgba(0, 188, 212, 1);
  font-size: 25px;
  font-weight: 700;
  line-height: 37.5px;
  text-align: center;
  color: #ffffff;
  border-radius: 5px;
  text-transform: none;
  padding: 10px 110px;
}

.node-tree {
  text-align: left;
  width: 620px;
  position: relative;
}

.node-tree .content-part-left {
  position: relative;
  border-right: 4px solid rgba(0, 188, 212, 1);
  padding-right: 10px;
  width: 200px;
  float: left;
}

.node-tree .content-part-right {
  float: left;
  padding-left: 10px;
  margin-top: 10px;
}

.node-tree .content-part-left .image-name {
  display: flex;
}

.node-tree .content-part-left .image-name .primary-image {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 10px;
  object-fit: cover;
  z-index: 2;
}

.node-tree .content-part-left .image-name .name {
  font-family: "Inter";
  font-size: 20px;
  font-weight: 500;
  line-height: 24.2px;
  text-align: left;
  color: rgba(0, 0, 0, 1);
  margin-bottom: 0;
  margin-right: 10px;
  /* width: 80px; */
  /* white-space: nowrap; */
  /* overflow: hidden; */
  /* text-overflow: ellipsis; */
}

.node-tree .content-part-left .image-name .button-chat {
  background-color: rgba(0, 188, 212, 1);
  color: #ffffff;
  font-family: "Inter";
  font-size: 10px;
  font-weight: 700;
  line-height: 24px;
  text-align: left;
  text-transform: none;
  height: 28px;
  margin-bottom: 5px;
}

.node-tree .content-part-left .image-name .button-chat:hover {
  background-color: rgba(0, 188, 212, 1);
}

.node-tree .content-part-left .image-name .button-chat img {
  margin-right: 5px;
}

.node-tree .content-part-left .mountly-ear {
  margin-left: 40px;
}

.node-tree .content-part-left .mountly-ear .box {
  background: rgba(242, 242, 242, 1);
  padding: 3px;
  margin-bottom: 2px;
}

.node-tree .content-part-left .mountly-ear .box .title {
  font-family: "Inter";
  font-size: 8px;
  font-weight: 400;
  line-height: 14px;
  text-align: left;
  color: rgba(101, 101, 117, 1);
  margin-bottom: 0;
}

.node-tree .content-part-left .mountly-ear .box .price {
  font-family: "Inter";
  font-size: 14px;
  font-weight: 700;
  line-height: 14px;
  text-align: left;
  color: rgba(0, 0, 0, 1);
  margin-bottom: 0;
}

.node-tree .icon {
  position: absolute;
  top: 86%;
  left: 5%;
  z-index: 3;
}

.node-tree .line {
  position: absolute;
  left: 9%;
  height: 63px;
  top: 35%;
  width: 1px;
  background-color: rgba(0, 188, 212, 1);
  z-index: -1;
}

.node-tree .level {
  position: absolute;
  height: 140px;
  top: 0%;
  width: 1px;
  background-color: rgba(0, 188, 212, 1);
  z-index: 1;
}

.node-tree .level-0 {
  left: 10%;
}

.node-tree .level-1 {
  left: -70px
}

.node-tree .level-2 {
  left: -160px;
}

.node-tree .level-3 {
  left: -250px;
}

.node-tree .level-4 {
  left: -340px;
}

.node-tree .line-up {
  position: absolute;
  top: -18%;
  left: -70px;
  z-index: -1;
}

.node-tree .hide {
  display: none;
}

.node-tree .total-earnings-generated-for-you {
  background: rgba(0, 188, 212, 0.1);
  padding: 5px 10px;
  text-align: left;
  border-radius: 2px;
  height: 46px;
  width: 178px;
  display: block;
  float: left;
  margin-right: 5px;
}

.node-tree .total-earnings-generated-for-you .title {
  font-family: "Inter";
  font-size: 8px;
  font-weight: 500;
  line-height: 12.1px;
  text-align: left;
  color: rgba(101, 101, 101, 1);
  margin-bottom: 2px;
}

.node-tree .total-earnings-generated-for-you .price {
  font-family: "Inter";
  font-size: 20px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;
  color: rgba(0, 0, 0, 1);
}

.node-tree .onboarding-process {
  background: rgba(242, 242, 242, 1);
  border-radius: 2px;
  padding: 3px 2px;
  width: 101px;
  height: 46px;
  float: left;
  margin-right: 2px;
}

.node-tree .onboarding-process .title {
  font-family: "Inter";
  font-size: 7px;
  font-weight: 500;
  line-height: 9.68px;
  text-align: left;
  color: rgba(101, 101, 101, 1);
  margin-bottom: 2px  ;
}

.node-tree .onboarding-process .text {
  font-family: "Inter";
  font-size: 10px;
  font-weight: 600;
  line-height: 12.1px;
  text-align: left;
  margin-bottom: 0;
  color: rgba(0, 128, 0, 1);
}

.node-tree .onboarding-process .text-incomplete {
  font-family: "Inter";
  font-size: 10px;
  font-weight: 600;
  line-height: 12.1px;
  text-align: left;
  color: rgba(188, 188, 2, 1);
  float: left;
}

.node-tree .total-closed-deals {
  background: rgba(0, 188, 212, 0.1);
  padding: 5px 10px;
  text-align: left;
  border-radius: 2px;
  height: 46px;
  width: 178px;
  display: block;
  float: left;
  margin-right: 5px;
}

.node-tree .total-closed-deals .title {
  font-family: "Inter";
  font-size: 10px;
  font-weight: 500;
  line-height: 12.1px;
  text-align: left;
  color: rgba(101, 101, 101, 1);
  margin-bottom: 2px;
}

.node-tree .content-part-up {
  margin-bottom: 2px;
}

.node-tree .total-closed-deals .number {
  font-family: "Inter";
  font-size: 20px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;
  color: rgba(0, 0, 0, 1);
}

.node-tree .first-deal-status {
  background: rgba(242, 242, 242, 1);
  width: 203px;
  padding: 9px 10px;
  float: left;
  height: 46px;
  border-radius: 2px;
}

.node-tree .first-deal-status .title {
  font-family: "Inter";
  font-size: 8px;
  font-weight: 500;
  line-height: 9.68px;
  text-align: left;
  color: rgba(188, 188, 2, 1);
  margin-bottom: 2px;
}

.node-tree .first-deal-status .price {
  font-family: "Inter";
  font-size: 10px;
  font-weight: 600;
  line-height: 12.1px;
  text-align: left;
  color: rgba(188, 188, 2, 1);
}
.node-tree .first-deal-status .price.complete {
  color: rgba(0, 128, 0, 1);

}

.dialog-grow-your-network {
  text-align: center;
  position: relative;
  margin: 0 10px;
}

.dialog-grow-your-network .dialog-grow-your-network-content {
  padding: 20px;
  overflow-y: auto;
}

.dialog-grow-your-network .dialog-grow-your-network-content .content {
  padding: 0;
}

.dialog-grow-your-network .dialog-grow-your-network-content .close-icon {
  float: right;
  cursor: pointer;
}

.dialog-grow-your-network .dialog-grow-your-network-content .title {
  font-family: "Inter";
  font-size: 20px;
  font-weight: 900;
  line-height: 35px;
  text-align: center;
  color: rgba(0, 188, 212, 1);
  text-transform: none;
}

.dialog-grow-your-network .dialog-grow-your-network-content .step-container {
  margin-bottom: 30px;
}

.dialog-grow-your-network .dialog-grow-your-network-content .step-container img {
  width: 30px;
  height: 30px;
  float: left;
  margin-right: 10px;
}

.dialog-grow-your-network .dialog-grow-your-network-content .step-container .content {
  float: left;
  width: 90%;
  text-align: left;
}

.dialog-grow-your-network .dialog-grow-your-network-content .step-container .content .content-title {
  font-family: "Inter";
  font-size: 18px;
  font-weight: 900;
  line-height: 24px;
  text-align: left;
  color: rgba(0, 0, 0, 1);
  margin-bottom: 10px;
  margin-top: 4px;
}

.dialog-grow-your-network .dialog-grow-your-network-content .step-container .content .content-text {
  font-family: "Inter";
  font-size: 18px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  color: rgba(0, 0, 0, 0.75);
  margin-bottom: 0;
}

.dialog-grow-your-network .dialog-grow-your-network-content .step-container .content .content-text-note {
  font-family: "Inter";
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
  text-align: left;
  margin-bottom: 0;
}

.dialog-grow-your-network .dialog-grow-your-network-content::-webkit-scrollbar {
  width: 10px !important;
  background: #0097A7;
}

.dialog-grow-your-network .dialog-grow-your-network-content::-webkit-scrollbar-track {
  background: #fff;
  border-radius: 12px;
}

.dialog-grow-your-network .dialog-grow-your-network-content::-webkit-scrollbar-thumb {
  background-color: #0097A7;
  border-radius: 10px;
}

@media screen and (max-width: 1245px) {
  .referral-tab .container-item .top-va-referral .grid-left,
  .referral-tab .container-item .top-va-referral .grid-right {
    float: none;
    display: block;
    margin-bottom: 10px;
    width: 100%;
  }
}

@media screen and (max-width: 991px) {
  .referral-tab .container-item {
    background: transparent;
    padding: 0;
  }

  .referral-tab {
    padding: 10px;
  }

  .referral-tab .container-item .earn-extra-credit-for-active-referrals, .referral-tab .container-item .maximize-your-earnings, .referral-tab .container-item .invite-and-grow-your-network, .referral-tab .container-item .earn-an-extra-for-growing-your-network {
    margin-bottom: 10px;
  }

  .node-tree {
    padding: 10px;
    background-color: #fff;
    border-radius: 10px;
    width: 100%;
  }

  .build-strong-network {
    border: 1px solid rgba(0, 0, 0, 0.25);
    background: rgba(248, 248, 248, 1);
  }
}

@media screen and (max-width: 655px) {
  .node-tree {
    width: max-content;
  }
}

@media screen and (max-width: 600px) {
  .referral-tab .container-item .build-strong-network {
    padding: 50px 10px;
  }

  .referral-tab .container-item .build-strong-network .button-invite-va, .referral-tab .container-item .no-virtual-assistant-invite .button-invite-va, .referral-tab .container-item .build-strong-network .button {
    padding: 10px;
    font-size: 16px;
    margin-bottom: 10px;
  }

  
  .referral-tab .container-item .build-strong-network .button-invite-va-second-button {
    padding: 10px;
  }

  .referral-tab .container-item .build-strong-network .button-invite-va-second-button img {
    width: 23px;
    height: 26px;
  }

  .referral-tab .container-item .earn-extra-credit-for-active-referrals .title, .referral-tab .container-item .maximize-your-earnings .title, .referral-tab .container-item .invite-and-grow-your-network .title, .referral-tab .container-item .earn-an-extra-for-growing-your-network .title {
    font-size: 16px;
  }

  .referral-tab .container-item .earn-extra-credit-for-active-referrals .title-sub, .referral-tab .container-item .maximize-your-earnings .title-sub, .referral-tab .container-item .invite-and-grow-your-network .title-sub, .referral-tab .container-item .earn-an-extra-for-growing-your-network .title-sub {
    font-size: 12px;
  }

  .referral-tab .container-item .maximize-your-earnings .read-the-book {
    padding: 5px 4px;
  }

  .referral-tab .container-item .referral-boost-bonus .title {
    font-size: 28px;
    line-height: 30px;
  }
}