.dialog-seller-virtual-meeting-questionnaire-request { text-align: center; }
.dialog-seller-virtual-meeting-questionnaire-request-content { padding: 64px 84px; }
.dialog-seller-virtual-meeting-questionnaire-request-content .title-header { padding: 0; margin-bottom: 8px; }
.dialog-seller-virtual-meeting-questionnaire-request-content .title-header .title { display: inline-block; font-size: 48px; line-height: 52px; font-family: Lora; font-weight: 700; }
.dialog-seller-virtual-meeting-questionnaire-request-content .title-header .close-icon { position: absolute; top: 20px; right: 20px; cursor: pointer; }

.dialog-seller-virtual-meeting-questionnaire-request-content .content { padding: 0; overflow-x: hidden; }
.dialog-seller-virtual-meeting-questionnaire-request-content .content .sub-title { font-size: 18px; line-height: 24px; font-weight: 400; margin-bottom: 32px; font-family: Source Sans Pro; padding: 0 40px; }

.dialog-seller-virtual-meeting-questionnaire-request-content .content .rate-services { margin-bottom: 24px; }

.dialog-seller-virtual-meeting-questionnaire-request-content .content .rate-services .label,
.dialog-seller-virtual-meeting-questionnaire-request-content .content .message-content .label { margin-bottom: 8px; font-size: 18px; line-height: 22px; font-family: Source Sans Pro; font-weight: 900; text-transform: uppercase; }

.dialog-seller-virtual-meeting-questionnaire-request-content .content .message-content .textField { margin-bottom: 24px; border-radius: 8px; background: #EDEDED; }

.dialog-seller-virtual-meeting-questionnaire-request-content .content .rate-services .rating { padding: 12px 20px; margin: 0 auto; width: fit-content; background: #F8F8F8; }
.dialog-seller-virtual-meeting-questionnaire-request-content .content .rate-services .rating .star { margin-right: 8px; cursor: pointer; }
.dialog-seller-virtual-meeting-questionnaire-request-content .content .rate-services .rating .star:last-child { margin-right: 0; }

.dialog-seller-virtual-meeting-questionnaire-request-content .content .actions { margin-top: 8px; display: flex; align-items: center; justify-content: center; }
.dialog-seller-virtual-meeting-questionnaire-request-content .content .actions .button-send { height: 45px; width: 190px; padding: 15px 32px; background: #00BCD4; border-radius: 4px; color: #fff; text-transform: uppercase; margin-right: 24px; font-size: 14px; line-height: 18px; font-family: Source Sans Pro; font-weight: 700; }
.dialog-seller-virtual-meeting-questionnaire-request-content .content .actions .button-skip { height: 45px; width: 190px; padding: 15px 32px; border: 2px solid #00BCD4; border-radius: 4px; color: #000; text-transform: uppercase; font-size: 14px; line-height: 18px; font-family: Source Sans Pro; font-weight: 700;  }