.dashboard-photographer .earning-header { text-align: center; }
.dashboard-photographer .earning-header .title { margin: 64px 0px 32px 0px; font-family: Lora; font-style: normal; font-weight: 500; font-size: 72px; line-height: 76px; }

.dashboard-photographer .root { flex-grow: 1; background-color: #F8F8F8; border-radius: 12px 12px 0px 0px; margin-bottom: 128px; width: 100%; }
.dashboard-photographer .root .tabs, .dashboard-photographer .app-bar { border-radius: 12px 12px 0px 0px; background: #E8E8E8; box-shadow: none; }
.dashboard-photographer .root .tabs .tab { padding: 16px 24px; font-family: Source Sans Pro; font-style: normal; font-weight: bold; font-size: 14px; line-height: 18px; text-transform: uppercase; color: #000000; opacity: 1; }
.dashboard-photographer .root .tabs .tab.active-tab { background: #F8F8F8; cursor: default; pointer-events: none; padding: 16px 24px; border-top-right-radius: 12px; border-top-left-radius: 12px; }

.dashboard-photographer .root .tabs .client .number, .dashboard-photographer .root .tabs .invitation .number { padding: 2px 5px; margin-top: -7px; float: right; background-color: #00838F; border-radius: 50%; font-family: Source Sans Pro; font-style: normal; font-weight: bold; font-size: 12px; line-height: 15px; text-align: center; color: #FFFFFF; }

.dashboard-photographer .root .tab-panel-body { background: #F8F8F8; border-radius: 0px; }

.dialog-deactivate-account-photographer {
    margin: 0 10px;
}

.dialog-deactivate-account-photographer-content {
    padding: 22px;
    text-align: center;
}

.dialog-deactivate-account-photographer-content .title-header {
    padding: 0;
    margin-bottom: 16px;
}

.dialog-deactivate-account-photographer-content .title-header .title {
    font-family: Lora;
    font-style: normal;
    font-weight: bold;
    font-size: 32px;
    line-height: 32px;
    margin-top: 22px;
    padding: 0px 140px;
}

.dialog-deactivate-account-photographer-content .title-header .close-icon {
    cursor: pointer;
    float: right;
    margin-top: -24px;
}

.dialog-deactivate-account-photographer-content .content {
    padding: 0;
    overflow-x: hidden;
}

.dialog-deactivate-account-photographer-content .content .actions {
    margin-top: 24px;
}

.dialog-deactivate-account-photographer-content .content .actions .button-continue {
    margin-right: 24px;
    padding: 15px 32px;
    background: #F44336;
    border-radius: 4px;
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 18px;
    text-transform: uppercase;
    color: #FFFFFF;
}

.dialog-deactivate-account-photographer-content .content .actions .button-back {
    padding: 15px 32px;
    border: 2px solid #00BCD4;
    box-sizing: border-box;
    border-radius: 4px;
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 18px;
    text-transform: uppercase;
    color: #000000;
}

.dialog-deactivate-account-photographer-content .button-send {
    height: 45px;
    padding: 15px 32px;
    background: #00BCD4;
    border-radius: 4px;
    color: #fff;
    text-transform: uppercase;
    margin-right: 25px;
    font-size: 14px;
    line-height: 18px;
    font-family: Source Sans Pro;
    font-weight: 700;
}

.dialog-deactivate-account-photographer-content .button-send:hover {
    height: 45px;
    padding: 15px 32px;
    background: #00BCD4;
    border-radius: 4px;
    color: #fff;
    text-transform: uppercase;
    margin-right: 25px;
    font-size: 14px;
    line-height: 18px;
    font-family: Source Sans Pro;
}

@media screen and (max-width:600px){
    .dashboard-photographer .earning-header .title {
        font-size: 57px; line-height: 60px; 
    }

    .dialog-deactivate-account-photographer-content .title-header .title {
        padding: 0;
    }
}