.lawyer-profile-modal .lawyer-profile-modal-content { padding: 21px 21px; }
.lawyer-profile-modal .lawyer-profile-modal-content .title-header .close-icon { float: right; cursor: pointer; }
.lawyer-profile-modal .lawyer-profile-modal-content .lawyer-profile-modal-content-inner { margin: 0 45px; padding-top: 40px; }
.lawyer-profile-modal .lawyer-profile-modal-content .lawyer-profile-modal-content-inner .title { padding: 0px 95px; margin-bottom: 8px; font-family: Lora; font-style: normal; font-weight: 1; font-size: 48px; line-height: 52px; text-align: center; color: #000000; }
.lawyer-profile-modal .lawyer-profile-modal-content .lawyer-profile-modal-content-inner .title-sub { padding: 0px 95px; margin-bottom: 24px; font-family: Source Sans Pro; font-style: normal; font-weight: normal; font-size: 18px; line-height: 24px; text-align: center; color: #000000; }
.lawyer-profile-modal .lawyer-profile-modal-content .lawyer-profile-modal-content-inner .field { width: 100%; margin-bottom: 24px; }
.lawyer-profile-modal .lawyer-profile-modal-content .lawyer-profile-modal-content-inner .button-send { display: block; margin: 0 auto; padding: 15px 32px; background: #00BCD4; border-radius: 4px; font-family: Source Sans Pro; font-style: normal; font-weight: bold; font-size: 14px; line-height: 18px; text-transform: uppercase; color: #FFFFFF; }
.lawyer-profile-modal .lawyer-profile-modal-content .lawyer-profile-modal-content-inner img  { display: block; margin: 0 auto 15px auto; }

.lawyer-profile-modal .lawyer-profile-modal-content .lawyer-profile-modal-content-inner .title-primary { margin-bottom: 8px; font-family: Lora; font-style: normal; font-weight: 1; font-size: 48px; line-height: 52px; text-align: center; color: #000000; }
.lawyer-profile-modal .lawyer-profile-modal-content .lawyer-profile-modal-content-inner .title-sub-primary { margin: 0 128px 24px 128px; font-family: Source Sans Pro; font-style: normal; font-weight: normal; font-size: 18px; line-height: 24px; text-align: center; color: #000000; }
.lawyer-profile-modal .lawyer-profile-modal-content .lawyer-profile-modal-content-inner .buttons { display: block; text-align: center; }
.lawyer-profile-modal .lawyer-profile-modal-content .lawyer-profile-modal-content-inner .buttons .button-back { margin-right: 15px; padding: 15px 32px; background: #00BCD4; font-family: Source Sans Pro; font-style: normal; font-weight: bold; font-size: 14px; line-height: 18px; text-transform: uppercase; color: #FFFFFF; border-radius: 4px; }
.lawyer-profile-modal .lawyer-profile-modal-content .lawyer-profile-modal-content-inner .buttons .button-close { padding: 15px 32px; border: 1px solid #00BCD4; border-radius: 4px; font-family: Source Sans Pro; font-style: normal; font-weight: bold; font-size: 14px; line-height: 18px; text-transform: uppercase; color: #000000; }

.lawyer-profile-modal .lawyer-profile-modal-content .lawyer-profile-modal-content-inner .stepper { margin: 0 auto; }
.lawyer-profile-modal .lawyer-profile-modal-content .lawyer-profile-modal-content-inner .stepper .stepper-actions {  display: flex; justify-content: center; gap: 24px; margin: 24px 0px 64px 0px; }
.lawyer-profile-modal .lawyer-profile-modal-content .lawyer-profile-modal-content-inner .stepper .stepper-actions .button-stepper { padding: 15px 32px; background: #00BCD4; border-radius: 4px; font-family: Source Sans Pro; font-style: normal; font-weight: bold; font-size: 14px; line-height: 18px; text-transform: uppercase; color: #FFFFFF; }
.lawyer-profile-modal .lawyer-profile-modal-content .lawyer-profile-modal-content-inner .stepper .stepper-actions .button-stepper-outline { padding: 15px 32px; border: 2px solid #00BCD4; box-sizing: border-box; border-radius: 4px; font-family: Source Sans Pro; font-style: normal; font-weight: bold; font-size: 14px; line-height: 18px; text-transform: uppercase; color: #000000; }
.lawyer-profile-modal .lawyer-profile-modal-content .lawyer-profile-modal-content-inner .stepper .MuiPaper-root.MuiStepper-root.MuiStepper-horizontal.MuiStepper-alternativeLabel { width:50%; margin: 0 auto }