.dialog-page-bid-virtual-assistant { padding: 20px; text-align: center; }
.dialog-bid-virtual-assistant { text-align: center; }
.dialog-bid-virtual-assistant-content { margin: 63px 245px 64px 244px; }
.dialog-bid-virtual-assistant-content .close-icon { cursor: pointer; float: right; position: absolute; top: 0; right: 0; margin-top: 20px; margin-right: 21px; }
.dialog-bid-virtual-assistant-content .title { font-size: 36px; line-height: 42px; margin-bottom: 8px; }
.dialog-bid-virtual-assistant-content .content { padding: 0; overflow-x: hidden; }
.dialog-bid-virtual-assistant-content .content .text { font-size: 16px; line-height: 18px; font-weight: 400; margin-bottom: 24px; }
.dialog-bid-virtual-assistant-content .content .text-bet { font-size: 18px; line-height: 21px; font-weight: 400; margin-bottom: 8px; }
.dialog-bid-virtual-assistant-content .content .textField { margin-bottom: 24px; }
.dialog-bid-virtual-assistant-content .content .bet-info { display: flex; justify-content: center; align-items: center; margin-top: 16px; margin-left: 3px; }
.dialog-bid-virtual-assistant-content .content .bet-info .text { margin-right: 8px; font-size: 12px; line-height: 14px; font-weight: 400; }
.dialog-bid-virtual-assistant-content .content .bet-info .icon { vertical-align: middle; margin-right: 8px; }
.dialog-bid-virtual-assistant-content .content .actions { margin-top: 24px; } 
.dialog-bid-virtual-assistant-content .content .actions .button-bidding { height: 40px; padding: 12px 32px; background: #000; border-radius: 12px; color: #FFFFFF; text-transform: none; margin-right: 16px; font-size: 12px; line-height: 14px; font-weight: 400; }
.dialog-bid-virtual-assistant-content .content .actions .button-close { height: 40px; padding: 12px 32px; border-radius: 12px; text-transform: none; font-size: 12px; line-height: 14px; font-weight: 400; }
