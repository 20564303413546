.review-item {
    margin: 32px 16px;
    padding: 24px;
    font-family: "Source Sans Pro", sans-serif;
    background: #ffffff;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px rgba(0, 0, 0, 0.12),
      0px 1px 3px rgba(0, 0, 0, 0.2);
    border-radius: 12px;
    height: fit-content;
}

.review-item .review-item-top {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.review-item .review-item-top img {
    margin-right: 8px;
}

.review-item .review-item-top .content-top {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}

.review-item .review-item-top .content-top h5 {
    margin: 0;
    padding: 0;
    font-style: normal;
    font-weight: 900;
    font-size: 18px;
    line-height: 22px;
    text-align: left;
    text-transform: uppercase;
    color: #000000;
}

.review-item .review-item-top .content-top p {
    margin: 0;
    padding: 0;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    text-align: right;
    letter-spacing: 0.4px;
    color: #000000;
}

.review-item .review-item-rating {
    margin: 8px 0 12px 0;
}

.review-item .review-item-content {
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 24px;
    color: #000000;
}

@media screen and (max-width: 767px) {
    .review-item {
       margin-bottom: 10px !important;
    }
}