.home-seller {
	text-align: center;
	margin: 0 auto;
	background-color: #fff;
	overflow: hidden;
}

.home-seller .createNewFlow-wrapper {
	margin: auto;
	display: flex;
	justify-content: center;
	margin-top: -80px;
	flex-direction: column;
	align-items: center;
	padding: 32px;
	background-color: white;
	width: 80%;
	box-shadow: 4px 10px 30px 0px rgba(0, 0, 0, 0.06);
	border-radius: 12px;
}

.home-seller .custom-height-wrapper {
	max-height: 1470px;
	overflow: auto;
	margin-bottom: 10px;
}

.home-seller .custom-height-wrapper::-webkit-scrollbar {
	width: 0.3em !important;
	height: 0.3em !important;
}

.home-seller .custom-height-wrapper::-webkit-scrollbar-track {
	margin-top: 1px !important;
	box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3) !important;
}

.home-seller .custom-height-wrapper::-webkit-scrollbar-thumb {
	background-color: #4dd0e1;
	outline: 1px solid #4dd0e1 !important;
}

.home-seller .createNewFlow-wrapper .stepper-wrapper {
	display: flex;
	justify-content: center;
	align-items: center;
}

.home-seller .createNewFlow-wrapper .step-wrapper .step-item,
.home-seller .createNewFlow-wrapper .step-wrapper .step-item.currentStep > div {
    width: 70px;
}

.home-seller .createAnAddFlow .currentStep {
    margin-right: 0;
}

.home-seller .createAnAddFlow .step-item {
    margin: 0 30px;
}

.home-seller .createAnAddFlow .step-item > div {
    background: rgba(1, 35, 36, 1);
    width: 70px;
    height: 70px;
    margin-bottom: 12px;
}

.home-seller .createAnAddFlow .step-item > div > p {
    color: #ffffff;
    font-size: 35px;
    margin-bottom: 0;
}

.home-seller .createNewFlow-wrapper>div>button {
	margin-top: 30px;
}

.home-seller .createAnAddFlow .step-item > p {
    color: rgba(1, 35, 36, 1);
    margin: 0;
    font-weight: 700;
}

.home-seller .createAnAddFlow .dashed-line {
    border-top: 8px solid rgba(1, 35, 36, 1);
    border-radius: 10px;
    width: 100px;
}

.home-seller .why-abidors {
    background-color: rgba(244, 244, 244, 1);
    padding: 55px;
    margin-bottom: 60px;
    text-align: center;
    margin-top: 60px;
}

.home-seller .why-abidors .title {
    font-size: 40px;
    font-weight: 700;
    line-height: 50px;
    margin-bottom: 0;
}

.home-seller .why-abidors .text {
    font-size: 20px;
    font-weight: 400;
    line-height: 40px;
    padding: 0 30px;
}

.home-seller .why-abidors .content .content-item {
    box-shadow: 4px 10px 30px 0px rgba(0, 0, 0, 0.06);
    background: rgba(255, 255, 255, 1);
    padding: 30px 40px;
    margin-bottom: 25px;
    border-radius: 30px;
}

.home-seller .why-abidors .content .content-item img {
    float: left;
    margin-right: 45px;
    width: 17%;
}

.home-seller .why-abidors .content .content-item .content-text {
    float: left;
    width: 71%;
    padding-top: 25px;
}

.home-seller .why-abidors .content .content-item .content-text .title {
    font-size: 25px;
    font-weight: 700;
    line-height: 40px;
    text-align: left;
}

.home-seller .why-abidors .content .content-item .content-text .text {
    font-size: 23px;
    font-weight: 400;
    line-height: 45px;
    text-align: left;
    padding: 0;
}

.home-seller .lawyer-and-assistant {
    margin: 100px 149px 64px;
}

.home-seller .lawyer-and-assistant .content {
    background-color: #f4f4f4;
    padding: 32px;
    border-radius: 12px;
    height: 530px;
}

.home-seller .lawyer-and-assistant .content .image {
    width: 130px;
    margin: 0 auto 20px auto;
}

.home-seller .lawyer-and-assistant .content .title {
    margin-bottom: 15px;
    font-size: 25px;
    font-weight: 700;
    line-height: 30px;
    text-align: center;
}

.home-seller .lawyer-and-assistant .content .sub-title {
    margin-bottom: 20px;
    font-size: 20px;
    font-weight: 400;
    line-height: 30px;
    text-align: center;
}

.home-seller .lawyer-and-assistant .content .button-more {
    font-size: 18px;
    font-weight: 700;
    text-align: center;
    padding: 15px 40px;
    border-radius: 5px;
    color: #fff;
    background-color: #00bcd4;
    text-transform: none;
}

.home-seller .lawyer-and-assistant .content .button-more:hover {
    background-color: rgba(0, 188, 212, 0.8);
}

.home-seller .lawyer-and-assistant .content .button-more.virtual {
    margin-top: 28px;
}

.home-seller .buyer-seller-section {
    margin: 0 149px;
    padding: 0;
}

.home-seller .buyer-seller-section .buyer-seller-section-content {
    background: rgba(244, 244, 244, 1);
    padding: 20px;
    border-radius: 30px;
    display: inline-block;
    width: 100%;
    margin-bottom: 100px;
}

.home-seller .buyer-seller-section .buyer-seller-section-content img {
    margin-bottom: 20px;
    width: 35%;
}

.home-seller .buyer-seller-section .buyer-seller-section-content .title {
    font-size: 25px;
    font-weight: 700;
    line-height: 30px;
    text-align: center;
    margin-bottom: 30px;
}

.home-seller .buyer-seller-section .buyer-seller-section-content .text {
    font-size: 20px;
    font-weight: 400;
    line-height: 35px;
    text-align: center;
    margin-bottom: 30px;
}

.home-seller .buyer-seller-section .buyer-seller-section-content .learn-more {
    font-size: 18px;
    font-weight: 700;
    text-align: center;
    background: rgba(0, 188, 212, 1);
    color: #fff;
    text-transform: none;
    padding: 15px 40px;
    border-radius: 5px;
}

.home-seller .buyer-seller-section .buyer-seller-section-content .learn-more:hover {
    background-color: rgba(0, 188, 212, 0.8);
}

.home-seller .last-added {
    margin-bottom: 32px;
}

.home-seller .last-added .last-added-title {
    font-size: 40px;
    font-weight: 600;
    line-height: 95px;
    text-align: center; 
}

.home-seller .createNewFlow-wrapper .call-to-action .button {
    font-size: 18px;
    font-weight: 700;
    text-align: center;
    padding: 15px 40px;
    border-radius: 5px;
    color: #fff;
    background-color: #00bcd4;
    text-transform: none;
}

.home-seller .createNewFlow-wrapper .call-to-action .button:hover {
    background-color: rgba(0, 188, 212, 0.8);
}

.home-seller .createNewFlow-wrapper .btn-new-ad {
	padding: 24px 0;
	border-radius: 8px;
	border: 1px dashed #8C8C8C;
	color: #0097A7;
	font-style: normal;
	font-weight: 700;
	font-size: 14px;
	line-height: 18px;
}

.home-seller .createNewFlow-wrapper .btn-new-ad:hover {
	background: transparent;
}

.btn-new-ad-container {
	width: 100%;
}

.slick-prev,
.slick-next {
	display: none !important;
}

@media screen and (max-width: 1340px) {
	.home-seller .why-abidors .content .content-item {
		padding: 20px;
	}

	.home-seller .why-abidors .content .content-item img {
		width: 24%;
	}

	.home-seller .why-abidors .content .content-item .content-text {
		padding-top: 30px;
	}
}

@media screen and (max-width: 1130px) {
    .home-seller .lawyer-and-assistant .col {
        margin-bottom: 10px;
    }
}

@media screen and (max-width: 1000px) {
	.home-seller .why-abidors .content .content-item img {
        width: 20%;   
    }

    .home-seller .why-abidors .content .content-item .content-text {
        padding-top: 0;
    }

    .home-seller .why-abidors .content .content-item .content-text .title {
        font-size: 30px;
    }

    .home-seller .why-abidors .content .content-item .content-text .text {
        font-size: 25px;
    }
}

@media screen and (max-width:993px) {
	.home-seller .why-abidors .content .content-item img {
        width: 20%;   
    }

	.home-seller .last-added .last-added-title {
        font-size: 30px;
        font-weight: 600;
        line-height: 45px;
	}
}

@media screen and (max-width:811px) {

	.home-seller .createNewFlow-wrapper {
		align-items: normal;
	}
}

@media screen and (max-width:767px) {
	.home-seller {
		text-align: center;
		margin: 0 auto;
		background-color: #fff;
		overflow: hidden;
	}

	.home-seller .createNewFlow-wrapper {
		width: 400px;
		max-height: 100%;
	}

	.home-seller .why-abidors {
        padding: 20px;
        margin-bottom: 30px;
    }

    .home-seller .why-abidors .title {
        font-size: 39.8px;
        font-weight: 600;
        line-height: 47px;
        text-align: center;
    }

    .home-seller .why-abidors .text {
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        text-align: center;
        padding: 0;
    }

    .home-seller .why-abidors .content .content-item {
        margin: 0 auto 15px auto;

    }

    .home-seller .why-abidors .content .content-item img {
        float: none;
        width: 70%;
        margin-bottom: 50px;
        margin-right: 0;
    }

    .home-seller .why-abidors .content .content-item .content-text {
        float: none;
        width: 100%;
        text-align: center;
    }

    .home-seller .why-abidors .content .content-item .content-text .title {
        text-align: center;
        font-size: 25px;
        font-weight: 700;
        line-height: 45px;
    }

    .home-seller .why-abidors .content .content-item .content-text .text {
        text-align: center;
        font-size: 12px;
        font-weight: 400;
        line-height: 20px;
    }

	.home-seller .lawyer-and-assistant {
        margin: 30px;
    }

    .home-seller .lawyer-and-assistant .container {
        max-width: 100%;
    }

    .home-seller .lawyer-and-assistant .content {
        height: 100%;
    }

    .home-seller .lawyer-and-assistant .content .title {
        font-size: 25px;
        font-weight: 700;
        line-height: 45px;
    }

    .home-seller .lawyer-and-assistant .content .sub-title {
        font-size: 12px;
        font-weight: 400;
        line-height: 20px;
    }

    .home-seller .lawyer-and-assistant .content .button-more {
        padding: 12px 20px;
        font-size: 18px;
        font-weight: 700;
        line-height: 30px;
    }

	.home-seller .buyer-seller-section {
        padding: 20px 0 0 0;
		margin: 0 auto;
        width: 85%;
	}

	.home-seller .buyer-seller-section .buyer-seller-section-content {
		margin-bottom: 20px;
	}

	.home-seller .buyer-seller-section .buyer-seller-section-content img {
        margin-bottom: 30px;
        width: 100%; 
    }

    .home-seller .buyer-seller-section .buyer-seller-section-content .title {
        font-size: 25px;
        font-weight: 700;
        line-height: 45px;
        text-align: center;
        margin-bottom: 0;
    }

    .home-seller .buyer-seller-section .buyer-seller-section-content .text {
        font-size: 12px;
        font-weight: 400;
        line-height: 20px;
        text-align: center;
        margin-bottom: 25px;
    }

    .home-seller .buyer-seller-section .buyer-seller-section-content .learn-more {
        padding: 15px;
        font-size: 20px;
        font-weight: 700;
        line-height: 25px;
    }

    .home-seller .createAnAddFlow .step-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 32px;
        flex-direction: column;
        gap: 20px;
    }

    .home-seller .createAnAddFlow .dashed-line {
        width: 64px;
        height: 0px;
        border-top: 2px dashed #000000;
        margin-bottom: 30px;
        transform: rotate(90deg);
    }

    .home-seller .createAnAddFlow > h1 {
        font-size: 30px;
    }

    .home-seller .createAnAddFlow .step-wrapper {
        margin-top: 0;
    }
}

@media screen and (max-width:618px) {
	.home-seller .createNewFlow-wrapper {
		width: 340px;
	}

    .home-seller .createAnAddFlow .dashed-line {
        border-top: 8px solid rgba(1, 35, 36, 1);
        border-radius: 10px;
        width: 60px;
    }

    .home-seller .createNewFlow-wrapper .step-wrapper .step-item, .home-seller .createNewFlow-wrapper .step-wrapper .step-item.currentStep > div {
        margin-bottom: 20px;
    }
}

@media screen and (max-width:400px) {
	.home-seller .createNewFlow-wrapper {
		padding: 0px 0px 15px 0px;
	}

	.btn-new-ad-container {
		width: 100%;
		padding: 0px 10px;
	}
}