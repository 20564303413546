.css-qd57tp-MuiPaper-root-MuiMenu-paper-MuiPopover-paper{
  top: 84px !important;
  padding: 20px 10px;
}


.notification-link{
  color:#000
}

.notification-link:hover{
  color:#000
}
.notification-link:visited{
  color:#000
}

.left-part {
  float: left;
}

.notification-title {
  font-size: 13px;
  margin: 0;
}

.notification-row .notification-content {
  font-size: 12px;
  margin: 0;
}

.notification-row .right-part {
  float: right;
  font-size: 12px;
}