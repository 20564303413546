.edit-profile-photographer {
    margin: 0 50px;
}

.edit-profile-photographer .header-title {
    margin-top: 64px;
}

.edit-profile-photographer .header-title .title {
    margin-bottom: 12px;
    font-family: Lora;
    font-size: 48px;
    line-height: 52px;
    text-align: center;
}

/* .edit-profile-photographer .header-common { text-align: center; margin-bottom: 64px; } */
.edit-profile-photographer .header-common {
    text-align: center;
}

.edit-profile-photographer .header-common .paragraph {
    margin-bottom: 24px;
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 24px;
    color: #000000;
}

.edit-profile-photographer .button-save {
    padding: 15px;
    background-color: #00BCD4;
    border-radius: 4px;
    color: #FFFFFF;
    margin-bottom: 32px;
    text-transform: uppercase;
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 18px;
}

.edit-profile-photographer .header-common .button-save:hover {
    background-color: #00BCD4;
}

.edit-profile-photographer .header-common .button-view-profile {
    margin-left: 32px;
    padding: 15px 32px;
    border: 1px solid #00BCD4;
    border-radius: 4px;
    color: #000000;
    margin-bottom: 32px;
    text-transform: uppercase;
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 18px;
}

.edit-profile-photographer .header-content {
    display: flex;
    margin-bottom: 64px;
}

.edit-profile-photographer .header-content .avatar {
    margin-bottom: 20px;
    width: 178px;
    height: 178px;
    background: #000000;
    border-radius: 5.12852e+07px;
    margin-left: 18px;
    float: left;
    position: relative;
}

.edit-profile-photographer .header-content .avatar .photo {
    width: 178px;
    height: 178px;
    border-radius: 5.12852e+07px;
    object-fit: cover;
}

.edit-profile-photographer .header-content .avatar:hover .photo {
    opacity: 0.3;
}

.edit-profile-photographer .header-content .avatar:hover .upload-section {
    opacity: 1;
}

.edit-profile-photographer .header-content .avatar .upload-section {
    position: absolute;
    top: 40%;
    left: 35%;
    display: none;
}

.edit-profile-photographer .header-content .avatar:hover .upload-section {
    display: block;
}



.edit-profile-photographer .header-content .avatar:hover span{
    width: 100%;
    height: 100%;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 999;
    cursor: pointer;
}

.edit-profile-photographer .header-content .avatar:hover span::before{
    position: absolute;
    content: '';
    height: 50px;
    width: 2px;
    background-color: #fff;
    transform: rotate(45deg);
    left: 50%;
    top: 38%;
}

.edit-profile-photographer .header-content .avatar:hover span::after{
    position: absolute;
    content: '';
    height: 50px;
    width: 2px;
    background-color: #fff;
    transform: rotate(-45deg);
    left: 50%;
    top: 38%;
}


.edit-profile-photographer .header-content .user-details {
    margin-left: 24px;
    float: left;
}

.edit-profile-photographer .header-content .user-details .user-name {
    font-family: Lora;
    font-size: 48px;
    line-height: 52px;
    margin-bottom: 8px;
}

.edit-profile-photographer .header-content .user-details .rating {
    display: flex;
    align-items: flex-end;
}

.edit-profile-photographer .header-content .user-details .rating .title {
    margin-right: 8px;
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: 900;
    font-size: 18px;
    line-height: 22px;
    text-transform: uppercase;
}

.edit-profile-photographer .header-content .user-details .rating .star {
    width: 16px;
    height: 16px;
    margin-top: 1px;
    filter: invert(35%) sepia(81%) saturate(1357%) hue-rotate(156deg) brightness(94%) contrast(101%)
}

.edit-profile-photographer .header-content .user-details .rating .result {
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 24px;
    margin-left: 8px;
}

.edit-profile-photographer .header-content .user-details .additional {
    margin-top: 17px;
    display: flex;
}

.edit-profile-photographer .header-content .user-details .additional .first-half .title {
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 18px;
    color: #656565;
    margin-bottom: 9px;
}

.edit-profile-photographer .header-content .user-details .additional .block {
    display: flex;
    background: #F4F4F4;
    border-radius: 4px;
}

.edit-profile-photographer .header-content .user-details .additional .block .icon {
    width: 18px;
    height: 20px;
    margin: 19px 11px 21px 19px;
    color: #000000;
}

.edit-profile-photographer .header-content .user-details .additional .block .text {
    margin: 16px 16px 16px 0px;
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 24px;
    text-transform: uppercase;
    color: #000000;
    flex: none;
}

.edit-profile-photographer .header-content .user-details .additional .second-half {
    margin-left: 32px;
}

.edit-profile-photographer .header-content .user-details .additional .second-half .title-content {
    display: flex;
}

.edit-profile-photographer .header-content .user-details .additional .second-half .title-content .title {
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 18px;
    color: #656565;
    margin-bottom: 9px;
}

.edit-profile-photographer .header-content .user-details .additional .second-half .title-content .icon {
    width: 20px;
    height: 20px;
    color: #000000;
    margin-left: 11px;
    margin-top: -1px;
}

.edit-profile-photographer .header-content .about-info {
    flex: 1;
    margin-left: 100px;
    
    background: #F8F8F8; 
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px rgba(0, 0, 0, 0.12), 0px 1px 3px rgba(0, 0, 0, 0.2);
    border-radius: 12px;
    color: #9D9D9D;
}

.edit-profile-photographer .header-content .about-info .box {
    background: #F8F8F8;
    padding: 16px;
    color: #9D9D9D;
    overflow-y: auto;
}

.edit-profile-photographer .header-content .about-info .title {
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 24px;
    text-transform: uppercase;
    color: #8C8C8C;
}

.edit-profile-photographer .header-content .about-info .paragraph {
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 24px;
    color: #8C8C8C;
    text-align: left;
}

.edit-profile-photographer .choice-content {
    margin: 64px 0px;
}

.edit-profile-photographer .choice-content .title {
    margin-bottom: 16px;
    font-family: Lora;
    font-style: normal;
    font-weight: normal;
    font-size: 48px;
    line-height: 52px;
    text-align: center;
}

.edit-profile-photographer .choice-content {
    margin: 64px 0 24px 0;
    text-align: center;
}

.edit-profile-photographer .choice-content .title {
    font-size: 48px;
    line-height: 52px;
    margin-bottom: 32px;
    font-family: Lora;
}

.edit-profile-photographer .choice-content .service-box {
    background: #F4F4F4;
    border-radius: 8px;
    margin-bottom: 32px;
    padding: 16px;
    text-align: left;
}

.edit-profile-photographer .choice-content .service-box .sub-title {
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.4px;
    margin-bottom: 4px;
}

.edit-profile-photographer .choice-content .service-box .icon {
    float: right;
    width: 9px;
    height: 9px;
    cursor: pointer;
    transition: 0.3s ease-in-out;
}

.edit-profile-photographer .choice-content .service-box .icon:hover {
    transform: scale(1.5);
}

.edit-profile-photographer .choice-content .button-section {
    height: 340px;
    border-style: dashed;
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 18px;
    text-transform: uppercase;
    color: #0097A7;
}

.edit-profile-photographer .choice-content .box {
    padding: 32px 24px 24px 24px;
    background: #F8F8F8;
    border-radius: 8px;
    text-align: center;
}

.edit-profile-photographer .choice-content .box .icon {
    width: 106px;
    height: 96px;
    margin-bottom: 8px;
}

.edit-profile-photographer .choice-content .box .title {
    font-family: Lora;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 26px;
}

.edit-profile-photographer .choice-content .box .text-box {
    margin-top: 8px;
    padding: 5px 20px;
}

.edit-profile-photographer .choice-content .box .text-box .text {
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 24px;
    color: #000000;
}

.edit-profile-photographer .save-changes {
    text-align: center;
}

.edit-profile-photographer .save-changes .button-save {
    width: 200px;
    height: 50px;
    padding: 15px 32px;
    background: #00BCD4;
    border-radius: 4px;
    margin-bottom: 128px;
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 18px;
    text-transform: uppercase;
    color: #FFFFFF;
}

.edit-profile-photographer .save-changes .button-save:hover {
    background: #00BCD4;
}

.edit-profile-photographer .choice-content .box .additional-items {
    margin-top: 24px;
    padding: 16px;
    border-radius: 8px;
    background: #EDEDED;
}

.edit-profile-photographer .choice-content .box .additional-items .item {
    display: flex;
    margin-bottom: 16px;
}

/* .edit-profile-photographer .choice-content .box .additional-items .item:last-child { padding-right: 18px; } */
.edit-profile-photographer .choice-content .box .additional-items .item .small-icon {
    width: 20px;
    height: 18px;
    margin-right: 10px;
    float: left;
    color: #9D9D9D;
    margin-top: 5px;
}

.edit-profile-photographer .choice-content .box .additional-items .item .close-icon {
    color: #8C8C8C;
    margin: 6px 3px;
    cursor: pointer;
}

.edit-profile-photographer .choice-content .box .additional-items .item .name {
    padding: 4px 12px;
    float: left;
    background: #ffffff;
    border-radius: 16px;
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 24px;
    color: #8C8C8C;
}

.edit-profile-photographer .choice-content .box .additional-items .item .button {
    width: 100%;
    margin-top: 5px;
    border: 1px dashed #8C8C8C;
    box-sizing: border-box;
    border-radius: 8px;
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 18px;
    text-transform: uppercase;
    color: #0097A7;
}

.edit-profile-photographer .choice-content .box .options-payment {
    margin-top: 16px;
}

.edit-profile-photographer .choice-content .box .options-payment .options-payment-row {
    display: flex;
    column-gap: 12px;
}

.edit-profile-photographer .choice-content .box .options-payment .options-payment-row .price-box {
    padding: 4px 24px;
    background: #FFFFFF;
    border-radius: 4px;
}

.edit-profile-photographer .choice-content .box .options-payment .options-payment-row .price-box .close-icon {
    color: #8C8C8C;
    cursor: pointer;
    float: right;
    height: 10px;
    width: 10px;
    margin-top: -2px;
    margin-right: -18px;
}

.edit-profile-photographer .choice-content .box .options-payment .options-payment-row .price-box .price {
    margin-bottom: 2px;
    font-family: Lora;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 26px;
    text-align: center;
    color: #6A6A6A;
}

.edit-profile-photographer .choice-content .box .options-payment .options-payment-row .price-box .meters {
    font-size: 12px;
    line-height: 14px;
    font-weight: 700;
    color: #6A6A6A;
}

.edit-profile-photographer .choice-content .box .options-payment .options-button {
    width: 100%;
    margin-top: 16px;
    padding: 9px 8px;
    border-radius: 8px;
    border: 1px dashed #8C8C8C;
    box-sizing: border-box;
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 18px;
    text-transform: uppercase;
    color: #0097A7;
}

.edit-profile-photographer .button-section {
    margin-bottom: 64px;
    width: 100%;
    padding: 53px 0;
    border: 1px dashed #8C8C8C;
    box-sizing: border-box;
    border-radius: 8px;
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 18px;
    text-transform: uppercase;
    color: #0097A7;
}

.edit-profile-photographer .portfolio-content {
    margin-bottom: 24px;
}

.edit-profile-photographer .portfolio-content .title {
    margin-bottom: 32px;
    font-family: Lora;
    font-style: normal;
    font-weight: normal;
    font-size: 48px;
    line-height: 52px;
    text-align: center;
}

.edit-profile-photographer .portfolio-content .box {
    position: relative;
    padding: 32px;
    background: #F8F8F8;
    border-radius: 10px;
    margin-bottom: 32px;
}

.edit-profile-photographer .portfolio-content .box .title-with-icon {
    margin-bottom: 8px;
}

.edit-profile-photographer .portfolio-content .box .title-with-icon .small-icon {
    width: 20px;
    height: 18px;
    margin-right: 10px;
    float: left;
}

.edit-profile-photographer .portfolio-content .box .title-with-icon .name {
    margin-bottom: 8px;
    font-family: Lora;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 26px;
}

.edit-profile-photographer .portfolio-content .box .text {
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 16px;
}

.edit-profile-photographer .last-content {
    margin-bottom: 128px;
    text-align: center;
}

.edit-profile-photographer .last-content .button-save {
    width: 200px;
    padding: 12px 32px;
    background-color: #000;
    border-radius: 12px;
    color: #E2E2E2;
    text-transform: none;
}

.edit-profile-photographer .last-content .button-save:hover {
    background-color: #000;
}

/* Modal */
.dialog-options-payment {
    text-align: center;
}

.dialog-options-payment-content {
    padding: 64px 144px;
}

.dialog-options-payment-content .title {
    margin-bottom: 24px;
    font-family: Lora;
    font-style: normal;
    font-weight: bold;
    font-size: 32px;
    line-height: 32px;
}

.dialog-options-payment-content .close-icon {
    cursor: pointer;
    float: right;
    position: absolute;
    top: 0;
    right: 0;
    margin-top: 20px;
    margin-right: 21px;
}

.dialog-options-payment-content .content .form-group-field {
    text-align: left;
    margin-bottom: 32px;
}

.dialog-options-payment-content .content .form-group-field .boxes-group {
    display: flex;
}

.dialog-options-payment-content .content .form-group-field .boxes-group .separator {
    margin: 5px 3px 0 3px;
    font-size: 25px;
}

.dialog-options-payment-content .content .form-group-field .label {
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 12px;
}

.dialog-options-payment-content .content .form-group-field .textField {
    margin-top: 2px;
}

.dialog-options-payment-content .content .form-group-field .textField .MuiOutlinedInput-root {
    height: 40px !important;
}

.price-input{
    width: 100%; 
    margin-bottom: 16px; 
    margin-right: 8px; 
    background: #FFFFFF;
    border-radius: 4px ;
    color: rgba(0, 0, 0, 0.6); 
    font-size: 18px; 
    line-height: 24px; 
    font-weight: normal; 
    font-style: normal;
    font-family: 'Source Sans Pro';
    border: none;
    outline: none;
    padding:15px;
}

.edit-profile-photographer .choice-content.photographer-service .cols.cols-full {
    margin: 0
}


@media screen and (max-width:900px){
    .edit-profile-photographer .photographer-service .cols-full{
        display: flex;
        flex-direction: column;
    }

    .edit-profile-photographer .photographer-service .cols-full .col4of12{
        width: 100%;
    }
}

@media screen and (max-width: 999px) {
    .edit-profile-photographer .header-content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 20px;
    }

    .edit-profile-photographer .header-content .user-details {
        text-align: center;
    }

    .edit-profile-photographer .header-content .user-details .rating {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .edit-profile-photographer .header-content .user-details .additional {
        display: flex;
        justify-content: space-around;
    }

    .edit-profile-photographer .header-content .about-info {
        flex: 1 1;
        margin-left: 0px;
        padding: 16px;
        background: #F8F8F8;
        box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px rgba(0, 0, 0, 0.12), 0px 1px 3px rgba(0, 0, 0, 0.2);
        border-radius: 12px;
        color: #9D9D9D;
        width: 100%;
        overflow-y: auto;
    }
    
}