.howToBuyProperty .title-main {
    margin-bottom: 8px;
    font-size: 48px;
    line-height: 52px;
    font-family: Lora;
    font-weight: 500;
    text-align: center;
}

.howToBuyProperty .title-sub {
    margin: 0 375px 55px 375px;
    font-size: 18px;
    line-height: 24px;
    font-family: Source Sans Pro;
    font-weight: 400;
    text-align: center;
}

.howToBuyProperty .how-to-buy {
    margin: 0 150px 75px;
}

.howToBuyProperty .how-to-buy .image {
    height: auto;
    width: 100%;
    border-radius: 12px;
    margin-bottom: 8px;
}

.howToBuyProperty .how-to-buy .title-under-image {
    font-size: 32px;
    line-height: 32px;
    font-family: Lora;
    font-weight: 700;
    margin-bottom: 20px;
}

.howToBuyProperty .how-to-buy .link-under-image {
    display: flex;
    margin-left: 16px;
    font-size: 14px;
    line-height: 18px;
    font-family: Source Sans Pro;
    font-weight: 700;
    color: #0097A7 !important;
    text-transform: uppercase;
}

.howToBuyProperty .how-to-buy .link-under-image .icon {
    margin-left: 8px;
    filter: invert(37%) sepia(61%) saturate(1659%) hue-rotate(155deg) brightness(94%) contrast(101%);
}

/* .howToBuyProperty .how-to-buy .content { margin-bottom: 10px; } */
.howToBuyProperty .how-to-buy .content .title {
    margin-bottom: 18px;
    font-size: 24px;
    line-height: 26px;
    font-family: Lora;
    font-weight: 700;
    text-align: left;
}

.howToBuyProperty .how-to-buy .content .link {
    display: flex;
    margin-left: 16px;
    font-size: 14px;
    line-height: 18px;
    font-family: Source Sans Pro;
    font-weight: 700;
    color: #0097A7 !important;
    text-transform: uppercase;
}

.howToBuyProperty .how-to-buy .content .icon {
    margin-left: 8px;
    filter: invert(37%) sepia(61%) saturate(1659%) hue-rotate(155deg) brightness(94%) contrast(101%);
}

.howToBuyProperty .how-to-buy hr {
    width: 100%;
    color: #656565;
    display: block;
    margin: 10px 0 16px 0;
}

.howToBuyProperty .asked-questions {
    margin-bottom: 64px;
}

.howToBuyProperty .asked-questions .title {
    margin-bottom: 24px;
    font-size: 48px;
    line-height: 52px;
    font-family: Lora;
    font-weight: 600;
    text-align: center;
}

.howToBuyProperty .asked-questions .content {
    padding: 32px 28px;
    background-color: #FFFFFF;
    border-radius: 12px;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px rgba(0, 0, 0, 0.12), 0px 1px 3px rgba(0, 0, 0, 0.2);
}

.howToBuyProperty .asked-questions .content .icon {
    margin-bottom: 16px;
}

.howToBuyProperty .asked-questions .content .title {
    margin-bottom: 8px;
    text-align: left;
    font-size: 24px;
    line-height: 28px;
    font-family: Roboto;
    font-weight: 700;
}

.howToBuyProperty .asked-questions .content ul {
    list-style-type: none;
    width: 258px;
}

.howToBuyProperty .asked-questions .content ul li {
    margin-bottom: 5px;
    font-size: 18px;
    line-height: 24px;
    font-family: Source Sans Pro;
    font-weight: 400;
}

.howToBuyProperty .all-manuals {
    margin: 0 240px 64px;
}

.howToBuyProperty .all-manuals hr {
    margin: 10px 0 16px 0;
}

.howToBuyProperty .all-manuals .title {
    margin-bottom: 32px;
    font-size: 32px;
    line-height: 32px;
    font-family: Lora;
    font-weight: 700;
    text-align: center;
}

.howToBuyProperty .all-manuals .content .title {
    margin-bottom: 16px;
    font-size: 20px;
    line-height: 24px;
    font-family: Source Sans Pro;
    font-weight: 700;
    text-align: left;
    text-transform: uppercase;
}

.howToBuyProperty .all-manuals .content .link {
    display: flex;
    margin-left: 16px;
    font-size: 14px;
    line-height: 18px;
    font-family: Source Sans Pro;
    font-weight: 700;
    color: #0097A7 !important;
    text-transform: uppercase;
}

.howToBuyProperty .all-manuals .content .icon {
    margin-left: 8px;
    filter: invert(37%) sepia(61%) saturate(1659%) hue-rotate(155deg) brightness(94%) contrast(101%);
}

.howToBuyProperty .why-abidors {
    margin-bottom: 32px;
    text-align: center;
}

.howToBuyProperty .why-abidors .title {
    margin-bottom: 16px;
    font-size: 72px;
    line-height: 76px;
    font-family: Lora;
    font-weight: 500;
}

.howToBuyProperty .why-abidors .text {
    margin: 0 270px;
    font-size: 18px;
    font-weight: 400;
    line-height: 21px;
}

.dialog-deactivate-account-buyer-content {
    padding: 22px;
    text-align: center;
}

.dialog-deactivate-account-buyer-content .title-header {
    padding: 0;
    margin-bottom: 16px;
}

.dialog-deactivate-account-buyer-content .title-header .title {
    font-family: Lora;
    font-style: normal;
    font-weight: bold;
    font-size: 32px;
    line-height: 32px;
    margin-top: 22px;
    padding: 0px 140px;
}

.dialog-deactivate-account-buyer-content .title-header .close-icon {
    cursor: pointer;
    float: right;
    margin-top: -24px;
}

.dialog-deactivate-account-buyer-content .content {
    padding: 0;
    overflow-x: hidden;
}

.dialog-deactivate-account-buyer-content .content .sub-title {
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 24px;
    padding: 0px 100px;
}

.dialog-deactivate-account-buyer-content .content .actions {
    margin-top: 24px;
}

.dialog-deactivate-account-buyer-content .content .actions .button-continue {
    margin-right: 24px;
    padding: 15px 32px;
    background: #F44336;
    border-radius: 4px;
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 18px;
    text-transform: uppercase;
    color: #FFFFFF;
}

.dialog-deactivate-account-buyer-content .content .actions .button-back {
    padding: 15px 32px;
    border: 2px solid #00BCD4;
    box-sizing: border-box;
    border-radius: 4px;
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 18px;
    text-transform: uppercase;
    color: #000000;
}

@media screen and (max-width:1162px){
    .howToBuyProperty .title-sub {
        margin: 0 150px 55px 150px ;
        font-size: 18px;
        text-align: left ;
        
}
.howToBuyProperty .why-abidors .text {
    text-align: left;
  }

 
}

@media screen and (max-width:900px){
    .howToBuyProperty .how-to-buy {
        margin: 0 90px 75px;
      }
      .howToBuyProperty .why-abidors .text {
        margin: 0 100px;
        
      }
}

@media screen and (max-width:738px){
    .howtobuy-buyer-responsive-subtitle{
        padding: 0 !important;
    }
    .howtobuy-buyer-responsive-title{
        padding: 0 !important;
    }
}

@media screen and (max-width:768px){
    .howToBuyProperty .how-to-buy .cols-full  {
      display: flex;
      flex-direction: column;
    }

    .howToBuyProperty .how-to-buy .cols-full .col7of12  {
        width: 100%;
      }
      .howToBuyProperty .how-to-buy .cols-full .col5of12{
        width: 100%;
    }
    .howToBuyProperty .title-sub {
        margin: 0px 100px !important;
        text-align: left;
    }

    .howToBuyProperty .why-abidors .text {
        text-align: left;
        margin: 0 100px;
       
      }

      .howToBuyProperty .how-to-buy {
        margin: 0 20px;
      }

      .howToBuyProperty .how-to-buy .content .title {
        margin-bottom: 8px;
        font-size: 20px;
        line-height: 26px;
        text-align: left;
    }
    .howToBuyProperty .banner .title-banner {
        font-size: 50px;
      }
      .howToBuyProperty .banner {
        margin-bottom: 20px;
        
      }

      .howToBuyProperty .why-abidors .title {
       
        font-size: 50px;
        
      }
      .howToBuyProperty .how-to-buy .image {
       margin:30px 0px;
      }
}


@media screen and (max-width:716px){
    .howToBuyProperty .banner .title-banner {
        font-size: 50px;
      }
      .howToBuyProperty .banner {
        margin-bottom: 20px;
        
      }

      .howToBuyProperty .why-abidors .title {
       
        font-size: 50px;
        
      }
}

@media screen and (max-width:600px){
    .howToBuyProperty .title-sub {
        margin: 0px 30px !important;
        text-align: left;
      }

      .howToBuyProperty .why-abidors .text {
        text-align: left;
        margin: 0 30px;
      }
}

@media screen and (max-width:552px){
    .howToBuyProperty .banner .title-banner {
        font-size: 40px;
      }
}

@media screen and (max-width:520px){
    .howToBuyProperty .banner .title-banner {
     text-align: center;
      }
}

@media screen and (max-width:400px){
    .howToBuyProperty .banner .title-banner {
        font-size: 30px;
      }
      .howToBuyProperty .why-abidors .title {
        font-size: 30px !important;
      }
}

