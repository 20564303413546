.dashboard {
    margin:  0 12px;
}

.dashboard .trans-terminate-button {
    margin-bottom: 4px;
    margin-top: 16px;
    padding: 12px 10px;
    float: right;
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 14px;
    text-transform: none;
}

.dashboard .trans-terminate-button img {
    width: 20px;
    height: 20px;
}

.dashboard .title {
    margin-bottom: 16px;
    font-family: Lora;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 26px;
    color: #000000;
}

.dashboard .content-left {
    margin-bottom: 62px;
    display: flex;
}

.dashboard .content-left .part-left {
    width: 36%;
    float: left;
    margin-right: 15px;
}

.dashboard .content-left .part-left img {
    border-radius: 12px;
    height: 195px;
}

.dashboard .content-left .part-right {
    float: left; 
}

.dashboard .content-left .part-right .price-containers{
   display: flex;
   justify-content: space-between;
   gap: 10px;
   
   
}

.dashboard .content-left .part-right .part-right-price {
    float: left;
}

.dashboard .content-left .part-right .part-right-price .title-price {
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: bold;
    font-size: 33px;
    color: #000000;
}

.dashboard .content-left .part-right .part-left-price {
    float: right;
    margin-top: 5px;
    text-align: right;
    
}

.dashboard .content-left .part-right .part-left-price p {
    margin-bottom: 8px;
}

.dashboard .content-left .part-right .part-left-price .title {
    margin-right: 6px;
    border-radius: 4px;
    padding: 3px 8px;
    background-color: #F4F4F4;
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 18px;
    color: #000000;
}

.dashboard .content-left .part-right .neighbor {
    margin-bottom: 10px;
}

.dashboard .content-left .part-right .neighbor span {
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 24px;
    color: #000000;
}

.dashboard .content-left .part-right .part-right-content .info .part-right-content-info-text {
    margin-bottom: 10px;
    margin-right: 12px;
}

.dashboard .content-left .part-right .part-right-content .info .part-right-content-info-text:nth-last-child(2) img {
    margin-top: 6px;
}

.dashboard .content-left .part-right .part-right-content .info .part-right-content-info-text img {
    margin-right: 5px;
}

.dashboard .content-left .part-right .part-right-content .info .part-right-content-info-text span {
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 24px;
    color: #000000;
}

.dashboard .content-left .part-right .part-right-content hr {
    margin: 8px 0 16px 0;
}

.dashboard .content-left .part-right .part-right-content .info-details{
    display: flex;
    justify-content: space-between;
}

.dashboard .content-left .part-right .part-right-content .info-details .info-details-part-left {
    float: left;
}

.dashboard .content-left .part-right .part-right-content .info-details .info-details-part-left .info-details-title {
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    color: #000000;
}

.dashboard .content-left .part-right .part-right-content .info-details .info-details-part-right .info-text {
    margin-right: 15px;
}

.dashboard .content-left .part-right .part-right-content .info-details .info-details-part-right .info-text:nth-last-child(1) {
    margin-right: 0;
}

.dashboard .content-left .part-right .part-right-content .info-details .info-details-part-right .info-text img {
    margin-right: 5px;
}

.dashboard .content-center .maps {
    height: 190px;
    width: 100%;
    border-radius: 12px;
}

.dashboard .content-center  .maps {
    position: relative;
    height: 220px;
    margin-bottom: 20px;
  }
  
  .dashboard .content-center  .maps .map {
    margin-bottom: 53px;
    padding: 70px 95px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-image: url("https://d3kuhob3u5bpy2.cloudfront.net/2bd40c7c-0e69-4d88-9161-27dc0b3fba1a.svg");
    filter: blur(8px);
  }
  
  .dashboard .content-center  .maps .map.filter {
    filter: blur(0);
  }
  
  .dashboard .content-center  .maps .text {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 243px;
    transform: translate(-50%, -50%);
    text-align: center;
    padding: 8px 16px;
    background-color: #e0f7fa;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 18px;
    text-transform: uppercase;
    color: #000000;
    cursor: pointer;
  }

.dashboard .content-right .price {
    margin-bottom: 5px;
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: bold;
    font-size: 52px;
    line-height: 64px;
    color: #000000;
}

.dashboard .content-right .seller {
    margin-bottom: 8px;
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 24px;
    text-transform: uppercase;
    color: #000000;
}

.dashboard .content-right .seller-person img {
    display: inline-block;
    margin-top: 10px;
    margin-right: 15px;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    object-fit: cover;
}

.dashboard .content-right .seller-person .info {
    display: inline-block;
}

.dashboard .content-right .seller-person .info .name {
    margin-top: 10px;
    margin-bottom: 4px;
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: 900;
    font-size: 18px;
    line-height: 22px;
    text-transform: uppercase;
    color: #000000;
}

.dashboard .content-right .seller-person .info img {
    margin-top: 5px;
    margin-right: 6px;
}

.dashboard .content-right .seller-person .info span {
    font-family: Source Code Pro;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
    color: #000000;
}

.dashboard .stepper {
    margin: 0 40px 64px 0;
}

.dashboard .stepper .title {
    margin-bottom: 14px;
    font-family: Lora;
    font-style: normal;
    font-weight: bold;
    font-size: 32px;
    line-height: 32px;
    text-align: center;
    color: #000000;
}

.dashboard .MuiStepIcon-text {
    font-family: Lora !important;
    font-style: normal !important;
    font-weight: bold !important;
    font-size: 14px !important;
    text-align: center !important;
    color: #8C8C8C;
}

.dashboard .MuiStepIcon-active .MuiStepIcon-text {
    color: red !important;
    fill: #fff !important;
}

.dashboard .chat-container {
    margin: 0 165px 64px;
}

.dashboard .chat-container .chat-left {
    float: left;
    height: 100%;
    padding: 55px 35px 0 16px;
    background-color: #F8F8F8;
    border-radius: 12px 0 0px 0px;
}

.dashboard .chat-container .chat-left .chat-content-users {
    margin-bottom: 24px;
    padding: 8px;
    background-color: #EDEDED;
    border-radius: 12px;
}

.dashboard .chat-container .chat-left .chat-content-users .avatar {
    display: inline-block;
    width: 24px;
    height: 24px;
    margin-right: 8px;
    border-radius: 50%;
}

.dashboard .chat-container .chat-left .chat-content-users .chat-info-user {
    display: inline-block;
}

.dashboard .chat-container .chat-left .chat-content-users .chat-info-user .name {
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 18px;
    text-transform: uppercase;
    color: #434343;
}

.dashboard .chat-container .chat-left .chat-content-users .chat-info-user .user-role {
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 12px;
    color: #8C8C8C;
}

.dashboard .chat-container .info {
    float: left;
    width: 100%;
}

.dashboard .chat-container .info.remove-user-chat {
    float: left;
    width: 100%;
}

/* styles tabs */
.dashboard .info .app-bar-info {
    background: #F8F8F8;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px rgba(0, 0, 0, 0.12), 0px 1px 3px rgba(0, 0, 0, 0.2);
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
}

.dashboard .info .app-bar-info .tabs-info-container .tabs-title {
    padding: 14px 25px;
    font-family: Source Sans Pro !important;
    font-style: normal !important;
    font-weight: normal !important;
    font-size: 18px !important;
    line-height: 24px !important;
    color: #000000 !important;
    text-transform: none !important;
}

.dashboard .info .app-bar-info .tabs-info-container .tabs-title[aria-selected="true"] {
    background-color: #E8E8E8;
    border-radius: 12px 12px 0px 0px;
}

.dashboard .info .container-info {
    background-color: #E8E8E8;
}

.dashboard .info .MuiTabs-indicator {
    display: none;
}

.dashboard .relationship {
    padding: 24px 24px 16px 24px;
}

.dashboard .relationship .chat-area {
    padding: 16px;
    background: #F4F4F4;
    border-radius: 12px;
    position: relative;
}

.dashboard .relationship .chat-area .chat-box {
    background: #fff;
    padding: 15px 13px;
    border-radius: 8px;
}

.dashboard .relationship .chat-area .chat-box .form-chat {
    margin-top: 60px;
}

.dashboard .relationship .chat-area .chat-box .field {
    float: left;
    width: 81%;
    border: none !important;
    border-radius: 4px 0px 0px 4px;
    background-color: #EDEDED;
    color: red;
}

.dashboard .relationship .chat-area .chat-box .button {
    float: left;
    padding: 22px 38px;
    width: 140px;
    background-color: #00BCD4;
    border-radius: 0px 12px 12px 0px;
    color: #FFFFFF;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
    font-family: Source Sans Pro;
}

.dashboard .relationship .chat-area .chat-box .chat-blob {
    padding: 13px 15px;
    text-align: left;
    background: #F2FEFF;
    border-radius: 12px 12px 12px 0px;
    width: 350px;
    margin-bottom: 16px;
}

.dashboard .relationship .chat-area .chat-box .chat-blob.waiting {
    background: #FAF4F1;
}

.dashboard .relationship .chat-area .chat-box .chat-blob.send {
    background: #9D9D9D;
}

.dashboard .relationship .chat-area .chat-box .chat-blob .blob-header {
    display: flex;
    align-items: center;
    margin-bottom: 8px;
}

.dashboard .relationship .chat-area .chat-box .chat-blob .blob-header .avatar {
    width: 24px;
    height: 24px;
    border-radius: 50%;
}

.dashboard .relationship .chat-area .chat-box .chat-blob .blob-header .title {
    font-size: 14px;
    line-height: 18px;
    font-weight: 700;
    font-family: Source Sans Pro;
    margin-left: 10px;
    text-transform: uppercase;
}

.dashboard .relationship .chat-area .chat-box .chat-blob .blob-header .hour {
    font-size: 12px;
    line-height: 12px;
    font-family: Source Sans Pro;
    font-weight: 400;
    margin-top: -13px;
    margin-left: 10px;
}

.dashboard .relationship .chat-area .chat-box .chat-blob .text {
    font-size: 16px;
    line-height: 18px;
    font-family: Source Sans Pro;
    font-weight: 400;
}

.dashboard .relationship .chat-area .chat-notifications {
    background: #fff;
    padding: 15px 10px;
    border-radius: 8px;
    overflow-y: auto;
    height: 457px;
}

.dashboard .relationship .chat-area .chat-notifications .notification-blob {
    text-align: left;
    padding: 24px 16px 16px 16px;
    background: #F2FEFF;
    border-radius: 12px 12px 12px 0px;
    margin-bottom: 8px;
    position: relative;
}

.dashboard .relationship .chat-area .chat-notifications .notification-blob .notification-blob-header {
    display: flex;
    align-items: center;
    margin-bottom: 8px;
}

.dashboard .relationship .chat-area .chat-notifications .notification-blob .notification-blob-header .icon {
    position: absolute;
    top: 0;
    margin-top: -6px;
    margin-left: 6px;
}

.dashboard .relationship .chat-area .chat-notifications .notification-blob .notification-blob-header .avatar {
    width: 24px;
    height: 24px;
    border-radius: 50%;
}

.dashboard .relationship .chat-area .chat-notifications .notification-blob .notification-blob-header .title {
    font-size: 14px;
    line-height: 18px;
    margin-left: 10px;
    font-family: Source Sans Pro;
    font-weight: 700;
    text-transform: uppercase;
}

.dashboard .relationship .chat-area .chat-notifications .notification-blob .notification-blob-header .hour {
    font-size: 12px;
    line-height: 12px;
    font-family: Source Sans Pro;
    font-weight: 400;
    margin-top: -13px;
    margin-left: 5px;
}

.dashboard .relationship .chat-area .chat-notifications .notification-blob .text {
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.4px;
    font-family: Source Sans Pro;
    font-weight: 400;
}

.dashboard .relationship .chat-area .chat-box::-webkit-scrollbar,
.dashboard .relationship .chat-area .chat-notifications::-webkit-scrollbar,
.MuiDialog-paper::-webkit-scrollbar {
    width: 6px !important;
    background: gray;
}

.dashboard .relationship .chat-area .chat-box::-webkit-scrollbar-track,
.dashboard .relationship .chat-area .chat-notifications::-webkit-scrollbar-track,
.MuiDialog-paper::-webkit-scrollbar-track {
    background: #fff;
    border-radius: 12px;
}

.dashboard .relationship .chat-area .chat-box::-webkit-scrollbar-thumb,
.dashboard .relationship .chat-area .chat-notifications::-webkit-scrollbar-thumb,
.MuiDialog-paper::-webkit-scrollbar-thumb {
    background-color: #000;
    border-radius: 10px;
}

.dashboard .hire-lawyer-content {
    margin: 64px 235px;
    padding: 32px 116px;
    background: #F8F8F8;
    border-radius: 10px;
    text-align: center;
}

.dashboard .hire-lawyer-content .title {
    font-size: 32px;
    line-height: 32px;
    font-weight: 700;
    font-family: Lora;
    margin-bottom: 8px;
}

.dashboard .hire-lawyer-content .text {
    margin: 0 20px 24px 20px;
    font-size: 18px;
    line-height: 24px;
    font-family: Source Sans Pro;
    font-weight: 400;
}

.dashboard .hire-lawyer-content .box-content {
    margin-bottom: 24px;
}

.dashboard .hire-lawyer-content .box-content .box {
    padding: 40px 24px 26px 24px;
    background: #F4F4F4;
    border-radius: 5px;
    text-align: center;
}

.dashboard .hire-lawyer-content .box-content .box .main-icon .icon {
    margin-bottom: 10px;
}

.dashboard .hire-lawyer-content .box-content .box .text-box {
    font-size: 16px;
    line-height: 18px;
    font-family: Source Sans Pro;
    font-weight: 400;
}

.dashboard .hire-lawyer-content .button {
    padding: 15px 32px;
    background: #00BCD4;
    color: #fff;
    border-radius: 4px;
    text-transform: uppercase;
    font-family: Source Sans Pro;
    font-size: 14px;
    line-height: 18px;
    font-weight: 700;
}


.dashboard .stepper .next {
    background: #00BCD4;
    border-radius: 4px;
    padding: 15px 32px;
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 18px;
    text-transform: uppercase;
    color: #FFFFFF;
    float: right;
}

.dashboard .stepper .back {
    background: #00BCD4;
    border-radius: 4px;
    padding: 15px 32px;
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 18px;
    text-transform: uppercase;
    color: #FFFFFF;
    float: left;
}

.dashboard .stepper .do-not-want-the-property {
    margin: 64px 50px;
    padding: 32px 117px;
    background: #F8F8F8;
    border-radius: 10px;
    text-align: center;
}

.dashboard .stepper .do-not-want-the-property .tile {
    margin-bottom: 8px;
    font-family: Lora;
    font-style: normal;
    font-weight: bold;
    font-size: 32px;
    line-height: 32px;
    text-align: center;
    color: #000000;
}

.dashboard .stepper .do-not-want-the-property .tile-sub {
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 24px;
    text-align: center;
    color: #000000;
}

.dashboard .chat-container .info .container-deal .title-content {
    margin-bottom: 40px;
    font-family: Lora;
    font-style: normal;
    font-weight: bold;
    font-size: 32px;
    line-height: 32px;
    color: #000000;
    text-align: center;
}

.dashboard .chat-container .info .container-deal .content {
    padding: 32px;
    background: #FFFFFF;
    border-radius: 8px;
    text-align: left;
}

.dashboard .chat-container .info .container-deal .content .title-sub {
    margin-bottom: 24px;
    display: inline-block;
    font-family: Lora;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 26px;
    color: #000000;
}

.dashboard .chat-container .info .container-deal .content .sum {
    margin-bottom: 8px;
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 18px;
    color: #000000;
}

.dashboard .chat-container .info .container-deal .content .price {
    margin-bottom: 16px;
    padding: 3px 8px;
    display: inline-block;
    background: #E0F7FA;
    border-radius: 4px;
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: bold;
    font-size: 36px;
    line-height: 45px;
    text-transform: uppercase;
    color: #000000;
}

.dashboard .chat-container .info .container-deal .content .local-tax-costs {
    margin-bottom: 16px;
}

.dashboard .chat-container .info .container-deal .content .local-tax-costs .local-tax-costs-title {
    margin-bottom: 8px;
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 18px;
    color: #000000;
}

.dashboard .chat-container .info .container-deal .content .local-tax-costs .local-tax-costs-text {
    padding: 8px;
    display: inline-block;
    background: #EDEDED;
    border-radius: 4px;
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 24px;
    color: #000000;
}

.dashboard .chat-container .info .container-deal .content .data {
    margin-bottom: 16px;
}

.dashboard .chat-container .info .container-deal .content .data .data-title {
    margin-bottom: 8px;
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 18px;
    color: #000000;
}

.dashboard .chat-container .info .container-deal .content .data .data-text {
    display: inline-block;
    padding: 8px;
    background: #EDEDED;
    border-radius: 4px;
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 24px;
    color: #000000;
}

.dashboard .chat-container .info .container-deal .content .both-sides {
    margin-bottom: 10px;
}

.dashboard .chat-container .info .container-deal .content .both-sides .both-sides-title {
    margin-bottom: 8px;
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 18px;
    color: #000000;
}

.dashboard .chat-container .info .container-deal .content .both-sides .both-sides-text {
    display: inline-block;
    padding: 8px;
    background: #EDEDED;
    border-radius: 4px;
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 24px;
    color: #000000;
}

.dashboard .chat-container .info .container-deal .content .checkbox span {
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.4px;
    color: #000000;
}

.dashboard .chat-container .info .container-deal .content .date-of-inspection {
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.4px;
    color: #8C8C8C;
}

.dashboard .container-document-seller .title-content {
    margin-bottom: 24px;
    font-family: Lora;
    font-style: normal;
    font-weight: bold;
    font-size: 32px;
    line-height: 32px;
    text-align: center;
    color: #000000;
}

.dashboard .container-document-seller .content {
    margin: 0 235px 16px 235px;
    padding: 24px;
    background: #FFFFFF;
    border-radius: 4px;
    border-bottom: 8px solid #A5D6A7;
}

.dashboard .container-document-seller .content.error {
    border-bottom: 8px solid #EF9A9A;
}

.dashboard .container-document-seller .content .content-title {
    margin-bottom: 8px;
}

.dashboard .container-document-seller .content .content-title img {
    display: inline-block;
    width: 17px;
    margin-top: 4px;
    margin-right: 10px
}

.dashboard .container-document-seller .content .content-title .title {
    display: inline-block;
    font-family: Lora;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 26px;
    color: #000000;
}

.dashboard .container-document-seller .content .text {
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 24px;
    color: #000000;
}

.dashboard .container-document-seller .content .content-right-title {
    margin-top: 25px;
}

.dashboard .container-document-seller .content .content-right-title img {
    display: inline-block;
    width: 53px;
    margin-right: 21px;
}

.dashboard .container-document-seller .content .content-right-title .title {
    display: inline-block;
    width: 154px;
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: 900;
    font-size: 18px;
    line-height: 22px;
    text-transform: uppercase;
    color: #000000;
}

.dashboard .container-document-seller .additional-document-box {
    margin: 0 235px;
    border: 1px dashed #000000;
    box-sizing: border-box;
    border-radius: 8px;
}

.dashboard .container-document-seller .additional-document-box .button {
    font-weight: 500;
    text-transform: none;
}

.dashboard .container-document-seller .additional-document-box .button .MuiButton-startIcon {
    margin: 0;
}

.dashboard .container-document-seller .additional-document-box .button .button-info {
    padding: 18px 0px;
}

.dashboard .container-document-seller .additional-document-box .button .button-info .header-content {
    display: flex;
    margin-bottom: 32px;
    margin-top: 10px;
}

.dashboard .container-document-seller .additional-document-box .button .button-info .header-content .icon {
    margin-right: 9px;
}

.dashboard .container-document-seller .additional-document-box .button .button-info .header-content .text {
    font-size: 20px;
    line-height: 28px;
}

.dashboard .container-document-seller .additional-document-box .button .button-info .footer-content .add-icon {
    width: 25px;
    height: 25px;
    margin-right: 10px;
    vertical-align: middle;
    filter: invert(39%) sepia(36%) saturate(5361%) hue-rotate(161deg) brightness(95%) contrast(101%);
    padding: 11px 8px;
}

.dashboard .container-document-seller .additional-document-box .button .button-info .footer-content .text {
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 18px;
    text-transform: uppercase;
    color: #0097A7;
}

@media screen and (max-width: 1216px) {


    .dashboard {
        margin-top: 50px;
    }

    .dashboard .trans-terminate-button {
        position: absolute;
        right: 31px;
    }

    .dashboard .cols-full {
        display: flex !important;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 20px;
    }

    .dashboard .col.col5of12 {
        width: 100% !important;
    }

    .dashboard .col.col5of12 .part-left {
        margin-right: 50px;

    }

    .dashboard .col.col5of12 .part-left img {
        height: 100% !important;
    }

    .dashboard .col.col4of12 {
        width: 100% !important;
    }

    .dashboard .col.col3of12 {
        width: 100% !important;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }

    .dashboard .content-left {
        display: flex;
        justify-content: center;
    }


    .dashboard .stepper {
        margin: 0px;
    }
}

@media screen and (max-width: 1120px) {
    .dashboard .hire-lawyer-content .box-content .col.col4of12{
        width: 100% !important;
    }
}

@media screen and (max-width: 1000px) {
    .dashboard .hire-lawyer-content {
        margin: 64px 0px;
       
    }

    .dashboard .chat-container {
        margin: 50px 150px 64px;
    }
}

@media screen and (max-width: 980px) {
    .dashboard .content-left {
        margin-top: 50px;
       
    }
}

@media screen and (max-width: 920px) {
    .dashboard .content-left {
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .dashboard .col.col5of12 .part-left {
        margin-right: 0px;
    }

    .dashboard .content-left .part-left {
        width: 50% !important;
    }

    .dashboard .content-left .part-right {
        margin-top: 50px !important;
        width: 50% !important;
    }

    .dashboard .chat-container .MuiBox-root .cols .col6of12{
        width: 100%;
    }
    
}


@media screen and (max-width: 800px) {
    .dashboard .chat-container {
        margin: 50px 70px 64px;
    }
}

@media screen and (max-width: 765px) {
    .dashboard .content-left .part-left {
        width: 60% !important;
    }

    .dashboard .content-left .part-right {
        
        width: 60% !important;
    }
    .dashboard .stepper .MuiStepper-horizontal {
        flex-direction: column !important;
        justify-content: center;
        align-items: center;
        gap: 20px;
    }

    .dashboard .stepper .makeStyles-connectorLine-6 {
        display: none !important;
    }
}

@media screen and (max-width: 660px) {
    .dashboard .content-left .part-left {
        width: 100% !important;
    }

    .dashboard .content-left .part-right {
        
        width: 100% !important;
    }

    .dashboard .hire-lawyer-content {
        padding: 32px 32px;
       
    }
}

@media screen and (max-width: 600px) {
    .dashboard .chat-container {
        margin: 50px 0px 64px;
    }
}

@media screen and (max-width: 432px) {
    .dashboard {
        margin: 20px;
    }

    .dashboard .title {
        margin-bottom: 0;
    }

    .dashboard .content-left {
        margin-top: 10px;
        margin-bottom: 15px;
    }

    .cols {
        margin: 0;
    }

    .col {
        padding: 0;
    }

    .dashboard .content-left .part-right {
        margin-top: 10px !important;
    }

    .dashboard .content-left .part-right .part-left-price img {
        margin-top: 4px;
    }

    .dashboard .content-left .part-right .part-right-price .title-price {
        margin-bottom: 15px;
    }

    .dashboard .content-left .part-right .part-right-content .info-details .info-details-part-left {
        margin-right: 30px;
    }

    .dashboard .content-left .part-right .part-right-content .info-details .info-details-part-left .info-details-title {
        margin-bottom: 0;
    }

    .dashboard .content-left .part-right .info-details {
        margin-bottom: 0;
    }

    .dashboard .content-left .part-right .info-details .propertyOfferCount{
        margin-bottom: 0;
    }

    .dashboard .stepper {
        margin-top: 20px;
    }

    .dashboard .trans-terminate-button {
        padding: 0;
        margin-top: 5px;
        right: 15px;
    }

    .dashboard .hire-lawyer-content {
        padding: 32px 10px;
    }

    .dashboard .MuiStep-root.MuiStep-horizontal.MuiStep-alternativeLabel .MuiStepConnctor-root.MuiStepConnector-horizontal.MuiStepConnector-alternativeLabel.makeStyles-connectorLine-50.Mui-disabled {
        display: none;
    }
    
    .dashboard .stepper .MuiPaper-root.MuiStepper-root.MuiStepper-horizontal.MuiStepper-alternativeLabel .MuiStepConnector-root.MuiStepConnector-horizontal.MuiStepConnector-alternativeLabel.Mui-disabled {
        display: none !important;
    }

    .dashboard .stepper .title {
        font-size: 24px;
    }

    .dashboard .hire-lawyer-content .text {
        text-align: justify;
    }

    .dashboard .chat-container .info .container-deal .title-content {
        font-size: 17px;
        line-height: 27px;
        margin: 0 5px;
    }

    .dashboard .chat-container .info .container-deal .content .price {
        font-size: 24px;
    }

    .dashboard .chat-container .info .container-deal .content .local-tax-costs .local-tax-costs-title {
        text-align: justify;
    }
}


@media screen and (max-width:330px){
    .dashboard .content-left .part-right .price-containers {
        display: flex;
        justify-content: space-between;
        gap: 5px;
    }
}