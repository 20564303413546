
.buyer-sent-offers .box-offer {
    padding: 16px;
    margin-bottom: 16px;
    background: #FFFFFF;
    border-radius: 12px;
    margin-top: 16px;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px rgba(0, 0, 0, 0.12), 0px 1px 3px rgba(0, 0, 0, 0.2);
}

.buyer-sent-offers .box-offer.highlighted {
    border: 2px solid #00BCD4;
}

.buyer-sent-offers .box-offer:last-child {
    margin-bottom: 0;
}

.buyer-sent-offers .box-offer .offer-price-box {
    padding: 52px 25px;
    border-radius: 8px;
    background: #F4F4F4;
    height: 190px;
}

.buyer-sent-offers .box-offer .offer-price-box.accepted {
    background: #00838F;
    color: #fff;
}

.buyer-sent-offers .box-offer .offer-price-box.expired {
    background: #880808;
    color: #fff;
}

.buyer-sent-offers .box-offer .offer-price-box .title {
    font-size: 22px;
    line-height: 26px;
    font-family: Lora;
    font-weight: 700;
    margin-bottom: 16px;
    width: 100%;
    text-align: center;
}

.buyer-sent-offers .box-offer .offer-price-box .price {
    font-size: 30px;
    line-height: 45px;
    font-family: Source Sans Pro;
    font-weight: 700;
    width: 100%;
    text-align: center;
}

.buyer-sent-offers .box-offer .image {
    height: 190px;
    border-radius: 12px;
}

.buyer-sent-offers .box-offer .part {
    text-align: left;
}

.buyer-sent-offers .box-offer .part .combine {
    height: 65px;
}

.buyer-sent-offers .box-offer .part .combine .price {
    font-size: 36px;
    line-height: 45px;
    font-family: Source Sans Pro;
    font-weight: 700;
    margin-bottom: 14px;
    float: left;
}

.buyer-sent-offers .box-offer .part .combine .group-items {
    display: flex;
    margin-top: 8px;
    float: right;
}

.buyer-sent-offers .box-offer .part .combine .group-items.first {
    margin-top: 12px;
}

.buyer-sent-offers .box-offer .part .combine .group-items .text {
    margin-right: 6px;
    background: #E0F7FA;
    border-radius: 4px;
    padding: 3px 8px;
    font-size: 14px;
    line-height: 18px;
    font-family: Source Sans Pro;
    font-weight: 700;
}

.buyer-sent-offers .box-offer .part .ad {
    font-size: 16px;
    line-height: 18px;
    font-family: Source Sans Pro;
    font-weight: 400;
    margin-bottom: 8px;
}

.buyer-sent-offers .box-offer .part .location {
    margin-bottom: 8px;
}

.buyer-sent-offers .box-offer .part .location .icon {
    margin-right: 4px;
}

.buyer-sent-offers .box-offer .part .location .text {
    font-size: 18px;
    line-height: 24px;
    font-family: Source Sans Pro;
    font-weight: 400;
}

.buyer-sent-offers .box-offer .part .category-group {
    display: flex;
}

.buyer-sent-offers .box-offer .part .category-group .category {
    margin-right: 11px;
}

.buyer-sent-offers .box-offer .part .category-group .category:last-child {
    margin-right: 0;
}

.buyer-sent-offers .box-offer .part .category-group .category .icon {
    margin-right: 4px;
    vertical-align: middle;
}

.buyer-sent-offers .box-offer .part .category-group .category .icon.bottom {
    vertical-align: text-bottom;
}

.buyer-sent-offers .box-offer .part .category-group .category .text {
    font-size: 18px;
    line-height: 24px;
    font-family: Source Sans Pro;
    font-weight: 400;
}

.buyer-sent-offers .box-offer .divider {
    margin: 8px 0;
    opacity: 0.5;
}

.buyer-sent-offers .box-offer .additional-details {
    display: flex;
    align-items: center;
}

.buyer-sent-offers .box-offer .additional-details .text {
    font-size: 18px;
    line-height: 21px;
    font-family: Roboto;
    font-weight: 500;
    margin-right: 30px;
}

.buyer-sent-offers .box-offer .additional-details .group-items {
    display: flex;
    margin-right: 12px;
}

.buyer-sent-offers .box-offer .additional-details .group-items:last-child {
    margin-right: 0;
}

.buyer-sent-offers .box-offer .additional-details .group-items .icon {
    margin-right: 6px;
}

.buyer-sent-offers .box-offer .additional-details .group-items .sub-text {
    font-size: 18px;
    line-height: 24px;
    font-family: Source Sans Pro;
    font-weight: 400;
}

