.loan-status-content {
    padding: 32px 0;
    text-align: left;
}

.loan-status-content .title {
    font-size: 24px;
    line-height: 26px;
    font-family: Lora;
    font-weight: 700;
    margin-top: 16px;
    margin-bottom: 8px;
}

.loan-status-content .sub-title {
    font-size: 16px;
    line-height: 18px;
    font-family: Source Sans Pro;
    font-weight: 400;
    margin-bottom: 24px;
}

.loan-status-content .certificate-information .box-certificate {
    padding: 37px 16px 16px 16px;
    background: #fff;
    border-radius: 8px;
    text-align: center;
}

.loan-status-content .certificate-information .box-certificate .title {
    font-size: 24px;
    line-height: 26px;
    font-family: Lora;
    font-weight: 700;
    margin-bottom: 8px;
}

.loan-status-content .certificate-information .box-certificate .text {
    font-size: 16px;
    line-height: 18px;
    font-family: Source Sans Pro;
    font-weight: 400;
    margin-bottom: 17px;
}

.loan-status-content .certificate-information .box-certificate .button {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 130px;
    padding: 8px 4px;
    border-radius: 4px;
    text-transform: uppercase;
    font-size: 14px;
    line-height: 18px;
    font-weight: 700;
    font-family: Source Sans Pro;
    color: #0097A7;
    cursor: pointer;
}

.loan-status-content .contact-support {
    margin-top: 24px;
    padding: 32px 219px 32px 219px;
    background: #FFFFFF;
    border-radius: 8px;
    text-align: center;
}

.loan-status-content .contact-support .title {
    font-size: 32px;
    line-height: 32px;
    font-family: Lora;
    font-weight: 700;
    margin-bottom: 8px;
}

.loan-status-content .contact-support .text {
    font-size: 18px;
    line-height: 24px;
    font-family: Source Sans Pro;
    font-weight: 400;
    margin-bottom: 24px;
}

.loan-status-content .contact-support .button {
    padding: 15px 32px;
    border-radius: 4px;
    background: #00BCD4;
    color: #fff;
    text-transform: uppercase;
    font-size: 14px;
    line-height: 18px;
    font-family: Source Sans Pro;
    font-weight: 700;
}

.loan-status-content .content-bank-information .content-bank-information-title {
    font-size: 30px;
    line-height: 26px;
    font-family: Lora;
    font-weight: 700;
    margin-bottom: 30px;
}

.loan-status-content .content-bank-information .content-item {
    font-size: 20px;
    line-height: 26px;
    font-family: Lora;
    font-weight: 700;
    margin-bottom: 15px;
}

.loan-status-content .button-apply {
    margin-top: 30px;
    padding: 15px 32px;
    border-radius: 4px;
    background: #00BCD4;
    color: #fff;
    text-transform: uppercase;
    font-size: 14px;
    line-height: 18px;
    font-family: Source Sans Pro;
    font-weight: 700;
}

.loan-status-content .button-apply:hover {
    padding: 15px 32px;
    border-radius: 4px;
    background: #00BCD4;
    color: #fff;
    text-transform: uppercase;
    font-size: 14px;
    line-height: 18px;
    font-family: Source Sans Pro;
    font-weight: 700;
}

@media screen and (max-width: 1134px) {
   .loan-status-content.loan-status-container .cols.cols-full .col8of12 {
        width: 60.667% !important;
    }
    
    .loan-status-content.loan-status-container .cols.cols-full .col4of12 {
       width: 39.333% !important;
       margin-top: 20px;
    }

    .profile-buyer .header-content {
        display: flex;
        align-items: center;
    }
}

@media screen and (max-width: 960px) {
    .loan-status-content.loan-status-container .cols.cols-full {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .loan-status-content.loan-status-container .cols.cols-full .col8of12 {
        width: 100% !important;
    }

    .loan-status-content .contact-support {
        margin-top: 24px;
        padding: 32px 100px 32px 100px;
    }
        
} 

@media screen and (max-width: 768px) {
    .loan-status-content.loan-status-container .cols.cols-full .col4of12 {
        width: 50% !important;
    }

    .profile-buyer .header-content .photo-group .upload-image {
        width: 100px;
        height: 100px;
    }

    .profile-buyer .header-content .user-details .title {
        font-size: 38px !important;
        line-height: 40px !important;
    }
}

@media screen and (max-width: 680px) {
    .loan-status-content.loan-status-container .cols.cols-full .col4of12 {
        width: 70% !important;
     }
}

@media screen and (max-width: 680px) {
    .loan-status-content.loan-status-container .cols {
        margin-right: 0px !important;
        margin-left: 0px !important;
    }
}

@media screen and (max-width: 560px) {
    .loan-status-content.loan-status-container .cols.cols-full .col4of12 {
        width: 80% !important;
    }
}
@media screen and (max-width: 540px) {
    .loan-status-content.loan-status-container .cols.cols-full .col4of12 {
        width: 100% !important;
    }

    .loan-status-content .contact-support {
        margin-top: 24px;
        padding: 32px  32px;
    }
}

@media screen and (max-width: 460px) {
    .loan-status-content.loan-status-container .cols .col {
        padding: 0 !important;
    }
}


@media screen and (max-width: 480px) {
    .loan-status-content .contact-support {
        margin-top: 24px;
        padding: 16px 16px;
    }
}

@media screen and (max-width: 423px) {
    .loan-status-content .button-apply {
        padding: 15px 15px !important;
    }

    .loan-status-content .contact-support .button {
        padding: 15px 15px !important;
    }
}

@media screen and (max-width: 423px) {

    .loan-status-content {
        padding: 13px !important;
    }

}

@media screen and (max-width: 360px) {
    .loan-status-content .content-bank-information .content-bank-information-title {
        font-size: 20px !important;
        line-height: 26px !important;
    }
}