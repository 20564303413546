.profile-menu {
    background: rgba(255, 255, 255, 1);
    width: 100%;
    height: 100%;
}

.profile-menu .content {
    padding: 10px 15px 0 15px;
}

.profile-menu .profile-image {
   width: 85px;
   height: 85px;
   margin: 0 auto 10px auto;
   display: block;
}

.profile-menu img.profile-image {
    border-radius: 50px;
    object-fit: cover;
 }

.profile-menu .profile-name {
    font-size: 20px;
    font-weight: 600;
    line-height: 30.26px;
    text-align: center;
    margin-bottom: 5px;
}

.profile-menu .profile-email {
    font-size: 18px;
    font-weight: 600;
    line-height: 24.2px;
    text-align: center;
    color: rgba(0, 0, 0, 0.8);
    margin-bottom: 5px;
}

.profile-menu .profile-account {
    padding: 10px;
    border-radius: 100px;
    background-color: rgba(1, 35, 36, 1);
    font-size: 18px;
    font-weight: 400;
    line-height: 30px;
    text-align: center;
    color: #ffffff;
    width: 90%;
    margin: 0 auto 5px auto;
}

.profile-menu .button-view {
    background-color: rgba(0, 188, 212, 1);
    color: #ffffff;
    padding: 15px;
    border-radius: 50px;
    width: 80px;
    height: 80px;
    margin: 0 auto 10px auto;
    text-align: center;
    cursor: pointer;
}

.profile-menu .button-view img {
    width: 25px;
    height: 25px;
}

.profile-menu .button-view .text {
    font-size: 18px;
    font-weight: 400;
    line-height: 30px;
}

.profile-menu .also-you-can {
    font-size: 18px;
    font-weight: 600;
    line-height: 24.2px;
    text-align: center;
    margin-bottom: 10px;
}

.profile-menu .button {
    border: 1px solid rgba(0, 188, 212, 1);
    padding: 5px 0;
    font-size: 18px;
    font-weight: 400;
    line-height: 30px;
    text-align: center;
    margin: 0 auto 10px auto;
    color: rgba(1, 35, 36, 1);
    width: 90%;
    display: block;
}

.profile-menu .button:hover {
    border: 1px solid rgba(0, 188, 212, 1);
    color: #ffffff;
    background-color: rgba(0, 188, 212, 1);
}

.profile-menu .button-logout {
    width: 100%;
    border-radius: 0px 0px 10px 10px;
    text-align: center;
    background-color: rgba(239, 238, 238, 1);
    font-size: 18px;
    font-weight: 600;
    line-height: 30px;
    text-align: center;
    color: rgba(255, 0, 0, 1);
    padding: 10px 0;
    text-transform: uppercase;
}

.profile-menu .button-logout:hover {
    color: #ffffff;
    background-color: rgba(255, 0, 0, 1);
}