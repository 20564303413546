.seller-profile-information-content {
    margin-bottom: 128px;
    margin-top: 64px;
    text-align: center;
}

.seller-profile-information-content .title {
    margin-bottom: 16px;
    font-family: Lora;
    font-size: 32px;
    line-height: 32px;
}

.seller-profile-information-content .button {
    padding: 15px 32px;
    border: 2px solid #00BCD4;
    box-sizing: border-box;
    border-radius: 4px;
    color: #000000;
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 18px;
    text-transform: uppercase;
}