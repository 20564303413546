.photographer-relationship-suggestion { margin: 24px; }

.photographer-relationship-suggestion .photographer-box { padding: 24px; text-align: left; background: #EDEDED; margin-bottom: 16px; box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px rgba(0, 0, 0, 0.12), 0px 1px 3px rgba(0, 0, 0, 0.2); border-radius: 8px; }
.photographer-relationship-suggestion .photographer-box .title { font-size: 24px; line-height: 26px; font-family: Lora; font-weight: 700; margin-bottom: 16px; }
.photographer-relationship-suggestion .photographer-box .sub-title { font-size: 18px; line-height: 22px; font-family: Source Sans Pro; font-weight: 900; margin: 8px 0; text-transform: uppercase; }

.photographer-relationship-suggestion .photographer-box .service-box { padding: 8px; background: #fff; border-radius: 4px; min-height: 112px; }
.photographer-relationship-suggestion .photographer-box .service-box .icon { width: 20px; height: 20px; margin-bottom: 12px; filter: invert(53%) sepia(63%) saturate(6314%) hue-rotate(160deg) brightness(94%) contrast(101%); }
.photographer-relationship-suggestion .photographer-box .service-box .text { font-size: 18px; line-height: 24px; font-family: Source Sans Pro; font-weight: 400; }

.photographer-relationship-suggestion .photographer-box .message-box { padding: 8px; border-radius: 4px; background: #fff; min-height: 112px; }
.photographer-relationship-suggestion .photographer-box .message-box .text { font-size: 18px; line-height: 24px; font-family: Source Sans Pro; font-weight: 400; }

.photographer-relationship-suggestion .photographer-box .avatar-group { display: flex; align-items: center; margin-bottom: 16px; }
.photographer-relationship-suggestion .photographer-box .avatar-group .avatar { width: 64px; height: 64px; border-radius: 50%;  margin-right: 8px; }
.photographer-relationship-suggestion .photographer-box .avatar-group .name-icons .name { font-size: 18px; line-height: 22px; font-family: Source Sans Pro; font-weight: 900; text-transform: uppercase; }
.photographer-relationship-suggestion .photographer-box .avatar-group .name-icons .icons-group { display: flex; gap: 8px; margin-top: 8px; }
.photographer-relationship-suggestion .photographer-box .avatar-group .name-icons .icons-group .icon { width: 24px; height: 24px; }

.photographer-relationship-suggestion .photographer-box .button-group { display: flex; }
.photographer-relationship-suggestion .photographer-box .button-group .button-accept { height: 48px; padding: 15px 32px; background: #00BCD4; color: #fff; border-radius: 4px; margin-right: 16px; font-size: 14px; line-height: 18px; font-family: Source Sans Pro; font-weight: 700; text-transform: uppercase; }
.photographer-relationship-suggestion .photographer-box .button-group .button-reject { height: 48px; padding: 15px 32px; border: 2px solid #00BCD4; color: #000; border-radius: 4px; font-size: 14px; line-height: 18px; font-family: Source Sans Pro; font-weight: 700; text-transform: uppercase; }

.photographer-relationship-suggestion .photographer-box .grid-layout { margin-top: 8px; display: grid; grid-template-columns: repeat(3, 1fr); gap: 8px; }

.photographer-relationship-suggestion .suggestion-additional-info { text-align: center; margin-top: 100px; }
.photographer-relationship-suggestion .suggestion-additional-info .title { font-size: 32px; line-height: 32px; font-family: Lora; font-weight: 700; margin-bottom: 16px; }
.photographer-relationship-suggestion .suggestion-additional-info .sub-title { margin: 0 220px; font-size: 18px; line-height: 24px; font-family: Source Sans Pro; font-weight: 400; margin-bottom: 24px; }
.photographer-relationship-suggestion .suggestion-additional-info .button-hire { height: 48px; padding: 15px 32px; background: #00BCD4; color: #fff; border-radius: 4px; font-size: 14px; line-height: 18px; font-family: Source Sans Pro; font-weight: 700; text-transform: uppercase; }