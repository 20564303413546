.profile-lawyer-relationship-suggestion {
    margin: 24px;
}

.profile-lawyer-relationship-suggestion .lawyer-box-overflow {
    max-height: 700px;
    overflow-y: scroll;
}

.profile-lawyer-relationship-suggestion .lawyer-box-overflow::-webkit-scrollbar {
    width: 10px !important;
    background: #0097A7;
}

.profile-lawyer-relationship-suggestion .lawyer-box-overflow::-webkit-scrollbar-track {
    background: #fff;
    border-radius: 12px;
}

.profile-lawyer-relationship-suggestion .lawyer-box-overflow::-webkit-scrollbar-thumb {
    background-color: #0097A7;
    border-radius: 10px;
}

.profile-lawyer-relationship-suggestion .lawyer-box {
    padding: 24px;
    text-align: left;
    background: #EDEDED;
    margin-bottom: 16px;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px rgba(0, 0, 0, 0.12), 0px 1px 3px rgba(0, 0, 0, 0.2);
    border-radius: 8px;
}

.profile-lawyer-relationship-suggestion .lawyer-box .title {
    font-size: 24px;
    line-height: 26px;
    font-family: Lora;
    font-weight: 700;
    margin-bottom: 16px;
}

.profile-lawyer-relationship-suggestion .lawyer-box .sub-title {
    font-size: 18px;
    line-height: 22px;
    font-family: Source Sans Pro;
    font-weight: 900;
    margin: 8px 0;
    text-transform: uppercase;
}

.profile-lawyer-relationship-suggestion .lawyer-box .service-box {
    padding: 8px;
    background: #fff;
    border-radius: 4px;
    min-height: 112px;
}

.profile-lawyer-relationship-suggestion .lawyer-box .service-box .icon {
    width: 20px;
    height: 20px;
    margin-bottom: 12px;
    filter: invert(53%) sepia(63%) saturate(6314%) hue-rotate(160deg) brightness(94%) contrast(101%);
}

.profile-lawyer-relationship-suggestion .lawyer-box .service-box .text {
    font-size: 18px;
    line-height: 24px;
    font-family: Source Sans Pro;
    font-weight: 400;
}

.profile-lawyer-relationship-suggestion .lawyer-box .message-box {
    padding: 8px;
    border-radius: 4px;
    background: #fff;
    min-height: 112px;
}

.profile-lawyer-relationship-suggestion .lawyer-box .message-box .text {
    font-size: 18px;
    line-height: 24px;
    font-family: Source Sans Pro;
    font-weight: 400;
}

.profile-lawyer-relationship-suggestion .lawyer-box .avatar-group {
    display: flex;
    align-items: center;
    margin-bottom: 16px;
}

.profile-lawyer-relationship-suggestion .lawyer-box .avatar-group .avatar {
    width: 64px;
    height: 64px;
    border-radius: 50%;
    margin-right: 8px;
}

.profile-lawyer-relationship-suggestion .lawyer-box .avatar-group .avatar img {
    width: 64px;
    height: 64px;
    border-radius: 50%;
}

.profile-lawyer-relationship-suggestion .lawyer-box .avatar-group .name {
    font-size: 18px;
    line-height: 22px;
    font-family: Source Sans Pro;
    font-weight: 900;
    text-transform: uppercase;
}

.profile-lawyer-relationship-suggestion .lawyer-box .button-group {
    display: flex;
}

.profile-lawyer-relationship-suggestion .lawyer-box .button-group .button-accept {
    height: 48px;
    padding: 15px 32px;
    background: #00BCD4;
    color: #fff;
    border-radius: 4px;
    margin-right: 16px;
    font-size: 14px;
    line-height: 18px;
    font-family: Source Sans Pro;
    font-weight: 700;
    text-transform: uppercase;
}

.profile-lawyer-relationship-suggestion .lawyer-box .button-group .button-reject {
    height: 48px;
    padding: 15px 32px;
    border: 2px solid #00BCD4;
    color: #000;
    border-radius: 4px;
    font-size: 14px;
    line-height: 18px;
    font-family: Source Sans Pro;
    font-weight: 700;
    text-transform: uppercase;
}

.profile-lawyer-relationship-suggestion .lawyer-box .additional {
    margin-top: 8px;
    display: grid;
}

.profile-lawyer-relationship-suggestion .lawyer-box .additional .first-half {
    margin-bottom: 8px;
}

.profile-lawyer-relationship-suggestion .lawyer-box .additional .first-half .title {
    color: #656565;
    font-size: 16px;
    line-height: 19px;
    font-weight: 400;
    font-family: Roboto;
    margin-bottom: 6px;
}

.profile-lawyer-relationship-suggestion .lawyer-box .additional .block {
    background: #FFFFFF;
    border-radius: 4px;
    padding: 13px;
    width: fit-content;
}

.profile-lawyer-relationship-suggestion .lawyer-box .additional .first-half .block {
    padding: 13px 26px;
}

.profile-lawyer-relationship-suggestion .lawyer-box .additional .block .icon {
    width: 20px;
    height: 20px;
    color: #000000;
    margin-right: 11px;
}

.profile-lawyer-relationship-suggestion .lawyer-box .additional .block .text {
    font-size: 18px;
    line-height: 24px;
    font-family: Source Sans Pro;
    font-weight: 700;
    flex: none;
}

.profile-lawyer-relationship-suggestion .lawyer-box .additional .second-half .title-content {
    display: flex;
}

.profile-lawyer-relationship-suggestion .lawyer-box .additional .second-half .title-content .title {
    color: #656565;
    font-size: 16px;
    line-height: 19px;
    font-weight: 400;
    font-family: Roboto;
    margin-bottom: 6px;
}

.profile-lawyer-relationship-suggestion .lawyer-box .additional .second-half .title-content .icon {
    width: 20px;
    height: 20px;
    color: #000000;
    margin-left: 11px;
    margin-top: -1px;
}

.profile-lawyer-relationship-suggestion .suggestion-additional-info {
    text-align: center;
    margin-top: 100px;
}

.profile-lawyer-relationship-suggestion .suggestion-additional-info .title {
    font-size: 32px;
    line-height: 32px;
    font-family: Lora;
    font-weight: 700;
    margin-bottom: 16px;
}

.profile-lawyer-relationship-suggestion .suggestion-additional-info .sub-title {
    margin: 0 220px;
    font-size: 18px;
    line-height: 24px;
    font-family: Source Sans Pro;
    font-weight: 400;
    margin-bottom: 24px;
}

.profile-lawyer-relationship-suggestion .suggestion-additional-info .button-hire {
    height: 48px;
    padding: 15px 32px;
    background: #00BCD4;
    color: #fff;
    border-radius: 4px;
    font-size: 14px;
    line-height: 18px;
    font-family: Source Sans Pro;
    font-weight: 700;
    text-transform: uppercase;
}

@media screen and (max-width:1200px) {

    .profile-lawyer-relationship-suggestion .lawyer-box-overflow {
        display: grid !important;
        grid-template-columns: repeat(2, 1fr) !important;
        grid-gap: 40px !important;
    }

    .profile-lawyer-relationship-suggestion .lawyer-box .cols-full {
        display: flex;
        flex-direction: column;
        gap: 40px;
    }

    .profile-lawyer-relationship-suggestion .lawyer-box .cols-full .col4of12 {
        width: 100% !important;
    }

    .profile-lawyer-relationship-suggestion .lawyer-box .cols-full .col3of12 {
        width: 100% !important;
    }

    .profile-lawyer-relationship-suggestion .lawyer-box .cols-full .col3of12 {
        width: 100% !important;
    }

    .profile-lawyer-relationship-suggestion .lawyer-box .additional .first-half {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;
    }

    .profile-lawyer-relationship-suggestion .lawyer-box .additional .second-half {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;
    }

    .profile-lawyer-relationship-suggestion .lawyer-box .cols-full .col5of12 {
        width: 100% !important;
    }

    .profile-lawyer-relationship-suggestion .lawyer-box .button-group {
        display: flex;
        flex-direction: column;
        gap: 16px;
        flex-wrap: wrap;
    }

    .profile-lawyer-relationship-suggestion .lawyer-box .button-group button {
       width: 100% !important;
    }
}

@media screen and (max-width:900px) {

    .profile-lawyer-relationship-suggestion .lawyer-box-overflow {
        grid-template-columns: repeat(1, 1fr) !important;
    }

    .profile-lawyer-relationship-suggestion .lawyer-box .avatar-group {
        flex-wrap: wrap;
    }

    .profile-lawyer-relationship-suggestion .suggestion-additional-info .sub-title {
        margin: 20px 0px !important;
    }

}


@media screen and (max-width:767px) {
    .profile-lawyer-relationship-suggestion .lawyer-box .cols {
        margin-left: 0 !important;
        margin-right: 0 !important;
    }
}


@media screen and (max-width:500px) {

    .profile-lawyer-relationship-suggestion .lawyer-box {
        padding-left: 0 !important;
        padding-right: 0 !important;
    }
}

@media screen and (max-width:400px) {
    .profile-lawyer-relationship-suggestion .suggestion-additional-info .title {
        font-size: 27px !important;
        line-height: 29px !important;
    }

    .profile-lawyer-relationship-suggestion .suggestion-additional-info .button-hire {
        height: inherit !important; display: block;
    }
}