.portfolio-content { margin-bottom: 64px; }
.portfolio-content .title { margin-bottom: 32px; font-family: Lora; font-style: normal; font-weight: normal; font-size: 48px; line-height: 52px; text-align: center }
.portfolio-content .box { padding: 32px 32px 14px 32px; background: #F8F8F8; border-radius: 10px; margin-bottom: 32px; }
.portfolio-content .box .title-with-icon { margin-bottom: 8px; }
.portfolio-content .box .title-with-icon .small-icon { width: 26px; height: 24px; margin-right: 10px; float: left; }
.portfolio-content .box .title-with-icon .name { font-family: Lora; font-style: normal; font-weight: bold; font-size: 24px; line-height: 26px; }
.portfolio-content .box .text { margin-bottom: 24px; font-family: Source Sans Pro; font-style: normal; font-weight: normal; font-size: 18px; line-height: 24px; }
.portfolio-content .box .gallery-images-grid { width: 100%; height: 250px; border-radius: 4px; margin-bottom: 8px;object-fit: cover; }

.portfolio-content .box .gallery-images-grid.primary { width: 100%; height: 500px; border-radius: 12px; }

.portfolio-content .box.box-1 {
    min-height: 10rem !important;
}

@media screen and (max-width: 1077px) {
    .seller-photographer-profile .portfolio-content .box.box-2 video{
        height: 100%  !important;
        margin-top: 40px;
    }
}

@media screen and (max-width: 1000px) {
	.seller-photographer-profile .portfolio-content .box.box-1 .cols  {
		display: grid;
		grid-template-columns: repeat(3, 1fr);
		grid-gap: 40px;
	}

    .seller-photographer-profile .portfolio-content .box.box-1 .cols::before {
        display: none !important;
    } 

    .seller-photographer-profile .portfolio-content .box.box-1 .col3of12 {
        width: 100% !important;
    }

    .seller-photographer-profile .portfolio-content .box.box-1 .col {
        padding:  0 !important;
    }
}

@media screen and (max-width: 767px) {

    .seller-photographer-profile .portfolio-content .box.box-1 .cols  {
		grid-template-columns: repeat(2, 1fr);
	}

    .photographer-features .photographer-features-items {
        grid-template-columns: repeat(2, 1fr);
    }

    .seller-photographer-profile {
        margin-bottom: 70px !important;
    }
}

@media screen and (max-width: 630px) {
    .seller-photographer-profile .portfolio-content .box.box-1 .cols  {
        margin-left: 0;
        margin-right: 0;
        gap: 30px !important;
    }
}

@media screen and (max-width: 560px) { 
    .seller-photographer-profile .photographer-profile-left {
        width: 100% !important;
    }
    .seller-photographer-profile .photographer-profile-right {
        width: 100% !important;
    }

    .photographer-profile-right-about>p{
        width: 100% !important;
    }
}

@media screen and (max-width: 520px) {
    .seller-photographer-profile .portfolio-content .box.box-1 .cols {
        grid-template-columns: repeat(1, 1fr);
    }
    .seller-photographer-profile .portfolio-content .box.box-2 .cols  {
        margin-left: 0;
        margin-right: 0;
    }
}

@media screen and (max-width: 483px) {
    .photographer-features .photographer-features-items {
        grid-template-columns: repeat(1, 1fr);
    }
}

