.my-adds-information-wrapper {
  .text-center {
    text-align: center;
  }

  .item-mt {
    margin-top: 30px;
  }

  .item-mb {
    margin-bottom: 30px;

    .title {
      font-family: Lora !important;
      font-size: 32px !important;
      font-style: normal !important;
      font-weight: 700 !important;
      line-height: 32px !important;
      margin-bottom: 8px !important;
      text-align: center !important;
    }
  }

  .p-0 {
    padding: 0 !important;
  }

  .label-description {
    font-family: Source Sans Pro;
    font-size: 1x;
    font-style: normal;
    font-weight: 400;
    line-height: 12px;
    letter-spacing: 0em;
    text-align: left;
    margin-bottom: 5px;
    color: #000;
    cursor: default;
  }

  .description {
    margin: 0;
    background-color: #ededed;
    border-radius: 4px;
    border: none;

    .notchedOutline {
      border: none;
    }
  }

  .buttons-wrapper {
    margin-left: -16px;
  }

  .buttonFilled {
    background-color: #00bcd4;
    border: 2px solid #00bcd4;
    margin-right: 25px;
    font-family: Source Sans Pro;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 18px;
    color: white;
    padding: 15px 32px 15px 32px;
    border-radius: 4px;
    text-transform: uppercase;
  }

  .buttonFilled:hover {
    border: 2px solid #00bcd4;
    background-color: #00bcd4;
  }

  .buttonUnfilled {
    border-radius: 4px;
    padding: 15px 32px 15px 32px;
    border: 2px solid #00bcd4;
    font-family: Source Sans Pro;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 18px;
    background-color: transparent;
    text-transform: uppercase;
  }

  .buttonUnfilled:hover {
    border: 2px solid #00bcd4;
  }

  .button-edit {
    background-color: #00bcd4;
    border: 2px solid #00bcd4;
    font-family: Source Sans Pro;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 18px;
    color: white;
    padding: 15px 32px;
    border-radius: 4px;
    text-transform: uppercase;
  }

  .button-edit:hover {
    background-color: #00bcd4;
  }

  .button-save {
    color: #000;
    border: 2px solid #00bcd4;
    border-radius: 4px;
    padding: 15px 32px;
    font-family: Source Sans Pro;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 18px;
    text-transform: uppercase;
  }

  .button-save:hover {
    border: 2px solid #00bcd4;
    background: transparent;
  }

}