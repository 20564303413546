.profile-favorite { padding: 32px; }

.profile-favorite .step { padding: 10px;  }

.profile-favorite .step .v-stepper { position: relative; margin-right: 20px; }
.profile-favorite .step .v-stepper .content-primary { margin-left: 120px; padding-top: 50px; }
.profile-favorite .step .v-stepper .circle { float: left; border-radius: 50px; width: 61px; height: 61px; background-color: #0097A7; z-index: 100; position: relative; }
.profile-favorite .step .v-stepper .circle .number { font-family: Lora; padding: 4px 18px; font-style: normal; font-weight: 1; font-size: 30px; line-height: 52px; text-align: center; color: #FFFFFF; }
.profile-favorite .step .v-stepper .evaluation { position: absolute; top: 23%; left: -15px; padding: 0 10px; z-index: 100; background-color: #F8F8F8; font-family: Source Sans Pro; font-style: normal; font-weight: 900; font-size: 18px; line-height: 22px; text-align: center; text-transform: uppercase; color: #00838F; transform: rotate(-90deg); }
.profile-favorite .step .v-stepper .line-vertical { top: 40px; left: 30px; height: 100%; position: absolute; border-left: 1px solid #014245; }
.profile-favorite .step .v-stepper .line-horizontal { top: 30px; left: 30px; width: 100%; position: absolute; border-top: 1px solid #014245; }
.profile-favorite .step .v-stepper .content-primary .delete-favorite { cursor: pointer; background-color: #fff; padding: 10px; position: absolute; top: 0; right: 0; z-index: 1; border-radius: 50px; }



@media screen and (max-width: 1230px) {
   
    .profile-favorite .step .v-stepper .content-primary {
        margin-left: 63px
    }
}

@media screen and (max-width:500px) {
    .profile-favorite {
        padding: 0;
    }

    .profile-favorite .step .v-stepper .circle .number {
        font-family: Lora;
        padding: 0px 0px;
        font-style: normal;
        font-weight: 1;
        font-size: 15px;
        line-height: 33px;
        text-align: center;
        color: #FFFFFF;
    }

    .profile-favorite .step .v-stepper .circle {
        float: left;
        border-radius: 50px;
        width: 30px;
        height: 30px;
        background-color: #0097A7;
        z-index: 100;
        position: relative;
    }

    .profile-favorite .step .v-stepper .line-horizontal {
        top: 15px;
    }

    .profile-favorite .step .v-stepper .line-vertical {
        top: 25px;
        left: 15px;
    }

    .profile-favorite .step {
        padding: 0;
    }

    .profile-favorite .step .v-stepper {
        margin-right: 0;
    }

    .profile-favorite .step .v-stepper .evaluation {
        left: -20px;
        font-size: 13px;
    }
    .profile-favorite .step .v-stepper .content-primary {
        margin-left: 25px;
    }

    .profile-favorite > .container {
        padding-left: 0 !important;
        padding-right: 0 !important;
    }
}