.header-photographer {
  width: 100%;
  position: sticky;
  top: 0;
  z-index: 990;
  background-color: #fff;
}

.header-photographer .navbar {
  padding: 17px 30px !important;
  z-index: 990;
  position: relative;
}

.header-photographer .nav-menu {
  float: left;
  display: flex;
  margin-top: 12px;
  padding-left: 0;
  list-style: none;
}

.header-photographer ul.nav-menu {
  margin-bottom: 0;
}

.header-photographer .lang-switcher-desk {
  float: left;
  margin-top: 13px;
  display: none;
}

.header-photographer .nav-links {
  padding: 0 8px;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  color: #000000;
  cursor: pointer;
  text-decoration: none;
}

.header-photographer .nav-links.active {
  background-color: rgba(0, 188, 212, 1);
  color: #ffffff;
  border-radius: 30px;
  padding: 10px;

}

.header-photographer .nav-links.dropdown {
  text-transform: lowercase;
  font-size: 12px;
  font-weight: 700;
}

.header-photographer .nav-links.dropdown span::first-letter {
  text-transform: uppercase;
}

.header-photographer .nav-links.dropdown:hover {
  background-color: transparent;
}

.header-photographer .down-caret {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 5px 5px 0 5px;
  border-color: #000 transparent transparent transparent;
  display: inline-block;
  margin-left: 9px;
  top: -1px;
  position: relative;
  transform: rotate(0deg);
  transition: all 0.25s ease-in;
}

.header-photographer .open-caret {
  transform: rotate(180deg);
  transition: all 0.25s ease-out;
}

.header-photographer .nav-item.dropdown {
  padding: 3px 0;
}

.header-photographer #menu-list-grow {
  margin-top: -12px;
}

.header-photographer .menu-icon {
  display: none;
}

/* logo */
.header-photographer .navbar-logo {
  float: left;
  margin-right: 32px;
  margin-top: 10px;
}

/* search */
.header-photographer .search {
  float: left;
  position: relative;
  margin-top: 2px;
  margin-right: 24px;
  background-color: rgba(244, 244, 244, 1);
  border-radius: 50px;
  width: 300px;
  height: 44px;
  margin-left: 24px;
  cursor: pointer;
  padding: 5px 16px;
}

.header-photographer .search.focus-input-filed {
  outline: 2px solid #00BCD4;
}

.header-photographer .search-field::placeholder {
  font-weight: 400;
  font-size: 18px;
}

.header-photographer .search .search-icon {
  float: left;
  margin-top: 4px;
  width: 26px;
  height: 26px;
  filter: invert(49%) sepia(0%) saturate(1%) hue-rotate(100deg) brightness(97%) contrast(89%);
}

.header-photographer .search .autocomplete-dropdown-container-home {
  margin-top: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  width: 100%;
  display: flex;
  flex-direction: column;
}

.header-photographer .search-field {
  float: left;
  padding: 16px 10px !important;
  height: 28px;
  border: none;
  background-color: transparent;
  width: 80% !important;
  margin-bottom: 10px;
}

.header-photographer .drop-down {
  padding: 50px 0 !important;
}

/* right part header */
.header-photographer .right-part {
  float: right;
  display: flex;
  gap: 8px;
}

.header-photographer .right-part .account-group {
  float: left;
  margin-top: 15px;
  margin-right: 0;
}

.header-photographer .right-part .account-group .link {
  display: flex;
}

.header-photographer .right-part .account-group .link img {
  float: left;
  width: 24px;
  margin-top: -3px;
  margin-right: 8px;
  filter: invert(33%) sepia(89%) saturate(2251%) hue-rotate(162deg)
    brightness(97%) contrast(101%);
}

.header-photographer .right-part .account-group .link .title {
  float: left;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  margin-top: -2px;
  margin-bottom: 0px;
  line-height: 18px;
  text-transform: uppercase;
  color: #0097a7;
}

.header-photographer .right-part .bell {
  position: relative;
  float: left;
  margin-top: 12px;
}

.header-photographer .right-part .bell .icon {
  filter: invert(33%) sepia(89%) saturate(2251%) hue-rotate(162deg) brightness(97%) contrast(101%);
  width: 18px;
  height: 18px;
}

.header-photographer .right-part .bell .notification-list-fade-in {
  color: #000;
  position: absolute;
  top: 47px;
  right: -70px;
  padding: 20px 23px;
  width: 410px;
  height: 250px;
  border-radius: 12px;
  background-color: #cfcfcf;
  visibility: visible;
  opacity: 1;
  transition: visibility 0s linear 0s, opacity 300ms;
  z-index: 1;
  overflow-x: hidden;
}

.header-photographer .right-part .bell .notification-list-fade-in:before {
  content: "";
  position: absolute;
  top: -30px;
  right: 64px;
  z-index: 1;
  border: solid 15px transparent;
  border-bottom-color: #cfcfcf;
}

.header-photographer
  .right-part
  .bell
  .notification-list-fade-in
  .row-notification
  .image {
  float: left;
  width: 10px;
  height: 18px;
  margin-right: 11px;
}

.header-photographer
  .right-part
  .bell
  .notification-list-fade-in
  .row-notification
  .author {
  float: left;
  font-size: 14px;
  font-weight: 700;
  margin-right: 5px;
}

.header-photographer
  .right-part
  .bell
  .notification-list-fade-in
  .row-notification
  .text {
  float: left;
  font-size: 14px;
  font-weight: 400;
  margin-right: 5px;
}

.header-photographer
  .right-part
  .bell
  .notification-list-fade-in
  .row-notification
  .offer-number {
  float: left;
  font-size: 14px;
  font-weight: 700;
}

.header-photographer .right-part .bell .notification-list-fade-in .hr {
  margin: 12px 0;
  color: rgba(101, 101, 101, 1);
}

.header-photographer .right-part .bell .notification-list-fade-out {
  width: 0;
  height: 0;
  opacity: 100;
  display: none;
  transition: visibility 0s linear 300ms, opacity 300ms;
}

.header-photographer .badge {
  position: absolute;
  top: -10px !important;
  right: -13px !important;
  padding: 2px 4px !important;
  border-radius: 50% !important;
  background: #00bcd4;
  color: white;
  font-size: 10px !important;
}

.header-photographer
  .right-part
  .bell
  .notification-list-fade-in::-webkit-scrollbar {
  width: 10px !important;
  background: #0097a7;
}

.header-photographer
  .right-part
  .bell
  .notification-list-fade-in::-webkit-scrollbar-track {
  background: #fff;
  border-radius: 12px;
}

.header-photographer
  .right-part
  .bell
  .notification-list-fade-in::-webkit-scrollbar-thumb {
  background-color: #0097a7;
  border-radius: 10px;
}

.header-photographer .photographer-sidenav {
  display: none;
}

.header-photographer .right-part .notification-icon {
  margin-top: 4px;
}

.header-photographer .section-fixed {
  background: rgba(1, 35, 36, 1);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px 0;
}

.header-photographer .section-fixed img {
  margin-right: 10px;
  height: 30px;
  width: 30px;
}

.header-photographer .section-fixed .text {
  font-size: 15px;
  font-weight: 700;
  line-height: 30px;
  letter-spacing: 1.0800000429153442px;
  text-align: center;
  color: rgba(255, 255, 255, 1);
  margin-bottom: 0;
}

@media only screen and (max-width: 1320px) {
  .header-photographer .search {
    width: 20%;
    margin-top: 0px !important;
    margin-left: 0;
  }
  
  .header-photographer .photographer-sidenav {
    display: block;
  }

  .header-photographer .navbar .clearfix {
    display: flex !important;
    justify-content: space-between !important;
    align-items: center;
  }

  .header-photographer .navbar-logo {
    margin-top: 0px;
  }

  .header-photographer .right-part .account-group {
    margin-top: 15px;
  }

  .header-photographer .right-part .bell {
    margin-top: 10px;
  }

  .header-photographer .nav-menu {
    margin-top: 0;
  }

  .header-photographer .navbar-logo {
    margin-right: 10px;
  }

  .header-photographer .right-part .notification-icon {
    margin-top: 3px;
  }
}

@media only screen and (max-width: 1205px) {
  .header-photographer .search {
    width: 17%;
  }
}

@media only screen and (max-width: 1000px) {
  .header-photographer .navbar .clearfix {
    display: block !important;
  }

  .header-photographer .navbar-logo {
    margin-top: 3px;
  }

  .header-photographer .right-part .account-group {
    margin-top: 15px !important;
  }

  .header-photographer .right-part .bell-desktop {
    margin-top: 20px !important;
    display: none;
  }

  .header-photographer .photographer-sidenav {
    display: block;
  }
  
}

@media only screen and (max-width: 1110px) {
  .header-photographer .nav-menu {
    display: none;
  }
  .header-photographer .search {
    display: none;
  }

  .header-photographer .lang-switcher-desk {
    display: block;
    margin-top: 5px;
  }
}

@media only screen and (max-width: 1030px) {


  .header-photographer .right-part .account-group {
    margin-top: 13px;
  }
}

@media only screen and (max-width: 768px) {
  .header-photographer .right-part .account-group {
    display: none;
  }

  .header-photographer .right-part .bell {
    margin-right: 10px;
  }

  .header-photographer .navbar {
    padding: 19px 10px !important;
  }

  .header-photographer .navbar-logo {
    margin-right: 10px;
    width: auto !important;
    margin-top: 4px;
  }

  .header-photographer .lang-switcher-desk {
    margin-top: 6px;
  }

  .header-photographer .right-part {
    gap: 0 !important;
  }

  .header-photographer .lang-switcher-desk {
    margin-top: 12px;
  }
  .header-photographer .right-part .notification-icon {
    margin-top: 3px;
    margin-right: 5px;
  } 

  .header-photographer .section-fixed {
    padding: 14px 10px;
  }
    
  .header-photographer .section-fixed .text {
      font-size: 16px;
  }
}

@media screen and (max-width:453px) {
  .header-photographer .section-fixed {
    padding: 3px 10px;
  }

  .header-photographer .section-fixed img {
      width: 40px;
  }

  .header-photographer .section-fixed .text {
      font-size: 11px;
      line-height: 21px;
  }
}
