.seach-property-details {
  margin-bottom: 64px;
}

.seach-property-details .appBar {
  background: #ffffff;
  box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.14), 0px 1px 18px rgba(0, 0, 0, 0.12),
    0px 3px 5px rgba(0, 0, 0, 0.2);
}

.seach-property-details .title-primary {
  margin-bottom: 32px;
  margin-top: 8px;
  font-style: normal;
  font-weight: 1;
  font-size: 48px;
  line-height: 52px;
  color: #000000;
  text-align: center;
}

.seach-property-details .appBar .tabs-title {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #000000;
  padding: 9px 14px;
  min-width: 72px;
  text-transform: none;
}

.seach-property-details .appBar .tabs-title[aria-selected="true"] {
  font-weight: 700;
}

.seach-property-details .appBar .tabs-container .MuiTabs-flexContainer {
  display: revert;
  text-align: center;
  padding-top: 13px;
}

.seach-property-details .appBar .MuiTabs-indicator {
  display: none;
}

.seach-property-details .information {
  margin: 30px 0;
}

.seach-property-details .information .section .carousel-container {
  position: relative;
}

.seach-property-details
  .information
  .section
  .carousel-container
  .heart_section {
  position: absolute;
  top: 5px;
  right: 5px;
  z-index: 1;
}

.seach-property-details .heart_section {
  cursor: pointer;
}

.seach-property-details .information .section .carousel-container .info {
  position: absolute;
  width: 858px;
  top: 74%;
  padding: 22px 28px;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 0px 0px 12px 12px;
}

.seach-property-details .information .info-property .titles .left-part {
  float: left;
  margin-bottom: 12px;
}

.seach-property-details
  .information
  .info-property
  .titles
  .left-part
  .title-sub {
  margin-bottom: 6px;
  font-style: normal;
  font-weight: bold;
  font-size: 44px;
  line-height: 60px;
  text-transform: uppercase;
  color: #000000;
}

.seach-property-details .information .info-property .titles .right-part {
  float: right;
  margin-top: 14px;
  text-align: right;
}

.seach-property-details
  .information
  .info-property
  .titles
  .right-part
  .offer-number {
  font-style: normal;
  font-size: 18px;
  line-height: 24px;
  color: #000000;
  margin-bottom: 10px;
}

.seach-property-details
  .information
  .info-property
  .titles
  .right-part
  .offer-number
  span {
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 24px;
  color: #000000;
  text-decoration: underline;
}

.seach-property-details
  .information
  .info-property
  .titles
  .right-part
  .info-details {
  margin-bottom: 8px;
}

.seach-property-details
  .information
  .info-property
  .titles
  .right-part
  .info-details
  .info-text {
  margin-right: 13px;
}

.seach-property-details
  .information
  .info-property
  .titles
  .right-part
  .info-details
  .info-text:nth-last-child(1) {
  margin-right: 0;
}

.seach-property-details
  .information
  .info-property
  .titles
  .right-part
  .info-details
  .info-text
  img {
  margin-top: 3px;
  margin-right: 7px;
}

.seach-property-details
  .information
  .info-property
  .titles
  .right-part
  .info-details
  .info-text
  .text {
  font-size: 18px;
  font-weight: 500;
  line-height: 21px;
}

.seach-property-details .information .info-property .info-squaring {
  margin-bottom: 18px;
}

.seach-property-details .information .info-property .info-squaring .squaring {
  float: left;
}

.seach-property-details
  .information
  .info-property
  .info-squaring
  .squaring
  .title {
  margin-right: 6px;
  padding: 5px 10px;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  background: #e0f7fa;
  border-radius: 4px;
}

.seach-property-details .information .info-property .info-squaring .text {
  background-color: #e0f7fa;
  padding: 3px 8px;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 18px;
  margin-right: 10px;
}

.seach-property-details
  .information
  .info-property
  .info-squaring
  .squaring
  img {
  width: 20px;
  height: 20px;
  margin-top: 2px;
  margin-right: 6px;
}

.seach-property-details .information .info-property .neighbor {
  margin-bottom: 15px;
}

.seach-property-details .information .info-property .neighbor img {
  float: left;
  margin-right: 4px;
}

.seach-property-details .information .info-property .neighbor span {
  float: left;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 24px;
}

.seach-property-details .information .info-property .property-type span {
  float: left;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 24px;
  width: 100%;
  margin-bottom: 15px;
}

.seach-property-details .information .info-property .rooms-details .info-text {
  display: inline-block;
  margin-right: 12px;
  margin-bottom: 12px;
}

.seach-property-details
  .information
  .info-property
  .rooms-details
  .info-text
  img {
  margin-right: 4px;
}

.seach-property-details
  .information
  .info-property
  .rooms-details
  .info-text
  .text {
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 24px;
  color: #000000;
}

.seach-property-details .information .info-property .maps {
  position: relative;
  height: 220px;
  margin-bottom: 20px;
}

.seach-property-details .information .info-property .maps .map {
  margin-bottom: 53px;
  padding: 70px 95px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url("https://d3kuhob3u5bpy2.cloudfront.net/2bd40c7c-0e69-4d88-9161-27dc0b3fba1a.svg");
  filter: blur(8px);
}

.seach-property-details .information .info-property .maps .map.filter {
  filter: blur(0);
}

.seach-property-details .information .info-property .maps .text {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 243px;
  transform: translate(-50%, -50%);
  text-align: center;
  padding: 8px 16px;
  background-color: #e0f7fa;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  text-transform: uppercase;
  color: #000000;
  cursor: pointer;
}

.seach-property-details .information .info-property .maps .text.filter {
  display: none;
}

.seach-property-details .information .info-property .maps .circle {
  border-radius: 50%;
  position: absolute;
  top: 40%;
  left: 50%;
  width: 110px;
  height: 110px;
  transform: translate(-50%, -50%);
  background: #00bcd4;
  opacity: 0.3;
}

.seach-property-details .information .info-property .offer {
  margin-bottom: 17px;
}

.seach-property-details .information .info-property .offer .title {
  margin-bottom: 8px;
  font-style: normal;
  font-weight: 900;
  font-size: 18px;
  line-height: 22px;
  text-transform: uppercase;
  color: #000000;
}

.seach-property-details .information .info-property .offer .price {
  display: inline-flex;
  margin-right: 8px;
  padding: 3px 8px;
  font-style: normal;
  font-weight: bold;
  font-size: 36px;
  line-height: 45px;
  text-transform: uppercase;
  color: #000000;
  background-color: #e0f7fa;
  border-radius: 4px;
}

.seach-property-details .information .info-property .offer .time {
  display: inline-block;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 18px;
  color: #000000;
}

.seach-property-details .information .info-property .suggest-offer .suggest,
.seach-property-details .information .info-property .suggest-offer .equals {
  float: left;
}

.seach-property-details .information .info-property .suggest-offer .suggest {
  margin-right: 25px;
  padding: 15px 32px;
  background-color: #00bcd4;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  text-transform: uppercase;
  color: #ffffff;
  border-radius: 4px;
}

.seach-property-details .information .info-property .suggest-offer span {
  margin-left: 10px;
}

.seach-property-details .information .info-property .suggest-offer .equals {
  padding: 15px 23px;
  background-color: #fff;
  border: 1px solid #00bcd4;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  text-transform: uppercase;
  border-radius: 4px;
  color: #000000;
}

/* style gallery  */
._2c50p {
  background-color: #fff !important;
  padding: 16px 0 0 0 !important;
  margin: 0 !important;
}

._Pfcmb {
  background-color: #fff !important;
}

._t1897 {
  border-radius: 12px !important;
}

._-LJ2W {
  background-color: #fff !important;
}

._-cAh3 {
  margin-right: 16px !important;
  border-radius: 12px !important;
}

.seach-property-details .price-list {
  filter: drop-shadow(0px 1px 1px rgba(0, 0, 0, 0.14))
    drop-shadow(0px 2px 1px rgba(0, 0, 0, 0.12))
    drop-shadow(0px 1px 3px rgba(0, 0, 0, 0.2));
}

.seach-property-details .price-list .content-left {
  padding: 24px 24px 32px 24px;
  background: #ffffff;
  border-radius: 12px 0px 0px 12px;
}

.seach-property-details .price-list .content-left .title {
  margin-bottom: 24px;
  text-align: center;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
  text-transform: uppercase;
  color: #000000;
}

.seach-property-details .price-list .content-left .price {
  margin-bottom: 8px;
  text-align: center;
}

.seach-property-details .price-list .content-left .price .number {
  font-style: normal;
  font-weight: bold;
  font-size: 48px;
  line-height: 60px;
  text-transform: uppercase;
  color: #000000;
}

.seach-property-details .price-list .content-left .price-square {
  margin-bottom: 8px;
  text-align: center;
}

.seach-property-details .price-list .content-left .price-square .left-part {
  margin-right: 10px;
}

.seach-property-details .price-list .content-left .price-square .left-part,
.seach-property-details .price-list .content-left .price-square .right-part {
  display: inline-block;
}

.seach-property-details .price-list .content-left .price-square .text {
  margin-bottom: 4px;
  text-align: center;
  font-size: 18px;
  line-height: 21px;
  font-weight: 700;
}

.seach-property-details .price-list .content-left .price-square .title {
  margin-right: 6px;
  padding: 3px 8px;
  background-color: #fff59d;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  color: #000000;
  border-radius: 8px;
}

.seach-property-details .price-list .content-left .attention {
  margin-bottom: 16px;
  padding: 12px;
  background-color: #fff59d;
  text-align: center;
}

.seach-property-details .price-list .content-left .attention img {
  display: inline-block;
  margin-right: 5px;
}

.seach-property-details .price-list .content-left .attention .high {
  display: inline-block;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 18px;
  color: #000000;
}

.seach-property-details .price-list .content-left .paragraph {
  margin-bottom: 82px;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 24px;
  color: #000000;
}

.seach-property-details .price-list .content-left .suggest {
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  text-transform: uppercase;
  color: #ffffff;
  background-color: #00bcd4;
  padding: 17px 0;
  text-align: center;
  display: block;
  border-radius: 4px;
}

.seach-property-details .price-list .content-left .suggest span {
  margin-left: 20px;
}

.seach-property-details .price-list .content-center {
  padding: 24px 60px 50px 60px;
  text-align: center;
  background-color: #fafafa;
}

.seach-property-details .price-list .content-center .title {
  margin-bottom: 28px;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
  text-transform: uppercase;
  color: #000000;
}

.seach-property-details .price-list .content-center .fee {
  margin-bottom: 45px;
}

.seach-property-details .price-list .content-center .fee p {
  display: inline-block;
  margin: 19px 6px 0 0;
  font-style: normal;
  font-weight: 900;
  font-size: 18px;
  line-height: 22px;
  text-transform: uppercase;
  color: #000000;
}

.seach-property-details .price-list .content-center .fee .fee-title {
  margin-bottom: 8px;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 12px;
  color: #000000;
}

.seach-property-details .price-list .content-center .fee .textField {
  display: inline-block;
  border-radius: 4px;
  width: 55px;
  background-color: #ededed;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 18px;
  color: rgba(0, 0, 0, 0.6);
}

.seach-property-details .price-list .content-center .fee .switch {
  display: inline-block;
}

.seach-property-details .price-list .content-center .fee .tooltip-icon {
  display: inline-block;
  margin-left: -15px;
}

.seach-property-details
  .price-list
  .content-center
  .col:nth-last-child(1)
  .fee
  .tooltip-icon
  img {
  margin-top: 3px;
}

.seach-property-details
  .price-list
  .content-center
  .col:nth-last-child(1)
  .fee
  .tooltip-icon {
  margin: 2px 5px 0 17px;
}

.seach-property-details .price-list .content-center .chart {
  width: 200px;
  position: relative;
  margin: 0 auto;
}

.seach-property-details .price-list .content-center .chart .text {
  position: absolute;
  top: 35%;
  left: 19%;
}

.seach-property-details .price-list .content-center .chart .title {
  margin-bottom: 0;
  font-style: normal;
  font-weight: bold;
  font-size: 32px;
  line-height: 37px;
  color: #000000;
}

.seach-property-details .price-list .content-center .chart .sub-title {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 18px;
  color: #000000;
}

.seach-property-details .price-list .content-right {
  padding: 24px 38px;
  background-color: #ffffff;
  border-bottom-right-radius: 12px;
  border-top-right-radius: 12px;
}

.seach-property-details .price-list .content-right .price {
  margin-bottom: 28px;
}

.seach-property-details .price-list .content-right .price .price-left {
  display: inline-block;
}

.seach-property-details .price-list .content-right .price .price-right {
  display: inline-block;
}

.seach-property-details .price-list .content-right .deposit {
  margin-bottom: 28px;
}

.seach-property-details .price-list .content-right .deposit .deposit-left {
  display: inline-block;
}

.seach-property-details .price-list .content-right .deposit .deposit-right {
  display: inline-block;
}

.seach-property-details .price-list .content-right .title {
  text-align: center;
  margin-bottom: 16px;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
  text-transform: uppercase;
  color: #000000;
}

.seach-property-details .price-list .content-right .textField {
  background-color: #ededed;
  border-radius: 4px;
}

.seach-property-details .price-list .content-right .textField.primary {
  width: 200px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-right: 1px solid #00bcd4;
}

.seach-property-details .price-list .content-right .textField.secondary {
  width: 70px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.seach-property-details .price-list .content-right .slider-container .term {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 12px;
  color: rgba(0, 0, 0, 0.6);
}

.seach-property-details .price-list .content-right .slider-container .slider {
  position: relative;
  color: #000;
  margin-bottom: 0;
}

.seach-property-details .price-list .content-right .slider-container .years {
  margin-top: -5px;
  margin-bottom: 22px;
  font-size: 12px;
  line-height: 14px;
  color: #9d9d9d;
  text-align: center;
}

.seach-property-details .price-list .content-right .suggest {
  display: block;
  margin: 0 auto;
  padding: 15px 32px !important;
  text-align: center;
  font-style: normal;
  font-weight: bold;
  background: #00bcd4;
  font-size: 14px;
  line-height: 18px;
  text-transform: uppercase;
  color: #ffffff;
  border-radius: 4px;
}

.seach-property-details .price-list .content-right .footer-calculation {
  margin-bottom: 1px;
}

.seach-property-details .price-list .content-right .footer-calculation .text {
  float: left;
  margin-top: 3px;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 12px;
  color: #000000;
}

.seach-property-details .price-list .content-right .footer-calculation .price {
  float: right;
  margin-top: -4px;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 33px;
  color: #000000;
}

.seach-property-details .history {
  margin: 0 149px;
  padding: 25px 24px;
  background: #ffffff;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px rgba(0, 0, 0, 0.12),
    0px 1px 3px rgba(0, 0, 0, 0.2);
  border-radius: 12px;
  border-radius: 12px;
}

.seach-property-details .history .row {
  margin-bottom: 19px;
}

.seach-property-details .history .row .date {
  float: left;
  width: 120px;
  margin-top: 3px;
  margin-right: 26px;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 12px;
  color: #000000;
}

.seach-property-details .history .row .history-text {
  float: left;
  margin-right: 100px;
}

.seach-property-details .history .row .history-text img {
  display: inline-block;
  margin-right: 10px;
  margin-top: 3px;
}

.seach-property-details .history .row .history-text .text {
  display: inline-block;
  font-size: 18px;
  margin-top: -20px;
}

.seach-property-details .history .row .history-text .text span {
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
}

.seach-property-details .history .row .history-text .text .underscore {
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
}

.seach-property-details .history .row .history-text.over {
  margin-right: 75px;
}

.seach-property-details .history .row .history-text.over img {
  margin-top: -3px;
}

.seach-property-details .history .row .history-statistics {
  float: right;
  width: 260px;
}

.seach-property-details .history .row .history-statistics img {
  display: inline-block;
  margin-right: 7px;
  margin-top: 3px;
}

.seach-property-details .history .row .history-statistics .text {
  display: inline-block;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 24px;
  color: #000000;
}

.seach-property-details .question-and-answers .col {
  position: relative;
}

.seach-property-details .question-and-answers {
  margin: 0 40px;
}

.seach-property-details .question-and-answers .title-sub {
  margin-bottom: 8px;
  font-size: 18px;
  font-weight: 500;
  line-height: 21px;
}

.seach-property-details .question-and-answers .content {
  margin-bottom: 32px;
  padding: 19px 20px 42px 16px;
  background-color: #e2e2e2;
  border-radius: 12px;
}

.seach-property-details .question-and-answers .content .top-text .left-part {
  float: left;
}

.seach-property-details .question-and-answers .content .top-text .right-part {
  float: right;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.4px;
  color: #656565;
}

.seach-property-details
  .question-and-answers
  .content
  .top-text
  .left-part
  .circle {
  display: inline-block;
  margin-right: 8px;
  margin-bottom: 10px;
}

.seach-property-details
  .question-and-answers
  .content
  .top-text
  .left-part
  .author {
  display: inline-block;
  font-style: normal;
  font-weight: 900;
  font-size: 18px;
  line-height: 22px;
  text-transform: uppercase;
  color: #656565;
}

.seach-property-details .question-and-answers .content .text {
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 24px;
  color: #000000;
}

.seach-property-details .question-and-answers .test1 {
  position: absolute;
  top: 44%;
  left: 97%;
  width: 42px;
  height: 24px;
  background-color: #e2e2e2;
}

.seach-property-details .question-and-answers .button {
  margin: 0 auto;
  padding: 12px 32px !important;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  text-transform: uppercase;
  background: #00bcd4;
  color: #ffffff;
  border-radius: 4px;
}

.seach-property-details .info .app-bar-info {
  background: #ffffff;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px rgba(0, 0, 0, 0.12),
    0px 1px 3px rgba(0, 0, 0, 0.2);
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
}

.seach-property-details .info .app-bar-info .tabs-info-container .tabs-title {
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  text-transform: uppercase;
  color: #000000;
  padding: 16px 24px;
  cursor: pointer;
}

.seach-property-details
  .info
  .app-bar-info
  .tabs-info-container
  .tabs-title[aria-selected="true"] {
  background-color: #f4f4f4;
  border-radius: 12px 12px 0 0;
}

.seach-property-details .info .container-info {
  background-color: #f4f4f4;
  height: max-content !important;
  padding: 20px;
}

.seach-property-details .info .container-info .title {
  margin-bottom: 15px;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 26px;
  color: #000000;
}

.seach-property-details .info .container-info .title.text {
  margin-top: 0;
}

.seach-property-details .info .container-info .title-sub {
  margin-bottom: 8px;
  font-size: 18px;
  line-height: 21px;
  font-weight: 400;
  color: #000;
}

.seach-property-details .info .container-info .text-content {
  margin-top: 0;
  margin-bottom: 30px;
  font-size: 18px;
  text-align: justify;
  line-height: 21px;
}

.seach-property-details .info .MuiTabs-indicator {
  display: none;
}

.seach-property-details .info .container-info .content {
  margin-bottom: 30px;
}

.seach-property-details .info .container-info .content .chip {
  float: left;
  padding: 8px 16px;
  margin-right: 16px;
  background-color: #ffffff;
  font-size: 16px;
  line-height: 18px;
  border-radius: 4px;
  color: #454545;
}

.seach-property-details .info .container-info .info-header {
  background-color: #f4f4f4;
  display: flex;
}

.seach-property-details .info .container-info .info-header .info-left {
  float: left;
}

.seach-property-details .info .container-info .info-header .info-left .title {
  margin-bottom: 8px;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 26px;
  color: #000000;
}

.seach-property-details
  .info
  .container-info
  .info-header
  .info-left
  .title.text {
  margin-top: 24px;
}

.seach-property-details
  .info
  .container-info
  .info-header
  .info-left
  .title-sub {
  margin-bottom: 8px;
  font-size: 18px;
  line-height: 21px;
  font-weight: 400;
  color: #000;
}

.seach-property-details .list-of-preliminary-offers-seller {
  margin: 15px 0 243px;
}

.seach-property-details .list-of-preliminary-offers-seller .title {
  text-align: center;
  margin-bottom: 40px;
  font-style: normal;
  font-weight: 600;
  font-size: 48px;
  line-height: 52px;
  color: #000000;
}

.seach-property-details .list-of-preliminary-offers-seller .cols-full {
  margin: 0 auto;
  margin-bottom: 30px;
  display: flex;
  align-items: center;
  padding: 25px 5px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.seach-property-details .list-of-preliminary-offers-seller .price {
  text-align: center;
  padding: 3px 8px;
  background-color: #e0f7fa;
  border-radius: 4px;
  font-style: normal;
  font-weight: bold;
  font-size: 36px;
  line-height: 45px;
  text-transform: uppercase;
  color: #000000;
}

.seach-property-details .list-of-preliminary-offers-seller .will-pay {
  text-align: left;
}

.seach-property-details
  .list-of-preliminary-offers-seller
  .will-pay
  .will-pay-author {
  margin-bottom: 10px;
  cursor: pointer;
}

.seach-property-details
  .list-of-preliminary-offers-seller
  .will-pay
  .will-pay-author
  img {
  display: inline-block;
  margin-right: 10px;
  width: 29px;
}

.seach-property-details
  .list-of-preliminary-offers-seller
  .will-pay
  .will-pay-author
  p {
  display: inline-block;
  font-style: normal;
  font-weight: 900;
  font-size: 18px;
  line-height: 22px;
  text-align: right;
  text-transform: uppercase;
  color: #000000;
}

.seach-property-details
  .list-of-preliminary-offers-seller
  .will-pay
  .will-pay-method
  .image {
  display: inline-block;
  padding: 3px 7px;
  margin-right: 10px;
  border-radius: 50px;
  background-color: #e8e8e8;
}

.seach-property-details
  .list-of-preliminary-offers-seller
  .will-pay
  .will-pay-method
  .image
  img {
  width: 16px;
  padding-top: 3px;
}

.seach-property-details
  .list-of-preliminary-offers-seller
  .will-pay
  .will-pay-method
  p {
  display: inline-block;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 18px;
  color: #000000;
}

.seach-property-details .list-of-preliminary-offers-seller .feedback {
  padding-top: 6px;
  padding-bottom: 6px;
  background-color: #fff9c4;
  text-align: center;
  border-radius: 4px;
  border: 1px solid #f57f17;
}

.seach-property-details .list-of-preliminary-offers-seller .feedback p {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 18px;
  color: #000000;
}

.seach-property-details .list-of-preliminary-offers-seller .date p {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 12px;
  color: #000000;
}

.seach-property-details .list-of-preliminary-offers-seller .date p.time {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 18px;
  color: #000000;
}

.seach-property-details
  .info
  .container-info
  .info-header
  .info-left
  .text-content {
  margin-top: 0;
  margin-bottom: 30px;
  margin-right: 561px;
  font-size: 18px;
  line-height: 21px;
}

.seach-property-details .info .MuiTabs-indicator {
  display: none;
}

.seach-property-details .info .container-info .info-header .info-right {
  float: right;
  min-width: 154px;
  text-align: center;
}

.seach-property-details
  .info
  .container-info
  .info-header
  .info-right
  .profile {
  margin-bottom: 4px;
  width: 64px;
  height: 64px;
}

.seach-property-details .info .container-info .info-header .info-right .name {
  margin-bottom: 8px;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 26px;
}

.seach-property-details
  .info
  .container-info
  .info-header
  .info-right
  .user-type {
  margin-bottom: 8px;
  display: flex;
  justify-content: center;
}

.seach-property-details
  .info
  .container-info
  .info-header
  .info-right
  .user-type
  .text-owner {
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 24px;
}

.seach-property-details
  .info
  .container-info
  .info-header
  .info-right
  .user-type
  .text-owner
  > img {
  margin-right: 4px;
}

.seach-property-details
  .info
  .container-info
  .info-header
  .info-right
  .view-profile {
  border: 2px solid #00bcd4;
  box-sizing: border-box;
  border-radius: 4px;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  text-transform: uppercase;
}

.seach-property-details .info .container-info .content {
  margin-bottom: 16px;
}

.seach-property-details .info .container-info .content .chip {
  float: left;
  padding: 8px 16px;
  margin-right: 16px;
  background-color: #ffffff;
  font-size: 16px;
  line-height: 18px;
  border-radius: 4px;
  color: #454545;
}

.seach-property-details .info .container-info .profile-seller {
  margin-bottom: 4px;
  text-align: center;
}

.seach-property-details
  .info
  .container-info
  .profile-seller
  .image-profile-picture {
  width: 64px;
  height: 64px;
  border-radius: 50%;
}

.seach-property-details .info .container-info .profile-seller .seller-name {
  margin-bottom: 8px;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 26px;
  color: #000000;
}

.seach-property-details .info .container-info .profile-seller .owner {
  display: flex;
  justify-content: center;
}

.seach-property-details
  .info
  .container-info
  .profile-seller
  .owner
  .ownershipType {
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  color: #000000;
  margin-top: -4px;
  margin-left: 10px;
}

.seach-property-details .info .container-info .profile-seller .button {
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  text-transform: uppercase;
  color: #000000;
}

.seach-property-details .info .container-document .col {
  padding: 0 12px;
}

.seach-property-details .info .container-document .content {
  padding: 25px 0;
  text-align: center;
  background-color: #fff;
  border-radius: 8px;
}

.seach-property-details .info .container-document .content .primary-svg {
  display: inline-block;
  margin-top: 3px;
  margin-right: 10px;
}

.seach-property-details .info .container-document .content .title {
  display: inline-block;
  margin-bottom: 18px;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 26px;
  color: #000000;
}

.seach-property-details .info .container-document .content .info-text img {
  display: inline-block;
  margin-right: 10px;
}

.seach-property-details .info .container-document .content .info-text .text {
  display: inline-block;
  font-size: 25px;
  font-weight: 500;
  line-height: 28px;
}

.seach-property-details .neighborhoods .content {
  position: relative;
  margin-bottom: 32px;
}

.seach-property-details .neighborhoods .content .text {
  position: absolute;
  bottom: 23px;
  right: 23px;
}

.seach-property-details .neighborhoods .content .text .photos {
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 26px;
  color: #000000;
}

.seach-property-details .neighborhoods .content .neighbor {
  position: absolute;
  top: 24px;
  left: 24px;
}

.seach-property-details .neighborhoods .rating {
  margin-bottom: 15px;
}

.seach-property-details .neighborhoods .rating .title {
  float: left;
  margin-right: 6px;
  font-style: normal;
  font-weight: 900;
  font-size: 18px;
  line-height: 22px;
  text-transform: uppercase;
  color: #000000;
}

.seach-property-details .neighborhoods .rating .all-stars {
  float: left;
  margin-right: 6px;
  margin-left: -3px;
}

.seach-property-details .neighborhoods .rating .result {
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 10px;
  color: #000000;
}

.seach-property-details .neighborhoods .content .neighbor p:nth-child(1) {
  margin-bottom: 4px;
  font-style: normal;
  font-weight: bold;
  font-size: 32px;
  line-height: 32px;
  color: #000000;
}

.seach-property-details .neighborhoods .content .neighbor p:nth-child(2) {
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 24px;
  color: #000000;
}

.seach-property-details .neighborhoods .content .primary-image {
  height: 600px;
}

.seach-property-details .neighborhoods .average-price {
  margin-bottom: 13px;
}

.seach-property-details .neighborhoods .average-price .image {
  float: left;
  margin-right: 10px;
}

.seach-property-details .neighborhoods .average-price .price {
  float: left;
  margin-right: 8px;
  font-style: normal;
  font-weight: 900;
  font-size: 18px;
  line-height: 22px;
  text-transform: uppercase;
  color: #000000;
}

.seach-property-details .neighborhoods .average-price .title-average-price {
  float: left;
  margin-right: 10px;
  margin-top: -5px;
  padding: 5px 16px 5px 10px;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  background: #fff59d;
  border-radius: 4px;
  color: #000000;
}

.seach-property-details .neighborhoods .average-price .tooltip-icon {
  float: left;
}

.seach-property-details .neighborhoods .minutes-to-the-center {
  margin-bottom: 13px;
}

.seach-property-details .neighborhoods .minutes-to-the-center .image {
  float: left;
  margin-right: 10px;
}

.seach-property-details .neighborhoods .minutes-to-the-center .text {
  float: left;
  font-style: normal;
  font-weight: 900;
  font-size: 18px;
  line-height: 22px;
  text-transform: uppercase;
  color: #000000;
}

.seach-property-details .neighborhoods .paragraph {
  margin-bottom: 16px;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 24px;
  color: #000000;
}

.seach-property-details .neighborhoods .content .neighbor.small p:nth-child(1) {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #000000;
}

.seach-property-details .neighborhoods .content .neighbor.small {
  top: 16px;
  left: 15px;
}

.seach-property-details .neighborhoods .content .primary-image.small {
  width: 100%;
  height: 160px;
  border-radius: 8px;
}

.seach-property-details .neighborhoods .content .text-small {
  position: absolute;
  bottom: 0;
  right: 0;
  background-color: #efefef;
}

.seach-property-details .neighborhoods .content .text-small .photos-small {
  position: absolute;
  bottom: 20px;
  right: 16px;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 18px;
  color: #000000;
}

.seach-property-details .neighborhoods .content .map {
  height: 160px;
  background-color: #efefef;
  border-radius: 8px;
}

.seach-property-details .neighborhoods .communication-place .content {
  position: relative;
  background-color: #f8f8f8;
  padding: 14px;
  border-radius: 12px;
}

.seach-property-details .neighborhoods .communication-place .content .point {
  position: absolute;
  top: 32px;
  left: 29px;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 28px;
  color: #000000;
}

.seach-property-details .neighborhoods .communication-place .content .chart {
  width: 64px;
  float: left;
  margin-right: 16px;
}

.seach-property-details .neighborhoods .communication-place .content .info {
  float: left;
  margin-bottom: 0;
}

.seach-property-details
  .neighborhoods
  .communication-place
  .content
  .info
  .transport {
  margin-bottom: 9px;
}

.seach-property-details
  .neighborhoods
  .communication-place
  .content
  .info
  .transport
  img {
  float: left;
  margin-right: 12px;
}

.seach-property-details
  .neighborhoods
  .communication-place
  .content
  .info
  .transport
  .transport-text {
  float: left;
  font-style: normal;
  font-weight: 900;
  font-size: 18px;
  line-height: 22px;
  text-transform: uppercase;
  color: #000000;
}

.seach-property-details
  .neighborhoods
  .communication-place
  .content
  .info
  .transport-info
  .transport-info-text {
  float: left;
  margin-right: 12px;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.4px;
  color: #000000;
}

.seach-property-details
  .neighborhoods
  .communication-place
  .content
  .info
  .transport-info
  .transport-info-text
  span {
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 15px;
  color: #000000;
}

.seach-property-details .neighborhoods .what-do-the-locals-say {
  margin-top: 32px;
}

.seach-property-details .neighborhoods .what-do-the-locals-say .title-sub {
  margin-bottom: 30px;
  text-align: left;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 26px;
  color: #000000;
}

.seach-property-details .neighborhoods .what-do-the-locals-say .content {
  margin-bottom: 30px;
}

.seach-property-details .neighborhoods .what-do-the-locals-say .content img {
  float: left;
  margin-right: 10px;
}

.seach-property-details
  .neighborhoods
  .what-do-the-locals-say
  .content
  .text-need {
  float: left;
  margin-right: 8px;
  font-style: normal;
  font-weight: 900;
  font-size: 18px;
  line-height: 22px;
  text-transform: uppercase;
  color: #000000;
}

.seach-property-details .neighborhoods .what-do-the-locals-say .content .like {
  float: left;
  margin-top: -8px;
  background-color: #c8e6c9;
  padding: 5px 8px;
  border: 1px solid #388e3c;
  box-sizing: border-box;
  border-radius: 8px;
}

.seach-property-details
  .neighborhoods
  .what-do-the-locals-say
  .content
  .like.rotate-img {
  background-color: #ffcdd2;
  border: 1px solid #b71c1c;
  box-sizing: border-box;
  border-radius: 8px;
}

.seach-property-details
  .neighborhoods
  .what-do-the-locals-say
  .content
  .like
  img {
  filter: invert(25%) sepia(16%) saturate(2198%) hue-rotate(74deg)
    brightness(102%) contrast(89%);
}

.seach-property-details
  .neighborhoods
  .what-do-the-locals-say
  .content
  .like.rotate-img
  img {
  margin-top: 3px;
  transform: rotate(180deg);
  filter: invert(17%) sepia(100%) saturate(2329%) hue-rotate(353deg)
    brightness(98%) contrast(88%);
}

.seach-property-details
  .neighborhoods
  .what-do-the-locals-say
  .content
  .like.rotate-img
  .percentage {
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 23px;
  color: #b71c1c;
}

.seach-property-details
  .neighborhoods
  .what-do-the-locals-say
  .content
  .like
  img {
  float: left;
  margin-right: 5px;
}

.seach-property-details
  .neighborhoods
  .what-do-the-locals-say
  .content
  .like
  .percentage {
  float: left;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 23px;
  color: #1b5e20;
}

.seach-property-details .neighborhoods .what-do-the-locals-say .button-more {
  float: right;
  display: inline-block;
  margin-bottom: 40px;
  padding: 12px 32px;
  background-color: #00bcd4;
  border-radius: 4px;
  cursor: pointer;
  transition: transform 1s;
}

.seach-property-details .neighborhoods .what-do-the-locals-say .text-more {
  float: left;
  color: #fff;
  margin-right: 10px;
  font-size: 12px;
  line-height: 14px;
}

.seach-property-details .neighborhoods .what-do-the-locals-say .arrow {
  float: left;
  height: 0;
  padding-top: 3px;
  transition: transform 1s;
}

.seach-property-details .neighborhoods .what-do-the-locals-say .rotate {
  transform: rotate(180deg) !important;
  transition: transform 1s;
}

.seach-property-details
  .neighborhoods
  .what-do-the-locals-say
  .col3of12
  .links {
  float: right;
}

.seach-property-details
  .neighborhoods
  .what-do-the-locals-say
  .col3of12
  .links
  a {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.4px;
  text-decoration-line: underline !important;
  color: #000000;
}

.seach-property-details
  .neighborhoods
  .what-do-the-locals-say
  .col3of12
  .links
  a:nth-child(1) {
  margin-right: 14px;
}

.seach-property-details .neighborhoods .recommendations {
  margin-bottom: 64px;
}

.seach-property-details .neighborhoods .recommendations .title-sub {
  margin-bottom: 16px;
  font-style: normal;
  font-weight: bold;
  font-size: 32px;
  line-height: 32px;
  color: #000000;
}

.seach-property-details
  .neighborhoods
  .recommendations
  .content-recommendations {
  position: relative;
  width: 80% !important;
  height: 310px;
  margin-right: 10px;
  padding: 24px;
  background: #ffffff;
  box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.14), 0px 3px 3px rgba(0, 0, 0, 0.12),
    0px 1px 8px rgba(0, 0, 0, 0.2);
  border-radius: 12px;
}

.seach-property-details
  .neighborhoods
  .recommendations
  .content-recommendations
  .content-top {
  margin-bottom: 16px;
}

.seach-property-details
  .neighborhoods
  .recommendations
  .content-recommendations
  .content-top
  img {
  float: left;
  margin-right: 4px;
}

.seach-property-details
  .neighborhoods
  .recommendations
  .content-recommendations
  .content-top
  .text {
  float: left;
}

.seach-property-details
  .neighborhoods
  .recommendations
  .content-recommendations
  .content-top
  .text
  .text-title {
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  text-transform: uppercase;
  color: #000000;
}

.seach-property-details
  .neighborhoods
  .recommendations
  .content-recommendations
  .content-top
  .text
  .text-title-sub {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.4px;
  color: #000000;
}

.seach-property-details
  .neighborhoods
  .recommendations
  .content-recommendations
  .content-center {
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 24px;
  color: #000000;
}

.seach-property-details
  .neighborhoods
  .recommendations
  .content-recommendations
  .content-bottom {
  position: absolute;
  width: 89%;
  bottom: 24px;
}

.seach-property-details
  .neighborhoods
  .recommendations
  .content-recommendations
  .content-bottom
  .content-bottom-left {
  float: left;
  padding: 5px 9px;
  background-color: #e0f7fa;
  border-radius: 8px;
}

.seach-property-details
  .neighborhoods
  .recommendations
  .content-recommendations
  .content-bottom
  .content-bottom-left
  img {
  float: left;
  margin-right: 5px;
}

.seach-property-details
  .neighborhoods
  .recommendations
  .content-recommendations
  .content-bottom
  .content-bottom-left
  .percentage {
  float: left;
  font-size: 18px;
  font-weight: 700;
  line-height: 21px;
}

.seach-property-details
  .neighborhoods
  .recommendations
  .content-recommendations
  .content-bottom
  .content-bottom-right {
  float: right;
  margin-top: 10px;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.4px;
  color: #000000;
}

.seach-property-details .neighborhoods .lawyer-and-assistant {
  margin: 0 149px 64px;
}

.seach-property-details .neighborhoods .lawyer-and-assistant .content {
  background-color: #f4f4f4;
  padding: 50px 24px 24px;
  border-radius: 12px;
  text-align: center;
}

.seach-property-details .neighborhoods .lawyer-and-assistant .content img {
  margin-bottom: 25px;
}

.seach-property-details .neighborhoods .lawyer-and-assistant .content .title {
  margin-bottom: 12px;
  font-style: normal;
  font-weight: bold;
  font-size: 32px;
  line-height: 32px;
  text-align: center;
  color: #000000;
}

.seach-property-details
  .neighborhoods
  .lawyer-and-assistant
  .content
  .sub-title {
  margin-bottom: 24px;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 24px;
  text-align: center;
  color: #000000;
}

.seach-property-details
  .neighborhoods
  .lawyer-and-assistant
  .content
  .button-more {
  display: inline-block;
  padding: 16px 32px;
  font-size: 16px;
  font-weight: 400;
  color: #fff;
  background-color: #00bcd4;
  border-radius: 4px;
  text-transform: uppercase;
}

.seach-property-details .info .desktop-offers {
  margin-top: 30px;
}

.seach-property-details .info .desktop-offers {
  background: rgba(176, 176, 176, 0.15);
  padding: 20px 0;
}

.seach-property-details .info .desktop-offers .title {
  font-size: 30px;
  font-weight: 700;
  line-height: 36.31px;
  text-align: center;
  color: rgba(0, 188, 212, 1);
  padding: 15px 0;
}

.seach-property-details .info .desktop-offers hr {
  height: 2px;
  width: 100%;
  color: rgba(0, 188, 212, 1);
  margin-bottom: 20px;
  margin-top: 0;
}

.seach-property-details .info .desktop-offers hr.table {
  color: rgba(0, 0, 0, 0.3);
}

.seach-property-details .info .desktop-offers .table-header {
  background: rgba(255, 255, 255, 1);
  border-radius: 20px;
  padding: 10px 0;
  margin: 0 7px;
  width: 99%;
}

.seach-property-details .info .desktop-offers .title-table {
  font-size: 25px;
  font-weight: 900;
  line-height: 37.5px;
  text-align: center;
  margin-bottom: 15px;
}

.seach-property-details .info .desktop-offers .title-table.hr-table {
  border-right: 0.3px solid rgba(0, 0, 0, 0.3);
}

.seach-property-details .info .desktop-offers .price {
  font-size: 25px;
  font-weight: 700;
  line-height: 24px;
  text-align: center;
  color: rgba(0, 128, 0, 1);
  margin-bottom: 10px;
}

.seach-property-details .info .desktop-offers .time {
  font-size: 25px;
  font-weight: 400;
  line-height: 24px;
  text-align: center;
  color: rgba(0, 0, 0, 1);
  margin-bottom: 10px;
}

.seach-property-details .info .desktop-offers .feedback p {
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
  text-align: center;
  margin-bottom: 10px;
}

.seach-property-details
  .info
  .list-of-preliminary-offers-buyer
  .will-pay
  .will-pay-author {
  margin-bottom: 10px;
  cursor: pointer;
}

.seach-property-details
  .info
  .list-of-preliminary-offers-buyer
  .will-pay
  .will-pay-author
  img {
  display: inline-block;
  margin-right: 10px;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  object-fit: cover;
}

.seach-property-details
  .info
  .list-of-preliminary-offers-buyer
  .will-pay
  .will-pay-author
  p {
  display: inline-block;
  font-style: normal;
  font-weight: 900;
  font-size: 18px;
  line-height: 22px;
  text-align: right;
  text-transform: uppercase;
  color: #000000;
}

.seach-property-details
  .info
  .list-of-preliminary-offers-buyer
  .will-pay
  .will-pay-method
  .image {
  display: inline-block;
  padding: 3px 7px;
  margin-right: 10px;
  border-radius: 50px;
  background-color: #e8e8e8;
}

.seach-property-details
  .info
  .list-of-preliminary-offers-buyer
  .will-pay
  .will-pay-method
  .image
  img {
  width: 20px;
  height: 20px;
  margin-top: -4px;
}

.seach-property-details
  .info
  .list-of-preliminary-offers-buyer
  .will-pay
  .will-pay-method
  p {
  display: inline-block;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 18px;
  color: #000000;
  margin-bottom: 0;
}

.seach-property-details .info .list-of-preliminary-offers-buyer .date p {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 12px;
  color: #000000;
}

.seach-property-details .info .list-of-preliminary-offers-buyer .date p.time {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 18px;
  color: #000000;
}

.seach-property-details .no-offers-yet .title {
  margin-bottom: 16px;
  font-style: normal;
  font-weight: 1;
  font-size: 48px;
  line-height: 52px;
  text-align: center;
  color: #000000;
}

.seach-property-details .no-offers-yet img {
  display: flex;
  margin: 0 auto;
}

.seach-property-details .no-offers-yet .title-sub {
  margin-bottom: 16px;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 24px;
  text-align: center;
  color: #000000;
}

.seach-property-details .no-offers-yet .suggest-offer {
  display: block;
  margin: 0 auto 30px auto;
  width: 300px;
  padding: 15px 32px;
  background: #00bcd4;
  border-radius: 4px;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  text-transform: uppercase;
}

.seach-property-details .no-offers-yet .suggest-offer .suggest {
  color: #ffffff;
}

.seach-property-details .lawyer-and-assistant {
  margin: 100px 149px 64px;
}

.seach-property-details .lawyer-and-assistant .content {
  background-color: #f4f4f4;
  padding: 32px;
  border-radius: 12px;
  height: 490px;
}

.seach-property-details .lawyer-and-assistant .content .image {
  width: 130px;
  margin: 0 auto 20px auto;
}

.seach-property-details .lawyer-and-assistant .content .title {
  margin-bottom: 15px;
  font-size: 25px;
  font-weight: 700;
  line-height: 30px;
  text-align: center;
}

.seach-property-details .lawyer-and-assistant .content .sub-title {
  margin-bottom: 20px;
  font-size: 20px;
  font-weight: 400;
  line-height: 30px;
  text-align: center;
}

.seach-property-details .lawyer-and-assistant .content .button-more {
  font-size: 18px;
  font-weight: 700;
  text-align: center;
  padding: 15px 40px;
  border-radius: 5px;
  color: #fff;
  background-color: #00bcd4;
  text-transform: none;
}

.seach-property-details .lawyer-and-assistant .content .button-more:hover {
  background-color: rgba(0, 188, 212, 0.8);
}

.seach-property-details .lawyer-and-assistant .content .button-more.virtual {
  margin-top: 28px;
}

.seach-property-details .info .last-added {
  margin-top: 64px;
  margin-bottom: 32px;
}

.seach-property-details .info .last-added .last-added-title {
  margin-top: 30px;
  text-align: center;
  margin-bottom: 32px;
  font-size: 72px;
  line-height: 95px;
  font-weight: 500;
}

/* Style modal pin */
.modal-marker-details {
  width: 200px;
  height: 100px;
}

.modal-marker-details img {
  width: 100%;
  height: 100%;
}

.buyer-offer {
  padding: 20px;
  text-align: center;
}

.buyer-offer .buyer-offer-content {
  padding: 24px 21px;
}

.buyer-offer .buyer-offer-content .title-header .back {
  float: left;
  cursor: pointer;
}

.buyer-offer .buyer-offer-content .title-header .back img {
  display: inline-block;
  margin-top: 5px;
  margin-right: 10px;
}

.buyer-offer .buyer-offer-content .title-header .back p {
  display: inline-block;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 12px;
  color: #000000;
}

.buyer-offer .buyer-offer-content .title-header .close-icon {
  float: right;
  cursor: pointer;
}

.buyer-offer .buyer-offer-content .title {
  margin-bottom: 32px;
  font-style: normal;
  font-weight: 1;
  font-size: 48px;
  line-height: 52px;
  text-align: center;
  color: #000000;
}

.buyer-offer .buyer-offer-content .cols {
  margin: 0 40px;
}

.buyer-offer .buyer-offer-content .content {
  padding: 27px 25px 19px 25px;
  background-color: #f4f4f4;
  border-radius: 12px;
}

.buyer-offer .buyer-offer-content .content.active-card {
  border: 1px solid #00bcd4;
  cursor: default;
}

.buyer-offer .buyer-offer-content .content img {
  width: 72px;
  height: 80px;
  margin-bottom: 15px;
  filter: invert(18%) sepia(20%) saturate(3118%) hue-rotate(144deg)
    brightness(97%) contrast(99%);
}

.buyer-offer .buyer-offer-content .content .title-modal {
  margin-bottom: 8px;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 26px;
  text-align: center;
  color: #000000;
}

.buyer-offer .buyer-offer-content .content .sub-title-modal {
  height: 70px;
  margin-bottom: 16px;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 24px;
  text-align: center;
  color: #000000;
}

.buyer-offer .buyer-offer-content .content .button-select {
  display: block;
  padding: 11px 16px;
  background: #00bcd4;
  border-radius: 4px;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  text-transform: uppercase;
  color: #ffffff;
}

.buyer-offer .buyer-offer-content .next-button {
  margin-top: 25px;
  padding: 11px 16px;
  background-color: #fff;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  border-radius: 4px;
  text-transform: uppercase;
  color: #000000;
  border: 1px solid #00bcd4;
}

.buyer-offer .buyer-offer-content .bank-selected-modal .primary-image {
  width: 72px;
  height: 80px;
  margin-bottom: 31px;
}

.buyer-offer .buyer-offer-content .bank-selected-modal .title {
  margin-bottom: 16px;
  font-style: normal;
  font-weight: 1;
  font-size: 48px;
  line-height: 52px;
  text-align: center;
  color: #000000;
}

.buyer-offer .buyer-offer-content .bank-selected-modal .title-modal {
  margin-bottom: 31px;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 26px;
  text-align: left;
  color: #000000;
}

.buyer-offer .buyer-offer-content .bank-selected-modal .sub-title-modal {
  display: inline-block;
  margin-bottom: 45px;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 24px;
  text-align: left;
  color: #000000;
}

.buyer-offer .buyer-offer-content .content .button-select {
  display: block;
  padding: 11px 16px;
  background: #00bcd4;
  text-align: left;
  border-radius: 4px;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  text-transform: uppercase;
  color: #ffffff;
}

.buyer-offer .buyer-offer-content .content .button-select-offer {
  padding: 11px 16px;
  background: #00bcd4;
  text-align: center;
  border-radius: 4px;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  text-transform: uppercase;
  color: #ffffff;
}

.buyer-offer .buyer-offer-content .buttons .next-button {
  display: inline-block;
  padding: 11px 16px;
  background-color: #fff;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  border-radius: 4px;
  text-transform: uppercase;
  color: #000000;
  border: 1px solid #00bcd4;
}

.buyer-offer .buyer-offer-content .buttons .tooltip {
  display: inline-block;
  position: relative;
}

.buyer-offer .buyer-offer-content .buttons .tooltip img {
  margin-top: 12px;
  margin-left: 75px;
}

.buyer-offer .buyer-offer-content .buttons .tooltip .tests {
  visibility: hidden;
  padding: 16px;
  background-color: #ededed;
  border: 1px solid #000;
  border-radius: 12px;
  font-size: 0.875em;
  position: fixed;
  left: 59%;
  top: 79%;
  text-align: left;
  width: 400px;
  opacity: 0;
}

.buyer-offer .buyer-offer-content .buttons .tooltip .tests .title {
  margin-bottom: 10px;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 26px;
  color: #000000;
}

.buyer-offer .buyer-offer-content .buttons .tooltip .tests .title-sub {
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 24px;
  color: #000000;
}

.buyer-offer .buyer-offer-content .buttons .tooltip .tests:before {
  content: "";
  position: absolute;
  left: 30px;
  top: -10px;
  border: solid #000;
  border-width: 0 1px 1px 0;
  display: inline-block;
  padding: 9px;
  transform: rotate(-135deg);
  background-color: #ededed;
}

.buyer-offer .buyer-offer-content .buttons .tooltip:hover .tests {
  visibility: visible;
  opacity: 1;
}

.modal-add-to-favorites .modal-add-to-favorites-content {
  padding: 24px 21px;
}

.modal-add-to-favorites
  .modal-add-to-favorites-content
  .title-header
  .close-icon {
  float: right;
  cursor: pointer;
}

.modal-add-to-favorites
  .modal-add-to-favorites-content
  .modal-add-to-favorites {
  text-align: center;
}

.modal-add-to-favorites
  .modal-add-to-favorites-content
  .modal-add-to-favorites
  .image {
  margin: 0 auto 27px auto;
}

.modal-add-to-favorites
  .modal-add-to-favorites-content
  .modal-add-to-favorites
  .modal-title {
  font-style: normal;
  font-weight: bold;
  font-size: 32px;
  line-height: 32px;
  text-align: center;
  color: #000000;
}

.modal-add-to-favorites
  .modal-add-to-favorites-content
  .modal-add-to-favorites
  .modal-title-sub {
  display: inline-block;
  margin-right: 15px;
  margin-bottom: 24px;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 24px;
  text-align: center;
  color: #000000;
}

.modal-add-to-favorites
  .modal-add-to-favorites-content
  .modal-add-to-favorites
  .stepper {
  margin-bottom: 24px;
}

.modal-add-to-favorites
  .modal-add-to-favorites-content
  .modal-add-to-favorites
  .stepper
  .favorite-cards
  .box {
  display: inline-block;
  margin-right: 24px;
  border-radius: 50%;
  background: #ededed;
  cursor: pointer;
}

.modal-add-to-favorites
  .modal-add-to-favorites-content
  .modal-add-to-favorites
  .stepper
  .favorite-cards
  .box
  .favorite-name {
  padding: 18px 25px;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
  text-transform: uppercase;
  color: #000000;
}

.modal-add-to-favorites
  .modal-add-to-favorites-content
  .modal-add-to-favorites
  .stepper
  .favorite-cards
  .box.active-favorite
  .favorite-name {
  color: #fff;
  background: #00838f;
  border-radius: 50%;
}

.modal-add-to-favorites
  .modal-add-to-favorites-content
  .modal-add-to-favorites
  .button-add {
  padding: 12px 32px;
  background: #00bcd4;
  border-radius: 4px;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  text-transform: uppercase;
  color: #ffffff;
}

.modal-question .modal-question-content {
  padding: 24px 21px;
  text-align: center;
}

.modal-question .modal-question-content .title-header .close-icon {
  float: right;
  cursor: pointer;
}

.modal-question .modal-question-content .modal-question-body .modal-title {
  margin-bottom: 24px;
  font-style: normal;
  font-weight: bold;
  font-size: 32px;
  line-height: 32px;
  text-align: center;
  color: #000000;
}

.modal-question .modal-question-content .modal-question-body .textField {
  width: 438px;
  display: block;
  margin: 0 auto 20px auto;
}

.modal-question .modal-question-content .modal-question-body .button-add {
  padding: 12px 32px;
  background: #00bcd4;
  border-radius: 4px;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  text-transform: uppercase;
  color: #ffffff;
}

/* compare modal */
.compare-modal {
  padding: 20px;
  text-align: center;
}

/* preliminary offer modal */
.buyer-offer .buyer-offer-content .title-preliminary-offer {
  margin-bottom: 8px;
  font-style: normal;
  font-weight: 600;
  font-size: 48px;
  line-height: 52px;
  text-align: center;
  color: #000000;
}

.buyer-offer .buyer-offer-content .title-sub-preliminary-offer {
  margin: 0 115px 30px 115px;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 24px;
  text-align: center;
  color: #000000;
}

.buyer-offer .buyer-offer-content .your-preliminary-offer {
  margin-bottom: 30px;
}

.buyer-offer .buyer-offer-content .your-preliminary-offer .field-group {
  display: grid;
  text-align: center;
}

.buyer-offer .buyer-offer-content .your-preliminary-offer .field-group .label {
  margin-bottom: 4px;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.4px;
  font-weight: 400;
  cursor: default;
}

.buyer-offer .buyer-offer-content .your-preliminary-offer .field-group .box {
  width: 150px;
  margin-bottom: 24px;
  padding: 4px 8px;
  background: #f4f4f4;
  border-radius: 4px;
  z-index: 10;
}

.buyer-offer
  .buyer-offer-content
  .your-preliminary-offer
  .field-group
  .box.offer {
  margin: 0 auto;
  background: #e0f7fa;
}

.buyer-offer .buyer-offer-content .your-preliminary-offer-info {
  margin-bottom: 24px;
}

.buyer-offer .buyer-offer-content .your-preliminary-offer-info .item {
  display: inline-block;
  margin-left: 24px;
  text-align: center;
}

.buyer-offer
  .buyer-offer-content
  .your-preliminary-offer-info
  .item:nth-child(1) {
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 24px;
  color: #000000;
}

.buyer-offer
  .buyer-offer-content
  .your-preliminary-offer-info
  .item:nth-child(2) {
  background: #e8e8e8;
  border-radius: 8px;
  font-style: normal;
  padding: 4px 8px;
  font-weight: bold;
  font-size: 24px;
  line-height: 30px;
  color: #000000;
}

.buyer-offer
  .buyer-offer-content
  .your-preliminary-offer-info
  .item:nth-child(3) {
  padding: 5px;
  background: #fff59d;
  border-radius: 4px;
}

.buyer-offer
  .buyer-offer-content
  .your-preliminary-offer-info
  .item:nth-child(3)
  .squaring
  .title {
  padding: 0 5px;
  font-style: normal;
  font-weight: 900;
  font-size: 18px;
  line-height: 22px;
  color: #000000;
}

.buyer-offer
  .buyer-offer-content
  .your-preliminary-offer-info
  .item:nth-child(4) {
  padding: 5px;
  background: #fff59d;
  border-radius: 4px;
}

.buyer-offer
  .buyer-offer-content
  .your-preliminary-offer-info
  .item:nth-child(4)
  .squaring
  .title {
  padding: 0 5px;
  font-style: normal;
  font-weight: 900;
  font-size: 18px;
  line-height: 22px;
  color: #000000;
}

.buyer-offer .buyer-offer-content .offer-info {
  margin-bottom: 24px;
  padding: 24px 16px;
  background: #ededed;
  border-radius: 8px;
}

.buyer-offer .buyer-offer-content .offer-info .item {
  display: inline-block;
  margin-left: 17px;
  text-align: center;
}

.buyer-offer .buyer-offer-content .offer-info .item span {
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 24px;
  color: #000000;
}

.buyer-offer .buyer-offer-content .offer-info .item span img {
  margin-top: 4px;
}

.buyer-offer .buyer-offer-content .preliminary-offer-message {
  text-align: left;
  margin-bottom: 8px;
}

.buyer-offer .buyer-offer-content .preliminary-offer-message .label {
  text-align: left !important;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  letter-spacing: 0.4px;
  color: #000000;
}

.buyer-offer .buyer-offer-content .preliminary-offer-message .field {
  padding: 16px;
  background: #f4f4f4;
  border-radius: 8px;
  width: 100%;
}

.buyer-offer .buyer-offer-content .checkbox {
  margin-bottom: 24px;
}

.buyer-offer .buyer-offer-content .checkbox span {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 18px;
  text-align: left;
  margin-top: 5px;
  color: #000000;
}

.dialog-profile-change-offer {
  text-align: center;
}

.dialog-profile-change-offer-content {
  padding: 64px 127px;
}

.dialog-profile-change-offer-content .title-header {
  padding: 0;
  margin-bottom: 8px;
}

.dialog-profile-change-offer .title-header .add-favorite {
  font-size: 32px;
  font-weight: 700;
  line-height: 32px;
  letter-spacing: 0em;
  text-align: center;
  margin-bottom: 15px;
}

.dialog-profile-change-offer-content .title-header .title {
  display: inline-block;
  font-size: 48px;
  line-height: 52px;
  font-weight: 700;
}

.dialog-profile-change-offer-content .title-header .close-icon {
  float: right;
  cursor: pointer;
  margin-top: -40px;
  margin-right: -100px;
}

.dialog-profile-change-offer-content .content {
  padding: 0;
  overflow-x: hidden;
}

.dialog-profile-change-offer .content .text-favorite {
  font-size: 18px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: center;
  margin-bottom: 20px;
}

.dialog-profile-change-offer .content .favorite-score {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin-bottom: 30px;
  width: 65%;
  margin: 0 auto 30px auto;
}

.dialog-profile-change-offer .content .favorite-score p {
  width: 61px;
  height: 61px;
  background-color: #ededed;
  margin: 0;
  border-radius: 50px;
  padding-top: 18px;
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0em;
  cursor: pointer;
  text-align: center;
}

.dialog-profile-change-offer .content .favorite-score p.active {
  background-color: #006064;
  color: #fff;
}

.dialog-profile-change-offer-content .content .sub-title {
  font-size: 18px;
  line-height: 24px;
  font-weight: 400;
  margin-bottom: 20px;
  text-align: justify;
}

.dialog-profile-change-offer-content .content .announced-price {
  display: flex;
  padding: 0 40px;
  margin-bottom: 20px;
  align-items: center;
  justify-content: center;
}

.dialog-profile-change-offer-content .content .announced-price .label {
  font-size: 18px;
  line-height: 24px;
  font-weight: 400;
  margin-right: 24px;
}

.dialog-profile-change-offer-content .content .announced-price .box-price {
  padding: 4px 8px;
  margin-right: 24px;
  border-radius: 8px;
  background: #e8e8e8;
  color: #000000;
  font-size: 24px;
  line-height: 30px;
  font-weight: 700;
}

.dialog-profile-change-offer-content .content .announced-price .box-group {
  margin-right: 18px;
  display: flex;
  align-items: center;
}

.dialog-profile-change-offer-content
  .content
  .announced-price
  .box-group
  .box-text {
  padding: 3px 8px;
  background: #fff59d;
  border-radius: 4px;
  font-size: 18px;
  line-height: 22px;
  font-weight: 900;
  margin-right: 6px;
}

.dialog-profile-change-offer-content
  .content
  .announced-price
  .box-group:last-child {
  margin-right: 0;
}

.dialog-profile-change-offer-content .content .description {
  margin-bottom: 44px;
  text-align: left;
}

.dialog-profile-change-offer-content .content .label {
  margin-bottom: 4px;
  font-size: 12px;
  line-height: 16px;
  font-weight: 400;
  letter-spacing: 0.4px;
}

.dialog-profile-change-offer-content .content .description .label {
  margin-bottom: 4px;
  font-size: 12px;
  line-height: 16px;
  font-weight: 400;
  letter-spacing: 0.4px;
}

.dialog-profile-change-offer-content .content .description .box {
  padding: 16px;
  border-radius: 8px;
  background: #f4f4f4;
}

.dialog-profile-change-offer-content .content .actions {
  margin-top: 8px;
}

.dialog-profile-change-offer-content .content .actions .button-send {
  height: 48px;
  padding: 15px 32px;
  background: #00bcd4;
  border-radius: 4px;
  color: #fff;
  text-transform: uppercase;
  margin-right: 34px;
  font-size: 14px;
  line-height: 18px;
  font-weight: 700;
  pointer-events: auto;
  margin-bottom: 10px;
}

.dialog-profile-change-offer-content .content .actions .button-disabled {
  background-color: rgba(0, 0, 0, 0.12);
  padding: 15px 32px;
  text-transform: uppercase;
  border-radius: 4px;
  margin-right: 34px;
  font-size: 14px;
  line-height: 18px;
  font-weight: 700;
  height: 48px;
  margin-bottom: 10px;
}

.dialog-profile-offer-accepted {
  text-align: center;
}

.dialog-profile-offer-accepted-content {
  padding: 30px 127px;
}

.dialog-profile-offer-accepted-content
  .be-a-virtual-assistant-earn-more-section
  .title {
  font-size: 25px;
  font-weight: 600;
  line-height: 40px;
  color: rgba(0, 0, 0, 1);
  margin-bottom: 0;
}

.dialog-profile-offer-accepted-content
  .be-a-virtual-assistant-earn-more-section
  .title-sub {
  font-size: 18px;
  font-weight: 400;
  line-height: 40px;
  color: rgba(0, 0, 0, 1);
  margin-bottom: 0;
}

.dialog-profile-offer-accepted-content
  .be-a-virtual-assistant-earn-more-section
  .see-details {
  font-size: 18px;
  font-weight: 700;
  line-height: 40px;
  width: 360px;
  padding: 15px 0;
  text-transform: none;
  margin-bottom: 20px;
  border-radius: 12px;
  border: 2px solid rgba(0, 188, 212, 1);
  background-color: #fff;
  color: rgba(0, 0, 0, 1);
}

.dialog-profile-offer-accepted-content
  .be-a-virtual-assistant-earn-more-section
  .see-details
  img {
  width: 25px;
  height: 25px;
  margin-right: 20px;
}

.dialog-profile-change-offer-content
  .be-a-virtual-assistant-earn-more-section
  .title {
  font-size: 25px;
  font-weight: 600;
  line-height: 40px;
  color: rgba(0, 0, 0, 1);
  margin-bottom: 0;
}

.dialog-profile-change-offer-content
  .be-a-virtual-assistant-earn-more-section
  .title-sub {
  font-size: 18px;
  font-weight: 400;
  line-height: 40px;
  color: rgba(0, 0, 0, 1);
  margin-bottom: 0;
}

.dialog-profile-change-offer-content
  .be-a-virtual-assistant-earn-more-section
  .see-details {
  background: rgba(0, 188, 212, 1);
  color: #ffffff;
  font-size: 18px;
  font-weight: 700;
  line-height: 40px;
  width: 360px;
  padding: 15px 0;
  text-transform: none;
  margin-bottom: 20px;
  border-radius: 12px;
}

.dialog-profile-change-offer-content
  .be-a-virtual-assistant-earn-more-section
  .see-details
  img {
  width: 25px;
  height: 25px;
  margin-right: 20px;
}

.dialog-profile-offer-accepted-content .title-header {
  padding: 0;
  margin-bottom: 8px;
}

.dialog-profile-offer-accepted-content .title-header .title {
  display: inline-block;
  font-size: 48px;
  line-height: 52px;
  font-weight: 700;
}

.dialog-profile-offer-accepted-content .check-icon {
  margin-bottom: 15px;
  height: 76px;
  width: 76px;
}

.dialog-profile-offer-accepted-content .close-icon {
  cursor: pointer;
  position: absolute;
  top: 30px;
  right: 30px;
}

.dialog-profile-offer-accepted-content .content {
  padding: 0;
  overflow-x: hidden;
}

.dialog-profile-offer-accepted-content .content .sub-title {
  font-size: 18px;
  line-height: 24px;
  font-weight: 400;
  margin-bottom: 32px;
}

.dialog-profile-offer-accepted-content .content .actions {
  margin-top: 8px;
}

.dialog-profile-offer-accepted-content .content .actions .button-close {
  height: 48px;
  padding: 15px 32px;
  background: #00bcd4;
  border-radius: 4px;
  color: #fff;
  text-transform: uppercase;
  font-size: 14px;
  line-height: 18px;
  font-weight: 700;
}

/* Style modal pin */
.modal-marker-details {
  width: 200px;
  height: 100px;
}

.modal-marker-details img {
  width: 100%;
  height: 100%;
}

.seach-property-details .info .container-info .profile-seller {
  margin-bottom: 4px;
  text-align: center;
}

.seach-property-details
  .info
  .container-info
  .profile-seller
  .image-profile-picture {
  width: 64px;
  height: 64px;
  border-radius: 50%;
  object-fit: cover;
}

.seach-property-details .info .container-info .profile-seller .seller-name {
  margin-bottom: 8px;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 26px;
  color: #000000;
  overflow: hidden;
  text-overflow: ellipsis;
}

.seach-property-details .info .container-info .profile-seller .owner {
  display: flex;
  justify-content: center;
}

.seach-property-details
  .info
  .container-info
  .profile-seller
  .owner
  .ownershipType {
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 38px;
  color: #000000;
  margin-top: -4px;
  margin-left: 10px;
}

.seach-property-details .info .container-info .profile-seller .button {
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  text-transform: uppercase;
  color: #000000;
  border: 1px solid #00bcd4;
  border-radius: 4px;
}

.seach-property-details .information .info-property .button {
  width: 100%;
  padding: 15px 32px;
  background-color: #00bcd4;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  text-transform: uppercase;
  color: #ffffff;
  border-radius: 4px;
}

.seach-property-details .information .info-property .button.isBidable {
  background: #b2ebf2;
  border-radius: 4px;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  text-transform: uppercase;
  color: #b1b1b1;
  cursor: not-allowed;
}

.media {
  width: 100%;
  height: 30rem;
  object-fit: contain;
}

.carousel {
  position: relative;
}

.index {
  position: absolute;
  top: 0.1rem;
  font-size: 0.7rem;
  font-weight: bold;
  color: black;
  padding: 0.1rem 0.3rem;
  background-color: lightgray;
  left: 1rem;
  opacity: 0.75;
}

.caption-container {
  position: absolute;
  bottom: 0;
  left: 20rem;
  right: 20rem;
}

.caption {
  padding: 0.3rem 0.5rem;
  font-size: 0.8rem;
  font-weight: bold;
  background-color: lightgray;
  color: black;
  width: fit-content;
  margin: 0 auto;
  opacity: 0.75;
}

.btn-prev,
.btn-next {
  position: absolute;
  font-size: 50px;
  color: lightgray;
  cursor: pointer;
  top: 13rem;
}

.btn-prev {
  left: 1rem;
}

.btn-next {
  right: 1rem;
}

.seach-property-details .is-bidable {
  width: 100%;
  background: #ffab91;
  box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.14), 0px 1px 10px rgba(0, 0, 0, 0.12),
    0px 2px 4px rgba(0, 0, 0, 0.2);
}

.seach-property-details .is-bidable p {
  padding: 8px;
  text-align: center;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 26px;
  color: #000000;
}

.sending-offer-scroll {
  position: fixed;
  bottom: 0;
  left: 0px;
  right: 0px;
  background: white;
  width: 100%;
  transition: transform 250ms cubic-bezier(0.445, 0.05, 0.55, 0.95) 0s;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
  height: 120px;
  text-align: center;
}

.sending-offer-scroll .text {
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 1.0800000429153442px;
  text-align: center;
  color: rgba(0, 0, 0, 0.75);
  max-width: 780px;
  text-align: center;
  margin: 0 auto;
}

.sending-offer-scroll .neighbor {
  display: inline-block;
}

.sending-offer-scroll .neighbor img {
  margin-right: 10px;
}

.sending-offer-scroll .neighbor .address {
  font-size: 24px;
  font-weight: 400;
  line-height: 36px;
  letter-spacing: 1.0800000429153442px;
}

.sending-offer-scroll .box-price {
  display: inline-block;
  font-family: Poppins;
  font-size: 30px;
  font-weight: 700;
  line-height: 24px;
  color: rgba(0, 128, 0, 1);
  margin-left: 30px;
  vertical-align: middle;
  margin-top: -5px;
}

.sending-offer-scroll {
  margin: 0 auto;
  opacity: 0;
  transition: opacity 0.5s ease;
}

.sending-offer-scroll.show {
  opacity: 1;
  z-index: 2;
  padding: 20px 0;
}

.seach-property-details .no-result {
  display: flex;
  margin: 0 auto;
}

#propertyDetails-bid-2 {
  padding: 5px 32px;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 14px;
  text-transform: uppercase;
  border-radius: 4px;
  display: inline-block;
  vertical-align: bottom;
}

#propertyDetails-bid-2 button {
  color: #ffffff !important;
  font-size: 14px;
  background-color: #00bcd4 !important;
  font-weight: 500;
  line-height: 24px;
}

.offer-open {
  display: none;
}

.seach-property-details
  .information
  .carousel-container
  .verifiedProipertyBanner {
  position: absolute;
  bottom: 60px;
  width: 100%;
  height: 60px;
  z-index: 1;
}
.play_button {
  background: #49bde0;
  position: absolute;
  border-radius: 50%;
  border: none;
  transform: scale(3);
  cursor: pointer;
  z-index: 1;
  margin-top: -50px;
}

.open_virtual_tour_property {
  background: white;
  padding: 50px;
  position: absolute;
  border-radius: 50%;
  transform: scale(1.3);
  cursor: pointer;
  border: 20px solid #49bde0;
  z-index: 1;
  margin-top: -100px;
}

.seach-property-details .back-to-search-page {
  align-items: center;
  background: #fff;
  border-radius: 50px;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.18);
  display: flex;
  line-height: 1.4;
  width: 105px;
  position: sticky;
  top: 8px;
  z-index: 1000;
  padding: 5px 10px;
  margin: 10px 13px;
}

.seach-property-details .back-to-search-page .prev-btn-search {
  cursor: pointer;
  font-size: 40px;
  color: #00bcd4;
  z-index: 999 !important;
  height: 34px;
}

.seach-property-details .back-to-search-page .image-back-search {
  width: 43px;
  height: 43px;
}

.seach-property-details
  .information
  .carousel-container
  .numberOfPhotosContainer {
  display: flex;
  width: fit-content;
  padding: 7px 15px;
  align-items: center;
  background-color: whitesmoke;
  flex-direction: row;
  position: absolute;
  bottom: 70px;
  z-index: 100;
  right: 15px;
  gap: 10px;
  border-radius: 6px;
  cursor: pointer;
}

.seach-property-details
  .information
  .carousel-container
  .numberOfPhotosContainer
  .image-icon {
  font-size: 25px;
}

@media screen and (max-width: 1300px) {
  .seach-property-details .information .section .carousel-container img {
    object-fit: cover !important;
  }

  .seach-property-details ._2c50p img {
    /* height: 100px !important; */
  }

  .seach-property-details ._-cAh3 {
    max-width: 30% !important;
    width: 25% !important;
  }
}

@media screen and (max-width: 1142px) {
  .seach-property-details .cols.cols-full {
    display: flex;
    gap: 50px;
  }

  .seach-property-details .col.col8of12 {
    flex: 1.5;
  }
}

@media screen and (max-width: 1050px) {
  .seach-property-details .info .lawyer-assistance {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 40px;
  }
}

@media screen and (max-width: 1170px) {
  .seach-property-details .info .container-info .text-content {
    margin-right: 0px;
  }

  .seach-property-details .col {
    padding: 0 !important;
  }
}

@media screen and (max-width: 1000px) {
  .play_button {
    transform: scale(0.6);
    margin-top: 0px;
  }

  .open_virtual_tour_property {
    transform: scale(0.5);
    margin-top: 0px;
  }
  .seach-property-details
    .information
    .carousel-container
    .verifiedProipertyBanner {
    bottom: 100px;
  }

  .seach-property-details .cols.cols-full {
    flex-direction: column;
  }
  .seach-property-details .col.col8of12 {
    width: 100% !important;
  }

  .seach-property-details .col.col4of12 {
    width: 100% !important;
  }

  .seach-property-details ._-cAh3 ._XQjA1 {
    zoom: 20% !important;
  }

  .seach-property-details ._2ILZE ._2c50p {
    padding: 0 !important;
  }

  .seach-property-details ._-cAh3 {
    min-width: 19% !important;
  }

  .seach-property-details
    .information
    .section
    .carousel-container
    ._1JHpX
    img {
    height: 500px !important;
  }
  .seach-property-details ._2ILZE {
    height: 557px !important;
  }
}

@media screen and (max-width: 840px) {
  .seach-property-details .info .list-of-preliminary-offers-buyer .feedback p {
    padding: 6px 10px;
  }

  .dialog-profile-change-offer .content .favorite-score {
    width: 100%;
  }

  .dialog-profile-offer-accepted-content .title-header .title {
    font-size: 35px;
  }

  .seach-property-details
    .info
    .list-of-preliminary-offers-buyer
    .desktop-offers {
    display: none;
  }

  .sending-offer-scroll {
    height: 170px;
  }
}

@media screen and (max-width: 768px) {
  .seach-property-details .info .container-info .cols-full {
    display: flex;
    flex-direction: column-reverse;
  }
  .seach-property-details .info .container-info .cols-full .col10of12 {
    width: 100%;
  }
  .seach-property-details .info .container-info .cols-full .col2of12 {
    width: 100%;
  }

  .seach-property-details .info .container-info .profile-seller .owner {
    display: flex;
    justify-content: left;
  }

  .seach-property-details .info .container-info .profile-seller {
    margin-bottom: 4px;
    text-align: left;
  }
  .seach-property-details .no-offers-yet {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .seach-property-details .info .lawyer-assistance {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 40px;
  }

  .seach-property-details
    .info
    .list-of-preliminary-offers-buyer
    .cols-full
    .col3of12 {
    width: 100%;
  }
  .seach-property-details .info .list-of-preliminary-offers-buyer {
    margin-bottom: 0px !important;
  }
  .seach-property-details .info .last-added .last-added-title {
    font-size: 40px;
  }

  /* .seach-property-details .information {
		padding: 0 20px !important;
	} */

  .dialog-profile-change-offer-content {
    padding: 40px 80px !important;
  }

  .seach-property-details ._-cAh3 {
    min-width: 18% !important;
  }

  .seach-property-details .lawyer-and-assistant {
    margin: 20px 5px;
  }

  .seach-property-details .lawyer-and-assistant .container {
    max-width: 100%;
  }

  .seach-property-details .lawyer-and-assistant .content {
    height: 100%;
  }

  .seach-property-details .lawyer-and-assistant .content .title {
    font-size: 25px;
    font-weight: 700;
    line-height: 30px;
  }

  .seach-property-details .lawyer-and-assistant .content .sub-title {
    font-size: 12px;
    font-weight: 400;
    line-height: 20px;
  }

  .seach-property-details .lawyer-and-assistant .content .button-more {
    padding: 12px 20px;
    font-size: 18px;
    font-weight: 700;
    line-height: 30px;
  }
}
.dialog-profile-change-offer-content
  .content
  .announced-price
  .box-group
  .box-text {
  margin-bottom: 0 !important;
}

@media screen and (max-width: 634px) {
  .seach-property-details
    .information
    .carousel-container
    .verifiedProipertyBanner {
    bottom: 65px;
  }
  .seach-property-details ._-cAh3 {
    min-width: 17.5% !important;
  }

  .seach-property-details ._-cAh3 ._XQjA1 {
    zoom: 15% !important;
  }
}

@media screen and (max-width: 686px) {
  .sending-offer-scroll {
    flex-direction: column;
    height: 170px;
    font-size: 12px;
  }

  .sending-offer-scroll .neighbor img {
    width: 20px;
    height: 20px;
  }

  .sending-offer-scroll .neighbor .address {
    font-size: 16px;
  }

  .sending-offer-scroll .box-price {
    font-size: 20px;
  }
}

@media screen and (max-width: 600px) {
  .seach-property-details .info .lawyer-assistance {
    padding: 0 20px;
  }
  .seach-property-details .info .lawyer-assistance .sub-section {
    width: 100%;
  }
  .seach-property-details .info .lawyer-assistance .sub-section > p {
    font-size: 16px;
    width: 100%;
    text-align: left;
    padding: 0 8px;
  }
  .seach-property-details .info .lawyer-assistance .sub-section > h1 {
    font-size: 20px;
  }

  .seach-property-details
    .information
    .section
    .carousel-container
    ._1JHpX
    img {
    height: 400px !important;
  }

  .seach-property-details ._2ILZE {
    height: 445px !important;
  }

  .seach-property-details .information .info-property .button {
    padding: 15px 10px !important;
  }

  .dialog-profile-change-offer-content {
    padding: 40px 40px !important;
  }
}

@media screen and (max-width: 600px) {
  .dialog-profile-change-offer-content .title-header .close-icon {
    right: 84px !important;
  }
}

@media screen and (max-width: 630px) {
  .dialog-profile-offer-accepted-content {
    padding: 64px;
  }
}

@media screen and (max-width: 630px) {
  .dialog-profile-offer-accepted-content {
    padding: 64px;
  }
}

@media screen and (max-width: 569px) {
  ._-cAh3 {
    margin-right: 14px !important;
  }
}

@media screen and (max-width: 520px) {
  .seach-property-details .no-offers-yet .title {
    font-size: 30px;
  }
  .dialog-profile-change-offer-content .title-header .title {
    font-size: 36px !important;
    line-height: 43px !important;
  }
}

@media screen and (max-width: 493px) {
  .seach-property-details .info .list-of-preliminary-offers-buyer .title {
    font-size: 20px !important;
  }
}
@media screen and (max-width: 475px) {
  .seach-property-details ._-cAh3 {
    min-width: 16.5% !important;
  }
}
@media screen and (max-width: 468px) {
  .dialog-profile-offer-accepted-content {
    padding: 24px;
  }

  .seach-property-details .information {
    padding: 0 !important;
    margin: 10px 20px !important;
  }
}

@media screen and (max-width: 445px) {
  .seach-property-details .info .app-bar-info .tabs-info-container .tabs-title {
    font-size: 16px;
    padding: 16px;
  }

  .dialog-profile-change-offer-content .title-header .title {
    font-size: 30px !important;
    line-height: 38px !important;
  }

  /* .seach-property-details ._-cAh3 {
    min-width: 70px !important;
    height: 70px !important;
  } */
}

@media screen and (max-width: 414px) {
  .dialog-profile-change-offer-content .content .actions .button-send {
    width: 100% !important;
  }
  .sending-offer-scroll {
    height: 190px;
  }
}

@media screen and (max-width: 400px) {
  .seach-property-details
    .information
    .carousel-container
    .verifiedProipertyBanner {
    bottom: 30px;
    left: -25px;
  }
  .seach-property-details .no-offers-yet .title {
    font-size: 24px;
  }

  .seach-property-details
    .information
    .section
    .carousel-container
    ._1JHpX
    img {
    height: 248px !important;
  }

  .seach-property-details
    .information
    .info-property
    .titles
    .left-part
    .title-sub {
    font-size: 32px !important;
  }

  .seach-property-details ._2ILZE {
    height: 300px !important;
  }

  .dialog-profile-change-offer-content {
    padding: 40px 20px !important;
  }

  .dialog-profile-change-offer-content .title-header .close-icon {
    right: 100px !important;
    top: 14px !important;
  }
  .seach-property-details
    .information
    .carousel-container
    .numberOfPhotosContainer {
    font-size: 15px;
    right: 10px;
    bottom: 65px;
  }
  .seach-property-details
    .information
    .carousel-container
    .numberOfPhotosContainer
    .image-icon {
    font-size: 20px;
  }
}

@media screen and (max-width: 370px) {
  .seach-property-details ._-cAh3 {
    min-width: 15.5% !important;
  }
  .sending-offer-scroll.show {
    padding: 5px 0;
  }
  .sending-offer-scroll .box-price {
    margin-left: 10px;
  }

  .sending-offer-scroll .text {
    font-size: 11px;
    margin: 0 5px;
  }
}

@media screen and (max-width: 341px) {
  .seach-property-details .info .app-bar-info .tabs-info-container .tabs-title {
    font-size: 16px;
    padding: 5px;
  }
}

@media screen and (max-width: 336px) {
  .seach-property-details
    .information
    .section
    .carousel-container
    ._1JHpX
    img {
    height: 200px !important;
  }

  .seach-property-details ._2ILZE {
    height: 224px !important;
  }

  .dialog-profile-change-offer-content .title-header .title {
    font-size: 25px !important;
    line-height: 30px !important;
  }
}

@media screen and (max-width: 328px) {
  .seach-property-details .info .list-of-preliminary-offers-buyer .title {
    font-size: 18px !important;
  }

  .dialog-profile-change-offer .content .favorite-score p {
    padding-top: 4px;
    margin-right: 10px;
    height: 35px;
  }
}

.how-it-works {
  text-align: center;
  margin: 0 10px;
  padding: 30px 0;
}

.how-it-works .title {
  font-size: 40px;
  font-weight: 600;
  line-height: 60px;
  text-align: center;
}

.how-it-works .title-sub {
  font-size: 18px;
  font-weight: 600;
  line-height: 26px;
  text-align: center;
  font-family: "Inter", sans-serif;
  width: 95%;
  margin: 0 auto;
}

.how-it-works .steps {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  margin: 0 auto;
}

.how-it-works .steps .step .icon {
  margin: 0 auto 10px auto;
  background: rgba(0, 188, 212, 0.2);
  border-radius: 50%;
  height: 160px;
  width: 160px;
  position: relative;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.how-it-works .steps .step .line-first-line {
  position: absolute;
  top: 13%;
  left: 78%;
  width: 70%;
  z-index: 0;
}

.how-it-works .steps .step .line-second-line {
  position: absolute;
  top: 30%;
  left: 75%;
  width: 70%;
  transform: rotate(180deg);
}

.how-it-works .steps .step .line-third-line {
  position: absolute;
  top: 13%;
  left: 78%;
  width: 70%;
}

.how-it-works .steps .step .content-title {
  font-size: 18px;
  font-weight: 500;
  line-height: 32px;
  text-align: center;
  padding: 0 20px;
  font-family: "Inter", sans-serif;
}

.how-it-works .steps .step .content-text {
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  text-align: center;
}

.step {
  flex: 1;
  max-width: 300px;
  min-width: 300px;
  width: 300px;
  text-align: center;
  position: relative;
}

.step h3 {
  font-size: 1.5rem;
  margin-bottom: 10px;
  color: #34495e;
}

.step p {
  font-size: 1rem;
  color: #7f8c8d;
}

@media (max-width: 1160px) {
  .step {
    min-width: 260px;
  }
}

@media (max-width: 1024px) {
  .how-it-works .steps {
    display: flex;
    margin: 0 auto;
    text-align: center;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
  }

  .how-it-works .steps .step .line-first-line,
  .how-it-works .steps .step .line-third-line {
    display: none;
    transform: rotate(90deg);
    position: absolute;
    top: 77%;
    left: 43%;
    width: 90%;
    z-index: 0;
  }

  .how-it-works .steps .step .line-second-line {
    width: 100%;
    top: 57%;
    left: -32%;
    transform: rotate(270deg);
    display: none;
  }

  .how-it-works .steps .step .content-text {
    padding: 0 60px;
  }

  .how-it-works .title-sub {
    font-size: 16px;
  }
}

@media (max-width: 768px) {
  .steps {
    flex-direction: column;
    align-items: flex-start;
  }

  .step {
    max-width: 100%;
    text-align: left;
  }

  .how-it-works .steps .step .line-first-line,
  .how-it-works .steps .step .line-second-line,
  .how-it-works .steps .step .line-third-line {
    display: block;
  }
}
.preview-working .schedule-viewings {
  background-color: #00bcd40d;
  display: flex;
  border-radius: 40px;
  margin-top: 40px;
  padding: 10px 30px 10px 30px;
  justify-content: center;
  align-items: center;
}
@media screen and (max-width: 634px) {
  .preview-working .schedule-viewings {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

}
.preview-working .schedule-viewings .schedule-description {
  flex-direction: column;
}
.preview-working .schedule-viewings h3 {
  font-weight: bold;
  padding-top: 10px;
}
.preview-working .preview-working-titles {
  display: flex;
  justify-content: space-around;
  margin-top: 40px;
  
}
.preview-working .preview-working-titles .our-system {
  background-color: #00bcd41a;
  color: #00bcd4;
  
  /* padding: 5px 30px 5px 30px; */
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  border-radius: 10px;
  font-size: 30px;
  font-weight: bold;
  
}
.preview-working .preview-working-titles .our-system p{
  text-align: center;
}
.preview-working .preview-working-titles .traditional-approach {
  background-color: #ff00001a;
  color: #ff000080;
  /* padding: 5px 30px 5px 30px; */
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  text-align: center;
  border-radius: 10px;
  font-size: 30px;
  font-weight: bold;


}
.preview-working .preview-working-titles .traditional-approach p{
  text-align: center;
}
@media screen and (max-width: 634px) {
.preview-working .preview-working-titles .our-system {
  font-size: smaller;
}
.preview-working .preview-working-titles .traditional-approach {
  font-size: smaller;
}
}
.preview-working .preview-working-titles h2 {
  font-weight: bold;
}
.preview-working .working-preview-image {
  margin-top: 20px;
  width: 100%;
}
.preview-working .working-preview-image img {
  border-radius: 10px;
}
.feature-container {
  background-color: "white";
  padding: "20px";
  border-radius: "8px";
  box-shadow: "0 2px 10px rgba(0,0,0,0.1)";
  margin-top: 30px;
  width: "50%"
}
.feature-container .feature-container-title {
  display: flex;
  align-items: center;
  justify-content: start;
  gap: 0.3em;
  border-radius: "8px";
  padding:5px 5px 5px 10px;
  font-size: 15px;
  font-weight: bold;
  box-shadow: "0 2px 10px rgba(0,0,0,0.1)";
 
}
@media screen and (max-width: 620px) {
.feature-container .description-popup {
 display: block;

}

}
.feature-container .feature-container-title p {
  padding-top: 10px;
}
.features-section {
  margin-top: 30px;
}

.description-popup {
  display: none;
}

@media (min-width: 769px) {
  .feature-container:hover .description-popup {
    display: flex; 
  }

}

@media (max-width: 768px) {
  .description-popup {
    display: flex;  
  }
}
@media screen and (max-width: 1208px) {
 .preview-working .preview-working-titles{
  display: flex;
  gap: 1em;
 }
 .feature-container .feature-container-title {
  padding: 5px;
  margin-top: 2px;
 }

}
@media screen and (max-width: 620px) {
  .preview-working .preview-working-titles{
   display: flex;
   gap: 1em;
  }
  .feature-container .feature-container-title {
   padding: 0px;
   margin-top: 0px;
  }
  .feature-container .feature-container-title p {
    font-size: 13px;
  }
}
@media (min-width: 769px) {
  .features-section div:hover {
    cursor: pointer;
    /* Additional hover styles */
  }
}
@media screen and (max-width: 400px) {
  .preview-working .preview-working-titles .traditional-approach {
    margin-top: -28px;
  }
}