.client-requests { background: #E2E2E2; }

.client-requests .invitation-box { padding: 16px; background: #EFEFEF; border: 1px solid #000; box-sizing: border-box; border-radius: 10px; margin-bottom: 24px; }
.client-requests .invitation-box .title { font-size: 16px; line-height: 18px; margin-bottom: 8px; }

.client-requests .invitation-box .avatar-info .image { width: 64px; height: 64px; border-radius: 50%; background: #000; float: left; margin-right: 8px; }
.client-requests .invitation-box .avatar-info .name { font-size: 24px; line-height: 28px; }

.client-requests .invitation-box .avatar-info .rating { margin-top: 10px; }
.client-requests .invitation-box .avatar-info .rating .icon { margin-right: 7px; }
.client-requests .invitation-box .avatar-info .rating .number { font-size: 18px; line-height: 21px; font-weight: 500; vertical-align: top; margin-right: 14px; }

.client-requests .invitation-box .buttons { margin-top: 16px; }
.client-requests .invitation-box .buttons .button-hire { height: 40px; padding: 12px 32px; background: #000; border-radius: 12px; color: #FFFFFF; text-transform: none; margin-right: 16px; font-size: 12px; line-height: 14px; font-weight: 400; }
.client-requests .invitation-box .buttons .button-cancel { height: 40px; padding: 12px 32px; border-radius: 12px; text-transform: none; font-size: 12px; line-height: 14px; font-weight: 400; }

.client-requests .additional-info .block { width: 160px; height: 45px; background: #E2E2E2; border-radius: 4px; display: flex; margin-bottom: 15px; }
.client-requests .additional-info .title { font-size: 16px; line-height: 18px; margin-bottom: 2px; }
.client-requests .additional-info .block .icon { width: 20px; height: 20px; margin: 11px; color: #000000; float: left; }
.client-requests .additional-info .block .icon-action { width: 25px; height: 25px; margin: 10px 10px 10px 0; color: #000000; float: left; }
.client-requests .additional-info .block .icon-action:first-child { margin: 10px; }
.client-requests .additional-info .block .text { font-size: 24px; line-height: 28px; margin-top: 8px; }

.client-requests .invitation-box .property-listing { margin-top: 4px; }
.client-requests .invitation-box .property-listing .property-row { display: table; margin-bottom: 12px; }
.client-requests .invitation-box .property-listing .property-row .text { font-size: 14px; line-height: 16px; font-weight: 500; margin-right: 6px; float: left; }
.client-requests .invitation-box .property-listing .property-row .icon { float: left; margin-right: 6px; margin-top: -4px; }
.client-requests .invitation-box .property-listing .property-row .link { color: #000; text-decoration: underline !important; font-size: 14px; line-height: 16px; font-weight: 700; float: left; }

.client-requests .invitation-box .message .title { font-size: 16px; line-height: 18px; margin-bottom: 8px; }
.client-requests .invitation-box .message .message-box { padding: 16px; background: #FFFFFF; border-radius: 10px; }
.client-requests .invitation-box .message .message-box .text { font-size: 16px; line-height: 18px; font-weight: 400; }

/* Dialog */
.dialog-invitation { text-align: center; }
.dialog-invitation-content { margin: 75px 133px 64px 126px; }
.dialog-invitation-content .close-icon { cursor: pointer; float: right; position: absolute; top: 0; right: 0; margin-top: 20px; margin-right: 21px; }
.dialog-invitation-content .content { padding-top: 42px; overflow-x: hidden; }
.dialog-invitation-content .content img { margin-bottom: 20px; }
.dialog-invitation-content .content .title { margin-bottom: 3px; font-size: 36px; }
.dialog-invitation-content .content .text { margin-bottom: 29px; font-size: 16px; line-height: 18px; }
.dialog-invitation-content .content .actions { margin-top: 24px; }
.dialog-invitation-content .content .actions div .button-submit, .dialog-invitation-content .content .actions div .button-reject { height: 40px; width: 105px; margin-bottom: 20px; padding: 12px 32px; background-color: #000; border-radius: 12px; color: #E2E2E2; text-transform: none; font-size: 12px; line-height: 14px; font-weight: 400; }
.dialog-invitation-content .content .actions div .button-close { height: 40px; width: 105px; padding: 12px 32px; border: 1px solid #000; border-radius: 12px; color: #000; text-transform: none; font-size: 12px; line-height: 14px; font-weight: 400; }