.dashboard-virtual-assistant .assistant-header { text-align: center; }
.dashboard-virtual-assistant .assistant-header .main-title { margin: 64px 0px 32px 0px; font-family: Lora; font-style: normal; font-weight: 500; font-size: 72px; line-height: 76px; }
.dashboard-virtual-assistant .assistant-header .button { margin: 15px 0px; }

.dashboard-virtual-assistant .root {  flex-grow: 1; background-color: #F8F8F8; border-radius: 12px 12px 0px 0px; margin-bottom: 128px; width: 100%; }

.dashboard-virtual-assistant .root .tabs, 
.dashboard-virtual-assistant .app-bar {
    box-shadow: none; 
    background: rgba(176, 176, 176, 0.15);
}

.dashboard-virtual-assistant .root .tabs .tab { 
    padding: 16px 24px; 
    font-size: 18px;
    font-weight: 700;
    line-height: 27px;
    text-align: center;
    color: #000000;
    text-transform: none;
}

.dashboard-virtual-assistant .root .tabs .tab.active-tab { 
    cursor: default; 
    pointer-events: none;
    color:rgba(0, 188, 212, 1);
}

.dashboard-virtual-assistant .root span[class^='PrivateTabIndicator-root-'] {
    background-color: rgba(0, 188, 212, 1) !important;
    height: 3px !important;
}

.dashboard-virtual-assistant .root .tabs .buyer .number, .dashboard-virtual-assistant .root .tabs .seller .number, .dashboard-virtual-assistant .root .tabs .invitation .number { padding: 2px 5px; margin-top: -7px; float: right; background-color: #00838F; border-radius: 50%; font-family: Source Sans Pro; font-style: normal; font-weight: bold; font-size: 12px; line-height: 15px; text-align: center; color: #FFFFFF; }

.dashboard-virtual-assistant .root .tab-panel-body { background: #F8F8F8; border-radius: 0px; }

.dialog-deactivate-account-virtual-assistant-seller {
    margin: 0 10px;
}

.dialog-deactivate-account-virtual-assistant-seller-content {
    padding: 22px;
    text-align: center;
}

.dialog-deactivate-account-virtual-assistant-seller-content .title-header {
    padding: 0;
    margin-bottom: 16px;
}

.dialog-deactivate-account-virtual-assistant-seller-content .title-header .title {
    font-family: Lora;
    font-style: normal;
    font-weight: bold;
    font-size: 32px;
    line-height: 32px;
    margin-top: 22px;
    padding: 0px 140px;
}

.dialog-deactivate-account-virtual-assistant-seller-content .title-header .close-icon {
    cursor: pointer;
    float: right;
    margin-top: -24px;
}

.dialog-deactivate-account-virtual-assistant-seller-content .content {
    padding: 0;
    overflow-x: hidden;
}

.dialog-deactivate-account-virtual-assistant-seller-content .content .actions {
    margin-top: 24px;
}

.dialog-deactivate-account-virtual-assistant-seller-content .content .actions .button-continue {
    margin-right: 24px;
    padding: 15px 32px;
    background: #F44336;
    border-radius: 4px;
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 18px;
    text-transform: uppercase;
    color: #FFFFFF;
}

.dialog-deactivate-account-virtual-assistant-seller-content .content .actions .button-back {
    padding: 15px 32px;
    border: 2px solid #00BCD4;
    box-sizing: border-box;
    border-radius: 4px;
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 18px;
    text-transform: uppercase;
    color: #000000;
}

.dialog-deactivate-account-virtual-assistant-seller-content .button-send {
    height: 45px;
    padding: 15px 32px;
    background: #00BCD4;
    border-radius: 4px;
    color: #fff;
    text-transform: uppercase;
    margin-right: 25px;
    font-size: 14px;
    line-height: 18px;
    font-family: Source Sans Pro;
    font-weight: 700;
}

.dialog-deactivate-account-virtual-assistant-seller-content .button-send:hover {
    height: 45px;
    padding: 15px 32px;
    background: #00BCD4;
    border-radius: 4px;
    color: #fff;
    text-transform: uppercase;
    margin-right: 25px;
    font-size: 14px;
    line-height: 18px;
    font-family: Source Sans Pro;
}

@media screen and (max-width:768px){
.dashboard-virtual-assistant .assistant-header .main-title {
     margin: 64px 0px 32px 0px; 
     font-family: Lora; 
     font-style: normal; 
     font-weight: 500; 
     font-size: 40px; 
     line-height: 76px; 
    }

}

@media screen and (max-width:400px){
    .dashboard-virtual-assistant .root .tabs .tab { 
        padding: 16px 10px; 
        font-family: Source Sans Pro; 
        font-style: normal; 
        font-weight: bold; 
        font-size: 12px; 
        line-height: 18px; 
        text-transform: uppercase; 
        color: #000000; 
        opacity: 1; 
    }

    .dialog-deactivate-account-virtual-assistant-seller-content .title-header .title {
        padding: 0;
    }

}