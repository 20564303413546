.photographer-header-content { display: flex; margin-top: 32px; }
.photographer-header-content .avatar { position: relative; width: 112px; height: 112px; border-radius: 50%; }
.photographer-header-content .avatar .photo { width: 112px; height: 112px; }
.photographer-header-content .avatar .icon { position: absolute; right: 2px; bottom: 15px; width: 29px; }

.photographer-header-content .user-details { margin-left: 24px; }
.photographer-header-content .user-details .title-primary { margin-bottom: 16px; font-family: Lora; font-style: normal; font-weight: bold; font-size: 32px; line-height: 32px; color: #000000; }

.photographer-header-content .user-details .rating { display: flex; margin-bottom: 20px; }
.photographer-header-content .user-details .rating .title { margin-right: 10px; font-family: Source Sans Pro; font-style: normal; font-weight: 900; font-size: 18px; line-height: 22px; text-transform: uppercase; color: #000000; }
.photographer-header-content .user-details .rating .star { width: 16px; height: 16px; margin-top: 2.5px; filter: invert(48%) sepia(93%) saturate(3629%) hue-rotate(157deg) brightness(90%) contrast(102%); }
.photographer-header-content .user-details .rating .result { margin-left: 8px; font-family: Source Sans Pro; font-style: normal; font-weight: normal; font-size: 18px; line-height: 24px; color: #000000; }

.photographer-header-content .user-details .block { display: flex; }
.photographer-header-content .user-details .block .icon { width: 20px; height: 20px; margin-right: 9px; color: #000000; }
.photographer-header-content .user-details .block .text { font-family: Source Sans Pro; font-style: normal; font-weight: bold; font-size: 18px; line-height: 24px; color: #000000; }

.photographer-header-content .progress-update { display: flex; flex-direction: column; margin-left: auto; text-align: center; background: #FFFFFF; border-radius: 4px; padding: 16px 64px; }
.photographer-header-content .progress-update .progress-icon { width: 33px; height: 33px; margin: 0px auto; }
.photographer-header-content .progress-update .text { margin-top: 8px; font-family: Source Sans Pro; font-style: normal; font-weight: bold; font-size: 14px; line-height: 18px; text-align: center; text-transform: uppercase; color: #000000; }
.photographer-header-content .progress-update .button-meeting-request { margin-top: 18px; background: #00BCD4; border-radius: 4px; padding: 19px 32px; font-family: Source Sans Pro; font-style: normal; font-weight: bold; font-size: 14px; line-height: 18px; text-transform: uppercase; color: #FFFFFF; }

.photographer-header-content .price-info { text-align: center; }
.photographer-header-content .price-info .price { margin-top: 27px; font-family: Source Sans Pro; font-style: normal; font-weight: bold; font-size: 48px; line-height: 60px; text-transform: uppercase; color: #000000; }
.photographer-header-content .price-info .price span { font-family: Source Sans Pro; font-style: normal; font-weight: 900; font-size: 18px; line-height: 22px; text-transform: uppercase; color: #000000; }

.photographer-header-content .price-info .text { margin-bottom: 32px; font-family: Source Sans Pro; font-style: normal; font-weight: normal; font-size: 18px; line-height: 24px; color: #000000; }
.photographer-header-content .price-info .text .icon { margin-left: 6px; vertical-align: bottom; }