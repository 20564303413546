/* General styles */
body {
  font-family: Arial, sans-serif;
}

/* Container for the login form */
.login-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 6rem 1rem;
  min-height: 100vh;
  background-color: #f7fafc; /* equivalent to bg-gray-50 */
}

/* Logo styles */
.logo {
  display: flex;
  align-items: center;
  margin-bottom: 1.5rem;
  font-size: 2rem;
  font-weight: 600;
  color: #1a202c; /* equivalent to text-gray-900 */
}

.logo img {
  width: 9rem;
  height: 9rem;
  margin-right: 0.5rem;
  object-fit: contain;
}

/* Form container */
.form-container {
  width: 100%;
  max-width: 24rem; /* equivalent to sm:max-w-md */
  background-color: #ffffff;
  border-radius: 0.5rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 1.5rem;
}

/* Form title */
.form-title {
  text-align: center;
  font-size: 1.25rem;
  font-weight: 700;
  color: #1a202c; /* equivalent to text-gray-900 */
  margin-bottom: 1.5rem;
}

/* Form fields */
.form-field {
  margin-bottom: 1rem;
}

.form-field label {
  display: block;
  margin-bottom: 0.5rem;
  font-size: 0.875rem;
  font-weight: 500;
  color: #1a202c; /* equivalent to text-gray-900 */
}

.form-field input {
  width: 100%;
  padding: 0.625rem;
  font-size: 0.875rem;
  border: 1px solid #cbd5e0; /* equivalent to border-gray-300 */
  border-radius: 0.375rem;
  background-color: #edf2f7; /* equivalent to bg-gray-50 */
  color: #1a202c; /* equivalent to text-gray-900 */
}

/* Submit button */
.submit-button {
  width: 100%;
  padding: 0.625rem;
  font-size: 0.875rem;
  font-weight: 500;
  text-align: center;
  color: #ffffff;
  background-color: #3182ce; /* equivalent to bg-primary-600 */
  border: none;
  border-radius: 0.375rem;
  cursor: pointer;
}

.submit-button:hover {
  background-color: #2b6cb0; /* equivalent to hover:bg-primary-700 */
}

.submit-button:disabled {
  background-color: #a0aec0; /* equivalent to dark:bg-primary-600 */
  cursor: not-allowed;
}
