.stop-deposit {
    margin-top: 47px;
}

.stop-deposit .stop-deposit-content {
    margin-bottom: 32px;
}

.stop-deposit .stop-deposit-content .text {
    margin: 0 120px 32px 120px;
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 24px;
    text-align: center;
    color: #000000;
}

.stop-deposit .content {
    margin: 0 auto 64px auto;
    padding: 40px;
    background: #F8F8F8;
    border-radius: 8px;
    text-align: center;
}

.stop-deposit .content .title {
    margin-bottom: 8px;
    font-family: Lora;
    font-style: normal;
    font-weight: bold;
    font-size: 32px;
    line-height: 32px;
    text-align: center;
    color: #000000;
}

.stop-deposit .content .title-sub {
    margin-bottom: 24px;
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 24px;
    text-align: center;
    color: #000000;
}

.stop-deposit .content .add-payment-method {
    padding: 15px 32px;
    background: #00BCD4;
    border-radius: 4px;
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 18px;
    text-transform: uppercase;
    color: #FFFFFF;
}

.stop-deposit .advance-to-the-preliminary-contract {
    margin-bottom: 30px;
}

.stop-deposit .advance-to-the-preliminary-contract .title {
    margin-bottom: 8px;
    font-family: Lora;
    font-style: normal;
    font-weight: bold;
    font-size: 32px;
    line-height: 32px;
    text-align: center;
    color: #000000;
}

.stop-deposit .advance-to-the-preliminary-contract .title-sub {
    margin: 0 130px 26px 130px;
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 24px;
    text-align: center;
    color: #000000;
}

.stop-deposit .deposit-content .text {
    margin: 0 120px 32px 120px;
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 24px;
    text-align: center;
    color: #000000;
}

.stop-deposit .deposit-content .content {
    margin: 0 auto 64px auto;
    padding: 40px;
    background: #F8F8F8;
    border-radius: 8px;
    text-align: center;
}

.stop-deposit .deposit-content .content .title {
    margin-bottom: 16px;
    font-family: Lora;
    font-style: normal;
    font-weight: bold;
    font-size: 32px;
    line-height: 32px;
    text-align: center;
    color: #000000;
}

.stop-deposit .deposit-content .content .title-primary {
    margin-bottom: 8px;
    font-family: Lora;
    font-style: normal;
    font-weight: bold;
    font-size: 32px;
    line-height: 32px;
    text-align: center;
    color: #000000;
}

.stop-deposit .deposit-content .content .congratulation {
    margin-bottom: 24px;
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 24px;
    text-align: center;
    color: #000000;
}

.stop-deposit .deposit-content .content .image-primary {
    margin-bottom: 13px;
}

.stop-deposit .deposit-content .content .deposit .deposit-field {
    display: inline-block;
}

.stop-deposit .deposit-content .content .deposit .price-number {
    display: inline-block;
    height: 58px;
    padding: 4px 8px;
    background: #E0F7FA;
    border-radius: 4px;
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: bold;
    font-size: 17px;
    color: #000000;
    width: 170px;
    border: none;
}

.stop-deposit .deposit-content .content .deposit .button-deposit {
    padding: 20px;
    background: #00BCD4;
    border-radius: 0px 4px 4px 0px;
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 18px;
    text-transform: uppercase;
    color: #FFFFFF;
}

.card-stripe {
    margin-top: 100px;
}

@media screen and (max-width: 900px) {

    .stop-deposit .deposit-content .content{
        margin: 0 auto 30px auto;
    }
}

@media screen and (max-width: 600px) {

    .stop-deposit .stop-deposit-content .text{
        margin: 0 60px 32px 60px;
    }
}

@media screen and (max-width: 424px) {
    .stop-deposit .deposit-content .content{
        padding: 40px 10px;
    }

    .stop-deposit .stop-deposit-content .text{
        margin: 0 25px 32px 25px;
    }

    .stop-deposit .stop-deposit-content .text {
        text-align: justify;
    }

    .stop-deposit .deposit-content .content .deposit .deposit-field {
        margin-bottom: 20px;
    }

    .dialog-common-multi-profile-content .MuiGrid-grid-xs-12 {
        margin-top: 10px;
    }
   
}