.addMovement-wrapper {
  width: 100%;
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 16px;
  border-radius: 4px;
  > .top {
    width: calc(100% - 32px);
    margin-top: 16px;
    height: 50px;
    z-index: 500;
    > h1 {
      font-family: Lora;
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
      line-height: 26px;
      margin-bottom: 16px;
    }
    > p {
      font-family: Source Sans Pro;
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: 18px;
      padding: 3px 8px;
      width: fit-content;
      margin-bottom: 8px;
      text-transform: uppercase;
    }
    > .highlighted {
      background-color: #e0f7fa;
    }
  }
  > .timePeriod-buttons-wrapper {
    width: calc(100% - 32px);
    display: flex;
    > div {
      font-family: Source Sans Pro;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 18px;
      padding: 3px 8px;
      margin-right: 8px;
      cursor: pointer;
    }
    > .highlighted {
      background: #e0f7fa;
    }
  }
}
