
.lawyer-profile-contract { padding: 32px; text-align: left; }

.lawyer-profile-contract .lawyer-header { display: flex; margin-bottom: 35px; }

.lawyer-profile-contract .lawyer-header .avatar { width: 178px; height: 178px; position: relative; border-radius: 50%; margin-right: 24px; background: url("https://d3kuhob3u5bpy2.cloudfront.net/078aba46-fd8b-4057-83be-3ec65121d0ce.jpg"); }
.lawyer-profile-contract .lawyer-header .avatar .icon { position: absolute; right: 0; top: 70%; }

.lawyer-profile-contract .lawyer-header .lawyer-info .name { font-size: 48px; line-height: 52px; font-family: Lora; font-weight: 500; margin-bottom: 12px; }

.lawyer-profile-contract .lawyer-header .lawyer-info .rating { display: flex; align-items: center; margin-bottom: 12px; }
.lawyer-profile-contract .lawyer-header .lawyer-info .rating .text { margin-right: 10px; font-size: 18px; line-height: 22px; font-family: Source Sans Pro; font-weight: 900; text-transform: uppercase; }
.lawyer-profile-contract .lawyer-header .lawyer-info .rating .star { margin: -3px 3px 0 0; filter: invert(43%) sepia(89%) saturate(677%) hue-rotate(143deg) brightness(87%) contrast(102%); }
.lawyer-profile-contract .lawyer-header .lawyer-info .rating .result { margin: -3px 0 0 7px; font-size: 18px; line-height: 24px; font-family: Source Sans Pro; font-weight: 400; }

.lawyer-profile-contract .lawyer-header .lawyer-info .additional-info { display: flex; }
.lawyer-profile-contract .lawyer-header .lawyer-info .additional-info .label { color: #656565; margin-bottom: 6px; font-size: 16px; line-height: 18px; font-family: Roboto; font-weight: 400; }
.lawyer-profile-contract .lawyer-header .lawyer-info .additional-info .second-half .label-content { display: flex; }
.lawyer-profile-contract .lawyer-header .lawyer-info .additional-info .second-half .label-content .label { margin-right: 9px; }
.lawyer-profile-contract .lawyer-header .lawyer-info .additional-info .second-half .label-content .icon { margin-top: -8px; }
.lawyer-profile-contract .lawyer-header .lawyer-info .additional-info .block { padding: 16px; background: #FFFFFF; border-radius: 4px; margin-right: 24px; }
.lawyer-profile-contract .lawyer-header .lawyer-info .additional-info .block .icon { width: 20px; height: 20px; margin-right: 11px; }

.lawyer-profile-contract .lawyer-box { padding: 28px 24px; background: #FFFFFF; border: 3px solid #4DD0E1; border-radius: 12px; margin-bottom: 32px; position: relative; }

.lawyer-profile-contract .lawyer-box .lawyer-content { height: 300px; overflow-y: auto; margin-bottom: 50px; }
.lawyer-profile-contract .lawyer-box .lawyer-content .icon-box { margin-bottom: 16px; }
.lawyer-profile-contract .lawyer-box .lawyer-content .icon-box .icon { margin-left: 5px; filter: invert(43%) sepia(89%) saturate(677%) hue-rotate(143deg) brightness(87%) contrast(102%); }
.lawyer-profile-contract .lawyer-box .lawyer-content .text { font-size: 20px; line-height: 24px; font-family: Source Sans Pro; font-weight: 700; text-transform: uppercase; margin-bottom: 16px; }
.lawyer-profile-contract .lawyer-box .lawyer-content .price { font-size: 48px; line-height: 44px; font-family: Source Sans Pro; font-weight: 700; }
.lawyer-profile-contract .lawyer-box .lawyer-content .price .currency { font-size: 18px; line-height: 22px; font-family: Source Sans Pro; font-weight: 900; margin-left: -8px; }

.lawyer-profile-contract .lawyer-box .checkbox { position: absolute; bottom: 28px; right: 24px; transform: scale(1.2); color: #000; }

.lawyer-profile-contract .text-footer { font-size: 24px; line-height: 26px; font-family: Lora; font-weight: 700; margin-bottom: 24px; }
.lawyer-profile-contract .button { padding: 15px 32px; height: 48px; border-radius: 4px; background: #00BCD4; color: #fff; font-size: 14px; line-height: 18px; font-family: Source Sans Pro; font-weight: 700; text-transform: uppercase; }
.lawyer-profile-contract .button:hover { background: #00BCD4; }

/* Scroll */
.lawyer-profile-contract .lawyer-box .lawyer-content::-webkit-scrollbar { width: 6px !important; background: gray; }
.lawyer-profile-contract .lawyer-box .lawyer-content::-webkit-scrollbar-track { background : #fff; border-radius: 12px; }
.lawyer-profile-contract .lawyer-box .lawyer-content::-webkit-scrollbar-thumb { background-color :#000; border-radius: 10px; }