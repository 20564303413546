.seller-profile .profile-lawyer .profile-lawyer-hire-content {
    margin: 64px 180px;;
    background: #F8F8F8;
    border-radius: 10px;
    text-align: center;
}

.profile-lawyer .profile-lawyer-hire-content .title {
    font-size: 32px;
    line-height: 32px;
    font-weight: 700;
    font-family: Lora;
    margin-bottom: 8px;
}

.profile-lawyer .profile-lawyer-hire-content .text {
    margin: 0 20px 24px 20px;
    font-size: 18px;
    line-height: 24px;
    font-family: Source Sans Pro;
    font-weight: 400;
}

.profile-lawyer .profile-lawyer-hire-content .box-content {
    margin-bottom: 24px;
}

.profile-lawyer .profile-lawyer-hire-content .box-content .box {
    padding: 40px 24px 26px 24px;
    background: #EDEDED;
    border-radius: 4px;
    text-align: center;
}

.profile-lawyer .profile-lawyer-hire-content .box-content .box .main-icon .icon {
    margin-bottom: 10px;
}

.profile-lawyer .profile-lawyer-hire-content .box-content .box .text-box {
    font-size: 16px;
    line-height: 18px;
    font-family: Source Sans Pro;
    font-weight: 400;
}

.profile-lawyer .profile-lawyer-hire-content .button {
    padding: 15px 32px;
    background: #00BCD4;
    color: #fff;
    border-radius: 4px;
    text-transform: uppercase;
    font-family: Source Sans Pro;
    font-size: 14px;
    line-height: 18px;
    font-weight: 700;
}