.mobile-filter-fields {
  margin-bottom: 32px;
}
.mobile-filter-fields .MuiSvgIcon-root {
  color: #0097a7;
}
.mobile-filter-fields .title {
  margin-bottom: 4px;
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 24px;
  color: #000000;
}

.mobile-filter-fields .filter-modal .mobile-filters .title {
  margin-top: 4px;
  margin-left: 3px;
}
.mobile-filter-fields .filter-modal .mobile-filters .autocomplete {
  background: #ededed;
  border-radius: 4px;
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #000000;
}
.mobile-filter-fields .filter-modal .mobile-filters .textField {
  margin: 0 !important;
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #000000;
}
.mobile-filter-fields .filter-modal .mobile-filters .textField input {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #000;
}

.mobile-filter-fields .filter-modal .mobile-filters .search {
  width: 100%;
  position: relative;
  float: left;
  padding: 15px 0 15px 13px;
  background-color: #ededed;
  border-radius: 4px;
}
.mobile-filter-fields
  .filter-modal
  .mobile-filters
  .search
  .search-field::placeholder {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: rgba(0, 0, 0, 0.6);
}
.mobile-filter-fields .filter-modal .mobile-filters .search .search-field {
  float: left;
  width: 80%;
  padding: 0;
  background-color: #ededed;
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  border: none;
  color: rgba(0, 0, 0, 0.6);
}
.mobile-filter-fields .filter-modal .mobile-filters .search .search-image {
  float: left;
  margin: 5px 8px 0 2px;
  filter: invert(32%) sepia(74%) saturate(1688%) hue-rotate(159deg)
    brightness(101%) contrast(101%);
}

.mobile-filter-fields .mobile-filter-nav .filter-item .autocomplete {
  background: #ededed;
  border-radius: 4px;
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #000000;
}

.mobile-filter-fields
  .mobile-filter-nav
  .filter-item
  .MuiFormControl-marginNormal {
  margin: 0;
}

.mobile-filter-fields .cols-full {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.mobile-filter-fields .cols-full .col3of12 {
  width: 100%;
}

.mobile-filter-fields .mobile-filter-nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.mobile-filter-fields .mobile-filter-nav .filter-item .filter-button {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  background: #00bcd4;
}
.mobile-filter-fields
  .mobile-filter-nav
  .filter-item
  .filter-button
  .filter-icon {
  transform: rotate(270deg);
  font-size: 20px;
}

.mobile-filter-fields
  .mobile-filter-nav
  .filter-item
  .MuiAutocomplete-inputRoot
  .MuiAutocomplete-input {
  width: 100%;
  min-width: 30px;
}

.mobile-filter-fields .filter-modal .filter-modal-header {
  width: 100%;
  height: 50px;
  background: #00bcd4;
  display: flex;
  justify-content: right;
  align-items: center;
  padding-right: 10px;
}

.mobile-filter-fields .filter-modal .mobile-close-modal {
  color: #ffffff;
  font-size: 30px;
  margin-top: -10px;
  cursor: pointer;
}

.place_autoCompletion_thirdParty_mobile .autoCompletion-input-box{
  background-color: #ededed; 
  padding: 20px;
  font-size: 16px;
  color: #000;
  width: 100%;
  border: none;
  border-radius: 8px;
}

.place_autoCompletion_thirdParty_mobile .autoComplete-input-container{
  position:relative
}

.place_autoCompletion_thirdParty_mobile .autocomplete-dropdown-container{
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  position: absolute;
  width: 100%;
  
}

.autocomplete-dropdown {
 position: absolute;
 width: 100%;
 z-index: 9999; 
}

@media screen and (min-width: 1321px) {
  .mobile-filter-fields {
    padding: 30px 0;
    display: none;
  }
}

@media screen and (max-width: 650px) {
  .mobile-filter-fields .mobile-filter-nav .filter-item .autocomplete {
    width: 80%;
  }
}

@media screen and (max-width: 424px) {
  .mobile-filter-fields
    .mobile-filter-nav
    .filter-item
    .autocomplete.textField {
    padding: 1px 2px;
  }
  .mobile-filter-fields .mobile-filter-nav .filter-item .autocomplete {
    width: 75%;
    height: 50px;
  }

  .mobile-filter-fields
    .mobile-filter-nav
    .filter-item
    .autocomplete
    .textField
    ::placeholder {
    font-size: 14px !important;
  }
 
  .mobile-filter-fields .mobile-filter-nav .filter-item .filter-button {
    gap: 2px;
    font-size: 14px;
  }
}
