.about-page {
    text-align: center;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
}

.about-page .primary-image {
    margin-bottom: 50px;
}

.about-page .welcome-to-abidors {
    margin: 0 60px 50px 60px;
}

.about-page .welcome-to-abidors .part-left {
    float: left;
    width: 60%;
}

.about-page .welcome-to-abidors .part-left .title {
    font-size: 40px;
    font-weight: 600;
    line-height: 40px;
    text-align: left;
    color: rgba(0, 188, 212, 1);
}

.about-page .welcome-to-abidors .part-left .title-sub {
    font-size: 25px;
    font-weight: 600;
    line-height: 45px;
    text-align: left;
}

.about-page .welcome-to-abidors .part-left .text {
    font-size: 18px;
    font-weight: 400;
    line-height: 30px;
    text-align: left;  
}

.about-page .welcome-to-abidors .part-right {
    float: right;
    width: 40%;
}

.about-page .welcome-to-abidors .part-right img {
    width: 80%;
}

.about-page .here-are-some-ways-technology-empowers {
    margin-bottom: 50px;
}

.about-page .here-are-some-ways-technology-empowers .col {
    float: none !important;
    display: inline-block;
    margin: 0 auto;
}

.about-page .here-are-some-ways-technology-empowers .title {
    font-size: 30px;
    font-weight: 700;
    margin-bottom: 20px;
    text-align: center;
    color: rgba(0, 188, 212, 1);
    padding: 0 200px;
}

.about-page .here-are-some-ways-technology-empowers .content {
    box-shadow: 0px 0px 40px 0px rgba(0, 0, 0, 0.15);
    background-color: #ffffff;
    margin-bottom: 10px;
    padding: 20px;
    height: 150px;
    border-radius: 15px;
}

.about-page .here-are-some-ways-technology-empowers .content .content-left {
    float: left;
    width: 6%;
    margin-right: 20px;
}

.about-page .here-are-some-ways-technology-empowers .content .content-right {
    float: left;
    width: 88%;
}

.about-page .here-are-some-ways-technology-empowers .content .content-right .content-title {
    font-size: 20px;
    font-weight: 700;
    line-height: 30px;
    text-align: left;
    color: rgba(0, 188, 212, 1);
    margin-bottom: 0;
}

.about-page .here-are-some-ways-technology-empowers .content .content-right .content-text {
    font-size: 15px;
    font-weight: 700;
    line-height: 30px;
    text-align: left;
    margin-bottom: 0;
}

.about-page .here-are-some-ways-technology-empowers .col10of12 .content {
    box-shadow: 0px 0px 40px 0px rgba(0, 0, 0, 0.15);
    background-color: #ffffff;
    margin: 0 auto;
    padding: 20px;
    height: 150px;
    border-radius: 15px;
}

.about-page .here-are-some-ways-technology-empowers .col10of12 .content .content-left {
    display: inline-block;
    width: 3%;
    margin-right: 20px;
    float: none;
    vertical-align: top;
}

.about-page .here-are-some-ways-technology-empowers .col10of12 .content .content-right {
    width: 30%;
    display: inline-block;
    float: none;
}

.about-page .here-are-some-ways-technology-empowers .col10of12 .content .content-right .content-title {
    font-size: 20px;
    font-weight: 700;
    line-height: 30px;
    text-align: left;
    color: rgba(0, 188, 212, 1);
    margin-bottom: 0;
}

.about-page .here-are-some-ways-technology-empowers .col10of12 .content .content-right .content-text {
    font-size: 15px;
    font-weight: 700;
    line-height: 30px;
    text-align: left;
    margin-bottom: 0;
}

.about-page .join-abidors-today {
    margin:  0 20px 40px 20px;
}

.about-page .join-abidors-today .title {
    font-size: 30px;
    font-weight: 700;
    margin-bottom: 40px;
    text-align: center;
    padding: 0 200px;
}

.about-page .join-abidors-today .join-abidors-today-content {
    margin: 0 60px 20px 60px;
}

.about-page .join-abidors-today .join-abidors-today-content .title-sub {
    font-size: 30px;
    font-weight: 600;
    line-height: 40px;
    text-align: left;
    color: rgba(0, 188, 212, 1);
    position: relative;
}

.about-page .join-abidors-today .join-abidors-today-content .title-sub:before {
    content: "";
    position: absolute;
    left: 0;
    bottom: -7px;
    height: 1px;
    width: 150px;
    border-bottom: 6px solid rgba(255, 87, 34, 1);
    border-radius: 8px;
}

.about-page .join-abidors-today .join-abidors-today-content .text {
    font-size: 18px;
    font-weight: 400;
    line-height: 30px;
    text-align: left;
    color: rgba(1, 35, 36, 1);
    margin-bottom: 0;
}

.about-page .join-abidors-today .join-abidors-today-content {
    margin-bottom: 40px;
}

.about-page .join-abidors-today .join-abidors-today-content.first-paragraph .part-left {
    float: left;
    width: 60%;
    margin-right: 40px;
}

.about-page .join-abidors-today .join-abidors-today-content.first-paragraph .part-right {
    float: left;
    width: 25%;
    margin-top: -10px;
}

.about-page .join-abidors-today .join-abidors-today-content.second-paragraph .part-left {
    float: left;
    width: 25%;
    margin-right: 40px;
    margin-top: -10px;
}

.about-page .join-abidors-today .join-abidors-today-content.second-paragraph .part-right {
    float: left;
    width: 60%;
}

.about-page .join-abidors-today .join-abidors-today-content.third-paragraph .part-left {
    float: left;
    width: 60%;
    margin-right: 40px;
}

.about-page .join-abidors-today .join-abidors-today-content.third-paragraph .part-right {
    float: left;
    width: 25%;
    margin-top: -10px;
}

.about-page .join-abidors-today .join-abidors-today-content.four-paragraph .part-left {
    float: left;
    width: 25%;
    margin-right: 40px;
    margin-top: -10px;
}

.about-page .join-abidors-today .join-abidors-today-content.four-paragraph .part-right {
    float: left;
    width: 60%;
}

.about-page .your-feedback {
    margin: 0 60px 30px 60px;
}

.about-page .your-feedback .title {
    font-size: 30px;
    font-weight: 700;
    line-height: 40px;
    text-align: center;
    color: rgba(0, 188, 212, 1);
}

.about-page .your-feedback .text {
    font-size: 25px;
    font-weight: 400;
    line-height: 40px;
    text-align: center;
    color: rgba(1, 35, 36, 1);
}

.about-page .your-feedback .write-to-as {
    background-color: rgba(0, 188, 212, 1);
    color: #ffffff;
    border-radius: 5px;
    text-transform: none;
    padding: 12px 40px;
    font-size: 18px;
    font-weight: 700;
    line-height: 40px;
    transition: all .5s;
}

.about-page .your-feedback .write-to-as:hover {
    transform: scale(1.1);
}

@media screen and (max-width: 1340px) {

}

@media screen and (max-width: 1130px) {

}

@media screen and (max-width: 1030px) {
    .about-page .welcome-to-abidors .part-right img {
        width: 100%;
    }

    .about-page .here-are-some-ways-technology-empowers .title {
        padding: 0 100px;
    }

    .about-page .col {
        float: none;
        width: 100%;
    }

    .about-page .here-are-some-ways-technology-empowers .content .content-left {
        width: 3%;
    }

    .about-page .here-are-some-ways-technology-empowers .content {
        height: 120px;
    }

    .about-page .here-are-some-ways-technology-empowers .col10of12 .content {
        height: 120px;
    }

    .about-page .here-are-some-ways-technology-empowers .col10of12 .content .content-right {
        width: 93%;
    }


    .about-page .join-abidors-today .join-abidors-today-content .text {
        margin-bottom: 30px;
    }

    .about-page .join-abidors-today .join-abidors-today-content.first-paragraph .part-left,
    .about-page .join-abidors-today .join-abidors-today-content.first-paragraph .part-right,
    .about-page .join-abidors-today .join-abidors-today-content.second-paragraph .part-left,
    .about-page .join-abidors-today .join-abidors-today-content.second-paragraph .part-right,
    .about-page .join-abidors-today .join-abidors-today-content.third-paragraph .part-left,
    .about-page .join-abidors-today .join-abidors-today-content.third-paragraph .part-right,
    .about-page .join-abidors-today .join-abidors-today-content.four-paragraph .part-left,
    .about-page .join-abidors-today .join-abidors-today-content.four-paragraph .part-right {
        float: none;
        width: 100%;
    }

    .about-page .join-abidors-today .join-abidors-today-content.first-paragraph .part-left img,
    .about-page .join-abidors-today .join-abidors-today-content.first-paragraph .part-right img,
    .about-page .join-abidors-today .join-abidors-today-content.second-paragraph .part-left img,
    .about-page .join-abidors-today .join-abidors-today-content.second-paragraph .part-right img,
    .about-page .join-abidors-today .join-abidors-today-content.third-paragraph .part-left img,
    .about-page .join-abidors-today .join-abidors-today-content.third-paragraph .part-right img,
    .about-page .join-abidors-today .join-abidors-today-content.four-paragraph .part-left img,
    .about-page .join-abidors-today .join-abidors-today-content.four-paragraph .part-right img {
        float: none;
        width: 35%;
    }
}

@media screen and (max-width: 984px) {
	.cols::before,
	.cols::after {
		display: none;
	}

    .about-page .welcome-to-abidors .part-left,
    .about-page .welcome-to-abidors .part-right {
        width: 100%;
        float: none;
    }
}

@media screen and (max-width: 767px){
    .about-page .join-abidors-today .title {
        padding: 0 25px;
    }

    .about-page .your-feedback {
        padding: 0 30px;
    }

    .about-page .your-feedback .text {
        font-size: 18px;
    }
}

@media screen and (max-width: 500px) {
    .about-page .welcome-to-abidors {
        margin: 0 10px 30px 10px;
        text-align: left;
    }

    .about-page .welcome-to-abidors .part-left .title {
        font-size: 30px;
        line-height: 35px;
        text-align: left;
    }

    .about-page .welcome-to-abidors .part-left .title-sub {
        font-size: 20px;
        line-height: 35px;
        text-align: left;
    }

    .about-page .welcome-to-abidors .part-left .text {
        text-align: left;
    }

    .about-page .here-are-some-ways-technology-empowers .title {
        padding: 0 10px;
        margin: 0 0 30px 0;
        text-align: left;
        line-height: 30px;
        font-size: 25px;
    }

    .about-page .here-are-some-ways-technology-empowers .content .content-left,
    .about-page .here-are-some-ways-technology-empowers .col10of12 .content .content-left {
        width: 10%;
        margin-right: 10px;
    }

    .about-page .here-are-some-ways-technology-empowers .content .content-right {
        width: 75%;
    }

    .about-page .here-are-some-ways-technology-empowers .col10of12 .content .content-right {
        width: 85%;
    }

    .about-page .here-are-some-ways-technology-empowers .content,
    .about-page .here-are-some-ways-technology-empowers .col10of12 .content {
        height: 200px;
    }

    .about-page .here-are-some-ways-technology-empowers .content,
    .about-page .here-are-some-ways-technology-empowers .col10of12 .content {
        padding: 15px 10px;
    }

    .about-page .here-are-some-ways-technology-empowers {
        margin: 0 10px 30px 10px;
    }

    .about-page .join-abidors-today .title {
        padding: 0;
        text-align: left;
        font-size: 25px;
        line-height: 30px;
    }

    .about-page .join-abidors-today .join-abidors-today-content {
        margin: 0 0 30px 0;
    }

    .about-page .join-abidors-today .join-abidors-today-content.first-paragraph .part-left img, .about-page .join-abidors-today .join-abidors-today-content.first-paragraph .part-right img, .about-page .join-abidors-today .join-abidors-today-content.second-paragraph .part-left img, .about-page .join-abidors-today .join-abidors-today-content.second-paragraph .part-right img, .about-page .join-abidors-today .join-abidors-today-content.third-paragraph .part-left img, .about-page .join-abidors-today .join-abidors-today-content.third-paragraph .part-right img, .about-page .join-abidors-today .join-abidors-today-content.four-paragraph .part-left img, .about-page .join-abidors-today .join-abidors-today-content.four-paragraph .part-right img {
        width: 80%;
    }

    .about-page .join-abidors-today .join-abidors-today-content.four-paragraph .part-left {
        margin-top: 0;
        margin-right: 0;
    }

    .about-page .your-feedback {
        padding: 0;
        margin: 0 10px 30px 10px;
        text-align: left;
    }

    .about-page .your-feedback .title {
        text-align: left;
    }

    .about-page .your-feedback .text {
        text-align: left;
    }

    .about-page .join-abidors-today .join-abidors-today-content .title-sub {
        font-size: 25px;
        text-align: left;
    }
}