.profile-lawyer .profile-lawyer-hire-content {
  /* margin: 95px 250px 270px 250px; */
  margin: 95px 95px 270px 95px;
  background: #F8F8F8;
  border-radius: 10px;
  text-align: center;
}

.profile-lawyer .profile-lawyer-hire-content .title {
  font-size: 32px;
  line-height: 32px;
  font-weight: 700;
  font-family: Lora;
  margin-bottom: 8px;
}

.profile-lawyer .profile-lawyer-hire-content .text {
  margin: 0 20px 24px 20px;
  font-size: 18px;
  line-height: 24px;
  font-family: Source Sans Pro;
  font-weight: 400;
}

.profile-lawyer .profile-lawyer-hire-content .box-content {
  margin-bottom: 24px;
}

.profile-lawyer .profile-lawyer-hire-content .box-content .box {
  padding: 40px 24px 26px 24px;
  background: #EDEDED;
  border-radius: 4px;
  text-align: center;
}

.profile-lawyer .profile-lawyer-hire-content .box-content .box .main-icon .icon {
  margin-bottom: 10px;
}

.profile-lawyer .profile-lawyer-hire-content .box-content .box .text-box {
  font-size: 16px;
  line-height: 18px;
  font-family: Source Sans Pro;
  font-weight: 400;
}

.profile-lawyer .profile-lawyer-hire-content .button {
  padding: 15px 32px;
  background: #00BCD4;
  color: #fff;
  border-radius: 4px;
  text-transform: uppercase;
  font-family: Source Sans Pro;
  font-size: 14px;
  line-height: 18px;
  font-weight: 700;
}


.profile-lawyer .back-btn {
  background: #00BCD4;
  color: #fff; 
}

.profile-lawyer .back-btn:hover {
  background: #00BCD4;
  color: #fff;
}

@media screen and (max-width:1224px) {


  .profile-lawyer .profile-lawyer-hire-content .box-content {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  .profile-lawyer .profile-lawyer-hire-content .box-content .col4of12 {
    width: 100%
  }

  .profile-lawyer .profile-lawyer-hire-content .text {
    text-align: center;
  }

  .profile-lawyer-relationship-suggestion .suggestion-additional-info .sub-title {
    margin: 0 200px 24px 200px;
    text-align: left;
  }
}

@media screen and (max-width:1034px) {
  .profile-lawyer .profile-lawyer-hire-content {
    margin: 64px 10px;
    background: #F8F8F8;
    border-radius: 10px;
    text-align: center;
  }
}

@media screen and (max-width:768px) {
  .profile-lawyer .profile-lawyer-hire-content {
    margin: 64px 10px;
    padding: 32px 0px;
  }

  .services-tab {
    padding: 0;
  }

  .services-tab .header-content {

  }
}


@media screen and (max-width: 600px) {
  .profile-lawyer .profile-lawyer-hire-content .box-content .col{
    padding: 0px !important;
  }
}