.card {
  width: 100%;
  background: #ffffff;
  border-radius: 4px;
  margin: 0 24px 16px 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  //   height: 154px;

  &-body {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    width: calc(100% - 48px);
    height: 100%;

    &-left {
      width: 48.56%;
      margin: 24px 0;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      > div {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;

        > img {
          margin-right: 10.51px;
          margin-left: 3.74px;
        }
        > h5 {
          margin: 0;
          padding: 0;
          font-family: 'Lora', sans-serif;
          font-style: normal;
          font-weight: bold;
          font-size: 24px;
          line-height: 26px;
          margin-bottom: 8px;
          /* identical to box height, or 108% */

          color: #000000;
        }
      }
      > p {
        margin: 0;
        padding: 0;
        font-family: 'Source Sans Pro', sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        line-height: 24px;
        /* identical to box height, or 133% */

        color: #000000;
      }
    }
    &-right {
      width: 51.44%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 24px 0;

      &-doc-upload {
        display: flex;
        justify-content: space-between;
        align-items: center;
        > h5 {
          margin: 0;
          padding: 0;
          font-family: 'Source Sans Pro', sans-serif;
          font-style: normal;
          font-weight: 900;
          font-size: 18px;
          line-height: 22px;
          /* or 122% */

          text-transform: uppercase;

          color: #000000;
        }
        > img {
          margin-right: 21.33px;
          margin-left: 37.33px;
        }
      }
      &-doc-type {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        height: fit-content;
        // margin: 5px 0;
        > button {
          cursor: pointer;
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-top: 5px;

          padding: 8px 16px 8px 12px;

          /* Cyan/500 */

          border: 2px solid #00bcd4;
          box-sizing: border-box;
          border-radius: 4px;
          > h5 {
            margin: 0;
            padding: 0;
            font-family: 'Source Sans Pro', sans-serif;
            font-style: normal;
            font-weight: bold;
            font-size: 14px;
            line-height: 18px;
            /* identical to box height, or 129% */

            text-transform: uppercase;

            color: #000000;
          }
          > img {
            margin-right: 7.53px;
          }
        }
      }
    }
  }

  &-bottom-bar {
    background: #a5d6a7;
    border-radius: 0px 0px 4px 4px;
    width: 100%;
    height: 8px;
  }
}
