.answer-seller {
    margin-top: 32px;
}

.answer-seller .title-sub {
    margin: 0 250px 32px 250px;
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 24px;
    text-align: center;
    color: #000000;
}

.answer-seller .content .title {
    margin-bottom: 24px;
    font-family: Lora;
    font-style: normal;
    font-weight: bold;
    font-size: 32px;
    line-height: 32px;
    text-align: center;
    color: #000000;
}

.answer-seller .content .question .text {
    margin-bottom: 8px;
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 24px;
    text-align: left;
    color: #000000;
}

.answer-seller .content .question .actions {
    margin-bottom: 32px;
}

.answer-seller .content .question .actions .made-a-final-offer {
    margin-right: 25px;
    padding: 10px;
    background: #00BCD4;
    border-radius: 4px;
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 18px;
    text-transform: uppercase;
    color: #FFFFFF;
}

.answer-seller .content .question .actions .made-a-final-offer:disabled {
    background-color: rgb(235, 235, 228);
    color: rgb(84, 84, 84);
}

.answer-seller .content .question .actions .did-not-make-an-offer {
    padding: 9px;
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 18px;
    text-transform: uppercase;
    color: #000000;
    background-color: #fff;
    border: 1px solid #00BCD4;
    border-radius: 4px;
}

.answer-seller .content .question .actions img {
    margin-right: 6px;
}

.answer-seller .content .price {
    margin-bottom: 32px;
    text-align: left;
}

.answer-seller .content .price .price-question {
    margin-bottom: 8px;
    text-align: left !important;
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 24px;
}

.answer-seller .content .price .price-number {
    display: inline-block;
    padding: 4px 8px;
    background: #E0F7FA;
    border-radius: 4px;
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 32px;
    text-transform: uppercase;
    color: #000000;
    width: 170px;
    border: none;
}

.answer-seller .content .price .price-number.error {
    border: 1px solid #F32013;
}

.answer-seller .content .radio-buttons {
    margin-bottom: 32px;
    text-align: left;
}

.answer-seller .content .radio-buttons .radio-buttons-question {
    margin-bottom: 10px;
    text-align: left !important;
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 24px;
    color: #000000;
}

.answer-seller .content .radio-buttons .radio-buttons-group {
    color: green;
}

.answer-seller .content .radio-buttons .radio-button-label {
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 12px;
    color: #000000;
}

.answer-seller .content .buttons-action {
    margin-bottom: 20px;
    display: flex;
    justify-content: flex-start;
}

.answer-seller .content .buttons-action .continue-btn {
    background: #00BCD4;
    padding: 15px 32px;
    border-radius: 4px;
    margin-right: 24px;
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
    text-transform: uppercase;
    color: #FFFFFF;
}

.answer-seller .content .buttons-action .continue-btn:disabled {
    background-color: rgb(235, 235, 228);
    color: rgb(84, 84, 84);
}

.answer-seller .content .buttons-action .check-answers-btn {
    border: 2px solid #00BCD4;
    padding: 15px 32px;
    border-radius: 4px;
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
    text-transform: uppercase;
    color: #000000;
}


.answer-seller .content .latest-contract {
    margin-bottom: 32px;
    text-align: left;
}

.answer-seller .content .latest-contract .title {
    margin-bottom: 8px;
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 24px;
    color: #000000;
    text-align: left;
}

.answer-seller .content .latest-contract .calendar {
    width: 200px;
    margin: 0;
    padding: 16px 12px;
    background-color: #EDEDED;
    border-radius: 4px;
}

.answer-seller .content .describe-free-text {
    margin-bottom: 27px;
}

.answer-seller .content .describe-free-text .title {
    text-align: left !important;
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 24px;
    text-align: center;
    color: #000000;
}

.answer-seller .content .describe-free-text .field {
    width: 100%;
}

.answer-seller .content .checkbox {
    margin-bottom: 28px;
    text-align: left;
}

.answer-seller .content .checkbox label {
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.4px;
    color: #000000;
}

.answer-seller .content .other-part {
    margin-bottom: 80px;
}

.answer-seller .content .other-part .other-part-title {
    margin: 0 240px 32px 240px;
    font-family: Source Sans Pro;
    font-style: normal !important;
    font-weight: normal !important;
    font-size: 18px !important;
    line-height: 24px !important;
    text-align: center;
    color: #000000;
}

.answer-seller .content .other-part .comment-author {
    margin-bottom: 8px;
    font-family: Lora;
    font-style: normal;
    font-weight: bold;
    font-size: 32px;
    line-height: 32px;
    text-align: center;
    color: #000000;
}

.answer-seller .content .other-part .text {
    margin: 0 130px 24px 130px;
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 24px;
    text-align: center;
    color: #000000;
}

.answer-seller .content .other-part .author {
    text-align: left !important;
    margin: 0 130px 8px 130px;
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.4px;
    color: #000000;
}

.answer-seller .content .other-part .field {
    width: 670px;
    margin: 0 130px 24px 130px;
}

.answer-seller .content .other-part .confirm {
    display: inline-block;
    width: 200px;
    margin-right: 25px;
    padding: 15px 42px;
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 18px;
    background: #00BCD4;
    border-radius: 4px;
    text-transform: uppercase;
    color: #FFFFFF;
}

.answer-seller .content .other-part .no-confirm {
    display: inline-block;
    width: 200px;
    padding: 15px 30px;
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 18px;
    border: 1px solid #00BCD4;
    border-radius: 4px;
    text-transform: uppercase;
    color: #000000
}

.answer-seller .content .what-happened-during {
    margin-bottom: 64px;
}

.answer-seller .content .what-happened-during .what-happened-during-title {
    margin-bottom: 24px;
    font-family: Lora;
    font-style: normal;
    font-weight: bold;
    font-size: 32px;
    line-height: 32px;
    text-align: center;
    color: #000000;
}

.answer-seller .content .what-happened-during .did-not-make-an-offer {
    display: block;
    margin: 0 auto 32px auto;
    padding: 15px 25px;
    border: 1px solid #00BCD4;
    border-radius: 4px;
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 18px;
    text-transform: uppercase;
    color: #000000;
}

.answer-seller .content .what-happened-during .did-not-make-an-offer img {
    margin-right: 10px;
}

.answer-seller .content .what-happened-during .what-happened-during-text {
    margin: 0 120px 24px 120px !important;
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    color: #000000;
}

.answer-seller .content .what-happened-during .comment {
    margin: 0 120px 24px 120px;
}

.answer-seller .content .what-happened-during .comment-title {
    margin-bottom: 8px;
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: 900;
    font-size: 18px;
    line-height: 22px;
    text-transform: uppercase;
    color: #000000;
}

.answer-seller .content .what-happened-during .field {
    width: 100%
}

.answer-seller .content .what-happened-during .share {
    display: block;
    margin: 0 auto;
    padding: 15px 32px;
    background: #00BCD4;
    border-radius: 4px;
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 18px;
    text-transform: uppercase;
    color: #FFFFFF;
}

.answer-dialog {
    text-align: center;
}

.answer-dialog-content {
    padding: 64px 84px;
}

.answer-dialog-content .title-header {
    padding: 0;
    margin-bottom: 8px;
}

.answer-dialog-content .title-header .title {
    display: inline-block;
    font-size: 48px;
    line-height: 52px;
    font-family: Lora;
    font-weight: 700;
}

.answer-dialog-content .title-header .close-icon {
    position: absolute;
    top: 20px;
    right: 20px;
    cursor: pointer;
}

.answer-dialog-content .content p {
    margin-bottom: 24px;
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 24px;
}

.answer-dialog-content .content .button-finish {
    display: block;
    margin: 0 auto;
    padding: 15px 32px;
    background: #00BCD4;
    border-radius: 4px;
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 18px;
    text-transform: uppercase;
    color: #FFFFFF;
}

.oposite-side-answer-dialog {
    text-align: center;
    cursor: default;
}

.oposite-side-answer-dialog-content {
    padding: 64px;
}

.oposite-side-answer-dialog-content .title-header {
    padding: 0 100px;
    margin-bottom: 24px;
}

.oposite-side-answer-dialog-content .title-header-no-answer {
    padding: 0 40px;
}

.oposite-side-answer-dialog-content .title-header .title,
.oposite-side-answer-dialog-content .title-header-no-answer .title {
    font-family: 'Lora';
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 32px;
}

.oposite-side-answer-dialog-content .title-header .close-icon,
.oposite-side-answer-dialog-content .title-header-no-answer .close-icon {
    position: absolute;
    top: 20px;
    right: 20px;
    cursor: pointer;
}

.oposite-side-answer-dialog-content .content {
    padding: 0 80px;
}

.oposite-side-answer-dialog-content .content .group {
    margin-bottom: 32px;
}

.oposite-side-answer-dialog-content .content .group .label {
    font-family: 'Lora';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 26px;
    color: #000000;
    margin-bottom: 8px;
}

.oposite-side-answer-dialog-content .content .group .text {
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    color: #000000;
}

.oposite-side-answer-dialog-content .content .group .price {
    padding: 4px 8px;
    background: #E0F7FA;
    border-radius: 4px;
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 700;
    font-size: 36px;
    line-height: 45px;
    text-transform: uppercase;
    color: #000000;
}

.oposite-side-answer-dialog-content .content .group .empty-value {
    width: 12px;
    height: 3px;
    background: #000000;
    margin: 0 auto;
}

.oposite-side-answer-dialog-content .content .button-close {
    padding: 15px 32px;
    background: #00BCD4;
    border-radius: 4px;
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
    text-transform: uppercase;
    color: #FFFFFF;
}

@media screen and (max-width:501px){
    .answer-seller .content .question .actions {
        display: flex;
        flex-direction: column;
    }

    .answer-seller .content .question .actions {
        display: flex;
        flex-direction: column;
        gap:20px
    }

    .answer-seller .content .question .actions .made-a-final-offer{
     width: 100%;
    }
    .oposite-side-answer-dialog-content {
        padding: 64px 0px;
    }

    .oposite-side-answer-dialog-content .title-header .title, .oposite-side-answer-dialog-content .title-header-no-answer .title {
        font-family: 'Lora';
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 32px;
    }
}

@media screen and (max-width:533px){
    .answer-seller .content .buttons-action .continue-btn {
        padding: 15px 20px;
        background: #00BCD4
    }
    .answer-seller .content .buttons-action .check-answers-btn {
        padding: 15px 20px;
    }
}

@media screen and (max-width:485px){
    .answer-seller .content .buttons-action .continue-btn {
        width: 100%;
        font-size: 12px;
    }

    .answer-seller .content .buttons-action .check-answers-btn {
        width: 100%;
        font-size: 12px;
    }

    .answer-seller .content .buttons-action{
        display:flex;
        flex-direction: column;
        gap:20px
    }

    .answer-seller .content .question .text {
        text-align: justify !important;
    }

    .oposite-side-answer-dialog-content .title-header {
        padding: 20px 10px;
        margin-bottom: 0;
    }

    .oposite-side-answer-dialog-content .title-header .title, .oposite-side-answer-dialog-content .title-header-no-answer .title {
        line-height: 23px;
    }

    .oposite-side-answer-dialog-content .content .group .label {
        font-size: 18px;
    }
}