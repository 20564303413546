.exam-test .exam-test-questions { padding: 100px 0; max-width: 672px; margin: 0 auto; text-align: center;}
.exam-test .actions { padding: 60px 0; background-color: #014245; text-align: center; }
.exam-test .actions .back-button { margin-right: 25px; background-color: inherit; border: 1px solid #00BCD4; cursor: pointer; padding: 15px 32px; border-radius: 4px; font-family: Source Sans Pro; font-style: normal; font-weight: bold; font-size: 14px; line-height: 18px; text-transform: uppercase; color: #FFFFFF; }
.exam-test .actions .next-button { margin-right: 24px; padding: 12px 32px; cursor: pointer; padding: 15px 32px; background: #00BCD4; border-radius: 4px; font-family: Source Sans Pro; font-style: normal; font-weight: bold; font-size: 14px; line-height: 18px; text-transform: uppercase; color: #EFEFEF; }

.question { display: grid; justify-content: center; text-align: left; }
.question .title { margin-bottom: 4px; font-family: Source Sans Pro; font-style: normal; font-weight: 900; font-size: 18px; line-height: 22px; text-transform: uppercase; }
.question .title-sub { margin-bottom: 26px; font-family: Source Sans Pro; font-style: normal; font-weight: normal; font-size: 12px; line-height: 16px; letter-spacing: 0.4px; }
.question .text-area { border-radius: 4px; padding: 12px; color: #000000; background: #EDEDED; font-family: Source Sans Pro; font-style: normal; font-weight: normal; font-size: 18px; line-height: 24px; }
.question .text-area::placeholder { color: rgba(0, 0, 0, 0.6); font-family: Source Sans Pro; font-style: normal; font-weight: normal; font-size: 18px; line-height: 24px; }

@media screen and (max-width:762px){
    .exam-test .exam-test-questions { 
        padding: 100px 10px; 
        max-width: 672px; 
        margin: 0 auto; 
        text-align: center;
    }

} 

@media screen and (max-width:450px){
.exam-test .actions { 
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding:  20px;
}

.exam-test .actions .back-button { 
    margin-right: 0; 
}
.exam-test .actions .next-button {
     margin:0; 
    }


}