.va-header-content {
  display: flex;
  margin-top: 32px;
    width: 100%;
  flex-direction: row;
}

.va-header-content .avatar {
  position: relative;
  width: 178px;
}

.va-header-content .avatar .photo {
  width: 178px;
  height: 178px;
  border-radius: 50%;
  object-fit: cover;
}

.va-header-content .avatar .icon {
  position: absolute;
  right: 0px;
  bottom: 10px;
  height: 30px;
  width: 30px;
}

.va-header-content .avatar >p{
  height: fit-content;
  display: flex;
}

.va-header-content .user-details {
  margin-left: 17px;
  max-width: 370px;
}

.va-header-content .user-details .title-primary {
  margin-bottom: 10px;
  font-family: Lora;
  font-style: normal;
  font-weight: 1;
  font-size: 48px;
  line-height: 52px;
  color: #000000;
}

.va-header-content .user-details .rating {
  display: flex;
  margin-bottom: 17px;
}

.va-header-content .user-details .rating .title {
  margin-right: 10px;
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: 900;
  font-size: 18px;
  line-height: 22px;
  text-transform: uppercase;
  color: #000000;
}

.va-header-content .user-details .rating .star {
  width: 16px;
  height: 16px;
  margin-top: 2.5px;
  filter: invert(48%) sepia(93%) saturate(3629%) hue-rotate(157deg) brightness(90%) contrast(102%);
}

.va-header-content .user-details .rating .result {
  margin-left: 8px;
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 24px;
  color: #000000;
}

.va-header-content .user-details .additional {
  display: flex;
}

.va-header-content .user-details .additional .first-half .title {
  margin-bottom: 9px;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  color: #656565;
}

.va-header-content .user-details .additional .block {
  width: 162px;
  height: 60px;
  display: flex;
  background: #F4F4F4;
  border-radius: 4px;
}

.va-header-content .user-details .additional .block .icon {
  width: 20px;
  height: 20px;
  margin: 19px 11px 21px 19px;
  color: #000000;
}

.va-header-content .user-details .additional .block .text {
  margin: 16px 16px 16px 0px;
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 24px;
  color: #000000;
}

.va-header-content .user-details .additional .second-half {
  margin-left: 22px;
}

.va-header-content .user-details .additional .second-half .block {
  width: 107px;
  background: #F4F4F4;
  border-radius: 4px;
}

.va-header-content .user-details .additional .second-half .title-content {
  display: flex;
}

.va-header-content .user-details .additional .second-half .title-content .title {
  margin-bottom: 9px;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  color: #656565;
}

.va-header-content .user-details .additional .second-half .title-content .icon {
  width: 20px;
  height: 20px;
  color: #000000;
  margin-left: 11px;
  margin-top: -1px;
}

.va-header-content .about-info {
  width: 500px;
  min-height: 190px;
  max-height: 190px;
  margin-left: 14px;
  margin-right: 11px;
  padding: 16px;
  background: #F8F8F8;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px rgba(0, 0, 0, 0.12), 0px 1px 3px rgba(0, 0, 0, 0.2);
  border-radius: 12px;
  height: 148px;
  overflow-y: scroll;
}

.va-header-content .about-info .title {
  margin-bottom: 6px;
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
  text-transform: uppercase;
  color: #000000;
}

.va-header-content .about-info .paragraph {
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 24px;
  color: #000000;
  word-wrap: break-word; 
  overflow-wrap: break-word;
  white-space: normal; 
}

@media screen and (max-width: 1321px) {
  .va-header-content .about-info {
      width: 430px;
  }
}


@media screen and (max-width: 1239px) {
  .va-header-content .about-info {
      width: 330px;
  }
}

@media screen and (max-width: 1200px) {

  .va-header-content .user-details .title-primary{
    text-align: center;
}

  .va-header-content .avatar p {
    width: 300px;
}
.va-header-content {
    display: flex;
    flex-direction: column;
    width: 80%;
    justify-content: center;
    align-items: center;
    gap: 30px;
}

.va-header-content .about-info {
    width: 430px;
}

.va-header-content .user-details {
    width: 430px;    
}

.va-header-content .user-details .additional .second-half {
    margin-left: 99px;
}

.va-header-content .user-details .additional .second-half .block {
    width: 100%;
}
}


@media screen and (max-width: 1100px) {
  .lawyer-features-items {
      
      grid-template-columns: repeat(3, 1fr) !important;
      
  }
}

@media screen and (max-width: 830px) {
  .lawyer-features-items {
      
      grid-template-columns: repeat(2, 1fr) !important;
      
  }
}


@media screen and (max-width: 631px) {
  .va-header-content {
      width: 100%;
  }

  .va-header-content .user-details{
      margin-left: 0px !important;
  }

  .va-header-content .about-info {
      margin-left: 24px !important;
  }
}


@media screen and (max-width: 611px) {
  .va-header-content .about-info {
      width: 100%;
  }

  .va-header-content .user-details .additional {
      justify-content: space-between;
  }

  .va-header-content .user-details .additional .second-half {
      margin-left: 0px;
  }

  .va-header-content .user-details {
      width: 100% !important;
  }

  .va-header-content .avatar p{
      width: 100%;
  }

}


@media screen and (max-width: 550px) {
  .lawyer-features-items {
      
      grid-template-columns: repeat(1, 1fr) !important;
      
  }
}

@media screen and (max-width: 531px) {
  .va-header-content {
      width: 100%;
  }

  .va-header-content .user-details {
      width: 100%;
  }
}

@media screen and (max-width: 411px) { 
  .va-header-content .user-details .additional {
      flex-direction: column;
      gap: 20px;
  }

  .va-header-content .user-details .additional .block {
      width: 100%;
  }

  .va-header-content .user-details {
      margin-left: 0;
  }

  .va-header-content .about-info {
      margin-left: 0;
      margin-right: 0;
  }

  .virtual-assistant-profile-left-content > .bottom {
      margin-top: 20px;
  }

  .va-header-content .about-info {
      margin-left:  0 !important;
  }
}