.choosen-bank {
  margin-top: 30px;
  margin-bottom: 128px;
  padding: 0 32px;
  text-align: center;
}

.choosen-bank .title {
  margin-bottom: 8px;
  font-family: Lora;
  font-style: normal;
  font-weight: 1;
  font-size: 48px;
  line-height: 52px;
  text-align: center;
  color: #000000;
}

.choosen-bank .title-sub {
  margin-bottom: 32px;
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 24px;
  text-align: center;
  color: #000000;
}

.choosen-bank .title-footer {
  margin-bottom: 16px;
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 24px;
  text-align: center;
  color: #000000;
}

.choosen-bank .bank-cards .box {
  border: 2px solid transparent;
  margin-bottom: 32px;
  background: #ffffff;
  border-radius: 4px;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.14), 0px 3px 1px rgba(0, 0, 0, 0.12),
    0px 1px 5px rgba(0, 0, 0, 0.2);
  cursor: pointer;
}

.choosen-bank .bank-cards .box.active-bank {
  cursor: default;
  border: 2px solid #00838f;
}

.choosen-bank .bank-cards .box .icon {
  height: 155px;
  margin: 32px 0;
}

.choosen-bank .bank-cards .box .bank-name {
  width: 100%;
  background: #ededed;
  border-radius: 0 0 4px 4px;
  padding: 8px 0;
  font-size: 18px;
  line-height: 22px;
  font-family: Source Sans Pro;
  font-weight: 900;
  text-transform: uppercase;
}

.choosen-bank .bank-cards .box.active-bank .bank-name {
  color: #fff;
  background: #00838f;
  border-radius: 0;
}

.choosen-bank .button-offer {
  padding: 15px 32px;
  border-radius: 4px;
  background: #00bcd4;
  color: #fff;
  font-size: 14px;
  line-height: 18px;
  font-family: Source Sans Pro;
  font-weight: 700;
  text-transform: uppercase;
  margin-bottom: 16px;
}

.choosen-bank .button-offer:disabled {
  background-color: rgb(235, 235, 228);
  color: rgb(84, 84, 84);
}

.choosen-bank .button-offer:hover {
  background: #00bcd4;
}

/* modal style */
.protocol-for-a-received-loan-application
  .protocol-for-a-received-loan-application-content {
  padding: 21px;
}

.protocol-for-a-received-loan-application
  .protocol-for-a-received-loan-application-content
  .title-header
  .close-icon {
  float: right;
  cursor: pointer;
}

.protocol-for-a-received-loan-application
  .protocol-for-a-received-loan-application-content
  .protocol-for-a-received-loan-application-modal {
  margin: 0 85px;
}

.protocol-for-a-received-loan-application
  .protocol-for-a-received-loan-application-content
  .protocol-for-a-received-loan-application-modal
  .title {
  margin-bottom: 8px;
  font-family: Lora;
  font-style: normal;
  font-weight: 1;
  font-size: 48px;
  line-height: 52px;
  text-align: center;
  color: #000000;
}

.protocol-for-a-received-loan-application
  .protocol-for-a-received-loan-application-content
  .protocol-for-a-received-loan-application-modal
  .title-sub {
  margin-bottom: 16px;
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 24px;
  text-align: center;
  color: #000000;
}

.protocol-for-a-received-loan-application
  .protocol-for-a-received-loan-application-content
  .protocol-for-a-received-loan-application-modal
  .textFieldContainer {
  width: 500px;
  display: block;
  margin: 0 auto 36px auto;
  border-radius: 4px;
  background-color: #e2e2e2;
}

.protocol-for-a-received-loan-application
  .protocol-for-a-received-loan-application-content
  .protocol-for-a-received-loan-application-modal
  .textFieldContainer:last-child {
  margin-bottom: 8px;
}

.protocol-for-a-received-loan-application
  .protocol-for-a-received-loan-application-content
  .protocol-for-a-received-loan-application-modal
  .actions {
  text-align: center;
}

.protocol-for-a-received-loan-application
  .protocol-for-a-received-loan-application-content
  .protocol-for-a-received-loan-application-modal
  .actions
  .send {
  margin-right: 20px;
  background: #00bcd4;
  border-radius: 4px;
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  padding: 15px 32px;
  text-transform: uppercase;
  color: #ffffff;
}

.protocol-for-a-received-loan-application
  .protocol-for-a-received-loan-application-content
  .protocol-for-a-received-loan-application-modal
  .actions
  .send:disabled {
  background-color: rgb(235, 235, 228);
  color: rgb(84, 84, 84);
}

.protocol-for-a-received-loan-application
  .protocol-for-a-received-loan-application-content
  .protocol-for-a-received-loan-application-modal
  .actions
  .close {
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  text-transform: uppercase;
  background-color: #fff;
  border-radius: 4px;
  border: 1px solid #00bcd4;
  padding: 15px 32px;
  color: #000000;
  margin-right: 20px;
}

.protocol-for-a-received-loan-application
  .protocol-for-a-received-loan-application-content
  .protocol-for-a-received-loan-application-modal
  .actions
  .deleteProperty {
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  text-transform: uppercase;
  background-color: #fff;
  border-radius: 4px;
  border: 1px solid red;
  padding: 15px 32px;
  color: #000000;
}

.protocol-for-a-received-loan-application
  .protocol-for-a-received-loan-application-content
  .protocol-for-a-received-loan-application-modal
  .checkbox-container {
  width: 500px;
}

.choosen-bank .no-mortage-banks-found {
  display: flex;
  width: 100%;
  text-wrap: wrap;
  padding-left: 100px;
  padding-right: 100px;
  text-align: center;
  margin-bottom: 30px;
}

.choosen-bank .mortage-banks-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 20px;
  width: 100%;
    margin-bottom: 20px;
    padding-left: 150px;
    padding-right: 150px;
}

.choosen-bank .mortage-bank {
  display: flex;
  width: 100%;
}

@media screen and (max-width: 1000px) {
  .choosen-bank {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .choosen-bank .cols-full {
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: center;
    align-items: center;
  }

  .choosen-bank .cols-full .col8of12 {
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 100%;
    justify-content: center;
    align-items: center;
  }
  .choosen-bank .cols-full .col2of12 {
    width: 100%;
  }

  .choosen-bank .bank-cards {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .choosen-bank .bank-cards .col3of12 {
    width: 100%;
  }

  .choosen-bank .bank-cards .col6of12 {
    width: 100%;
  }

  .choosen-bank .title-sub {
    text-align: left;
  }

  .choosen-bank .title-footer {
    text-align: left;
  }
}

@media screen and (max-width: 954px) {
  .protocol-for-a-received-loan-application
    .protocol-for-a-received-loan-application-content
    .protocol-for-a-received-loan-application-modal
    .title {
    font-size: 30px;
  }

  .protocol-for-a-received-loan-application
    .protocol-for-a-received-loan-application-content
    .protocol-for-a-received-loan-application-modal
    .title-sub {
    text-align: left;
  }
}

@media screen and (max-width: 768px) {
  .protocol-for-a-received-loan-application
    .protocol-for-a-received-loan-application-content
    .protocol-for-a-received-loan-application-modal {
    margin: 0px;
  }
  .choosen-bank .no-mortage-banks-found {
    padding-left: 60px;
    padding-right: 60px;
  }

  .choosen-bank .mortage-banks-container {
    grid-template-columns: repeat(1, 1fr);
    padding-left: 0px;
    padding-right: 0px;
  }
  .choosen-bank .mortage-bank {
    display: flex;
    width: 100%;
  }
}

@media screen and (max-width: 701px) {
  .choosen-bank .title {
    font-size: 40px;
    line-height: 30px;
  }
  .choosen-bank .cols-full .col {
    padding: 0px;
  }
  .MuiTypography-body1 {
    font-size: 16px !important;
    text-align: left !important;
  }
}

@media screen and (max-width: 630px) {
  .protocol-for-a-received-loan-application
    .protocol-for-a-received-loan-application-content
    .protocol-for-a-received-loan-application-modal
    .checkbox-container {
    width: 100%;
  }
}

@media screen and (max-width: 595px) {
  .choosen-bank .title {
    font-size: 35px;
  }

  .choosen-bank .no-mortage-banks-found {
    padding-left: 20px;
    padding-right: 20px;
  }
}

@media screen and (max-width: 529px) {
  .choosen-bank .title {
    font-size: 30px;
  }
}

@media screen and (max-width: 550px) {
  .protocol-for-a-received-loan-application
    .protocol-for-a-received-loan-application-content
    .protocol-for-a-received-loan-application-modal {
    margin: 0 10px;
  }

  .protocol-for-a-received-loan-application
    .protocol-for-a-received-loan-application-content
    .protocol-for-a-received-loan-application-modal
    .title {
    line-height: 30px;
    text-align: left;
  }
}

@media screen and (max-width: 467px) {
  .protocol-for-a-received-loan-application
    .protocol-for-a-received-loan-application-content
    .protocol-for-a-received-loan-application-modal
    .actions {
    display: flex;
    flex-direction: column;
  }

  .protocol-for-a-received-loan-application
    .protocol-for-a-received-loan-application-content
    .protocol-for-a-received-loan-application-modal
    .actions
    .send {
    width: 100%;
    margin-bottom: 10px;
  }
  .protocol-for-a-received-loan-application
    .protocol-for-a-received-loan-application-content
    .protocol-for-a-received-loan-application-modal
    .actions
    .close {
    width: 100%;
  }
}

@media screen and (max-width: 400px) {
  .choosen-bank .title-sub {
    font-size: 18px;
  }

  .choosen-bank .title-footer {
    font-size: 18px;
  }

  .MuiDialogContent-root {
    padding: 8px 0px !important;
  }

  .protocol-for-a-received-loan-application
    .protocol-for-a-received-loan-application-content
    .protocol-for-a-received-loan-application-modal {
    margin: 0px;
  }

  .MuiTypography-body1 {
    font-size: 13px !important;
    text-align: left !important;
  }

  .protocol-for-a-received-loan-application
    .protocol-for-a-received-loan-application-content
    .protocol-for-a-received-loan-application-modal
    .title {
    font-size: 25px;
  }
}
